import joi from 'joi'
import { differenceInCalendarYears } from 'date-fns'

const Joi = joi.extend(require('@joi/date'))

export const validateWuSend = (payload) => {
  const schema = Joi.object({
    has_issue_date: Joi.string().allow('').label('Has issue date'),
    has_expiration_date: Joi.string().allow('').label('Has expiration date'),
    id_type: Joi.string().required().label('ID Type'),
    id_country_of_issue: Joi.string().required().label('ID Country of Issue'),
    id_number: Joi.string().required().regex(/(^[A-Za-z0-9 -.,]+$)+/).label('ID Number').messages({
      'string.pattern.base': 'ID Number must be valid'
    }),
    id_issue_date: Joi.alternatives().conditional('has_issue_date', { is: 'Y', then: Joi.string().label('ID Issue Date').required(), otherwise: Joi.allow('') }).custom((value, helper) => {
      const currentDate = new Date()

      const getYear = value.substr(4, 4)
      const getMonth = value.substr(2, 2)
      const getDay = value.substr(0, 2)
      const currentValue = new Date(`${getYear}-${getMonth}-${getDay}`)

      if (currentValue > currentDate) {
        return helper.message('The Issue Date is ahead of time than the date today. Please adjust the value.')
      } else {
        return true
      }
    }),
    id_expiration_date: Joi.alternatives().conditional('has_expiration_date', { is: 'Y', then: Joi.string().label('ID Expiry Date').required(), otherwise: Joi.allow('') }).custom((value, helper) => {
      const currentDate = new Date()
      const getYear = value.substr(4, 4)
      const getMonth = value.substr(2, 2)
      const getDay = value.substr(0, 2)
      const currentValue = new Date(`${getYear}-${getMonth}-${getDay}`)

      if (currentDate > currentValue) {
        return helper.message('The Expiry Date has lapsed. Please Try Another ID')
      } else {
        return true
      }
    }),
    Purpose_of_Transaction: Joi.string().required().label('Purpose of Transaction'),
    is_on_behalf: Joi.string().required().label('Transaction on Behalf').custom((value, helper) => {
      if (value === 'Y') {
        return helper.message('Transaction on Behalf is not allowed.')
      } else {
        return true
      }
    }),
    employment_position_level: Joi.string().required().label('Employment Position level'),
    occupation: Joi.string().required().label('Occupation'),
    sender_addr_country_code: Joi.string().required().label('Sender Address Country'),
    Source_of_Funds: Joi.string().required().label('Source of funds'),
    sender_addr_line1: Joi.string().required().label('Sender address').regex(/(^[A-Za-z0-9 -.,]+$)+/).messages({
      'string.pattern.base': 'Sender Address must not have special characters. Only comma, dot and hash sign are accepted.'
    }),
    mywu_number: Joi.string().allow('').label('My WU Number'),
    destination_country_code: Joi.string().required().label('Destination Country'),
    Country_of_Birth: Joi.string().required().label('Country of Birth'),
    // reason_for_send: Joi.string().required().label('Reason for Send'),
    foreign_reference_no: Joi.string().required().label('Foreign Reference Number'),
    sender_mobile_country_code: Joi.string().required().label('Sender Country Code'),
    date_of_birth: Joi.date().format('DDMMYYYY').utc().required().label('Date of Birth').custom((value, helper) => {
      const date = value
      const age = differenceInCalendarYears(new Date(), date)

      if (parseInt(age) < 18) {
        return helper.message('Customers under 18 years old cannot transact with WU')
      } else {
        return true
      }
    }),
    sender_contact_phone: Joi.alternatives().conditional('sender_mobile_no', { is: '', then: Joi.number().label('Sender Contact Phone').required(), otherwise: Joi.allow('') }),
    sender_mobile_no: Joi.alternatives().conditional('sender_contact_phone', { is: '', then: Joi.number().label('Sender Mobile Number').required(), otherwise: Joi.allow('') }),
    sender_first_name: Joi.string().required().regex(/(^[A-Za-z0-9 -.,]+$)+/).label('Sender First Name').messages({
      'string.pattern.base': 'Sender First Name must not have special characters.'
    })
  })

  return schema.validate(payload, { abortEarly: false })
}

export const validateWuQuickPay = (payload) => {
  const schema = Joi.object({
    has_issue_date: Joi.string().allow('').label('Has issue date'),
    has_expiration_date: Joi.string().allow('').label('Has expiration date'),
    id_type: Joi.string().required().label('ID Type'),
    id_country_of_issue: Joi.string().required().label('ID Country of Issue'),
    id_number: Joi.string().required().regex(/(^[A-Za-z0-9 -.,]+$)+/).label('ID Number').messages({
      'string.pattern.base': 'ID Number must be valid'
    }),
    id_issue_date: Joi.alternatives().conditional('has_issue_date', { is: 'Y', then: Joi.string().label('ID Issue Date').required(), otherwise: Joi.allow('') }).custom((value, helper) => {
      const currentDate = new Date()

      const getYear = value.substr(4, 4)
      const getMonth = value.substr(2, 2)
      const getDay = value.substr(0, 2)
      const currentValue = new Date(`${getYear}-${getMonth}-${getDay}`)

      if (currentValue > currentDate) {
        return helper.message('The Issue Date is ahead of time than the date today. Please adjust the value.')
      } else {
        return true
      }
    }),
    id_expiration_date: Joi.alternatives().conditional('has_expiration_date', { is: 'Y', then: Joi.string().label('ID Expiry Date').required(), otherwise: Joi.allow('') }).custom((value, helper) => {
      const currentDate = new Date()
      const getYear = value.substr(4, 4)
      const getMonth = value.substr(2, 2)
      const getDay = value.substr(0, 2)
      const currentValue = new Date(`${getYear}-${getMonth}-${getDay}`)

      if (currentDate > currentValue) {
        return helper.message('The Expiry Date has lapsed. Please Try Another ID')
      } else {
        return true
      }
    }),
    Purpose_of_Transaction: Joi.string().required().label('Purpose of Transaction'),
    is_on_behalf: Joi.string().required().label('Transaction on Behalf').custom((value, helper) => {
      if (value === 'Y') {
        return helper.message('Transaction on Behalf is not allowed.')
      } else {
        return true
      }
    }),
    employment_position_level: Joi.string().required().label('Employment Position level'),
    occupation: Joi.string().required().label('Occupation'),
    sender_addr_country_code: Joi.string().required().label('Sender Address Country'),
    Source_of_Funds: Joi.string().required().label('Source of funds'),
    sender_addr_line1: Joi.string().required().label('Sender address').regex(/(^[A-Za-z0-9 -.,]+$)+/).messages({
      'string.pattern.base': 'Sender Address must not have special characters. Only comma, dot and hash sign are accepted.'
    }),
    // mywu_number: Joi.string().required().label('My WU Number'),
    destination_country_code: Joi.string().required().label('Destination Country'),
    Country_of_Birth: Joi.string().required().label('Country of Birth'),
    foreign_reference_no: Joi.string().required().label('Foreign Reference Number'),
    // reason_for_send: Joi.string().required().label('Reason for Send'),
    sender_mobile_country_code: Joi.string().required().label('Sender Country Code'),
    date_of_birth: Joi.date().format('DDMMYYYY').utc().required().label('Date of Birth').custom((value, helper) => {
      const date = value
      const age = differenceInCalendarYears(new Date(), date)

      if (parseInt(age) < 18) {
        return helper.message('Customers under 18 years old cannot transact with WU')
      } else {
        return true
      }
    }),
    sender_contact_phone: Joi.alternatives().conditional('sender_mobile_no', { is: '', then: Joi.string().label('Sender Contact Phone').required(), otherwise: Joi.allow('') }),
    sender_mobile_no: Joi.alternatives().conditional('sender_contact_phone', { is: '', then: Joi.string().label('Sender Mobile Number').required(), otherwise: Joi.allow('') }),
    sender_first_name: Joi.string().required().regex(/(^[A-Za-z0-9 -.,]+$)+/).label('Sender First Name').messages({
      'string.pattern.base': 'Sender First Name must not have special characters.'
    })
  })

  return schema.validate(payload, { abortEarly: false })
}

export const validateWuReceive = (payload) => {
  const schema = Joi.object({
    has_issue_date: Joi.string().allow('').label('Has issue date'),
    has_expiration_date: Joi.string().allow('').label('Has expiration date'),
    Purpose_of_Transaction: Joi.string().required().label('Purpose of Transaction'),
    id_type: Joi.string().required().label('ID Type'),
    id_issue_date: Joi.alternatives().conditional('has_issue_date', { is: 'Y', then: Joi.string().label('ID Issue Date').required(), otherwise: Joi.allow('') }).custom((value, helper) => {
      const currentDate = new Date()
      const currentValue = new Date(value)

      if (currentValue > currentDate) {
        return helper.message('The Issue Date is ahead of time than the date today. Please adjust the value.')
      } else {
        return true
      }
    }),
    id_expiration_date: Joi.alternatives().conditional('has_expiration_date', { is: 'Y', then: Joi.string().label('ID Expiry Date').required(), otherwise: Joi.allow('') }).custom((value, helper) => {
      const currentDate = new Date()
      const currentValue = new Date(value)

      if (currentDate > currentValue) {
        return helper.message('The Expiry Date has lapsed. Please Try Another ID')
      } else {
        return true
      }
    }),
    occupation: Joi.string().required().label('Occupation'),
    nationality: Joi.string().required().label('Nationality'),
    date_of_birth: Joi.date().format('YYYY-MM-DD').utc().required().label('Date of Birth').custom((value, helper) => {
      const date = value
      const age = differenceInCalendarYears(new Date(), date)

      if (parseInt(age) < 16) {
        return helper.message('Customers under 16 years old cannot transact with WU')
      } else {
        return true
      }
    }),
    rec_mob_country_code: Joi.string().required().label('Country Code'),
    contact_phone: Joi.string().required().label('Mobile Number'),
    employment_position_level: Joi.string().required().label('Employment Position level'),
    receiver_street: Joi.string().required().label('Receiver Street').regex(/(^[A-Za-z0-9 -.,]+$)+/).messages({
      'string.pattern.base': 'Receiver Street must not have special characters. Only comma, dot and hash sign are accepted.'
    }),
    receiver_city: Joi.string().required().label('Receiver City'),
    receiver_first_name: Joi.string().required().regex(/(^[A-Za-z0-9 -.,]+$)+/).label('Receiver First Name').messages({
      'string.pattern.base': 'Receiver First Name must not have special characters.'
    }),
    receiver_last_name: Joi.string().required().regex(/(^[A-Za-z0-9 -.,]+$)+/).label('Receiver Last Name').messages({
      'string.pattern.base': 'Receiver Last Name must not have special characters.'
    })
  })

  return schema.validate(payload, { abortEarly: false })
}
