<template>
  <div class="px-md-2">
    <h2 class="pt-md-6 custom-heading primary--text">
      Success!
    </h2>
    <p class="pt-md-4 pb-md-6">
      Thank you. Pera Load transaction has been completed.
    </p>
    <h3 class="custom-heading">
      Transaction Summary
    </h3>
    <div class="mt-md-6" style="max-width: 350px">
      <v-row no-gutters>
        <v-col v-for="data in summaryData" :key="data.text" :cols="12">
          <div class="d-flex justify-space-between">
            <div>{{ data.text }}</div>
            <div>
              <strong>{{ data.value || '—' }}</strong>
            </div>
          </div>
          <v-divider class="my-md-2" style="border-style: dashed" />
        </v-col>
      </v-row>
    </div>
    <div class="mt-6">
      <v-btn
        @click="backToMain"
        class="rounded-lg"
        color="primary"
        large
      >
        Back to Pera Load
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toMoney } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      transactionData: 'eload/transactionData',
      successData: 'eload/successData'
    }),
    totalAmount () {
      const { product } = this.transactionData
      if (product) {
        return toMoney(parseFloat(product.Amount) + parseFloat(product.Additional))
      } else {
        return toMoney(0)
      }
    },
    summaryData () {
      const fullData = this.transactionData
      const summary = {
        formNumber: { text: 'Reference No.', value: null },
        product: { text: 'Product', value: null },
        type: { text: 'Type', value: null },
        mobileNumber: { text: 'Mobile Number', value: null },
        amount: { text: 'Load', value: null },
        fee: { text: 'Fee', value: null },
        promoCode: { text: 'Promo Code', value: null },
        totalAmount: { text: 'Total Amount', value: null }
      }

      const fields = Object.keys(summary)

      fields.forEach(field => {
        switch (field) {
          case 'product':
            if (fullData.product) {
              summary[field].value = fullData.product.ProductName
            }
            break
          case 'type':
            if (fullData.product) {
              summary[field].value = fullData.product.ProductType
            }
            break
          case 'amount':
            if (fullData.product) {
              summary[field].value = toMoney(parseFloat(fullData.product.Amount))
            }
            break
          case 'fee':
            if (fullData.product) {
              summary[field].value = toMoney(parseFloat(fullData.product.Additional))
            }
            break
          case 'totalAmount':
            if (fullData.product) {
              summary[field].value = this.totalAmount
            }
            break
          default:
            if (fullData[field]) {
              summary[field].value = fullData[field]
            }
            break
        }
      })

      if (fullData.freeLoad === false) delete summary.promoCode
      if (fullData.issueOAR === false) delete summary.formNumber

      return Object.values(summary)
    }
  },
  methods: {
    backToMain () {
      this.$store.dispatch('eload/resetForm')
      this.$router.push({ path: '/pera-load' })
    }
  },
  mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Transaction Successful')
  }
}
</script>

<style scoped>
h3,
p {
  color: #4A4A4A !important;
}
.icon {
  width: 20%;
}
</style>
