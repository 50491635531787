<template>
  <v-form ref="form" class="pt-4">
    <div v-if="mode === 'edit'" class="d-flex align-center mb-10">
      <strong class="d-inline-block mr-2">Status:</strong>
      <v-switch
        v-model="formData.status"
        @change="updatePromoStatus"
        class="d-inline-block mt-0"
        color="primary"
        :label="(formData.status === 1) ? 'Active' : 'Inactive'"
        :disabled="!isEnableToggle"
        :true-value="1"
        :false-value="0"
        :ripple="false"
        hide-details
        inset
      />
    </div>
    <strong>Promo Details</strong>
    <v-row class="mt-2 mb-6">
      <v-col :cols="12" :md="mode === 'new' ? 5 : 4">
        <v-select
          v-model="formData.promoType"
          :items="promoTypes"
          :rules="multipleOptionRules"
          :readonly="!isEnableField"
          class="ph-textbox"
          label="Promo Type"
          multiple
          outlined
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }} &nbsp;</span>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ formData.promoType.length - 1 }} more)</span>
          </template>
        </v-select>
      </v-col>
      <v-col :cols="0" :md="mode === 'new' ? 7 : 8"></v-col>
      <v-col :cols="12" :md="mode === 'new' ? 5 : 4">
        <v-text-field
          v-model="formData.promoName"
          @input="convertToProperCase('promoName')"
          :rules="requiredRules"
          :readonly="!isEnableField"
          class="ph-textbox"
          label="Promo Name"
          outlined
        />
      </v-col>
      <v-col :cols="0" :md="mode === 'new' ? 7 : 8"></v-col>
      <v-col :cols="12" :md="mode === 'new' ? 5 : 4">
        <v-text-field
          v-model="formData.startDate"
          @click:append="startDatePicker = true"
          class="ph-textbox"
          label="Start Date"
          append-icon="mdi-calendar"
          :rules="dateRules"
          :readonly="!isEnableField"
          outlined
        />
        <v-dialog v-model="startDatePicker" width="300">
          <v-date-picker
            v-model="formData.startDate"
            @input="startDatePicker = false"
            :disabled="!isEnableField"
          />
        </v-dialog>
      </v-col>
      <v-col :cols="12" :md="mode === 'new' ? 5 : 4">
        <v-text-field
          v-model="formData.endDate"
          @click:append="endDatePicker = true"
          class="ph-textbox"
          label="End Date"
          append-icon="mdi-calendar"
          :rules="dateRules"
          :readonly="!isEnableField"
          outlined
        />
        <v-dialog v-model="endDatePicker" width="300">
          <v-date-picker
            v-model="formData.endDate"
            @input="endDatePicker = false"
            :disabled="!isEnableField"
          />
        </v-dialog>
      </v-col>
      <v-col :cols="0" :md="mode === 'new' ? 2 : 4"></v-col>
      <v-col :cols="12" :md="mode === 'new' ? 5 : 4">
        <v-select
          v-model="formData.frequency"
          :items="frequencyList"
          :rules="multipleOptionRules"
          :readonly="!isEnableField"
          class="ph-textbox"
          label="Transaction Frequency"
          multiple
          outlined
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }} &nbsp;</span>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >(+{{ formData.frequency.length - 1 }} more)</span>
          </template>
        </v-select>
      </v-col>
      <v-col :cols="0" :md="mode === 'new' ? 7 : 8"></v-col>
      <v-col :cols="12" :md="mode === 'new' ? 5 : 4">
        <v-text-field
          v-model="formData.amountToCredit"
          :rules="requiredRules"
          :readonly="!isEnableField"
          type="number"
          class="ph-textbox"
          label="Peso Amount to Credit"
          hide-spin-buttons
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="mode === 'new' ? 5 : 4">
        <v-text-field
          v-model="formData.minTransactionAmount"
          :rules="requiredRules"
          :readonly="!isEnableField"
          type="number"
          class="ph-textbox"
          label="Minimum Transaction Amount"
          hide-spin-buttons
          outlined
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between">
      <div>
        <v-btn
          v-if="mode === 'new'"
          @click="cancel"
          class="rounded-lg mx-1 font-weight-bold"
          color="primary"
          outlined
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="mode === 'edit'"
          @click="back"
          class="rounded-lg mx-1 font-weight-bold"
          color="primary"
          outlined
        >
          Back
        </v-btn>
      </div>
      <div>
        <v-btn
          @click="confirmUpdate"
          v-if="mode === 'edit'"
          class="rounded-lg mx-1 font-weight-bold"
          color="primary"
          outlined
          :disabled="!isEnableUpdate"
        >
          Save Changes
        </v-btn>
        <v-btn
          @click="confirmDeletion"
          v-if="mode === 'edit'"
          class="rounded-lg mx-1 font-weight-bold"
          :disabled="!this.selectedPromo || this.formData.status === 1"
          color="primary"
          outlined
        >
          Delete
        </v-btn>
        <v-btn
          to="/reports"
          v-if="mode === 'edit'"
          class="rounded-lg mx-1 font-weight-bold"
          :disabled="!this.selectedPromo"
          color="primary"
        >
          View Reports
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="updateDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Update Promo
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to update this promo?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeUpdateDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="savePromo"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Promo
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this promo?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDeleteDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="deletePromo"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { isEqual } from 'lodash'
import { mapGetters } from 'vuex'
import {
  toProperCase,
  renderToast,
  trim
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      formData: 'promos/formData',
      isApprover: 'promos/isApprover',
      selectedPromo: 'promos/selectedPromo',
      promoTypes: 'promos/promoTypes',
      frequencyList: 'promos/frequencyList'
    }),
    isEnableToggle () {
      if (this.isApprover) {
        if (this.isEditMode) return false

        return true
      }

      return false
    },
    isEnableField () {
      if (this.formData.status === 1) {
        if (this.isApprover) return true

        return false
      }

      return true
    },
    isEnableUpdate () {
      if (this.formData.status === 1) {
        if (this.isApprover) {
          if (this.isEditMode) return true

          return false
        }

        return false
      } else {
        if (this.isEditMode) return true

        return false
      }
    },
    isEditMode () {
      if (this.selectedPromo) {
        const prefix = (this.formData.status === 1 ? 'approved_' : 'requested_')
        const oldData = {
          promoType: this.selectedPromo[`${prefix}promo_transaction_type`],
          promoName: this.selectedPromo[`${prefix}promo_name`],
          startDate: this.selectedPromo[`${prefix}start_date`],
          endDate: this.selectedPromo[`${prefix}end_date`],
          frequency: this.selectedPromo[`${prefix}frequency`],
          amountToCredit: this.selectedPromo[`${prefix}incentive_amount`],
          minTransactionAmount: this.selectedPromo[`${prefix}minimum_amount`]
        }
        const newData = {
          promoType: this.formData.promoType,
          promoName: this.formData.promoName,
          startDate: this.formData.startDate,
          endDate: this.formData.endDate,
          frequency: this.formData.frequency,
          amountToCredit: this.formData.amountToCredit,
          minTransactionAmount: this.formData.minTransactionAmount
        }

        return !isEqual(oldData, newData)
      }

      return false
    }
  },
  data () {
    return {
      updateDialog: false,
      deleteDialog: false,
      startDatePicker: false,
      endDatePicker: false,
      dateRules: [
        v => !!v || 'This field is required',
        v => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v) || 'Please enter a valid date (YYYY-MM-DD format)'
      ],
      multipleOptionRules: [
        v => (v !== []) || 'This field is required'
      ],
      requiredRules: [
        v => !!v || 'This field is required'
      ]
    }
  },
  props: {
    mode: {
      type: String,
      default: 'new',
      validator (value) {
        return ['new', 'edit'].includes(value)
      }
    }
  },
  methods: {
    async updatePromoStatus () {
      const payload = {
        approved_by: this.currUser.user_id
      }

      if (this.formData.status === 1) {
        payload.approved_promo_transaction_type = this.formData.promoType
        payload.approved_promo_name = this.formData.promoName
        payload.approved_start_date = this.formData.startDate
        payload.approved_end_date = this.formData.endDate
        payload.approved_incentive_amount = this.formData.amountToCredit
        payload.approved_minimum_amount = this.formData.minTransactionAmount
        payload.approved_frequency = this.formData.frequency
      }
      const req = await this.$store.dispatch('promos/updatePromoStatus', {
        id: this.selectedPromo.promo_id,
        payload
      })

      if (req.code === 200) {
        this.getPromoDetails()
        this.$store.commit('promos/SET_FORM_DATA', { status: (req.result === true) ? 1 : 0 })
        renderToast('success', 'Successful', 'Promo has been ' + ((req.result === true) ? 'activated' : 'deactivated'))
      } else {
        renderToast('error', 'Failed', 'Failed to ' + ((this.formData.status === 1) ? 'activate' : 'deactivate') + ' promo')
        this.$store.commit('promos/SET_FORM_DATA', { status: ((this.formData.status === 1) ? 0 : 1) })
      }
    },
    savePromo () {
      if (this.mode === 'edit') {
        if (this.formData.status === 1) {
          if (this.isApprover) {
            this.updatePromo()
          } else {
            //
          }
        } else {
          this.updatePromo()
        }
      } else {
        this.createPromo()
      }
    },
    async updatePromo () {
      let endpoint = ''
      let payload = {
        updated_by: this.currUser.user_id
      }
      if (this.formData.status === 1) {
        endpoint = 'updateApproverPromo'
        payload = {
          ...payload,
          approved_promo_transaction_type: this.formData.promoType,
          approved_promo_name: this.formData.promoName,
          approved_start_date: this.formData.startDate,
          approved_end_date: this.formData.endDate,
          approved_incentive_amount: this.formData.amountToCredit,
          approved_minimum_amount: this.formData.minTransactionAmount,
          approved_frequency: this.formData.frequency
        }
      } else {
        endpoint = 'updateRequestorPromo'
        payload = {
          ...payload,
          requested_promo_transaction_type: this.formData.promoType,
          requested_promo_name: this.formData.promoName,
          requested_start_date: this.formData.startDate,
          requested_end_date: this.formData.endDate,
          requested_incentive_amount: this.formData.amountToCredit,
          requested_minimum_amount: this.formData.minTransactionAmount,
          requested_frequency: this.formData.frequency
        }
      }

      const req = await this.$store.dispatch(`promos/${endpoint}`, {
        id: this.selectedPromo.promo_id,
        payload
      })

      if (req.code === 200) {
        this.getPromoDetails()
        this.closeUpdateDialog()
        renderToast('success', 'Successful', 'Promo has been updated')
      } else {
        renderToast('error', 'Failed', 'Failed to update promo')
      }
    },
    async createPromo () {
      const payload = {
        requested_promo_transaction_type: this.formData.promoType,
        requested_promo_name: this.formData.promoName,
        requested_start_date: this.formData.startDate,
        requested_end_date: this.formData.endDate,
        requested_incentive_amount: this.formData.amountToCredit,
        requested_minimum_amount: this.formData.minTransactionAmount,
        requested_frequency: this.formData.frequency,
        created_by: this.currUser.user_id
      }
      const req = await this.$store.dispatch('promos/savePromo', payload)

      if (req.code === 200) {
        renderToast('success', 'Successful', 'Promo has been added')
        this.$router.push('/promos')
      } else {
        renderToast('error', 'Failed', 'Failed to add promo')
      }
    },
    confirmUpdate () {
      this.updateDialog = true
    },
    closeUpdateDialog () {
      this.updateDialog = false
    },
    confirmDeletion () {
      this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.deleteDialog = false
    },
    async deletePromo () {
      if (this.selectedPromo) {
        const id = this.selectedPromo.promo_id
        const req = await this.$store.dispatch('promos/deletePromo', id)

        this.closeDeleteDialog()

        if (req.code === 200) {
          renderToast('success', 'Successful', 'Promo has been deleted')
          this.$router.push('/promos')
        } else {
          renderToast('error', 'Failed', 'Failed to delete promo')
        }
      }
    },
    async getPromoDetails () {
      const req = await this.$store.dispatch('promos/getPromo', this.$route.params.id)

      if (req.code === 200) {
        const item = req.result
        const prefix = (item.status === 1 ? 'approved_' : 'requested_')
        this.$store.commit('promos/SET_SELECTED_PROMO', item)
        this.$store.commit('promos/SET_FORM_DATA', {
          promoType: item[`${prefix}promo_transaction_type`],
          promoName: item[`${prefix}promo_name`],
          startDate: item[`${prefix}start_date`],
          endDate: item[`${prefix}end_date`],
          frequency: item[`${prefix}frequency`],
          amountToCredit: item[`${prefix}incentive_amount`],
          minTransactionAmount: item[`${prefix}minimum_amount`],
          status: item.status
        })
      } else {
        //
      }
    },
    cancel () {
      this.back()
    },
    back () {
      this.$store.dispatch('promos/resetForm')
      this.$router.push('/promos')
    },
    convertToProperCase (field) {
      this.formData[field] = toProperCase(trim(this.formData[field]))
    }
  },
  async beforeMount () {
    const promoTypes = await this.$store.dispatch('promos/getPromoTypes')
    const frequencies = await this.$store.dispatch('promos/getFrequencies')

    this.$store.commit('promos/SET_PROMO_TYPES', promoTypes.result.map(item => {
      return {
        text: item.promo_transaction_type_name,
        value: item.promo_transaction_type_id
      }
    }))

    this.$store.commit('promos/SET_FREQUENCIES', frequencies.result.map(item => {
      return {
        text: item.frequency_name,
        value: item.frequency_id
      }
    }))
  }
}
</script>
