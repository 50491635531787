<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-card-title class="ph-nonex-datatable-title">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="ph-textbox ph-textbox-grid-search"
                outlined
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="deposits"
              :search="search"
              :calculate-widths="true"
              class="ph-nonex-datatable"
            >
              <template v-slot:[`item.action`]="{item}">
                <v-btn @click="editDeposit(item)" color="primary" icon v-if="item.status !== 'Posted'">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn color="grey" icon v-else>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="deleteDeposit(item)" color="primary" icon  v-if="item.status !== 'Posted'">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
                <v-btn color="grey" icon  v-else>
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-speed-dial
        v-model="fab"
        dark
        fixed
        bottom
        right
        class="mb-5"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="#1A2791"
            dark
            fab
            @click="addNewDeposit"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
      </v-speed-dial>
    </v-container>
    <CreateDepositModal/>
    <EditDepositModal/>
    <v-dialog
      v-model="deleteDepositDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Deposit
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this deposit data?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDelete"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="doDelete"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CreateDepositModal from './CreateDepositModal.vue'
import EditDepositModal from './EditDepositModal.vue'

import {
  renderToast, aesDecrypt
} from '@/utils'

export default {
  name: 'CICOindex',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'PRP Deposit',
          disabled: false,
          href: '/pera-deposit'
        }
      ],
      headers: [
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date'
        },
        {
          text: 'PRP Name',
          sortable: true,
          value: 'prp_name'
        },
        {
          text: 'Currency',
          sortable: true,
          value: 'currency'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      search: '',
      direction: 'top',
      fab: false,
      hover: false,
      transition: 'slide-y-reverse-transition',
      selectedItem: {},
      deleteDepositDialog: false
    }
  },
  computed: {
    ...mapGetters({
      deposits: 'prpDeposit/deposits'
    })
  },
  components: {
    CreateDepositModal,
    EditDepositModal
  },
  async mounted () {
    const userData = JSON.parse(aesDecrypt(this.$cookies.get('USP_DATA')))
    this.$store.dispatch('prpDeposit/getDeposit', userData.location_code)
  },
  methods: {
    renderToast,
    addNewDeposit () {
      this.resultDialog = false
      this.$store.commit('prpDeposit/SET_DEPOSIT_CREATION_MODAL', true)
    },
    editDeposit (data) {
      this.$store.commit('prpDeposit/SET_DEPOSIT_UPDATE_MODAL', true)
      this.$store.commit('prpDeposit/SET_EDITED_ITEM', data)
    },
    deleteDeposit (data) {
      this.selectedItem = data
      this.deleteDepositDialog = true
    },
    closeDelete () {
      this.selectedItem = {}
      this.deleteDepositDialog = false
    },
    async doDelete () {
      const userData = JSON.parse(aesDecrypt(this.$cookies.get('USP_DATA')))
      if (this.selectedItem.id) {
        const req = await this.$store.dispatch('prpDeposit/deleteDeposit', this.selectedItem.id)

        if (req.code === 200) {
          this.selectedItem = {}
          this.renderToast('success', 'Success!', 'Deposit Deletion Successful')
          this.$store.dispatch('prpDeposit/getDeposit', userData.location_code)
          this.deleteDepositDialog = false
        } else if (req.code === 403) {
          this.renderToast('error', req.message, req.error.message)
        } else {
          this.renderToast('error', 'Error', 'An error occured while deleteing the selected deposit. Please Try Again.')
        }
      }
    }
  }
}
</script>
<style scoped>
/* .ph-nonex-datatable-title {
  padding-left: 0;
} */

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.7);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

.ph-nonex-table-names-text.text-amount {
  margin-left: 0 !important;
}

.text-inquire-button {
  cursor: pointer;
}

/* .ph-textbox.ph-textbox-grid-search {
  width: 256px;
} */
</style>
