<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col>
        <PromoDetails ref="promoForm" mode="edit" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import PromoDetails from '@/components/promos/PromoDetails.vue'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      selectedPromo: 'promos/selectedPromo'
    })
  },
  components: {
    PromoDetails
  },
  data () {
    return {
      breadcrumbsItems: [
        {
          text: 'Promos',
          disabled: false,
          href: '/promos'
        },
        {
          text: 'Promo Details',
          disabled: true
        }
      ]
    }
  },
  async beforeMount () {
    if (!this.selectedPromo) {
      const req = await this.$store.dispatch('promos/getPromo', this.$route.params.id)

      if (req.code === 200) {
        const item = req.result
        const prefix = (item.status === 1 ? 'approved_' : 'requested_')
        this.$store.commit('promos/SET_SELECTED_PROMO', item)
        this.$store.commit('promos/SET_FORM_DATA', {
          promoType: item[`${prefix}promo_transaction_type`],
          promoName: item[`${prefix}promo_name`],
          startDate: item[`${prefix}start_date`],
          endDate: item[`${prefix}end_date`],
          frequency: item[`${prefix}frequency`],
          amountToCredit: item[`${prefix}incentive_amount`],
          minTransactionAmount: item[`${prefix}minimum_amount`],
          status: item.status
        })
        this.$refs.promoForm.$refs.form.resetValidation()
      } else {
        //
      }

      const res = await this.$store.dispatch('promos/checkApprover', { user_id: this.currUser.user_id })

      if (res.code === 200 && res.result) {
        this.$store.commit('promos/SET_IS_APPROVER', true)
      } else {
        this.$store.commit('promos/SET_IS_APPROVER', false)
      }
    }
  }
}
</script>
