import api from '@/api'

export default {
  retrieveData (trxDate, currencyId, locationId) {
    return api.get(`/v1/maintenance/api/maintenance/mc-rate-grid?trx_date=${trxDate}&currency_id=${currencyId}&location_id=${locationId}`)
  },
  saveData (payload) {
    return api.post('/v1/maintenance/api/maintenance/mc-rate-insert', payload)
  },
  getRate (locationId, currencyId, trxDate) {
    return api.get(`/v1/maintenance/api/maintenance/trx-mc-get-rate?location_id=${locationId}&currency_id=${currencyId}&trx_date=${trxDate}`)
  }
}
