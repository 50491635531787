import api from '@/api'

export default {
  retrieveData (trxDate) {
    return api.get(`/v1/maintenance/api/maintenance/mc-rate-general-grid?trx_date=${trxDate}`)
  },
  saveData (payload) {
    return api.post('/v1/maintenance/api/maintenance/mc-rate-general-insert', payload)
  },
  updateStatus (payload) {
    return api.post('/v1/maintenance/api/maintenance/mc-rate-update-status', payload)
  }
}
