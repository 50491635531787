<template>
  <v-container fluid>
    <TrailGeneration v-if="currentPage === 0" />
    <TrailRecords v-if="currentPage === 1" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import TrailGeneration from '@/views/Compliance/AuditTrail/TrailGeneration.vue'
import TrailRecords from '@/views/Compliance/AuditTrail/TrailRecords.vue'

export default {
  computed: {
    ...mapGetters({ currentPage: 'compliance/currentPage' })
  },
  components: { TrailGeneration, TrailRecords },
  mounted () {
    // TODO: Reset everthing here
    this.$store.commit('compliance/SET_CURRENT_PAGE', 0)
  }
}
</script>
