export default {
  SET_PH_ID (state, payload) {
    state.phID = payload
  },
  SET_HAS_AGENT (state, payload) {
    state.hasAgent = payload
  },
  SET_CURRENT_PAGE (state, payload) {
    state.currentPage = payload
  },
  SET_CURRENT_STEP (state, payload) {
    state.currentStep = payload
  },
  SET_RECEIVER_LIST (state, payload) {
    state.receiverList = payload
  },
  SET_FEE_INQUIRY_DATA (state, payload) {
    state.feeInquiryData = payload
  },
  SET_KYC_LOOKUP_DATA (state, payload) {
    state.kycLookupData = payload
  },
  SET_VALIDATE_DATA (state, payload) {
    state.validateData = payload
  },
  SET_STORE_DATA (state, payload) {
    state.storeData = payload
  },
  SET_REMOTE_DATA (state, payload) {
    state.remoteData = payload
  },
  SET_REMOTE_DETAILS (state, { prop, value }) {
    state.remoteDetails[prop] = value
  },
  SET_REMCO_FEE (state, { id, serviceFee, body }) {
    const index = state.remcoList.findIndex(item => item.id === id)
    if (index !== -1) {
      state.remcoList[index].fee = serviceFee
      if (body) state.remcoList[index].body = body
    }
  },
  SET_TRANSACTION_DATA (state, { prop, value }) {
    state.transactionData[prop] = value
  },
  SET_PAYOUT_COUNTRIES_DATA (state, payload) {
    state.payoutCountryList = payload
  },
  SET_DELIVERY_SERVICE_TYPES (state, payload) {
    state.deliveryServiceTypes = payload
  },
  SET_FIELDS_LIST (state, payload) {
    state.fieldsList = payload
  },
  SET_COMBOS_LIST (state, payload) {
    state.combosList = payload
  }
}
