import api from '@/api/treasury.js'

export default {
  getBranches (payload) {
    return api.get('/integration/prp-information/branch/' + payload)
  },
  getPRP () {
    return api.get('/integration/prp-information')
  },
  getPRPDeposit (payload) {
    return api.get('/integration/deposit?loc_idno=' + payload)
  },
  addDeposit (payload) {
    return api.post('/integration/deposit', payload,
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  updateDeposit (id, payload) {
    return api.post(`/integration/deposit/${id}`, payload, { params: { _method: 'PUT' } },
      {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      })
  },
  deleteDeposit (id) {
    return api.delete(`/integration/deposit/${id}`)
  }
}
