<template>
  <v-row>
    <v-col :cols="12" :md="3" v-for="(id, index) in ids" :key="index">
      <h3 class="mb-4">ID #{{ index + 1 }}</h3>
      <v-row>
        <v-col style="height: 200px !important" :cols="12" class="pb-2 pb-sm-4 text-center">
          <img style="height: 200px !important" :src="getStoragePath(id)" alt="">
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="id.id_type"
            class="ph-textbox"
            color="gray darken-1"
            label="ID Type"
            dense
            outlined
            readonly
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="id.id_number"
            class="ph-textbox"
            color="gray darken-1"
            label="ID Number"
            dense
            outlined
            readonly
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="id.issue"
            class="ph-textbox"
            color="gray darken-1"
            label="ID Issue Date"
            dense
            outlined
            readonly
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="id.expiry"
            class="ph-textbox"
            color="gray darken-1"
            label="ID Expiry Date"
            dense
            outlined
            readonly
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="id.country"
            class="ph-textbox"
            color="gray darken-1"
            label="Issuing Country"
            dense
            outlined
            readonly
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col :cols="12" class="text-center" v-if="ids.length === 0">
      <span class="caption">
        Nothing to display
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { aesDecrypt } from '@/utils'

export default {
  computed: {
    ids () {
      let img = this.customerInfo.img || '{}'
      img = JSON.parse(img)
      return Object.values(img)
    }
  },
  props: {
    customerInfo: {
      type: Object,
      default: null
    },
    customerTransactions: {
      type: Array,
      default: null
    },
    customerRiskLog: {
      type: Array,
      default: null
    }
  },
  methods: {
    getStoragePath (id) {
      if (id.usp2 === 'true') {
        return `${aesDecrypt(process.env.VUE_APP_STORAGE_BASE_URL)}/${this.$route.params.id}/${id.img}`
      } else {
        return `${aesDecrypt(process.env.VUE_APP_STORAGE_USP1_URL)}/${this.$route.params.id}/${id.img}`
      }
    }
  }
}
</script>
