<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mb-3 mx-2">
      <v-col :cols="3">
        <v-form ref="form">
          <v-row class="mt-1">
            <v-col :cols="12">
              <v-menu
                v-model="isDatePickerOpened"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.date"
                    :rules="dateRules"
                    class="ph-textbox"
                    label="Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    clearable
                  />
                </template>
                <v-date-picker
                  v-model="formData.date"
                  :min="minDate"
                  @input="isDatePickerOpened = false"
                />
              </v-menu>
            </v-col>
            <v-col :cols="12">
              <v-select
                v-model="formData.currencyId"
                :items="collections.currencies"
                class="ph-textbox"
                label="Currency"
                :rules="requiredRules"
                disabled
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-select
                v-model="formData.category"
                :items="categories"
                class="ph-textbox"
                label="Rate Category"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.buyingRate"
                class="ph-textbox"
                label="Buying Rate"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.sellingRate"
                class="ph-textbox"
                label="Selling Rate"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <h5 class="custom-heading primary--text mb-3">
                FOR REFERENCE
              </h5>
              <v-text-field
                v-model="formData.tradeRate"
                class="ph-textbox"
                label="Trade Rate"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.quantity"
                class="ph-textbox"
                label="Quantity"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="saveData" class="font-weight-bold" color="primary" block x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  addTimestamp,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections'
    }),
    minDate () {
      return this.currUser.trx_date
    }
  },
  data () {
    return {
      isDatePickerOpened: false,
      requiredRules: [v => !!v || 'This field is required'],
      dateRules: [
        v => !!v || 'This field is required',
        v => (!v || (!!v && /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v))) || 'Use YYYY-MM-DD format'
      ],
      formData: {
        date: '',
        currencyId: '',
        category: '',
        buyingRate: '',
        sellingRate: '',
        tradeRate: '',
        quantity: ''
      },
      breadcrumbsItems: [
        {
          text: 'MC Rate',
          disabled: false,
          exact: true,
          to: '/mc-rate'
        },
        {
          text: 'General Setup',
          disabled: false,
          exact: true,
          to: '/mc-rate/setup'
        },
        {
          text: 'New Entry',
          disabled: true
        }
      ],
      categories: [
        { text: 'Updated Rate', value: 'UPDATED RATE' },
        { text: 'Special Rate', value: 'SPECIAL RATE' }
      ]
    }
  },
  methods: {
    async saveData () {
      if (this.$refs.form.validate()) {
        try {
          const { code } = await this.$store.dispatch('mcRate/saveData', {
            trx_date: this.formData.date,
            currency_id: this.formData.currencyId,
            category: this.formData.category,
            buy_am_rate: this.formData.buyingRate,
            buy_pm_rate: 0,
            buy_special_rate: 0,
            sell_am_rate: this.formData.sellingRate,
            sell_pm_rate: 0,
            sell_special_rate: 0,
            updated_by: this.currUser.user_id,
            date_updated: addTimestamp(this.currUser.trx_date),
            trade_rate: this.formData.tradeRate,
            quantity: this.formData.quantity
          })

          if (code === 200) {
            renderToast('success', 'Successful', 'MC Rate has been saved')
            this.cancel()
          } else {
            renderToast('error', 'Request Error', 'Failed saving the given data')
          }
        } catch (e) {
          renderToast('error', 'USP Error', 'Failed saving the given data')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill out all required fields')
      }
    },
    cancel () {
      this.$router.push('/mc-rate/setup')
    }
  },
  mounted () {
    this.formData.currencyId = 2
  }
}
</script>
