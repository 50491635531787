export default {
  loanAccountData: {},
  tpaId: '',
  loanCollectionTransactions: [],
  loanCollectionCurrentTrx: null,
  loanCollectionCurrentPage: 0,
  collectionProviders: [],
  loanCollectionResponseData: null,
  loanAccountNo: ''
}
