<template>
  <v-dialog
    v-model="pnaAlertDialog.isShow"
    :width="pnaAlertDialog.isFalsePositive ? '70%' : '40%'"
    persistent
  >
    <v-card class="px-2 py-3 pb-6">
      <v-card-title class="primary--text">
        <h2 class="title ph-title" style="font-size: 24px !important;">
          Compliance
        </h2>
      </v-card-title>
      <v-card-text class="pt-6" style="font-size: 14px !important;">
        <v-row>
          <v-col :cols="pnaAlertDialog.isFalsePositive ? 8 : 12">
            <NegativeListMessage v-if="pnaAlertDialog.type === 'FOUND_NEGATIVE_LIST'" />
            <AdverseMessage v-if="pnaAlertDialog.type === 'FOUND_ADVERSE'" />
            <PEPMessage v-if="pnaAlertDialog.type === 'FOUND_PEP'" />
          </v-col>
          <v-col :cols="4" v-if="pnaAlertDialog.isFalsePositive">
            <v-form ref="uploadForm">
              <v-row class="pr-3">
                <v-col :cols="12">
                  <v-autocomplete
                    v-model="formData.idType"
                    :rules="requiredField"
                    :items="collections.idList"
                    item-text="id_type"
                    item-value="id_value"
                    label="ID Type"
                    color="gray darken-1"
                    class="ph-textbox"
                    dense
                    outlined
                  />
                </v-col>
                <v-col :cols="12">
                  <v-text-field
                    v-model="formData.idNumber"
                    :rules="idNumberValidator(formData.idNumber, formData.idType, formData.idCountry)"
                    class="ph-textbox"
                    color="gray darken-1"
                    label="ID Number"
                    dense
                    outlined
                  />
                </v-col>
                <v-col :cols="12">
                  <v-menu
                    v-model="issueDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.idIssue"
                        :rules="dateRules"
                        class="ph-textbox"
                        color="gray darken-1"
                        label="Issue Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                      />
                    </template>
                    <v-date-picker
                      v-model="formData.idIssue"
                      @input="syncIssueDate()"
                    />
                  </v-menu>
                </v-col>
                <v-col :cols="12">
                  <v-menu
                    v-model="expiryDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.idExpiry"
                        :rules="dateRules"
                        class="ph-textbox"
                        color="gray darken-1"
                        label="Expiry Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                      />
                    </template>
                    <v-date-picker
                      v-model="formData.idExpiry"
                      @input="syncExpiryDate()"
                    />
                  </v-menu>
                </v-col>
                <v-col :cols="12">
                  <v-autocomplete
                      v-model="formData.idCountry"
                      :rules="requiredField"
                      :items="collections.countries"
                      item-text="country"
                      item-value="country"
                      label="Country of Issuance"
                      color="gray darken-1"
                      class="ph-textbox"
                      dense
                      outlined
                    />
                </v-col>
                <v-col :cols="12">
                  <FileUploader accept="image/*" guideText="image (.png, .jpg, .webp)" @onFileChange="handleFile" />
                  <span class="red--text" v-if="showUploadValidationMessage">This field is required</span>
                </v-col>
                <v-col :cols="12" v-if="uploadedFile">
                  <v-row class="d-flex flex-column align-center">
                    <v-col :cols="12">
                      <img
                        class="rounded-lg"
                        style="max-width: 100%;"
                        :src="uploadedFile"
                        alt=""
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="12" v-else>
                  <v-card style="min-height: 100px" class="rounded-lg text-center" outlined>
                    <v-card-text>
                      <v-row class="d-flex flex-column align-center">
                        <v-col :cols="12">
                          <span>Image Preview</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-8">
        <v-btn
          @click="cancel"
          color="primary"
          class="ph-action-button px-4 "
          outlined
          x-large
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          @click="ok"
          color="primary"
          elevation="0"
          class="ph-action-button px-4 "
          x-large
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { idNumberValidator, renderToast } from '@/utils'
import NegativeListMessage from '@/components/compliance/NegativeListMessage.vue'
import AdverseMessage from '@/components/compliance/AdverseMessage.vue'
import PEPMessage from '@/components/compliance/PEPMessage.vue'
import FileUploader from '@/components/partials/FileUploader.vue'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections',
      pnaAlertDialog: 'compliance/pnaAlertDialog',
      riskData: 'compliance/riskData'
    })
  },
  data () {
    return {
      showUploadValidationMessage: false,
      expiryDatePicker: false,
      issueDatePicker: false,
      uploadedFile: null,
      requiredField: [v => !!v || 'This field is required'],
      dateRules: [v => (!v || (!!v && /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v))) || 'Use YYYY-MM-DD format'],
      formData: {
        idFile: null,
        idType: '',
        idNumber: '',
        idExpiry: '',
        idIssue: '',
        idCountry: 'Philippines'
      }
    }
  },
  components: {
    NegativeListMessage,
    AdverseMessage,
    PEPMessage,
    FileUploader
  },
  methods: {
    validateUploadedFile () {
      const hasFile = !(this.uploadedFile === null)

      if (!hasFile) this.showUploadValidationMessage = true

      return hasFile
    },
    handleFile ([file]) {
      const that = this
      const reader = new FileReader()
      reader.onload = function ({ target: { result } }) {
        that.uploadedFile = result
      }
      reader.readAsDataURL(file)
      this.formData.idFile = file
    },
    async ok () {
      let falsePositive = ''
      const riskCriteria = []
      const trx = { domInt: '', type: '', serviceId: '' }

      if (this.$route.fullPath.includes('pera-remit/send')) {
        trx.domInt = 'DMT'
        trx.type = 'WU Sendout'
        trx.serviceId = '1'
      }

      if (this.$route.fullPath.includes('pera-remit/receive')) {
        trx.domInt = 'IMT'
        trx.type = 'WU Receive'
        trx.serviceId = '1'
      }

      if (this.$route.fullPath.includes('pera-palit')) {
        trx.domInt = 'MC'
        trx.type = 'MC'
        trx.serviceId = '2'
      }

      if (this.$route.fullPath.includes('pera-cico/cash-in')) {
        trx.domInt = 'CICO'
        trx.type = 'CICO CashIN'
        trx.serviceId = '3'
      }

      if (this.$route.fullPath.includes('pera-cico/cash-out')) {
        trx.domInt = 'CICO'
        trx.type = 'CICO CashOUT'
        trx.serviceId = '3'
      }

      if (this.pnaAlertDialog.isFalsePositive) {
        switch (this.pnaAlertDialog.type) {
          case 'FOUND_ADVERSE':
            falsePositive = '2'
            break
          case 'FOUND_PEP':
            falsePositive = '1'
            break
          case 'FOUND_NEGATIVE_LIST':
            falsePositive = '3'
            break
        }
      }

      let riskCategory = ''

      switch (this.pnaAlertDialog.type) {
        case 'FOUND_ADVERSE':
          if (this.pnaAlertDialog.customer.risk_score === 3 || this.pnaAlertDialog.customer.risk_score === 4) {
            riskCategory = 'HighRiskStop'
          }

          riskCriteria.push({
            criteria: 'Fraudsters',
            reason: 'Fraudsters',
            risk_score: 4,
            risk_category: riskCategory,
            type_risk: false
          })
          break
        case 'FOUND_PEP':
          if (this.pnaAlertDialog.customer.edd_upload_status !== 3 && this.pnaAlertDialog.customer.risk_score === 3) {
            riskCategory = 'HighRiskGo'
          }

          riskCriteria.push({
            criteria: 'PEP',
            reason: this.pnaAlertDialog.details.link_pep_name || '',
            risk_score: this.pnaAlertDialog.details.risk_score || 3,
            risk_category: riskCategory,
            type_risk: false
          })
          break
        case 'FOUND_NEGATIVE_LIST':
          if (this.pnaAlertDialog.customer.edd_upload_status !== 3 && this.pnaAlertDialog.customer.risk_score === 3) {
            riskCategory = 'HighRiskGo'
          }

          if (this.pnaAlertDialog.customer.risk_score === 4) {
            riskCategory = 'HighRiskStop'
          }

          riskCriteria.push({
            criteria: 'Negative List',
            reason: this.pnaAlertDialog.details.predicate_crime || '',
            risk_score: this.pnaAlertDialog.details.risk_score || 3,
            risk_category: riskCategory,
            type_risk: false
          })
          break
      }

      await this.$store.dispatch('compliance/saveRisk', {
        trans_details: {
          dom_int: trx.domInt,
          trx_type: trx.type,
          trx_date: this.currUser.trx_date,
          customer_name: this.pnaAlertDialog.customer.full_name,
          risk_score: this.pnaAlertDialog.details.risk_score || 4,
          curr_risk_score: this.pnaAlertDialog.customer.risk_score || 1,
          risk_criteria: JSON.stringify(riskCriteria),
          customer_id: this.pnaAlertDialog.customer.customer_id,
          monitoring_type: '2',
          principal_amount: ''
        },
        location_name: this.currUser.location_name,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        service_id: trx.serviceId,
        update_status_by: this.currUser.user_id,
        first_name: this.pnaAlertDialog.customer.first_name,
        last_name: this.pnaAlertDialog.customer.last_name,
        compliance_remarks: this.pnaAlertDialog.customer.compliance_edd_remarks,
        fp_remarks: '',
        false_positive: falsePositive,
        edd_alert: 'true',
        _is_not_fraud: this.pnaAlertDialog.isFalsePositive ? '1' : '0',
        criteria: JSON.stringify(riskCriteria),
        reference_no: '',
        actual_date_trx: this.currUser.trx_date
      })

      if (this.pnaAlertDialog.isFalsePositive) {
        this.$store.commit('compliance/UPDATE_RISK_DATA', {
          pnaAlertDialog: this.pnaAlertDialog,
          falsePositiveID: { ...this.formData }
        })
        if (this.$refs.uploadForm.validate() && this.validateUploadedFile()) {
          const payload = new FormData()
          const dateToday = format(new Date(), 'yyyyMMdd')
          const randomNumber = Math.floor(Math.random() * (999 - 100 + 1)) + 100
          const fileName = `${dateToday}${randomNumber}.png`
          payload.append('photo', this.riskData.falsePositiveID.idFile)
          payload.append('filename', fileName)
          payload.append('customer_id', this.riskData?.pnaAlertDialog?.customer?.customer_id || '0')

          const { code } = await this.$store.dispatch('compliance/savePhoto', payload)

          if (code === 200) {
            const newImg = {}
            let img = this.riskData?.pnaAlertDialog?.customer?.img || '{}'
            img = JSON.parse(img)
            const ids = Object.values(img)
            ids.reverse()
            ids.push({
              img: fileName,
              id_type: this.riskData.falsePositiveID.idType,
              id_number: this.riskData.falsePositiveID.idNumber,
              country: this.riskData.falsePositiveID.idCountry,
              expiry: this.riskData.falsePositiveID.idExpiry,
              issue: this.riskData.falsePositiveID.idIssue,
              usp2: 'true'
            })
            ids.reverse()
            ids.forEach((value, index) => {
              newImg[index + 1] = value
            })

            await this.$store.dispatch('customers/doUpdate', {
              first_name: this.pnaAlertDialog?.customer?.first_name,
              last_name: this.pnaAlertDialog?.customer?.last_name,
              customer_id: this.pnaAlertDialog?.customer?.customer_id,
              img: JSON.stringify(newImg)
            })

            renderToast('warning', 'Request Sent', 'Request has been submitted and waiting for approval')
          }

          this.cancel()
        } else {
          renderToast('warning', 'Validation Error', 'Please fill out all required fields')
        }
      } else {
        this.cancel()
      }
    },
    cancel () {
      this.formData = {
        idFile: null,
        idType: '',
        idNumber: '',
        idExpiry: '',
        idIssue: '',
        idCountry: 'Philippines'
      }
      this.uploadedFile = null
      this.$store.dispatch('compliance/resetPNAAlertDialog')
    },
    syncExpiryDate () {
      this.expiryDatePicker = false
    },
    syncIssueDate () {
      this.issueDatePicker = false
    },
    idNumberValidator
  },
  beforeMount () {
    this.$store.dispatch('getIDList')
  }
}
</script>
