import api from '@/api'

export default {
  retrieveData (trxDate, locationId, userId) {
    return api.get(`/v1/maintenance/api/maintenance/trx-user-cash-transfer-grid?trx_date=${trxDate}&location_id=${locationId}&user_id=${userId}`)
  },
  saveData (payload) {
    return api.post('/v1/maintenance/api/maintenance/trx-user-cash-transfer-insert', payload)
  },
  getUsers (trxDate, locationId) {
    return api.get(`/v1/maintenance/api/maintenance/users-grid-cash-transfer?trx_date=${trxDate}&location_id=${locationId}`)
  }
}
