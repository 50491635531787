import { endpoints } from '@/api/endpoints'

const promos = endpoints.use('promos')

export default {
  getPromos: ({ commit }) => new Promise((resolve, reject) => {
    promos.getPromos()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getPromo: ({ commit }, id) => new Promise((resolve, reject) => {
    promos.getPromo(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  savePromo: ({ commit }, payload) => new Promise((resolve, reject) => {
    promos.savePromo(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateApproverPromo: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    promos.updateApproverPromo(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateRequestorPromo: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    promos.updateRequestorPromo(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deletePromo: ({ commit }, id) => new Promise((resolve, reject) => {
    promos.deletePromo(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updatePromoStatus: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    promos.updatePromoStatus(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getPromoTypes: ({ commit }) => new Promise((resolve, reject) => {
    promos.getPromoTypes()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getFrequencies: ({ commit }) => new Promise((resolve, reject) => {
    promos.getFrequencies()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  checkApprover: ({ commit }, payload) => new Promise((resolve, reject) => {
    promos.checkApprover(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  resetForm ({ state, commit }) {
    Object.keys(state.formData).forEach(prop => {
      let value = ''

      if (prop === 'promoType' || prop === 'frequency') value = []
      if (prop === 'status') value = 0

      commit('SET_FORM_DATA', { [prop]: value })
    })
  }
}
