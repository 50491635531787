<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-card-title class="ph-nonex-datatable-title">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="ph-textbox ph-textbox-grid-search"
                outlined
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="transactions"
              :search="search"
              :calculate-widths="true"
              class="ph-nonex-datatable"
            >
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDateTime(item.created_at) }}
              </template>
              <template v-slot:[`item.sender_name`]="{item}">
                <div class="ph-nonex-sender-container">
                  <!-- <v-avatar
                    color="#2563EB"
                    size="24"
                    class="ph-nonex-table-avatar"
                  >
                    <span class="white--text">{{item.sender_name.substring(0, 1)}}</span>
                  </v-avatar> -->
                  <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.sender_name)">
                    <span class="white--text text-body-2">{{ avatarInitial(item.sender_name) }}</span>
                  </v-avatar>
                  <p class="ph-nonex-table-names-text">{{item.sender_name}}</p>
                </div>
              </template>
              <template v-slot:[`item.receiver_name`]="{item}">
                <div class="ph-nonex-receiver-container">
                  <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.receiver_name)">
                    <span class="white--text text-body-2">{{ avatarInitial(item.receiver_name) }}</span>
                  </v-avatar>
                  <p class="ph-nonex-table-names-text">{{item.receiver_name}}</p>
                </div>
              </template>
              <template v-slot:[`item.non_ex_name`]="{item}">
                <div class="ph-nonex-receiver-container">
                  <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.non_ex_name)">
                    <span class="white--text text-body-2">{{ avatarInitial(item.non_ex_name) }}</span>
                  </v-avatar>
                  <p class="ph-nonex-table-names-text">{{item.non_ex_name}}</p>
                </div>
              </template>
              <template v-slot:[`item.principal_amount`]="{item}">
                <p class="ph-nonex-table-names-text">{{item.currency_id == 1 ? 'PHP' : 'USD'}} {{item.principal_amount}}</p>
              </template>
              <template v-slot:[`item.currency_id`]="{item}">
                <p class="ph-nonex-table-names-text">{{item.currency_id == 1 ? 'PHP' : 'USD'}}</p>
              </template>
               <template v-slot:[`item.total_amount`]="{item}">
                <p class="ph-nonex-table-names-text">{{item.currency_id == 1 ? 'PHP' : 'USD'}} {{item.total_amount}}</p>
              </template>
              <!-- <template v-slot:[`item.status`]="{item}">
                <div class="ph-nonex-status-container">
                  <div :class="{'ph-nonex-status-circle': true, 'ph-nonex-status-success': item.status === 0, 'ph-nonex-status-pending': item.status === 'Pending'}"></div>
                  <p :class="{'ph-nonex-status-text': true, 'ph-nonex-status-success-text': item.status === 1, 'ph-nonex-status-pending-text': item.status === 'Pending'}">{{item.status}}</p>
                </div>
              </template> -->
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.status === 0 || item.status === '0'">
                  <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.status === 1 || item.status === '1'">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Successful
                </span>
              </template>
              <template v-slot:[`item.action`]="{item}">
                <v-menu
                  bottom
                  left
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.status === 1"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-if="item.status === 1" @click="reprintACRNoWacom(item)">
                      <v-list-item-title>Print ACR</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="item.status === 1 && !checkIfUploadedPre(item) && item.remco_id === 0" @click="retrieveUploaded(item, 'pre')">
                      <v-list-item-title>Print Pre Print</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="item.status === 1 && !checkIfUploaded(item)" @click="retrieveUploaded(item, 'acr')">
                      <v-list-item-title>Print Uploaded ACR</v-list-item-title>
                    </v-list-item>
                    <ACRUpload v-show="checkIfUploaded(item)" :item="item"/>
                  </v-list>
                </v-menu>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="doRetryPayout(item)"
                      v-if="item.status === 0 || item.status === '0'"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>Retry</span>
                </v-tooltip>
                <div class="ph-nonex-action-container" v-if="false">
                  <v-btn
                    icon
                    color="primary"
                    v-if="item.status === 1 && !checkIfUploaded(item)"
                    @click="retrieveUploaded(item)"
                  >
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="primary"
                    v-if="item.status === 1 && checkIfUploaded(item)"
                    @click="reprintACRNoWacom(item)"
                  >
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="primary"
                    @click="doRetryPayout(item)"
                    v-if="item.status === 0 || item.status === '0'"
                    >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                  <ACRUpload v-show="checkIfUploaded(item)" :item="item"/>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-speed-dial
        v-model="fab"
        dark
        fixed
        bottom
        right
        class="mb-5"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="#1A2791"
            dark
            fab
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="newReceiveMoneyFulfillOBO"
              color="#1A2791"
              v-bind="attrs"
              v-on="on"
              small
              fab
            >
              <img src="@/assets/images/send-money-obo-icon.svg" width="18" />
            </v-btn>
          </template>
          <span>Fulfill Receive Money for Different Location</span>
        </v-tooltip>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="#1A2791"
              v-bind="attrs"
              v-on="on"
              to="/pera-remit/receive/new-fulfill-trx"
            >
              <v-img class="ph-fulfill-logo" src="@/assets/images/wu-fulfill-receive-icon.svg"></v-img>
            </v-btn>
          </template>
          <span>Fulfill Receive Money</span>
        </v-tooltip>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="#8B51FF"
              v-bind="attrs"
              v-on="on"
              to="/pera-remit/receive/new"
            >
              <v-img class="ph-nonex-logo" src="@/assets/images/nonex-receive-icon-white.svg"></v-img>
            </v-btn>
          </template>
          <span>Receive Money</span>
        </v-tooltip>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="newReceiveMoneyOBO"
              color="#05ACE5"
              v-bind="attrs"
              v-on="on"
              small
              fab
            >
              <img src="@/assets/images/send-money-obo-icon.svg" width="18" />
            </v-btn>
          </template>
          <span>WU Receive Money for Different Location</span>
        </v-tooltip>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              light
              small
              color="black"
              v-bind="attrs"
              v-on="on"
              to="/pera-remit/receive/new-wu-trx"
            >
              <v-img class="ph-wu-logo" src="@/assets/images/wu-logo.svg"></v-img>
            </v-btn>
          </template>
          <span>WU Receive Money</span>
        </v-tooltip>
      </v-speed-dial>
    </v-container>
    <v-dialog
      v-model="successDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Successful
        </v-card-title>

        <v-card-text>
          <br>
          <p><b>The print receipt window will open in 3 seconds.</b></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="printReceipt"
          >
            Print Receipt
            &nbsp;
            <v-progress-circular
              :rotate="360"
              :value="progressValue"
              color="white"
            >
              {{ countdown }}
            </v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import { Buffer } from 'buffer/'
import WacomSDK from '../../../assets/js/wacom/wgssStuSdk.js'
import ACRUpload from '@/components/customers/ACRUpload'
import wacomMixin from '@/mixins/wacom.js'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'ReceiveMoney',
  mixins: [wacomMixin],
  components: {
    ACRUpload
  },
  data () {
    return {
      base64String: '',
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Receive',
          disabled: true,
          href: '#'
        }
      ],
      filteredACRs: [],
      search: '',
      headers: [
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'created_at'
        },
        {
          text: 'MTCN/ Ref No.',
          sortable: true,
          value: 'control_number'
        },
        {
          text: 'Sender\'s Name',
          sortable: true,
          value: 'sender_name'
        },
        {
          text: 'Receiver\'s Name',
          sortable: true,
          value: 'receiver_name'
        },
        {
          text: 'Partner',
          sortable: true,
          value: 'non_ex_name'
        },
        {
          text: 'Payout Amount',
          sortable: true,
          value: 'principal_amount'
        },
        {
          text: 'Payout Currency',
          sortable: true,
          value: 'currency_id'
        },
        {
          text: 'DST',
          sortable: true,
          value: 'service_charge'
        },
        {
          text: 'Net Amount',
          sortable: true,
          value: 'total_amount'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      successDialog: false,
      interval: {},
      progressValue: 0,
      countdown: 3,
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      transition: 'slide-y-reverse-transition',
      customerSignature: ''
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'nonex/transactions',
      collections: 'collections',
      currUser: 'auth/currUser',
      remcos: 'nonex/remcos'
    })
  },
  watch: {
    countdown: {
      handler (count) {
        if (count === 0) {
          const that = this
          setTimeout(function () {
            that.progressValue = 0
            that.successDialog = false
          }, 1000)

          clearInterval(this.interval)
        }
      }
    }
    // transactions: {
    //   handler (newVal) {
    //     if (newVal) {
    //       this.checkUploadedFiles()
    //     }
    //   },
    //   immediate: true
    // }
  },
  async beforeMount () {
    this.getTransactions()
    await this.$store.dispatch('nonex/getRemcos')
  },
  async mounted () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    const acrs = await this.$store.dispatch('acr/retrieveFilteredACR',
      {
        location_id: this.currUser.location_id,
        trx_date: this.currUser.trx_date.replace(' 00:00:00', ''),
        user_id: this.currUser.user_id
      })
    this.filteredACRs = acrs.data
    this.checkUploadedFiles()

    this.$store.commit('nonex/SET_INQUIRE_RESULT', [])
    this.$store.commit('nonex/SET_CURRENT_TRANSACTION', {})
    this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
    this.$store.commit('customers/SET_SEARCH_RESULT', null)
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    checkIfMoneygram (ctlNo) {
      if (ctlNo.substr(0, 2) !== 'FT' && ctlNo.substr(0, 3) !== 'ALB' && ctlNo.substr(0, 5) !== 'CMTMY' && ctlNo.substr(0, 5) !== 'CMTTH' && ctlNo.substr(0, 1) !== 'X' && ctlNo.substr(0, 3) !== 'P33' && ctlNo.substr(0, 2) !== 'QZ' && ctlNo.substr(0, 2) !== 'CS' && ctlNo.substr(0, 2) !== 'SW' && ctlNo.substr(0, 3) !== 'EMQ' && ctlNo.substr(0, 2) !== 'AF' && ctlNo.substr(0, 3) !== 'CEB' && ctlNo.substr(0, 6) !== 'REWIRE' && ctlNo.substr(0, 4) !== '1982' && ctlNo.substr(0, 3) !== 'RFE' && ctlNo.substr(0, 3) !== 'RAK' && ctlNo.substr(0, 1) !== 'R' && ctlNo.substr(0, 4) !== '13EC' && ctlNo.substr(-2) !== 'CB' && ctlNo.substr(0, 2) !== 'CE' && ctlNo.substr(0, 1) !== 'C' && ctlNo.substr(0, 2) !== 'EZ') {
        return true
      } else {
        return false
      }
    },
    newReceiveMoneyOBO () {
      this.$router.push({
        path: '/pera-remit/remote-location?trx_type=PO'
      })
    },
    newReceiveMoneyFulfillOBO () {
      this.$router.push({
        path: '/pera-remit/remote-location?trx_type=POF'
      })
    },
    checkUploadedFiles () {
      const self = this
      console.log(self.filteredACRs, 'filteredACR')
      console.log(self.transactions)
      var transactionsWithACR = []
      self.transactions.forEach(function (e) {
        self.filteredACRs.forEach(function (f) {
          if (e.control_number === f.ref_number) {
            transactionsWithACR.push(e)
          }
        })
      })
      return transactionsWithACR
    },
    async retrieveUploaded (item, type) {
      let filtered = {}
      if (type === 'acr') {
        filtered = this.filteredACRs.filter(function (e) {
          return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id && e.type === 'acr'
        })
      } else {
        filtered = this.filteredACRs.filter(function (e) {
          return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id && e.type === 'pre'
        })
      }
      const payload = {
        file: btoa(filtered[0].bucket_path)
      }
      const res = await this.$store.dispatch('acr/retrieveACRFile', payload)
      var extension = res.result.extension
      var file = Buffer.from(res.result.file, 'base64')
      var blob = ''
      if (extension === 'pdf') {
        blob = new Blob([file], { type: 'application/pdf' })
      } else if (extension === 'jpg') {
        blob = new Blob([file], { type: 'image/jpg' })
      } else if (extension === 'png') {
        blob = new Blob([file], { type: 'image/png' })
      } else if (extension === 'jpeg') {
        blob = new Blob([file], { type: 'image/jpeg' })
      }
      var fileURL = URL.createObjectURL(blob)
      window.open(fileURL, '_blank')
    },
    async reprintACRNoWacom (item) {
      let customerSignature = ''

      if (this.currUser.has_wacom) {
        await this.$store.dispatch('customers/retrieveSignatures', { customer_id: item.customer_id.toString() }).then(res => {
          if (res.code >= 200 && res.code <= 299) {
            if (res.result.length > 0) {
              customerSignature = res.result[0].bucket_path
            } else {
              customerSignature = ''
            }
          } else {
            customerSignature = ''
          }
        })
      }

      if (item.remco_id === 0) {
        const that = this
        const printReceiptPayload = {}
        printReceiptPayload.print_data = {
          receiver: item.receiver_name,
          address: item.address + ' ' + item.barangay + ' ' + item.city + ' ' + item.province + ' ' + item.zip_code + ' ' + item.country,
          id_type: item.id_type,
          id_number: item.id_number,
          sender: item.sender_name,
          test_question: '',
          answer: '',
          message: item.delivery_message,
          mtcn: item.control_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
          date_time: this.formatDateTimeACR(new Date()),
          host_message_set2: '',
          sender_mobile_number: item.contact_number,
          agent_name: this.currUser.location_name + ' BRANCH',
          amount: this.formatAmount(item.principal_amount.toFixed(2)),
          originating_country: item.originating_country,
          total: item.country_currency_paid + ' ' + this.formatAmount(item.total_amount.toFixed(2)),
          exchange_rate: item.exchange_rate,
          dst: item.dst,
          payout_amount: item.principal_amount.toFixed(2),
          mywu_number: '',
          mywu_points: '',
          total_mywu_points: '',
          adj_type: item.country_currency_paid !== 'PHP' ? item.adj_type : '',
          mc_rate: item.country_currency_paid !== 'PHP' ? item.mc_rate : '',
          buyback_amount: item.country_currency_paid !== 'PHP' ? item.buy_back_amount : '',
          total_amount: item.country_currency_paid !== 'PHP' ? item.buy_back_amount * item.mc_rate : '',
          ub_account_no: '',
          des_curr: item.country_currency_paid,
          purpose_of_trxn: item.purpose_transaction,
          receiver_mobile_number: '',
          promo_flag: 'N',
          promo_code: '',
          expiration_date: '',
          vuser_group_id: this.currUser.user_group_id
        }
        printReceiptPayload.data = {
          has_wacom: false,
          trx_type: 2,
          remco_name: 'Western Union',
          control_number: '',
          customer_id: item.customer_id.toString(),
          location_id: this.currUser.location_id,
          location_name_uploaded: this.currUser.location_name,
          crn: '-',
          uploaded_by: this.currUser.user_id,
          first_name: '-',
          last_name: '-',
          product: 'WU Receive Money',
          receipt_type: 'acr'
        }
        if (this.currUser.has_wacom) {
          printReceiptPayload.print_data.fla_signature = this.currUser.userSignature
          printReceiptPayload.print_data.customer_signature = customerSignature
        }
        setTimeout(function () {
          const thatFunc = that
          that.$store.dispatch('wu/generateACRWU', printReceiptPayload).then((res) => {
            console.log(res)
            thatFunc.base64String = res.result
            thatFunc.pdfConvert()
          }).catch((error) => {
            console.log(error)
          })
        }, 3000)
      } else {
        const that = this
        const currentAddress = `${item.address}, ${item.barangay}, ${item.city}, ${item.province}, ${item.country}`
        const agentName = `${this.currUser.last_name}, ${this.currUser.first_name}`
        const ACRPayload = {
          remco_name: item.non_ex_name,
          sender_name: item.sender_name,
          remco_id: item.remco_id,
          trx_type: item.trx_type,
          receiver_name: item.receiver_name,
          current_address: currentAddress,
          id_type: item.id_type,
          id_number: item.id_number,
          contact_number: item.contact_number,
          purpose_transaction: item.purpose_transaction,
          control_number: item.control_number,
          trx_timestamp: this.formatDateTimeACR(new Date()),
          vlocation_name: this.currUser.location_name,
          currency_name: 'PHP',
          principal_amount: item.principal_amount,
          service_charge: item.service_charge,
          total_amount: item.total_amount,
          buy_back_amount: item.buy_back_amount,
          currency_id: item.currency_id,
          agent_name: agentName,
          location_id: item.location_id,
          has_wacom: false,
          customer_id: item.customer_id.toString(),
          crn: item.client_reference_no,
          uploaded_by: this.currUser.user_id
        }
        setTimeout(function () {
          const thisFunction = that
          if (thisFunction.checkIfMoneygram(item.control_number) && item.remco_id === 19) {
            that.$store.dispatch('customers/generateACRMoneygram', ACRPayload).then((res) => {
              thisFunction.base64String = res.result
              thisFunction.pdfConvert()
            }).catch((error) => {
              console.log(error)
            })
          } else {
            that.$store.dispatch('customers/generateACR', ACRPayload).then((res) => {
              thisFunction.base64String = res.result
              thisFunction.pdfConvert()
            }).catch((error) => {
              console.log(error)
            })
          }
        }, 3000)
      }
    },
    checkIfUploadedPre (item) {
      console.log(item, 'item from table')
      console.log(this.filteredACRs, 'filtered')
      const self = this
      var filtered = self.filteredACRs.filter(function (e) {
        return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id && e.type === 'pre'
      })
      console.log(filtered, 'filtered pre')
      if (filtered.length !== 0) {
        return false
      } else {
        return true
      }
    },
    checkIfUploaded (item) {
      console.log(item, 'item from table')
      console.log(this.filteredACRs, 'filtered acr')
      var filtered = this.filteredACRs.filter(function (e) {
        return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id
      })
      if (filtered.length !== 0) {
        return false
      } else {
        return true
      }
    },
    checkForWacom () {
      const that = this
      WacomSDK.STU.isDCAReady()
        .then(function (message) {
          if (!message) {
            throw new this.DCANotReady()
          }
          // Set handler for Device Control App timeout
          WacomSDK.STU.onDCAtimeout = self.onDCAtimeout
          return WacomSDK.STU.getUsbDevices()
        })
        .then(function (message) {
          if (message === null || message.length === 0) {
            that.hasWacom = false
          } else {
            that.hasWacom = true
          }
        })
    },
    getTransactions () {
      this.$store.dispatch('nonex/getTransactions', {
        trxDate: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        locationID: this.currUser.location_id,
        trxType: 2
      })
    },
    formatDateTime (date) {
      return format(parseISO(date), 'yyyy-MM-dd HH:mm:SS')
    },
    formatDateTimeACR (date) {
      if (date) return format(new Date(date), 'yyyy-MM-dd h:mmaa')
      return ''
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    reprintACR (payload) {
      console.log(payload)
      var filtered = this.filteredACRs.filter(function (e) {
        return e.ref_number === payload
      })
      console.log(filtered)
      window.open(filtered[0].bucket_path, '_blank')
    },
    pdfConvert () {
      var decoded = Buffer.from(this.base64String, 'base64')
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      window.open(fileURL, '_blank')
    },
    printReceipt (ACRPayload) {
      const that = this
      this.$store.dispatch('customers/generateACR', ACRPayload).then((res) => {
        that.base64String = res.result
        that.pdfConvert()
        that.getTransactions()
      }).catch((error) => {
        console.log(error)
      })
    },
    doRetryPayout (item) {
      if (item.remco_id === 0) this.retryWUPayout(item)
      else this.retryPayout(item)
    },
    retryPayout (item) {
      const currentUser = this.currUser
      const remcoList = this.remcos.data
      const remcoId = item.remco_id
      const remcoData = remcoList.filter(data => { return data.id === remcoId })
      const endpoint = remcoData[0].payout_route
      const remcoName = remcoData[0].non_ex_name
      const endpointSeparator = endpoint.split('/')
      const retryRoute = endpointSeparator[0] + '/retry'
      console.log(item)

      let retryPayload = {
        endpoint: retryRoute,
        formData: {
          control_number: item.control_number,
          reference_number: item.reference_number,
          id: item.id,
          location_id: currentUser.location_id,
          location_name: currentUser.location_name,
          // additional for rcbc
          user_id: currentUser.user_id
        }
      }

      let getCurrentCountry = ''
      // Maya
      if (item.remco_id === 5) {
        this.collections.countries.filter(data => {
          if (data.country === item.country) {
            getCurrentCountry = data
          }
        })

        retryPayload = Object.assign({}, retryPayload, {
          formData: {
            control_number: item.control_number,
            reference_number: item.reference_number,
            id: item.id,
            location_id: currentUser.location_id,
            location_name: currentUser.location_name,
            user_id: currentUser.user_id,
            country_of_origin: getCurrentCountry.two_iso_code,
            country: getCurrentCountry.two_iso_code,
            birth_country: getCurrentCountry.two_iso_code,
            originating_country: getCurrentCountry.two_iso_code
            // beneficiary: {
            //   permanent_address: {
            //     country: getCurrentCountry.two_iso_code
            //   },
            //   present_address: {
            //     country: getCurrentCountry.two_iso_code
            //   },
            //   birth_country: getCurrentCountry.two_iso_code
            // }
          }
        })
      }

      // Gcash, Remitly Payout
      if (item.remco_id === 26 || item.remco_id === 21) {
        retryPayload = Object.assign({}, retryPayload, {
          formData: {
            control_number: item.control_number,
            reference_number: item.reference_number,
            id: item.id,
            location_id: currentUser.location_id,
            location_name: currentUser.location_name,
            user_id: currentUser.user_id
          }
        })
      }

      // Transfast and Metrobank
      if (item.remco_id === 7 || item.remco_id === 8) {
        retryPayload = Object.assign({}, retryPayload, {
          formData: {
            control_number: item.control_number,
            reference_number: item.reference_number,
            id: item.id,
            location_name: currentUser.location_name,
            client_reference_no: item.client_reference_no
          }
        })
      }

      // Ria
      if (item.remco_id === 12) {
        var generateDeviceId = `${currentUser.user_id}${currentUser.location_id}`
        retryPayload = Object.assign({}, retryPayload, {
          formData: {
            control_number: item.control_number,
            reference_number: item.reference_number,
            id: item.id,
            location_id: currentUser.location_id,
            location_name: currentUser.location_name,
            user_id: currentUser.user_id,
            agent_id: 84424911,
            agent_code: currentUser.location_code,
            device_id: Buffer.from(generateDeviceId).toString('base64')
          }
        })
      }

      // USSC
      if (item.remco_id === 10) {
        var getLocationId = currentUser.location_id.toString()
        retryPayload = Object.assign({}, retryPayload, {
          formData: {
            control_number: item.control_number,
            reference_number: item.reference_number,
            id: item.id,
            location_id: currentUser.location_id,
            location_name: currentUser.location_name,
            branch: currentUser.location_name,
            branch_code: getLocationId.padStart(5, '0')
          }
        })
      }

      // Cebuana International
      if (item.remco_id === 19) {
        retryPayload = Object.assign({}, retryPayload, {
          formData: {
            control_number: item.control_number,
            reference_number: item.reference_number,
            id: item.id,
            location_id: currentUser.location_id,
            location_name: currentUser.location_name,
            user_id: currentUser.user_id,
            international_partner_code: 'PL0005'
          }
        })
      }

      // Cebuana Domestic
      if (item.remco_id === 9) {
        retryPayload = Object.assign({}, retryPayload, {
          formData: {
            control_number: item.control_number,
            id: item.id,
            location_id: currentUser.location_id,
            location_name: currentUser.location_name,
            user_id: currentUser.user_id
          }
        })
      }

      const trxData = item

      let currentAddress = ''

      if (trxData?.current_address) {
        currentAddress = `${trxData.current_address.address_1}, ${trxData.current_address.barangay}, ${trxData.current_address.city}, ${trxData.current_address.province}, ${trxData.current_address.country}, ${trxData.current_address.zip_code}`
      }

      const agentName = `${this.currUser.last_name}, ${this.currUser.first_name}`
      const ACRPayload = {
        remco_name: remcoName,
        sender_name: trxData.sender_name,
        receiver_name: trxData.receiver_name,
        current_address: currentAddress,
        id_type: trxData.id_type,
        id_number: trxData.id_number,
        contact_number: trxData.contact_number,
        purpose_transaction: trxData.purpose_transaction,
        control_number: trxData.control_number,
        trx_timestamp: this.formatDateTimeACR(new Date()),
        vlocation_name: this.currUser.location_name,
        currency_name: trxData.currency_id === 1 ? 'PHP' : 'USD',
        principal_amount: trxData.principal_amount,
        service_charge: trxData.service_charge,
        total_amount: trxData.total_amount,
        buy_back_amount: trxData.buy_back_amount,
        currency_id: trxData.currency_id,
        agent_name: agentName,
        location_id: trxData.location_id,
        trx_type: 2,
        has_wacom: false
      }

      this.$store.dispatch('nonex/retryPayout', retryPayload).then(result => {
        if (result.code >= 200 && result.code < 300) {
          this.successDialog = true
          this.countdown = 3
          this.interval = setInterval(() => {
            if (this.progressValue === 99.99 && this.countdown === 0) {
              return (this.progressValue = 0)
            }
            this.progressValue += 33.3333333333
            this.countdown -= 1
          }, 1000)

          const that = this

          setTimeout(function () {
            that.printReceipt(ACRPayload)
          }, 3000)
        } else {
          this.renderToast('error', 'Retry Payout Error', result?.errors ? result.errors : 'Re-transaction Failed. Please Try Again')
        }
      }).catch(() => {
        this.renderToast('error', 'Retry Payout Error', 'Re-transaction failed. Please try again')
      })
    },
    async retryWUPayout (item) {
      const payload = {
        mtcn: item.control_number,
        terminal_id: item.wu_terminal_id,
        operator_id: item.wu_opid,
        trx_wu_pending_id: item.id,
        location_id: item.location_id
      }

      const { code } = await this.$store.dispatch('wu/retryReceiveMoney', payload)

      if (code === 200) {
        renderToast('success', 'Successful', 'Retry Succeeded')
        this.getTransactions()
      } else {
        renderToast('error', 'Failed', 'Retry Failed')
      }
    }
  }
}
</script>
<style scoped>
/* .ph-nonex-datatable-title {
  padding-left: 0;
} */

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.35);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

/* .ph-textbox.ph-textbox-grid-search {
  width: 256px;
} */
</style>
