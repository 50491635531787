<template>
  <v-row class="ph-wu-receive-transfer-info">
    <v-col cols="12">
      <div class="ph-transfer-details-container payout-details-container">
        <div class="ph-transfer-detail-title">
          <b>Delivery Services</b>
        </div>
        <v-simple-table class="transfer-info-table">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in testQuestion"
                :key="item.name"
              >
                <td class="transfer-info-title">{{ item.name }}</td>
                <td class="transfer-info-value">{{ item.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br>
      </div>
      <div class="ph-transfer-details-container sender-details-container">
        <div class="ph-transfer-detail-title">
          <b>Transaction Info</b>
        </div>
        <v-simple-table class="transfer-info-table">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in transferInformation"
                :key="item.name"
              >
                <td class="transfer-info-title">{{ item.name }}</td>
                <td class="transfer-info-value">{{ item.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { convertToRealAmount, formatTime, formatMTCN } from '@/utils'
import numeral from 'numeral'
import { mapGetters } from 'vuex'

export default {
  name: 'SendMoneyFulfillTransferInfo',
  props: {
    selectedTrxData: Object
  },
  data () {
    return {
      testQuestion: [
        { name: 'Test Question', value: '-' },
        { name: 'Answer', value: '-' }
      ],
      transferInformation: [
        { name: 'Destination Country', value: '-' },
        { name: 'Exchange Rate', value: '-' },
        { name: 'Amount to Send', value: '-' },
        { name: 'Amount to Receive', value: '-' },
        { name: 'Transfer Fee', value: '-' },
        { name: 'Promo Code', value: '-' },
        { name: 'Discount Amount', value: '-' },
        { name: 'Message', value: '-' },
        { name: 'Message Charge', value: '-' },
        { name: 'Total Amount', value: '-' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      collections: 'collections'
    })
  },
  watch: {
    selectedTrxData: {
      handler (returnVal) {
        this.populateTransferInfo(returnVal)
      },
      deep: true
    }
  },
  methods: {
    convertToRealAmount,
    formatTime,
    formatMTCN,
    populateTransferInfo (data) {
      console.log(data)

      const orignatingCountryCurency = data.payment_details.originating_country_currency
      const destinationCountryCurency = data.payment_details.destination_country_currency
      // this.receiverName = `${data.receiver.name.first_name} ${data.receiver.name.last_name}`

      // let originatingCountry = ''
      let destinationCountry = ''

      this.collections.countries.filter(data => {
        // if (data.two_iso_code === orignatingCountryCurency.iso_code.country_code) {
        //   originatingCountry = data.country
        // }

        if (data.two_iso_code === destinationCountryCurency.iso_code.country_code) {
          destinationCountry = data.country
        }
      })

      let question = '-'
      let answer = '-'

      if (Object.prototype.hasOwnProperty.call(data.delivery_services, 'identification_question')) {
        const testQuestionObject = data.delivery_services.identification_question

        question = testQuestionObject.question
        answer = testQuestionObject.answer
      } else {
        question = '-'
        answer = '-'
      }

      let message = '-'

      if (Object.prototype.hasOwnProperty.call(data.delivery_services, 'message')) {
        message = data.delivery_services.message.message_details.text
      }

      this.testQuestion = [
        { name: 'Test Question', value: question },
        { name: 'Answer', value: answer }
      ]

      this.transferInformation = [
        { name: 'Destination Country', value: destinationCountry },
        { name: 'Exchange Rate', value: `${orignatingCountryCurency.iso_code.currency_code} 1.00 = ${data.payment_details.exchange_rate}` },
        { name: 'Amount to Send', value: `${orignatingCountryCurency.iso_code.currency_code} ${numeral(this.convertToRealAmount(data.financials.originators_principal_amount)).format('0, 0.00')}` },
        { name: 'Amount to Receive', value: `${destinationCountryCurency.iso_code.currency_code} ${numeral(this.convertToRealAmount(data.financials.destination_principal_amount)).format('0, 0.00')}` },
        { name: 'Transfer Fee', value: `${orignatingCountryCurency.iso_code.currency_code} ${numeral(this.convertToRealAmount(data.financials.charges)).format('0, 0.00')}` },
        { name: 'Promo Code', value: data.promotions.sender_promo_code !== '' ? data.promotions.sender_promo_code : '-' },
        { name: 'Discount Amount', value: `${orignatingCountryCurency.iso_code.currency_code} ${numeral(this.convertToRealAmount(data.financials.total_discounted_charges)).format('0, 0.00')}` },
        { name: 'Message', value: message },
        { name: 'Message Charge', value: `${orignatingCountryCurency.iso_code.currency_code} ${numeral(this.convertToRealAmount(data.financials.message_charge)).format('0, 0.00')}` },
        { name: 'Total Amount', value: `${orignatingCountryCurency.iso_code.currency_code} ${numeral(this.convertToRealAmount(data.financials.gross_total_amount)).format('0, 0.00')}` }
      ]
    }
  }
}
</script>
<style scoped>
  .ph-wu-form-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-transfer-details-container {
    margin-bottom: 15px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }
</style>
