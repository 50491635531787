import api from '@/api'

export default {
  generateConsent (payload) {
    return api.post('/v1/maintenance/api/generate-data-consent', payload)
  },
  checkCustomerConsent (payload) {
    return api.post('/v1/maintenance/api/check-data-consent', payload)
  },
  getLatestConsent (payload) {
    return api.post('/v1/maintenance/api/get-latest-consent', payload)
  }
}
