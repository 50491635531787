export default {
  currentTrx: {},
  currentSendFulfillTrx: {},
  currentReceiveFulfillTrx: {},
  payoutCurrencyType: '',
  payoutFulfillCurrencyType: '',
  showPayoutSearchForm: true,
  showPayoutResultForm: false,
  showSuccessPayoutScreen: false,
  showPayoutFulfillSearchForm: true,
  showPayoutFulfillResultForm: false,
  showSuccessPayoutFulfillScreen: false,
  showSendoutFulfillSearchForm: true,
  showSendoutFulfillResultForm: false,
  showSuccessSendoutFulfillScreen: false,
  payoutForeignRefNo: '',
  sendoutReleaseForeignRefNo: '',
  payoutReleaseForeignRefNo: '',
  payoutFormData: {
    foreign_reference_no: '',
    sender_name_type: '',
    sender_first_name: '',
    sender_middle_name: '',
    sender_last_name: '',
    sender_addr_line1: '',
    sender_addr_line2: '',
    sender_city: '',
    sender_state: '',
    sender_postal_code: '',
    sender_addr_country_code: '',
    sender_addr_currency_code: '',
    sender_contact_phone: '',
    sender_mobile_country_code: '',
    sender_mobile_no: '',
    sender_addr_country_name: '',
    mywu_number: '',
    id_type: '',
    id_country_of_issue: '',
    id_number: '',
    id_issue_date: '',
    id_expiration_date: '',
    date_of_birth: '',
    occupation: '',
    Country_of_Birth: '',
    nationality: '',
    Gender: '',
    Source_of_Funds: '',
    Sender_Employeer: '',
    Relationship_to_Receiver: '',
    GEN_III_Indicator: '',
    ack_flag: '',
    reason_for_send: '',
    mywu_enroll_tag: '',
    email: '',
    receiver_name_type: '',
    receiver_first_name: '',
    receiver_middle_name: '',
    receiver_last_name: '',
    receiver_addr_line1: '',
    receiver_addr_line2: '',
    receiver_city: '',
    receiver_state: '',
    receiver_postal_code: '',
    receiver_addr_country_code: '',
    receiver_addr_currency_code: '',
    receiver_contact_phone: '',
    receiver_mobile_country_code: '',
    receiver_mobile_no: '',
    destination_country_code: '',
    destination_currency_code: '',
    destination_state_code: '',
    destination_city_name: '',
    transaction_type: '',
    principal_amount: '',
    fixed_amount_flag: '',
    promo_code: '',
    message: [],
    bank_name: '',
    account_number: '',
    bank_code: '',
    bank_location: '',
    account_suffix: '',
    terminal_id: '',
    operator_id: '',
    remote_terminal_id: '',
    remote_operator_id: '',
    second_id_number: '',
    third_id_number: '',
    employment_position_level: '',
    Purpose_of_Transaction: '',
    is_current_and_permanent_addr_same: '',
    perma_addr_line1: '',
    perma_addr_line2: '',
    perma_city: '',
    perma_state_name: '',
    perma_postal_code: '',
    perma_country: '',
    is_on_behalf: '',
    galactic_id: '',
    location_id: ''
  },
  payoutSuccessData: {},
  payoutFulfillSuccessData: {},
  sendoutFulfillSuccessData: {},
  wuKYCMultipleSearchData: null,
  wuKYCSingleSearchData: {},
  quickPayCurrentPage: 0,
  qpForeignRefNo: '',
  qpValidateData: {},
  qpSuccessData: {},
  mcRateData: []
}
