import api from '@/api'
import consolidator from '@/api/consolidator'
import petnetapiv2 from '@/api/petnetapiv2'

export default {
  transactions (trxDate, locationID, trxType, wuTrxCategory) {
    return api.get(`/v1/transactions/api/trx/remit?trx_date=${trxDate}&location_id=${locationID}&trx_type=${trxType}` + (wuTrxCategory !== '' ? `&wu_trx_category=${wuTrxCategory}` : ''))
  },
  consolidator (payload) {
    return consolidator.post('/consolidator/v1/search', payload)
  },
  payoutStatus (controlNo) {
    return api.get(`/v1/transactions/api/maintenance/adjustment/trx/search?control_number=${controlNo}`)
  },
  remcos () {
    return api.get('/v1/transactions/api/maintenance/remco')
  },
  cebuanaIntUpdateBeneficiary (payload) {
    return petnetapiv2.post('/cebuana-international/update-beneficiary', payload)
  },
  executePayout (endpoint, payload) {
    return petnetapiv2.post(`/${endpoint}`, payload)
  },
  retryPayout (endpoint, payload) {
    return petnetapiv2.post(`/${endpoint}`, payload)
  },
  sendOutServiceFee (amount, agentCode, branchCode) {
    return api.get(`/v1/maintenance/api/nonex-sf-all?principal_amount=${amount}&agent_code=${agentCode}&branch_code=${branchCode}`)
  },
  remcoServiceFee (amount, remcoID) {
    return api.get(`/v1/maintenance/api/nonex-sf?principal_amount=${amount}&remco_id=${remcoID}`)
  },
  sendout (endpoint, payload) {
    return petnetapiv2.post(`/${endpoint}`, payload)
  },
  retrySendout (endpoint, payload) {
    return petnetapiv2.post(`/${endpoint}`, payload)
  },
  cebuanaFindClient (customer) {
    return petnetapiv2.get(`/cebuana/find-client?first_name=${customer.first_name}&last_name=${customer.last_name}&birth_date=${customer.birth_date}&client_number=`)
  },
  cebuanaAddClient (payload) {
    return petnetapiv2.post('/cebuana/add-client', payload)
  },
  cebuanaViewBeneficiary (clientID) {
    return petnetapiv2.get(`/cebuana/beneficiary-by-sender?sender_client_id=${clientID}`)
  },
  cebuanaAddBeneficiary (payload) {
    return petnetapiv2.post('/cebuana/add-beneficiary', payload)
  },
  cebuanaSendExecute (payload) {
    return petnetapiv2.post('/cebuana/add-beneficiary', payload)
  },
  getRemcoStateCity (state, city, remcoId) {
    return api.get(`/v1/maintenance/api/maintenance/states?state=${state}&city=${city}&remco_id=${remcoId}`)
  },
  uploadACR (payload) {
    return api.post('/v1/maintenance/api/customer-acr-upload', payload)
  }
}
