<template>
  <div class="ph-container">
    <v-container fluid class="ph-dashboard-container">
      <v-row dense>
        <v-col v-for="subModule in subModules" :key="subModule.id" cols="12" sm="6" md="6" lg="3">
          <v-card v-ripple class="ph-dashboard-button mt-5" elevation="0" router :to="subModule.to" >
            <div class="ph-dashboard-button-icon-container">
              <v-avatar
                :color="subModule.bgColor"
                size="58"
                class="ph-dashboard-button-icon"
              >
                <img class="ph-dashboard-button-icon-img" :src="require('@/assets/images/wuPayments/' + subModule.image + '.svg')" />
              </v-avatar>
            </div>
            <div class="ph-dashboard-button-text-container">
              <h1 class="ph-dashboard-button-title" :style="`color: ${subModule.textColor}`">{{ subModule.title }}</h1>
              <p class="ph-dashboard-button-description">{{ subModule.description }}</p>
            </div>
          </v-card>
      </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      subModules: [
        { id: 1, bgColor: 'rgba(253, 209, 0, 0.1)', textColor: '#C9A600', title: 'General Setup', description: 'Managing base rates', image: 'payment-order', to: '/mc-rate/setup' },
        { id: 2, bgColor: 'rgba(139, 81, 255, 0.1)', textColor: '#6633CC', title: 'Requests', description: 'Requests from branches', image: 'refund-order', to: '/mc-rate/requests' }
      ]
    }
  }
}
</script>
<style scoped>
  .ph-container {
    margin: 0 15px;
  }

  .v-card.ph-dashboard-button {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
    /* border: 1px solid #D0D0D0; */
  }

  .ph-dashboard-button-text-container {
    padding: 30px;
    width: 100%;
  }

  .ph-dashboard-button-title {
    font-family: "Baloo Extra Bold";
    color: #1A2791;
    font-size: 22px;
  }

  .ph-dashboard-button-icon-container {
    padding: 30px;
    width: 100%;
    display: flex;
    padding-bottom: 0;
    /* margin-right: 20px !important; */
  }

  .ph-dashboard-button-description {
    font-size: 16px;
  }

  /* .ph-dashboard-button-icon {
    margin: auto;
  } */

  .ph-dashboard-button-icon-img {
    margin: auto;
    opacity: 1 !important;
    transform: scale(0.35);
    border-radius: 0;
  }

  @media only screen and (min-width: 500px) {
    .v-card.ph-dashboard-button {
      display: flex;
      cursor: pointer;
      box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
      border-radius: 12px;
      height: 250px;
    }

    .ph-dashboard-button:nth-of-type(odd) {
      margin-left: 5px;
    }

    .ph-dashboard-button:nth-of-type(even) {
      margin-right: 5px;
    }

    .ph-dashboard-button-text-container {
      width: 80%;
    }

    .ph-dashboard-button-icon-container {
      width: 20%;
      /* margin-right: unset !important; */
    }

    .ph-dashboard-button-icon-img {
      margin: auto;
      opacity: 1 !important;
      transform: scale(0.4);
    }
  }

  @media only screen and (min-width: 376px) {
    .ph-dashboard-container {
      margin: 0;
    }
  }
</style>
