<template>
  <v-row>
    <v-col :cols="12" :md="3">
      <v-text-field
        v-model="search"
        class="ph-textbox"
        placeholder="Search"
        append-icon="mdi-magnify"
        hide-details
        outlined
      />
    </v-col>
    <v-col class="d-flex align-center" :cols="12" :md="2">
      <v-btn
        @click="getData"
        class="text-capitalize font-weight-bold"
        color="primary"
        rounded
        large
        text
      >
        <v-icon class="mr-1">mdi-refresh</v-icon>
        Refresh
      </v-btn>
    </v-col>
    <v-col :cols="12" :md="3">
      <v-text-field
        @click="showDateRangePicker = true"
        v-model="dateRange"
        append-icon="mdi-calendar"
        class="ph-textbox"
        label="Date Covered"
        readonly
        outlined
      />
      <v-dialog v-model="showDateRangePicker" width="300">
        <v-date-picker
          v-model="filter.dateRange"
          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
          min="1900-01-01"
          @change="syncData"
          range
        />
      </v-dialog>
    </v-col>
    <v-col :cols="12" :md="2">
      <v-select
        @change="getData"
        v-model="filter.provider"
        :items="collections.providers"
        item-text="provider_name"
        item-value="id"
        class="ph-textbox"
        label="Provider"
        return-object
        outlined
      />
    </v-col>
    <v-col :cols="12" :md="2">
      <v-select
        @change="getData"
        v-model="filter.channel"
        :items="collections.channels"
        item-text="channel_name"
        item-value="channel_code"
        class="ph-textbox"
        label="Channel"
        outlined
      />
    </v-col>
    <v-col :cols="12">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="data"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:item.total_approved_amount="{ item }">
            {{ toMoney(item.total_approved_amount) }}
          </template>
          <template v-slot:item.final_default_limit="{ item }">
            {{ toMoney(item.final_default_limit) }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { toMoney } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections'
    }),
    dateRange () {
      const dates = this.filter.dateRange
      return dates.join(' ~ ')
    }
  },
  data () {
    return {
      showDateRangePicker: false,
      filter: {
        provider: {},
        channel: '',
        dateRange: []
      },
      search: '',
      data: [],
      headers: [
        {
          text: 'Branch Name',
          value: 'location_name',
          width: '150px'
        },
        {
          text: 'Pending Requests',
          value: 'no_request',
          width: '150px'
        },
        {
          text: 'Approved Requests',
          value: 'no_approved',
          width: '150px'
        },
        {
          text: 'Total Amount Adjusted',
          value: 'total_approved_amount',
          width: '150px'
        },
        {
          text: 'Default Limit',
          value: 'final_default_limit',
          width: '150px'
        }
      ]
    }
  },
  watch: {
    collections: {
      handler ({ providers, channels }) {
        if (providers.length > 0) this.filter.provider = providers[0]
        if (channels.length > 0) this.filter.channel = channels[0].channel_code

        if (providers.length > 0 && channels.length > 0 && this.data.length === 0) this.getData()
      },
      deep: true
    }
  },
  methods: {
    syncData () {
      this.filter.dateRange.sort((x, y) => new Date(x) - new Date(y))
      this.getData()
      this.showDateRangePicker = false
    },
    async getData () {
      const [dateFrom, dateTo] = this.filter.dateRange
      const payload = {
        service_name: this.filter.provider.service_name || '',
        provider_code: this.filter.provider.provider_code || '',
        service_type: this.filter.provider.service_type || '',
        channel: this.filter.channel,
        date_from: dateFrom || (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date_to: dateTo || (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      }
      const { code, result } = await this.$store.dispatch('transactionLimit/getLocationDashboardData', payload)

      if (code === 200) {
        this.data = result.map(item => {
          return {
            ...item,
            final_default_limit: item.default_limit || item.provider_limit
          }
        })
      }
    },
    toMoney
  },
  mounted () {
    // Select the first in the filter dropdown
    if (this.collections.providers.length > 0) this.filter.provider = this.collections.providers[0]
    if (this.collections.channels.length > 0) this.filter.channel = this.collections.channels[0].channel_code

    if (this.collections.providers.length > 0 && this.collections.channels.length > 0 && this.data.length === 0) this.getData()
    this.filter.dateRange.push(this.currUser.trx_date, this.currUser.trx_date)
  }
}
</script>
