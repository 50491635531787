export default {
  loanAccountData: state => state.loanAccountData,
  tpaId: state => state.tpaId,
  loanCollectionTransactions: state => state.loanCollectionTransactions,
  loanPaymentResponseData: state => state.loanPaymentResponseData,
  loanCollectionCurrentPage: state => state.loanCollectionCurrentPage,
  collectionProviders: state => state.collectionProviders,
  loanCollectionResponseData: state => state.loanCollectionResponseData,
  loanCollectionCurrentTrx: state => state.loanCollectionCurrentTrx,
  loanAccountNo: state => state.loanAccountNo
}
