import axios from 'axios'
import store from '@/store'
import CryptoJS from 'crypto-js'
import { env } from '@/api/config'
import { createSignature } from '@/utils'

const susi = 'wlK7ZRNOBimj74oV9bqowfVPSqq+QD6t1YRwVWQMsHA='

const http = axios.create({
  baseURL: CryptoJS.AES.decrypt(env.get('baseURL'), susi).toString(CryptoJS.enc.Utf8),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Perahub-Gateway-Token': CryptoJS.AES.decrypt(env.get('token'), susi).toString(CryptoJS.enc.Utf8)
  }
})

http.interceptors.request.use((config) => {
  const dateToday = new Date()
  const timestamp = dateToday.getTime()
  const signature = createSignature(config.data, timestamp)

  config.headers['Client-Code'] = 'usp2.0'
  config.headers.Timestamp = timestamp
  config.headers.Signature = signature

  store.commit('app/TOGGLE_LOADING', true)

  if (store?.state?.auth?.currUser?.token) {
    config.headers.Authorization = 'Bearer ' + store.state.auth.currUser.token
  } else {
    delete config.headers.Authorization
  }

  return config
})

http.interceptors.response.use(
  response => {
    store.commit('app/TOGGLE_LOADING', false)
    return response
  },
  err => {
    store.commit('app/TOGGLE_LOADING', false)

    if (Object.prototype.hasOwnProperty.call(err, 'response')) {
      return err.response
    }
    return Promise.reject(err)
  }
)

export default http
