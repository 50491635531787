<template>
  <v-dialog
    v-model="showResult"
    max-width="1000"
    persistent
  >
    <v-card>
      <v-card-title class="ph-wu-search-result-header text-h5 text-primary">
        <h5 class="ph-wu-search-text">WU Customer Results ({{ custSearchResult ? custSearchResult.length : 0}})</h5>
      </v-card-title>

      <v-card-text>
        <v-data-table
          class="ph-clickable-table"
          :headers="listViewHeaders"
          :items="custSearchResult"
          @click:row="doSelect"
          no-data-text="No customers found"
        >
          <template v-slot:[`item.wu_card_no`]="{ item }">
            {{item.mywu_details.mywu_number}}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-avatar class="mr-1" size="25px" :style="avatarColor(item.name.first_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.name.first_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.name.first_name) }} {{ toProperCase(item.name.last_name) }}
          </template>
          <template v-slot:[`item.postal_code`]="{ item }">
            {{item.address.postal_code}}
          </template>
          <template v-slot:[`item.country_code`]="{ item }">
            {{item.address.country_details.ctry_code}}
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="showResult = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'ReceiveMoneyFulfillSearchResult',
  data () {
    return {
      listViewHeaders: [
        {
          text: 'MyWU Number.',
          sortable: true,
          value: 'wu_card_no'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Postal Code',
          sortable: true,
          value: 'postal_code'
        },
        {
          text: 'Country Code',
          sortable: true,
          value: 'country_code'
        }
      ],
      showResult: false,
      isNotCustomerPage: true,
      custSearchResult: ''
    }
  },
  computed: {
    ...mapGetters({
      wuKYCMultipleSearchData: 'wu/wuKYCMultipleSearchData',
      selectedCustomer: 'customers/selectedCustomer',
      collections: 'collections',
      currUser: 'auth/currUser',
      currentReceiveFulfillTrx: 'wu/currentReceiveFulfillTrx',
      payoutReleaseForeignRefNo: 'wu/payoutReleaseForeignRefNo'
    })
  },
  watch: {
    wuKYCMultipleSearchData: {
      handler (getSearch) {
        this.custSearchResult = getSearch ? getSearch.customer : []

        if (this.custSearchResult !== null && this.custSearchResult && this.custSearchResult.length > 0) {
          this.showResult = true
        } else if (this.custSearchResult === undefined || this.custSearchResult === null) {
          this.showResult = false
        } else {
          this.showResult = false
        }
      },
      deep: true
    }
  },
  mounted () {
    this.custSearchResult = this.wuKYCMultipleSearchData ? this.wuKYCMultipleSearchData.customer : []

    console.log(this.custSearchResult)

    if (this.custSearchResult !== null && this.custSearchResult && this.custSearchResult.length > 0) {
      this.showResult = true
      console.log('pumasok')
    } else if (this.custSearchResult === undefined || this.custSearchResult === null) {
      this.showResult = false
    } else {
      this.showResult = false
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    async doSelect (customerDetails) {
      const idList = this.selectedCustomer.img ? JSON.parse(this.selectedCustomer.img) : []
      let currentId = idList['1']
      currentId = idList['1'] ? idList['1'] : {}

      const getId = this.collections.wuIDList.filter(item => {
        return item.id_type === currentId.id_type
      })

      const singleKYCLookupPayload = {
        foreign_reference_no: this.payoutReleaseForeignRefNo,
        search_by: 'by_id',
        id_type: getId[0].id_value ? getId[0].id_value : '',
        id_number: currentId.id_number ? currentId.id_number : '',
        contact_phone: customerDetails.contact_phone,
        mywu_number: customerDetails.mywu_details.mywu_number,
        first_name: customerDetails.name.first_name,
        last_name: customerDetails.name.last_name,
        is_multi: 'S',
        terminal_id: this.currUser.fs_id,
        operator_id: this.currUser.user_id,
        location_id: this.currUser.location_id
      }

      await this.$store.dispatch('wu/kycLookup', singleKYCLookupPayload).then(res => {
        console.log(res)
        if (res.result) {
          this.$store.commit('wu/SET_WU_KYC_SINGLE_SEARCH_DATA', res.result)
          this.$store.commit('wu/SET_WU_KYC_MULTIPLE_SEARCH_DATA', null)
          this.renderToast('info', 'WU KYC Customer Selected!', `${customerDetails.name.first_name} ${customerDetails.name.last_name} has been successfully selected.`)
        }
      }).catch(err => {
        console.log(err)
      })
      this.showResult = false
    }
  }
}
</script>
<style>
  .v-card__title.text-h5.ph-wu-search-result-header .ph-wu-search-text {
    font-family: 'Baloo Extra Bold', sans-serif !important;
    font-size: 24px;
    color: #1A2791;
  }
</style>
