<template>
  <v-row class="ph-cico-fields">
    <v-col class="py-6" cols="12" md="6">
      <v-text-field
        v-model="transactionData.application_number"
        label="Application Number"
        color="gray darken-1"
        class="ph-textbox"
        :rules="transactionData.appNumberRequiredField"
        outlined
        maxlength="7"
      />
    </v-col>
    <v-col class="py-6" cols="12" md="6">
      <v-text-field
        v-model="transactionData.employee_number"
        label="Employee Number"
        color="gray darken-1"
        class="ph-textbox"
        :rules="transactionData.requiredField"
        outlined
      />
    </v-col>
    <v-col class="py-6" cols="12" md="6">
      <v-text-field
        v-model="transactionData.fsa_agent_code"
        label="FSA Agent Code"
        color="gray darken-1"
        class="ph-textbox"
        :rules="transactionData.requiredField"
        outlined
      />
    </v-col>
    <v-col class="py-6" cols="12" md="6">
      <v-text-field
        v-model="transactionData.deped_email_address"
        label="DepEd E-Mail Address"
        color="gray darken-1"
        class="ph-textbox"
        :rules="transactionData.requiredField"
        outlined
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'PeraLoanTeachers',
  props: {
    transactionData: Object
  }
}
</script>
