<template>
  <v-row class="pt-4 pb-4 px-2 px-sm-4 px-md-6 pb-sm-12" no-gutters>
    <v-col :cols="12">
      <h3 class="custom-heading pb-4 pt-2 pb-md-6 primary--text font-weight-black">
        Recently Added
      </h3>
    </v-col>
    <v-col
      class="d-flex px-0 px-sm-1 px-md-2 pb-2 pb-md-3"
      :cols="12"
      :sm="4"
      :md="2"
      v-for="customer in recentlyAdded"
      :key="customer.customer_id"
    >
      <v-card @click="doUpdate(customer)" class="custom-card px-auto px-sm-2">
        <v-card-title class="px-0 mx-0 py-2">
          <v-spacer />
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="px-0 py-1">
              <v-list-item  @click="doUpdate(customer)">
                <v-icon class="mr-1" small>mdi-pencil</v-icon>
                Update
              </v-list-item>
              <v-list-item class="red--text" @click="confirmDelete(customer.customer_id)">
                <v-icon class="mr-1" color="red" small>mdi-delete</v-icon>
                Archive
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text class="px-1">
          <v-row no-gutters>
            <v-col class="d-flex flex-row justify-center" :cols="12">
              <v-avatar size="70px" :style="avatarColor(customer.full_name)">
                <span class="white--text text-h4">{{ avatarInitial(customer.full_name) }}</span>
              </v-avatar>
            </v-col>
            <v-col class="pt-3 pb-3" :cols="12">
              <h4 class="customer-name">
                {{ toProperCase(customer.first_name) }} {{ toProperCase(customer.last_name) }}
              </h4>
            </v-col>
            <v-col class="pt-2 pb-1" :cols="12">
              <v-row no-gutters>
                <v-col class="px-1 text-right" :cols="4">
                  <span class="caption pr-1">CRN</span>
                </v-col>
                <v-col class="px-1" :cols="8">
                  <strong>{{ customer.customer_number || '-' }}</strong>
                </v-col>
                <v-col class="px-1 text-right" :cols="4">
                  <span class="caption pr-1">Birth</span>
                </v-col>
                <v-col class="px-1" :cols="8">
                  <strong>{{ customer.birth_date || '-' }}</strong>
                </v-col>
                <v-col class="px-1 text-right" :cols="4">
                  <span class="caption pr-1">Mobile</span>
                </v-col>
                <v-col class="px-1" :cols="8">
                  <strong>{{ customer.mobile_no || '-' }}</strong>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col :cols="12" v-if="recentlyAdded.length === 0">
      <span class="caption">
        Nothing to display
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      recentlyAdded: 'customers/recentlyAdded'
    })
  },
  data () {
    return {
      headers: [
        {
          text: 'Name',
          value: 'full_name',
          sortable: true
        },
        {
          text: 'CRN',
          value: 'customer_number',
          sortable: true
        },
        {
          text: 'Last Transaction',
          value: 'LastTransaction',
          sortable: true
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  methods: {
    doUpdate (customerDetails) {
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)
      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: '/customers'
        }
      })
    },
    confirmDelete (customerID) {
      const confirmDelete = confirm('Do you want to archive this customer?')

      if (confirmDelete) {
        this.doDelete({
          userID: this.currUser.user_id,
          customerID
        })
      }
    },
    ...mapActions({
      doDelete: 'customers/doDelete'
    }),
    avatarColor,
    avatarInitial,
    toProperCase
  },
  beforeMount () {
    this.$store.dispatch('customers/getRecentlyAdded', this.currUser.location_code)
  }
}
</script>
