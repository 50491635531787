<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-card-title class="ph-nonex-datatable-title">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="ph-textbox ph-textbox-grid-search"
                outlined
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="serviceFeeList"
              class="custom-table"
              :calculate-widths="true"
            >
              <template v-slot:[`item.price_fee`]="{ item }">
                {{item.price_fee !== null ? 'PHP ' + formatAmount(item.price_fee) : '-' }}
              </template>
              <template v-slot:[`item.valid_date_from`]="{ item }">
                {{item.valid_date_from !== null ? item.valid_date_from : '-' }}
              </template>
              <template v-slot:[`item.valid_date_to`]="{ item }">
                {{item.valid_date_to !== null ? item.valid_date_to : '-' }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn @click="editServiceFee(item)" color="primary" icon>
                <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="deleteServiceFee(item)" color="primary" icon>
                <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-fab-transition>
        <v-btn
          color="primary"
          fab
          large
          fixed
          dark
          bottom
          right
          @click="addServiceFeeDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
      <!-- Add Service Fee -->
      <v-dialog v-model="addServiceFeeDialog" :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '50%'" persistent>
        <v-card>
          <v-form ref="addServiceFeeForm">
            <v-card-title>
              <h3 class="custom-heading pt-2 pb-2 primary--text font-weight-black">
                Add Service Fee
              </h3>
              <v-spacer />
              <v-btn @click="addServiceFeeDialog = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col :cols="12" :md="6">
                  <v-text-field
                    v-model="phvcData.phvc_code"
                    class="ph-textbox"
                    label="PHVC Code"
                    outlined
                    :rules="requiredField"
                  />
                </v-col>
                <v-col :cols="12" :md="6">
                  <v-text-field
                    v-model="phvcData.product_name"
                    class="ph-textbox"
                    label="Product Name"
                    outlined
                    :rules="requiredField"
                  />
                </v-col>
                <v-col :cols="12" :md="12">
                  <v-text-field
                    type="number"
                    :hide-spin-buttons="true"
                    v-model="phvcData.price_fee"
                    class="ph-textbox"
                    label="Service Fee Amount"
                    outlined
                    :rules="requiredField"
                  />
                </v-col>
                <v-col :cols="12">
                  <v-menu
                    ref="dateFromPicker"
                    v-model="dateFromPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="phvcData.valid_date_from"
                        class="ph-textbox"
                        color="gray darken-1"
                        label="From"
                        append-icon="mdi-calendar"
                        hide-details
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        :rules="(phvcData.valid_date_to === undefined || phvcData.valid_date_to === '' || phvcData.valid_date_to === null)  ? [] : requiredField"
                      />
                    </template>
                    <v-date-picker
                      v-model="phvcData.valid_date_from"
                      :active-picker.sync="activeDateFromPicker"
                      :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      @change="syncDateFrom"
                    />
                  </v-menu>
                </v-col>
                <v-col :cols="12">
                  <v-menu
                    ref="dateToPicker"
                    v-model="dateToPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="phvcData.valid_date_to"
                        class="ph-textbox"
                        color="gray darken-1"
                        label="To"
                        append-icon="mdi-calendar"
                        hide-details
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        :rules="(phvcData.valid_date_from === undefined || phvcData.valid_date_from === '' || phvcData.valid_date_from === null) ? [] : requiredField"
                      />
                    </template>
                    <v-date-picker
                      v-model="phvcData.valid_date_to"
                      :active-picker.sync="activeDateToPicker"
                      :min="phvcData.valid_date_from ? phvcData.valid_date_from : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      @change="syncDateTo"
                    />
                  </v-menu>
                </v-col>
                <v-col :cols="12" :md="12">
                  <v-text-field
                    v-model="phvcData.remarks"
                    class="ph-textbox"
                    label="Remarks"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-md-8 pt-md-4 pr-md-6">
              <v-spacer />
              <v-btn color="primary" @click="createServiceFee()">
                <b>Add New Fee</b>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <!-- Update Service Fee -->
      <v-dialog v-model="updateServiceFeeDialog" :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '50%'" persistent>
        <v-card>
          <v-form ref="updateServiceFeeForm">
            <v-card-title>
              <h3 class="custom-heading pt-2 pb-2 primary--text font-weight-black">
                Update Service Fee
              </h3>
              <v-spacer />
              <v-btn @click="closeUpdate" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col :cols="12" :md="6">
                  <v-text-field
                    v-model="selectedFeeData.phvc_code"
                    class="ph-textbox"
                    label="PHVC Code"
                    outlined
                    readonly
                  />
                </v-col>
                <v-col :cols="12" :md="6">
                  <v-text-field
                    v-model="selectedFeeData.product_name"
                    class="ph-textbox"
                    label="Product Name"
                    outlined
                    :rules="requiredField"
                  />
                </v-col>
                <v-col :cols="12">
                  <v-text-field
                    v-model="selectedFeeData.price_fee"
                    class="ph-textbox"
                    label="Service Fee Amount"
                    outlined
                    type="number"
                    :hide-spin-buttons="true"
                  />
                </v-col>
                <v-col :cols="12">
                  <v-menu
                      ref="dateFromPickerEdit"
                      v-model="dateFromPickerEdit"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selectedFeeData.valid_date_from"
                        class="ph-textbox"
                        color="gray darken-1"
                        label="From"
                        append-icon="mdi-calendar"
                        hide-details
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        :rules="(selectedFeeData.valid_date_to === undefined || selectedFeeData.valid_date_to === '' || selectedFeeData.valid_date_to === null) ? [] : requiredField"
                      />
                    </template>
                    <v-date-picker
                      v-model="selectedFeeData.valid_date_from"
                      :active-picker.sync="activeDateFromPickerEdit"
                      :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      @change="syncDateFromEdit"
                    />
                  </v-menu>
                </v-col>
                <v-col :cols="12">
                  <v-menu
                    ref="dateToPickerEdit"
                    v-model="dateToPickerEdit"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selectedFeeData.valid_date_to"
                        class="ph-textbox"
                        color="gray darken-1"
                        label="To"
                        append-icon="mdi-calendar"
                        hide-details
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        :rules="(selectedFeeData.valid_date_from === undefined || selectedFeeData.valid_date_from === '' || selectedFeeData.valid_date_from === null) ? [] : requiredField"
                      />
                    </template>
                    <v-date-picker
                      v-model="selectedFeeData.valid_date_to"
                      :active-picker.sync="activeDateToPickerEdit"
                      :min="selectedFeeData.valid_date_from ? selectedFeeData.valid_date_from : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      @change="syncDateToEdit"
                    />
                  </v-menu>
                </v-col>
                <v-col :cols="12">
                  <v-text-field
                    v-model="selectedFeeData.remarks"
                    class="ph-textbox"
                    label="Remarks"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-md-8 pt-md-4 pr-md-6">
              <v-spacer />
              <v-btn
                @click="doUpdate"
                color="primary"
              >
                Update Record
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="deleteServiceFeeDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Delete Service Fee
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            Are you sure you want to delete this service fee data?
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="danger"
              text
              @click="closeDelete"
              class="ph-action-button"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="doDelete"
              class="ph-action-button"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      serviceFeeList: 'phvc/serviceFeeList',
      collections: 'collections',
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      searchText: '',
      addServiceFeeDialog: false,
      updateServiceFeeDialog: false,
      deleteServiceFeeDialog: false,
      dateFromPicker: false,
      dateToPicker: false,
      dateFromPickerEdit: false,
      dateToPickerEdit: false,
      activeDateFromPicker: null,
      activeDateToPicker: null,
      activeDateFromPickerEdit: null,
      activeDateToPickerEdit: null,
      phvcData: {},
      selectedFeeData: {
        phvc_code: '',
        product_name: '',
        price_fee: '',
        remarks: '',
        valid_date_from: '',
        valid_date_to: ''
      },
      search: '',
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Admin',
          disabled: false,
          href: '#'
        },
        {
          text: 'PHVC Maintenance',
          disabled: true,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'ID',
          sortable: false,
          value: 'id'
        },
        {
          text: 'PHVC Code',
          sortable: false,
          value: 'phvc_code'
        },
        {
          text: 'Service Fee',
          sortable: true,
          value: 'price_fee'
        },
        {
          text: 'Product Name',
          sortable: false,
          value: 'product_name'
        },
        {
          text: 'Remarks',
          sortable: false,
          value: 'remarks'
        },
        {
          text: 'From',
          sortable: false,
          value: 'valid_date_from'
        },
        {
          text: 'To',
          sortable: false,
          value: 'valid_date_to'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  async mounted () {
    const req = await this.$store.dispatch('phvc/getServiceFeeList', {
      user_id: this.currUser.user_id,
      module_id: 19,
      access_permission: 'view_access'
    })

    if (req.code === 403) {
      renderToast('error', req.message, req.error.message)
    }
  },
  methods: {
    syncDateFrom (date) {
      this.$refs.dateFromPicker.save(date)
    },
    syncDateTo (date) {
      this.$refs.dateToPicker.save(date)
    },
    syncDateFromEdit (date) {
      this.$refs.dateFromPickerEdit.save(date)
    },
    syncDateToEdit (date) {
      this.$refs.dateToPickerEdit.save(date)
    },
    async createServiceFee () {
      const data = this.phvcData
      const payload = {
        phvc_code: data.phvc_code,
        product_name: data.product_name,
        price_fee: data.price_fee,
        remarks: data.remarks,
        valid_date_from: data.valid_date_from,
        valid_date_to: data.valid_date_to,
        date_updated: this.currUser.trx_date,
        updated_by: this.currUser.user_id,
        status: '1',
        default_fee: 0,
        user_id: this.currUser.user_id,
        module_id: 19,
        access_permission: 'create_access'
      }

      console.log(payload)

      if (this.$refs.addServiceFeeForm.validate()) {
        this.$store.dispatch('phvc/addServiceFee', payload).then(res => {
          if (res.message === 'Good') {
            this.renderToast('success', 'Success!', 'Service Fee Creation Successful')
            this.addServiceFeeDialog = false
            this.$store.dispatch('phvc/getServiceFeeList', {
              user_id: this.currUser.user_id,
              module_id: 19,
              access_permission: 'view_access'
            })
          } else if (res.code === 403) {
            renderToast('error', res.message, res.error.message)
          } else {
            this.renderToast('error', 'Error', 'An error occured while creating a service fee. Please Try Again.')
          }
        }).catch(err => {
          this.renderToast('error', 'Error', 'An error occured while creating a service fee. Please Try Again.')
          console.log(err)
        })
      } else {
        this.renderToast('error', 'Error', 'The required fields must be completed.')
      }
    },
    closeUpdate () {
      this.selectedFeeReset()
      this.updateServiceFeeDialog = false
    },
    closeDelete () {
      this.selectedFeeReset()
      this.deleteServiceFeeDialog = false
    },
    getUserRole (id) {
      if (id) {
        const role = this.collections.userTypes.filter(item => item.id === Number(id))
        if (role.length === 1) return role[0].name
        return ''
      }
      return ''
    },
    selectServiceFee (data) {
      console.log(typeof data.price_fee)

      this.selectedFeeData = {
        id: data.id,
        phvc_code: data.phvc_code,
        product_name: data.product_name,
        price_fee: data.price_fee,
        remarks: data.remarks,
        valid_date_from: data.valid_date_from,
        valid_date_to: data.valid_date_to
      }
    },
    editServiceFee (data) {
      // const payload = {
      //   phvc_code: data.phvc_code,
      //   user_id: this.currUser.user_id,
      //   module_id: 19,
      //   access_permission: 'view_access'
      // }

      // this.$store.dispatch('phvc/getServiceFee', payload).then(res => {
      //   console.log(res)
      // })

      this.selectServiceFee(data)
      this.updateServiceFeeDialog = true
    },
    deleteServiceFee (data) {
      this.selectServiceFee(data)
      this.deleteServiceFeeDialog = true
    },
    async doDelete () {
      if (this.selectedFeeData.id) {
        const req = await this.$store.dispatch('phvc/deleteServiceFee', {
          id: this.selectedFeeData.id,
          user_id: this.currUser.user_id,
          module_id: 19,
          access_permission: 'delete_access'
        })

        if (req.code === 200) {
          this.selectedFeeReset()
          this.deleteServiceFeeDialog = false
          this.$store.dispatch('phvc/getServiceFeeList', {
            user_id: this.currUser.user_id,
            module_id: 19,
            access_permission: 'view_access'
          })
          this.renderToast('success', 'Success!', 'Service Fee Deletion Successful')
        } else if (req.code === 403) {
          renderToast('error', req.message, req.error.message)
        } else {
          this.renderToast('error', 'Error', 'An error occured while deleteing the selected service fee. Please Try Again.')
        }
      }
    },
    async doUpdate () {
      const updatedData = this.selectedFeeData

      const payload = {
        id: updatedData.id,
        phvc_code: updatedData.phvc_code,
        product_name: updatedData.product_name,
        price_fee: updatedData.price_fee,
        remarks: updatedData.remarks,
        valid_date_from: updatedData.valid_date_from,
        valid_date_to: updatedData.valid_date_to,
        date_updated: this.currUser.trx_date,
        updated_by: this.currUser.user_id,
        status: '1',
        default_fee: 0,
        user_id: this.currUser.user_id,
        module_id: 19,
        access_permission: 'update_access'
      }

      if (this.$refs.updateServiceFeeForm.validate() || (updatedData.price_fee !== undefined || updatedData.price_fee !== '' || updatedData.price_fee !== null)) {
        this.$store.dispatch('phvc/updateServiceFee', payload).then(res => {
          if (res.message === 'Good') {
            this.renderToast('success', 'Success!', 'Service Fee Update Successful')
            this.updateServiceFeeDialog = false
            this.$store.dispatch('phvc/getServiceFeeList', {
              user_id: this.currUser.user_id,
              module_id: 19,
              access_permission: 'view_access'
            })
          } else if (res.code === 403) {
            renderToast('error', res.message, res.error.message)
          } else {
            this.renderToast('error', 'Error', 'An error occured while updating the service fee. Please Try Again.')
          }
        }).catch(err => {
          this.renderToast('error', 'Error', 'An error occured while updating the service fee. Please Try Again.')
          console.log(err)
        })
      } else {
        this.renderToast('error', 'Error', 'The required fields must be completed.')
      }
    },
    selectedFeeReset () {
      this.selectedFeeData = {
        phvc_code: '',
        product_name: '',
        price_fee: '',
        remarks: '',
        valid_date_from: '',
        valid_date_to: ''
      }
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    renderToast,
    toProperCase,
    avatarColor,
    avatarInitial
  },
  async beforeMount () {
    await this.$store.dispatch('getAllLocations', 0)
    const res = await this.$store.dispatch('getAllModules', {
      module_code: 0,
      user_id: this.currUser.user_id,
      module_id: 20,
      access_permission: 'view_access'
    })

    if (res.code === 403) {
      renderToast('error', res.message, res.error.message)
    }
  }
}
</script>
