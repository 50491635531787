import { endpoints } from '@/api/endpoints'
import qs from 'qs'

const prompt = endpoints.use('prompt')

export default {
  saveOffer: ({ commit }, payload) => new Promise((resolve, reject) => {
    prompt.saveOffer(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  retrieveOffers: ({ commit }) => new Promise((resolve, reject) => {
    prompt.retrieveOffers()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  retrieveOfferById: ({ commit }, id) => new Promise((resolve, reject) => {
    prompt.retrieveOfferById(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateOffer: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    prompt.updateOffer(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deleteOffer: ({ commit }, id) => new Promise((resolve, reject) => {
    prompt.deleteOffer(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  retrieveQualifiedCustomers: ({ commit }) => new Promise((resolve, reject) => {
    prompt.retrieveQualifiedCustomers()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateQualifiedCustomer: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    prompt.updateQualifiedCustomer(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateQualifiedCustomerStatus: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    prompt.updateQualifiedCustomerStatus(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  checkAvailableOffer: ({ commit }, payload) => new Promise((resolve, reject) => {
    const query = qs.stringify(payload)
    prompt.checkAvailableOffer(query)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
