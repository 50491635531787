export default {
  SET_PERA_DALI_BILLERS (state, payload) {
    state.billers = payload
  },
  SET_PERA_DALI_TPA_ID (state, payload) {
    state.tpaId = payload
  },
  SET_PERA_DALI_CURRENT_PAGE (state, payload) {
    state.peraDaliCurrentPage = payload
  },
  SET_PERA_DALI_TRANSACTIONS (state, payload) {
    state.peraDaliTransactions = payload
  },
  SET_PERA_DALI_RESPONSE_DATA (state, payload) {
    state.peraDaliResponseData = payload
  },
  SET_PERA_DALI_CURRENT_TRX (state, payload) {
    state.peraDaliCurrentTrx = payload
  },
  SET_PERA_DALI_PROVIDERS (state, payload) {
    state.collectionProviders = payload
  },
  SET_PERA_DALI_ACCOUNT_NO (state, payload) {
    state.loanAccountNo = payload
  }
}
