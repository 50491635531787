<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mb-3 mx-2">
      <v-col :cols="3">
        <v-form ref="form">
          <v-row class="mt-1">
            <v-col :cols="12">
              <v-text-field
                v-model="formData.voucherNumber"
                class="ph-textbox"
                label="Voucher Number"
                :rules="requiredRules"
                readonly
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.payee"
                class="ph-textbox"
                label="Payee"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.type"
                :items="types"
                item-text="category_name"
                class="ph-textbox"
                label="Type"
                :rules="requiredRules"
                return-object
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.amount"
                class="ph-textbox"
                label="Amount"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.particulars"
                class="ph-textbox"
                label="Particulars"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-menu
                v-model="isDatePickerOpened"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.dateIncured"
                    :rules="dateRules"
                    class="ph-textbox"
                    label="Date Incured"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="formData.dateIncured"
                  @change="dateIncuredChanged"
                  :min="minDate"
                  @input="isDatePickerOpened = false"
                />
              </v-menu>
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.referenceNumber"
                class="ph-textbox"
                label="Reference Number"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="saveData" class="font-weight-bold" color="primary" block x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { format, parseISO } from 'date-fns'
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections'
    }),
    minDate () {
      return this.currUser.trx_date
    }
  },
  data () {
    return {
      isDatePickerOpened: false,
      currentCount: 0,
      requiredRules: [v => !!v || 'This field is required'],
      dateRules: [
        v => !!v || 'This field is required',
        v => (!v || (!!v && /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v))) || 'Use YYYY-MM-DD format'
      ],
      formData: {
        voucherNumber: '',
        payee: '',
        type: null,
        amount: '',
        particulars: '',
        dateIncured: '',
        referenceNumber: ''
      },
      breadcrumbsItems: [
        {
          text: 'BOS',
          disabled: true
        },
        {
          text: 'MC Rate Request',
          disabled: false,
          exact: true,
          to: '/adjustment-and-expenses'
        },
        {
          text: 'New Entry',
          disabled: true
        }
      ],
      types: []
    }
  },
  methods: {
    async saveData () {
      if (this.$refs.form.validate()) {
        try {
          const { code } = await this.$store.dispatch('adjustmentAndExpenses/saveData', {
            location_id: this.currUser.location_id,
            expense_type_id: this.formData.type?.expense_type_id,
            particulars: this.formData.particulars,
            reference_no: this.formData.voucherNumber,
            amount: this.formData.amount,
            trx_date: this.currUser.trx_date,
            encoded_by: this.currUser.user_id,
            client_ip: this.currUser.ip_address,
            terminal_id: this.currUser.fs_id,
            pettycash_category_id: this.formData.type?.category_id,
            date_incured: this.formData.dateIncured,
            payee: this.formData.payee,
            supplier_ref_no: this.formData.referenceNumber
          })

          if (code === 200) {
            renderToast('success', 'Successful', 'Adjustment & Expenses has been saved')
            this.cancel()
          } else {
            renderToast('error', 'Request Error', 'Failed saving the given data')
          }
        } catch (e) {
          renderToast('error', 'USP Error', 'Failed saving the given data')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill out all required fields')
      }
    },
    cancel () {
      this.$router.push('/adjustment-and-expenses')
    },
    dateIncuredChanged (newValue) {
      if (newValue) {
        const locationCode = this.currUser.location_code
        const date = format(parseISO(newValue), 'MMyyyy')
        const paddedCount = `${this.currentCount}`.padStart(4, '0')
        this.formData.voucherNumber = `${locationCode}${date}-${paddedCount}`
      } else {
        this.formData.voucherNumber = ''
      }
    }
  },
  async beforeMount () {
    const { code: getCountCode, result: getCountResult } = await this.$store.dispatch('adjustmentAndExpenses/getCount', {
      locationId: this.currUser.location_id,
      trxDate: this.currUser.trx_date
    })

    if (getCountCode === 200) {
      this.currentCount = getCountResult[0].exp_count + 1
    } else {
      renderToast('error', 'USP Error', 'Failed fetching the required data')
    }

    const { code: getTypesCode, result: getTypesResult } = await this.$store.dispatch('adjustmentAndExpenses/getTypes')

    if (getTypesCode === 200) {
      this.types = getTypesResult.filter(item => Number(item.expense_type_id) !== 2)
    } else {
      renderToast('error', 'USP Error', 'Failed fetching the required data')
    }
  }
}
</script>
