import { endpoints } from '@/api/endpoints'

const moneyChange = endpoints.use('moneyChange')

export default {
  getGrid: ({ commit }, { trxDate, locationID }) => new Promise((resolve, reject) => {
    moneyChange.grid(trxDate, locationID)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  save: ({ commit }, payload) => new Promise((resolve, reject) => {
    moneyChange.save(payload)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  getRates: ({ commit }, { trxDate, locationID, currencyID }) => new Promise((resolve, reject) => {
    moneyChange.rates(trxDate, locationID, currencyID)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  resetForm ({ state, commit }) {
    commit('SET_CURRENT_PAGE', 0)
    commit('SET_SUCCESS_DATA', null)

    Object.keys(state.transactionData).forEach(prop => {
      let value = ''

      if (prop === 'currencyID') value = 2

      commit('SET_TRANSACTION_DATA', { prop, value })
    })
  }
}
