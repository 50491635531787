import { endpoints } from '@/api/endpoints'

const mcRateRequest = endpoints.use('mcRateRequest')

export default {
  retrieveData: ({ commit }, { trxDate, currencyId, locationId }) => new Promise((resolve, reject) => {
    mcRateRequest.retrieveData(trxDate, currencyId, locationId)
      .then(({ data }) => {
        commit('setItems', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveData: ({ commit }, payload) => new Promise((resolve, reject) => {
    mcRateRequest.saveData(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getRate: ({ commit }, { locationId, currencyId, trxDate }) => new Promise((resolve, reject) => {
    mcRateRequest.getRate(locationId, currencyId, trxDate)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
