export default {
  searchData: state => state.searchData,
  formData: state => state.formData,
  requests: state => state.requests,
  recentlyAdded: state => state.recentlyAdded,
  selectedCustomer: state => state.selectedCustomer,
  policyList: state => state.policyList,
  currentView: state => state.currentView,
  showCustomerCreationModal: state => state.showCustomerCreationModal,
  selectedMobileUser: state => state.selectedMobileUser,
  oldMobileNumber: state => state.oldMobileNumber
}
