export default {
  selectedPromo: null,
  isApprover: false,
  formData: {
    promoType: [],
    promoName: '',
    startDate: '',
    endDate: '',
    frequency: [],
    amountToCredit: '',
    minTransactionAmount: '',
    status: 0
  },
  promoTypes: [],
  frequencyList: []
}
