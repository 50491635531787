<template>
  <v-row>
    <v-col :cols="12" v-if="customerTransactions.length > 0">
      <v-data-table
        :headers="headers"
        :items="customerTransactions"
        class="custom-table"
        calculate-widths
      >
        <template v-slot:[`item.product_type`]="{ item }">
          {{ getProductType(item.product) }}
        </template>
        <template v-slot:[`item.beneficiary`]="{ item }">
          {{ getBeneficiary(item) }}
        </template>
        <template v-slot:[`item.relation_to`]="{ item }">
          <span v-if="item.relation_to">{{ item.relation_to }}</span>
          <span v-else>N/A</span>
        </template>
        <template v-slot:[`item.origin_destination`]="{ item }">
          {{ getOrigin(item) }}
        </template>
        <template v-slot:[`item.destination`]="{ item }">
          {{ getDestination(item) }}
        </template>
      </v-data-table>
    </v-col>
    <v-col :cols="12" class="text-center" v-else>
      <span class="caption">
        Nothing to display
      </span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      headers: [
        {
          text: 'Product Type',
          sortable: true,
          value: 'product_type',
          width: '180px'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customer_name',
          width: '180px'
        },
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date',
          width: '180px'
        },
        {
          text: 'Reference #',
          sortable: true,
          value: 'mtcn',
          width: '180px'
        },
        {
          text: 'Amount Release',
          sortable: true,
          value: 'amount_released',
          width: '180px'
        },
        {
          text: 'Currency',
          sortable: true,
          value: 'currency',
          width: '180px'
        },
        {
          text: 'Purpose of Transaction',
          sortable: true,
          value: 'purpose_transaction',
          width: '180px'
        },
        {
          text: 'Source of Fund',
          sortable: true,
          value: 'sender_source_fund',
          width: '180px'
        },
        {
          text: 'Transaction Type',
          sortable: true,
          value: 'trx_type',
          width: '180px'
        },
        {
          text: 'Risk Score',
          sortable: true,
          value: 'risk_score',
          width: '180px'
        },
        {
          text: 'Beneficiary',
          sortable: true,
          value: 'beneficiary',
          width: '180px'
        },
        {
          text: 'Relation To',
          sortable: false,
          value: 'relation_to',
          width: '180px'
        },
        {
          text: 'Origin',
          sortable: false,
          value: 'origin_destination',
          width: '180px'
        },
        {
          text: 'Destination',
          sortable: false,
          value: 'destination',
          width: '180px'
        }
      ]
    }
  },
  props: {
    customerInfo: {
      type: Object,
      default: null
    },
    customerTransactions: {
      type: Array,
      default: null
    },
    customerRiskLog: {
      type: Array,
      default: null
    }
  },
  methods: {
    getProductType (type) {
      if (String(type) === '1') return 'WU'
      if (String(type) === '2') return 'MC'
      if (String(type) === '3') return 'CICO'
      if (String(type) === '4') return 'UB-CICO'
    },
    getBeneficiary (item) {
      return `${item.receiver_last_name}, ${item.receiver_first_name}`
    },
    getOrigin (item) {
      if (String(item.product) === '2' || String(item.product) === '3') {
        return 'PH'
      } else if (String(item.product) === '1') {
        if (item.trx_type === 'SO') return 'PH'
        else if (item.trx_type === 'PO') return item.origin_destination
      } else if (item.origin_destination) return 'PH'
    },
    getDestination (item) {
      if (String(item.product) === '2' || String(item.product) === '3') {
        return 'PH'
      } else if (String(item.product) === '1') {
        if (item.trx_type === 'SO') return item.origin_destination
        else if (item.trx_type === 'PO') return 'PH'
        else if (item.origin_destination) return 'PH'
      }
    }
  }
}
</script>
