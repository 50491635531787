<template>
  <v-row class="ph-cico-cashin-forms-container">
    <v-col cols="12" md="8" class="ph-cico-cashin-form">
      <TransactionProfileCard />
      <div class="ph-cico-fields-title">
        <b>Transaction Details</b>
      </div>
      <br>
      <v-form ref="collectionForm">
        <v-row class="ph-cico-fields">
          <v-col class="py-6" cols="12">
            <v-autocomplete
              v-model="transactionData.provider"
              label="Provider"
              :items="collectionProviders.result"
              :rules="requiredField"
              :item-text="$route.query.provider_type === 'loans' ? 'provider' : 'provider_name'"
              item-value="provider_code"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Provider"
              @change="getProviderInfo($event)"
              :return-object="true"
            >
            </v-autocomplete>
          </v-col>
          <v-col class="py-6" cols="12" md="6">
            <v-text-field
              v-model="transactionData.reference_number"
              label="Reference Number"
              color="gray darken-1"
              class="ph-textbox"
              :rules="requiredField"
              outlined
            />
          </v-col>
          <v-col class="py-6" cols="12" md="6" v-if="hasOar">
            <v-text-field
              v-model="transactionData.oar_number"
              label="OAR Number"
              color="gray darken-1"
              class="ph-textbox"
              :rules="requiredField"
              outlined
              readonly
            />
          </v-col>
          <!-- Dynamic Fields -->
          <v-col class="py-6" v-for="(item, index) in collectionFields" :key="index" cols="12" md="4">
            <v-text-field
              v-model="dynamicTrxData[item.name_of_the_field]"
              :label="item.name_of_the_field"
              color="gray darken-1"
              class="ph-textbox"
              :rules="requiredField"
              outlined
              :persistent-hint="true"
              :hint="item.data_type"
              :maxlength="item.field_length"
              @change="handleTextValidation($event, item.data_type, item.name_of_the_field)"
              :refs="item.name_of_the_field"
            />
          </v-col>
          <!-- Remarks and Amount -->
          <v-col class="py-6" cols="12">
            <v-text-field
              v-model="transactionData.remarks"
              label="Remarks"
              color="gray darken-1"
              class="ph-textbox"
              :rules="requiredField"
              outlined
            />
          </v-col>
          <v-col class="py-6" cols="12" md="4">
            <v-text-field
              v-model="transactionData.amount"
              :label="trxType === 'D' ? 'Amount to Withdraw' : 'Amount to Collect'"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="number"
              :rules="requiredField"
              :hide-spin-buttons="true"
            />
          </v-col>
          <v-col class="py-6" cols="12" md="4">
            <v-text-field
              v-model="transactionData.service_fee"
              label="Service Fee"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="number"
              :readonly="$route.query.provider_type === 'loans'"
              :rules="transactionData.service_fee === 0 ? [] : requiredField"
              :hide-spin-buttons="true"
            />
          </v-col>
          <v-col class="py-6" cols="12" md="4">
            <v-text-field
              v-model="transactionData.total_amount"
              :label="trxType === 'D' ? 'Amount to Release' : 'Total Amount'"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              :rules="transactionData.service_fee === 0 ? [] : requiredField"
              readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="ph-stepper-action-buttons">
            <v-btn
              color="danger"
              outlined
              text
              light
              class="ph-primary-button ph-stepper-button ph-action-button"
              @click="cancelButton()"
            >
              Cancel
            </v-btn>
            <v-spacer/>
            <v-spacer/>
            <v-btn
              color="primary"
              elevation="0"
              class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
              @click="summaryDialog = true"
              v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            >
              Next
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="4" class="ph-cico-cashin-summary">
      <v-card
        elevation="0"
        color="rgba(26, 39, 145, 0.05)"
      >
        <div class="ph-transfer-summary-header">
          <b>Summary Overview</b>
        </div>
        <br>
        <div class="ph-transfer-summary-content">
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in billsSummaryOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="ph-transfer-summary-action">
          <v-btn
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn ph-validate-cash-in-desktop"
            @click="validatePayment"
          >
            Confirm
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-cico-cashin-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-cico-cashin-summary-minified">
          <div class="ph-cico-cashin-summary-content">
            <div class="ph-cico-cashin-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>PHP {{transactionData.total_amount > 0 ? transactionData.total_amount : '-'}}</b></p>
            </div>
            <div class="ph-cico-cashin-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <div class="ph-transfer-summary-content">
                <p class="ph-transfer-summary-content-title">Please confirm if the payment details are correct</p>
                <v-simple-table class="ph-transfer-summary-table">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in billsSummaryOverview"
                        :key="item.name"
                      >
                        <td class="transfer-info-title">{{ item.name }}</td>
                        <td class="transfer-info-value">{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="ph-transfer-summary-action">
                <v-btn
                  color="primary"
                  elevation="0"
                  class="ph-primary-button ph-primary-execute-btn ph-validate-cash-in"
                  @click="validatePayment"
                >
                  Confirm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noIDFlag"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          No identification card found
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="noIDFlag = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="updateDetails(selectedCustomer)"
          >
            Update Customer Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- OTP -->
    <v-dialog
      v-model="billsOTPDialog"
      persistent
      :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '30%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="billsOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <br>
          <p v-if="!showAmount"><b>Amount to Cash-In: </b>PHP {{formatAmount(transactionData.amount)}}</p>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { avatarColor, avatarInitial, renderToast } from '@/utils'
import { format, parseISO } from 'date-fns'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'

export default {
  name: 'BillsCollectionForm',
  data () {
    return {
      billsCollectionForm: 1,
      noIDFlag: false,
      billsOTPDialog: false,
      isOTPLoading: false,
      otpInvalid: false,
      otp: '',
      otpMessage: '',
      summaryDialog: false,
      isNotCustomerPage: true,
      showAmount: true,
      billsSummaryOverview: [],
      mobileNumber: '',
      amount: '',
      billId: '',
      petnetTrackingNo: '',
      currentProvider: '',
      currentProviderAmountPlaceholder: '',
      currentProviderTooltip: '',
      transactionData: {},
      dynamicTrxData: {},
      otherInfo: {},
      collectionFields: [],
      clientRefNo: '',
      row: null,
      hasOar: false,
      trxType: '',
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  computed: {
    ...mapGetters({
      billers: 'bills/billers',
      collectionProviders: 'bills/collectionProviders',
      tpaId: 'bills/tpaId',
      billsCollectionCurrentTrx: 'bills/billsCollectionCurrentTrx',
      collections: 'collections',
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer',
      billsCollectionTransactions: 'bills/billsCollectionTransactions',
      oarNumber: 'oar/oarNumber',
      billsLoanAccountNo: 'bills/billsLoanAccountNo'
    })
  },
  components: {
    TransactionProfileCard
  },
  watch: {
    dynamicTrxData: {
      handler (val) {
        console.log(val)
      },
      deep: true
    },
    transactionData: {
      handler (val) {
        console.log(val)
        if (this.trxType === 'D') {
          this.transactionData.total_amount = parseFloat(val.amount ? val.amount : '0') - parseFloat(val.service_fee ? val.service_fee : '0')
        } else {
          this.transactionData.total_amount = parseFloat(val.amount ? val.amount : '0') + parseFloat(val.service_fee ? val.service_fee : '0')
        }

        this.updateOverview(val)
      },
      deep: true
    }
  },
  async mounted () {
    if (this.selectedCustomer) {
      await this.$store.dispatch('bills/getCollectionTrx', {
        trx_date: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id
      })

      const collectionProviderPayload = {
        location_id: this.currUser.location_id
      }

      if (this.$route.query.provider_type === 'loans') {
        await this.$store.dispatch('bills/getLoanProvider', collectionProviderPayload)
      } else {
        await this.$store.dispatch('bills/getOtherProvider', collectionProviderPayload)
      }

      const trxCount = this.billsCollectionTransactions.length + 1
      const trxCountString = trxCount.toString()

      this.billsSummaryOverview = [
        { name: 'Provider', value: '-' },
        { name: 'Reference Number', value: '-' },
        { name: 'OAR Number', value: '-' },
        { name: 'Service Fee', value: '-' },
        { name: 'Amount to Collect', value: '-' },
        { name: 'Total Amount to Collect', value: '-' }
      ]

      this.transactionData = {
        service_fee: this.$route.query.provider_type === 'loans' ? 0.00 : '',
        reference_number: `${this.currUser.location_code}${format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyyMMdd')}${trxCountString.padStart(4, '0')}`
      }
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    renderToast,
    async getProviderInfo (providerInfo) {
      this.collectionFields = JSON.parse(providerInfo.provider_fields)

      const dynamicFields = {}
      this.dynamicTrxData = {}

      this.collectionFields.forEach(item => {
        const checkIfHasAccountNumber = this.$route.query.provider_type === 'loans' && (item.name_of_the_field === 'Account Number' || item.name_of_the_field === 'Account')

        dynamicFields[item.name_of_the_field] = checkIfHasAccountNumber ? this.billsLoanAccountNo : ''
      })

      this.dynamicTrxData = Object.assign({}, this.dynamicTrxData, dynamicFields)

      const checkIfHasOar = this.collectionFields.filter(item => item.hasOar === true)
      this.hasOar = checkIfHasOar
      this.trxType = providerInfo.trx_type

      if (this.hasOar) {
        await this.$store.dispatch('oar/getOarNumber', {
          locationID: this.currUser.location_id,
          type: 'OAR'
        }).then(res => {
          if (res.result === null) {
            this.renderToast('warning', 'No OAR available', 'Please allocate OAR first to proceed to the transaction')
          } else {
            const oarNumberProp = { oar_number: this.oarNumber.series_no }
            this.transactionData = Object.assign({}, this.transactionData, oarNumberProp)
          }
        })
      }
      console.log(providerInfo)
    },
    formatPayload (data) {
      const otherInfoData = {}

      Object.entries(data).forEach(item => {
        const currentProp = item[0]
        const splitObject = currentProp.split('.')
        const getParentObject = splitObject[0]
        const getChildItem = splitObject[1]

        if (!otherInfoData?.otherInfo) {
          otherInfoData[getParentObject] = {
            [getChildItem]: item[1]
          }
        } else {
          const otherInfoItem = otherInfoData.otherInfo
          otherInfoItem[getChildItem] = item[1]
        }
      })

      data.otherInfo = otherInfoData.otherInfo

      Object.entries(data).forEach(item => {
        const isPropObject = item[0].includes('.')

        if (isPropObject) {
          delete data[item[0]]
        }
      })

      return data
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    updateOverview (data) {
      this.billsSummaryOverview = [
        { name: 'Provider', value: data.provider ? (this.$route.query.provider_type === 'loans' ? data.provider.provider : data.provider.provider_name) : '-' },
        { name: 'Reference Number', value: data.reference_number ? data.reference_number : '-' },
        { name: 'OAR Number', value: data.oar_number ? data.oar_number : '-' },
        { name: 'Service Fee', value: data.service_fee ? `PHP ${this.formatAmount(data.service_fee)}` : '-' },
        { name: this.trxType === 'D' ? 'Amount to Withdraw' : 'Amount to Collect', value: data.amount ? `PHP ${this.formatAmount(data.amount)}` : '-' },
        { name: this.trxType === 'D' ? 'Total Amount to Release' : 'Total Amount to Collect', value: data.total_amount ? `PHP ${this.formatAmount(data.total_amount)}` : '-' }
      ]
    },
    selectPartner (code, provider) {
      this.currentProvider = code
      this.renderToast('info', 'Provider selected', `${provider} was selected.`)
    },
    async validatePayment () {
      if (this.$refs.collectionForm.validate()) {
        this.openVerifyOTP()
      } else {
        this.renderToast('error', 'Validation Error', 'You must complete the following fields')
      }
    },
    async transactPayment () {
      const currentCustomer = this.selectedCustomer
      const currentUser = this.currUser
      const transactionData = this.transactionData
      const dynamicTrxData = this.dynamicTrxData
      const printReceiptDataArray = []
      printReceiptDataArray.push(JSON.stringify(dynamicTrxData))
      const printReceiptDataArrayString = printReceiptDataArray.toString()

      const payload = {
        location_id: currentUser.location_id.toString(),
        user_id: currentUser.user_id.toString(),
        trx_date: currentUser.trx_date,
        reference_no: transactionData.reference_number,
        principal_amount: transactionData.amount,
        service_fee: this.$route.query.provider_type === 'loans' ? 0 : transactionData.service_fee,
        total_amount: transactionData.total_amount,
        provider_id: transactionData.provider.provider_id.toString(),
        provider: this.$route.query.provider_type === 'loans' ? transactionData.provider.provider : transactionData.provider.provider_name,
        customer_id: currentCustomer.customer_id.toString(),
        customer_name: currentCustomer.full_name,
        print_receipt: this.$route.query.provider_type === 'loans' ? `${dynamicTrxData['Account Number'] ? dynamicTrxData['Account Number'] : dynamicTrxData.Account}` : `[${printReceiptDataArrayString}]`,
        client_ip: this.currUser.ip_address,
        date_encoded: format(new Date(), 'yyyy-MM-dd'),
        trx_type: transactionData.provider.trx_type,
        form_type: this.currUser.location_type === '1' ? 'OAR' : '',
        form_number: transactionData.oar_number,
        remarks: transactionData.remarks
      }

      await this.$store.dispatch('bills/executeCollectionTrx', payload).then(res => {
        console.log(res)

        if (res.message === 'Good') {
          this.$store.commit('bills/SET_BILLS_COLLECTION_CURRENT_TRX', payload)
          this.$store.commit('bills/SET_BILLS_COLLECTION_CURRENT_PAGE', 2)
        } else {
          this.renderToast('error', 'Transaction Failed', 'Something went wrong when processing the transaction, please try again.')
        }
      }).catch(e => {
        console.log(e)
        this.renderToast('error', 'Transaction Failed', 'Something went wrong when processing the transaction, please try again.')
      })
    },
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    openVerifyOTP () {
      this.otp = ''
      this.billsOTPDialog = true

      console.log(this.billsOTPDialog)
    },
    updateDetails (customerDetails) {
      this.noIDFlag = false

      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    cancelButton () {
      this.$store.commit('bills/SET_BILLS_COLLECTION_CURRENT_PAGE', 0)
    },
    async doVerify (pin) {
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        const payload = {
          user_name: this.currUser.user_name,
          otp_code: this.otp,
          pin: this.currUser.otp_pin,
          module: 'send'
        }

        const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)
        const isOTPVerified = OTPVerification.result?.value || false

        if (isOTPVerified) {
          this.otpInvalid = false
          this.isOTPLoading = false
          this.billsOTPDialog = false
          this.transactPayment()
        } else {
          this.otp = ''
          this.otpInvalid = true
          this.isOTPLoading = false
          this.otpMessage = 'Incorrect OTP PIN, please try again.'
        }
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    },
    toggleForm (step) {
      this.billsCollectionForm = step

      switch (step) {
        case 1:
          this.$store.commit('SET_PAGE_TITLE', 'Transfer Information')
          break
        case 2:
          this.$store.commit('SET_PAGE_TITLE', 'Receiver Details')
          break
      }
    },
    handleTextValidation (value, dataType, fieldName) {
      let currentText = ''
      let regex = ''

      currentText = value

      switch (dataType) {
        case 'Alpha Characters with special chars (,.-)':
          regex = currentText.replace(/[^a-zA-Z .,-]/, '')

          if (currentText !== regex) {
            this.dynamicTrxData[fieldName] = regex
          }
          break
        case 'Alpha characters only':
          regex = currentText.replace(/[^a-zA-Z ]/, '')

          if (currentText !== regex) {
            this.dynamicTrxData[fieldName] = regex
          }
          break
        case 'Numeric with special chars (,.-)':
          regex = currentText.replace(/[^0-9 .,-]/, '')

          if (currentText !== regex) {
            this.dynamicTrxData[fieldName] = regex
          }
          break
        case 'Numeric only':
          regex = currentText.replace(/[^0-9 ]/g, '')

          console.log(currentText, regex)
          if (currentText !== regex) {
            this.dynamicTrxData[fieldName] = regex
          }
          break
        case 'Alphanumeric with special chars (,.-)':
          regex = currentText.replace(/[^a-zA-Z0-9 .,-]/, '')

          if (currentText !== regex) {
            this.dynamicTrxData[fieldName] = regex
          }
          break
        case 'Alphanumeric only':
          regex = currentText.replace(/[^a-zA-Z0-9 ]/, '')

          if (currentText !== regex) {
            this.dynamicTrxData[fieldName] = regex
          }
          break
      }
    }
  }
}
</script>
<style scoped>
  .v-stepper.ph-wu-receive-stepper .v-stepper__header {
    box-shadow: none !important;
  }

  .ph-cico-cashin-summary .v-card {
    padding: 20px;
  }

  .ph-transfer-summary-header {
    font-size: 18px;
    text-align: center;
  }

  .ph-transfer-summary-content-title {
    font-size: 14px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
    border: 2px solid #1A2791;
  }

  .ph-transaction-container {
    padding: 0;
  }

  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 100px;
  }

  .ph-cico-cashin-summary-minified {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F4F4FA;
  }

  .ph-cico-cashin-summary-content {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
  }

  .ph-cico-cashin-summary-minified-info {
    margin-right: auto;
  }

  .ph-cico-cashin-summary-minified-info p {
    margin-bottom: 0;
  }

  .ph-cico-cashin-summary-minified-action {
    margin-left: auto;
  }

  .ph-primary-button.ph-stepper-button {
    height: 40px !important;
    width: 115px;
  }

  .ph-cico-cashin-form {
    padding: 12px 0;
  }

  /* @media only screen and (min-width: 600px) {
    .v-stepper.ph-wu-quickpay-stepper .v-stepper__step {
      padding-left: 0;
    }
  } */

  .transfer-info-title,
  .transfer-info-value {
    border-bottom: thin dashed rgba(0,0,0,.12) !important;
  }

  .ph-cico-providers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .ph-cico-partner-container {
    height: 100px;
    display: flex;
    z-index: 2;
    border: 1px solid transparent;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08) !important;
    border-radius: 12px !important;
    transition: all .2s;
    cursor: pointer;
  }

  .ph-cico-partner-container:hover {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  .ph-cico-partner-icon-img {
    margin: auto;
    object-fit: fill;
    z-index: 1;
  }

  .ph-cico-partner-icon-img.partner-paymaya {
    height: 50px;
    width: auto;
    /* position: relative;
    bottom: 15px; */
  }

  .ph-cico-partner-icon-img.partner-diskartech {
    height: 45px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-dragonpay {
    height: 65px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-ph,
  .ph-cico-partner-icon-img.partner-eon {
    height: 30px;
    width: auto;
  }

  .ph-cico-fields,
  .ph-cico-back-button {
    margin: 0;
  }

  .ph-cico-fields {
    margin-left: -10px;
    margin-right: -10px;
  }

  .ph-cico-partner-container.selected {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  .ph-bills-radio-button {
    padding: 15px;
    width: calc(30% + 11px);
    background-color: #F7F7F7;
    border-radius: 10px;
  }

  @media only screen and (min-width: 960px) {
    .ph-stepper-action-buttons {
      display: flex;
      margin-bottom: 0;
    }

    .ph-cico-cashin-forms-container {
      margin: 0;
    }
  }
</style>
