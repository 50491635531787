<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-card-title class="ph-nonex-datatable-title">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="ph-textbox ph-textbox-grid-search"
                outlined
              ></v-text-field>
            </v-card-title>
            <v-tabs
              v-model="tab"
            >
              <v-tabs-slider color="#1A2791"></v-tabs-slider>

              <v-tab
                v-for="item in tableTabs"
                :key="item.name"
              >
                <b>{{ item.title }}</b>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="item in tableTabs"
                :key="item.name"
              >
                <div v-if="item.name === 'acknowledgment'">
                  <v-data-table
                    :headers="headers"
                    :items="allocatedCards"
                    :search="search"
                    :calculate-widths="true"
                    class="ph-nonex-datatable"
                  >
                    <template v-slot:[`item.expiry_date`]="{item}">
                      <p class="ph-nonex-table-names-text text-amount">
                        {{item.expiry_date ? item.expiry_date : '-'}}
                      </p>
                    </template>
                    <template v-slot:[`item.batch_number`]="{item}">
                      <p class="ph-nonex-table-names-text text-amount">
                        {{item.batch_number ? item.batch_number : '-'}}
                      </p>
                    </template>
                    <template v-slot:[`item.current_status`]="{ item }">
                      <span class="ph-nonex-status-container red--text" v-if="item.current_status === 2">
                        <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                        Acknowledged
                      </span>
                      <span class="ph-nonex-status-container green--text" v-if="item.current_status === 1">
                        <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                        Allocated
                      </span>
                    </template>
                    <template v-slot:[`item.action`]="{item}">
                      <v-btn
                        color="danger"
                        outlined
                        text
                        light
                        v-if="item.current_status === 1"
                        class="ph-primary-button ph-primary-execute-btn ph-action-button"
                        @click="acknowledgeCard(item)"
                      >
                        Acknowledge
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
                <div v-if="item.name === 'view'">
                  <div class="ph-cards-inventory-counter">
                    <p><b>Current Inventory:</b> {{currentCount}}</p>
                    <p><b>Incoming Inventory:</b> {{incomingCount}}</p>
                  </div>
                  <v-data-table
                    :headers="cardsInventoryHeader"
                    :items="acknowledgedCards"
                    :search="search"
                    :calculate-widths="true"
                    class="ph-nonex-datatable"
                  >
                    <template v-slot:[`item.current_status`]="{ item }">
                      <span class="ph-nonex-status-container green--text" v-if="item.current_status === 2">
                        <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                        Acknowledged
                      </span>
                      <span class="ph-nonex-status-container orange--text" v-if="item.current_status === 1">
                        <v-icon color="orange" class="mr-1" x-small>mdi-circle</v-icon>
                        Allocated
                      </span>
                    </template>
                    <template v-slot:[`item.action`]="{item}">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="item.current_status === 2"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="cancelAcknowledgment(item)"
                          >
                            <v-icon>mdi-credit-card-off</v-icon>
                          </v-btn>
                        </template>
                        <span>Cancel Acknowledgment</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
      <v-fab-transition>
        <v-btn
          color="primary"
          fab
          large
          fixed
          dark
          bottom
          right
          @click="requestCardDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
      <!-- Request Card -->
      <v-dialog v-model="requestCardDialog" :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '50%'" persistent>
        <v-card>
          <v-form ref="requestCardForm">
            <v-card-title>
              <h3 class="custom-heading pt-2 pb-2 primary--text font-weight-black">
                Request Card
              </h3>
              <v-spacer />
              <v-btn @click="requestCardDialog = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col :cols="12" :md="12">
                  <v-textarea
                    v-model="requestCardData.message"
                    class="ph-textarea"
                    label="Message"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-md-8 pt-md-4 pr-md-6">
              <v-spacer />
              <v-btn
                color="primary"
                @click="sendEmail()"
                class="ph-action-button ph-action-button-receive-confirm"
                elevation="0"
              >
                <b>Send Email</b>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <!-- Acknowledge Card -->
      <v-dialog
        v-model="confirmAcknowledgeDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Acknowledge This Card?
          </v-card-title>

          <v-card-text>
            <br>
            <p><b>Are you sure you want to acknowledge the selected card?</b></p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              class="ph-action-button ph-stepper-button"
              elevation="0"
              @click="confirmAcknowledgeDialog = false; selectedCardToAcknowledge = {}"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="executeAcknowledgeCard(selectedCardToAcknowledge)"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Cancel Acknowledgment -->
       <v-dialog
        v-model="confirmCancelDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Cancel Acknowledgment?
          </v-card-title>

          <v-card-text>
            <br>
            <p><b>Are you sure you want cancel the acknowledgment of the selected card?</b></p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              class="ph-action-button ph-stepper-button"
              elevation="0"
              @click="confirmCancelDialog = false; selectedCardToCancel = {}"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="executeCancelAcknowledge(selectedCardToCancel)"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'PHVCardInventory',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Cards',
          disabled: false,
          href: '/cards'
        },
        {
          text: 'Card Inventory',
          disabled: false,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Card Number',
          sortable: true,
          value: 'idub_card_inventory'
        },
        {
          text: 'Expiry Date',
          sortable: true,
          value: 'expiry_date'
        },
        {
          text: 'Batch Number',
          sortable: true,
          value: 'batch_number'
        },
        {
          text: 'Category',
          sortable: true,
          value: 'card_category'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'current_status'
        },
        {
          text: 'Change Status',
          sortable: false,
          value: 'action'
        }
      ],
      cardsInventoryHeader: [
        {
          text: 'Category',
          sortable: true,
          value: 'card_category'
        },
        {
          text: 'Serial Number',
          sortable: true,
          value: 'serial_number'
        },
        {
          text: 'Expiry Date',
          sortable: true,
          value: 'expiry_date'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'current_status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      search: '',
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      transition: 'slide-y-reverse-transition',
      successTransactionData: {},
      tab: null,
      tableTabs: [
        { name: 'acknowledgment', title: 'Acknowledgment Card' },
        { name: 'view', title: 'View Cards Inventory' }
      ],
      requestCardDialog: false,
      requestCardData: [],
      currentCount: 0,
      incomingCount: 0,
      allocatedCards: [],
      acknowledgedCards: [],
      confirmAcknowledgeDialog: false,
      confirmCancelDialog: false,
      selectedCardToAcknowledge: {},
      selectedCardToCancel: {},
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'cico/transactions',
      currUser: 'auth/currUser',
      cardInventory: 'debitCard/cardInventory'
    })
  },
  async mounted () {
    this.getGrids()
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    acknowledgeCard (item) {
      this.confirmAcknowledgeDialog = true
      this.selectedCardToAcknowledge = item
    },
    cancelAcknowledgment (item) {
      this.confirmCancelDialog = true
      this.selectedCardToCancel = item
    },
    getGrids () {
      const getAllocatedCards = {
        location_id: this.currUser.location_id,
        card_category: 'PERAHUB',
        current_status: 1
      }

      const getAcknowledgedCards = {
        location_id: this.currUser.location_id,
        card_category: 'PERAHUB',
        current_status: 2
      }

      this.$store.dispatch('debitCard/getCardInventory', getAllocatedCards).then(res => {
        this.allocatedCards = res
        this.incomingCount = this.allocatedCards.length
      })

      this.$store.dispatch('debitCard/getCardInventory', getAcknowledgedCards).then(res => {
        this.acknowledgedCards = res
        this.currentCount = this.acknowledgedCards.length
      })
    },
    executeAcknowledgeCard (item) {
      const payload = {
        id: item.idub_card_inventory,
        data: {
          user_id: this.currUser.user_id,
          current_status: 2
        }
      }

      this.$store.dispatch('debitCard/updateCardInventory', payload).then(res => {
        if (res) {
          this.getGrids()
          this.renderToast('success', 'Success', 'Card Allocation Successful')
          this.confirmAcknowledgeDialog = false
        } else {
          this.renderToast('error', 'Error', 'An error has occured, please try again.')
          this.confirmAcknowledgeDialog = false
        }
      }).catch(err => {
        console.log(err)
        this.renderToast('error', 'Error', 'An error has occured, please try again.')
      })
    },
    executeCancelAcknowledge (item) {
      const payload = {
        id: item.idub_card_inventory,
        data: {
          user_id: this.currUser.user_id,
          current_status: 1
        }
      }

      this.$store.dispatch('debitCard/updateCardInventory', payload).then(res => {
        if (res) {
          this.getGrids()
          this.renderToast('success', 'Success', 'Card Acknowledgment Cancellation Successful')
          this.confirmCancelDialog = false
        } else {
          this.renderToast('error', 'Error', 'An error has occured, please try again.')
          this.confirmCancelDialog = false
        }
      }).catch(err => {
        console.log(err)
        this.renderToast('error', 'Error', 'An error has occured, please try again.')
      })
    },
    sendEmail () {
      console.log(this.requestCardData.message)
      if (this.requestCardData?.message) {
        const payload = {
          location_id: this.currUser.location_id,
          mail_body: this.requestCardData.message
        }

        this.$store.dispatch('debitCard/requestCardEmail', payload).then(res => {
          if (res.code === 200) {
            this.renderToast('success', 'Success', 'An Email Request has been sent.')
            this.requestCardDialog = false
          } else {
            this.renderToast('error', 'Error', res.error.message)
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.renderToast('error', 'Error', 'Message is Required.')
      }
    }
  }
}
</script>
<style scoped>
/* .ph-nonex-datatable-title {
  padding-left: 0;
} */

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.7);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

.ph-nonex-table-names-text.text-amount {
  margin-left: 0 !important;
}

.text-inquire-button {
  cursor: pointer;
}

.ph-cards-inventory-counter {
  display: flex;
  flex-direction: row;
  padding: 15px;
  padding-bottom: 10px;
}

.ph-cards-inventory-counter p:first-child {
  margin-right: 15px;
}

/* .ph-textbox.ph-textbox-grid-search {
  width: 256px;
} */
</style>
