<template>
  <component :is="this.$route.meta.layout">
    <LoadingScreen />
    <router-view />
    <Snackbar />
    <AVPCrossSellingSpiel />
    <TokenReminder v-if="this.$route.name !== 'Login' && this.$route.name !== 'VerifyOneTimeCode'" />
    <Prompt />
    <OfferPrompt />
    <wacom-idle v-if="currUser.has_wacom" />
    <CompliancePNAAlertDialog />
    <ComplianceHighRiskDialog />
  </component>
</template>

<script>
import CompliancePNAAlertDialog from '@/components/compliance/PNAAlertDialog.vue'
import ComplianceHighRiskDialog from '@/components/compliance/HighRiskDialog.vue'
import Prompt from '@/components/transactionLimit/prompt.vue'
import OfferPrompt from '@/components/prompt/OfferPrompt.vue'
import LoadingScreen from '@/components/partials/LoadingScreen.vue'
import Snackbar from '@/components/partials/Snackbar.vue'
import AVPCrossSellingSpiel from '@/components/partials/AVPCrossSellingSpiel.vue'
import TokenReminder from '@/components/partials/TokenReminder.vue'
import { mapGetters } from 'vuex'
import wacomIdle from '@/components/wacom/wacomIdle'
import wacomMixin from '@/mixins/wacom.js'
// import { format } from 'date-fns'

export default {
  components: {
    CompliancePNAAlertDialog,
    ComplianceHighRiskDialog,
    Prompt,
    OfferPrompt,
    LoadingScreen,
    Snackbar,
    AVPCrossSellingSpiel,
    TokenReminder,
    wacomIdle
  },
  mixins: wacomMixin,
  data () {
    return {
      currentUser: {},
      expireChecker: {}
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      runSession: 'auth/runSession',
      authTimestamp: 'auth/authTimestamp'
    })
  },
  watch: {
    currUser: {
      handler (user) {
        this.currentUser = user
      }
    },
    runSession: {
      handler (value) {
        if (value === false) {
          clearInterval(this.expireChecker)
        } else {
          this.expireChecker = setInterval(this.checkTime, 5000)
        }
      },
      deep: true
    },
    authTimestamp: {
      handler () {
      },
      deep: true
    }
  },
  beforeMount () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
  },
  mounted () {
    if (this.$route.name !== 'Login' && this.$route.name !== 'VerifyOneTimeCode' && this.$route.name !== 'ForgetPassword' && this.$route.name !== 'ResetPassword') {
      this.populateCollections()
      this.$store.dispatch('auth/doCheckToken', this.currUser.token).then(res => {
        if (!res.data.result) {
          this.logout()
        } else {
          setInterval(this.checkTime, 5000)
        }
      })
    }
  },
  methods: {
    async logout () {
      localStorage.removeItem('udata')

      this.$store.commit('auth/SET_IS_OTP_PASSED', false)
      this.$store.commit('wacom/SET_WACOM_IDLE_DIALOG', false)
      this.$store.commit('auth/SET_CURRENT_USER', {})
      this.$store.commit('auth/AUTH_SUCCESS', false)
      this.$store.commit('app/TOGGLE_LOADING', false)
      this.$store.commit('auth/SET_SHOW_REFRESH', false)

      this.$cookies.remove('USP_DATA', '/', this.$uspBaseDomain)
      this.$cookies.remove('USP_TOKEN', '/', this.$uspBaseDomain)
      this.$cookies.remove('USP_MODULES', '/', this.$uspBaseDomain)
      this.$cookies.remove('USP_AUTH_TIMESTAMP', '/', this.$uspBaseDomain)

      window.location.href = `${this.$uspAuthAppURL}/login`
      localStorage.setItem('logout_message', 'Your session has expired. Please login again to continue.')
    },
    populateCollections () {
      [
        'getSourceOfFund',
        'getRelationship',
        'getRelationshipDppv2',
        'getRelationshipRuralNet',
        'getCountries',
        'getNatureOfWork',
        'getPositionLevel',
        'getEmployment',
        'getCities'
      ].forEach(action => this.$store.dispatch(action))
    },
    checkTime () {
      const currentTime = new Date()
      const expireTime = new Date(this.authTimestamp.timeToExpire)
      const loggedInTime = new Date(this.authTimestamp.timeLoggedIn)
      const actualExpireTime = new Date(loggedInTime.getTime() + (23 * 60 * 1000))

      // if logged-in time exceeds 20 minutes
      if (currentTime.getTime() > actualExpireTime.getTime()) {
        this.logout()
      }

      // trigger reminder 2 mins before expiry
      if (currentTime.getTime() > expireTime.getTime() - (1 * 60 * 1000)) {
        this.$store.commit('auth/SET_SHOW_REFRESH', true)
        this.$store.commit('auth/SET_RUN_SESSION', false)
        clearInterval(this.expireChecker)
      }
    }
  }
}
</script>

<style lang="scss">
  // @import url('sass/variables.scss');
  @import url('sass/font.scss');
</style>

<!-- CSS -->
<style>
  @import url('assets/fonts/font.css');
  @import url('css/ph-default.css');
</style>
