<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row v-if="approverLevel === 'ADMIN'" class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="12" :md="3">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search Approver"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="d-flex align-center pl-md-2" :cols="12" :md="9">
        <v-btn @click="getApprovers" class="text-capitalize font-weight-bold" color="primary" rounded large text>
          <v-icon class="mr-1">mdi-refresh</v-icon>
          Refresh
        </v-btn>
      </v-col>
      <v-col class="my-6" :cols="12">
        <v-data-table
          :headers="headers"
          :items="approversData"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:item.action="{ item }">
            <v-btn @click="edit(item)" color="primary" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="openDeleteDialog(item)" color="primary" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDeleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Approver
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this approver?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDeleteDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="doDelete"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-fab-transition v-if="approverLevel === 'ADMIN'">
      <v-btn
        @click="create"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      selectedApprover: 'transactionLimit/selectedApprover',
      approverLevel: 'transactionLimit/approverLevel',
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      search: '',
      approversData: [],
      showDeleteDialog: false,
      headers: [
        {
          text: 'Name',
          value: 'approver_name',
          width: '150px'
        },
        {
          text: 'Level',
          value: 'approver_level',
          width: '150px'
        },
        {
          text: 'Email Address',
          value: 'email',
          width: '150px'
        },
        {
          text: 'Action',
          value: 'action',
          width: '100px',
          sortable: false
        }
      ],
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Approvers',
          disabled: true
        }
      ]
    }
  },
  methods: {
    create () {
      this.$router.push('/transaction-limit/approvers/new')
    },
    async getApprovers () {
      const { code, result } = await this.$store.dispatch('transactionLimit/getApprovers')

      if (code === 200) {
        this.approversData = result.sort((x, y) => {
          return (new Date(y.created_at).getTime() - new Date(x.created_at).getTime())
        })
      }
    },
    edit (payload) {
      this.$store.commit('transactionLimit/SET_SELECTED_APPROVER', payload)
      this.$router.push(`/transaction-limit/approvers/${payload.id}`)
    },
    async doDelete () {
      if (this.selectedApprover) {
        const { code } = await this.$store.dispatch('transactionLimit/deleteApprover', this.selectedApprover.id)

        if (code === 200) {
          renderToast('success', 'Successful', 'Approver has been deleted')
          this.closeDeleteDialog()
          this.getApprovers()
        } else {
          renderToast('error', 'Failed', 'Failed to delete approver')
        }
      }
    },
    openDeleteDialog (payload) {
      this.$store.commit('transactionLimit/SET_SELECTED_APPROVER', payload)
      this.showDeleteDialog = true
    },
    closeDeleteDialog () {
      this.$store.commit('transactionLimit/SET_SELECTED_APPROVER', null)
      this.showDeleteDialog = false
    }
  },
  async mounted () {
    const { code, result } = await this.$store.dispatch('transactionLimit/getApprover', this.currUser.user_id)

    if (code === 200) {
      if (result.length > 0) {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', result[0].approver_level)
      } else {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', '')
      }
    }

    if (this.approverLevel !== 'ADMIN') {
      renderToast('error', 'No access', 'You don\'t have access to this module')
    }
    this.getApprovers()
  }
}
</script>
