<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mt-md-4 mx-md-5" no-gutters>
      <v-col class="pb-md-5" :cols="12">
        <strong>Sender Details</strong>
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="formData.senderFirstName"
          @input="convertToProperCase('senderFirstName')"
          class="ph-textbox mr-md-2"
          label="First Name"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="formData.senderMiddleName"
          @input="convertToProperCase('senderMiddleName')"
          class="ph-textbox mx-md-2 mt-2 mb-2"
          label="Middle Name"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="formData.senderLastName"
          @input="convertToProperCase('senderLastName')"
          class="ph-textbox ml-md-2 mt-2 mb-2"
          label="Last Name"
          outlined
        />
      </v-col>
      <v-col class="pt-md-8 pb-md-5" :cols="12">
        <strong>Receiver Details</strong>
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="formData.receiverFirstName"
          @input="convertToProperCase('receiverFirstName')"
          class="ph-textbox mr-md-2 mt-2 mb-2"
          label="First Name"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="formData.receiverMiddleName"
          @input="convertToProperCase('receiverMiddleName')"
          class="ph-textbox mx-md-2 mt-2 mb-2"
          label="Middle Name"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="formData.receiverLastName"
          @input="convertToProperCase('receiverLastName')"
          class="ph-textbox ml-md-2 mt-2 mb-2"
          label="Last Name"
          outlined
        />
      </v-col>
      <v-col class="pt-md-8 pb-md-5" :cols="12">
        <strong>Transaction Details</strong>
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="formData.mtcn"
          class="ph-textbox mr-md-2 mt-2 mb-2"
          label="MTCN"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="formData.date"
          @click:append="datePickerDialog = true"
          @input="syncDate"
          :rules="dateRules"
          class="ph-textbox mx-md-2 mt-2 mb-2"
          label="Date Transacted"
          append-icon="mdi-calendar"
          outlined
        />
        <v-dialog v-model="datePickerDialog" width="300">
          <v-date-picker
            v-model="tempDate"
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            min="1950-01-01"
            @input="datePickerDialog = false"
          />
        </v-dialog>
      </v-col>
      <v-col :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.country"
          :items="collections.countries"
          item-text="country"
          item-value="two_iso_code"
          class="ph-textbox ml-md-2 mt-2 mb-2"
          label="Destination Country"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-select
          v-model="formData.currency"
          :items="collections.currencies"
          :return-object="false"
          class="ph-textbox mr-md-2 mt-md-8 mt-4 mb-4"
          label="Currency"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          class="ph-textbox mx-md-2 mt-md-8 mt-4 mb-4"
          label="Service Charge"
          type="number"
          value="0"
          disabled
          hide-spin-buttons
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="formData.amount"
          class="ph-textbox ml-md-2 mt-md-8 mt-4 mb-4"
          label="Principal Amount"
          type="number"
          hide-spin-buttons
          outlined
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between px-md-5 py-md-10">
      <v-btn @click="cancel" class="rounded-lg" color="primary" outlined x-large>
        Cancel
      </v-btn>
      <v-btn @click="save" class="rounded-lg" color="primary" x-large>
        Save
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  toProperCase,
  trim,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      collections: 'collections',
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      tempDate: '',
      datePickerDialog: false,
      dateRules: [
        v => !!v || 'This field is required',
        v => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v) || 'Please use YYYY-MM-DD format'
      ],
      formData: {
        senderFirstName: '',
        senderMiddleName: '',
        senderLastName: '',
        receiverFirstName: '',
        receiverMiddleName: '',
        receiverLastName: '',
        mtcn: '',
        date: '',
        country: '',
        currency: '',
        charge: '',
        amount: ''
      },
      breadcrumbsItems: [
        {
          text: 'PERA Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Manual Entry',
          disabled: false,
          href: '/pera-remit/manual-entry'
        },
        {
          text: 'Manual Receive Entry',
          disabled: true
        }
      ]
    }
  },
  watch: {
    tempDate (val) {
      this.formData.date = val
    }
  },
  methods: {
    async save () {
      const payload = {
        trx_date: this.formData.date,
        wu_trx_date: this.formData.date,
        wu_opid: this.currUser.user_id,
        wu_mtcn: this.formData.mtcn,
        wu_tran_type: 'PO',
        wu_payee_name: `${this.formData.receiverFirstName} ${this.formData.receiverLastName}`,
        wu_sender_name: `${this.formData.senderFirstName} ${this.formData.senderLastName}`,
        principal_amount: this.formData.amount,
        wu_remote_terminal_id: this.currUser.fs_id,
        wu_fx_rate: '1',
        wu_originating_country: this.formData.country,
        wu_terminal_id: this.currUser.fs_id,
        wu_total_charge: 0,
        wu_country_currency_paid: (this.formData.currency === 1) ? 'PHP' : 'USD',
        wu_country_code_paid: this.formData.country,
        dst: 0,
        currency_id: this.formData.currency,
        client_ip: this.currUser.ip_address,
        net_amount: Number(this.formData.amount),
        remote_location_id: this.currUser.location_id,
        remote_user_id: this.currUser.user_id,
        remote_ip: this.currUser.ip_address,
        dom_int: (this.formData.country !== 'PH') ? 'IMT' : 'DMT',
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id
      }

      const req = await this.$store.dispatch('manual/savePending', payload)

      if (req.code === 200) {
        this.renderToast('success', 'Success', 'Manual entry has been saved successfully')
        this.$router.push({
          path: '/pera-remit/manual-entry'
        })
      } else {
        this.renderToast('error', 'Error', 'Failed to save manual entry')
      }
    },
    cancel () {
      this.$router.push({
        path: '/pera-remit/manual-entry'
      })
    },
    syncDate (val) {
      if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(val)) {
        this.tempDate = val
      }
    },
    convertToProperCase (field) {
      this.formData[field] = toProperCase(trim(this.formData[field]))
    },
    renderToast
  }
}
</script>
