<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 px-md-5 mb-md-12" no-gutters>
      <v-col class="mt-2 mt-sm-4 mt-md-7" :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          class="custom-table"
          calculate-widths
        >
          <template #item.currency_id="{ item }">
            <span v-if="Number(item.currency_id) === 1">PESO</span>
            <span v-if="Number(item.currency_id) === 2">US DOLLAR</span>
          </template>
          <template #item.status="{ item }">
            <span class="blue--text" v-if="Number(item.status) === 0">Pending</span>
            <span class="green--text" v-if="Number(item.status) === 1">Approved</span>
            <span class="red--text" v-if="Number(item.status) === 2">Disapproved</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="newEntry"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      items: 'mcRateRequest/items'
    })
  },
  data () {
    return {
      breadcrumbsItems: [
        {
          text: 'BOS Module',
          disabled: true,
          exact: true,
          to: '/'
        },
        {
          text: 'MC Rate Request',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Date',
          sortable: true,
          value: 'trx_date',
          width: '150px'
        },
        {
          text: 'Category',
          sortable: true,
          value: 'category',
          width: '150px'
        },
        {
          text: 'Currency',
          sortable: true,
          value: 'currency_id',
          width: '150px'
        },
        {
          text: 'BUY Requested',
          sortable: true,
          value: 'buy_req_rate',
          width: '150px'
        },
        {
          text: 'BUY Approved',
          sortable: true,
          value: 'buy_am_rate',
          width: '150px'
        },
        {
          text: 'SELL Requested',
          sortable: true,
          value: 'sell_req_rate',
          width: '150px'
        },
        {
          text: 'SELL Approved',
          sortable: true,
          value: 'sell_am_rate',
          width: '150px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '150px'
        }
      ]
    }
  },
  methods: {
    newEntry () {
      this.$router.push('/mc-rate-request/new')
    },
    async retrieveData () {
      try {
        const { code } = await this.$store.dispatch('mcRateRequest/retrieveData', {
          trxDate: this.currUser.trx_date,
          currencyId: 0,
          locationId: this.currUser.location_id
        })

        if (code !== 200) {
          renderToast('error', 'Request Error', 'Failed to retrieve grid')
        }
      } catch (e) {
        renderToast('error', 'USP Error', 'Failed to retrieve grid')
      }
    }
  },
  mounted () {
    this.retrieveData()
  }
}
</script>
