<template>
  <v-row class="px-8 py-8" no-gutters v-if="show">
    <v-col class="py-4 px-2" :cols="12" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
      <div v-if="view === 'edit'">
        <h2>Previous Signatures</h2>
        <div v-if="formData.display_previous_signatures.length != 0" class="row pt-3">
          <div style="position:relative;" v-for="(signature, index) in formData.display_previous_signatures" :key="index">
            <img :src="signature.bucket_path" alt="" class="column">
          </div>
        </div>
        <div v-else>
          <span class="caption">No previous signatures!</span>
        </div>
      </div>
      <div v-if="currUser.has_wacom" class="pt-3">
        <wacom type="kyc" @submit-sig="submitSig" style="margin-bottom: 10px;" v-if="signatures.length < 3"/>
        <div class="card card-w-title">
          <div v-if="signatures.length != 0" class="row">
            <div style="position:relative;" v-for="(signature, index) in signatures" :key="index">
              <img :src="signature" alt="" class="column">
              <v-btn v-on:click="deleteSignature(index)" style="z-index: 1; position:absolute;">
                <v-icon class="mr-1" color="red">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else>
            <span class="caption">No signatures captured yet!</span>
          </div>
        </div>
      </div>
      <div v-else>
        <span class="caption">No Wacom device detected!</span>
      </div>
      <!-- <v-text-field
        v-model="base64String"
        label="Regular"
        clearable
      ></v-text-field> -->
      <!-- <v-btn
        v-on:click="pdfConvert"
      ></v-btn> -->
    </v-col>
    <v-col v-else>
      "Wacom is not Supported on Mobile"
    </v-col>
  </v-row>
</template>

<script>
import wacom from '@/components/wacom/wacom'
import { mapGetters } from 'vuex'
import { Buffer } from 'buffer/'
import wacomMixin from '@/mixins/wacom.js'
// import FileSaver from 'file-saver'

export default {
  mixins: [wacomMixin],
  name: 'dashboard',
  components: {
    wacom
  },
  props: ['view'],
  data () {
    return {
      base64String: '',
      signatures: [],
      show: false
    }
  },
  computed: {
    ...mapGetters({
      formData: 'customers/formData',
      currUser: 'auth/currUser'
    })
  },
  mounted () {
    this.$store.commit('app/TOGGLE_LOADING', true)
    this.delayedShow()
    console.log(this.currUser)
  },
  methods: {
    delayedShow () {
      const delay = 4000
      setTimeout(() => {
        this.show = true
        this.$store.commit('app/TOGGLE_LOADING', false)
      }, delay)
    },
    pdfConvert () {
      var decoded = Buffer.from(this.base64String, 'base64')
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      window.open(fileURL)
      // FileSaver.saveAs(blob, 'filename')
    },
    submitSig (emitSignature) {
      // this.formData.customer_signatures.push(emitSignature)
      this.$store.commit('customers/SET_CUSTOMER_SIGNATURE', emitSignature.substr(emitSignature.indexOf(',') + 1))
      this.signatures.push(emitSignature)
      console.log(this.formData)
    },
    deleteSignature (index) {
      // let filtered = this.customerSignatures.filter(function(e){
      //     return e == signature;
      // })
      // let indexDelete = this.customerSignatures.indexOf(filtered);
      this.signatures.splice(index, 1)
      this.formData.customer_signatures.splice(index, 1)
    }
  }
}
</script>
