export default {
  loading: false,
  opaqueLoading: false,
  mini: false,
  drawer: false,
  crossSellingSpiel: {
    renderSpielModal: false,
    offer_one: '',
    offer_two: '',
    product_line: ''
  },
  snackbar: {
    renderSnackbar: false,
    snackbarColor: '',
    snackbarTitle: '',
    snackbarText: ''
  }
}
