<template>
  <div v-if="inquireResult" ref="receiveResult">
    <div v-if="inquireResult.data">
      <v-card class="mb-10 ph-nonex-card" v-for="inqResult in inquireResult.data" :key="inqResult.index">
        <v-card-title>
          <h1 color="primary" class="ph-nonex-card-title">{{inqResult.remco_name}}</h1>
        </v-card-title>
        <v-card-text class="ph-inquire-result-content">
          <v-row>
            <v-col cols="12" sm="9">
              <h1 class="ph-inquire-result-receiver-name">
                <b class="ph-inquire-result-header">Receiver Name: </b>{{inqResult.result.receiver_name}}
              </h1>
              <p><b class="ph-inquire-result-header">Sender Name: </b>{{inqResult.result.sender_name ? inqResult.result.sender_name : 'Not Provided'}}</p>
              <p><b class="ph-inquire-result-header">Amount: </b> {{inqResult.result.currency}} {{ formatAmount(inqResult.result.principal_amount) }}</p>
              <br>
              <p class="ph-inquire-result-reminder">
                <b><i>Reminder: </i>Please check if the transaction is in PESO before processing.</b>
              </p>
              <p class="ph-inquire-result-usd-reminder" v-if="inqResult.result.currency === 'USD'">
                <b>Sorry, you cannot payout this transaction. Only PHP transactions can be released.</b>
              </p>
            </v-col>
            <v-col cols="12" sm="3">
              <v-btn
                v-if="inqResult.result.currency === 'USD'"
                color="primary"
                class="ph-primary-button"
                :disabled="inqResult.result.currency === 'USD'"
              >
                Select Transaction
              </v-btn>
              <v-btn
                v-if="inqResult.result.currency !== 'USD'"
                color="primary"
                elevation="0"
                class="ph-primary-button ph-primary-execute-btn"
                @click="selectTransaction(inqResult.result.receiver_name, inqResult.remco_id, inqResult)"
              >
                Select Transaction
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="inquireResult.errors && inquireResult.errors.length > 0">
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header class="ph-expansion-panel-header">
            <div class="ph-nonex-card-title-container">
              <h1 color="primary" class="ph-nonex-card-title">{{inquireResult.data.length > 0 ? 'Result from other partners' : 'Transaction does not exist or already claimed'}}</h1>
              <div class="ph-nonex-card-chip-container">
                <v-chip
                  class="ph-inquire-error-count"
                  color="red"
                  text-color="white"
                >
                  <v-avatar
                    left
                    class="red darken-4"
                  >
                    {{inquireResult.errors.length}}
                  </v-avatar>
                  Error(s) Found
                </v-chip>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="ph-inquire-result-content">
            <v-list
              two-line
              subheader
            >
              <v-subheader class="ph-inquire-error-items ph-inquire-result-header">We have found one or more Remcos that returned an error message in our system:</v-subheader>
              <v-list-item class="ph-inquire-error-items" v-for="error in inquireResult.errors" :key="error.index">
                <v-list-item-content class="ph-inquire-error-item">
                  <v-list-item-title>
                    <p><b class="ph-inquire-result-header">{{error.remco_name}}</b></p>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{error.error ? ((error.error.message) ? error.error.message : error.error) : error.message}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

import numeral from 'numeral'

export default {
  name: 'ReceiveMoneyResult',
  data () {
    return {
      result: []
    }
  },
  computed: {
    ...mapGetters({
      inquireResult: 'nonex/inquireResult'
    })
  },
  watch: {
    inquireResult: {
      handler (getState) {
        this.result = getState

        console.log(getState)

        if (getState === undefined) {
          this.renderToast('error', 'Inquire Error', 'Transaction does not exist or the partner is not yet enabled.')
        } else {
          this.$nextTick(() => {
            this.$refs.receiveResult.scrollIntoView({ behavior: 'smooth' })
          })
        }
      },
      deep: true
    }
  },
  methods: {
    renderToast,
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    selectTransaction (receiverName, remcoId, trxDetails) {
      console.log(receiverName, remcoId, trxDetails)
      const keyword = this.formatName(receiverName, remcoId.toString())
      this.$store.commit('nonex/SET_CURRENT_TRANSACTION', trxDetails)
      this.$store.dispatch('customers/doSearch', keyword)
    },
    formatName (name, remco) {
      let firstNameSplit = ''
      let nameSplit = ''

      let firstName = ''
      let lastName = ''

      var remcoId = remco

      if (remcoId === '7' || remcoId === '22') {
        if (!name.includes(',')) {
          nameSplit = name.split(' ')

          firstName = nameSplit[0]
          firstNameSplit = firstName.split(' ')

          if (nameSplit.length > 2) {
            if (nameSplit[nameSplit.length - 1] === '') {
              lastName = nameSplit[nameSplit.length - 2]
            } else {
              lastName = nameSplit[nameSplit.length - 1]
            }
          } else {
            lastName = nameSplit[1]
          }

          if (firstNameSplit.length > 1) {
            return lastName + ', ' + firstNameSplit[0]
          } else {
            return lastName + ', ' + firstName
          }
        } else if (name.includes(',')) {
          nameSplit = name.split(', ')

          firstName = nameSplit[1]
          lastName = nameSplit[0]

          firstNameSplit = firstName.split(' ')

          if (nameSplit.length > 2) {
            if (nameSplit[nameSplit.length - 1] === '') {
              lastName = nameSplit[nameSplit.length - 2]
            } else {
              lastName = nameSplit[nameSplit.length - 1]
            }
          } else {
            lastName = nameSplit[0]
          }

          if (firstNameSplit.length > 1) {
            if (firstNameSplit[0].length === 1 && firstNameSplit[1].length === 1) {
              return lastName + ', ' + firstNameSplit[2]
            }
            return lastName + ', ' + firstNameSplit[0]
          } else {
            return lastName + ', ' + firstName
          }
        }
      } else if (remcoId === '2' || remcoId === '8' || remcoId === '16' || remcoId === '18') {
        if (!name.includes(',')) {
          const removePeriod = name.replace(/\./g, '')
          const removeSpecChars = removePeriod.replace(/[^a-zA-Z0-9Ñ ]/g, '')

          nameSplit = removeSpecChars.split(' ')
          firstName = nameSplit[0]

          if (firstName.length < 2) {
            firstName = nameSplit[1]
          }

          firstNameSplit = firstName.split(' ')

          if (nameSplit.length > 2) {
            if (nameSplit[nameSplit.length - 1] === '') {
              lastName = nameSplit[nameSplit.length - 2]
            } else if (nameSplit[nameSplit.length - 1] !== '' && nameSplit[nameSplit.length - 2] !== '') {
              lastName = nameSplit[nameSplit.length - 2] + ' ' + nameSplit[nameSplit.length - 1]
            } else {
              lastName = nameSplit[nameSplit.length - 1]
            }
          } else {
            lastName = nameSplit[1]
          }

          if (firstNameSplit.length > 1) {
            if (firstNameSplit[0] === '') {
              return lastName
            } else {
              return lastName + ', ' + firstNameSplit[0]
            }
          } else {
            if (firstName === '') {
              return lastName
            } else {
              return lastName + ', ' + firstName
            }
          }
        } else {
          const removeComma = name.replace(/,/g, ' ')
          const removePeriod = removeComma.replace(/\./g, '')
          nameSplit = removePeriod.split(' ')
          if (nameSplit[nameSplit.length - 1] === '' || nameSplit[nameSplit.length - 1].length < 2) {
            if (nameSplit[nameSplit.length - 2].length < 2) {
              firstName = nameSplit[nameSplit.length - 3]
            } else {
              firstName = nameSplit[nameSplit.length - 2]
            }
          } else if (nameSplit[nameSplit.length - 1].length > 1) {
            firstName = nameSplit[nameSplit.length - 1]
          } else {
            if (nameSplit[nameSplit.length - 2].length < 2) {
              firstName = nameSplit[nameSplit.length - 3]
            } else {
              firstName = nameSplit[nameSplit.length - 2]
            }
          }
          lastName = nameSplit[0]

          firstNameSplit = firstName.split(' ')

          if (firstNameSplit.length > 1) {
            if (firstNameSplit[0] === '') {
              return lastName
            } else {
              return lastName + ', ' + firstNameSplit[0]
            }
          } else {
            if (firstName === '') {
              return lastName
            } else {
              return lastName + ', ' + firstName
            }
          }
        }
      } else if (remcoId === '12') {
        const arrayCountInComma = name.split(',').length - 1
        if (arrayCountInComma === 0) { // e.g: L G M
          const newNameToSplit = name.replace(/ /g, ',')
          const newNameSplit = newNameToSplit.split(',')
          return newNameSplit[0] + ',' + newNameSplit[1]
        } else {
          nameSplit = name.split(', ')

          firstName = nameSplit[1]
          lastName = nameSplit[0]

          firstNameSplit = firstName.split(' ')

          if (firstNameSplit.length > 1) {
            return lastName + ', ' + firstNameSplit[0]
          } else if (firstName.length < 2 || firstName === '') {
            return lastName
          } else {
            return lastName + ', ' + firstName
          }
        }
      } else if (remcoId === '13') {
        if (!name.includes(',') && name.includes('.')) { //  eg. G M. L
          return this.gmlNameFormat(name)
        } else {
          const splitName = name.split(',')
          const lastIndex = splitName.length - 1
          let newName = ''
          if (lastIndex === 1) { //  eg. G M. L,
            newName = name.replace(',', '')
            return this.gmlNameFormat(newName)
          } else if (lastIndex === 2) { //  eg. L, G M(m.i only),
            const firstName = splitName[1].trim().split(' ')
            const firstNameLastIndex = firstName.length - 1

            if (firstName[firstNameLastIndex].length === 1) { // to confirm middle name
              firstName.pop()
              const newfirstName = firstName.join(' ')
              const fullName = splitName[0] + ', ' + newfirstName
              return fullName
            } else {
              return splitName[0] + ', ' + splitName[1]
            }
          }
        }
      } else if (remcoId === '17' || remcoId === '20' || remcoId === '28') {
        return name
      } else {
        nameSplit = name.split(', ')

        firstName = nameSplit[1]
        lastName = nameSplit[0]

        firstNameSplit = firstName.trim().split(' ')

        if (firstNameSplit.length > 1) {
          return lastName + ', ' + firstNameSplit[0]
        } else if (firstName.length < 2 || firstName === '') {
          return lastName
        } else {
          return lastName + ', ' + firstName
        }
      }
    },
    gmlNameFormat (_name) {
      let lastName = ''
      let firstName = ''

      // If name has dot separator
      if (_name.includes('.')) {
        lastName = _name.split('.')[1].trim()
        firstName = _name.split('.')[0].split(' ')
        firstName.pop()
        firstName = firstName.join(' ')
      } else {
        // otherwise
        lastName = _name.split(' ')[1].trim()
        firstName = _name.split(' ')[0].trim()
      }

      const fullName = lastName + ', ' + firstName
      return fullName
    }
  }
}
</script>
<style scoped>
  .ph-inquire-result-usd-reminder {
    color: #f44336;
  }
  .ph-nonex-card-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-nonex-receive-search-title {
    display: flex;
  }

  .ph-textbox-title {
    margin: auto;
    margin-left: 0;
    font-family: 'Proxima Nova Bold';
    font-size: 16px;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 13px 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
  }

  .ph-inquire-result-content {
    line-height: 1.5;
  }

  .ph-inquire-result-header {
    margin-top: 10px;
  }

  .ph-inquire-result-content p {
    margin-bottom: 0;
    font-size: 16px;
  }

  .ph-nonex-receive-action-buttons {
    display: flex;
  }

  .ph-inquire-result-receiver-name,
  .ph-inquire-result-header {
    font-family: 'Proxima Nova Bold';
  }

  .ph-inquire-error-items {
    padding: 0;
  }

  .ph-inquire-error-count {
    flex: unset !important;
    margin-left: 10px;
  }

  .ph-expansion-panel-header {
    display: flex;
    flex-direction: row;
  }

  .ph-nonex-card-title-container {
    display: flex;
    flex-direction: column;
  }

  .ph-inquire-error-count {
    flex: unset !important;
    margin-left: 0;
    margin-right: 10px;
  }

  .ph-nonex-card-chip-container {
    margin-top: 10px;
  }

  @media only screen and (min-width: 600px) {
    .ph-nonex-card-title-container {
      flex-direction: row;
    }

    .ph-nonex-card-chip-container {
      margin-left: auto;
      margin-top: 0;
    }

    .ph-inquire-error-count {
      margin-left: 10px;
      margin-right: 0;
    }
  }
</style>
