<template>
  <table class="report-table exportable" id="reportTable" style="width: 100%">
    <!-- Head -->
    <tr class="report-table-head" style="background-color: #1A2791; color: #fff; border: 1px solid black; print-color-adjust: exact;">
      <th>Customer Name</th>
      <th>Default/Current Limit</th>
      <th>Last Date Changed/Requested</th>
      <th>Amount Increased</th>
      <th>Transaction Type</th>
      <th>Provider</th>
    </tr>
    <!-- Body -->
    <tr class="report-table-data" v-for="(data, index) in reportData" :key="index">
      <td class="text-center">{{ data.customer_name || '-' }}</td>
      <td class="text-center">{{ data.max_amount || 0 }}</td>
      <td class="text-center">{{ formatDate(data.updated_at) || formatDate(data.created_at) || '-' }}</td>
      <td class="text-center">{{ data.increase_amount || 0 }}</td>
      <td class="text-center">{{ data.service_name }}</td>
      <td class="text-center">{{ data.provider_name }}</td>
    </tr>
  </table>
</template>
<script>
import * as XLSX from 'xlsx'
import { format, parseISO } from 'date-fns'

export default {
  name: 'CustomerLimits',
  props: ['reportData'],
  methods: {
    formatDate (date) {
      if (date) return format(parseISO(date), 'yyyy-MM-dd HH:mm:ss')
      return date
    },
    export (reportName, fileName) {
      const data = document.getElementById('reportTable')
      const excelFile = XLSX.utils.table_to_book(data, { sheet: reportName, cellDates: true })
      XLSX.write(excelFile, { bookType: 'xlsx', bookSST: true, type: 'base64' })
      XLSX.writeFile(excelFile, `${fileName}.xlsx`)
    },
    print () {
      window.print()
    }
  }
}
</script>
<style scoped>
.report-table-head th {
  font-weight: bolder;
}

tr {
  border-bottom: 1px solid black;
}

.report-table-data td,
.report-table-footer td {
  padding-right: 20px;
  padding-left: 20px;
}

@media print {
  #reportTable {
    display: block;
    width: 100%;
  }
}
</style>
