export default {
  SET_CLOSE_LIST (state, payload) {
    state.closeList = payload
  },
  SET_EXCESS_REASON_LIST (state, payload) {
    state.excessFundReasonList = payload
  },
  SET_EXCESS_REQUEST_LIST (state, payload) {
    state.excessFundRequestList = payload
  },
  SET_EXCESS_FUND_INFO (state, payload) {
    state.excessFundInfo = payload
  }
}
