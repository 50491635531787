<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-btn
          icon
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-upload-outline</v-icon>
        </v-btn> -->
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-title>Upload ACR</v-list-item-title>
        </v-list-item>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Upload ACR
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-file-input
              v-model="file"
              placeholder="Upload your documents"
              label="File input"
              multiple
              prepend-icon="mdi-paperclip"
              >
              <template v-slot:selection="{ text }">
                <v-chip
                  small
                  label
                  color="primary"
                  >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            v-on:click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            v-on:click="uploadFile"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { serialize } from 'object-to-formdata'
import { renderToast } from '@/utils'
// import { Buffer } from 'buffer/'

export default {
  name: 'ACRUpload',
  props: ['item'],
  data () {
    return {
      file: [],
      dialog: false,
      formData: {}
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  methods: {
    renderToast,
    uploadFile () {
      if (this.file.length === 0) {
        this.renderToast('error', 'No File Selected!', 'Please select a file before uploading!')
      } else {
        this.generateBase64File().then(res => {
          var formData = {}
          const self = this
          formData.customer_id = self.item.customer_id
          formData.crn = self.item.client_reference_no
          formData.location_id = self.currUser.location_id
          formData.control_number = self.item.control_number
          formData.vlocation_name = self.currUser.location_name
          formData.remco_name = self.item.non_ex_name
          formData.filename = self.file[0].name
          formData.trx_type = self.item.trx_type
          formData.file = res
          formData.first_name = '-'
          formData.last_name = '-'
          formData.product = self.item.non_ex_name
          formData.uploaded_by = self.currUser.user_id
          this.$store.dispatch('nonex/uploadACR', serialize(formData)).then(res => {
            console.log(res)
            if (res.code === 200) {
              this.renderToast('success', 'Upload Successful', 'Customer ACR uploaded successfully!')
              setTimeout(location.reload(), 1000)
            }
          })
        })
      }

      // const res = await this.$store.dispatch('nonex/uploadACR', formData)

      // if (res.code === 200) {
      //   this.renderToast('success', 'Upload Successful', 'Customer ACR uploaded successfully!')
      //   setTimeout(location.reload(), 3000)
      // }
    },
    cancel () {
      this.file = []
      this.dialog = false
    },
    generateBase64File () {
      return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.readAsDataURL(this.file[0])
        reader.onload = function () {
          var base64result = reader.result.substr(reader.result.indexOf(',') + 1)
          resolve(base64result)
        }
      })
    }
  }
}
</script>
