<template>
  <div>
    <strong>Payout Details</strong>
    <v-row class="mt-3 mb-3 mb-md-5" no-gutters>
      <v-col :cols="12" :md="8">
        <v-autocomplete
          v-model="transactionData.destinationCountryID"
          :items="payoutCountryList"
          @change="selectCountry"
          item-text="country"
          item-value="id"
          class="ph-textbox mb-md-6"
          label="Payout Country"
          outlined
        >
          <template v-slot:selection="data">
            {{ data.item.country }} - {{ data.item.currency_value }}
          </template>
          <template v-slot:item="data">
            <template v-if="(typeof data.item !== 'object')">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.country"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.currency_value"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="py-3 py-md-0 pr-md-1 pb-md-6 d-flex align-center justify-md-center" :cols="12" :md="4">
        <a
          @click.prevent="viewCountry"
          class="primary--text font-weight-bold body-2"
          style="text-decoration: none!important"
        >
          View Country Information
        </a>
      </v-col>
      <v-col class="pb-4 pb-md-6 body-2" :cols="12">
        Exchange Rate: <strong>{{ transactionData.exchangeRate || '' }}</strong>
      </v-col>
      <v-col class="pr-md-1" :cols="12" :md="6">
        <v-text-field
          v-model="transactionData.sendAmount"
          @blur="feeInquiry('N')"
          @input="clear('receiveAmount')"
          class="ph-textbox mb-md-6"
          label="Amount to Send"
          type="number"
          hide-spin-buttons
          :disabled="!transactionData.destinationCountryID"
          outlined
        />
      </v-col>
      <v-col class="pl-md-1" :cols="12" :md="6">
        <v-text-field
          v-model="transactionData.receiveAmount"
          @blur="feeInquiry('F')"
          @input="clear('sendAmount')"
          class="ph-textbox mb-md-6"
          label="Amount to Receive"
          type="number"
          hide-spin-buttons
          :disabled="!transactionData.destinationCountryID"
          v-if="transactionData.destinationCountryID && transactionData.destinationCountryID !== countryID"
          outlined
        />
      </v-col>
    </v-row>
    <v-dialog v-model="viewCountryDialog" width="70%" persistent>
      <v-card>
        <v-card-title>
          <h2 class="primary--text title ph-title">
            Country Information
          </h2>
          <v-spacer />
          <v-btn @click="viewCountryDialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="countryDetails"
            class="mt-2"
            rows="10"
            filled
            outlined
            readonly
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toMoney, renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      collections: 'collections',
      transactionData: 'send/transactionData',
      feeInquiryData: 'send/feeInquiryData',
      currUser: 'auth/currUser',
      remoteData: 'obo/remoteData',
      remcoList: 'send/remcoList',
      payoutCountryList: 'send/payoutCountryList',
      countryID: 'send/phID'
    })
  },
  data () {
    return {
      countryDetails: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      viewCountryDialog: false,
      payoutCountries: []
    }
  },
  methods: {
    renderToast,
    async feeInquiry (flag) {
      const {
        destinationCountryID,
        destinationCountryCode,
        destinationCountryCurrency,
        deliveryService,
        remcoID,
        sendAmount,
        receiveAmount,
        promoCode,
        message,
        refNo
      } = this.transactionData

      if (flag && sendAmount && receiveAmount) return false

      if (flag) {
        this.$store.commit('send/SET_TRANSACTION_DATA', {
          prop: 'fixedAmountFlag',
          value: flag
        })
      }

      // this.transactionData.remcoID = ''
      this.transactionData.serviceFee = ''
      this.transactionData.totalAmount = ''

      const { fixedAmountFlag } = this.transactionData

      if (destinationCountryID && fixedAmountFlag && (sendAmount || receiveAmount) && (Number(sendAmount) > 0 || Number(receiveAmount) > 0)) {
        let principalAmount

        if (fixedAmountFlag === 'N') principalAmount = sendAmount
        if (fixedAmountFlag === 'F') principalAmount = receiveAmount

        if (principalAmount) {
          const payload = {
            principal_amount: principalAmount,
            agent_code: this.currUser.location_code,
            // agent_code: '01030063',
            branch_code: this.currUser.location_code,
            foreign_reference_no: refNo,
            fixed_amount_flag: fixedAmountFlag,
            destination_country_code: destinationCountryCode,
            destination_currency_code: destinationCountryCurrency,
            transaction_type: deliveryService.value || 'MIM',
            promo_code: promoCode,
            message: message,
            message_line_count: message.length,
            terminal_id: this.terminalId,
            operator_id: this.operatorId,
            location_id: this.locationId
          }

          if (payload.destination_currency_code === '') {
            this.renderToast('error', 'Error', 'Please Enter Valid Currency')
          } else {
            const req = await this.$store.dispatch('send/feeInquiry', payload)

            if (req.code === 200) {
              const remcos = Object.values(req.result)

              remcos.forEach(remco => {
                this.$store.commit('send/SET_REMCO_FEE', {
                  id: remco.id,
                  serviceFee: remco.service_fee,
                  body: remco.body
                })

                if (remco.id === 0) {
                  this.$store.commit('send/SET_FEE_INQUIRY_DATA', remco.body)
                  this.$store.commit('send/SET_TRANSACTION_DATA', {
                    prop: 'exchangeRate',
                    value: this.feeInquiryData.exchange_rate
                  })

                  if (destinationCountryID !== this.countryID) {
                    this.$store.commit('send/SET_TRANSACTION_DATA', {
                      prop: 'sendAmount',
                      value: Number(this.feeInquiryData.originators_principal_amount) / 100
                    })

                    this.$store.commit('send/SET_TRANSACTION_DATA', {
                      prop: 'receiveAmount',
                      value: Number(this.feeInquiryData.pay_amount) / 100
                    })
                  }
                }
              })

              if (destinationCountryID === this.countryID) {
                this.$store.commit('send/SET_TRANSACTION_DATA', {
                  prop: 'exchangeRate',
                  value: '1 PHP = 1.0000000 PHP'
                })
                this.$store.commit('send/SET_TRANSACTION_DATA', {
                  prop: 'receiveAmount',
                  value: principalAmount
                })
              }

              if (remcoID !== '') {
                const selectedRemco = this.remcoList.filter(remco => remco.id === remcoID)
                let totalAmount = (Number(sendAmount) + Number(selectedRemco[0].fee))

                if (remcoID === 0) {
                  totalAmount = selectedRemco[0]?.body?.gross_total_amount / 100

                  this.$store.commit('send/SET_TRANSACTION_DATA', {
                    prop: 'messageCharge',
                    value: selectedRemco[0].body.message_charge / 100
                  })
                }

                this.$store.commit('send/SET_TRANSACTION_DATA', {
                  prop: 'totalAmount',
                  value: totalAmount
                })

                this.$store.commit('send/SET_TRANSACTION_DATA', {
                  prop: 'serviceFee',
                  value: selectedRemco[0].fee
                })
              }
            } else {
              // Error handler
            }
          }
        }
      }
    },
    clear (prop) {
      this.$store.commit('send/SET_TRANSACTION_DATA', {
        prop,
        value: ''
      })
    },
    async selectCountry () {
      const { destinationCountryID } = this.transactionData

      if (destinationCountryID) {
        const countryDetail = this.payoutCountryList.filter((item) => {
          return item.id === destinationCountryID
        })

        const updateState = [
          {
            prop: 'destinationCountryCurrency',
            value: countryDetail[0].currency_value
          },
          {
            prop: 'destinationCountryName',
            value: countryDetail[0].country
          },
          {
            prop: 'destinationCountryCode',
            value: countryDetail[0].iso_country_code
          }
        ]

        this.$store.commit('send/SET_FIELDS_LIST', [])
        this.$store.commit('send/SET_COMBOS_LIST', [])

        updateState.map(payload => this.$store.commit('send/SET_TRANSACTION_DATA', payload))

        this.transactionData.deliveryService = ''

        this.feeInquiry()
      } else {
        const updateState = [
          {
            prop: 'receiveCurrency',
            value: ''
          },
          {
            prop: 'destinationCountryName',
            value: ''
          }
        ]

        updateState.map(payload => this.$store.commit('send/SET_TRANSACTION_DATA', payload))
      }

      // Clears out Remco Selection after selecting country
      this.$store.commit('send/SET_TRANSACTION_DATA', {
        prop: 'remcoID',
        value: ''
      })

      this.$store.commit('send/SET_TRANSACTION_DATA', {
        prop: 'remcoName',
        value: ''
      })
    },
    async viewCountry () {
      const { destinationCountryID } = this.transactionData

      if (destinationCountryID) {
        const countryDetail = this.payoutCountryList.filter((item) => {
          return item.id === destinationCountryID
        })

        const payload = {
          location_code: this.currUser.location_code,
          user_code: this.currUser.user_id,
          country: `${countryDetail[0].two_iso_code} ${countryDetail[0].currency}`,
          terminal_id: this.terminalId,
          operator_id: this.operatorId,
          location_id: this.locationId
        }

        const countryDetails = await this.$store.dispatch('wu/getF2ZoomResult', payload)
        this.countryDetails = this.renderCountryInformation(countryDetails.result)
        this.viewCountryDialog = true
      }
    },
    renderCountryInformation (value) {
      const textCountry = []
      const countryData = value

      for (var i = 0; i < countryData.length; i++) {
        var _countrDyata = countryData[i].INFO
        if (typeof _countrDyata !== 'undefined' && _countrDyata !== null) {
          textCountry.push(countryData[i].INFO)
        } else {
          textCountry.push('')
        }
        // textCountry.push(countryData[i]['INFO'] + ' ');
      }

      const string = textCountry.toString()
      const countryInfo = string.replace(/,/g, '\n')

      return countryInfo
    },
    toMoney
  },
  async mounted () {
    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData?.remote_location?.location_id
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    }
  }
}
</script>
