import { endpoints } from '@/api/endpoints'

const debitCard = endpoints.use('debitCard')

export default {
  getCardTransactions: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.getCardTransactions(payload)
      .then(({ data }) => {
        commit('SET_DEBIT_CARD_TRANSACTIONS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getCardInventory: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.getCardInventory(payload)
      .then(({ data }) => {
        commit('SET_DEBIT_CARD_INVENTORY', data)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  updateCardInventory: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.updateCardInventory(payload)
      .then(({ data }) => {
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  registerPHVCard: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.registerPHVCard(payload)
      .then(({ data }) => {
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getEONSourceOfFund: ({ commit }) => new Promise((resolve, reject) => {
    debitCard.getEONSourceOfFund()
      .then(({ data }) => {
        commit('SET_EON_SOURCE_OF_FUND', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getEONEmployment: ({ commit }) => new Promise((resolve, reject) => {
    debitCard.getEONEmployment()
      .then(({ data }) => {
        commit('SET_EON_EMPLOYMENT', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getEONNatureWork: ({ commit }) => new Promise((resolve, reject) => {
    debitCard.getEONNatureWork()
      .then(({ data }) => {
        commit('SET_EON_NATURE_OF_WORK', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getEONIdList: ({ commit }) => new Promise((resolve, reject) => {
    debitCard.getEONIdList()
      .then(({ data }) => {
        commit('SET_EON_ID_LIST', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getCardStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.getCardStatus(payload)
      .then(({ data }) => {
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  requestCardEmail: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.requestCardEmail(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  inquireCardNumber: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.inquireCardNumber(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  savePendingVirtualCard: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.savePendingVirtualCard(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),

  updatePendingVirtualCard: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.updatePendingVirtualCard(payload.id, payload.params)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),

  savePendingDebitCard: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.savePendingDebitCard(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),

  updatePendingDebitCard: ({ commit }, payload) => new Promise((resolve, reject) => {
    debitCard.updatePendingDebitCard(payload.id, payload.params)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
