<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-data-table
              :headers="headers"
              :items="ibtList"
              class="custom-table"
              :calculate-widths="true"
            >
              <template v-slot:[`item.trx_date`]="{ item }">
                {{ formatDate(item.trx_date) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.status === 1 || item.status === '1'">
                  <v-icon color="orange" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.status === 2 || item.status === '2'">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Approved
                </span>
                <span class="ph-nonex-status-container red--text" v-if="item.status === 3 || item.status === '3'">
                  <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                  Disapproved
                </span>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ !isNaN(item.amount) ? formatAmount(item.amount) : 0 }}
              </template>
              <template v-slot:[`item.is_cash`]="{ item }">
                {{ item.is_cash === '1' ? 'Deliver/Pick-up by Cash' : 'Deposit to Account' }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <!-- Lender -->
                <v-tooltip left v-if="item.location_from === currUser.location_id && item.status === '2' && item.is_settled === 0 && item.is_released === 0" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="markAction(item, 'released')" v-bind="attrs" v-on="on" v-if="item.location_from === currUser.location_id && item.status === '2' && item.is_settled === 0 && item.is_released === 0" color="primary" icon>
                      <v-icon>mdi-stamper</v-icon>
                    </v-btn>
                  </template>
                  <span>Mark as Released</span>
                </v-tooltip>
                <!-- Borrower -->
                <v-tooltip left v-if="item.is_released === 1 && item.is_received === 0 && item.location_to === currUser.location_id && item.status === '2' && item.is_settled === 0" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="markAction(item, 'received')" v-bind="attrs" v-on="on" v-if="item.is_released === 1 && item.is_received === 0 && item.location_to === currUser.location_id  &&  item.status === '2' && item.is_settled === 0" color="primary" icon>
                      <v-icon>mdi-hand-coin</v-icon>
                    </v-btn>
                  </template>
                  <span>Mark as Received</span>
                </v-tooltip>
                <!-- Settlement - For borrower action only -->
                <v-tooltip left v-if="item.location_to === currUser.location_id && item.is_received === 1 && item.status === '2' && item.is_settled === 0 && item.is_borrower === 0 && item.is_lender === 1" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="markAction(item, 'settle')" v-bind="attrs" v-on="on" v-if="item.location_to === currUser.location_id && item.is_received === 1 && item.status === '2' && item.is_settled === 0 && item.is_borrower === 0 && item.is_lender === 1" color="primary" icon>
                      <v-icon>mdi-pen</v-icon>
                    </v-btn>
                  </template>
                  <span>Request Settlement</span>
                </v-tooltip>
                <!-- Print -->
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="printIBT(item)" v-bind="attrs" v-on="on" v-if="item.status === '2'" color="primary" icon>
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <span>Print</span>
                </v-tooltip>
                <!-- <v-btn v-if="item.status !== 1 && item.status !== '1'" disabled color="primary" icon>
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn> -->
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-fab-transition>
        <v-btn
          color="primary"
          fab
          large
          fixed
          dark
          bottom
          right
          @click="addNewEntry"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-dialog
        v-model="confirmDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            {{ confirmDialogTitle }}
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            {{ confirmDialogText }}
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="closeConfirmDialog"
              class="ph-action-button"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="doConfirmAction(confirmPayload, confirmActionType)"
              class="ph-action-button"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="settlementDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="50%">
        <v-card class="rounded-lg">
          <v-form ref="settlementFormRequest">
            <v-card-title class="py-md-6">
              <h3 class="custom-heading primary--text">
                Request for Settlement
              </h3>
              <v-spacer />
              <v-btn @click="closeSettlementDialog" small icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row class="ph-ibt-form">
                <v-col cols="12">
                  <v-text-field
                    v-model="settlementForm.reference_no"
                    label="Reference No."
                    color="gray darken-1"
                    class="ph-textbox"
                    outlined
                    :hide-spin-buttons="true"
                    :rules="requiredRules"
                    readonly
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="settlementForm.received_by"
                    :items="usersByBorrowerLocation"
                    item-text="user_name"
                    item-value="user_id"
                    class="ph-textbox"
                    label="Received by:"
                    :rules="requiredRules"
                    outlined
                  >
                  <template slot="selection" slot-scope="{ item }">
                    {{item.first_name}} {{item.last_name}}
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    {{item.first_name}} {{item.last_name}}
                  </template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="settlementForm.type"
                    :items="typeList"
                    item-text="text"
                    item-value="value"
                    class="ph-textbox"
                    label="Type"
                    :rules="requiredRules"
                    outlined
                  />
                </v-col>
                <v-col cols="12" v-if="settlementForm.type === '0'">
                  <v-select
                    v-model="settlementForm.to_bank"
                    :items="borrowerBanks"
                    item-text="bank_name"
                    item-value="bank_id"
                    class="ph-textbox"
                    label="To Bank"
                    :rules="requiredRules"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="danger"
                text
                @click="closeSettlementDialog"
                class="ph-action-button"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                elevation="0"
                @click="confirmEdit"
                class="ph-action-button"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'PHVCardInventory',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Inter Branch Transfer',
          disabled: false,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Date of Transaction',
          sortable: true,
          value: 'trx_date'
        },
        {
          text: 'Sender',
          sortable: true,
          value: 'sender_name'
        },
        {
          text: 'Receiver',
          sortable: true,
          value: 'receiver_name'
        },
        {
          text: 'To Branch',
          sortable: true,
          value: 'location_name'
        },
        {
          text: 'Reference No.',
          sortable: true,
          value: 'reference_no'
        },
        {
          text: 'Currency',
          sortable: true,
          value: 'currency_code'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount'
        },
        {
          text: 'Type',
          sortable: true,
          value: 'is_cash'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      requiredField: [
        v => !!v || 'This field is required'
      ],
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      confirmActionType: '',
      confirmPayload: '',
      selectedItem: {},
      settlementForm: {},
      typeList: [
        { text: 'Deposit to Account', value: '0' },
        { text: 'Deliver/Pick-up by cash', value: '1' }
      ],
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      settlementDialog: false,
      usersByBorrowerLocation2: [{
        user_name: 'Luis Rafael Octaviano',
        user_id: 5188
      }]
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'cico/transactions',
      currUser: 'auth/currUser',
      cardInventory: 'debitCard/cardInventory',
      ibtList: 'interBranchTransfer/ibtList',
      collections: 'collections',
      borrowerBanks: 'interBranchTransfer/borrowerBanks',
      usersByBorrowerLocation: 'interBranchTransfer/usersByBorrowerLocation'
    })
  },
  async mounted () {
    await this.$store.dispatch('getAllLocations', 0)
    this.getGrid()
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    formatDate (date) {
      if (date) return format(parseISO(date), 'yyyy-MM-dd')
      return date
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    addNewEntry () {
      this.$router.push({ path: '/inter-branch-transfer/form' })
    },
    markAction (payload, type) {
      this.confirmPayload = payload
      this.confirmActionType = type
      switch (type) {
        case 'received':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Receive Entry'
          this.confirmDialogText = 'Are you sure you want to receive this entry?'
          this.selectedItem = payload

          break
        case 'released':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Release Entry'
          this.confirmDialogText = 'Are you sure you want to mark this entry as released?'
          this.selectedItem = payload

          break
        case 'settle':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Request For Settlement'
          this.confirmDialogText = 'Are you sure you want to settle this request?'
          this.selectedItem = payload
          break
        case 'settle-rq':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Settlement Request'
          this.confirmDialogText = 'Are you sure you want to submit this request?'
          break
      }
    },
    closeConfirmDialog () {
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''
    },
    async doConfirmAction (payload, type) {
      let endpoint = ''
      let confirmTypeText = ''
      const data = {
        id: payload.trx_location_transfer_id
      }

      switch (type) {
        case 'received':
          endpoint = 'interBranchTransfer/tagAsReceived'
          confirmTypeText = 'The transaction has been successfully marked as Received'

          data.params = {
            is_borrower: payload.is_borrower,
            is_lender: payload.is_lender
          }

          break
        case 'released':
          endpoint = 'interBranchTransfer/tagAsReleased'
          confirmTypeText = 'The transaction has been successfully marked as Released'
          break
        case 'settle':
          this.getSettlementCollections(payload)
          break
        case 'settle-rq':
          endpoint = 'interBranchTransfer/requestSettlement'
          confirmTypeText = 'The request has been successfully submitted'

          data.id = payload.id
          data.params = payload.params
          break
      }

      if (type !== 'settle') {
        await this.$store.dispatch(endpoint, data).then(res => {
          if (res.code === 200) {
            this.renderToast('success', 'Action Successful', confirmTypeText)
            this.confirmDialog = false
            this.confirmDialogTitle = ''
            this.confirmDialogText = ''
            this.currentLocation = ''
            this.confirmPayload = ''
            this.selectedItem = {}
            this.settlementDialog = false
            this.getGrid()
          }
        }).catch(err => {
          this.renderToast('error', 'Error', err)
          this.confirmDialog = false
          this.confirmDialogTitle = ''
          this.confirmDialogText = ''
          this.currentLocation = ''
        })
      }
    },
    getGrid () {
      const getIBTList = {
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id
      }

      this.$store.dispatch('interBranchTransfer/getIBTList', getIBTList)
    },
    closeSettlementDialog () {
      this.settlementDialog = false
    },
    async getSettlementCollections (item) {
      this.settlementForm.reference_no = await this.generateVoucherNumber()
      this.settlementForm.trx_date = this.currUser.trx_date
      this.settlementDialog = true
      this.confirmDialog = false

      await this.$store.dispatch('getAllLocations', 0)
      await this.$store.dispatch('interBranchTransfer/getReasonList')
      await this.getSelectedLocDetails(item.location_from)
    },
    async getSelectedLocDetails (locationId) {
      await this.$store.dispatch('interBranchTransfer/getBorrowerBank', { location_id: locationId })
      await this.$store.dispatch('interBranchTransfer/getUsersOnBorrowerLocations', { location_id: locationId })
    },
    confirmEdit () {
      const payload = {
        id: this.selectedItem.trx_location_transfer_id,
        params: {
          reference_no: this.settlementForm.reference_no,
          trx_date: this.settlementForm.trx_date,
          tranfer_by: this.currUser.user_id,
          client_ip: this.currUser.ip_address,
          is_cash: this.settlementForm.type,
          locations_bank_id: this.settlementForm.type === '0' ? this.settlementForm.to_bank : '0',
          received_by: this.settlementForm.received_by
        }
      }

      if (this.$refs.settlementFormRequest.validate()) {
        this.markAction(payload, 'settle-rq')
      } else {
        this.renderToast('error', 'Incomplete fields', 'Please complete the form to continue')
      }
    },
    async generateVoucherNumber () {
      const currentYear = format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy')
      const currentMonth = format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'MM')
      const payload = {
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id
      }

      let trxCount = 0

      await this.$store.dispatch('interBranchTransfer/getIBTCount', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          trxCount = this.generateTrxCount(res.result, 4)
        } else {
          trxCount = this.generateTrxCount(0, 4)
        }
      })

      const refNumberNew = `${this.currUser.location_code}${currentMonth}${currentYear}-${trxCount}`
      return refNumberNew
    },
    generateTrxCount (num, places) {
      return String(num).padStart(places, '0')
    },
    async printIBT (item) {
      const payload = {
        trx_date: item.trx_date,
        reference_no: item.reference_no
      }

      let printData = {}

      await this.$store.dispatch('interBranchTransfer/getReceiptData', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          printData = res.result
        }
      })

      const encodedData = Buffer.from(JSON.stringify(printData)).toString('base64')
      this.$router.push(`/inter-branch-transfer/print?qq=${encodedData}`)
    }
  }
}
</script>
<style scoped>
/* .ph-nonex-datatable-title {
  padding-left: 0;
} */

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.7);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

.ph-nonex-table-names-text.text-amount {
  margin-left: 0 !important;
}

.text-inquire-button {
  cursor: pointer;
}

.ph-cards-inventory-counter {
  display: flex;
  flex-direction: row;
  padding: 15px;
  padding-bottom: 10px;
}

.ph-cards-inventory-counter p:first-child {
  margin-right: 15px;
}

/* .ph-textbox.ph-textbox-grid-search {
  width: 256px;
} */
</style>
