<template>
  <v-row class="ph-wu-success-page-container">
    <v-col cols="12">
      <h1 class="ph-wu-form-title">Success!</h1>
      <p class="ph-wu-form-subtitle">Thank you. Receive Money transaction with Western Union has been completed.</p>
    </v-col>
    <v-col cols="12" md="8">
      <p class="ph-wu-success-receiver-name"><b>Receiver Name:</b> {{receiverName}}</p>
      <v-row class="ph-wu-success-details">
        <v-col cols="12" sm="4">
          <v-sheet
            class="ph-wu-success-detail"
            rounded
          >
            <div class="ph-wu-success-detail-icon">
              <img src="@/assets/images/icons/credit-card.svg" />
            </div>
            <div class="ph-wu-success-detail-text">
              <strong>Loyalty Number</strong>
              <p>{{loyaltyCardNumber !== '' ? loyaltyCardNumber : 'XXXXXXXXX'}}</p>
            </div>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="4">
          <v-sheet
            class="ph-wu-success-detail"
            rounded
          >
            <div class="ph-wu-success-detail-icon">
              <img src="@/assets/images/icons/gift.svg" />
            </div>
            <div class="ph-wu-success-detail-text">
              <strong>Loyalty Points</strong>
              <p>{{loyaltyPoints > 0 ? loyaltyPoints : 0}} points</p>
            </div>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="4">
          <v-sheet
            class="ph-wu-success-detail"
            rounded
          >
            <div class="ph-wu-success-detail-icon">
              <img src="@/assets/images/icons/person-pin-circle.svg" />
            </div>
            <div class="ph-wu-success-detail-text">
              <strong>MTCN</strong>
              <p>{{mtcn !== '' ? mtcn : 'XXX-XXX-XXXX'}}</p>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        elevation="0"
        class="ph-primary-button ph-primary-execute-btn"
        @click="backToReceivePage"
      >
        Back to receive money
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { formatMTCN, renderAVPSpiel, getAge, convertToRealAmount } from '@/utils'

export default {
  name: 'ReceiveMoneyWUSuccess',
  data () {
    return {
      receiverName: '',
      loyaltyCardNumber: '',
      loyaltyPoints: '',
      mtcn: '',
      crossSellingData: {}
    }
  },
  computed: {
    ...mapGetters({
      currentTrx: 'wu/currentTrx',
      payoutSuccessData: 'wu/payoutSuccessData',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    const payload = {
      product: 'remittance',
      amount: this.convertToRealAmount(this.currentTrx.result.payment_transaction.financials.principal_amount),
      gender: this.selectedCustomer.gender,
      age: this.getAge(this.selectedCustomer.birth_date),
      type: this.selectedCustomer.civil_status
    }

    await this.$store.dispatch('getCrossSellingSpiel', payload).then(res => {
      this.crossSellingData = res.result[0]
      this.renderAVPSpiel(this.crossSellingData)
    }).catch(err => {
      console.log(err)
    })

    this.receiverName = `${this.selectedCustomer.first_name} ${this.selectedCustomer.last_name}`
    this.loyaltyCardNumber = (this.selectedCustomer.wu_card_no !== '0' || this.selectedCustomer.wu_card_no !== null) ? this.selectedCustomer.wu_card_no : '-'
    this.loyaltyPoints = this.payoutSuccessData.new_points_earned
    this.mtcn = this.formatMTCN(this.currentTrx.result.payment_transaction.mtcn)

    this.$store.dispatch('report/getRptUserBalanceTotal', {
      locationID: this.currUser.location_id,
      trxDate: this.currUser.trx_date,
      userID: this.currUser.user_id
    })
  },
  methods: {
    formatMTCN,
    renderAVPSpiel,
    getAge,
    convertToRealAmount,
    backToReceivePage () {
      this.$store.commit('wu/SET_SHOW_PAYOUT_SEARCH_FORM', false)
      this.$store.commit('wu/SET_SHOW_PAYOUT_RESULT_FORM', false)
      this.$store.commit('wu/SET_CURRENT_TRANSACTION', {})
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
      this.$store.commit('wu/SET_PAYOUT_CURRENT_CURRENCY', '')
      this.$store.commit('SET_SHOW_PAYOUT_SUCCESS_DATA', {})
      this.$store.commit('SET_SHOW_PAYOUT_SUCCESS_SCREEN', false)
      this.$router.push('/pera-remit/receive')
    }
  }
}
</script>
<style scoped>
  .ph-wu-form-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-wu-success-detail {
    border: 1px solid #CCC !important;
    border-radius: 10px !important;
    display: flex;
    padding: 10px 18px;
  }

  .ph-wu-success-detail-text {
    margin-left: 10px;
    margin-top: 4px;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    font-size: 14px;
  }

  @media only screen and (min-width: 600px) {
    .ph-primary-button {
      width: 250px;
      margin-left: auto;
    }
  }
</style>
