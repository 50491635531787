export default {
  SET_CURRENT_MONITORING_VIEW (state, payload) {
    state.currentMonitoringView = payload
  },
  SET_SHOW_PROMPT_DIALOG (state, payload) {
    state.showPromptDialog = payload
  },
  SET_SHOW_FLAGGED_PROMPT_DIALOG (state, payload) {
    state.showFlaggedPromptDialog = payload
  },
  SET_FLAGGED_TRANSACTION_PAYLOAD (state, payload) {
    state.flaggedTransactionPayload = { ...payload }
  },
  SET_PROMPT_DATA (state, payload) {
    state.promptData = {
      ...state.promptData,
      ...payload
    }
  },
  SET_APPROVER_LEVEL (state, payload) {
    state.approverLevel = payload
  },
  SET_CURRENT_INCREASE_REQUESTS_VIEW (state, payload) {
    state.currentIncreaseRequestsView = payload
  },
  SET_SELECTED_INCREASE_LOCATION_LIMIT (state, payload) {
    state.selectedIncreaseLocationLimit = payload
  },
  SET_SELECTED_INCREASE_CUSTOMER_LIMIT (state, payload) {
    state.selectedIncreaseCustomerLimit = payload
  },
  SET_INCREASE_LOCATION_REQUESTS (state, payload) {
    state.increaseLocationRequests = payload
  },
  SET_INCREASE_CUSTOMER_REQUESTS (state, payload) {
    state.increaseCustomerRequests = payload
  },
  SET_CURRENT_APPROVAL_REQUESTS_VIEW (state, payload) {
    state.currentApprovalRequestsView = payload
  },
  SET_SELECTED_APPROVAL_LOCATION_LIMIT (state, payload) {
    state.selectedApprovalLocationLimit = payload
  },
  SET_SELECTED_APPROVAL_CUSTOMER_LIMIT (state, payload) {
    state.selectedApprovalCustomerLimit = payload
  },
  SET_APPROVAL_LOCATION_REQUESTS (state, payload) {
    state.approvalLocationRequests = payload
  },
  SET_APPROVAL_CUSTOMER_REQUESTS (state, payload) {
    state.approvalCustomerRequests = payload
  },
  SET_CURRENT_VIEW (state, payload) {
    state.currentView = payload
  },
  SET_SELECTED_LOCATION_LIMIT (state, payload) {
    state.selectedLocationLimit = payload
  },
  SET_SELECTED_CUSTOMER_LIMIT (state, payload) {
    state.selectedCustomerLimit = payload
  },
  SET_SELECTED_APPROVER (state, payload) {
    state.selectedApprover = payload
  },
  SET_SELECTED_PROVIDER (state, payload) {
    state.selectedProvider = payload
  },
  SET_UPLOADED_DATA (state, payload) {
    state.uploadedData = payload
  },
  SET_FILE_NAME (state, payload) {
    state.fileName = payload
  },
  SET_APPROVAL_LOCATION_FORM_DATA (state, payload) {
    state.approvalLocationFormData = {
      ...state.approvalLocationFormData,
      ...payload
    }
  },
  SET_APPROVAL_CUSTOMER_FORM_DATA (state, payload) {
    state.approvalCustomerFormData = {
      ...state.approvalCustomerFormData,
      ...payload
    }
  },
  SET_INCREASE_LOCATION_FORM_DATA (state, payload) {
    state.increaseLocationFormData = {
      ...state.increaseLocationFormData,
      ...payload
    }
  },
  SET_INCREASE_CUSTOMER_FORM_DATA (state, payload) {
    state.increaseCustomerFormData = {
      ...state.increaseCustomerFormData,
      ...payload
    }
  },
  SET_LOCATION_FORM_DATA (state, payload) {
    state.locationFormData = {
      ...state.locationFormData,
      ...payload
    }
  },
  SET_CUSTOMER_FORM_DATA (state, payload) {
    state.customerFormData = {
      ...state.customerFormData,
      ...payload
    }
  },
  SET_APPROVER_FORM_DATA (state, payload) {
    state.approverFormData = {
      ...state.approverFormData,
      ...payload
    }
  },
  SET_PROVIDER_FORM_DATA (state, payload) {
    state.providerFormData = {
      ...state.providerFormData,
      ...payload
    }
  }
}
