<template>
  <div class="px-md-2">
    <h3 class="pt-md-6 custom-heading primary--text">
      Success!
    </h3>
    <p class="pt-md-4 pb-md-6">
      Thank you. Send money transaction with {{ currentRemcoName }} has been completed.
    </p>
    <strong>Sender:</strong> {{ selectedCustomer.full_name }}
    <v-row class="mt-md-6 mb-md-10">
      <v-col :cols="12" :md="3">
        <v-card class="rounded-lg" outlined>
          <div class="d-flex px-md-3 py-md-2">
            <div class="icon">
              <img src="@/assets/images/icons/credit-card.svg" alt="">
            </div>
            <div class="mt-md-1">
              <strong class="font-weight-bold">
                Loyalty Number
              </strong>
              <p class="body-2">
                {{ selectedCustomer.card_no || 'XXXXXXXXXX' }}
              </p>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col :cols="12" :md="3">
        <v-card class="rounded-lg" outlined>
          <div class="d-flex px-md-3 py-md-2">
            <div class="icon">
              <img src="@/assets/images/icons/credit-card.svg" alt="">
            </div>
            <div class="mt-md-1">
              <strong class="font-weight-bold">
                Loyalty Points
              </strong>
              <p class="body-2">
                {{ formatPoints(selectedCustomer.card_points) }} points
              </p>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col :cols="12" :md="3">
        <v-card class="rounded-lg" outlined>
          <div class="d-flex px-md-3 py-md-2">
            <div class="icon">
              <img src="@/assets/images/icons/credit-card.svg" alt="">
            </div>
            <div class="mt-md-1">
              <strong class="font-weight-bold">
                MTCN
              </strong>
              <p class="body-2">
                {{ referenceNumber }}
              </p>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-md-12">
      <v-btn
        @click="backToMain"
        class="rounded-lg"
        color="primary"
        large
      >
        Back to Send Money
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { renderAVPSpiel, getAge } from '@/utils'

export default {
  data () {
    return {
      crossSellingData: {}
    }
  },
  computed: {
    ...mapGetters({
      selectedCustomer: 'customers/selectedCustomer',
      transactionData: 'send/transactionData',
      remcoList: 'send/remcoList',
      storeData: 'send/storeData',
      currUser: 'auth/currUser'
    }),
    currentRemcoName () {
      const remcoDetails = this.remcoList.filter(remco => remco.id === this.transactionData.remcoID)

      if (remcoDetails.length === 1) return remcoDetails[0].name
      return ''
    },
    referenceNumber () {
      const { remcoID } = this.transactionData

      if (remcoID === 0) return this.storeData.confirmed_details?.mtcn
      if (remcoID === 9) return this.storeData.ControlNumber
      if (remcoID === 10 || remcoID === 22) return this.storeData.control_number
      return 'XXXXXXXXXX'
    }
  },
  methods: {
    renderAVPSpiel,
    getAge,
    backToMain () {
      this.$router.push({ path: '/pera-remit/send' })
    },
    formatPoints (points) {
      return numeral(points).format('0,0')
    }
  },
  async mounted () {
    const payload = {
      product: 'remittance',
      amount: this.transactionData.sendAmount,
      gender: this.selectedCustomer.gender,
      age: this.getAge(this.selectedCustomer.birth_date),
      type: this.selectedCustomer.civil_status
    }

    await this.$store.dispatch('getCrossSellingSpiel', payload).then(res => {
      this.crossSellingData = res.result[0]
      this.renderAVPSpiel(this.crossSellingData)
    }).catch(err => {
      console.log(err)
    })

    this.$store.commit('SET_PAGE_TITLE', 'Transaction Successful')

    this.$store.dispatch('report/getRptUserBalanceTotal', {
      locationID: this.currUser.location_id,
      trxDate: this.currUser.trx_date,
      userID: this.currUser.user_id
    })
  }
}
</script>

<style scoped>
p {
  color: #4A4A4A !important;
}
.icon {
  width: 20%;
}
</style>
