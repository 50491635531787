<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col class="flex" :cols="12" :sm="4" :md="4">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search Transaction"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="d-flex align-center ml-sm-4 pt-2 pt-sm-0" :cols="12" :sm="2">
        <v-btn
          v-if="currUser.location_type === '1'"
          @click="confirmAssignAllOAR"
          color="primary"
          block
          large
        >
          Assign All OAR
        </v-btn>
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-8" :cols="12">
        <v-data-table
          :headers="headers"
          :items="transactions"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:[`item.trx_date`]="{ item }">
            <span v-if="item.trx_date">
              {{ formatDate(item.trx_date) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.form_number`]="{ item }">
            <span v-if="item.form_number">
              {{ item.form_number }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.customer_number`]="{ item }">
            <span v-if="item.customer_number">
              {{ item.customer_number }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <span v-if="item.amount">
              {{ toMoney(item.amount) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.product_code`]="{ item }">
            <span v-if="item.product_code">
              {{ item.product_code }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.promo_code`]="{ item }">
            <span v-if="item.promo_code">
              {{ item.promo_code }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span class="blue--text" v-if="item.status === '1' && item.resp === '0' && item.form_number !== ''">
              <v-icon color="blue" class="mr-1" x-small>mdi-circle</v-icon>
              OAR Allocated
            </span>
            <span class="green--text" v-if="item.status === '0' && item.resp === '0'">
              <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
              Successful
            </span>
            <span class="red--text" v-if="item.status === '0' && item.resp !== '0'">
              <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
              Pending
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="doInquire(item)" v-if="item.err !== 'Success'">
                  <v-list-item-title>
                    Inquire
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="assignOAR(item)" v-if="item.form_number === ''">
                  <v-list-item-title>
                    Assign OAR
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="printOAR(item)" v-if="item.form_number !== ''">
                  <v-list-item-title>
                    Print OAR
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="newTransaction"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="assignAllOARDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Assign OAR
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          <p>
            Are you sure you want to assign an OAR to all transactions?
          </p>
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeAssignAllOARDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="checkEmail"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withoutEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Receipt Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updateDetails(selectedCustomer)"
            color="primary"
            text
          >
            Update Customer Record
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Receipt Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Receipt will send to the client/s email address. Would you like to proceed this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="printReceiptInstead"
            color="primary"
            text
          >
            I want a printed Receipt
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import {
  avatarInitial,
  renderToast,
  avatarColor,
  toProperCase,
  toMoney
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      receipt: 'receipt'
    })
  },
  data () {
    return {
      assignAllOARDialog: false,
      selectedTransaction: null,
      fab: false,
      search: '',
      transactions: [],
      breadcrumbsItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Load',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Date',
          sortable: true,
          value: 'trx_date',
          width: '100px'
        },
        {
          text: 'Reference No.',
          sortable: true,
          value: 'tid',
          width: '120px'
        },
        {
          text: 'Epin',
          sortable: true,
          value: 'epin',
          width: '120px'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'customer_number',
          width: '140px'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount',
          width: '110px'
        },
        {
          text: 'Product Code',
          sortable: true,
          value: 'product_code',
          width: '110px'
        },
        {
          text: 'Promo Code',
          sortable: true,
          value: 'promo_code',
          width: '110px'
        },
        {
          text: 'OAR No.',
          sortable: true,
          value: 'form_number',
          width: '120px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '100px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '100px'
        }
      ],
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true
    }
  },
  methods: {
    async printOAR (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: '2',
          or_series_no: '',
          oar_series_no: data.form_number,
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    },
    viewDetails (data) {},
    assignOAR (data) {},
    openOTPDialog () {
      this.withoutEmailPrompt = false
      this.withEmailPrompt = false
      this.doAssignAllOAR()
    },
    proceedTransaction () {
      this.openOTPDialog()
    },
    checkEmail () {
      this.assignAllOARDialog = false
      if (this.currUser.location_type === '1') {
        this.sendViaEmail = true
        if (this.currUser.email_address) {
          this.withoutEmailPrompt = false
          this.withEmailPrompt = true
        } else {
          this.withoutEmailPrompt = true
          this.withEmailPrompt = false
          this.sendViaEmail = false
        }
      } else {
        this.proceedTransaction()
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.openOTPDialog()
    },
    reprintOAR (data) {
      window.open(`/receipt?ref=${data.form_number}&trx_type=eload&type=OAR`, '_blank')
    },
    confirmAssignAllOAR (data) {
      const transactions = this.transactions.filter(item => item.form_number === '')

      if (transactions.length > 0) {
        this.selectedtItem = data
        this.assignAllOARDialog = true
      } else {
        renderToast('error', 'Assign OAR Error', 'Sorry, there are no available transactions to allocate OAR')
      }
    },
    closeAssignAllOARDialog () {
      this.selectedtItem = null
      this.assignAllOARDialog = false
    },
    async doAssignAllOAR () {
      try {
        const getReceipt = await this.$store.dispatch('getReceipt', {
          location_id: this.currUser.location_id,
          type: '2',
          application_id: this.currUser.application_id
        })

        if (getReceipt.code === 200) {
          const payload = {
            location_id: this.currUser.location_id,
            trx_date: this.currUser.trx_date,
            form_type: 'OAR',
            form_number: this.receipt.oar_series_no,
            customer_id: this.currUser.user_id,
            customer_name: `${this.currUser.last_name}, ${this.currUser.first_name}`
          }

          const req = await this.$store.dispatch('eload/assignAllOAR', payload)

          if (req.code === 200) {
            let totalAmount = 0

            const transactions = req.result

            for (let i = 0; i < transactions.length; i++) {
              totalAmount += transactions[i].amount + transactions[i].added_comission
            }

            const saveReceipt = await this.$store.dispatch('saveReceipt', {
              ereceipt_id: this.receipt.ereceipt_id,
              application_id: this.currUser.application_id,
              application_header_name: this.currUser.branchDetail.header_name,
              application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
              receipt_type: this.receipt.type,
              customer_id: this.currUser.user_id,
              customer_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
              customer_tin_no: '',
              customer_address: '',
              customer_email_address: (this.sendViaEmail) ? this.currUser.email_address : null,
              cashier_id: this.currUser.user_id,
              cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
              oar_receipt_no: this.receipt.oar_receipt_no,
              oar_number: this.receipt.oar_series_no,
              oar_service: 'Pera Load',
              oar_currency: 'PHP',
              oar_principal_amount: totalAmount,
              oar_discount: 0,
              oar_total: totalAmount,
              grand_total: totalAmount,
              print_email_hold: 0,
              is_forex_logo: 0
            })

            if (saveReceipt.code === 200) {
              if (saveReceipt.result.receipt_status === 1) {
                renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
              } else if (saveReceipt.result.receipt_status === 2) {
                const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                  application_id: this.currUser.application_id,
                  ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                })
                var newBlob = new Blob([res], { type: 'application/pdf' })
                var newFileURL = URL.createObjectURL(newBlob)
                window.open(newFileURL, '_blank')
              } else {
                renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
              }
            } else {
              renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
            }
          }

          this.closeAssignAllOARDialog()

          if (req.code === 200) {
            renderToast('success', 'Successful', 'Assigning of OAR to all has been successful')
            this.getGrid()
          } else {
            renderToast('error', `Error (${req.code})`, 'Failed to assign all OAR')
          }
        } else {
          renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
        }
      } catch (error) {
        console.log(error)
        renderToast('error', 'Internal Error', 'An error occurred while processing your request, please contact IT Support')
      }
    },
    async doInquire (data) {
      if (data) {
        try {
          let tpa = ['', '']

          if (this.currUser.tpa) tpa = this.currUser.tpa.split('|')

          const payload = {
            uid: tpa[0],
            pw: tpa[1],
            location_id: data.location_id,
            trx_date: this.formatDate(data.trx_date),
            rrn: data.rrn
          }

          const req = await this.$store.dispatch('eload/inquire', payload)

          if (req.code === 200) {
            renderToast('warning', 'Inquire', req.result.ERR)
          } else {
            renderToast('error', 'Failed', 'Failed to inquire this transaction')
          }
        } catch (error) {
          renderToast('error', 'Error', 'An error occurred while processing your transaction, please contact IT Support')
        }
      }
    },
    newTransaction () {
      this.$store.dispatch('eload/resetForm')
      this.$router.push({ path: '/pera-load/new' })
    },
    async getGrid () {
      const req = await this.$store.dispatch('eload/getGrid', {
        trxDate: this.currUser.trx_date,
        locationID: this.currUser.location_id
      })

      if (req.code === 200) {
        this.transactions = req.result
      }
    },
    formatDate (date) {
      return format(new Date(date), 'yyyy-MM-dd')
    },
    avatarInitial,
    avatarColor,
    toProperCase,
    toMoney
  },
  beforeMount () {
    this.getGrid()
  }
}
</script>
