<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
    </v-container>
    <v-row class="ph-transaction-container">
      <v-col cols="12" md="4">
        <v-autocomplete
          label="Country"
          :items="collections.countries"
          @change="getCountryInformation"
          @click:clear="clearCountryInformation"
          item-text="country"
          item-value="two_iso_code"
          color="gray darken-1"
          class="ph-textbox"
          outlined
          clearable
        />
      </v-col>
      <v-col cols="12">
        <p><b>Country Information:</b></p>
        <v-textarea
          v-model="countryDetails"
          name="input-7-1"
          filled
          auto-grow
          outlined
          readonly
          value=""
          class="ph-wu-f2zoom-textarea"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'F2Zoom',
  data () {
    return {
      countryDetails: 'Please select a country to continue',
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'F2 Zoom',
          disabled: false,
          href: '#'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      collections: 'collections',
      currUser: 'auth/currUser'
    })
  },
  mounted () {
    console.log(this.currUser)
  },
  methods: {
    clearCountryInformation () {
      this.countryDetails = 'Please select a country to continue'
    },
    async getCountryInformation (country) {
      if (country) {
        let getCurrency = ''

        await this.collections.countries.filter(data => {
          if (data.two_iso_code === country) {
            getCurrency = data.currency
          }
        })

        const payload = {
          location_code: this.currUser.location_code,
          user_code: this.currUser.user_id,
          country: `${country} ${getCurrency}`,
          terminal_id: this.currUser.fs_id,
          operator_id: this.currUser.user_id,
          location_id: this.currUser.location_id
        }

        this.$store.dispatch('wu/getF2ZoomResult', payload).then(res => {
          this.countryDetails = this.renderCountryInformation(res.result)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    renderCountryInformation (value) {
      const textCountry = []
      const countryData = value

      for (var i = 0; i < countryData.length; i++) {
        var _countrDyata = countryData[i].INFO
        if (typeof _countrDyata !== 'undefined' && _countrDyata !== null) {
          textCountry.push(countryData[i].INFO)
        } else {
          textCountry.push('')
        }
        // textCountry.push(countryData[i]['INFO'] + ' ');
      }

      const string = textCountry.toString()
      const countryInfo = string.replace(/,/g, '\n')

      console.log(countryInfo)

      return countryInfo
    }
  }
}
</script>
<style scoped>
  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 35px;
    }
  }

  .ph-wu-f2zoom-textarea {
    font-family: 'consolas' !important;
    font-size: 14px;
    line-height: 1.5;
  }
</style>
