<template>
  <div class="reports-container" v-if="currentReport !== null">
    <div class="report-header" id="reportActionHeader" style="display: flex; flex-direction: row;">
      <div class="report-logo">
        <img style="width: 100%; margin: auto" src="@/assets/images/ph-logo-horizontal.svg"/>
      </div>
      <div class="report-details">
        <p class="report-details-name"><strong>{{ reportData.name }}</strong></p>
        <p>Branch: <b>{{ reportData.branch }}</b></p>
        <p></p>
      </div>
      <div class="report-action" id="reportActionButtons">
        <v-btn elevation="0" color="primary" class="report-export" @click="$refs.reportTable.export(reportData.name, reportData.type)">
          <v-icon
            left
            dark
          >
            mdi-file-excel
          </v-icon>
          Export to Excel
        </v-btn>
        <v-btn elevation="0" class="report-print" @click="$refs.reportTable.print()">
          <v-icon
            left
            dark
          >
            mdi-printer-outline
          </v-icon>
          Print
        </v-btn>
      </div>
    </div>
    <component :is="currentReport" ref="reportTable" :reportData.sync="reportData.result" />
  </div>
</template>
<script>
import LocationLimits from '@/components/reports/types/LocationLimits.vue'
import CustomerLimits from '@/components/reports/types/CustomerLimits.vue'
import CustomerLimitRequests from '@/components/reports/types/CustomerLimitRequests.vue'
import CustomerIncreaseRequests from '@/components/reports/types/CustomerIncreaseRequests.vue'
import LocationLimitRequests from '@/components/reports/types/LocationLimitRequests.vue'
import LocationIncreaseRequests from '@/components/reports/types/LocationIncreaseRequests.vue'
import FlaggedTransactions from '@/components/reports/types/FlaggedTransactions.vue'
import { aesDecrypt } from '@/utils'

export default {
  data () {
    return {
      reportData: {},
      currentReport: null
    }
  },
  async beforeMount () {
    if (this.$route.query.genrpt) {
      const getBase64Data = Buffer.from(this.$route.query.genrpt, 'base64').toString('ascii')
      this.reportData = JSON.parse(getBase64Data)

      if (this.reportData.type === 'location-limits-report') {
        const { code, result } = await this.$store.dispatch('transactionLimit/getLocationLimitsReportData', this.reportData.payload)

        if (code === 200) {
          this.currentReport = LocationLimits
          this.reportData.result = result
        }
      }

      if (this.reportData.type === 'customer-limits-report') {
        const { code, result } = await this.$store.dispatch('transactionLimit/getCustomerLimitsReportData', this.reportData.payload)

        if (code === 200) {
          this.currentReport = CustomerLimits
          this.reportData.result = result
        }
      }

      if (this.reportData.type === 'request-logs-report') {
        const { code, result } = await this.$store.dispatch('transactionLimit/getRequestLogsReportData', this.reportData.payload)

        if (code === 200) {
          this.reportData.result = result

          if (this.reportData.payload.trxlimit_type === 'CUSTOMER') {
            if (this.reportData.payload.log_type === 'DEFAULT') this.currentReport = CustomerLimitRequests
            if (this.reportData.payload.log_type === 'INCREASE') this.currentReport = CustomerIncreaseRequests
          }

          if (this.reportData.payload.trxlimit_type === 'LOCATION') {
            if (this.reportData.payload.log_type === 'DEFAULT') this.currentReport = LocationLimitRequests
            if (this.reportData.payload.log_type === 'INCREASE') this.currentReport = LocationIncreaseRequests
          }
        }
      }

      if (this.reportData.type === 'flagged-transactions-report') {
        const { code, result } = await this.$store.dispatch('transactionLimit/getFlaggedTransactionReportData', this.reportData.payload)

        if (code === 200) {
          this.currentReport = FlaggedTransactions
          this.reportData.result = result
        }
      }
    } else {
      window.close()
    }
  },
  methods: {
    aesDecrypt
  }
}
</script>
<style>
.report-header {
  display: flex;
  flex-direction: row;
}
.report-logo {
  display: flex;
  width: 200px;
  height: auto;
  margin: 15px;
}

.report-details {
  margin-right: 15px;
}

.report-details p {
  margin: 0;
}

.report-table {
  width: 100%;
  border-collapse: collapse;
}

.report-table-head {
  background: #1A2791;
  color: #fff;
  border: 1px solid black;
}

.report-table-head th {
  border: 0
}

.report-details-name {
  text-transform: uppercase;
}

.report-action {
  margin-top: 15px;
  margin-right: 20px;
  margin-left: auto;
}

.report-export {
  margin-right: 15px;
}

@media print {
  #reportActionHeader {
    display: block;
  }

  #reportActionButtons {
    display: none;
  }
}

.reports-container {
  overflow-x: auto;
}
</style>
