import api from '@/api'

export default {
  grid (trxDate, locationID) {
    return api.get(`/v1/transactions/api/trx/mc?trx_date=${trxDate}&location_id=${locationID}`)
  },
  save (payload) {
    return api.post('/v1/transactions/api/trx/mc', payload)
  },
  rates (trxDate, locationID, currencyID) {
    return api.get(`/v1/transactions/api/trx/mc-get-rate?trx_date=${trxDate}&location_id=${locationID}&currency_id=${currencyID}`)
  }
}
