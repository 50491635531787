<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <h2 class="title ph-title primary--text mx-6 my-2" style="font-size: 24px !important;">
      Information
    </h2>
    <v-row class="mb-3 mx-2">
      <v-col :cols="3">
        <v-form ref="form">
          <v-row class="mt-1">
            <v-col :cols="12">
              <v-text-field
                v-model="formData.firstName"
                class="ph-textbox"
                label="First Name *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.lastName"
                class="ph-textbox"
                label="Last Name *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.predicateCrime"
                :items="predicateCrimes"
                class="ph-textbox"
                label="Predicate Crime *"
                :rules="requiredDropdownRules"
                multiple
                outlined
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.value }} &nbsp;</span>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ formData.predicateCrime.length - 1 }} more)</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.source"
                class="ph-textbox"
                label="Source *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-menu
                v-model="datePublishedPicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.datePublished"
                    :rules="dateRules"
                    class="ph-textbox"
                    label="Date Published *"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    clearable
                  />
                </template>
                <v-date-picker
                  v-model="formData.datePublished"
                  @input="syncDatePublished()"
                />
              </v-menu>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="save" class="font-weight-bold" color="primary" block x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      predicateCrimes: [],
      datePublishedPicker: false,
      formData: {
        firstName: '',
        lastName: '',
        predicateCrime: [],
        source: '',
        datePublished: ''
      },
      requiredRules: [v => !!v || 'This field is required'],
      requiredDropdownRules: [v => v.length > 0 || 'This field is required'],
      dateRules: [
        v => !!v || 'This field is required',
        v => (!v || (!!v && /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v))) || 'Use YYYY-MM-DD format'
      ],
      breadcrumbsItems: [
        {
          text: 'Compliance Module',
          disabled: false,
          exact: true,
          to: '/compliance'
        },
        {
          text: 'Negative List',
          disabled: false,
          exact: true,
          to: '/compliance/negative-list'
        },
        {
          text: 'New Entry',
          disabled: true
        }
      ]
    }
  },
  methods: {
    syncDatePublished () {
      this.datePublishedPicker = false
    },
    async save () {
      if (this.$refs.form.validate()) {
        const payload = {
          id: this.$route.params.id,
          first_name: this.formData.firstName,
          last_name: this.formData.lastName,
          predicate_crime: JSON.stringify(this.formData.predicateCrime),
          updated_by: this.currUser.user_id,
          source: this.formData.source,
          date_published: this.formData.datePublished
        }

        const { code } = await this.$store.dispatch('compliance/updateNegativeListEntry', payload)

        if (code === 200) {
          renderToast('success', 'Successful', 'Customer in the negative list has been updated')
          this.$router.push('/compliance/negative-list')
        } else {
          renderToast('error', 'Error', 'Failed to update customer to the negative list')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill out all required fields')
      }
    },
    cancel () {
      this.$router.push('/compliance/negative-list')
    }
  },
  async mounted () {
    const id = this.$route.params.id

    if (id) {
      const { code, result } = await this.$store.dispatch('compliance/retrieveNegativeListItem', id)

      if (code === 200) {
        if (result.length === 1) {
          this.predicateCrimes = await this.$store.dispatch('compliance/getPredicateCrimes')
          this.formData = {
            ...this.formData,
            firstName: result[0].first_name,
            lastName: result[0].last_name,
            predicateCrime: JSON.parse(result[0].predicate_crime),
            source: result[0].source,
            datePublished: result[0].date_published
          }
        } else {
          renderToast('error', 'Error', 'Failed to retrieve Negative List details')
          this.$router.push('/compliance/negative-list')
        }
      } else {
        renderToast('error', 'Error', 'Failed to retrieve Negative List details')
        this.$router.push('/compliance/negative-list')
      }
    } else {
      this.$router.push('/compliance/negative-list')
    }
  }
}
</script>
