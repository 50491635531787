<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="mt-md-12 mb-md-10 ma-2" no-gutters>
        <v-col :cols="12" :sm="4" :md="3">
          <v-select
            v-model="currency"
            :items="currencyList"
            item-text="text"
            item-value="value"
            class="ph-textbox ml-md-3"
            label="Currency"
            outlined
            @change="selectedCurrency(currency)"
          />
        </v-col>
      </v-row>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters v-if="hasSelectedCurrency">
        <v-col :cols="12" :sm="8" :md="6">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-data-table
              :headers="headers"
              :items="cashCountEntries"
              class="custom-table"
              :calculate-widths="true"
              :items-per-page="-1"
              :hide-default-footer="true"
            >
              <template v-slot:[`item.bills_id`]="{ index }">
                {{ selectedDenomination[index].value }}
              </template>
              <template v-slot:[`item.bills_count`]="{ item }">
                <v-text-field
                  v-model="item.bills_count"
                  color="gray darken-1"
                  outlined
                  number
                  type="number"
                  hide-spin-buttons
                  class="ph-textbox ph-textbox-cash-count"
                  @click="item.bills_count = ''"
                />
              </template>
              <template v-slot:[`item.total_amount`]="{ item }">
                {{ !isNaN(item.total_amount) ? item.total_amount : 0 }}
              </template>
           </v-data-table>
          </v-card>
          <v-row class="mt-md-12 mb-md-10 ma-2" no-gutters>
            <v-col cols="12">
              Total Amount: {{ totalAmount }}
            </v-col>
            <br>
            <br>
            <v-col cols="12">
              <v-btn
                x-large
                color="primary"
                elevation="0"
                class="ph-action-button ph-cashcountform-action-btn"
                outlined
                @click="cashCountAction('cancel')"
              >
                Cancel
              </v-btn>
              <v-btn
                x-large
                color="primary"
                elevation="0"
                class="ph-action-button ph-primary-execute-btn ph-cashcountform-action-btn"
                @click="cashCountAction('save')"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog
        v-model="confirmDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            {{ confirmDialogTitle }}
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            {{ confirmDialogText }}
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="closeConfirmDialog"
              class="ph-action-button"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="doConfirmAction(cashCountEntries, confirmActionType)"
              class="ph-action-button"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { getForeignRefNo, renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      cashCountList: 'cashCount/cashCountList'
    })
  },
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'BOS',
          disabled: false,
          href: '#'
        },
        {
          text: 'Cash Count',
          disabled: false,
          href: '/cash-count'
        },
        {
          text: 'New Entry',
          disabled: true,
          href: '#'
        }
      ],
      currencyList: [
        { text: 'Philippine Peso - PHP', value: '1' },
        { text: 'US Dollar - USD', value: '2' }
      ],
      headers: [
        {
          text: 'Denomination',
          sortable: false,
          value: 'bills_id'
        },
        {
          text: 'Quantity',
          sortable: false,
          value: 'bills_count'
        },
        {
          text: 'Amount',
          sortable: false,
          value: 'total_amount'
        }
      ],
      cashCountEntries: [
        {
          location_id: '',
          trx_date: '',
          bills_id: '',
          bills_count: '',
          total_amount: '',
          encoded_by: '',
          reference_no: '',
          client_ip: '',
          terminal_id: '',
          currency_id: ''
        }
      ],
      hasSelectedCurrency: false,
      currency: '',
      requiredField: [
        v => !!v || 'This field is required'
      ],
      selectedDenomination: [],
      totalAmount: 0.00,
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      isEntryValid: true
    }
  },
  watch: {
    cashCountEntries: {
      handler (data) {
        let getTotal = 0
        let missingFieldCounter = 0
        data.forEach((item, index) => {
          if (!isNaN(item.bills_count)) {
            const getTotalAmountPerItem = parseFloat(this.selectedDenomination[index].value) * parseFloat(item.bills_count)
            const getTotalPerItemRounded = Math.round(getTotalAmountPerItem * 100) / 100
            item.bills_count = parseFloat(item.bills_count)
            item.total_amount = getTotalPerItemRounded
            getTotal += item.total_amount
          } else {
            missingFieldCounter += 1
          }
        })

        if (!isNaN(getTotal)) {
          this.totalAmount = numeral(getTotal).format('0,0.00')
        }

        this.isEntryValid = missingFieldCounter < 1
      },
      deep: true
    }
  },
  beforeMount () {
    this.hasSelectedCurrency = false
  },
  methods: {
    renderToast,
    getForeignRefNo,
    async selectedCurrency (currency) {
      this.hasSelectedCurrency = true
      this.cashCountEntries = []
      this.selectedDenomination = []
      const phpDenomination = [
        { bills_id: 23, value: '0.1' },
        { bills_id: 22, value: '0.25' },
        { bills_id: 21, value: '0.05' },
        { bills_id: 10, value: '0.01' },
        { bills_id: 9, value: '1' },
        { bills_id: 8, value: '5' },
        { bills_id: 7, value: '10' },
        { bills_id: 6, value: '20' },
        { bills_id: 5, value: '50' },
        { bills_id: 4, value: '100' },
        { bills_id: 3, value: '200' },
        { bills_id: 2, value: '500' },
        { bills_id: 1, value: '1000' }
      ]

      const usdDenomination = [
        { bills_id: 20, value: '1' },
        { bills_id: 19, value: '2' },
        { bills_id: 18, value: '5' },
        { bills_id: 17, value: '10' },
        { bills_id: 16, value: '20' },
        { bills_id: 15, value: '50' },
        { bills_id: 14, value: '100' }
      ]

      if (currency === '1') {
        this.selectedDenomination = phpDenomination.reverse()
      } else {
        this.selectedDenomination = usdDenomination.reverse()
      }

      const refNo = this.getForeignRefNo()

      this.selectedDenomination.forEach((item) => {
        this.cashCountEntries.push({
          location_id: this.currUser.location_id,
          trx_date: this.currUser.trx_date,
          bills_id: item.bills_id,
          bills_count: 0,
          total_amount: 0,
          encoded_by: this.currUser.user_id,
          reference_no: refNo.slice(0, 10),
          client_ip: this.currUser.ip_address,
          terminal_id: this.currUser.fs_id,
          currency_id: parseInt(currency)
        })
      })
    },
    closeConfirmDialog () {
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''
    },
    async getCashCountList () {
      const payload = {
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id,
        encoded_by: this.currUser.user_id
      }
      await this.$store.dispatch('cashCount/getCashCountList', payload)
    },
    async cashCountAction (type) {
      this.confirmActionType = type
      let checkCurrency = []

      switch (type) {
        case 'save':
          if (this.isEntryValid) {
            if (this.cashCountList.length > 0) {
              checkCurrency = this.cashCountList.filter(item => {
                return item.currency_id === parseInt(this.currency)
              })
            } else {
              await this.getCashCountList()

              checkCurrency = this.cashCountList.filter(item => {
                return item.currency_id === parseInt(this.currency)
              })
            }

            if (checkCurrency.length > 0) {
              this.renderToast('error', 'Currency already exists', 'Please choose another currency or update the current entry.')
            } else {
              this.confirmDialog = true
              this.confirmDialogTitle = 'Save Entry'
              this.confirmDialogText = 'Are you sure you want to save your entry?'
            }
          } else {
            this.renderToast('error', 'Incomplete Field', 'Please complete the fields to continue.')
          }

          break
        case 'cancel':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Cancel Entry'
          this.confirmDialogText = 'Are you sure you want to cancel?'
          break
      }
    },
    async doConfirmAction (payload, type) {
      this.closeConfirmDialog()
      if (type === 'cancel') {
        this.$router.push('/cash-count')
      } else {
        await this.$store.dispatch('cashCount/insertCashCountEntry', payload).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.renderToast('error', 'Error', res.message)
          } else {
            this.renderToast('success', 'Success', 'Insert Cash Count Entry Successful')
            this.$router.push('/cash-count')
          }
        }).catch(err => {
          this.renderToast('error', 'Error', err)
        })
      }
    }
  }
}
</script>
<style>
.v-input.ph-textbox.ph-textbox-cash-count,
.v-input.ph-textbox.ph-textbox-cash-count .v-input__slot {
  height: 30px !important;
  min-height: 30px !important;
}

.ph-action-button.ph-cashcountform-action-btn {
  margin: 0 20px;
  margin-left: 0;
}
</style>
