<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <h2 class="title ph-title primary--text mx-6 my-2" style="font-size: 24px !important;">
      PEP Details
    </h2>
    <v-row class="mb-3 mx-2">
      <v-col :cols="3">
        <v-form ref="form">
          <v-row class="mt-1">
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.category"
                @change="changeCategory"
                :items="categories"
                class="ph-textbox"
                label="Category *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.firstName"
                class="ph-textbox"
                label="First Name *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.lastName"
                class="ph-textbox"
                label="Last Name *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.riskScore"
                :items="riskScores"
                class="ph-textbox"
                label="Risk Score *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-menu
                v-model="birthDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.birthDate"
                    :rules="dateRules"
                    class="ph-textbox"
                    label="Birth Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    clearable
                  />
                </template>
                <v-date-picker
                  v-model="formData.birthDate"
                  @input="syncBirthDate()"
                />
              </v-menu>
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.areaCovered"
                class="ph-textbox"
                label="Area Covered *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.position"
                :items="filteredPositions"
                class="ph-textbox"
                label="Position *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col class="pt-4 pb-4" :cols="12" v-if="showLinkPEP">
              <v-btn
                v-if="hasLinkToPEP"
                class="mb-4"
                @click="removeLinkToPEP"
                color="red"
                outlined
                small
              >
                Remove Link to PEP
              </v-btn>
              <v-btn
                v-else
                class="mb-4"
                @click="addLinkToPEP"
                color="primary"
                outlined
                small
              >
                Add Link to PEP
              </v-btn>
              <v-text-field
                v-model="formData.linkPEPName"
                class="ph-textbox"
                label="Link to PEP"
                placeholder="Lastname, Firstname"
                append-icon="mdi-magnify"
                @click:append="doSearchPEP"
                @keyup.enter="doSearchPEP"
                hint="Press ENTER or click the 🔍 to search"
                :rules="linkToPEPRules"
                v-if="hasLinkToPEP"
                :disabled="formData.linkTo !== 0"
                outlined
              />
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="save" class="font-weight-bold" color="primary" block x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showSearchPEPResult"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          <span>Select PEP to Link</span>
          <v-spacer />
          <v-btn @click="showSearchPEPResult = false" dark small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="ph-dialog-header text-center">
          <v-data-table
            :headers="searchPEPHeaders"
            :items="searchPEPResult"
            class="custom-table"
            no-header
            calculate-widths
          >
            <template v-slot:[`item.customer_name`]="{ item }">
              <v-avatar class="mr-1" size="26px" :style="avatarColor(item.customer_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.customer_name) }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn @click="addLinkTo(item)" color="primary" icon>
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarInitial,
  toProperCase,
  avatarColor,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      showLinkPEP: false,
      hasLinkToPEP: true,
      birthDatePicker: false,
      showSearchPEPResult: false,
      searchPEPResult: [],
      categories: [],
      positions: [],
      filteredPositions: [],
      riskScores: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 }
      ],
      formData: {
        category: null,
        firstName: '',
        lastName: '',
        riskScore: '',
        birthDate: '',
        areaCovered: '',
        position: null,
        linkTo: 0,
        linkPEPName: ''
      },
      requiredRules: [v => !!v || 'This field is required'],
      linkToPEPRules: [
        v => !!v || 'This field is required',
        v => (!!this.formData.linkTo) || 'Search to link PEP'
      ],
      dateRules: [
        v => (!v || (!!v && /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v))) || 'Use YYYY-MM-DD format'
      ],
      breadcrumbsItems: [
        {
          text: 'Compliance Module',
          disabled: false,
          exact: true,
          to: '/compliance'
        },
        {
          text: 'PEP',
          disabled: false,
          exact: true,
          to: '/compliance/pep'
        },
        {
          text: 'New Entry',
          disabled: true
        }
      ],
      searchPEPHeaders: [
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customer_name',
          width: '280px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '10px'
        }
      ]
    }
  },
  methods: {
    addLinkTo (item) {
      this.formData = {
        ...this.formData,
        linkTo: item.id,
        linkPEPName: `${toProperCase(item.last_name.replace(/(^\s+|\s+$)/g, ''))}, ${toProperCase(item.first_name.replace(/(^\s+|\s+$)/g, ''))}`
      }
      this.showSearchPEPResult = false
      this.searchPEPResult = []
    },
    async doSearchPEP () {
      const [lastName, firstName] = this.formData.linkPEPName.replace(/(^\s+|\s+$)/g, '').split(',')

      if (firstName && lastName) {
        this.searchPEPResult = await this.$store.dispatch('compliance/searchPEP', { firstName, lastName })
        this.searchPEPResult = this.searchPEPResult.map(item => {
          return {
            ...item,
            customer_name: `${item.first_name} ${item.last_name}`
          }
        })
        this.showSearchPEPResult = true
      } else {
        this.renderToast('warning', 'Validation Error', 'All fields')
      }
    },
    resetLinkTo () {
      this.formData = {
        ...this.formData,
        linkTo: 0,
        linkPEPName: ''
      }
    },
    addLinkToPEP () {
      this.hasLinkToPEP = true
      this.resetLinkTo()
    },
    removeLinkToPEP () {
      this.hasLinkToPEP = false
      this.resetLinkTo()
    },
    changeCategory (id) {
      if (id) {
        const [{ text, value }] = this.categories.filter(item => item.value === id)

        this.filteredPositions = [
          ...this.positions.filter(item => item.category === value)
        ]

        if (text === 'PEP') {
          this.formData = {
            ...this.formData,
            linkTo: 0,
            linkPEPName: ''
          }
          this.showLinkPEP = false
          this.hasLinkToPEP = true
        } else {
          this.showLinkPEP = true
          this.hasLinkToPEP = true
        }
      }
    },
    syncBirthDate () {
      this.birthDatePicker = false
    },
    async save () {
      if (this.$refs.form.validate()) {
        const payload = {
          first_name: this.formData.firstName,
          last_name: this.formData.lastName,
          category_id: this.formData.category,
          position_id: this.formData.position,
          link_to: this.formData.linkTo,
          link_pep_name: this.formData.linkPEPName,
          updated_by: this.currUser.user_id,
          risk_score: this.formData.riskScore,
          birth_date: this.formData.birthDate,
          area_covered: this.formData.areaCovered
        }

        const { code } = await this.$store.dispatch('compliance/addPEPEntry', payload)

        if (code === 200) {
          renderToast('success', 'Successful', 'PEP has been added')
          this.$router.push('/compliance/pep')
        } else {
          renderToast('error', 'Error', 'Failed to add new PEP')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill out all required fields')
      }
    },
    cancel () {
      this.$router.push('/compliance/pep')
    },
    avatarInitial,
    toProperCase,
    avatarColor
  },
  async mounted () {
    this.categories = await this.$store.dispatch('compliance/getPEPCategories')
    this.positions = await this.$store.dispatch('compliance/getPEPPositions')
  }
}
</script>
