<template>
  <div>
    <strong>Select Remittance Partner</strong>
    <v-row class="mt-md-3 mb-md-5" no-gutters>
      <v-col
        v-for="remco in remcoList"
        :key="remco.id"
        class="py-1 py-md-0 pr-md-2 pb-md-2"
        :cols="12"
        :sm="6"
        :md="4"
      >
        <v-card
          @click="selectRemco(remco.id)"
          :class="['rounded-lg py-1 remco', { 'selected': transactionData.remcoID === remco.id }]"
          :disabled="!remco.fee || (remco.id !== 0 && transactionData.destinationCountryID !== countryID)"
          outlined
          flat
        >
          <v-card-text>
            <v-row align="center" no-gutters>
              <v-col class="d-flex flex-column align-center" :cols="4">
                <img
                  v-if="remco.logo"
                  style="width: 100%"
                  :src="require(`@/assets/images/partner/${remco.logo}`)"
                  alt=""
                >
              </v-col>
              <v-col class="pl-md-2" :cols="8">
                <span>{{ remco.name }}</span>
                <br>
                <strong>Charge: {{ remco.fee ? toMoney(remco.fee) : '—' }}</strong>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-3 mb-md-5" no-gutters>
      <v-col class="pr-md-1" :cols="12" :md="4">
        <v-text-field
          v-model="transactionData.promoCode"
          class="ph-textbox"
          label="Promo Code"
          @blur="feeInquiry()"
          :disabled="!transactionData.destinationCountryID || transactionData.remcoID !== 0"
          outlined
        />
      </v-col>
      <v-col class="px-md-1 pt-3 text-md-center" :cols="12" :md="4">
        Discount Amount:  <strong>{{ toMoney(transactionData.discountAmount) }}</strong>
      </v-col>
      <v-col class="pl-md-1 pt-2 pt-md-3 text-md-right" :cols="12" :md="4">
        Total Amount:  <strong>{{ toMoney(transactionData.totalAmount) }}</strong>
      </v-col>
      <v-col :cols="12">
        <v-textarea
          v-model="message"
          class="ph-textarea mt-4 mb-4 mt-md-6 mb-md-6"
          label="Message"
          @blur="feeInquiry()"
          :disabled="!transactionData.destinationCountryID || transactionData.remcoID !== 0"
          :rows="1"
          auto-grow
          filled
          outlined
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toMoney } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      selectedCustomer: 'customers/selectedCustomer',
      transactionData: 'send/transactionData',
      feeInquiryData: 'send/feeInquiryData',
      remcoList: 'send/remcoList',
      currUser: 'auth/currUser',
      remoteData: 'obo/remoteData',
      countryID: 'send/phID'
    }),
    message: {
      get () {
        return this.transactionData.message.join('\n')
      },
      set (val) {
        this.$store.commit('send/SET_TRANSACTION_DATA', {
          prop: 'message',
          value: val.split('\n')
        })
      }
    }
  },
  data () {
    return {
      terminalId: '',
      operatorId: '',
      locationId: ''
    }
  },
  methods: {
    async feeInquiry (flag) {
      const {
        destinationCountryID,
        destinationCountryCode,
        destinationCountryCurrency,
        deliveryService,
        remcoID,
        sendAmount,
        receiveAmount,
        promoCode,
        message,
        refNo
      } = this.transactionData

      if (flag && sendAmount && receiveAmount) return false

      if (flag) {
        this.$store.commit('send/SET_TRANSACTION_DATA', {
          prop: 'fixedAmountFlag',
          value: flag
        })
      }

      this.transactionData.serviceFee = ''
      this.transactionData.totalAmount = ''

      const { fixedAmountFlag } = this.transactionData

      if (destinationCountryID && fixedAmountFlag && (sendAmount || receiveAmount) && (Number(sendAmount) > 0 || Number(receiveAmount) > 0)) {
        let principalAmount

        if (fixedAmountFlag === 'N') principalAmount = sendAmount
        if (fixedAmountFlag === 'F') principalAmount = receiveAmount

        if (principalAmount) {
          const payload = {
            principal_amount: principalAmount,
            agent_code: this.currUser.location_code,
            // agent_code: '01030063',
            branch_code: this.currUser.location_code,
            foreign_reference_no: refNo,
            fixed_amount_flag: fixedAmountFlag,
            destination_country_code: destinationCountryCode,
            destination_currency_code: destinationCountryCurrency,
            transaction_type: deliveryService.value || 'MIM',
            promo_code: promoCode,
            message: message,
            message_line_count: message.length,
            terminal_id: this.terminalId,
            operator_id: this.operatorId,
            location_id: this.locationId
          }

          const req = await this.$store.dispatch('send/feeInquiry', payload)

          if (req.code === 200) {
            const remcos = Object.values(req.result)

            remcos.forEach(remco => {
              this.$store.commit('send/SET_REMCO_FEE', {
                id: remco.id,
                serviceFee: remco.service_fee,
                body: remco.body
              })

              if (remco.id === 0) {
                this.$store.commit('send/SET_FEE_INQUIRY_DATA', remco.body)
                this.$store.commit('send/SET_TRANSACTION_DATA', {
                  prop: 'exchangeRate',
                  value: this.feeInquiryData.exchange_rate
                })

                if (destinationCountryID !== this.countryID) {
                  this.$store.commit('send/SET_TRANSACTION_DATA', {
                    prop: 'sendAmount',
                    value: Number(this.feeInquiryData.originators_principal_amount) / 100
                  })

                  this.$store.commit('send/SET_TRANSACTION_DATA', {
                    prop: 'receiveAmount',
                    value: Number(this.feeInquiryData.pay_amount) / 100
                  })
                }
              }
            })

            if (destinationCountryID === this.countryID) {
              this.$store.commit('send/SET_TRANSACTION_DATA', {
                prop: 'exchangeRate',
                value: '1 PHP = 1.0000000 PHP'
              })
              this.$store.commit('send/SET_TRANSACTION_DATA', {
                prop: 'receiveAmount',
                value: principalAmount
              })
            }

            if (remcoID !== '') {
              const selectedRemco = this.remcoList.filter(remco => remco.id === remcoID)
              let totalAmount = (Number(sendAmount) + Number(selectedRemco[0].fee))

              if (remcoID === 0 && fixedAmountFlag === 'F') {
                totalAmount = selectedRemco[0]?.body?.gross_total_amount / 100
              }

              this.$store.commit('send/SET_TRANSACTION_DATA', {
                prop: 'totalAmount',
                value: totalAmount
              })

              this.$store.commit('send/SET_TRANSACTION_DATA', {
                prop: 'serviceFee',
                value: selectedRemco[0].fee
              })
            }
          } else {
            // Error handler
          }
        }
      }
    },
    async selectRemco (id) {
      const selectedRemco = this.remcoList.filter(remco => remco.id === id)

      console.log(selectedRemco, 'selected remco')

      this.$store.commit('send/SET_TRANSACTION_DATA', {
        prop: 'remcoID',
        value: id
      })

      this.$store.commit('send/SET_TRANSACTION_DATA', {
        prop: 'remcoName',
        value: selectedRemco[0].name
      })

      const getCollections = [
        'getPurposeOfTransaction',
        'getOccupation',
        'getIDList'
      ]

      getCollections.forEach(action => this.$store.dispatch(action, id))

      if (this.transactionData.destinationCountryCode === 'US' || this.transactionData.destinationCountryCode === 'MX') {
        this.$store.dispatch('getStateAndCities', this.transactionData.destinationCountryCode)
      }

      if (selectedRemco.length === 1) {
        this.$store.commit('send/SET_TRANSACTION_DATA', {
          prop: 'serviceFee',
          value: selectedRemco[0].fee
        })

        const {
          fixedAmountFlag,
          sendAmount,
          serviceFee
        } = this.transactionData

        let totalAmount = (Number(sendAmount) + Number(serviceFee))

        if (id === 0 && fixedAmountFlag === 'F') {
          totalAmount = selectedRemco[0]?.body?.gross_total_amount / 100
        }

        if (id === 0) {
          const currency = this.transactionData.destinationCountryCurrency
          const countryCode = this.transactionData.destinationCountryCode

          const payload = {
            location_id: this.currUser.location_id,
            operator_id: this.operatorId,
            module: 'wudas',
            request: 'GetDeliveryServices',
            terminal_id: this.currUser.fs_id,
            DestinationCountry: `${countryCode} ${currency}`,
            TransactionType: 'ALL'
          }

          await this.$store.dispatch('getWudasData', payload).then(res => {
            const apiResponse = res.result

            if (!Array.isArray(apiResponse)) {
              let type = ''

              switch (apiResponse.SVC_NAME) {
                case 'MONEY IN MINUTES':
                  type = 'MIM'
                  break
                case 'DIRECT TO BANK':
                  type = 'D2B'
                  break
                case 'MOBILE MONEY TRANSFER':
                  type = 'MMT'
                  break
              }

              const populateValue = [
                {
                  text: 'Select Delivery Service',
                  value: '',
                  tmplt: ''
                },
                {
                  text: apiResponse.SVC_NAME,
                  value: type,
                  tmplt: apiResponse.TEMPLT
                }
              ]

              this.$store.commit('send/SET_DELIVERY_SERVICE_TYPES', populateValue)

              const that = this

              setTimeout(function () {
                that.transactionData.deliveryService = populateValue[1]
              }, 1500)
            } else if (Array.isArray(apiResponse)) {
              const delServiceItem = []
              apiResponse.forEach((item, index) => {
                let type = ''

                switch (item.SVC_NAME) {
                  case 'MONEY IN MINUTES':
                    type = 'MIM'
                    break
                  case 'DIRECT TO BANK':
                    type = 'D2B'
                    break
                  case 'MOBILE MONEY TRANSFER':
                    type = 'MMT'
                    break
                }

                delServiceItem.push({
                  text: item.SVC_NAME,
                  value: type,
                  tmplt: item.TEMPLT
                })

                console.log(index === apiResponse.length - 1)

                if (index === apiResponse.length - 1) {
                  delServiceItem.unshift({
                    text: 'Select Delivery Service',
                    value: '',
                    tmplt: ''
                  })
                }
              })

              this.$store.commit('send/SET_DELIVERY_SERVICE_TYPES', delServiceItem)

              const that = this

              setTimeout(function () {
                console.log(delServiceItem[1])
                that.transactionData.deliveryService = delServiceItem[1]
              }, 1500)
            }
          })
        }

        this.$store.commit('send/SET_TRANSACTION_DATA', {
          prop: 'totalAmount',
          value: totalAmount
        })

        if (id === 9) this.cebuanaFindClient()
      }
    },
    async cebuanaFindClient () {
      const findClientRequest = await this.$store.dispatch('nonex/cebuanaFindClient', this.selectedCustomer)

      if (findClientRequest.message === 'Successful') {
        if (JSON.stringify(findClientRequest.result) === '{}') {
          const payload = {
            customer: this.selectedCustomer,
            currUser: this.currUser
          }

          const addClientRequest = await this.$store.dispatch('nonex/cebuanaAddClient', payload)

          if (addClientRequest.message === 'Successful') {
            this.$store.commit('send/SET_TRANSACTION_DATA', {
              prop: 'clientID',
              value: addClientRequest.result.ClientID
            })
          } else {
            // ERROR
          }
        } else {
          const resultSize = findClientRequest.result.length - 1

          if (resultSize > 0) {
            this.$store.commit('send/SET_TRANSACTION_DATA', {
              prop: 'clientID',
              value: findClientRequest.result.Client[resultSize].ClientID
            })
          } else {
            this.$store.commit('send/SET_TRANSACTION_DATA', {
              prop: 'clientID',
              value: findClientRequest.result.Client.ClientID
            })
          }
        }
      } else {
        // Handle error here
      }
    },
    toMoney
  },
  mounted () {
    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData?.remote_location?.location_id
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    }
  }
}
</script>
