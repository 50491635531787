import { endpoints } from '@/api/endpoints'

const users = endpoints.use('users')

export default {
  uploadUserSignature: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.uploadUserSignature(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  retrieveUserSignature: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.retrieveUserSignature(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  searchUser: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.searchUser(payload)
      .then(({ data }) => {
        commit('SET_SEARCH_RESULT', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  allocateLocation: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.allocateLocation(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  deleteUser: ({ commit }, userID) => new Promise((resolve, reject) => {
    users.deleteUser(userID)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getUserRoles: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.getUserRoles(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  searchUserRole: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.searchUserRole(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  addUserRole: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.addUserRole(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  updateUserRole: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.updateUserRole(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  deleteUserRole: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.deleteUserRole(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  showUserRoles: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.showUserRoles(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  showAllModules: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.showAllModules(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
