import { endpoints } from '@/api/endpoints'

const voucher = endpoints.use('voucher')

export default {
  printVoucher: ({ commit }, payload) => new Promise((resolve, reject) => {
    voucher.printVoucher(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
