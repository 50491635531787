<template>
  <div>
    <img src="@/assets/images/logo-horizontal-small.png" alt="" id="hello" style="display:none; height:50px; width: 50px;">
  </div>
</template>
<script>
import WacomSDK from '../../assets/js/wacom/wgssStuSdk.js'
import Q from '../../assets/js/wacom/q.js'
import WacomEncryption from '../../assets/js/wacom/demoButtons_encryption'
// import sjcl from '../../assets/js/wacom/sjcl.js';
import { mapGetters } from 'vuex'

export default {
  name: 'wacom',
  props: ['type', 'trxData', 'referenceSignatures'],
  data () {
    return {
      // hasWacom: "",
      m_btns: '',
      m_clickBtn: -1,
      intf: '',
      formDiv: '',
      protocol: '',
      m_usbDevices: '',
      m_capability: '',
      tablet: '',
      m_inkThreshold: '',
      m_imgData: '',
      m_encodingMode: '',
      ctx: '',
      canvas: '',
      modalBackground: '',
      container: '',
      m_penData: '',
      lastPoint: '',
      isDown: '',
      retry: 0,
      signatureImage: '',
      canvasEmpty: true
    }
  },
  created: function () {
    this.addPrototype()
  },
  computed: {
    ...mapGetters({
      wacomIdle: 'wacom/showWacomIdle',
      currUser: 'auth/currUser'
    })
  },
  watch: {
    wacomIdle: {
      handler (returnVal) {
        if (returnVal === true) {
          console.log('called at watch true')
          if (this.currUser.has_wacom) {
            this.tabletDemo()
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    checkForSigCaptX () {
      // Establishing a connection to SigCaptX Web Service can take a few seconds,
      // particularly if the browser itself is still loading/initialising
      // or on a slower machine.
      this.retry = this.retry + 1
      if (WacomSDK.STU.isServiceReady()) {
        this.retry = 0
        console.log('SigCaptX Web Service: ready')
      } else {
        console.log('SigCaptX Web Service: not connected')
        if (this.retry < 20) {
          setTimeout(this.checkForSigCaptX, 1000)
        } else {
          console.log('Unable to establish connection to SigCaptX')
        }
      }
    },

    onDCAtimeout () {
      // Device Control App has timed-out and shut down
      // For this sample, we just closedown tabletDemo (assumking it's running)
      console.log('DCA disconnected')
      // setTimeout(this.close, 0)
      WacomSDK.STU.Reinitialize()
    },

    Rectangle (x, y, width, height) {
      this.x = x
      this.y = y
      this.width = width
      this.height = height
      this.Contains = function (pt) {
        if (((pt.x >= this.x) && (pt.x <= (this.x + this.width))) &&
            ((pt.y >= this.y) && (pt.y <= (this.y + this.height)))) {
          return true
        } else {
          return false
        }
      }
    },

    Button () {
      this.Bounds // eslint-disable-line
      this.Text // eslint-disable-line
      this.Click // eslint-disable-line
    },

    Point (x, y) {
      this.x = x
      this.y = y
    },

    createModalWindow (width, height) {
      this.modalBackground = document.createElement('div')
      this.modalBackground.id = 'modal-background-idle'
      this.modalBackground.className = 'active'
      this.modalBackground.style.width = window.innerWidth
      this.modalBackground.style.height = window.innerHeight
      document.getElementsByTagName('body')[0].appendChild(this.modalBackground)

      this.container = document.createElement('div')
      this.container.id = 'signatureWindowContainerIdle'
      this.container.classList.add('vertical-center')
      this.container.style.display = 'flex'
      document.getElementsByTagName('body')[0].appendChild(this.container)

      this.formDiv = document.createElement('div')
      this.formDiv.id = 'signatureWindowIdle'
      this.formDiv.classList.add('active', 'signatureWindow')
      this.formDiv.style.width = width + 'px'
      this.formDiv.style.height = height + 'px'
      this.container.appendChild(this.formDiv)

      this.canvas = document.createElement('canvas')
      this.canvas.id = 'myCanvasIdle'
      this.canvas.height = this.formDiv.offsetHeight
      this.canvas.width = this.formDiv.offsetWidth
      this.formDiv.appendChild(this.canvas)
      this.ctx = this.canvas.getContext('2d')

      if (this.canvas.addEventListener) {
        this.canvas.addEventListener('click', this.onCanvasClick, false)
      } else if (this.canvas.attachEvent) {
        this.canvas.attachEvent('onClick', this.onCanvasClick)
      } else {
        this.canvas.onClick = this.onCanvasClick
      }
    },

    disconnect () {
      var deferred = Q.defer()
      const self = this
      if (!(self.tablet === undefined || self.tablet === null)) {
        var p = new WacomSDK.STU.Protocol()
        console.log(self.tablet)
        self.tablet.setInkingMode(p.InkingMode.InkingMode_Off)
          .then(function (message) {
            console.log('received: ' + JSON.stringify(message))
            return self.tablet.endCapture()
          })
          .then(function (message) {
            console.log('received: ' + JSON.stringify(message))
            if (self.m_imgData !== null) {
              return self.m_imgData.remove()
            } else {
              return message
            }
          })
          .then(function (message) {
            console.log('received: ' + JSON.stringify(message))
            self.m_imgData = null
            return true
          })
          .then(function (message) {
            console.log('received: ' + JSON.stringify(message))
            return self.tablet.disconnect()
          })
          .then(function (message) {
            console.log('received: ' + JSON.stringify(message))
            self.tablet = null
            // clear canvas
            // self.clearCanvas(self.canvas, self.ctx)
          })
          .then(function (message) {
            console.log(message)
            deferred.resolve()
          })
          .fail(function (message) {
            console.log('disconnect error: ' + message)
            deferred.resolve()
          })
      } else {
        deferred.resolve()
      }
      return deferred.promise
    },

    // Error-derived object for Device Control App not ready exception
    DCANotReady () { },

    tabletDemo () {
      const self = this
      var p = new WacomSDK.STU.Protocol()
      var intf
      var usingEncryption = false
      var encH
      var encH2
      var encH2Impl

      WacomSDK.STU.isDCAReady()
        .then(function (message) {
          console.log(message)
          if (!message) {
            throw new self.DCANotReady()
          }
          // Set handler for Device Control App timeout
          WacomSDK.STU.onDCAtimeout = self.onDCAtimeout
          return WacomSDK.STU.getUsbDevices()
        })
        .then(function (message) {
          if (message === null || message.length === 0) {
            // $('#btn_e_signature').attr('disabled', true);
            throw new Error('No STU devices found')
          }
          console.log('received: ' + JSON.stringify(message))
          self.m_usbDevices = message
          return WacomSDK.STU.isSupportedUsbDevice(self.m_usbDevices[0].idVendor, self.m_usbDevices[0].idProduct)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          intf = new WacomSDK.STU.UsbInterface()
          return intf.Constructor()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return intf.connect(self.m_usbDevices[0], true)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          console.log(message.value === 0 ? 'connected!' : 'not connected')
          if (message.value === 0) {
            encH = new WacomSDK.STU.EncryptionHandler(new WacomEncryption.EncryptionHandler())
            return encH.Constructor()
          }
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          encH2Impl = new WacomEncryption.EncryptionHandler2()
          encH2 = new WacomSDK.STU.EncryptionHandler2(encH2Impl)
          return encH2.Constructor()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          const tablet = new WacomSDK.STU.Tablet()
          // self.tablet = new WacomSDK.STU.Tablet()
          self.tablet = tablet
          console.log(self.tablet)
          return self.tablet.Constructor(intf, encH, encH2)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          intf = null
          return self.tablet.getInkThreshold()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          self.m_inkThreshold = message
          // console.log(self.tablet)
          return self.tablet.getCapability()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          self.m_capability = message
          self.createModalWindow(self.m_capability.screenWidth, self.m_capability.screenHeight)
          return self.tablet.getInformation()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.getInkThreshold()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.getProductId()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return WacomSDK.STU.ProtocolHelper.simulateEncodingFlag(message, self.m_capability.encodingFlag)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          var encodingFlag = message
          if ((encodingFlag & p.EncodingFlag.EncodingFlag_24bit) !== 0) {
            return self.tablet.supportsWrite()
              .then(function (message) {
                self.m_encodingMode = message ? p.EncodingMode.EncodingMode_24bit_Bulk : p.EncodingMode.EncodingMode_24bit
              })
          } else if ((encodingFlag & p.EncodingFlag.EncodingFlag_16bit) !== 0) {
            return this.tablet.supportsWrite()
              .then(function (message) {
                self.m_encodingMode = message ? p.EncodingMode.EncodingMode_16bit_Bulk : p.EncodingMode.EncodingMode_16bit
              })
          } else { // assumes 1bit is available
            self.m_encodingMode = p.EncodingMode.EncodingMode_1bit
          }
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.isSupported(p.ReportId.ReportId_EncryptionStatus) // v2 encryption
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          usingEncryption = message
          // if the encryption script is missing turn off encryption regardless
          if (typeof window.sjcl === 'undefined') {
            console.log('sjcl not found - encryption disabled')
            usingEncryption = true
          }
          return self.tablet.getDHprime()
        })
        .then(function (dhPrime) {
          console.log('received: ' + JSON.stringify(dhPrime))
          return WacomSDK.STU.ProtocolHelper.supportsEncryption_DHprime(dhPrime) // v1 encryption
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          usingEncryption = (message ? true : usingEncryption)
          return self.tablet.setClearScreen()
        })
        .then(function (message) {
          if (usingEncryption) {
            // return tablet.startCapture(0xc0ffee);
          } else {
            return message
          }
        })
        .then(function (message) {
          if (typeof encH2Impl.error !== 'undefined') {
            throw new Error('Encryption failed, restarting demo')
          }
          return message
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.isSupported(p.ReportId.ReportId_PenDataOptionMode)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          if (message) {
            return self.tablet.getProductId()
              .then(function (message) {
                // console.log(message);
                var penDataOptionMode = p.PenDataOptionMode.PenDataOptionMode_None
                switch (message) {
                  case WacomSDK.STU.ProductId.ProductId_520A:
                    penDataOptionMode = p.PenDataOptionMode.PenDataOptionMode_TimeCount
                    break
                  case WacomSDK.STU.ProductId.ProductId_430:
                  case WacomSDK.STU.ProductId.ProductId_530:
                    penDataOptionMode = p.PenDataOptionMode.PenDataOptionMode_TimeCountSequence
                    break
                  default:
                    console.log('Unknown tablet supporting PenDataOptionMode, setting to None.')
                }
                return self.tablet.setPenDataOptionMode(penDataOptionMode)
              })
          } else {
            this.m_encodingMode = p.EncodingMode.EncodingMode_1bit
            return this.m_encodingMode
          }
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          self.addButtons()
          var canvasImage = self.canvas.toDataURL('image/jpeg')
          // console.log(self.m_encodingMode, self.m_capability)
          return WacomSDK.STU.ProtocolHelper.resizeAndFlatten(canvasImage, 0, 0, 0, 0, self.m_capability.screenWidth, self.m_capability.screenHeight, self.m_encodingMode, 1, false, 0, true)
        })
        .then(function (message) {
          self.m_imgData = message
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.writeImage(self.m_encodingMode, message)
        }).then(function (message) {
          console.log(message)
          self.tablet.disconnect()
        })
        .fail(function (ex) {
          console.log(ex)
          // console.log('not hello');
          if (ex instanceof this.DCANotReady) {
            // console.log('hello');
            // Device Control App not detected
            // Reinitialize and re-try
            WacomSDK.STU.Reinitialize()
            setTimeout(this.tabletDemo, 1000)
          } else {
            // Some other error - Inform the user and closedown
            alert('tabletDemo failed:\n' + ex)
            setTimeout(this.close, 0)
          }
        })
    },

    addButtons () {
      this.clearCanvas(this.canvas, this.ctx)
      this.drawButtonsIdle()
    },

    drawButtonsIdle () {
      // This application uses the same bitmap for both the screen and client (window).
      console.log('buttons drawn')
      this.ctx.save()
      this.ctx.setTransform(1, 0, 0, 1, 0, 0)

      var img = document.getElementById('hello')
      this.ctx.drawImage(img, 70, 180, 650, 130)

      this.ctx.beginPath()
      this.canvas.style.display = 'none'
      this.container.style.display = 'none'
      this.modalBackground.style.display = 'none'
    },

    clearScreen () {
      console.log('cleared screen')
      this.clearCanvas(this.canvas, this.ctx)
      this.drawButtonsIdle()
      this.m_penData = []
      this.tablet.writeImage(this.m_encodingMode, this.m_imgData)
    },

    btnOk_Click () {
      var self = this
      console.log(self.canvasEmpty, 'canvasEmpty')
    },

    btnCancel_Click () {
      // You probably want to add additional processing here.
      window.removeEventListener('scroll', this.noScroll)
      setTimeout(this.close, 0)
    },

    btnClear_Click () {
      // You probably want to add additional processing here.
      // console.log("clear!");
      this.canvasEmpty = true
      this.clearScreen()
    },

    distance (a, b) {
      return Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)
    },

    clearCanvas (inCanvas, inCtx) {
      inCtx.save()
      inCtx.setTransform(1, 0, 0, 1, 0, 0)
      inCtx.fillStyle = 'white'
      inCtx.fillRect(0, 0, inCanvas.width, inCanvas.height)
      inCtx.restore()
    },

    processButtons (point, inCanvas) {
      // console.log('processButtons',in_canvas)
      // console.log('processButtons', point)
      // console.log(this.m_capability.tabletMaxX, 'tabletmaxx')
      var nextPoint = {}
      nextPoint.x = Math.round(inCanvas.width * point.x / this.m_capability.tabletMaxX)
      nextPoint.y = Math.round(inCanvas.height * point.y / this.m_capability.tabletMaxY)
      var isDown2 = (this.isDown ? !(point.pressure <= this.m_inkThreshold.offPressureMark) : (point.pressure > this.m_inkThreshold.onPressureMark))
      var btn = -1

      for (var i = 0; i < this.m_btns.length; ++i) {
        if (this.m_btns[i].Bounds.Contains(nextPoint)) {
          btn = i
          break
        }
      }
      // console.log(this.isDown, 'isDown');
      if (this.isDown && !isDown2) {
        if (btn !== -1 && this.m_clickBtn === btn) {
          this.m_btns[btn].Click()
        }
        this.m_clickBtn = -1
      } else if (btn !== -1 && !this.isDown && isDown2) {
        this.m_clickBtn = btn
      }
      return (btn === -1)
    },

    processPoint (point, inCanvas, inCtx) {
      // console.log('processPoint-point', point)
      // console.log('processPoint', inCanvas)
      // console.log('processPoint', inCtx)
      var nextPoint = {}
      nextPoint.x = Math.round(inCanvas.width * point.x / this.m_capability.tabletMaxX)
      nextPoint.y = Math.round(inCanvas.height * point.y / this.m_capability.tabletMaxY)
      // console.log(nextPoint.x, 'nextPoint.x');
      // console.log(nextPoint.y, 'nextPoint.y');
      var isDown2 = (this.isDown ? !(point.pressure <= this.m_inkThreshold.offPressureMark) : (point.pressure > this.m_inkThreshold.onPressureMark))
      // console.log(point.pressure);
      if (!this.isDown && isDown2) {
        this.lastPoint = nextPoint
      }

      if ((isDown2 && 10 < this.distance(this.lastPoint, nextPoint)) || (this.isDown && !isDown2)) { // eslint-disable-line
        // this.canvasEmpty = false;
        inCtx.beginPath()
        inCtx.imageSmoothingQuality = 'high'
        inCtx.moveTo(this.lastPoint.x, this.lastPoint.y)
        inCtx.lineTo(nextPoint.x, nextPoint.y)
        inCtx.stroke()
        inCtx.closePath()
        this.lastPoint = nextPoint
      }
      // console.log(isDown2, 'isdown2')
      this.isDown = isDown2
    },

    generateImage () {
      // var customerSignature;
      var signatureCanvas = document.createElement('canvas')
      signatureCanvas.id = 'signatureCanvas'
      var signatureCtx = signatureCanvas.getContext('2d')
      this.clearCanvas(signatureCanvas, signatureCtx)
      signatureCtx.lineWidth = 1
      signatureCtx.strokeStyle = 'black'
      this.lastPoint = { 'x': 0, 'y': 0 } // eslint-disable-line
      this.isDown = false

      for (var i = 0; i < this.m_penData.length; i++) {
        this.processPoint(this.m_penData[i], signatureCanvas, signatureCtx)
      }

      var newCanvas = document.createElement('canvas')
      newCanvas.id = 'newCanvas'
      document.body.appendChild(newCanvas)
      document.getElementById('newCanvas').style.display = 'none'
      // $('#newCanvas').hide();
      var imgToCon = new Image()
      imgToCon.src = signatureCanvas.toDataURL('image/png')
      imgToCon.id = 'imgToCon'
      document.body.appendChild(imgToCon)
    },

    addPrototype () {
      this.DCANotReady.prototype = new Error()
    },

    close () {
      // Clear handler for Device Control App timeout
      WacomSDK.STU.onDCAtimeout = null
      this.disconnect()
      document.getElementsByTagName('body')[0].removeChild(this.modalBackground)
      document.getElementById('signatureWindowContainerIdle').remove()
      //   document.getElementById('signatureWindowContainer').removeChild(formDiv);
      //   document.getElementById('signatureWindowContainer').removeChild(signatureImage);
    },

    onCanvasClick (event) {
      // Enable the mouse to click on the simulated buttons that we have displayed.

      // Note that this can add some tricky logic into processing pen data
      // if the pen was down at the time of this click, especially if the pen was logically
      // also 'pressing' a button! This demo however ignores any that.

      var posX = event.pageX - this.formDiv.offsetLeft
      var posY = event.pageY - this.formDiv.offsetTop

      for (var i = 0; i < this.m_btns.length; i++) {
        if (this.m_btns[i].Bounds.Contains(new this.Point(posX, posY))) {
          this.m_btns[i].Click()
          break
        }
      }
    }
  }
}
</script>

<style>
.button-wacom {
  position:absolute;
}

#signatureBox {
  display: none;
}
#modal-background-idle {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

#signatureWindowIdle {
  display: none;
}

#modal-background-idle.active, #signatureWindowIdle.active {
  display: block;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#myCanvasIdle {
  width:100%;
  height:100%;
}

#signatureDiv {
  float:left;
  margin-right:15px;
}

#signatureImage {
  width:300px;
  height:200px;
  display:none;
}

#wgssSTU {
  width:0;
  height:0;
}

.thirdPartyLicenses {
  display:none;
  font-size:8pt;
}.button {
  position:absolute;
}

#signatureBox {
  display: none;
}

.signatureWindow {
  display: none;
  z-index: 1500000 !important;
}

.vertical-center{
  margin: auto;
  padding-top: 40px;
  position: fixed;
  justify-content: center;
  top: 0; left: 0; bottom: 0; right: 0;
}

#modal-background.active, #signatureWindow.active {
  display: block;
}

#myCanvas {
  width:100%;
  height:100%;
}
/*
#modal-background.active, #myCanvas.active {
    display: block;
}*/
/*
#myCanvas canvas {
  width:100%;
  height:100%;
}*/

#signatureDiv {
  float:left;
  margin-right:15px;
}

#signatureImage {
  width:300px;
  height:200px;
  display:block;
}

#wgssSTU {
  width:0;
  height:0;
}

.thirdPartyLicenses {
  display:none;
  font-size:8pt;
}
</style>
