import Vue from 'vue'
import { endpoints } from '@/api/endpoints'
// import { format } from 'date-fns'

const uspBaseDomain = '.perahub.com.ph'

const auth = endpoints.use('auth')

const doLoginWithGoogle = (context) => new Promise((resolve, reject) => {
  // context.commit('AUTH_REQUEST');
  const newWindow = openWindow('', 'message')

  setTimeout(() => {
    auth.googleLogin()
      .then((response) => {
        const urlAccountSelection = response.data + '&prompt=select_account'

        newWindow.location.href = urlAccountSelection
        context.commit('AUTH_SUCCESS', false)
        resolve(response)
      })
      .catch((err) => {
        reject(err.response)
      })
  }, 2000)
})

const doLoginWithOkta = (context) => new Promise((resolve, reject) => {
  // context.commit('AUTH_REQUEST');
  const newWindow = openWindow('', 'message')

  setTimeout(() => {
    auth.oktaLogin()
      .then((response) => {
        const urlAccountSelection = response.data + '&prompt=select_account'

        newWindow.location.href = urlAccountSelection
        context.commit('AUTH_SUCCESS', false)
        resolve(response)
      })
      .catch((err) => {
        reject(err.response)
      })
  }, 2000)
})

const doLoginWithUserPW = (context, payload) => new Promise((resolve, reject) => {
  // context.commit('AUTH_REQUEST');
  setTimeout(() => {
    auth.userPWLogin(payload)
      .then(response => {
        if (response.status !== 422) {
          context.commit('AUTH_SUCCESS', true)
          context.commit('SET_RUN_SESSION', true)

          const currentTime = new Date()
          const expireTime = new Date(currentTime.getTime() + (23 * 60 * 1000))

          context.commit('SET_AUTH_TIMESTAMP', {
            timeLoggedIn: currentTime,
            timeToExpire: expireTime
          })

          context.commit('SET_CURRENT_USER', response.data.result)
        }

        resolve(response.data)
      }).catch(err => {
        reject(err.response)
      })
  }, 2000)
})

const doLogout = (context, payload) => new Promise((resolve, reject) => {
  const ipAddress = payload.ip_address
  const userId = payload.username

  auth.userLogout(userId, ipAddress)
    .then(response => {
      Vue.$cookies.remove('USP_DATA', '/', uspBaseDomain)
      Vue.$cookies.remove('USP_TOKEN', '/', uspBaseDomain)
      Vue.$cookies.remove('USP_MODULES', '/', uspBaseDomain)
      Vue.$cookies.remove('USP_AUTH_TIMESTAMP', '/', uspBaseDomain)

      context.commit('AUTH_SUCCESS', false)
      context.commit('SET_CURRENT_USER', {})
      context.commit('SET_RUN_SESSION', false)
      context.commit('SET_IS_OTP_PASSED', false)
      context.commit('SET_AUTH_TIMESTAMP', {
        timeLoggedIn: '',
        timeToExpire: ''
      })
      context.commit('SET_SHOW_REFRESH', false)
      resolve(response.data)
    }).catch(err => {
      reject(err.response)
    })
})

const doRequestPasswordReset = (context, payload) => new Promise((resolve, reject) => {
  setTimeout(() => {
    auth.requestPasswordReset(payload)
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err.response)
      })
  }, 2000)
})

const doUpdatePassword = (context, payload) => new Promise((resolve, reject) => {
  setTimeout(() => {
    auth.updatePassword(payload)
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err.response)
      })
  }, 2000)
})

const verifyAuth = (context) => new Promise((resolve, reject) => {
  setTimeout(() => {
    auth.verifyAuth().then((response) => {
      context.commit('SET_CURRENT_USER', response.data)
      context.commit('AUTH_SUCCESS', true)
      resolve(response.data)
    }).catch((err) => {
      reject(err.response)
    })
  }, 2000)
})

const verifyOTP = (context, payload) => new Promise((resolve, reject) => {
  setTimeout(() => {
    auth.verifyOTP(payload).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  }, 2000)
})

const closeBranch = (context, { locationID, trxDate, userID }) => new Promise((resolve, reject) => {
  auth.closeBranch(locationID, trxDate, userID).then(({ data }) => {
    resolve(data)
  }).catch((err) => {
    reject(err.response)
  })
})

function openWindow (url, title, options = {}) {
  if (typeof url === 'object') {
    options = url
    url = ''
  }

  options = { url, title, width: 600, height: 720, ...options }

  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
  const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height

  options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
  options.top = ((height / 2) - (options.height / 2)) + dualScreenTop

  const optionsStr = Object.keys(options).reduce((acc, key) => {
    acc.push(`${key}=${options[key]}`)
    return acc
  }, []).join(',')

  const newWindow = window.open(url, title, optionsStr)

  if (window.focus) {
    newWindow.focus()
  }

  return newWindow
}

const doCheckToken = (context, payload) => new Promise((resolve, reject) => {
  setTimeout(() => {
    auth.checkToken(payload)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err.response)
      })
  }, 2000)
})

const doRefreshToken = (context, payload) => new Promise((resolve, reject) => {
  setTimeout(() => {
    auth.refreshToken(payload)
      .then((response) => {
        context.commit('SET_RUN_SESSION', true)

        const currentTime = new Date()
        const expireTime = new Date(currentTime.getTime() + (23 * 60 * 1000))

        context.commit('SET_AUTH_TIMESTAMP', {
          timeLoggedIn: currentTime,
          timeToExpire: expireTime
        })

        context.commit('SET_SHOW_REFRESH', false)
        resolve(response.data)
      })
      .catch((err) => {
        reject(err.response)
      })
  }, 2000)
})

const resetAuthentication = ({ commit }) => {
  commit('AUTH_SUCCESS', false)
  commit('SET_RUN_SESSION', false)
  commit('SET_IS_OTP_PASSED', false)
  commit('SET_CURRENT_USER', {})
  commit('SET_AUTH_TIMESTAMP', {
    timeLoggedIn: '',
    timeToExpire: ''
  })
  commit('SET_SHOW_REFRESH', false)
}

export default {
  doLoginWithGoogle,
  doLoginWithOkta,
  doLoginWithUserPW,
  doRequestPasswordReset,
  doUpdatePassword,
  doCheckToken,
  doRefreshToken,
  verifyAuth,
  verifyOTP,
  doLogout,
  closeBranch,
  resetAuthentication
}
