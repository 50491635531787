import { endpoints } from '@/api/endpoints'

const collections = endpoints.use('collections')
const receipts = endpoints.use('receipts')
const avp = endpoints.use('avp')

export default {
  getSourceOfFund: (context, payload) => new Promise((resolve) => {
    collections.sourceOfFund(payload)
      .then(({ data }) => {
        context.state.collections.sourceOfFund = data.result
        resolve(data)
      })
  }),

  getPurposeOfTransaction: (context, payload) => new Promise((resolve) => {
    collections.purposeOfTransaction(payload)
      .then(({ data }) => {
        context.state.collections.purposeOfTransaction = data.result
        resolve(data)
      })
  }),

  getOccupation: (context, payload) => new Promise((resolve) => {
    collections.occupation(payload)
      .then(({ data }) => {
        context.state.collections.occupation = data.result
        resolve(data)
      })
  }),

  getRelationship: (context, payload) => new Promise((resolve) => {
    collections.relationship()
      .then(({ data }) => {
        context.state.collections.relationship = data.result
        resolve(data)
      })
  }),

  getRelationshipDppv2: (context, payload) => new Promise((resolve) => {
    collections.relationshipdppv2()
      .then(({ data }) => {
        context.state.collections.relationshipdppv2 = data.result
        resolve(data)
      })
  }),

  getRelationshipRuralNet: (context, payload) => new Promise((resolve) => {
    collections.relationshipRuralNet()
      .then(({ data }) => {
        context.state.collections.relationshipRuralNet = data.result
        resolve(data)
      })
  }),

  getIDList: (context, payload) => new Promise((resolve) => {
    collections.idList(payload)
      .then(({ data }) => {
        if (payload === 'wu') {
          context.state.collections.wuIDList = data.result
        } else {
          context.state.collections.idList = data.result
        }
        resolve(data)
      })
  }),

  getCountries: (context, payload) => new Promise((resolve) => {
    collections.countries()
      .then(({ data }) => {
        context.state.collections.countries = data.result
        resolve(data)
      })
  }),

  getWudasData: (context, payload) => new Promise((resolve) => {
    collections.wudascountries(payload)
      .then(({ data }) => {
        context.state.collections.wudasCountries = data.result
        resolve(data)
      })
  }),

  getD2BCorridors: (context, payload) => new Promise((resolve) => {
    collections.d2bcorridors()
      .then(({ data }) => {
        context.state.collections.d2bCorridors = data.result
        resolve(data)
      })
  }),

  getNatureOfWork: (context, payload) => new Promise((resolve) => {
    collections.natureOfWork()
      .then(({ data }) => {
        context.state.collections.natureOfWork = data.result
        resolve(data)
      })
  }),

  getPositionLevel: (context, payload) => new Promise((resolve) => {
    collections.positionLevel()
      .then(({ data }) => {
        context.state.collections.positionLevel = data.result
        resolve(data)
      })
  }),

  getEmployment: (context, payload) => new Promise((resolve) => {
    collections.employment()
      .then(({ data }) => {
        context.state.collections.employment = data.result
        resolve(data)
      })
  }),

  getCities: (context, payload) => new Promise((resolve) => {
    collections.cities()
      .then(({ data }) => {
        context.state.collections.cities = data.result
        resolve(data)
      })
  }),

  getBarangayList: (context, city) => new Promise((resolve) => {
    collections.barangay(city)
      .then(({ data }) => {
        resolve(data)
      })
  }),

  getReceipt: ({ commit }, payload) => new Promise((resolve) => {
    receipts.getReceipt(payload)
      .then(({ data }) => {
        commit('SET_RECEIPT', data.result)
        resolve(data)
      })
  }),

  saveReceipt: (context, payload) => new Promise((resolve) => {
    receipts.saveReceipt(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),

  getFormNumber: (context, { locationID, type }) => new Promise((resolve) => {
    receipts.formNumber(locationID, type)
      .then(({ data }) => {
        if (data.code === 200) {
          const formNumber = data.result?.series_no

          context.commit('SET_OAR', data.result)

          resolve(formNumber)
        }
        resolve('')
      })
  }),

  cancelFormNumber: (context, payload) => new Promise((resolve) => {
    receipts.cancelFormNumber(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),

  printReceipt: (context, { locationID, trxDate, refNo, trxType, type }) => new Promise((resolve) => {
    receipts.print(locationID, trxDate, refNo, trxType, type)
      .then(({ data }) => {
        resolve(data)
      })
  }),

  getD2BCountries: (context, payload) => new Promise((resolve) => {
    collections.d2bcountries()
      .then(({ data }) => {
        context.state.collections.d2bcountries = data.result
        resolve(data)
      })
  }),

  getAllLocations: ({ commit }, locationType) => new Promise((resolve) => {
    collections.locations(locationType)
      .then(({ data }) => {
        commit('SET_COLLECTIONS', {
          prop: 'locations',
          value: data.result
        })
        resolve(data)
      })
  }),

  getAllModules: ({ commit }, userType) => new Promise((resolve) => {
    collections.modules(userType)
      .then(({ data }) => {
        commit('SET_COLLECTIONS', {
          prop: 'modules',
          value: data.result
        })
        resolve(data)
      })
  }),

  getQPCompanies: (context, payload) => new Promise((resolve) => {
    collections.qpCompanies()
      .then(({ data }) => {
        context.state.collections.qpCompanies = data.result
        resolve(data)
      })
  }),

  getQPStateCities: (context, payload) => new Promise((resolve) => {
    collections.qpStateCities(payload)
      .then(({ data }) => {
        context.state.collections.qpStateCities = data.result
        resolve(data)
      })
  }),

  getStateAndCities: (context, countryCode) => new Promise((resolve) => {
    collections.stateAndCities(countryCode)
      .then(({ data }) => {
        context.state.collections.stateAndCities = data.result
        resolve(data)
      })
  }),

  getQRCode: (context, locationID) => new Promise((resolve) => {
    collections.qrCode(locationID)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  getCrossSellingSpiel: (context, payload) => new Promise((resolve) => {
    avp.getCrossSellingSpiel(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),

  getProviders: ({ commit }, locationType) => new Promise((resolve) => {
    collections.providers(locationType)
      .then(({ data }) => {
        commit('SET_COLLECTIONS', {
          prop: 'providers',
          value: data.result
        })
        resolve(data)
      })
  })
}
