import axios from 'axios'
import store from '@/store'
// import CryptoJS from 'crypto-js'
import { env } from '@/api/config'

// const susi = 'wlK7ZRNOBimj74oV9bqowfVPSqq+QD6t1YRwVWQMsHA='

const http = axios.create({
  // baseURL: CryptoJS.AES.decrypt(env.get('uspMaintenanceURL'), susi).toString(CryptoJS.enc.Utf8),
  baseURL: env.get('treasuryURL'),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Treasury-Token': env.get('treasuryToken')
  }
})

http.interceptors.request.use((config) => {
  store.commit('app/TOGGLE_LOADING', true)
  return config
})

http.interceptors.response.use(
  response => {
    store.commit('app/TOGGLE_LOADING', false)
    return response
  },
  err => {
    store.commit('app/TOGGLE_LOADING', false)

    if (Object.prototype.hasOwnProperty.call(err, 'response')) {
      return err.response
    }
    return Promise.reject(err)
  }
)

export default http
