<template>
  <v-row class="ph-wu-receive-transfer-info">
    <v-col cols="12">
      <div class="ph-transfer-details-container payout-details-container">
        <div class="ph-transfer-detail-title">
          <b>Payout Details</b>
        </div>
        <v-simple-table class="transfer-info-table">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in payoutDetails"
                :key="item.name"
              >
                <td class="transfer-info-title">{{ item.name }}</td>
                <td class="transfer-info-value">{{ item.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br>
      </div>
      <div class="ph-transfer-details-container sender-details-container">
        <div class="ph-transfer-detail-title">
          <b>Sender Details</b>
        </div>
        <v-simple-table class="transfer-info-table">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in senderDetails"
                :key="item.name"
              >
                <td class="transfer-info-title">{{ item.name }}</td>
                <td class="transfer-info-value">{{ item.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br>
      </div>
      <div class="ph-transfer-details-container originating-agent-container">
        <div class="ph-transfer-detail-title">
          <b>Originating Agent Location</b>
        </div>
        <v-simple-table class="transfer-info-table">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in OriginatingAgentInfo"
                :key="item.name"
              >
                <td class="transfer-info-title">{{ item.name }}</td>
                <td class="transfer-info-value">{{ item.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br>
      </div>
      <div class="ph-transfer-details-container receiver-container">
        <div class="ph-transfer-detail-title">
          <b>Receiver</b>
        </div>
        <v-simple-table class="transfer-info-table">
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="transfer-info-title">Name</td>
                <td class="transfer-info-value">{{receiverName}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { convertToRealAmount, formatTime, formatMTCN, formatWUName } from '@/utils'
import numeral from 'numeral'
import { mapGetters } from 'vuex'

export default {
  name: 'ReceiveMoneyFulfillTransferInfo',
  props: {
    selectedTrxData: Object
  },
  data () {
    return {
      payoutDetails: [
        { name: 'MTCN', value: '-' },
        { name: 'Amount Sent', value: '-' },
        { name: 'Amount to Payment', value: '-' }
      ],
      senderDetails: [
        { name: 'Name', value: '-' },
        { name: 'Originating Country', value: '-' }
      ],
      OriginatingAgentInfo: [
        { name: 'City / Locale', value: '-' },
        { name: 'Sent / Date and Time', value: '-' },
        { name: 'Country', value: '-' }
      ],
      receiverName: ''
    }
  },
  computed: {
    ...mapGetters({
      collections: 'collections'
    })
  },
  watch: {
    selectedTrxData: {
      handler (returnVal) {
        this.populateTransferInfo(returnVal)
      },
      deep: true
    }
  },
  methods: {
    convertToRealAmount,
    formatTime,
    formatMTCN,
    formatWUName,
    populateTransferInfo (data) {
      console.log(data)

      const orignatingCountryCurency = data.payment_details.originating_country_currency
      const destinationCountryCurency = data.payment_details.destination_country_currency
      this.receiverName = this.formatWUName(data.receiver.name)

      let originatingCountry = ''
      let destinationCountry = ''

      this.collections.countries.filter(data => {
        if (data.two_iso_code === orignatingCountryCurency.iso_code.country_code) {
          originatingCountry = data.country
        }

        if (data.two_iso_code === destinationCountryCurency.iso_code.country_code) {
          destinationCountry = data.country
        }
      })

      this.payoutDetails = [
        { name: 'MTCN', value: this.formatMTCN(data.mtcn) },
        { name: 'Amount Sent', value: orignatingCountryCurency.iso_code.currency_code + ' ' + numeral(this.convertToRealAmount(data.financials.principal_amount)).format('0, 0.00') },
        { name: 'Amount to Payment', value: destinationCountryCurency.iso_code.currency_code + ' ' + numeral(this.convertToRealAmount(data.financials.pay_amount)).format('0, 0.00') }
      ]

      this.senderDetails = [
        { name: 'Name', value: this.formatWUName(data.sender.name) },
        { name: 'Originating Country', value: originatingCountry }
      ]

      this.OriginatingAgentInfo = [
        { name: 'City / Locale', value: 'Metro Manila' },
        { name: 'Country', value: destinationCountry }
      ]
    }
  }
}
</script>
<style scoped>
  .ph-wu-form-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-transfer-details-container {
    margin-bottom: 15px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }
</style>
