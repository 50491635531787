<template>
  <v-dialog
    v-model="showResult"
    max-width="1000"
  >
    <v-card>
      <v-card-title class="ph-wu-search-result-header text-h5 text-primary">
        <h5 class="ph-wu-search-text">PERA Hub Customer Results ({{searchData.results ? searchData.results.length : 0}})</h5>
      </v-card-title>

      <v-card-text v-if="searchData.results ? (searchData.results.length > 0 ? true : false) : false">
        <v-data-table
          class="ph-clickable-table"
          :headers="listViewHeaders"
          :items="searchData.results"
          @click:row="doSelect"
          no-data-text="No customers found"
        >
          <template v-slot:[`item.name`]="{ item }">
            <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            {{ item.gender ? toProperCase(item.gender) : '-' }}
          </template>
          <template v-slot:[`item.wu_card_no`]="{ item }">
            {{ item.wu_card_no || '-' }}
          </template>
          <template v-slot:[`item.LastTransaction`]="{ item }">
            {{ item.LastTransaction || '-' }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text v-if="searchData.results ? (searchData.results.length < 1 ? true : false) : true">
        <h3>Looks like the customer you are looking for doesn't exist.<br> You need to create a new customer record for this transaction or retry searching for that customer.</h3>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="danger"
          text
          @click="showResult = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          theme="dark"
          v-if="searchData.results ? (searchData.results.length < 1 ? true : false) : true"
          :to="{ path: '/customers/new', query: { persistData: true, redirectURL: this.$route.path } }"
          @click="showResult = false"
        >
          Create New Customer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'ReceiveMoneyFulfillSearchResult',
  data () {
    return {
      listViewHeaders: [
        {
          text: 'Customer Number',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'WU No.',
          sortable: true,
          value: 'wu_card_no'
        },
        {
          text: 'ID Indicator',
          sortable: true,
          value: '-'
        },
        {
          text: 'Last Transaction',
          sortable: true,
          value: 'LastTransaction'
        }
      ],
      terminalId: '',
      operatorId: '',
      locationId: '',
      showResult: false,
      isNotCustomerPage: true,
      custSearchResult: ''
    }
  },
  computed: {
    ...mapGetters({
      searchData: 'customers/searchData',
      collections: 'collections',
      currUser: 'auth/currUser',
      currentReceiveFulfillTrx: 'wu/currentReceiveFulfillTrx',
      payoutReleaseForeignRefNo: 'wu/payoutReleaseForeignRefNo',
      remoteData: 'obo/remoteData'
    })
  },
  watch: {
    searchData: {
      handler (getSearch) {
        this.custSearchResult = getSearch.results

        if (this.custSearchResult !== null) {
          this.showResult = true
        } else {
          this.showResult = false
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData.remote_location.location_id
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    async doSelect (customerDetails) {
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)
      this.renderToast('info', 'Customer Selected!', `${customerDetails.full_name} has been successfully selected.`)
      this.showResult = false

      const idList = customerDetails.img ? JSON.parse(customerDetails.img) : []
      let currentId = idList['1']
      currentId = idList['1'] ? idList['1'] : {}

      const getId = this.collections.wuIDList.filter(item => {
        return item.id_type === currentId.id_type
      })

      const multipleKYCLookupPayload = {
        foreign_reference_no: this.payoutReleaseForeignRefNo,
        search_by: 'by_id',
        id_type: getId[0].id_value ? getId[0].id_value : '',
        id_number: currentId.id_number ? currentId.id_number : '',
        contact_phone: customerDetails.mobile_no.substring(1),
        mywu_number: (customerDetails.wu_card_no !== '0' || customerDetails.wu_card_no !== null) ? customerDetails.wu_card_no : '',
        first_name: this.currentReceiveFulfillTrx.receiver.name.first_name,
        last_name: this.currentReceiveFulfillTrx.receiver.name.last_name,
        is_multi: 'M',
        terminal_id: this.terminalId,
        operator_id: this.operatorId,
        location_id: this.locationId
      }

      this.$store.commit('wu/SET_SHOW_PAYOUT_FULFILL_SEARCH_FORM', false)
      this.$store.commit('wu/SET_SHOW_PAYOUT_FULFILL_RESULT_FORM', true)

      const that = this
      setTimeout(function () {
        that.$store.commit('app/TOGGLE_LOADING', true)
      }, 500)

      await this.$store.dispatch('wu/kycLookup', multipleKYCLookupPayload).then(res => {
        this.$store.commit('app/TOGGLE_LOADING', false)
        if (res.result) {
          console.log('pumasok')
          this.$store.commit('wu/SET_WU_KYC_MULTIPLE_SEARCH_DATA', res.result.customer)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style>
  .v-card__title.text-h5.ph-wu-search-result-header .ph-wu-search-text {
    font-family: 'Baloo Extra Bold', sans-serif !important;
    font-size: 24px;
    color: #1A2791;
  }
</style>
