export default {
  showWacom: false,
  showWacomIdle: false,
  showWacomConsent: false,
  showWacomDataConsentFirst: false,
  showWacomDataConsentSecond: false,
  showWacomDataConsentThird: false,
  showWacomDataConsentFourth: false,
  showWacomDataConsentFifth: false,
  showWacomDataConsentSixth: false,
  showWacomDataConsentSeventh: false,
  createDataConsent: false,
  consentValues: {
    first_clause: false,
    second_clause: false,
    third_clause: false,
    fourth_clause: false,
    fifth_clause: false
  }
}
