<template>
  <v-form ref="form">
    <v-row class="px-8 py-8" no-gutters>
      <v-col class="pb-1 pb-sm-2" :cols="12">
        <span class="text-caption">
          Please complete the necessary fields*.
        </span>
        <h3 class="mt-4 mt-sm-6">Current Address</h3>
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.country"
          :items="collections.countries"
          item-text="country"
          item-value="country"
          :return-object="false"
          label="Current Country *"
          :rules="requiredField"
          color="gray darken-1"
          class="ph-textbox"
          autofocus
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.municipality"
          :items="collections.cities"
          item-text="city"
          item-value="city"
          :return-object="false"
          label="Current Municipality *"
          :rules="requiredField"
          color="gray darken-1"
          @change="onCurrentCityChange"
          class="ph-textbox"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.barangay"
          :items="barangay"
          item-text="barangay"
          item-value="barangay"
          label="Current Barangay *"
          :rules="requiredField"
          color="gray darken-1"
          @change="setCurrentZIPCode"
          class="ph-textbox"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.address"
          class="ph-textbox"
          color="gray darken-1"
          label="Current Address *"
          :rules="requiredField"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.province"
          class="ph-textbox"
          color="gray darken-1"
          label="Current Province *"
          :rules="requiredField"
          disabled
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.zip_code"
          class="ph-textbox"
          color="gray darken-1"
          label="Current ZIP Code *"
          :rules="requiredField"
          disabled
          outlined
        />
      </v-col>
      <v-col class="pb-1 pb-sm-2" :cols="9">
        <h3 class="mt-4 mt-sm-6">Permanent Address</h3>
      </v-col>
      <v-col class="pb-1 pb-sm-2" :cols="3">
        <v-checkbox
          class="my-0 py-0 mt-4 mt-sm-6"
          :ripple="false"
          label="Same with current address"
          hide-details
          on-icon="mdi-check-circle"
          off-icon="mdi-checkbox-blank-circle-outline"
          @change="syncAddresses"
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.country1"
          :items="collections.countries"
          item-text="country"
          item-value="country"
          :return-object="false"
          label="Permanent Country *"
          :rules="requiredField"
          color="gray darken-1"
          class="ph-textbox"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.city1"
          :items="collections.cities"
          item-text="city"
          item-value="city"
          :return-object="false"
          label="Permanent Municipality *"
          :rules="requiredField"
          color="gray darken-1"
          @change="onPermanentCityChange"
          class="ph-textbox"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.barangay1"
          :items="barangay1"
          item-text="barangay"
          item-value="barangay"
          label="Permanent Barangay *"
          :rules="requiredField"
          color="gray darken-1"
          @change="setPermanentZIPCode"
          class="ph-textbox"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.street1"
          class="ph-textbox"
          color="gray darken-1"
          label="Permanent Address *"
          :rules="requiredField"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.province1"
          class="ph-textbox"
          color="gray darken-1"
          label="Permanent Province *"
          :rules="requiredField"
          disabled
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.zip_code1"
          class="ph-textbox"
          color="gray darken-1"
          label="Permanent ZIP Code *"
          :rules="requiredField"
          disabled
          hide-details
          outlined
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      formData: 'customers/formData',
      collections: 'collections'
    })
  },
  data () {
    return {
      barangay: [],
      barangay1: [],
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  methods: {
    syncAddresses (val) {
      if (val) {
        this.formData.country1 = this.formData.country
        this.formData.city1 = this.formData.municipality
        this.formData.barangay1 = this.formData.barangay
        this.formData.street1 = this.formData.address
        this.formData.province1 = this.formData.province
        this.formData.zip_code1 = this.formData.zip_code

        if (this.formData.city1) this.onPermanentCityChange(this.formData.city1)
      } else {
        this.formData.country1 = 'Philippines'
        this.formData.city1 = ''
        this.formData.barangay1 = ''
        this.formData.street1 = ''
        this.formData.province1 = ''
        this.formData.zip_code1 = ''
      }
    },
    async onCurrentCityChange (city) {
      console.log('selected city:' + city)
      const selectedCity = this.collections.cities.filter(item => item.city === city)

      if (selectedCity.length === 1) this.formData.province = selectedCity[0]?.province

      const barangayList = await this.$store.dispatch('getBarangayList', city)

      if (barangayList.code === 200) this.barangay = barangayList.result
    },
    async onPermanentCityChange (city) {
      const selectedCity = this.collections.cities.filter(item => item.city === city)

      if (selectedCity.length === 1) this.formData.province1 = selectedCity[0]?.province

      const barangayList = await this.$store.dispatch('getBarangayList', city)

      if (barangayList.code === 200) this.barangay1 = barangayList.result
    },
    setCurrentZIPCode (barangay) {
      const selectedBarangay = this.barangay.filter(item => item.barangay === barangay)

      if (selectedBarangay.length === 1) {
        this.formData.zip_code = selectedBarangay[0]?.zipcode
      }
    },
    setPermanentZIPCode (barangay) {
      const selectedBarangay = this.barangay1.filter(item => item.barangay === barangay)

      if (selectedBarangay.length === 1) {
        this.formData.zip_code1 = selectedBarangay[0]?.zipcode
      }
    }
  },
  async beforeMount () {
    console.log(this.formData.municipality)

    if (this.$route.query.isMobileUser) {
      const that = this
      setTimeout(function () {
        if (that.formData.municipality) that.onCurrentCityChange(that.formData.municipality)
        if (that.formData.city1) that.onPermanentCityChange(that.formData.city1)
      }, 2500)
    } else {
      const that = this
      setTimeout(function () {
        if (that.formData.municipality) that.onCurrentCityChange(that.formData.municipality)
        if (that.formData.city1) that.onPermanentCityChange(that.formData.city1)
      }, 1000)
    }
  }
}
</script>
