<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="3">
        <v-form ref="form">
          <v-row>
            <v-col class="mb-md-2" :cols="12">
              <h6 class="text-h6 custom-heading primary--text">
                Increase Location Limit Request
              </h6>
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.branchID"
                :items="collections.locations"
                item-text="location_name"
                item-value="location_id"
                :rules="requiredRules"
                class="ph-textbox"
                label="Branch"
                outlined
                disabled
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.provider"
                :items="collections.providers"
                item-text="provider_name"
                item-value="id"
                :rules="requiredRules"
                class="ph-textbox"
                label="Provider"
                return-object
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.provider.service_name"
                class="ph-textbox"
                label="Service Name"
                :rules="requiredRules"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.provider.service_type"
                class="ph-textbox"
                label="Service Type"
                :rules="requiredRules"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.amount"
                class="ph-textbox"
                label="Amount to Request"
                :rules="requiredRules"
                type="number"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col class="mb-md-2" :cols="12">
              <v-textarea
                v-model="formData.remarks"
                @input="convertToProperCase('remarks')"
                :rules="requiredRules"
                class="ph-textbox"
                label="Remarks"
                outlined
              />
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="save" class="font-weight-bold" color="primary" block x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast, toProperCase, trim } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      formData: 'transactionLimit/increaseLocationFormData',
      currUser: 'auth/currUser',
      collections: 'collections'
    })
  },
  data () {
    return {
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Increase Requests',
          disabled: false,
          exact: true,
          to: '/transaction-limit/increase-requests'
        },
        {
          text: 'Create Location Limit',
          disabled: true
        }
      ]
    }
  },
  methods: {
    async save () {
      if (this.$refs.form.validate()) {
        const payload = {
          location_id: this.formData.branchID,
          provider_name: this.formData.provider.provider_name,
          provider_code: this.formData.provider.provider_code,
          service_name: this.formData.provider.service_name,
          service_type: this.formData.provider.service_type,
          max_increase_amount: this.formData.amount,
          increase_amount: this.formData.amount,
          channel: (this.currUser.location_type === '1') ? 'COW' : 'PRP',
          remarks: this.formData.remarks,
          requestor: this.currUser.user_id
        }

        const { code } = await this.$store.dispatch('transactionLimit/saveIncreaseLocationLimit', payload)

        if (code === 200) {
          this.$store.dispatch('transactionLimit/resetIncreaseLocationForm')
          renderToast('success', 'Successful', 'Increase Location Limit has been added')
          this.$router.push('/transaction-limit/increase-requests')
        } else {
          renderToast('error', 'Failed', 'Failed to create increase location limit')
        }
      }
    },
    cancel () {
      this.$router.push('/transaction-limit/increase-requests')
    },
    convertToProperCase (field) {
      this.formData[field] = toProperCase(trim(this.formData[field]))
    }
  },
  async beforeMount () {
    this.$store.dispatch('transactionLimit/resetIncreaseLocationForm')
    await this.$store.dispatch('getAllLocations', 0)
    await this.$store.dispatch('getProviders')

    this.formData.branchID = this.currUser.location_id
  }
}
</script>

<style scoped>
.custom-heading {
  font-family: 'Baloo Extra Bold' !important;
}
</style>
