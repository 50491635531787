<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        class="ph-breadcrumbs"
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ph-transaction-container">
        <v-col cols="12" v-if="currentPage === 0">
          <LoanCollectionSearch />
        </v-col>
        <v-col cols="12" v-if="currentPage === 1">
          <LoanCollectionForm />
        </v-col>
        <v-col cols="12" v-if="currentPage === 2">
          <LoanCollectionSuccess />
        </v-col>
      </v-row>
      <CreateCustomerModal />
    </v-container>
 </div>
</template>
<script>
import { mapGetters } from 'vuex'
import LoanCollectionSearch from '@/components/loanCollections/LoanCollectionSearch'
import LoanCollectionForm from '@/components/loanCollections/LoanCollectionForm'
import LoanCollectionSuccess from '@/components/loanCollections/LoanCollectionSuccess'
import CreateCustomerModal from '@/components/customers/CreateCustomerModal'

export default {
  name: 'LoanCollectionNewTrx',
  components: {
    LoanCollectionSearch,
    LoanCollectionForm,
    LoanCollectionSuccess,
    CreateCustomerModal
  },
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Loans',
          disabled: false,
          href: '/loans'
        },
        {
          text: 'Collections',
          disabled: false,
          href: '/loans/collections'
        },
        {
          text: 'New Transaction',
          disabled: true,
          href: '#'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'loanCollections/loanCollectionCurrentPage'
    })
  },
  methods: {
  }
}
</script>
<style scoped>
  .ph-transaction-container .col {
    padding: 15px;
  }

  .ph-breadcrumbs {
    padding: 5px 5px;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }

    .ph-transaction-container .col {
      padding: 12px;
    }

    .ph-breadcrumbs {
      padding: 18px 12px;
      padding-left: 24px;
    }
  }
</style>
