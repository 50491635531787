<template>
  <v-form ref="form">
    <v-row class="px-8 py-8" no-gutters>
      <v-col class="py-4 px-2" :cols="12">
        <h2>Compliance Documents</h2>
      </v-col>
      <v-col :cols="12">
        <v-btn @click="addCompDocFile" color="primary" class="rounded-lg">
          Upload
        </v-btn>
        <form ref="compDocFileForm">
          <input ref="compDocFile" @change="addCompDoc" type="file" class="d-none" accept="image/*,application/pdf">
        </form>
      </v-col>
      <v-col class="pt-4 pt-sm-6 pt-md-12 pb-md-12" :cols="12">
        <v-row no-gutters v-if="compDoc.length > 0">
          <v-col
            :cols="12"
            :sm="6"
            :md="4"
            class="px-sm-2"
            v-for="(value, key) in compDoc"
            :key="key"
          >
              <h3 class="mb-2">Compliance Document #{{ key + 1 }}</h3>
              <v-row no-gutters>
                <v-col class="pt-4" :cols="12">
                  <v-btn @click="viewFile(value)" class="rounded-lg" color="primary" block>View File</v-btn>
                </v-col>
                <v-col class="pt-4" :cols="12">
                  <v-autocomplete
                    v-model="compDoc[key].doc_type"
                    @change="syncCompDocInput"
                    label="Compliance Document Type *"
                    :items="compDocTypes"
                    item-text="text"
                    item-value="value"
                    :return-object="false"
                    :rules="requiredField"
                    color="gray darken-1"
                    class="ph-textbox"
                    dense
                    outlined
                  />
                </v-col>
                <v-col class="pt-4" :cols="12">
                  <v-text-field
                    v-model="compDoc[key].remarks"
                    @change="syncCompDocInput"
                    class="ph-textbox"
                    color="gray darken-1"
                    label="Remarks"
                    dense
                    outlined
                  />
                </v-col>
              </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters v-else>
          <v-col :cols="12">
            <span class="caption">
              No available Compliance Document
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pt-4 px-2 pt-md-8 pb-4" :cols="12">
        <h2>Other Documents</h2>
      </v-col>
      <v-col :cols="12">
        <v-btn @click="addOtherDocFile" color="primary" class="rounded-lg">
          Upload
        </v-btn>
        <form ref="otherDocFileForm">
          <input ref="otherDocFile" @change="addOtherDoc" type="file" class="d-none" accept="image/*,application/pdf">
        </form>
      </v-col>
      <v-col class="pt-4 pt-sm-6 pt-md-12 pb-md-12" :cols="12">
        <v-row no-gutters v-if="otherDoc.length > 0">
          <v-col
            :cols="12"
            :sm="6"
            :md="4"
            class="px-sm-2"
            v-for="(value, key) in otherDoc"
            :key="key"
          >
              <h3 class="mb-2">Other Document #{{ key + 1 }}</h3>
              <v-row no-gutters>
                <v-col class="pt-4" :cols="12">
                  <v-btn @click="viewFile(value)" class="rounded-lg" color="primary" block>View File</v-btn>
                </v-col>
                <v-col class="pt-4" :cols="12">
                  <v-autocomplete
                    v-model="otherDoc[key].doc_type"
                    @change="syncOtherDocInput"
                    label="Other Document Type *"
                    :items="otherDocTypes"
                    item-text="text"
                    item-value="value"
                    :return-object="false"
                    :rules="requiredField"
                    color="gray darken-1"
                    class="ph-textbox"
                    dense
                    outlined
                  />
                </v-col>
              </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters v-else>
          <v-col :cols="12">
            <span class="caption">
              No available Other Document
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pt-4 px-2 pt-md-8 pb-4" :cols="12">
        <h2>Customer Consent</h2>
      </v-col>
      <v-col :cols="12" v-if="!Array.isArray(formData.customerDataConsent)">
        <v-btn @click="pdfConvert" color="primary" class="rounded-lg">
          View Customer Data Consent
        </v-btn>
      </v-col>
      <v-col :cols="12" v-else>
        <span class="caption">
          No available Customer Consent
        </span>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { aesDecrypt } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      formData: 'customers/formData'
    })
  },
  watch: {
    formData () {
      this.syncCompDoc()
      this.syncOtherDoc()
    }
  },
  data () {
    return {
      compDoc: [],
      otherDoc: [],
      dataConsent: [],
      requiredField: [
        v => !!v || 'This field is required'
      ],
      compDocTypes: [
        {
          text: 'Large Principal Money Transfer',
          value: 'lpmt'
        },
        {
          text: 'DPA Consent Form',
          value: 'dpa_consent'
        },
        {
          text: 'EDD',
          value: 'edd'
        },
        {
          text: 'SEC',
          value: 'sec'
        },
        {
          text: 'DTI',
          value: 'dti'
        },
        {
          text: 'Business Permit',
          value: 'busi_permit'
        },
        {
          text: 'Profile Picture',
          value: 'pp'
        },
        {
          text: 'Medical Bills',
          value: 'med_bills'
        },
        {
          text: 'IOU/loan document',
          value: 'iou'
        },
        {
          text: 'Contract of Sale',
          value: 'cos'
        },
        {
          text: 'Purchase Order/Invoice',
          value: 'invoice'
        },
        {
          text: 'Birth certificate',
          value: 'birth_cert'
        }
      ],
      otherDocTypes: [
        {
          text: 'SEC',
          value: 'sec'
        },
        {
          text: 'DTI',
          value: 'dti'
        },
        {
          text: 'Business Permit',
          value: 'busi_permit'
        },
        {
          text: 'Other Document',
          value: 'other'
        },
        {
          text: 'Profile Picture',
          value: 'pp'
        },
        {
          text: 'Image ID Back',
          value: 'id_back'
        },
        {
          text: 'ACR',
          value: 'ACR'
        }
      ]
    }
  },
  methods: {
    addCompDocFile () {
      this.$refs.compDocFileForm.reset()
      this.$refs.compDocFile.click()
    },
    addOtherDocFile () {
      this.$refs.otherDocFileForm.reset()
      this.$refs.otherDocFile.click()
    },
    async addCompDoc (e) {
      const files = e.target.files

      if (files.length > 0) {
        const dateToday = format(new Date(), 'yyyyMMdd')
        const randomNumber = Math.floor(Math.random() * (999 - 100 + 1)) + 100
        this.formData.compliance_edd_docs_file.reverse()
        this.formData.compliance_edd_docs_file.push(files[0])
        this.formData.compliance_edd_docs_file.reverse()

        const fileType = files[0].type
        let fileExtension = '.png'

        if (fileType === 'application/pdf') {
          fileExtension = '.png'
        }

        this.compDoc.reverse()
        this.compDoc.push({
          doc: `${dateToday}${randomNumber}${fileExtension}`,
          doc_type: '',
          remarks: '',
          date_uploaded: format(new Date(), 'yyyy-MM-dd'),
          user_full_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
          user_id: this.currUser.user_id,
          location_id: this.currUser.location_id,
          location_name: this.currUser.location_name,
          usp2: 'true'
        })
        this.compDoc.reverse()

        this.formData.compliance_edd_docs = this.convertToObject(this.compDoc)

        this.syncCompDoc()
      }
    },
    async addOtherDoc (e) {
      const files = e.target.files

      if (files.length > 0) {
        const dateToday = format(new Date(), 'yyyyMMdd')
        const randomNumber = Math.floor(Math.random() * (999 - 100 + 1)) + 100
        this.formData.edd_path_file.reverse()
        this.formData.edd_path_file.push(files[0])
        this.formData.edd_path_file.reverse()

        const fileType = files[0].type
        let fileExtension = '.png'

        if (fileType === 'application/pdf') {
          fileExtension = '.png'
        }

        this.otherDoc.reverse()
        this.otherDoc.push({
          doc: `${dateToday}${randomNumber}${fileExtension}`,
          doc_type: '',
          usp2: 'true'
        })
        this.otherDoc.reverse()

        this.formData.edd_path = this.convertToObject(this.otherDoc)

        this.syncOtherDoc()
      }
    },
    convertToObject (array) {
      const obj = {}

      array.forEach((value, index) => {
        obj[index + 1] = value
      })

      return JSON.stringify(obj)
    },
    syncCompDocInput () {
      this.formData.compliance_edd_docs = this.convertToObject(this.compDoc)
      this.syncCompDoc()
    },
    syncOtherDocInput () {
      this.formData.edd_path = this.convertToObject(this.otherDoc)
      this.syncOtherDoc()
    },
    syncCompDoc () {
      let compDoc = this.formData.compliance_edd_docs || '{}'
      compDoc = JSON.parse(compDoc)
      this.compDoc = Object.values(compDoc)
      this.$refs.form.resetValidation()
    },
    syncOtherDoc () {
      let otherDoc = this.formData.edd_path || '{}'
      otherDoc = JSON.parse(otherDoc)
      this.otherDoc = Object.values(otherDoc)
      this.$refs.form.resetValidation()
    },
    viewFile (file) {
      if (file) {
        let fileStoragePath

        if (file.usp2) fileStoragePath = aesDecrypt(process.env.VUE_APP_STORAGE_BASE_URL)
        else fileStoragePath = aesDecrypt(process.env.VUE_APP_STORAGE_USP1_URL)

        window.open(`${fileStoragePath}/${this.formData.customer_id}/${file.doc}`)
      }
    },
    pdfConvert () {
      var decoded = Buffer.from(this.formData.customerDataConsent, 'base64')
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      window.open(fileURL)
    }
  },
  async mounted () {
    this.syncCompDoc()
    this.syncOtherDoc()
  }
}
</script>
