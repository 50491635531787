<template>
  <v-card class="custom-card mx-12 py-4" v-if="selectedCustomer">
    <v-row class="px-2 py-2 px-md-12 py-md-4" no-gutters>
      <v-col class="d-flex flex-row align-center" :cols="12" :md="3">
        <v-avatar class="mr-2" size="50px" :style="avatarColor(selectedCustomer.full_name)">
          <span class="white--text text-h5">{{ avatarInitial(selectedCustomer.full_name) }}</span>
        </v-avatar>
        <h3>{{ selectedCustomer.full_name }}</h3>
      </v-col>
      <v-col :cols="12" :md="4">
        <span class="caption">CRN: <strong>{{ selectedCustomer.customer_number || '-' }}</strong></span><br>
        <span class="caption">Loyalty: <strong>{{ selectedCustomer.card_no || '-' }}</strong></span><br>
        <span class="caption">Account Number: <strong>{{ selectedCustomer.ub_account_no || '-' }}</strong></span>
      </v-col>
      <v-col :cols="12" :md="2" v-if="!isNotCustomerPage">
        <span class="caption">Send: <strong>{{ transactionCount.total_so_nonex_trx }}</strong></span><br>
        <span class="caption">Receive: <strong>{{ transactionCount.total_po_nonex_trx }}</strong></span><br>
        <span class="caption">Total Transaction: <strong>{{ transactionCount.total_trx }}</strong></span>
      </v-col>
      <v-col :cols="12" :md="2" v-if="isNotCustomerPage">
        <span class="caption">Gender: <strong>{{ selectedCustomer.gender }}</strong></span><br>
        <span class="caption">Birthdate: <strong>{{ selectedCustomer.birth_date }}</strong></span><br>
        <span class="caption">Mobile: <strong>{{ selectedCustomer.mobile_no }}</strong></span>
      </v-col>
      <v-col class="d-flex flex-row justify-center align-center" :cols="12" :md="3">
        <v-btn v-if="!isNotCustomerPage" @click="viewTransactionHistory" color="primary" rounded>
          Transaction History
        </v-btn>
        <v-btn v-if="isNotCustomerPage" @click="updateDetails(selectedCustomer)" class="rounded-lg" color="primary" outlined>
          Update Details
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="transactionHistoryDialog"
      scrollable
      max-width="600px"
      persistent
      v-if="!isNotCustomerPage"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction History
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <v-data-table
            :items="transactionHistory"
            :headers="transactionHistoryHeader"
          >
            <template v-slot:[`item.amount`]="{ item }">
              {{ toMoney(item.amount) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            class="ph-action-button"
            @click="transactionHistoryDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import {
  avatarColor,
  avatarInitial
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      selectedCustomer: 'customers/selectedCustomer'
    })
  },
  props: {
    isNotCustomerPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      transactionHistoryDialog: false,
      transactionHistory: [],
      transactionHistoryHeader: [
        {
          text: 'Transaction',
          sortable: true,
          value: 'transaction_name'
        },
        {
          text: 'Date',
          sortable: true,
          value: 'actual_date'
        },
        {
          text: 'Reference #',
          sortable: true,
          value: 'reference_no'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount'
        },
        {
          text: 'Branch',
          sortable: true,
          value: 'location_name'
        },
        {
          text: 'FLA',
          sortable: true,
          value: 'updated_by'
        }
      ],
      transactionCount: {
        total_trx: 0,
        total_so_nonex_trx: 0,
        total_po_nonex_trx: 0
      }
    }
  },
  methods: {
    async viewTransactionHistory () {
      this.transactionHistory = await this.$store.dispatch('customers/getTransactionLogs', this.selectedCustomer.customer_id)
      this.transactionHistoryDialog = true
    },
    toMoney (number) {
      return numeral(number).format('0,0.00')
    },
    updateDetails (customerDetails) {
      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    avatarColor,
    avatarInitial
  },
  async mounted () {
    this.transactionCount = await this.$store.dispatch('customers/getTransactionCount', this.selectedCustomer.customer_id)
  }
}
</script>
