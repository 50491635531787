<template>
  <v-row class="ph-cico-cashin-forms-container">
    <v-col cols="12" md="8" class="ph-cico-cashin-form">
      <TransactionProfileCard />
      <div class="ph-cico-providers">
        <v-col
          v-for="(provider, index) in providersList"
          :key="index"
          :cols="12"
          :sm="6"
          :md="4"
          class="ph-cico-provider-list"
        >
          <v-card
            @click="selectPartner(provider.provider_code, provider.provider_name, provider)"
            outlined
            flat
            :class="['ph-cico-partner-container', { 'selected': currentProvider === provider.provider_code }]"
          >
            <img :class="provider.class + ' ph-cico-partner-icon-img'" :src="require('../../assets/images/' + provider.image)" />
            <p class="phv-text" v-if="provider.provider_code === 'PHV'"><b>VISA Card</b></p>
          </v-card>
        </v-col>
      </div>
      <v-row class="ph-cico-fields" v-if="currentProvider">
        <v-col cols="12">
          <div class="ph-cico-fields-title">
            <b>Wallet Information</b>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="transactionData.mobileNumber"
            :label="currentProviderAmountPlaceholder"
            color="gray darken-1"
            class="ph-textbox"
            outlined
            @keydown="formatControl($event)"
            :maxlength="currentProvider === 'DISKARTECH' ? 15 : ''"
          />
          <br>
          <p>{{currentProviderTooltip}}</p>
        </v-col>
        <v-col cols="12" md="6" v-if="showAmount">
          <v-text-field
            v-model="transactionData.amount"
            label="Amount"
            color="gray darken-1"
            class="ph-textbox"
            outlined
            type="number"
            :hide-spin-buttons="true"
          />
        </v-col>
      </v-row>
      <v-row class="ph-cico-back-button">
        <v-col cols="12" class="ph-stepper-action-buttons">
          <v-btn
            color="danger"
            outlined
            text
            light
            class="ph-primary-button ph-stepper-button ph-action-button"
            @click="cancelButton"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="4" class="ph-cico-cashin-summary">
      <v-card
        elevation="0"
        color="rgba(26, 39, 145, 0.05)"
      >
        <div class="ph-transfer-summary-header">
          <b>Summary Overview</b>
        </div>
        <br>
        <div class="ph-transfer-summary-content">
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in cicoSummaryOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="ph-transfer-summary-action">
          <v-btn
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn ph-validate-cash-in-desktop"
            @click="validateCashIn"
          >
            Confirm
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-cico-cashin-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-cico-cashin-summary-minified">
          <div class="ph-cico-cashin-summary-content">
            <div class="ph-cico-cashin-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>PHP {{amount > 0 ? amount : '-'}}</b></p>
            </div>
            <div class="ph-cico-cashin-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <div class="ph-transfer-summary-content">
                <p class="ph-transfer-summary-content-title">Please confirm if the sendout details are correct</p>
                <v-simple-table class="ph-transfer-summary-table">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in cicoSummaryOverview"
                        :key="item.name"
                      >
                        <td class="transfer-info-title">{{ item.name }}</td>
                        <td class="transfer-info-value">{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="ph-transfer-summary-action">
                <v-btn
                  color="primary"
                  elevation="0"
                  class="ph-primary-button ph-primary-execute-btn ph-validate-cash-in"
                  @click="validateCashIn"
                >
                  Confirm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noIDFlag"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          No identification card found
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="noIDFlag = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="updateDetails(selectedCustomer)"
          >
            Update Customer Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- OTP -->
    <v-dialog
      v-model="cashInOTPDialog"
      persistent
      :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '30%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="cashInOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <br>
          <p v-if="!showAmount"><b>Amount to Cash-In: </b>PHP {{formatAmount(transactionData.amount)}}</p>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withoutEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updateDetails(selectedCustomer)"
            color="primary"
            text
          >
            Update Customer Record
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Receipt will send to the client/s email address. Would you like to proceed this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="printReceiptInstead"
            color="primary"
            text
          >
            I want a printed Receipt
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <flaggingPrompt @callback="executeCashIn" />
    <wacom-transaction
      v-if="currUser.has_wacom"
      @submit-sig="submitSig" type="transactional"
      :referenceSignatures="customerSignaturesReference"
      :trxData="{sender_name: selectedCustomer.full_name, receiver_name: 'N/A', total_amount: transactionData.amount}"
    />
    <wacom-data-consent-first />
    <wacom-data-consent-second />
    <wacom-data-consent-third />
    <wacom-data-consent-fourth />
    <wacom-data-consent-fifth />
    <wacom-data-consent-sixth />
    <wacom-data-consent-seventh
      :remco="'Pera CICO'"
    />
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { avatarColor, avatarInitial, getForeignRefNo, renderToast } from '@/utils'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import flaggingPrompt from '@/components/transactionLimit/flaggingPrompt.vue'
import wacomMixin from '@/mixins/wacom.js'
import wacomTransaction from '@/components/wacom/wacomTransaction'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'
import { format, parseISO } from 'date-fns'
import CryptoJS from 'crypto-js'

export default {
  name: 'CashInForm',
  mixins: wacomMixin,
  data () {
    return {
      noIDFlag: false,
      cashInOTPDialog: false,
      isOTPLoading: false,
      otpInvalid: false,
      otp: '',
      otpMessage: '',
      summaryDialog: false,
      isNotCustomerPage: true,
      showAmount: true,
      cicoSummaryOverview: [],
      providersList: [],
      mobileNumber: '',
      amount: '',
      currentId: '',
      foreignRefNo: '',
      petnetTrackingNo: '',
      currentProvider: '',
      currentProviderAmountPlaceholder: '',
      currentProviderTooltip: '',
      providerTimestamp: '',
      providerTrackingNo: '',
      transactionData: {},
      executeData: {},
      minAmount: '',
      maxAmount: '',
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      customerSignaturesReference: [],
      hasCustomerDataConsent: false,
      eonTrxPayload: {}
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'cico/transactions',
      collections: 'collections',
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer',
      cashInCurrentTrx: 'cico/cashInCurrentTrx',
      oarNumber: 'oar/oarNumber',
      receipt: 'receipt',
      consentValues: 'wacom/consentValues'
    })
  },
  components: {
    TransactionProfileCard,
    flaggingPrompt,
    wacomTransaction,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  watch: {
    transactionData: {
      handler (val) {
        this.updateOverview(val)
      },
      deep: true
    }
  },
  async mounted () {
    if (this.selectedCustomer) {
      this.foreignRefNo = this.getForeignRefNo()

      this.cicoSummaryOverview = [
        { name: 'Customer Name', value: this.selectedCustomer.full_name },
        { name: 'Wallet', value: this.mobileNumber ? this.mobileNumber : '-' },
        { name: 'Amount to Cash-In', value: this.amount ? this.amount : '-' }
      ]

      await this.$store.dispatch('cico/getCICOProviders').then(res => {
        if (res?.result) {
          const getCashInItems = res.result.filter(res => res.cash_in === 1)

          this.providersList = getCashInItems
        }
      }).catch(e => {
        console.log(e)
      })

      // this.providersList = [
      //   { code: 'GCASH', name: 'GCash', class: 'partner-gcash', image: 'gcash-logo.svg' },
      //   { code: 'DRAGONPAY', name: 'DragonPay', class: 'partner-dragonpay', image: 'dragonpay-logo.svg' },
      //   { code: 'COINS', name: 'coins.ph', class: 'partner-coinsph', image: 'coinsph-logo.svg' },
      //   { code: 'PAYMAYA', name: 'Maya (PayMaya)', class: 'partner-paymaya', image: 'paymaya-logo.png' },
      //   { code: 'PERAHUB', name: 'PeraHub', class: 'partner-ph', image: 'ph-logo-horizontal.svg' },
      //   { code: 'DISKARTECH', name: 'Diskartech', class: 'partner-diskartech', image: 'diskartech-logo.png' },
      //   { code: 'PHV', name: 'PHV Cash-In', class: 'partner-phv', image: 'ph-logo-vertical.svg' },
      //   { code: 'EON', name: 'EON', class: 'partner-eon', image: 'eon-logo.svg' }
      // ]

      const that = this
      setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
      setTimeout(wacomMixin.methods.checkForWacom, 2000)
      const signatures = await that.$store.dispatch('customers/retrieveSignatures', { customer_id: that.selectedCustomer.customer_id })
      that.customerSignaturesReference = signatures.result
      this.checkConsent()
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    getForeignRefNo,
    renderToast,
    flagChecker () {
      if (this.currUser.first_name === this.selectedCustomer.first_name && this.currUser.last_name === this.selectedCustomer.last_name) {
        renderToast('error', 'Blocked Transaction', 'You are not allowed to process this transaction for this customer')
        return false
      }

      if (this.currUser.last_name === this.selectedCustomer.last_name) {
        this.$store.commit('transactionLimit/SET_FLAGGED_TRANSACTION_PAYLOAD', {
          location_id: this.currUser.location_id,
          user_id: this.currUser.user_id,
          customer_id: this.selectedCustomer.customer_id,
          service_name: 'CICO',
          service_type: 'OUTBOUND',
          provider_name: this.currentProvider,
          provider_code: this.currentProvider
        })
        this.$store.commit('transactionLimit/SET_SHOW_FLAGGED_PROMPT_DIALOG', true)
      } else {
        if (this.currentProvider === 'EON' || this.currentProvider === 'PHV') {
          this.executeEONCashIn(this.eonTrxPayload)
        } else {
          this.executeCashIn()
        }
      }
    },
    submitSig (emitted) {
      this.customerSignature = emitted
      this.generateConsent()
      this.openOTPDialog()
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
    },
    openOTPDialog () {
      this.withoutEmailPrompt = false
      this.withEmailPrompt = false
      this.openVerifyOTP()
    },
    proceedTransaction () {
      if (this.currUser.has_wacom && !this.hasCustomerDataConsent) {
        this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
      } else {
        this.openOTPDialog()
      }
    },
    checkEmail () {
      if (this.currUser.location_type === '1') {
        this.sendViaEmail = true
        if (this.selectedCustomer.email_add) {
          this.withoutEmailPrompt = false
          this.withEmailPrompt = true
        } else {
          this.withoutEmailPrompt = true
          this.withEmailPrompt = false
          this.sendViaEmail = false
        }
      } else {
        this.proceedTransaction()
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.proceedTransaction()
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    updateOverview (data) {
      if (this.currentProvider === 'DISKARTECH' || this.currentProvider === 'COINS' || this.currentProvider === 'PAYMAYA' || (data.mobileNumber.length === 11 && this.currentProvider === 'GCASH') || this.currentProvider === 'PHV' || this.currentProvider === 'EON') {
        this.showAmount = true
      } else if (data.mobileNumber.length === 8 && this.currentProvider === 'GCASH') {
        this.showAmount = false
      } else {
        this.showAmount = false
      }

      let amount = ''

      if (data?.trx?.principal_amount) {
        amount = data.trx.principal_amount
      } else if (data.amount) {
        amount = data.amount
      } else if (data.amount < 1) {
        amount = '-'
      } else {
        amount = '-'
      }

      this.cicoSummaryOverview = [
        { name: 'Customer Name', value: this.selectedCustomer.full_name },
        { name: 'Wallet', value: data.mobileNumber ? data.mobileNumber : '-' },
        { name: 'Amount to Cash-In', value: ((amount !== '' || amount !== '-') && data.amount > 0) ? `PHP ${this.formatAmount(amount)}` : '-' }
      ]
    },
    selectPartner (code, provider, providerInfo) {
      this.currentProvider = code

      this.minAmount = providerInfo.min_cash_in
      this.maxAmount = providerInfo.max_cash_in

      switch (code) {
        case 'GCASH':
          this.showAmount = true
          this.currentProviderAmountPlaceholder = 'Cash-In Code/Mobile Number'
          this.currentProviderTooltip = 'Enter 11-digit Mobile No. or Cash-In Code'
          break
        case 'DRAGONPAY':
          this.showAmount = false
          this.currentProviderAmountPlaceholder = 'Control Number'
          this.currentProviderTooltip = 'Enter a Valid Control Number to transact'
          break
        case 'COINS':
          this.showAmount = true
          this.currentProviderAmountPlaceholder = 'Control Number'
          this.currentProviderTooltip = 'Enter a Valid Control Number to transact'
          break
        case 'PAYMAYA':
          this.showAmount = true
          this.currentProviderAmountPlaceholder = 'Mobile Number'
          this.currentProviderTooltip = 'Enter your 11-digit Mobile No.'
          break
        case 'DISKARTECH':
          this.showAmount = false
          this.currentProviderAmountPlaceholder = 'Control Number'
          this.currentProviderTooltip = 'Enter a Valid Control Number to transact'
          break
        case 'PERAHUB':
          this.showAmount = false
          this.currentProviderAmountPlaceholder = 'Control Number'
          this.currentProviderTooltip = 'Enter a Valid Control Number to transact'
          break
        case 'PHV':
          this.showAmount = true
          this.currentProviderAmountPlaceholder = 'Account Number'
          this.currentProviderTooltip = ''
          this.transactionData.mobileNumber = this.selectedCustomer.ub_account_no
          break
        case 'EON':
          this.showAmount = true
          this.currentProviderAmountPlaceholder = 'Account Number'
          this.currentProviderTooltip = ''
          this.transactionData.mobileNumber = ''
          break
      }

      this.renderToast('info', 'Provider selected', `${provider} was selected.`)
    },
    async validateCashIn () {
      const pendingChecker = this.transactions.filter(item => {
        return item.principal_amount === parseFloat(this.transactionData.amount) &&
          item.provider === this.currentProvider &&
          item.mobile_no === this.transactionData.mobileNumber &&
          item.status === 0 && item.trx_type === 'Cash In'
      })

      console.log(this.currentProvider)

      if (pendingChecker.length > 0 && this.currentProvider === 'GCASH') {
        this.renderToast('error', 'Transaction already exists', 'This transaction is already on pending list. You must retry the pending transaction to continue.')
      } else if (!this.transactionData.mobileNumber) {
        this.renderToast('error', 'Transaction Error', 'Mobile or Control Number is required')
      } else if (this.transactionData.amount < this.minAmount) {
        this.renderToast('error', 'Transaction Error', 'The minimum amount for this transaction must be PHP ' + (this.minAmount === 0 ? this.formatAmount(1) : this.formatAmount(this.minAmount)))
      } else if (this.transactionData.amount > this.maxAmount) {
        this.renderToast('error', 'Transaction Error', 'The maximum amount for this transaction must be PHP ' + this.formatAmount(this.maxAmount))
      } else {
        if (this.showAmount === true && (!this.transactionData.amount || this.transactionData.amount < 1)) {
          this.renderToast('error', 'Transaction Error', 'Amount field is required and must not be 0')
        } else {
          if (this.currUser.location_type !== '1') {
            this.executeValidate()
          } else {
            const getReceipt = await this.$store.dispatch('getReceipt', {
              location_id: this.currUser.location_id,
              type: '2',
              application_id: this.currUser.application_id
            })

            if (getReceipt.code === 200) {
              this.executeValidate()
            } else {
              renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
            }
          }
        }
      }
    },
    async executeValidate () {
      const currentCustomer = this.selectedCustomer
      const currentUser = this.currUser

      const idList = currentCustomer.img ? JSON.parse(currentCustomer.img) : []
      let currentId = idList['1']
      currentId = idList['1'] ? idList['1'] : {}
      this.currentId = currentId // 060002135295

      const payload = {
        partner_code: 'USP',
        trx: {
          provider: this.currentProvider,
          reference_number: this.transactionData.mobileNumber,
          trx_type: 'Cash In',
          principal_amount: this.transactionData.amount
        },
        customer: {
          customer_id: currentCustomer?.customer_id ? currentCustomer.customer_id.toString() : '',
          customer_firstname: currentCustomer.first_name,
          customer_lastname: currentCustomer.last_name,
          curr_address: currentCustomer?.address ? currentCustomer.address : '',
          curr_barangay: currentCustomer?.barangay ? currentCustomer.barangay : '',
          curr_city: currentCustomer?.city1 ? currentCustomer.city1 : '',
          curr_province: currentCustomer?.province ? currentCustomer.province : '',
          curr_country: currentCustomer?.country ? currentCustomer.country : '',
          birth_date: currentCustomer.birth_date,
          birth_country: currentCustomer.country_birth,
          contact_no: this.transactionData.mobileNumber.length > 8 ? this.transactionData.mobileNumber : currentCustomer.mobile_no,
          id_type: this.currentId.id_type,
          id_number: this.currentId.id_number
        },
        usp: {
          location_id: currentUser.location_id.toString(),
          location_name: currentUser.location_name,
          user_id: currentUser.user_id.toString(),
          location_group_id: currentUser.location_group_id.toString(),
          form_type: 'OAR', // (this.currUser.location_type === '1' ? 'OAR' : ''),
          form_number: (this.currUser.location_type === '1' ? this.receipt.oar_series_no : this.foreignRefNo),
          ip_address: currentUser.ip_address,
          risk_score: currentCustomer.risk_score,
          risk_criteria: currentCustomer.risk_criteria ? currentCustomer.risk_criteria : '{}'
        }
      }

      if (this.transactionData.mobileNumber.length > 8 || this.provider !== 'DRAGONPAY') {
        payload.trx.principal_amount = this.transactionData.amount
      }

      if (this.currentProvider === 'PHV' || this.currentProvider === 'EON') {
        this.eonTrxPayload = payload
        this.$store.commit('cico/SET_CASHIN_CURRENT_TRX', payload)
        this.checkEmail()
      } else {
        await this.$store.dispatch('cico/trxCICOValidate', payload).then(res => {
          if (res.code === 500) {
            this.renderErrorMessage(res)
          } else if (res === 'Service Unavailable') {
            this.renderToast('error', 'Cash-In Error', res)
          } else if (res.code === 402 && res?.error) {
            this.renderToast('error', 'Cash-In Error', res.error.status_message)
          } else {
            this.executeData = payload
            this.petnetTrackingNo = res.result.petnet_trackingno
            this.providerTrackingNo = res.result.provider_trackingno
            this.providerTimestamp = res.result.timestamp
            payload.trx.principal_amount = res.result.principal_amount
            this.transactionData.amount = res.result.principal_amount

            this.updateOverview(this.transactionData)

            this.$store.commit('cico/SET_CASHIN_CURRENT_TRX', payload)
            this.checkEmail()
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    async executeEONCashIn (payload) {
      const currentDate = format(parseISO(this.currUser.trx_date), 'yyyy-MM-dd HH:mm:ss')
      const clientRefererenceConvert = CryptoJS.MD5(currentDate).toString()
      const clientRefererenceNo = clientRefererenceConvert.substr(0, 20)

      const eonPayload = {
        customer_number: this.selectedCustomer.customer_number,
        card_no: payload.trx.reference_number,
        ub_amount: this.transactionData.amount,
        service_fee: 0,
        total_amount: this.transactionData.amount,
        client_reference_no: clientRefererenceNo,
        coy: 'usp',
        user_name: this.currUser.user_name,
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        customer_id: payload.customer.customer_id,
        customer_name: `${payload.customer.customer_lastname}, ${payload.customer.customer_firstname}`,
        form_type: payload.usp.form_type,
        form_number: payload.usp.form_number,
        trx_type: 'C',
        trx_category: this.currentProvider === 'PHV' ? 'PeraHubCard' : 'Regular',
        date_encoded: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        currency_id: 1,
        // client_ip: this.currUser.ip_address,
        pending_status: 0
      }

      await this.$store.dispatch('cico/trxEONCashIn', eonPayload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.eonTrxPayload = {}
          this.renderToast('success', 'Cash-In Successful', res.message)
          this.$store.commit('cico/SET_CASHIN_CURRENT_PAGE', 2)
          if (this.currUser.location_type === '1') {
            this.generateReceipt(res)
          }
        } else {
          this.renderErrorMessage(res)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async executeCashIn () {
      const validateTrxData = this.executeData
      const currentUser = this.currUser

      try {
        const isProcessable = await this.$store.dispatch('transactionLimit/checkLimit', {
          transactionName: this.currentProvider,
          payload: {
            customer_id: this.selectedCustomer.customer_id,
            provider_code: this.currentProvider,
            service_name: 'CICO',
            service_type: 'OUTBOUND',
            amount: Number(this.transactionData.amount)
          }
        })

        if (isProcessable) {
          const payload = {
            location_id: validateTrxData.usp.location_id,
            petnet_trackingno: this.petnetTrackingNo,
            trx_date: currentUser.trx_date,
            partner_code: 'USP',
            location_name: validateTrxData.usp.location_name,
            reference_number: this.transactionData.mobileNumber,
            provider_trackingno: this.providerTrackingNo,
            trxno: this.transactionData.mobileNumber,
            principal_amount: this.transactionData.amount.toString(),
            date: currentUser.trx_date,
            cust: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
            teller: `${this.currUser.first_name} ${this.currUser.last_name}`,
            idtrx_cashin_cashout: this.transactionData.mobileNumber,
            provider_timestamp: this.providerTimestamp
          }

          console.log(payload)

          await this.$store.dispatch('cico/trxCICOExecute', payload).then(async res => {
            if (res.code >= 200 && res.code <= 299) {
              this.eonTrxPayload = {}
              this.renderToast('success', 'Cash-In Successful', res.message)
              this.$store.commit('cico/SET_CASHIN_CURRENT_PAGE', 2)
              if (this.currUser.location_type === '1') {
                this.generateReceipt(res)
              }
            } else {
              this.renderErrorMessage(res)
              setTimeout(function () {
                window.location.href = '/pera-cico'
              }, 1000)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      } catch (error) {
        renderToast('error', 'Checking Failed', 'Failed to check current limit, please submit a ticket to IT Support')
      }
    },
    async generateReceipt (res) {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipalty
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const saveReceipt = await this.$store.dispatch('saveReceipt', {
        ereceipt_id: this.receipt.ereceipt_id,
        application_id: this.currUser.application_id,
        application_header_name: this.currUser.branchDetail.header_name,
        application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
        receipt_type: this.receipt.type,
        customer_id: this.selectedCustomer.customer_id,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_tin_no: '',
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
        cashier_id: this.currUser.user_id,
        cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
        oar_receipt_no: this.receipt.oar_receipt_no,
        oar_number: this.receipt.oar_series_no,
        oar_service: `Pera CICO (${this.transactionData.mobileNumber})`,
        oar_currency: 'PHP',
        oar_principal_amount: Number(this.transactionData.amount),
        oar_discount: 0,
        oar_total: Number(this.transactionData.amount),
        grand_total: Number(this.transactionData.amount),
        print_email_hold: 0,
        is_forex_logo: 0
      })

      if (saveReceipt.code === 200) {
        if (saveReceipt.result.receipt_status === 1) {
          renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
        } else if (saveReceipt.result.receipt_status === 2) {
          const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
            application_id: this.currUser.application_id,
            ereceipt_api_id: saveReceipt.result.ereceipt_api_id
          })
          var newBlob = new Blob([res], { type: 'application/pdf' })
          var newFileURL = URL.createObjectURL(newBlob)
          window.open(newFileURL, '_blank')
        } else {
          renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
        }
      } else {
        renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
      }
    },
    renderErrorMessage (res) {
      let errorMessage = ''

      switch (this.currentProvider) {
        case 'GCASH':
        case 'DRAGONPAY':
        case 'PAYMAYA':
        case 'DISKARTECH':
          this.renderToast('error', 'Cash-In Error', res.error?.status_message ? res.error.status_message : res.message)
          break
        case 'COINS':
          if (res?.error) {
            Object.keys(res.error).forEach(function (key) {
              errorMessage += res.error[key][0].message
            })

            this.renderToast('error', 'Cash-In Error', errorMessage)
          } else {
            this.renderToast('error', 'Cash-In Error', res.message)
          }
          break
        case 'PERAHUB':
        case 'PHV':
        case 'EON':
          this.renderToast('error', 'Cash-In Error', res.message)
          break
      }
    },
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    openVerifyOTP () {
      this.otp = ''
      this.cashInOTPDialog = true

      console.log(this.cashInOTPDialog)
    },
    updateDetails (customerDetails) {
      this.noIDFlag = false

      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    cancelButton () {
      this.$store.commit('cico/SET_CASHIN_CURRENT_PAGE', 0)
    },
    async doVerify (pin) {
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        const payload = {
          user_name: this.currUser.user_name,
          otp_code: this.otp,
          pin: this.currUser.otp_pin,
          module: 'send'
        }

        const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)
        const isOTPVerified = OTPVerification.result?.value || false

        if (isOTPVerified) {
          this.otpInvalid = false
          this.isOTPLoading = false
          this.cashInOTPDialog = false
          this.flagChecker()
        } else {
          this.otp = ''
          this.otpInvalid = true
          this.isOTPLoading = false
          this.otpMessage = 'Incorrect OTP PIN, please try again.'
        }
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    },
    formatControl (e) {
      if (this.currentProvider === 'DISKARTECH') {
        var key = e.charCode || e.keyCode || 0
        if (key !== 8 && key !== 9) {
          if (this.transactionData.mobileNumber.length === 6) {
            this.transactionData.mobileNumber = this.transactionData.mobileNumber + '-'
          }
          if (this.transactionData.mobileNumber.length === 13) {
            this.transactionData.mobileNumber = this.transactionData.mobileNumber + '-'
          }
        }

        return (key === 8 || key === 9 || key === 46 || key === 13 || key === 37 || key === 39 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105))
      }
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    },
    async generateConsent () {
      console.log(this.selectedCustomer)
      console.log(this.transactionData)
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: 'Pera Cashin - ' + this.currentProvider,
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>
<style scoped>
  .v-stepper.ph-wu-quickpay-stepper .v-stepper__header {
    box-shadow: none !important;
  }

  .ph-cico-cashin-summary .v-card {
    padding: 20px;
  }

  .ph-transfer-summary-header {
    font-size: 18px;
    text-align: center;
  }

  .ph-transfer-summary-content-title {
    font-size: 14px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
    border: 2px solid #1A2791;
  }

  .ph-transaction-container {
    padding: 0;
  }

  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 100px;
  }

  .ph-cico-cashin-summary-minified {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F4F4FA;
  }

  .ph-cico-cashin-summary-content {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
  }

  .ph-cico-cashin-summary-minified-info {
    margin-right: auto;
  }

  .ph-cico-cashin-summary-minified-info p {
    margin-bottom: 0;
  }

  .ph-cico-cashin-summary-minified-action {
    margin-left: auto;
  }

  .ph-primary-button.ph-stepper-button {
    height: 40px !important;
    width: 115px;
  }

  .ph-cico-cashin-form {
    padding: 12px 0;
  }

  /* @media only screen and (min-width: 600px) {
    .v-stepper.ph-wu-quickpay-stepper .v-stepper__step {
      padding-left: 0;
    }
  } */

  .transfer-info-title,
  .transfer-info-value {
    border-bottom: thin dashed rgba(0,0,0,.12) !important;
  }

  .ph-cico-providers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .ph-cico-partner-container {
    height: 100px;
    display: flex;
    z-index: 2;
    border: 1px solid transparent;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08) !important;
    border-radius: 12px !important;
    transition: all .2s;
    cursor: pointer;
  }

  .ph-cico-partner-container:hover {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  .ph-cico-partner-icon-img {
    margin: auto;
    object-fit: fill;
    z-index: 1;
    border-radius: 0 !important;
  }

  .ph-cico-partner-icon-img.partner-paymaya {
    height: 50px;
    width: auto;
    /* position: relative;
    bottom: 15px; */
  }

  .ph-cico-partner-icon-img.partner-diskartech {
    height: 45px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-dragonpay {
    height: 65px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-ph,
  .ph-cico-partner-icon-img.partner-eon {
    height: 30px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-phv {
    height: 30px;
    width: auto;
    margin-right: 10px;
  }

  .phv-text {
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: auto !important;
    font-size: 25px;
    font-family: 'Proxima Nova Bold';
  }

  .ph-cico-fields,
  .ph-cico-back-button {
    margin: 0;
  }

  .ph-cico-partner-container.selected {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  @media only screen and (min-width: 960px) {
    .ph-stepper-action-buttons {
      display: flex;
      margin-bottom: 0;
    }

    .ph-cico-cashin-forms-container {
      margin: 0;
    }
  }
</style>
