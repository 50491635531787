export default {
  currentMonitoringView: state => state.currentMonitoringView,
  showPromptDialog: state => state.showPromptDialog,
  showFlaggedPromptDialog: state => state.showFlaggedPromptDialog,
  flaggedTransactionPayload: state => state.flaggedTransactionPayload,
  promptData: state => state.promptData,
  approverLevel: state => state.approverLevel,
  currentIncreaseRequestsView: state => state.currentIncreaseRequestsView,
  selectedIncreaseLocationLimit: state => state.selectedIncreaseLocationLimit,
  selectedIncreaseCustomerLimit: state => state.selectedIncreaseCustomerLimit,
  increaseLocationRequests: state => state.increaseLocationRequests,
  increaseCustomerRequests: state => state.increaseCustomerRequests,
  currentApprovalRequestsView: state => state.currentApprovalRequestsView,
  selectedApprovalLocationLimit: state => state.selectedApprovalLocationLimit,
  selectedApprovalCustomerLimit: state => state.selectedApprovalCustomerLimit,
  approvalLocationRequests: state => state.approvalLocationRequests,
  approvalCustomerRequests: state => state.approvalCustomerRequests,
  currentView: state => state.currentView,
  selectedLocationLimit: state => state.selectedLocationLimit,
  selectedCustomerLimit: state => state.selectedCustomerLimit,
  selectedApprover: state => state.selectedApprover,
  selectedProvider: state => state.selectedProvider,
  uploadedData: state => state.uploadedData,
  fileName: state => state.fileName,
  approvalLocationFormData: state => state.approvalLocationFormData,
  approvalCustomerFormData: state => state.approvalCustomerFormData,
  increaseLocationFormData: state => state.increaseLocationFormData,
  increaseCustomerFormData: state => state.increaseCustomerFormData,
  locationFormData: state => state.locationFormData,
  customerFormData: state => state.customerFormData,
  approverFormData: state => state.approverFormData,
  providerFormData: state => state.providerFormData
}
