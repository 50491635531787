export default {
  SET_SELECTED_ENTRY (state, payload) {
    state.selectedEntry = payload
  },
  SET_OAR (state, payload) {
    state.oar = payload
  },
  SET_CURRENT_PAGE (state, payload) {
    state.currentPage = payload
  }
}
