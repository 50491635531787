<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 px-md-5 mb-md-12" no-gutters>
      <v-col class="" :cols="12" :md="3">
        <v-menu
          v-model="isDatePickerOpened"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedDate"
              class="ph-textbox"
              label="Select Date"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
            />
          </template>
          <v-date-picker
            v-model="selectedDate"
            @change="retrieveData"
            @input="isDatePickerOpened = false"
          />
        </v-menu>
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-7" :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          class="custom-table"
          calculate-widths
        >
          <template #item.currency_id="{ item }">
            <span v-if="Number(item.currency_id) === 1">PESO</span>
            <span v-if="Number(item.currency_id) === 2">US DOLLAR</span>
          </template>
          <template #item.status="{ item }">
            <span class="blue--text" v-if="Number(item.status) === 0">Pending</span>
            <span class="green--text" v-if="Number(item.status) === 1">Approved</span>
            <span class="red--text" v-if="Number(item.status) === 2">Disapproved</span>
          </template>
          <template #item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openApprovePrompt(item)"
                  color="primary"
                  :disabled="Number(item.status) !== 0"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-check-circle</v-icon>
                </v-btn>
              </template>
              <span>Approve</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openDisapprovePrompt(item)"
                  color="red"
                  :disabled="Number(item.status) !== 0"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Disapprove</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isApprovePromptShown"
      width="700"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Approve
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to approve this request?

          <v-form ref="approveForm">
            <v-row class="mt-6">
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.trxDate"
                  class="ph-textbox"
                  label="Date"
                  :rules="requiredRules"
                  disabled
                  outlined
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.branchName"
                  class="ph-textbox"
                  label="Branch"
                  :rules="requiredRules"
                  disabled
                  outlined
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.currency"
                  class="ph-textbox"
                  label="Currency"
                  :rules="requiredRules"
                  disabled
                  outlined
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.category"
                  class="ph-textbox"
                  label="Category"
                  :rules="requiredRules"
                  disabled
                  outlined
                />
              </v-col>
              <v-col :cols="12">
                <v-text-field
                  v-model="formData.remarks"
                  class="ph-textbox"
                  label="Remarks"
                  :rules="requiredRules"
                  disabled
                  outlined
                />
              </v-col>
              <v-col :cols="12"><h5>Buying Rate</h5></v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.buyRateRequested"
                  class="ph-textbox"
                  label="Requested Rate"
                  :rules="requiredRules"
                  disabled
                  outlined
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.buyRateApproved"
                  class="ph-textbox"
                  label="Approved Rate"
                  :rules="requiredRules"
                  type="number"
                  outlined
                  hide-spin-buttons
                />
              </v-col>
              <v-col :cols="12"><h5>Selling Rate</h5></v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.sellRateRequested"
                  class="ph-textbox"
                  label="Requested Rate"
                  :rules="requiredRules"
                  disabled
                  outlined
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.sellRateApproved"
                  class="ph-textbox"
                  label="Approved Rate"
                  :rules="requiredRules"
                  type="number"
                  outlined
                  hide-spin-buttons
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeApprovePrompt"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="approve"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isDisapprovePromptShown"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Disapprove
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header text-center">
          Are you sure you want to disapprove this request?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDisapprovePrompt"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="disapprove"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      items: 'mcRateRequest/items'
    })
  },
  data () {
    return {
      formData: {
        trxDate: '',
        branchName: '',
        currency: '',
        category: '',
        remarks: '',
        buyRateRequested: '',
        buyRateApproved: '',
        sellRateRequested: '',
        sellRateApproved: ''
      },
      isApprovePromptShown: false,
      isDisapprovePromptShown: false,
      selectedDate: '',
      isDatePickerOpened: false,
      selectedItem: null,
      requiredRules: [v => !!v || 'This field is required'],
      breadcrumbsItems: [
        {
          text: 'MC Rate',
          disabled: false,
          exact: true,
          to: '/mc-rate'
        },
        {
          text: 'Requests',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Date',
          sortable: true,
          value: 'trx_date',
          width: '120px'
        },
        {
          text: 'Branch',
          sortable: true,
          value: 'location_name',
          width: '150px'
        },
        {
          text: 'Category',
          sortable: true,
          value: 'category',
          width: '150px'
        },
        {
          text: 'BUY (R)',
          sortable: true,
          value: 'buy_req_rate',
          width: '100px'
        },
        {
          text: 'BUY (A)',
          sortable: true,
          value: 'buy_am_rate',
          width: '100px'
        },
        {
          text: 'SELL (R)',
          sortable: true,
          value: 'sell_req_rate',
          width: '100px'
        },
        {
          text: 'SELL (A)',
          sortable: true,
          value: 'sell_am_rate',
          width: '100px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '100px'
        },
        {
          text: 'Remarks',
          sortable: true,
          value: 'remarks',
          width: '100px'
        },
        {
          text: 'RH',
          sortable: true,
          value: 'location_group_name',
          width: '120px'
        },
        {
          text: 'Approver',
          sortable: true,
          value: 'approved_by',
          width: '120px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '120px'
        }
      ]
    }
  },
  methods: {
    async approve () {
      if (this.selectedItem) {
        if (this.$refs.approveForm.validate()) {
          try {
            const { code } = await this.$store.dispatch('mcRate/updateStatus', {
              status: 1,
              buy_am_rate: Number(this.formData.buyRateApproved),
              sell_am_rate: Number(this.formData.sellRateApproved),
              approved_by: this.currUser.user_id,
              mc_rate_id: this.selectedItem.mc_rate_id
            })

            if (code === 200) {
              renderToast('success', 'Successful', 'MC Rate has been approved')
              this.closeApprovePrompt()
              this.retrieveData()
            } else {
              renderToast('error', 'Request Error', 'Failed approving the selected request')
            }
          } catch (e) {
            renderToast('error', 'USP Error', 'Failed approving the selected request')
          }
        } else {
          renderToast('warning', 'Validation Error', 'Please fill out all required fields')
        }
      }
    },
    openApprovePrompt (item) {
      this.selectedItem = item
      this.formData.trxDate = item.trx_date
      this.formData.branchName = item.location_name
      this.formData.currency = 'US Dollar'
      this.formData.category = item.category
      this.formData.remarks = item.remarks
      this.formData.buyRateRequested = item.buy_req_rate
      this.formData.buyRateApproved = item.buy_req_rate
      this.formData.sellRateRequested = item.sell_req_rate
      this.formData.sellRateApproved = item.sell_req_rate
      this.isApprovePromptShown = true
    },
    closeApprovePrompt () {
      this.selectedItem = null
      this.isApprovePromptShown = false
      this.$refs.approveForm.reset()
      this.$refs.approveForm.resetValidation()
    },
    async disapprove () {
      if (this.selectedItem) {
        try {
          const { code } = await this.$store.dispatch('mcRate/updateStatus', {
            status: 2,
            buy_am_rate: this.selectedItem.buy_req_rate,
            sell_am_rate: this.selectedItem.sell_req_rate,
            approved_by: this.currUser.user_id,
            mc_rate_id: this.selectedItem.mc_rate_id
          })

          if (code === 200) {
            renderToast('success', 'Successful', 'MC Rate has been disapproved')
            this.closeDisapprovePrompt()
            this.retrieveData()
          } else {
            renderToast('error', 'Request Error', 'Failed disapproving the selected request')
          }
        } catch (e) {
          renderToast('error', 'USP Error', 'Failed disapproving the selected request')
        }
      }
    },
    openDisapprovePrompt (item) {
      this.selectedItem = item
      this.isDisapprovePromptShown = true
    },
    closeDisapprovePrompt () {
      this.selectedItem = null
      this.isDisapprovePromptShown = false
    },
    async retrieveData () {
      try {
        const { code } = await this.$store.dispatch('mcRateRequest/retrieveData', {
          trxDate: this.selectedDate,
          currencyId: 0,
          locationId: 0
        })

        if (code !== 200) {
          renderToast('error', 'Request Error', 'Failed to retrieve grid')
        }
      } catch (e) {
        renderToast('error', 'USP Error', 'Failed to retrieve grid')
      }
    }
  },
  mounted () {
    this.selectedDate = this.currUser.trx_date
    this.retrieveData()
  }
}
</script>
