(function () {
  const FILL = 0
  const STROKE = 1
  const MEASURE = 2
  var renderType = FILL
  var maxSpaceSize = 3
  var minSpaceSize = 0.5
  var renderTextJustified = function (ctx, text, x, y, width) {
    var words, wordsWidth, count, spaces, spaceWidth, adjSpace, renderer, i, textAlign, useSize, totalWidth
    textAlign = ctx.textAlign // get current align settings
    ctx.textAlign = 'left'
    wordsWidth = 0
    words = text.split(' ').map(word => {
      var w = ctx.measureText(word).width
      wordsWidth += w
      return {
        width: w,
        word: word
      }
    })
    count = words.length
    spaces = count - 1
    spaceWidth = ctx.measureText(' ').width
    adjSpace = Math.max(spaceWidth * minSpaceSize, (width - wordsWidth) / spaces)
    useSize = adjSpace > spaceWidth * maxSpaceSize ? spaceWidth : adjSpace
    totalWidth = wordsWidth + useSize * spaces
    if (renderType === MEASURE) {
      ctx.textAlign = textAlign
      return totalWidth
    }
    renderer = renderType === FILL ? ctx.fillText.bind(ctx) : ctx.strokeText.bind(ctx) // fill or stroke
    switch (textAlign) {
      case 'right':
        x -= totalWidth
        break
      case 'end':
        x += width - totalWidth
        break
      case 'center':
        x -= totalWidth / 2
        break
      default:
    }
    if (useSize === spaceWidth) {
      renderer(text, x, y)
    } else {
      for (i = 0; i < count; i += 1) {
        renderer(words[i].word, x, y)
        x += words[i].width
        x += useSize
      }
    }
    ctx.textAlign = textAlign
  }
  // Parse vet and set settings object.
  var justifiedTextSettings = function (settings) {
    var min, max
    var vetNumber = (num, defaultNum) => {
      num = num !== null && num !== null && !isNaN(num) ? num : defaultNum
      if (num < 0) {
        num = defaultNum
      }
      return num
    }
    if (settings === undefined || settings === null) {
      return
    }
    max = vetNumber(settings.maxSpaceSize, maxSpaceSize)
    min = vetNumber(settings.minSpaceSize, minSpaceSize)
    if (min > max) {
      return
    }
    minSpaceSize = min
    maxSpaceSize = max
  }
  // define fill text
  var fillJustifyText = function (text, x, y, width, settings) {
    justifiedTextSettings(settings)
    renderType = FILL
    renderTextJustified(this, text, x, y, width)
  }
  // define stroke text
  var strokeJustifyText = function (text, x, y, width, settings) {
    justifiedTextSettings(settings)
    renderType = STROKE
    renderTextJustified(this, text, x, y, width)
  }
  // define measure text
  var measureJustifiedText = function (text, width, settings) {
    justifiedTextSettings(settings)
    renderType = MEASURE
    return renderTextJustified(this, text, 0, 0, width)
  }
  // code point A
  // set the prototypes
  CanvasRenderingContext2D.prototype.fillJustifyText = fillJustifyText
  CanvasRenderingContext2D.prototype.strokeJustifyText = strokeJustifyText
  CanvasRenderingContext2D.prototype.measureJustifiedText = measureJustifiedText
})()
