<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12">
      <v-col :cols="12" :md="3">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="d-flex align-center" :cols="12" :md="9">
        <v-btn
          @click="cancel"
          class="text-capitalize font-weight-bold"
          color="grey darken-2"
          rounded
          large
          text
        >
          Cancel
        </v-btn>
        <v-btn
          @click="save"
          class="text-capitalize font-weight-bold"
          color="primary"
          rounded
          large
          text
        >
          <v-icon class="mr-1">mdi-check</v-icon>
          Save Changes
        </v-btn>
      </v-col>
      <v-col :cols="12">
        <v-card flat>
          <v-data-table
            :headers="headers"
            :items="uploadedData"
            :search="search"
            class="custom-table"
            calculate-widths
          >
            <template v-slot:item.max_amount="{ item }">
              {{ toMoney(item.max_amount) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { toMoney, renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      fileName: 'transactionLimit/fileName',
      uploadedData: 'transactionLimit/uploadedData'
    })
  },
  data () {
    return {
      search: '',
      headers: [
        {
          text: 'Branch Name',
          value: 'location_name',
          width: '150px'
        },
        {
          text: 'Provider',
          value: 'provider_name',
          width: '150px'
        },
        {
          text: 'Service Name',
          value: 'service_name',
          width: '150px'
        },
        {
          text: 'Service Type',
          value: 'service_type',
          width: '150px'
        },
        {
          text: 'Amount',
          value: 'max_amount',
          width: '150px'
        },
        {
          text: 'Channel',
          value: 'channel',
          width: '150px'
        }
      ],
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Default Limits',
          disabled: false,
          exact: true,
          to: '/transaction-limit/defaults'
        },
        {
          text: 'Upload Preview',
          disabled: true
        }
      ]
    }
  },
  methods: {
    async save () {
      const payload = this.uploadedData.map(item => {
        return {
          location_id: item.location_id,
          is_dsa: false,
          dsa_code: null,
          provider_name: item.provider_name,
          provider_code: item.provider_code,
          service_name: item.service_name,
          service_type: item.service_type,
          max_req_amount: item.max_req_amount,
          max_amount: item.max_amount,
          channel: item.channel,
          requestor: item.requestor,
          remarks: item.remarks
        }
      })

      const { code } = await this.$store.dispatch('transactionLimit/bulkSaveLocationLimit', payload)

      if (code === 200) {
        renderToast('success', 'Successful', 'Bulk Default Location Limit has been uploaded')
        this.cancel()
      } else {
        renderToast('error', 'Failed', 'Failed to save uploaded file')
      }
    },
    cancel () {
      this.$store.commit('transactionLimit/SET_FILE_NAME', '')
      this.$store.commit('transactionLimit/SET_UPLOADED_DATA', [])
      this.$router.push('/transaction-limit/defaults')
    },
    toMoney
  },
  beforeMount () {
    if (this.uploadedData.length === 0) {
      renderToast('error', 'No file uploaded', 'Please upload a file first')
      this.$router.push('/transaction-limit/defaults')
    }
  }
}
</script>

<style scoped>
.custom-heading {
  font-family: 'Baloo Extra Bold' !important;
}
</style>
