<template>
  <div>
    <v-dialog
      v-model="updateSummaryDialog"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Update Summary
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-left py-12">
          <p>Please review the changes before updating the record.</p>
          <br>
          <v-data-table
            :headers="updateSummaryHeaders"
            :items="results"
            no-data-text="No updates found"
          >
            <template v-slot:[`item.prop_name`]="{ item }">
              <span>{{ getPropertyName(item.prop_name) }}</span>
            </template>
            <template v-slot:[`item.old_value`]="{ item }">
              <span>
                <!-- IDs -->
                <v-row class="py-2" v-if="item.prop_name.toLowerCase() === 'img'" no-gutters>
                  <v-col v-for="(data, index) in item.old_value" :key="data.id" :cols="12">
                    <div class="d-block justify-space-between">
                      <div>ID Type: {{ data.id_type }}</div>
                      <div>ID Number: {{ data.id_number }}</div>
                      <div>Country: {{ data.country }}</div>
                      <div>ID Expiry Date: {{ data.expiry ? data.expiry : '-' }}</div>
                      <div>ID Issue Date: {{ data.issue }}</div>
                    </div>
                    <v-divider v-if="!(item.old_value.length === parseInt(index + 1))" class="my-md-2" style="border-style: dashed" />
                  </v-col>
                </v-row>
                <!-- Compliance Docs -->
                <v-row class="py-2" v-if="item.prop_name.toLowerCase().includes('compliance')" no-gutters>
                  <v-col v-for="(data, index) in item.old_value" :key="data.id" :cols="12">
                    <div class="d-block justify-space-between">
                      <div>Document: {{ getDocumentType(data.doc_type, 'compliance') }}</div>
                      <div>Remarks: {{ data.remarks }}</div>
                    </div>
                    <v-divider v-if="!(item.old_value.length === parseInt(index + 1))" class="my-md-2" style="border-style: dashed" />
                  </v-col>
                </v-row>
                <!-- EDD Path -->
                <v-row class="py-2" v-if="item.prop_name.toLowerCase().includes('edd path')" no-gutters>
                  <v-col v-for="(data, index) in item.old_value" :key="data.id" :cols="12">
                    <div class="d-block justify-space-between">
                      <div>Document File: {{ data.doc }}</div>
                      <div>Document Type: {{ getDocumentType(data.doc_type, 'edd') }}</div>
                    </div>
                    <v-divider v-if="!(item.old_value.length === parseInt(index + 1))" class="my-md-2" style="border-style: dashed" />
                  </v-col>
                </v-row>
                <!-- Consent -->
                <v-row v-if="item.prop_name.toLowerCase().includes('consent')" no-gutters>
                  <v-col :cols="12" v-if="!Array.isArray(item.old_value)">
                    <v-btn @click="pdfConvert" color="primary" class="rounded-lg">
                      View Customer Data Consent
                    </v-btn>
                  </v-col>
                  <v-col :cols="12" v-else>
                    <span class="caption">
                      No available Customer Consent
                    </span>
                  </v-col>
                </v-row>
                <!-- Customer Signature -->
                <v-row v-if="item.prop_name.toLowerCase().includes('signatures')" no-gutters>
                  <div v-if="item.old_value.length < 1">
                    <span>No previous signature submitted</span>
                  </div>
                  <span v-if="item.old_value.length > 0" >
                    <div v-for="(signature, index) in item.old_value" :key="index">
                      <img :src="signature.bucket_path" alt="" class="column rendered-img">
                    </div>
                  </span>
                </v-row>
              </span>
              <!-- Text Only -->
              <span v-if="!(item.prop_name.toLowerCase() === 'img' ||
                item.prop_name.toLowerCase().includes('compliance') ||
                item.prop_name.toLowerCase().includes('edd path') ||
                item.prop_name.toLowerCase().includes('consent') ||
                item.prop_name.toLowerCase().includes('signatures'))">
                {{item.old_value}}
              </span>
            </template>
            <template v-slot:[`item.new_value`]="{ item }">
              <span>
                <!-- IDs -->
                <v-row class="py-2" v-if="item.prop_name.toLowerCase() === 'img'" no-gutters>
                  <v-col v-for="(data, index) in item.new_value" :key="data.id" :cols="12">
                    <div class="d-block justify-space-between">
                      <div>ID Type: {{ data.id_type }}</div>
                      <div>ID Number: {{ data.id_number }}</div>
                      <div>Country: {{ data.country }}</div>
                      <div>ID Expiry Date: {{ data.expiry ? data.expiry : '-' }}</div>
                      <div>ID Issue Date: {{ data.issue }}</div>
                    </div>
                    <v-divider v-if="!(item.new_value.length === parseInt(index + 1))" class="my-md-2" style="border-style: dashed" />
                  </v-col>
                </v-row>
                <!-- Compliance Docs -->
                <v-row class="py-2" v-if="item.prop_name.toLowerCase().includes('compliance')" no-gutters>
                  <v-col v-for="(data, index) in item.new_value" :key="data.id" :cols="12">
                    <div class="d-block justify-space-between">
                      <div>Document: {{ getDocumentType(data.doc_type, 'compliance') }}</div>
                      <div>Remarks: {{ data.remarks }}</div>
                    </div>
                    <v-divider v-if="!(item.new_value.length === parseInt(index + 1))" class="my-md-2" style="border-style: dashed" />
                  </v-col>
                </v-row>
                <!-- EDD Path -->
                <v-row class="py-2" v-if="item.prop_name.toLowerCase().includes('edd path')" no-gutters>
                  <v-col v-for="(data, index) in item.new_value" :key="data.id" :cols="12">
                    <div class="d-block justify-space-between">
                      <div>Document File: {{ data.doc }}</div>
                      <div>Document Type: {{ getDocumentType(data.doc_type, 'edd') }}</div>
                    </div>
                    <v-divider v-if="!(item.new_value.length === parseInt(index + 1))" class="my-md-2" style="border-style: dashed" />
                  </v-col>
                </v-row>
                <!-- Consent -->
                <v-row v-if="item.prop_name.toLowerCase().includes('consent')" no-gutters>
                  <v-col :cols="12" v-if="!Array.isArray(item.new_value)">
                    <v-btn @click="pdfConvert" color="primary" class="rounded-lg">
                      View Customer Data Consent
                    </v-btn>
                  </v-col>
                  <v-col :cols="12" v-else>
                    <span class="caption">
                      No available Customer Consent
                    </span>
                  </v-col>
                </v-row>
                <!-- Customer Signature -->
                <v-row v-if="item.prop_name.toLowerCase().includes('signatures')" no-gutters>
                  <div v-for="(signature, index) in item.new_value" :key="index">
                    <img :src="'data:image/png;base64,' + signature" alt="" class="column rendered-img">
                  </div>
                </v-row>
              </span>
              <!-- Text Only -->
              <span v-if="!(item.prop_name.toLowerCase() === 'img' ||
                item.prop_name.toLowerCase().includes('compliance') ||
                item.prop_name.toLowerCase().includes('edd path') ||
                item.prop_name.toLowerCase().includes('consent') ||
                item.prop_name.toLowerCase().includes('signatures'))">
                {{item.new_value ? item.new_value : '-'}}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            class="ph-action-button"
            @click="confirmDialog = true"
          >
            Accept Changes
          </v-btn>
          <v-btn
            text
            class="ph-action-button"
            @click="closeDialog"
          >
            Decline Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Customer Saving
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to update the customer details?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="confirmDialog = false"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="updateRecord"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  props: {
    showUpdateSummary: Boolean,
    changeSummary: Array
  },
  data () {
    return {
      updateSummaryDialog: false,
      isUpdating: false,
      confirmDialog: false,
      updateSummaryHeaders: [
        {
          text: 'Field',
          sortable: true,
          value: 'prop_name'
        },
        {
          text: 'Current Record',
          sortable: true,
          value: 'old_value'
        },
        {
          text: 'Updated Record',
          sortable: true,
          value: 'new_value'
        }
      ],
      compDocTypes: [
        {
          text: 'Large Principal Money Transfer',
          value: 'lpmt'
        },
        {
          text: 'DPA Consent Form',
          value: 'dpa_consent'
        },
        {
          text: 'EDD',
          value: 'edd'
        },
        {
          text: 'SEC',
          value: 'sec'
        },
        {
          text: 'DTI',
          value: 'dti'
        },
        {
          text: 'Business Permit',
          value: 'busi_permit'
        },
        {
          text: 'Profile Picture',
          value: 'pp'
        },
        {
          text: 'Medical Bills',
          value: 'med_bills'
        },
        {
          text: 'IOU/loan document',
          value: 'iou'
        },
        {
          text: 'Contract of Sale',
          value: 'cos'
        },
        {
          text: 'Purchase Order/Invoice',
          value: 'invoice'
        },
        {
          text: 'Birth certificate',
          value: 'birth_cert'
        }
      ],
      otherDocTypes: [
        {
          text: 'SEC',
          value: 'sec'
        },
        {
          text: 'DTI',
          value: 'dti'
        },
        {
          text: 'Business Permit',
          value: 'busi_permit'
        },
        {
          text: 'Other Document',
          value: 'other'
        },
        {
          text: 'Profile Picture',
          value: 'pp'
        },
        {
          text: 'Image ID Back',
          value: 'id_back'
        },
        {
          text: 'ACR',
          value: 'ACR'
        }
      ],
      results: []
    }
  },
  watch: {
    showUpdateSummary: {
      handler (data) {
        if (data) {
          this.updateSummaryDialog = true
        } else {
          this.updateSummaryDialog = false
        }
      },
      deep: true
    },
    changeSummary: {
      handler (data) {
        this.results = data
        this.formatData(this.results)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      formData: 'customers/formData',
      currUser: 'auth/currUser'
    })
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    closeDialog () {
      this.$emit('update:showUpdateSummary', false)
      this.$emit('update:changeSummary', [])
    },
    formatData (changeData) {
      const objectChar = new RegExp(/{([^}]*)}/)
      changeData.forEach(item => {
        if (objectChar.test(item.old_value) || objectChar.test(item.new_value)) {
          const oldValueObj = objectChar.test(item.old_value) ? JSON.parse(item.old_value) : {}
          const newValueObj = objectChar.test(item.new_value) ? JSON.parse(item.new_value) : {}
          const formattedOldValue = []
          const formattedNewValue = []

          if (oldValueObj) {
            Object.keys(oldValueObj).forEach(function (key) {
              oldValueObj[key].id = key
              formattedOldValue.push(oldValueObj[key])
            })
          }

          if (newValueObj) {
            Object.keys(newValueObj).forEach(function (key) {
              newValueObj[key].id = key
              formattedNewValue.push(newValueObj[key])
            })
          }

          const uniqueResultOld = formattedOldValue.filter(oldVal => {
            return !formattedNewValue.some(newVal => {
              return JSON.stringify(oldVal) === JSON.stringify(newVal)
            })
          })

          const uniqueResultNew = formattedNewValue.filter(newVal => {
            return !formattedOldValue.some(oldVal => {
              return JSON.stringify(newVal) === JSON.stringify(oldVal)
            })
          })

          item.old_value = uniqueResultOld
          item.new_value = uniqueResultNew
        }
      })

      this.results = changeData
    },
    async updateRecord () {
      this.$emit('updateCustomer')
      this.closeDialog()
      this.confirmDialog = false
    },
    getDocumentType (value, type) {
      let finalValue = ''
      if (type === 'compliance') {
        const getComplianceValue = this.compDocTypes.filter(x => x.value === value)
        finalValue = getComplianceValue[0].text
      } else {
        const getEDDValue = this.otherDocTypes.filter(x => x.value === value)
        finalValue = getEDDValue[0].text
      }

      return finalValue
    },
    getPropertyName (prop) {
      const propertyName = prop.toLowerCase()
      let property = ''

      if (propertyName === 'img') {
        property = 'Identification Cards'
      } else if (propertyName.includes('compliance')) {
        property = 'Compliance Documents'
      } else if (propertyName.includes('edd path')) {
        property = 'Other Documents'
      } else if (propertyName.includes('consent')) {
        property = 'Customer Consent'
      } else {
        switch (propertyName) {
          case 'country birth':
            property = 'Country of Birth'
            break
          case 'email add':
            property = 'Email Address'
            break
          case 'mobile no':
            property = 'Mobile Number'
            break
          case 'tel no':
            property = 'Telephone Number'
            break
          case 'street1':
            property = 'Permanent Address'
            break
          case 'barangay1':
            property = 'Permanent Barangay'
            break
          case 'city1':
            property = 'Permanent City'
            break
          case 'country1':
            property = 'Permanent Country'
            break
          case 'zip code1':
            property = 'Permanent Zip Code'
            break
          case 'source fund':
            property = 'Source of fund'
            break
          case 'nature work':
            property = 'Nature of work'
            break
          case 'purpose transaction':
            property = 'Purpose of Transaction'
            break
          case 'relation to':
            property = 'Relationship to Sender/Receiver'
            break
          default:
            property = prop
        }
      }

      return property
    }
  }
}
</script>
<style scoped>
.rendered-img {
  height: 100px;
  width: 200px;
}
</style>
