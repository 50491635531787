<template>
  <component :is="receipt" :printData="printData"></component>
</template>

<script>
import { mapGetters } from 'vuex'
import OAR from '@/views/Receipt/OAR.vue'
import OR from '@/views/Receipt/OR.vue'
import MCReceipt from '@/views/Receipt/MCReceipt.vue'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      receipt: null,
      printData: null
    }
  },
  methods: {
    async getReceiptData (refNo, trxType, type, template) {
      const receiptData = await this.$store.dispatch('printReceipt', {
        locationID: this.$store.state.auth.currUser.location_id,
        trxDate: this.$store.state.auth.currUser.trx_date,
        refNo,
        trxType,
        type
      })

      if (receiptData.code === 200) {
        this.printData = receiptData.result[0]
        this.receipt = template
      }
    },
    async getMCPrePrinted (refNo) {
      const req = await this.$store.dispatch('moneyChange/getGrid', {
        trxDate: this.currUser.trx_date,
        locationID: this.currUser.location_id
      })

      if (req.code === 200) {
        const transactionData = req.result.filter(item => item.form_number === refNo)

        if (transactionData.length === 1) {
          this.printData = transactionData[0]
          this.receipt = MCReceipt
        }
      }
    }
  },
  async beforeMount () {
    const refNo = this.$route.query.ref
    const trxType = this.$route.query.trx_type
    const type = this.$route.query.type

    if (refNo && trxType && type) {
      if (type === 'OAR') {
        this.getReceiptData(refNo, trxType, type, OAR)
      } else if (type === 'OR') {
        this.getReceiptData(refNo, trxType, type, OR)
      } else if (trxType === 'mc' && type === 'PRE-PRINT') {
        this.getMCPrePrinted(refNo)
      } else {
        window.close()
      }
    } else {
      window.close()
    }
  }
}
</script>
