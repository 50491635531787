<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-row class="ph-transaction-container">
        <v-col cols="12">
          <v-row class="ph-wu-success-page-container">
            <v-col cols="12">
              <h1 class="ph-wu-form-title">Success!</h1>
              <p class="ph-wu-form-subtitle">Thank you. Transaction has been completed.</p>
            </v-col>
            <v-col cols="12" md="9">
              <p class="ph-wu-success-receiver-name"><b>Order Details</b></p>
              <v-row class="ph-wu-success-details">
                <v-col cols="12" sm="5">
                  <v-simple-table class="ph-transfer-summary-table">
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="item in orderDetails"
                          :key="item.name"
                        >
                          <td class="transfer-info-title">{{ item.name }}</td>
                          <td class="transfer-info-value">{{ item.value }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <br>
              <p class="ph-wu-success-receiver-name"><b>Customer details</b></p>
              <v-row class="ph-wu-success-details">
                <v-col cols="12" sm="5">
                  <v-simple-table class="ph-transfer-summary-table">
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="item in customerDetails"
                          :key="item.name"
                        >
                          <td class="transfer-info-title">{{ item.name }}</td>
                          <td class="transfer-info-value">{{ item.value }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                elevation="0"
                class="ph-primary-button ph-primary-execute-btn"
                @click="backToPayAtWU"
              >
                Back to Pay @ WU
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { formatMTCN, renderAVPSpiel, getAge } from '@/utils'
import numeral from 'numeral'

export default {
  name: 'WUPaymentSuccessPage',
  data () {
    return {
      loyaltyCardNumber: '',
      loyaltyPoints: '',
      mtcn: '',
      crossSellingData: {},
      orderDetails: [
        { name: 'Merchant Name', value: '-' },
        { name: 'Pay/Refund Code', value: '-' },
        { name: 'Total Payment', value: '-' }
      ],
      customerDetails: [
        { name: 'First Name', value: '-' },
        { name: 'Last Name', value: '-' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      orderCurrentTrx: 'wuPayments/orderCurrentTrx',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    const orderPayload = this.orderCurrentTrx
    console.log(orderPayload)
    this.orderDetails = [
      { name: 'Merchant Name', value: orderPayload.order_details.merchant_name },
      { name: orderPayload.tran_type === 'PAYMENT' ? 'Pay Code' : 'Refund Code', value: orderPayload.order_details.confirmation_number },
      { name: 'Total Payment', value: orderPayload.payment_details.amount }
    ]
    this.customerDetails = [
      { name: 'First Name', value: orderPayload.first_name },
      { name: 'Last Name', value: orderPayload.last_name }
    ]
  },
  methods: {
    formatMTCN,
    renderAVPSpiel,
    getAge,
    backToPayAtWU () {
      this.$router.push('/wu-payments')
      this.$store.commit('wuPayments/SET_CURRENT_ORDER_TRX', {})
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    }
  }
}
</script>
<style scoped>
  .ph-wu-form-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-wu-success-detail {
    border: 1px solid #CCC !important;
    border-radius: 10px !important;
    display: flex;
    padding: 10px 18px;
  }

  .ph-wu-success-detail-text {
    margin-left: 10px;
    margin-top: 4px;
  }

  .ph-primary-button {
    background: #FFF !important;
    color: #1A2791;
    border: 2px solid #1A2791;
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    border: 2px solid #fff;
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    font-size: 14px;
  }

  .ph-primary-button.ph-oar-button {
    margin-right: 10px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-action-button {
    margin-right: 10px;
  }

  .ph-transaction-container .col {
    padding: 15px;
  }

  .ph-breadcrumbs {
    padding: 5px 5px;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }

    .ph-transaction-container .col {
      padding: 12px;
    }

    .ph-breadcrumbs {
      padding: 18px 12px;
      padding-left: 24px;
    }

    .ph-primary-button {
      width: 250px;
      margin-left: auto;
    }
  }
</style>
