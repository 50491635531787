<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row v-if="approverLevel === 'ADMIN' || approverLevel === 'ROH'" class="px-2 pt-md-2 px-md-5 mb-md-12">
      <v-col :cols="12">
        <v-tabs v-model="currentView" class="noBottomLine">
          <v-tab class="font-weight-bold text-subtitle-1 text-capitalize tabText">Location</v-tab>
          <v-tab class="font-weight-bold text-subtitle-1 text-capitalize tabText">Customer</v-tab>
        </v-tabs>
      </v-col>
      <v-col class="pt-4" :cols="12">
        <v-tabs-items v-model="currentView" class="py-2">
          <v-tab-item>
            <DefaultLocationLimit />
          </v-tab-item>
          <v-tab-item>
            <DefaultCustomerLimit />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-speed-dial
      v-if="approverLevel === 'ROH'"
      v-model="fab"
      bottom
      right
      fixed
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/transaction-limit/defaults/customer"
            color="#1A2791"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <v-icon color="white">mdi-account</v-icon>
          </v-btn>
        </template>
        <span>Customer</span>
      </v-tooltip>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/transaction-limit/defaults/location"
            color="#1A2791"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
          <v-icon color="white">mdi-map-marker</v-icon>
          </v-btn>
        </template>
        <span>Location</span>
      </v-tooltip>
    </v-speed-dial>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'
import DefaultLocationLimit from '@/views/TransactionLimit/DefaultLimit/DefaultLocationLimit.vue'
import DefaultCustomerLimit from '@/views/TransactionLimit/DefaultLimit/DefaultCustomerLimit.vue'

export default {
  computed: {
    ...mapGetters({
      approverLevel: 'transactionLimit/approverLevel',
      currUser: 'auth/currUser'
    }),
    currentView: {
      get () {
        return this.$store.state.transactionLimit.currentView
      },
      set (val) {
        this.$store.commit('transactionLimit/SET_CURRENT_VIEW', val)
      }
    }
  },
  components: {
    DefaultLocationLimit,
    DefaultCustomerLimit
  },
  data () {
    return {
      fab: false,
      views: [
        { text: 'Default Location Limit', value: 'loc' },
        { text: 'Default Customer Limit', value: 'cus' }
      ],
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Default Limits',
          disabled: true
        }
      ]
    }
  },
  async mounted () {
    const { code, result } = await this.$store.dispatch('transactionLimit/getApprover', this.currUser.user_id)

    if (code === 200) {
      if (result.length > 0) {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', result[0].approver_level)
      } else {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', '')
      }
    }

    if (this.approverLevel !== 'ADMIN' && this.approverLevel !== 'ROH') {
      renderToast('error', 'No access', 'You don\'t have access to this module')
    }
  }
}
</script>

<style lang="css" scoped>
.noBottomLine {
  border-bottom: none !important;
}

.tabText {
  font-family: 'Baloo Extra Bold' !important;
  /* min-width: 150px !important; */
  margin-right: 10px !important;
}
</style>
