<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-form ref="form" v-if="formData.customerID">
      <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
        <v-col :cols="3">
          <v-row>
            <v-col class="mb-md-2" :cols="12">
              <h6 class="text-h6 custom-heading primary--text">
                Default Limit Request
              </h6>
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.customerName"
                @click:append="changeCustomer"
                append-icon="mdi-pencil"
                class="ph-textbox"
                label="Customer"
                :rules="requiredRules"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.provider"
                :items="collections.providers"
                item-text="provider_name"
                item-value="id"
                :rules="requiredRules"
                class="ph-textbox"
                label="Provider"
                return-object
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.provider.service_name"
                class="ph-textbox"
                label="Service Name"
                :rules="requiredRules"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.provider.service_type"
                class="ph-textbox"
                label="Service Type"
                :rules="requiredRules"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.channel"
                :items="collections.channels"
                item-text="channel_name"
                item-value="channel_code"
                class="ph-textbox"
                label="Channel"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.amount"
                class="ph-textbox"
                label="Amount to Request"
                :rules="requiredRules"
                type="number"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col class="mb-md-2" :cols="12">
              <v-textarea
                v-model="formData.remarks"
                @input="convertFieldToProperCase('remarks')"
                class="ph-textbox"
                :rules="requiredRules"
                label="Remarks"
                outlined
              />
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="save" class="font-weight-bold" color="primary" block x-large>
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row v-else class="px-md-1 mx-md-4 mt-md-5" no-gutters>
      <v-col :cols="12" :md="4">
        <h3 class="pb-2 pb-md-6 custom-heading primary--text">
          Customer Lookup
        </h3>
        <p class="text-body-2 mb-6">
          Search for a PERA Hub customer
        </p>
      </v-col>
      <v-col class="pl-md-6" :cols="12" :md="8">
        <h4 class="mb-4 mb-md-5 grey--text text--darken-2">
          Customer's name
        </h4>
        <v-text-field
          v-model="firstName"
          class="ph-textbox mb-4 mb-md-6"
          label="First Name"
          @input="convertToProperCase('firstName')"
          @keyup.enter="doSearch"
          outlined
        />
        <v-text-field
          v-model="lastName"
          class="ph-textbox mb-4 mb-md-6"
          label="Last Name"
          @input="convertToProperCase('lastName')"
          @keyup.enter="doSearch"
          outlined
        />
        <div class="text-right">
          <v-btn @click="doSearch" :disabled="!firstName || !lastName" class="rounded-lg" color="primary" large>
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="resultDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="60%">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            PERA Hub Customer Results
          </h3>
          <v-spacer />
          <v-btn @click="resultDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select Customer</strong>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                class="ph-textbox rounded-lg"
                placeholder="Search"
                outlined
              />
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectCustomer"
            :headers="headers"
            :items="searchData.results"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No customers found"
          >
            <template v-slot:item.name="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:item.gender="{ item }">
              {{ toProperCase(item.gender) || '-' }}
            </template>
            <template v-slot:item.mobile_no="{ item }">
              {{ item.mobile_no || '-' }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarInitial,
  toProperCase,
  renderToast,
  avatarColor,
  trim
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections',
      searchData: 'customers/searchData',
      formData: 'transactionLimit/customerFormData',
      selectedCustomerLimit: 'transactionLimit/selectedCustomerLimit'
    })
  },
  data () {
    return {
      searchText: '',
      firstName: '',
      lastName: '',
      resultDialog: false,
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Default Limits',
          disabled: false,
          exact: true,
          to: '/transaction-limit/defaults'
        },
        {
          text: 'Update Customer Limit',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Customer No.',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'mobile_no'
        }
      ]
    }
  },
  methods: {
    doSearch () {
      if (this.firstName && this.lastName) {
        const firstName = this.firstName.replace(/(^\s+|\s+$)/g, '')
        const lastName = this.lastName.replace(/(^\s+|\s+$)/g, '')
        const keyword = `${lastName},${firstName}`

        this.searchData.results = []
        this.$store.dispatch('customers/doSearch', keyword)
        this.resultDialog = true
      } else {
        this.searchData.results = []
      }
    },
    changeCustomer () {
      this.resetSearchField()
      this.$store.commit('transactionLimit/SET_CUSTOMER_FORM_DATA', {
        customerID: '',
        customerName: ''
      })
    },
    selectCustomer (data) {
      this.$store.commit('transactionLimit/SET_CUSTOMER_FORM_DATA', {
        customerID: data.customer_id,
        customerName: data.full_name
      })
      this.resultDialog = false
    },
    async save () {
      if (this.$refs.form.validate()) {
        const payload = {
          customer_id: this.formData.customerID,
          location_id: this.currUser.location_id,
          provider_name: this.formData.provider.provider_name,
          provider_code: this.formData.provider.provider_code,
          service_name: this.formData.provider.service_name,
          service_type: this.formData.provider.service_type,
          max_req_amount: this.formData.amount,
          max_amount: this.formData.amount,
          channel: this.formData.channel,
          remarks: this.formData.remarks,
          requestor: this.currUser.user_id
        }

        const { code } = await this.$store.dispatch('transactionLimit/updateCustomerLimit', {
          id: this.$route.params.id,
          payload
        })

        if (code === 200) {
          this.$store.dispatch('transactionLimit/resetCustomerForm')
          renderToast('success', 'Successful', 'Default Customer Limit has been updated')
          this.$router.push('/transaction-limit/defaults')
        } else {
          renderToast('error', 'Failed', 'Failed to create default customer limit')
        }
      }
    },
    cancel () {
      this.$router.push('/transaction-limit/defaults')
    },
    convertToProperCase (field) {
      this[field] = toProperCase(trim(this[field]))
    },
    convertFieldToProperCase (field) {
      this.formData[field] = toProperCase(trim(this.formData[field]))
    },
    resetSearchField () {
      this.firstName = ''
      this.lastName = ''
    },
    avatarInitial,
    toProperCase,
    avatarColor
  },
  async beforeMount () {
    this.$store.dispatch('transactionLimit/resetCustomerForm')
    await this.$store.dispatch('getProviders')
    this.resetSearchField()

    if (this.selectedCustomerLimit) {
      const [selectedProvider] = this.collections.providers.filter(item => item.provider_code === this.selectedCustomerLimit.provider_code)

      this.$store.commit('transactionLimit/SET_CUSTOMER_FORM_DATA', {
        customerID: this.selectedCustomerLimit.customer_id,
        customerName: this.selectedCustomerLimit.customer_name,
        provider: selectedProvider,
        channel: this.selectedCustomerLimit.channel,
        amount: this.selectedCustomerLimit.max_amount,
        remarks: this.selectedCustomerLimit.remarks
      })
    } else {
      // Implement getting the data when reload
    }
  }
}
</script>

<style scoped>
.custom-heading {
  font-family: 'Baloo Extra Bold' !important;
}
</style>
