import { endpoints } from '@/api/endpoints'

const coc = endpoints.use('coc')

export default {
  printMalayan: ({ commit }, payload) => new Promise((resolve, reject) => {
    coc.printMalayan(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  printMalayanNew: ({ commit }, payload) => new Promise((resolve, reject) => {
    coc.printMalayanNew(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  reprintMalayan: ({ commit }, payload) => new Promise((resolve, reject) => {
    coc.reprintMalayan(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  printPeraPayapa: ({ commit }, payload) => new Promise((resolve, reject) => {
    coc.printPeraPayapa(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  printDengueProtectPlus: ({ commit }, payload) => new Promise((resolve, reject) => {
    coc.printDengueProtectPlus(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  printDengueProtectv2: ({ commit }, payload) => new Promise((resolve, reject) => {
    coc.printDengueProtectv2(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  printCovidProtectPlus: ({ commit }, payload) => new Promise((resolve, reject) => {
    coc.printCovidProtectPlus(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  printCTPL: ({ commit }, payload) => new Promise((resolve, reject) => {
    coc.printCTPL(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
