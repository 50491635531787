import api from '@/api'

export default {
  getPromos () {
    return api.get('/v1/maintenance/api/promo-maintenance/promo-grid')
  },
  getPromo (id) {
    return api.get(`/v1/maintenance/api/promo-maintenance/promo/${id}`)
  },
  savePromo (payload) {
    return api.post('/v1/maintenance/api/promo-maintenance/create-promo', payload)
  },
  updateApproverPromo (id, payload) {
    return api.put(`/v1/maintenance/api/promo-maintenance/update-promo-approver/${id}`, payload)
  },
  updateRequestorPromo (id, payload) {
    return api.put(`/v1/maintenance/api/promo-maintenance/update-promo-requestor/${id}`, payload)
  },
  updatePromoStatus (id, payload) {
    return api.put(`/v1/maintenance/api/promo-maintenance/update-status/${id}`, payload)
  },
  deletePromo (id) {
    return api.delete(`/v1/maintenance/api/promo-maintenance/delete-promo/${id}`)
  },
  toggleStatus (id, payload) {
    return api.put(`/v1/maintenance/api/promo-maintenance/update-status/${id}`, payload)
  },
  getPromoTypes () {
    return api.get('/v1/maintenance/api/promo-maintenance/promo-transaction-types-grid')
  },
  getFrequencies () {
    return api.get('/v1/maintenance/api/promo-maintenance/frequency-grid')
  },
  checkApprover (payload) {
    return api.post('/v1/maintenance/api/promo-maintenance/get-approver', payload)
  }
}
