<template>
  <v-form ref="form">
    <v-row class="mb-md-8">
      <v-col :cols="12">
        <strong>Transaction Details</strong>
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="transactionData.relocNumber"
          @input="convertToUpperCase('relocNumber')"
          class="ph-textbox"
          label="Reloc Number *"
          :rules="requiredRules"
          :disabled="!transactionData.provider"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="6">
        <v-select
          v-model="transactionData.routeType"
          :items="collections.routeTypes"
          class="ph-textbox"
          label="Route Type *"
          :rules="requiredRules"
          :disabled="!transactionData.provider"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="6">
        <v-select
          v-model="transactionData.flightType"
          @input="updateServiceCharge"
          :items="collections.flightTypes"
          class="ph-textbox"
          label="Flight Type *"
          :rules="requiredRules"
          :disabled="!transactionData.provider"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="6">
        <v-select
          v-model="transactionData.ticketStatus"
          @input="updateServiceCharge"
          :items="collections.ticketStatus"
          class="ph-textbox"
          label="Ticket Status *"
          :rules="requiredRules"
          :disabled="!transactionData.provider"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="transactionData.totalPax"
          @input="updateServiceCharge"
          class="ph-textbox"
          label="Total Pax *"
          type="number"
          :rules="requiredRules"
          :disabled="!transactionData.provider"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="transactionData.principalAmount"
          class="ph-textbox"
          label="Principal Amount *"
          type="number"
          :rules="requiredRules"
          :disabled="!transactionData.provider"
          hide-spin-buttons
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="transactionData.remarks"
          @input="convertToProperCase('remarks')"
          class="ph-textbox"
          :label="remarksLabel"
          :rules="remarksRules"
          :disabled="!transactionData.provider"
          outlined
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { getForeignRefNo, toProperCase, trim } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      transactionData: 'airlines/transactionData',
      serviceFees: 'airlines/serviceFees',
      collections: 'collections'
    }),
    remarksLabel () {
      if (this.transactionData.provider && this.transactionData.provider.name === 'Cebu Pacific') {
        return 'Remarks'
      } else {
        return 'Remarks *'
      }
    }
  },
  data () {
    return {
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      remarksRules: [
        v => (
          (this.transactionData.provider && this.transactionData.provider.name === 'Cebu Pacific') ||
          (this.transactionData.provider && this.transactionData.provider.name === 'Air Asia' && !!v)) ||
          'This field is required'
      ]
    }
  },
  methods: {
    updateServiceCharge () {
      const { flightType, ticketStatus, totalPax } = this.transactionData

      if (flightType && ticketStatus && totalPax) {
        let serviceCharge = ''

        switch (ticketStatus) {
          case 'CONFIRMED':
          case 'RESERVED':
            if (flightType === 'DOM') {
              const charge = this.getServiceFee('Issuance DOM')

              if (charge !== '') {
                serviceCharge = charge * Number(totalPax)
              } else {
                serviceCharge = ''
              }
            } else {
              const charge = this.getServiceFee('Issuance INT')

              if (charge !== '') {
                serviceCharge = charge * Number(totalPax)
              } else {
                serviceCharge = ''
              }
            }
            break
          case 'ADD ON':
            if (flightType === 'DOM') {
              const charge = this.getServiceFee('Add Ons DOM')

              if (charge !== '') {
                serviceCharge = charge * Number(totalPax)
              } else {
                serviceCharge = ''
              }
            } else {
              const charge = this.getServiceFee('Add Ons INT')

              if (charge !== '') {
                serviceCharge = charge * Number(totalPax)
              } else {
                serviceCharge = ''
              }
            }
            break
          case 'REBOOKED':
          case 'CONFIRMED REBOOKED':
          case 'TO REBOOKED':
          case 'FOR REBOOK':
            if (flightType === 'DOM') {
              const charge = this.getServiceFee('Rebooking DOM')

              if (charge !== '') {
                serviceCharge = charge * Number(totalPax)
              } else {
                serviceCharge = ''
              }
            } else {
              const charge = this.getServiceFee('Rebooking INT')

              if (charge !== '') {
                serviceCharge = charge * Number(totalPax)
              } else {
                serviceCharge = ''
              }
            }
            break
          case 'FOR VOID':
          case 'FOR REFUND':
          case 'VOIDED':
            if (flightType === 'DOM') {
              const charge = this.getServiceFee('Cancellation DOM')

              if (charge !== '') {
                serviceCharge = charge * Number(totalPax)
              } else {
                serviceCharge = ''
              }
            } else {
              const charge = this.getServiceFee('Cancellation INT')

              if (charge !== '') {
                serviceCharge = charge * Number(totalPax)
              } else {
                serviceCharge = ''
              }
            }
            break
          default:
            serviceCharge = ''
            break
        }

        this.$store.commit('airlines/SET_TRANSACTION_DATA', {
          serviceCharge
        })
      } else {
        this.$store.commit('airlines/SET_TRANSACTION_DATA', {
          serviceCharge: ''
        })
      }
    },
    getServiceFee (name) {
      const filteredServiceFee = this.serviceFees.filter(item => item.service_fee_name === name)

      if (filteredServiceFee.length === 1) {
        return filteredServiceFee[0].amount
      } else {
        return ''
      }
    },
    convertToUpperCase (field) {
      this.transactionData[field] = this.transactionData[field].toUpperCase()
    },
    convertToProperCase (field) {
      this.transactionData[field] = toProperCase(trim(this.transactionData[field]))
    }
  },
  mounted () {
    const refNo = getForeignRefNo()
    this.transactionData.referenceNumber = refNo.toUpperCase()
  }
}
</script>
