<template>
  <div>
    <div class="ph-header d-sm-flex flex align-center">
      <h1 class="ph-header-title white--text">{{title}}</h1>
      <img class="ph-header-image" :src="require('../../assets/images/' + img + '.svg')" alt="">
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
export default {
  name: 'Header',
  props: {
    title: {
      type: String
    },
    img: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  methods: {
    formatDateTime (date) {
      return format(parseISO(date), 'yyyy-MM-dd')
    }
  }
}
</script>
<style scoped>
#branchHeader {
  margin-left: 2%;
  padding: 1%;
  font-weight: bold;
}

#branchHeaderDate {
  margin-right: 2%;
  padding: 1%;
  font-weight: bold;
  text-align: right;
}
</style>
