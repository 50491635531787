<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-form ref="form">
      <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
        <v-col :cols="4">
          <v-row>
            <v-col class="mb-md-2" :cols="12">
              <h6 class="text-h6 custom-heading primary--text">
                Generate Report
              </h6>
            </v-col>
            <v-col :cols="12">
              <v-select
                v-model="formData.reportType"
                :items="reportTypes"
                class="ph-textbox"
                label="Report Type"
                :rules="requiredRules"
                return-object
                outlined
              />
            </v-col>
            <v-col :cols="12" v-if="typeof formData.reportType === 'object' && formData.reportType.value !== 'flagged-transactions-report'">
              <v-select
                v-model="formData.provider"
                :items="collections.providers"
                item-text="provider_name"
                item-value="id"
                :rules="requiredRules"
                class="ph-textbox"
                label="Provider"
                return-object
                outlined
              />
            </v-col>
            <v-col :cols="12" v-if="typeof formData.reportType === 'object' && formData.reportType.value === 'request-logs-report'">
              <v-select
                v-model="formData.limitType"
                :items="limitTypes"
                :rules="requiredRules"
                class="ph-textbox"
                label="Limit Type"
                outlined
              />
            </v-col>
            <v-col :cols="12" v-if="typeof formData.reportType === 'object' && formData.reportType.value === 'request-logs-report'">
              <v-select
                v-model="formData.logType"
                :items="logTypes"
                :rules="requiredRules"
                class="ph-textbox"
                label="Log Type"
                outlined
              />
            </v-col>
            <v-col :cols="12" v-if="typeof formData.reportType === 'object' && formData.reportType.value === 'request-logs-report'">
              <v-autocomplete
                v-model="formData.location"
                :items="collections.locations"
                item-text="location_name"
                item-value="location_id"
                :rules="requiredRules"
                class="ph-textbox"
                label="Branch"
                return-object
                outlined
              />
            </v-col>
            <v-col class="mt-4" :cols="6">
              <v-btn @click="cancel" class="font-weight-bold rounded-lg" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col class="mt-4" :cols="6">
              <v-btn @click="generate" class="font-weight-bold rounded-lg" color="primary" block x-large>
                Generate
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      collections: 'collections'
    })
  },
  data () {
    return {
      formData: {
        reportType: '',
        provider: '',
        location: '',
        limitType: '',
        logType: ''
      },
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Monitoring',
          disabled: false,
          exact: true,
          to: '/transaction-limit/monitoring'
        },
        {
          text: 'Generate Report',
          disabled: true
        }
      ],
      reportTypes: [
        { text: 'Customer Limits', value: 'customer-limits-report' },
        { text: 'Flagged Transactions', value: 'flagged-transactions-report' },
        { text: 'Location Limits', value: 'location-limits-report' },
        { text: 'Request Logs', value: 'request-logs-report' }
      ],
      limitTypes: [
        { text: 'Customer', value: 'CUSTOMER' },
        { text: 'Location', value: 'LOCATION' }
      ],
      logTypes: [
        { text: 'Default', value: 'DEFAULT' },
        { text: 'Increase', value: 'INCREASE' }
      ]
    }
  },
  methods: {
    async generate () {
      if (this.$refs.form.validate()) {
        if (this.formData.reportType.value === 'location-limits-report' || this.formData.reportType.value === 'customer-limits-report') {
          const payload = {
            service_name: this.formData.provider.service_name,
            provider_code: this.formData.provider.provider_code
          }

          const reportData = {
            name: this.formData.reportType.text,
            type: this.formData.reportType.value,
            branch: 'ALL',
            payload
          }

          const convertData = Buffer.from(JSON.stringify(reportData)).toString('base64')
          window.open(`/transaction-limit/monitoring/generate-report/preview?genrpt=${convertData}`, '_blank')
        }

        if (this.formData.reportType.value === 'request-logs-report') {
          const payload = {
            provider_code: this.formData.provider.provider_code,
            trxlimit_type: this.formData.limitType,
            log_type: this.formData.logType,
            location_id: this.formData.location.location_id
          }

          const reportData = {
            name: this.formData.reportType.text,
            type: this.formData.reportType.value,
            branch: this.formData.location.location_name,
            payload
          }

          const convertData = Buffer.from(JSON.stringify(reportData)).toString('base64')
          window.open(`/transaction-limit/monitoring/generate-report/preview?genrpt=${convertData}`, '_blank')
        }

        if (this.formData.reportType.value === 'flagged-transactions-report') {
          const reportData = {
            name: this.formData.reportType.text,
            type: this.formData.reportType.value,
            branch: 'ALL'
          }

          const convertData = Buffer.from(JSON.stringify(reportData)).toString('base64')
          window.open(`/transaction-limit/monitoring/generate-report/preview?genrpt=${convertData}`, '_blank')
        }
      } else {
        renderToast('error', 'Incomplete Fields', 'Please complete the form to continue')
      }
    },
    cancel () {
      this.$router.push('/transaction-limit/monitoring')
    }
  },
  async beforeMount () {
    await this.$store.dispatch('getProviders')
    await this.$store.dispatch('getAllLocations', 0)
  }
}
</script>

<style scoped>
.custom-heading {
  font-family: 'Baloo Extra Bold' !important;
}
</style>
