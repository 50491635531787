<template>
  <div class="px-md-2">
    <h2 class="pt-md-6 custom-heading primary--text">
      Success!
    </h2>
    <p class="pt-md-4 pb-md-6">
      Thank you. {{ transactionType }} transaction has been completed.
    </p>
    <h3 class="custom-heading">
      Transaction Summary
    </h3>
    <div class="mt-md-6" style="max-width: 350px">
      <v-row no-gutters>
        <v-col :cols="6">
          Transaction Type
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionType }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Rate Type
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ rateCategory }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Rate Amount
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ rateAmount }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Amount to Receive
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ receiveAmount }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Amount to Release
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ releaseAmount }}
          <v-divider class="my-md-2" />
        </v-col>
      </v-row>
    </div>
    <div class="mt-6">
      <v-btn
        @click="backToMain"
        class="rounded-lg"
        color="primary"
        large
      >
        Back to Pera Palit
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  toProperCase,
  toMoney
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      transactionData: 'moneyChange/transactionData',
      successData: 'moneyChange/successData',
      rates: 'moneyChange/rates'
    }),
    transactionType () {
      return toProperCase(this.transactionData.type)
    },
    rateCategory () {
      return this.rateData(this.transactionData.rateID).category
    },
    rateAmount () {
      if (this.transactionData.type === 'BUY') {
        return toMoney(this.rateData(this.transactionData.rateID).buy_am_rate)
      }
      return toMoney(this.rateData(this.transactionData.rateID).sell_am_rate)
    },
    receiveAmount () {
      return toMoney(this.transactionData.receiveAmount, this.transactionData.receiveCurrency)
    },
    releaseAmount () {
      return toMoney(this.transactionData.releaseAmount, this.transactionData.releaseCurrency)
    }
  },
  methods: {
    backToMain () {
      this.$store.dispatch('moneyChange/resetForm')
      this.$router.push({ path: '/pera-palit' })
    },
    rateData (rateID) {
      const filter = this.rates.filter(item => item.mc_rate_id === rateID)

      if (filter.length === 1) return filter[0]
      else return []
    }
  },
  mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Transaction Successful')
  }
}
</script>

<style scoped>
h3,
p {
  color: #4A4A4A !important;
}
.icon {
  width: 20%;
}
</style>
