const SET_REPORTS_STRUCTURE = (state, payload) => {
  state.reportsStructure = payload
}

const SET_REMCO_DATA = (state, payload) => {
  state.remcoDatas = payload
}

const SET_RH_DATA = (state, payload) => {
  state.rhDatas = payload
}

const SET_USER_BAL_DATA = (state, payload) => {
  state.userBalanceDatas = payload
}

export default {
  SET_REPORTS_STRUCTURE,
  SET_REMCO_DATA,
  SET_RH_DATA,
  SET_USER_BAL_DATA
}
