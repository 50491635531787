export default {
  SET_SELECTED_PROMO (state, payload) {
    state.selectedPromo = payload
  },
  SET_IS_APPROVER (state, payload) {
    state.isApprover = payload
  },
  SET_CURRENT_PAGE (state, payload) {
    state.currentPage = payload
  },
  SET_FORM_DATA (state, payload) {
    state.formData = {
      ...state.formData,
      ...payload
    }
  },
  SET_PROMO_TYPES (state, payload) {
    state.promoTypes = [...payload]
  },
  SET_FREQUENCIES (state, payload) {
    state.frequencyList = [...payload]
  }
}
