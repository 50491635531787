<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ph-transaction-container">
        <div class="ph-wu-search-page-container">
          <v-col cols="12">
            <v-form ref="oboForm" @submit.prevent="setRemoteLocation">
              <v-row class="ph-wu-search-container">
                <v-col cols="12" md="4" class="ph-wu-search-header">
                  <h1 class="ph-wu-form-title">Agent Details</h1>
                  <p class="ph-wu-form-subtitle">{{type}} money for my agent ({{location}})</p>
                </v-col>
                <v-col cols="12" md="8" class="ph-wu-search-form">
                  <v-row class="ph-wu-search-fields ph-wu-customer-search">
                    <v-col cols="12">
                      <b>Agent Information</b>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        @change="getRemoteUsersList($event)"
                        v-model="remoteLocation"
                        label="Remote Location"
                        :items="remoteLocations"
                        item-text="location_name"
                        return-object
                        color="gray darken-1"
                        class="ph-textbox"
                        :rules="requiredField"
                        outlined
                      >
                        <template v-slot:item="{ item }">
                          <div>
                            {{ item.location_name }}
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="remoteUser"
                        label="Remote User"
                        :items="remoteUsers"
                        item-text="first_name"
                        item-value="user_id"
                        color="gray darken-1"
                        class="ph-textbox"
                        :rules="requiredField"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="otp"
                        class="ph-textbox"
                        single-line
                        outlined
                        placeholder="OTP"
                        persistent-hint
                        :rules="requiredField"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ph-wu-search-fields ph-wu-action-container">
                    <v-col cols="12" class="ph-wu-action-item">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        elevation="0"
                        class="ph-primary-button ph-primary-execute-btn"
                        @click="setRemoteLocation"
                        :disabled="!remoteLocation || !remoteUser || !otp"
                      >
                        validate remote user
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </div>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getForeignRefNo, renderToast } from '@/utils'

export default {
  name: 'OBO',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Remote Location',
          disabled: true,
          href: '#'
        }
      ],
      remoteLocation: '',
      remoteUser: '',
      otp: '',
      ftid: '',
      currency: '',
      type: '',
      location: '',
      module: '',
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      remoteLocations: 'obo/remoteLocations',
      remoteUsers: 'obo/remoteUsers'
    })
  },
  beforeMount () {
    console.log(this.$route.query.trx_type)

    switch (this.$route.query.trx_type) {
      case 'SO':
        this.type = 'Send'
        break
      case 'PO':
        this.type = 'Receive'
        break
      case 'SOF':
        this.type = 'Fulfill Send'
        break
      case 'POF':
        this.type = 'Fulfill Receive'
        break
      case 'QP':
        this.type = 'Quick Pay'
        break
    }

    switch (this.$route.query.trx_type) {
      case 'SO':
      case 'SOF':
        this.items = [
          { text: 'Dashboard', disabled: false, href: '/' },
          { text: 'Pera Remit', disabled: false, href: '/pera-remit' },
          { text: 'Send Money', disabled: false, href: '/pera-remit/send' },
          { text: 'Remote Location', disabled: true, href: '#' }
        ]

        this.module = 'send'
        break
      case 'PO':
      case 'POF':
        this.items = [
          { text: 'Dashboard', disabled: false, href: '/' },
          { text: 'Pera Remit', disabled: false, href: '/pera-remit' },
          { text: 'Receive Money', disabled: false, href: '/pera-remit/receive' },
          { text: 'Remote Location', disabled: true, href: '#' }
        ]

        this.module = 'receive'
        break
      case 'QP':
        this.items = [
          { text: 'Dashboard', disabled: false, href: '/' },
          { text: 'Pera Remit', disabled: false, href: '/pera-remit' },
          { text: 'Quick Pay', disabled: false, href: '/pera-remit/quickpay' },
          { text: 'Remote Location', disabled: true, href: '#' }
        ]

        this.module = 'quickpay'
        break
    }

    console.log(this.currUser)

    this.location = this.currUser.location_name
    this.$store.dispatch('obo/getRemoteLocations', this.currUser.location_group_id)
    this.$store.dispatch('obo/getRemoteUsers', this.currUser.location_id)
  },
  mounted () {
  },
  methods: {
    getForeignRefNo,
    renderToast,
    async getRemoteUsersList (data) {
      this.ftid = data.obo_ft_id
      console.log(this.ftid)
      this.$store.commit('obo/SET_REMOTE_USERS', [])
      this.$store.dispatch('obo/getRemoteUsers', data.location_id)
    },
    async setRemoteLocation () {
      const validate = this.$refs.oboForm.validate()

      if (validate) {
        const payload = {
          user_id: this.remoteUser,
          otp: this.otp,
          remote_location: this.remoteLocation,
          ftid: this.ftid,
          trx_type: this.$route.query.trx_type,
          module: this.module
        }

        await this.$store.dispatch('obo/getRemoteData', payload).then(res => {
          if (res) {
            switch (this.$route.query.trx_type) {
              case 'SO':
                this.$router.push('/pera-remit/send/new?is_remote=true')
                break
              case 'PO':
                this.$router.push('/pera-remit/receive/new-wu-trx?is_remote=true')
                break
              case 'SOF':
                this.$router.push('/pera-remit/send/new-fulfill-trx?is_remote=true')
                break
              case 'POF':
                this.$router.push('/pera-remit/receive/new-fulfill-trx?is_remote=true')
                break
              case 'QP':
                this.$router.push('/pera-remit/quickpay/new?is_remote=true')
                break
            }
          } else {
            this.renderToast('error', 'Error', 'The OTP is invalid or expired. Please Try Again')
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.renderToast('error', 'Remote User Validate Error', 'You must complete the fields to continue.')
      }
    }
  }
}
</script>
<style scoped>
  .ph-wu-form-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-wu-search-header {
    margin-top: 10px;
  }

  .ph-wu-search-fields {
    margin-top: 20px;
  }

  .ph-wu-currency-select-title {
    font-family: 'Proxima Nova Bold';
    padding-top: 18px;
  }

  .ph-wu-currency-selector .v-input {
    margin-top: 0
  }

  .ph-wu-customer-search {
    margin-top: 0;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
    width: 264px;
  }

  .ph-wu-action-item {
    display: flex;
  }

  @media only screen and (min-width: 600px) {
    .ph-primary-button {
      width: 160px;
      margin-left: auto;
    }

    .ph-transaction-container {
      padding: 0 25px;
    }
  }
</style>
