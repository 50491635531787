<template>
  <v-container fluid>
    <v-breadcrumbs class="mt-sm-4 mx-sm-2 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mt-2 mt-sm-4 mt-md-6" no-gutters>
      <v-col class="px-2 px-md-4" :cols="12">
        <v-card class="custom-card px-0 pb-8">
          <v-tabs v-model="currentTab" class="px-6" height="60px">
            <v-tabs-slider />
            <v-tab>Personal</v-tab>
            <v-tab>Contact</v-tab>
            <v-tab>Address</v-tab>
            <v-tab>Financial</v-tab>
            <v-tab>ID Cards</v-tab>
            <v-tab>Documents</v-tab>
            <v-tab>e-Signature</v-tab>
          </v-tabs>
          <div>
            <personal-tab ref="personalForm" :class="['d-none', { 'd-block': currentTab === 0 }]" />
            <contact-tab ref="contactForm" :class="['d-none', { 'd-block': currentTab === 1 }]" @showDuplicateModal="showDuplicateModal" />
            <address-tab ref="addressForm" :class="['d-none', { 'd-block': currentTab === 2 }]" />
            <financial-tab ref="financialForm" :class="['d-none', { 'd-block': currentTab === 3 }]" />
            <identification-cards-tab ref="idCardsForm" :class="['d-none', { 'd-block': currentTab === 4 }]" />
            <documents-tab ref="documentsForm" :class="['d-none', { 'd-block': currentTab === 5 }]" />
            <e-signature-tab ref="eSignatureForm" :class="['d-none', { 'd-block': currentTab === 6 }]" />
          </div>
          <v-row class="px-12 pt-4" no-gutters>
            <v-col :cols="6">
              <v-btn
                @click="nextTab"
                class="mr-sm-2 mr-md-4 rounded-lg"
                v-if="currentTab < 6"
                outlined
                x-large
              >
                Next
              </v-btn>
            </v-col>
            <v-col :cols=6>
              <div class="d-flex flex-row justify-end">
                <v-btn
                  @click="confirmSave"
                  class="text-bold mr-sm-2 mr-md-4 rounded-lg"
                  color="primary"
                  width="150px"
                  x-large
                >
                  SAVE
                </v-btn>
                <v-btn
                  @click="doCancel"
                  class="rounded-lg"
                  color="primary"
                  outlined
                  x-large
                >
                  CANCEL
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="confirmDialog"
      scrollable
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Customer Saving
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center py-12">
          <span class="caption">
            <h3>
              Are you sure you want to add this customer?
            </h3>
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            class="ph-action-button"
            @click="doSave"
          >
            Confirm
          </v-btn>
          <v-btn
            text
            class="ph-action-button"
            @click="confirmDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DuplicateCustomerModal ref="duplicateCustomerModal" v-bind:isDuplicate.sync="isDuplicate" v-bind:duplicateCustomers.sync="duplicateCustomers" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { serialize } from 'object-to-formdata'
import PersonalTab from '@/components/customers/PersonalTab.vue'
import ContactTab from '@/components/customers/ContactTab.vue'
import AddressTab from '@/components/customers/AddressTab.vue'
import FinancialTab from '@/components/customers/FinancialTab.vue'
import IdentificationCardsTab from '@/components/customers/IdentificationCardsTab.vue'
import DocumentsTab from '@/components/customers/DocumentsTab.vue'
import ESignatureTab from '@/components/customers/ESignatureTab.vue'
import wacomMixin from '@/mixins/wacom.js'
import { renderToast } from '@/utils'
import { format } from 'date-fns'
import DuplicateCustomerModal from '@/components/customers/DuplicateCustomerModal.vue'

export default {
  mixins: [wacomMixin],
  computed: {
    ...mapGetters({
      formData: 'customers/formData',
      selectedMobileUser: 'customers/selectedMobileUser',
      currUser: 'auth/currUser',
      collections: 'collections',
      eonNatureOfWork: 'debitCard/eonNatureOfWork',
      eonEmployment: 'debitCard/eonEmployment',
      eonSourceOfFund: 'debitCard/eonSourceOfFund'
    })
  },
  components: {
    PersonalTab,
    ContactTab,
    AddressTab,
    FinancialTab,
    IdentificationCardsTab,
    DocumentsTab,
    ESignatureTab,
    DuplicateCustomerModal
  },
  data () {
    return {
      confirmDialog: false,
      currentTab: 0,
      persistData: false,
      redirectURL: '/customers',
      module: 'customer',
      isDuplicate: false,
      breadcrumbsItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Customers',
          disabled: false,
          href: '/customers'
        },
        {
          text: 'New Customer',
          disabled: true
        }
      ],
      duplicateCustomers: []
    }
  },
  methods: {
    renderToast,
    confirmSave () {
      const personalForm = this.$refs.personalForm.$refs.form.validate()
      const contactForm = this.$refs.contactForm.$refs.form.validate()
      const addressForm = this.$refs.addressForm.$refs.form.validate()
      const financialForm = this.$refs.financialForm.$refs.form.validate()
      const idCardsForm = this.$refs.idCardsForm.$refs.form.validate()
      const documentsForm = this.$refs.documentsForm.$refs.form.validate()

      if (personalForm && contactForm && addressForm && financialForm && idCardsForm && documentsForm) {
        if (this.currUser.has_wacom === true) {
          if (this.formData.customer_signatures.length === 3) {
            this.confirmDialog = true
          } else {
            this.renderToast('error', 'Saving Failed', 'Must capture 3 specimen signatures')
          }
        } else {
          this.confirmDialog = true
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please complete the form')
      }
    },
    async doSave () {
      this.$store.commit('wacom/SET_WACOM_IDLE_DIALOG', true)
      var finalSignatures = []
      const newRequestID = this.formData.newRequestID
      this.formData.customer_signatures.forEach(function (e) {
        finalSignatures.push(e.substr(e.indexOf(',') + 1))
      })
      this.formData.customer_signatures = finalSignatures
      this.formData.updated_by = this.currUser.user_id
      this.formData.updated_loc = this.currUser.location_id
      this.formData.branchcode = this.currUser.location_code

      delete this.formData.LastTransaction
      delete this.formData.newRequestID

      const formData = serialize(this.formData)

      const res = await this.$store.dispatch('customers/doSave', formData)

      if (res.code === 200) {
        if (this.$route.query.standalone && newRequestID) {
          await this.$store.dispatch('customers/deleteNewRequest', newRequestID)
        }

        this.renderToast('success', 'Saving Successful', 'New customer has been added successfully')

        if (this.persistData) {
          this.$store.commit('customers/SET_SELECTED_CUSTOMER', res.result)
        }

        this.$store.dispatch('customers/resetForm')
        this.$refs.personalForm.$refs.form.resetValidation()
        this.$refs.contactForm.$refs.form.resetValidation()
        this.$refs.addressForm.$refs.form.resetValidation()
        this.$refs.financialForm.$refs.form.resetValidation()
        this.$refs.idCardsForm.$refs.form.resetValidation()
        this.$refs.documentsForm.$refs.form.resetValidation()

        this.$router.push(this.redirectURL)
      } else {
        this.renderToast('error', 'Saving Failed', 'Failed to add new customer')
      }
    },
    doCancel () {
      this.$store.commit('wacom/SET_WACOM_IDLE_DIALOG', true)
      this.$router.push(this.redirectURL)
    },
    nextTab () {
      if (this.currentTab < 6) this.currentTab++
    },
    showDuplicateModal (value, data) {
      this.isDuplicate = value
      this.duplicateCustomers = data
    }
  },
  mounted () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
  },
  beforeMount () {
    [
      'getPurposeOfTransaction',
      'getOccupation',
      'getIDList'
    ].forEach(action => this.$store.dispatch(action))

    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'persistData')) {
      this.persistData = (String(this.$route.query.persistData).toLowerCase() === 'true')
    }

    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'redirectURL')) {
      if (this.$route.query.redirectURL) {
        this.redirectURL = this.$route.query.redirectURL
      }
    }

    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'module')) {
      if (this.$route.query.module) {
        this.module = this.$route.query.module
      }
    }

    if (this.$route.query.standalone && !this.formData.newRequestID) {
      this.$router.push({
        path: '/customers'
      })
    }

    if (!this.$route.query.standalone) {
      this.$store.commit('customers/SET_FORM_DATA', {
        first_name: '',
        middle_name: '',
        last_name: '',
        birth_date: '',
        address: '',
        municipality: '',
        province: '',
        zip_code: '',
        tel_no: '',
        mobile_no: '',
        email_add: '',
        updated_by: '',
        updated_loc: '',
        ip_address: '',
        gender: '',
        img: '',
        img_file: [],
        occupation: '',
        civil_status: '',
        nationality: 'Filipino',
        source_fund: '',
        employer_name: '',
        nature_work: '',
        employment: '',
        street1: '',
        city1: '',
        province1: '',
        country1: 'Philippines',
        zip_code1: '',
        country_birth: 'Philippines',
        mobile_country_code: '',
        tags: '[]',
        risk_level: '',
        dpa_consent: '',
        is_corporate: '',
        edd_path: '',
        edd_path_file: [],
        barangay: '',
        barangay1: '',
        country: 'Philippines',
        birth_place: '',
        position_level: '',
        purpose_transaction: '',
        relation_to: '',
        compliance_edd_docs: '',
        compliance_edd_docs_file: [],
        branchcode: '',
        tag_no_middlename: '0',
        customer_signatures: [],
        customerDataConsent: []
      })
    }

    if (this.$route.query.isMobileUser) {
      if (this.selectedMobileUser !== null) {
        const customer = this.selectedMobileUser

        let civilStatus = ''
        const getEONOccupation = this.eonNatureOfWork.filter(res => res.eon_value_sent === customer.emp_occupation)
        const occupation = getEONOccupation[0].eon_description

        const getEONNature = this.eonNatureOfWork.filter(res => res.eon_value_sent === customer.emp_nature_work)
        const natureOfWork = getEONNature[0].eon_description

        const getEONEmployment = this.eonEmployment.filter(res => res.eon_value_sent === customer.emp_status)
        const employment = getEONEmployment[0].eon_description

        const getEONSourceOfFund = this.eonSourceOfFund.filter(res => res.eon_value_sent === customer.emp_source_of_fund)
        const sourceOfFund = getEONSourceOfFund[0].eon_description

        console.log(natureOfWork, customer.emp_occupation)

        switch (customer.civil_status) {
          case 'S':
            civilStatus = 'Single'
            break
          case 'M':
            civilStatus = 'Married'
            break
          case 'W':
            civilStatus = 'Widow/Er'
            break
          case 'D':
            civilStatus = 'Divorced'
            break
        }

        let customerIds = ''
        const imageFiles = []

        // check if customer has ID
        if (customer.valid_id !== '') {
          const dateToday = format(new Date(), 'yyyyMMdd')
          const randomNumber = Math.floor(Math.random() * (999 - 100 + 1)) + 100

          const currentIdType = customer.valid_id
          let selectedId = {}
          let selectedPurpose = ''
          const that = this

          let formValue = {
            first_name: customer.first_name,
            middle_name: customer.middle_name !== 'NA' ? customer.middle_name : '',
            last_name: customer.last_name,
            birth_date: customer.date_of_birth,
            address: customer.curr_street,
            municipality: customer.curr_city,
            province: customer.curr_province,
            zip_code: customer.curr_zip_code,
            tel_no: '',
            mobile_no: customer.mobile_no,
            email_add: customer.email_address,
            updated_by: '',
            updated_loc: '',
            ip_address: '',
            gender: customer.gender === 'M' ? 'male' : 'female',
            img: customerIds,
            img_file: imageFiles,
            civil_status: civilStatus,
            nationality: 'Filipino',
            street1: customer.perm_street,
            city1: customer.perm_city,
            province1: customer.perm_province,
            country1: 'Philippines',
            zip_code1: customer.perm_zip_code,
            country_birth: 'Philippines',
            mobile_country_code: '',
            tags: '[]',
            risk_level: '',
            dpa_consent: '',
            is_corporate: '',
            edd_path: '',
            edd_path_file: [],
            barangay: customer.curr_barangay,
            barangay1: customer.perm_barangay,
            country: 'Philippines',
            birth_place: customer.place_of_birth,
            compliance_edd_docs: '',
            compliance_edd_docs_file: [],
            branchcode: '',
            tag_no_middlename: customer.middle_name !== 'NA' ? '1' : '0',
            customer_signatures: [],
            occupation: occupation, // customer.occupation,
            source_fund: sourceOfFund,
            employer_name: customer.emp_business_name,
            nature_work: natureOfWork,
            employment: employment,
            position_level: customer.emp_position,
            purpose_transaction: customer.reason_for_account,
            relation_to: customer.relation_to,
            customerDataConsent: []
          }

          this.$store.commit('customers/SET_FORM_DATA', formValue)
          this.$store.commit('app/TOGGLE_LOADING', true)

          console.log(this.eonNatureOfWork)

          setTimeout(function () {
            that.collections.purposeOfTransaction.forEach(list => {
              if (list.index === customer.reason_for_account) {
                selectedPurpose = list
              }
            })

            that.collections.idList.forEach(list => {
              if (list.id_value.toLowerCase() === currentIdType.toLowerCase()) {
                selectedId = list
              }
            })

            const customerIdObject = {
              1: {
                img: `${dateToday}${randomNumber}.png`,
                id_type: selectedId.id_value,
                id_number: customer.valid_id_no,
                country: 'Philippines',
                expiry: customer.id_expired_date,
                issue: customer.id_issued_date,
                usp2: 'true'
              }
            }

            const dataURLtoFile = function (dataURL, fileName) {
              const base64 = dataURL.split(',')
              const mime = base64[0].match(/:(.*?);/)[1]
              const baseString = atob(base64[1])
              let n = baseString.length
              const u8arr = new Uint8Array(n)

              while (n--) {
                u8arr[n] = baseString.charCodeAt(n)
              }

              return new File([u8arr], fileName, { type: mime })
            }

            const file = dataURLtoFile(`data:text/plain;base64, ${customer.uploaded_id}`, `${dateToday}${randomNumber}.png`)
            imageFiles.push(file)
            customerIds = JSON.stringify(customerIdObject)

            const updatedDataValues = {
              img_file: imageFiles,
              img: customerIds,
              purpose_transaction: selectedPurpose.purpose
            }

            formValue = Object.assign({}, formValue, updatedDataValues)

            that.$store.commit('customers/SET_FORM_DATA', formValue)
            that.$store.commit('app/TOGGLE_LOADING', false)
          }, 2000)
        }
      }
    }
  }
}
</script>
