export default {
  cardInventory: state => state.cardInventory,
  debitCardTransactions: state => state.debitCardTransactions,
  debitCardCurrentTrx: state => state.debitCardCurrentTrx,
  debitCardCurrentPage: state => state.debitCardCurrentPage,
  debitCardPartners: state => state.debitCardPartners,
  eonSourceOfFund: state => state.eonSourceOfFund,
  eonEmployment: state => state.eonEmployment,
  eonNatureOfWork: state => state.eonNatureOfWork,
  eonIDList: state => state.eonIDList,
  isCustomerHit: state => state.isCustomerHit,
  showSpiel: state => state.showSpiel
}
