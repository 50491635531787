export default {
  currentPage: 0,
  rates: [],
  successData: null,
  transactionData: {
    formNumber: '',
    type: '',
    currencyID: 2,
    receiveAmount: '',
    receiveCurrency: '',
    releaseAmount: '',
    releaseCurrency: '',
    rateID: ''
  },
  currencies: [
    { text: 'USD', value: 2 }
  ],
  alert: {
    isVisible: false,
    type: '',
    message: ''
  }
}
