<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 px-md-5 mb-md-12" no-gutters>
      <v-col class="mt-2 mt-sm-4 mt-md-7" :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          class="custom-table"
          calculate-widths
        />
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="newEntry"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      items: 'mcRate/items'
    })
  },
  data () {
    return {
      isDeletePromptOpened: false,
      selectedItem: null,
      breadcrumbsItems: [
        {
          text: 'MC Rate',
          disabled: false,
          exact: true,
          to: '/mc-rate'
        },
        {
          text: 'General Setup',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Date Encoded',
          sortable: true,
          value: 'date_updated',
          width: '150px'
        },
        {
          text: 'Rate Category',
          sortable: true,
          value: 'category',
          width: '150px'
        },
        {
          text: 'Buying Rate',
          sortable: true,
          value: 'buy_am_rate',
          width: '150px'
        },
        {
          text: 'Selling Rate',
          sortable: true,
          value: 'sell_am_rate',
          width: '150px'
        },
        {
          text: 'Trade Rate',
          sortable: true,
          value: 'trade_rate',
          width: '150px'
        },
        {
          text: 'Quantity',
          sortable: true,
          value: 'quantity',
          width: '150px'
        },
        {
          text: 'Encoded By',
          sortable: true,
          value: 'encoded_by',
          width: '150px'
        },
        {
          text: 'Effective Date',
          sortable: true,
          value: 'trx_date',
          width: '150px'
        }
      ]
    }
  },
  methods: {
    newEntry () {
      this.$router.push('/mc-rate/setup/new')
    },
    async retrieveData () {
      try {
        const { code } = await this.$store.dispatch('mcRate/retrieveData', {
          trxDate: this.currUser.trx_date
        })

        if (code !== 200) {
          renderToast('error', 'Request Error', 'Failed to retrieve grid')
        }
      } catch (e) {
        renderToast('error', 'USP Error', 'Failed to retrieve grid')
      }
    }
  },
  mounted () {
    this.retrieveData()
  }
}
</script>
