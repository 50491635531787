<template>
  <div>
    <v-dialog
      v-model="duplicateCustomerDialog"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Duplicate Customer Found
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-left py-12">
          <p>The details provided already exists with other customers</p>
          <br>
          <v-data-table
            class="ph-clickable-table"
            :headers="duplicateCustomerHeaders"
            :items="results"
            no-data-text="No customers found"
          >
            <!-- @click:row="doSelectUpdate" -->
            <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.tel_no`]="{ item }">
              {{ item.tel_no || '-' }}
            </template>
            <template v-slot:[`item.mobile_no`]="{ item }">
              {{ item.mobile_no || '-' }}
            </template>
            <template v-slot:[`item.email_add`]="{ item }">
              {{ item.email_add || '-' }}
            </template>
            <template v-slot:[`item.LastTransaction`]="{ item }">
              {{ item.LastTransaction || '-' }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            class="ph-action-button"
            @click="confirmDialog = true"
          >
            Fix Duplicate Details
          </v-btn>
          <v-btn
            text
            class="ph-action-button"
            @click="closeDialog"
          >
            I want to use another number
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Confirm Action
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to fix the duplicate details?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="confirmDialog = false"
            class="ph-action-button"
          >
            No
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="fixDuplicateDetails"
            class="ph-action-button"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  props: {
    isDuplicate: Boolean,
    duplicateCustomers: Array
  },
  data () {
    return {
      duplicateCustomerDialog: false,
      isUpdating: false,
      confirmDialog: false,
      duplicateCustomerHeaders: [
        {
          text: 'CRN',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Telephone',
          sortable: true,
          value: 'tel_no'
        },
        {
          text: 'Mobile',
          sortable: true,
          value: 'mobile_no'
        },
        {
          text: 'Email',
          sortable: true,
          value: 'email_add'
        },
        {
          text: 'Last Transaction',
          sortable: true,
          value: 'LastTransaction'
        }
      ],
      results: []
    }
  },
  watch: {
    isDuplicate: {
      handler (data) {
        if (data) {
          this.duplicateCustomerDialog = true
        } else {
          this.duplicateCustomerDialog = false
        }
      },
      deep: true
    },
    duplicateCustomers: {
      handler (data) {
        this.results = data
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      formData: 'customers/formData',
      currUser: 'auth/currUser'
    })
  },
  mounted () {
    if (Object.prototype.hasOwnProperty.call(this.$route.params, 'crn')) {
      this.isUpdating = true
    } else {
      this.isUpdating = false
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    closeDialog () {
      if (this.isUpdating === false) {
        this.formData.mobile_no = ''
        this.formData.tel_no = ''
        this.formData.email_add = ''
      }

      this.$emit('update:isDuplicate', false)
      this.$emit('update:duplicateCustomers', [])
    },
    async fixDuplicateDetails () {
      const duplicateCust = this.duplicateCustomers
      const that = this
      let successFix = 0
      let failedFix = 0
      let iterate = 0

      duplicateCust.forEach(item => {
        const customerItem = item
        const updatedItems = {
          tel_no: '',
          mobile_no: '',
          tag_verified_mobile: '0',
          email_add: ''
        }

        const payload = Object.assign({}, customerItem, updatedItems)
        const result = this.fixDuplicate(payload)

        if (result) {
          successFix += 1
        } else {
          failedFix += 1
        }

        iterate += 1
      })

      if (duplicateCust.length === iterate) {
        if (successFix === duplicateCust.length && failedFix === 0) {
          this.renderToast('success', 'Fix Successful', 'Duplicated Details has been removed from other customers')
        } else if (failedFix === duplicateCust.length && successFix === 0) {
          this.renderToast('error', 'Removal Failed', 'Failed to removed duplicated details from other customers')
        } else {
          this.renderToast('warning', 'Fixed on some customers', 'Duplicated Details has been removed from some of the other customers')
        }

        setTimeout(function () {
          successFix = 0
          failedFix = 0
          that.confirmDialog = false
          that.closeDialog()
        }, 1000)
      }
    },
    async fixDuplicate (payload) {
      await this.$store.dispatch('customers/doUpdate', payload).then(res => {
        if (res.code === 200) {
          return true
        } else {
          return false
        }
      })
    }
  }
}
</script>
