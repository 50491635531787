import WacomSDK from '../assets/js/wacom/wgssStuSdk.js'
import store from '../store/index'

export default {
  methods: {
    checkForSigCaptX () {
      // Establishing a connection to SigCaptX Web Service can take a few seconds,
      // particularly if the browser itself is still loading/initialising
      // or on a slower machine.
      var retry = 0
      retry += 1
      if (WacomSDK.STU.isServiceReady()) {
        store.commit('auth/SET_HAS_WACOM_SDK', true)
        retry = 0
        console.log('SigCaptX Web Service: ready')
      } else {
        console.log('SigCaptX Web Service: not connected')
        store.commit('auth/SET_HAS_WACOM_SDK', false)
        if (retry < 50) {
          setTimeout(this.checkForSigCaptX, 1000)
        } else {
          // store.commit('auth/SET_HAS_WACOM_SDK', false)
          console.log('Unable to establish connection to SigCaptX')
        }
      }
    },

    checkForWacom () {
      WacomSDK.STU.isDCAReady()
        .then(function (message) {
          console.log(message)
          if (!message) {
            store.commit('auth/SET_HAS_WACOM', false)
            throw new this.DCANotReady()
          }
          // Set handler for Device Control App timeout
          WacomSDK.STU.onDCAtimeout = self.onDCAtimeout
          return WacomSDK.STU.getUsbDevices()
        })
        .then(function (message) {
          console.log(message)
          if (message === null || message.length === 0) {
            console.log('none')
            store.commit('auth/SET_HAS_WACOM', false)
          } else {
            console.log('has')
            store.commit('auth/SET_HAS_WACOM', true)
          }
        })
    }
  }
}
