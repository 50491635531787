<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5" no-gutters>
      <v-col :cols="12" :sm="4" :md="4">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-8" :cols="12">
        <v-data-table
          :headers="headers"
          :items="transactions"
          :search="search"
          class="custom-table"
          :calculate-widths="true"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDateTime(item.created_at) }}
          </template>
          <template v-slot:[`item.sender_name`]="{ item }">
            <v-avatar class="mr-1" size="26px" :style="avatarColor(item.sender_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.sender_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.sender_name) }}
          </template>
          <template v-slot:[`item.non_ex_name`]="{ item }">
            <v-avatar class="mr-1" size="26px" :style="avatarColor(item.non_ex_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.non_ex_name) }}</span>
            </v-avatar>
            {{ item.non_ex_name }}
          </template>
          <template v-slot:[`item.total_amount`]="{ item }">
            {{ toMoney(item.total_amount) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span :class="{ 'red--text': item.status === 0, 'green--text': item.status === 1}">
              <v-icon
                :class="{ 'red--text': item.status === 0, 'green--text': item.status === 1}"
                x-small
              >
                mdi-circle
              </v-icon>
              {{ getStatusText(item.status) }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.status === 1"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <!-- <v-list-item>
                  <v-list-item-title>Print OAR & ACR</v-list-item-title>
                </v-list-item> -->
                <v-list-item @click="printOAR(item) && currUser.location_type === '1'">
                  <v-list-item-title>Print OAR</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.status === 1 && checkIfUploaded(item)" @click="reprintACRNoWacom(item)">
                  <v-list-item-title>Print ACR</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.status === 1 && !checkIfUploadedPre(item)" @click="retrieveUploaded(item, 'pre')">
                  <v-list-item-title>Print Pre Print</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.status === 1 && !checkIfUploaded(item)" @click="retrieveUploaded(item, 'acr')">
                  <v-list-item-title>Print ACR</v-list-item-title>
                </v-list-item>
                <ACRUpload v-show="checkIfUploaded(item)" :item="item"/>
              </v-list>
            </v-menu>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.status === 0"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <span>Retry</span>
            </v-tooltip>
            <div style="display: flex; flex-direction: row;" v-if="false">
              <v-btn
                text
                color="primary"
                v-if="item.status === 1 && !checkIfUploaded(item)"
                @click="retrieveUploaded(item.control_number)"
                >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <v-btn
                text
                color="primary"
                v-if="item.status === 1 && checkIfUploaded(item)"
                @click="reprintACRNoWacom(item)"
                >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <ACRUpload v-show="checkIfUploaded(item)" :item="item"/>
              <!-- <v-btn class="d-none d-sm-inline" color="primary" icon title="Print Receipt">
                <v-icon>mdi-printer</v-icon>
              </v-btn> -->
              <!-- <v-btn class="d-inline d-sm-none" color="primary" text>
                <v-icon class="mr-1">mdi-printer</v-icon>
                Print Receipt
              </v-btn> -->
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-speed-dial
      v-model="fab"
      dark
      fixed
      bottom
      right
      class="mb-5"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="#1A2791"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="newQuickPayOBO"
            color="#D12C7F"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <img src="@/assets/images/send-money-obo-icon.svg" width="18" />
          </v-btn>
        </template>
        <span>Quick Pay for Different Location</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            light
            small
            v-bind="attrs"
            v-on="on"
            to="/pera-remit/quickpay/new"
          >
            <img src="@/assets/images/nonex-quickpay-icon.svg" width="18" />
          </v-btn>
        </template>
        <span>New Quick Pay Transaction</span>
      </v-tooltip>
    </v-speed-dial>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ACRUpload from '@/components/customers/ACRUpload'
import wacomMixin from '@/mixins/wacom.js'
import { format } from 'date-fns'
import {
  getStatusText,
  avatarColor,
  avatarInitial,
  toProperCase,
  toMoney
} from '@/utils'

export default {
  mixins: [wacomMixin],
  computed: {
    ...mapGetters({
      transactions: 'nonex/transactions',
      currUser: 'auth/currUser'
    })
  },
  components: {
    ACRUpload
  },
  data () {
    return {
      direction: 'top',
      hover: false,
      search: '',
      fab: false,
      transition: 'slide-y-reverse-transition',
      filteredACRs: [],
      breadcrumbsItems: [
        {
          text: 'PERA Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Quickpay',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'created_at',
          width: '140px'
        },
        {
          text: 'MTCN / Ref No.',
          sortable: true,
          value: 'control_number',
          width: '160px'
        },
        {
          text: 'Sender',
          sortable: true,
          value: 'sender_name',
          width: '200px'
        },
        {
          text: 'Send via',
          sortable: true,
          value: 'non_ex_name',
          width: '150px'
        },
        {
          text: 'Total Amount',
          sortable: true,
          value: 'total_amount',
          width: '150px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '130px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '90px'
        }
      ]
    }
  },
  methods: {
    customAvatarColor (name) {
      if (name === 'Western Union') {
        return 'background-color: #FFDD00'
      } else {
        return this.avatarColor(name)
      }
    },
    customAvatarInitial (name) {
      if (name === 'Western Union') {
        return 'WU'
      } else {
        return this.avatarInitial(name)
      }
    },
    async retrieveUploaded (item, type) {
      let filtered = {}
      if (type === 'acr') {
        filtered = this.filteredACRs.filter(function (e) {
          return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id && e.type === 'acr'
        })
      } else {
        filtered = this.filteredACRs.filter(function (e) {
          return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id && e.type === 'pre'
        })
      }

      console.log(filtered, 'filtered for print')
      const payload = {
        file: btoa(filtered[0].bucket_path)
      }
      console.log(payload)
      const res = await this.$store.dispatch('acr/retrieveACRFile', payload)
      console.log(res)
      var extension = res.result.extension
      var file = Buffer.from(res.result.file, 'base64')
      var blob = ''
      if (extension === 'pdf') {
        blob = new Blob([file], { type: 'application/pdf' })
      } else if (extension === 'jpg') {
        blob = new Blob([file], { type: 'image/jpg' })
      } else if (extension === 'png') {
        blob = new Blob([file], { type: 'image/png' })
      } else if (extension === 'jpeg') {
        blob = new Blob([file], { type: 'image/jpeg' })
      }
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
    },
    formatDateTime (date) {
      if (date) return format(new Date(date), 'yyyy-MM-dd h:mmaa')
      return ''
    },
    pdfConvert () {
      var decoded = Buffer.from(this.base64String, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
      // FileSaver.saveAs(blob, 'filename')
    },
    checkIfUploaded (item) {
      console.log(item, 'item from table')
      console.log(this.filteredACRs, 'filtered')
      const self = this
      var filtered = self.filteredACRs.filter(function (e) {
        return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id
      })
      if (filtered.length !== 0) {
        return false
      } else {
        return true
      }
    },
    checkIfUploadedPre (item) {
      console.log(item, 'item from table')
      console.log(this.filteredACRs, 'filtered')
      const self = this
      var filtered = self.filteredACRs.filter(function (e) {
        return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id && e.type === 'pre'
      })
      console.log(filtered, 'filtered pre')
      if (filtered.length !== 0) {
        return false
      } else {
        return true
      }
    },
    checkUploadedFiles () {
      const self = this
      console.log(self.filteredACRs, 'filteredACR')
      console.log(self.transactions)
      var transactionsWithACR = []
      self.transactions.forEach(function (e) {
        self.filteredACRs.forEach(function (f) {
          if (e.control_number === f.ref_number) {
            transactionsWithACR.push(e)
          }
        })
      })
      return transactionsWithACR
    },
    async printOAR (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: '2',
          or_series_no: '',
          oar_series_no: data.form_number,
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    },
    reprintACR (payload) {
      console.log(payload)
      var filtered = this.filteredACRs.filter(function (e) {
        return e.ref_number === payload
      })
      window.open(filtered[0].bucket_path, '_blank')

      if (this.currUser.location_type === '1') {
        this.printOAR(payload.form_number)
      }
    },
    newQuickPayOBO () {
      this.$router.push({
        path: '/pera-remit/remote-location?trx_type=QP'
      })
    },
    async reprintACRNoWacom (item) {
      let customerSignature = ''

      if (this.currUser.has_wacom) {
        await this.$store.dispatch('customers/retrieveSignatures', { customer_id: item.customer_id.toString() }).then(res => {
          if (res.code >= 200 && res.code <= 299) {
            if (res.result.length > 0) {
              customerSignature = res.result[0].bucket_path
            } else {
              customerSignature = ''
            }
          } else {
            customerSignature = ''
          }
        })
      }

      const that = this
      const printReceiptPayload = {}
      printReceiptPayload.print_data = {
        sender: item.sender_name,
        address: item.address + ' ' + item.barangay + ' ' + item.city + ' ' + item.province + ' ' + item.zip_code + ' ' + item.country,
        id_type: '',
        id_number: item.id_number,
        test_question: '',
        answer: '',
        sender_mobile_number: item.contact_number,
        receiver: item.receiver_name,
        r_city: '',
        r_country: '',
        service: '',
        message: item.delivery_message,
        receiver_mobile_number: '',
        mtcn: item.control_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
        date_time: this.formatDateTime(new Date()),
        agent_name: this.currUser.location_name + ' BRANCH',
        amount: item.principal_amount.toFixed(2),
        transfer_fee: parseInt(item.service_charge).toFixed(2),
        msg_charge: '',
        delivery_charge: '0.00',
        promo_code: '',
        discount: '',
        total: 'Philippine Peso ' + item.total_amount.toFixed(2),
        exchange_rate: item.exchange_rate,
        payout_amount: item.principal_amount.toFixed(2),
        promo_text_message: '',
        pin_text_message_set_2: '',
        want_to_register: '',
        mywu_number: '',
        mywu_points: '',
        total_mywu_points: '',
        adj_type: '',
        mc_rate: '',
        buyback_amount: '',
        total_amount: '',
        purpose_of_trxn: item.purpose_transaction,
        promo_flag: 'N',
        expiration_date: '',
        vuser_group_id: this.currUser.user_group_id
      }
      printReceiptPayload.data = {
        has_wacom: false,
        trx_type: 1,
        remco_name: 'Western Union',
        control_number: '',
        customer_id: item.customer_id.toString(),
        location_id: this.currUser.location_id,
        location_name_uploaded: this.currUser.location_name,
        crn: '-',
        uploaded_by: this.currUser.user_id,
        first_name: '-',
        last_name: '-',
        product: 'WU Send Money',
        receipt_type: 'acr'
      }

      if (this.currUser.has_wacom) {
        printReceiptPayload.print_data.fla_signature = this.currUser.userSignature
        printReceiptPayload.print_data.customer_signature = customerSignature
      }

      setTimeout(function () {
        const thatFunc = that
        that.$store.dispatch('wu/generateACRWU', printReceiptPayload).then((res) => {
          console.log(res)
          thatFunc.base64String = res.result
          thatFunc.pdfConvert()
        }).catch((error) => {
          console.log(error)
        })
      }, 3000)
    },
    toMoney,
    getStatusText,
    avatarColor,
    avatarInitial,
    toProperCase
  },
  beforeMount () {
    this.$store.dispatch('nonex/getTransactions', {
      trxDate: this.currUser.trx_date,
      locationID: this.currUser.location_id,
      trxType: 1,
      wuTrxCategory: 'QP'
    })
    this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
  },
  async mounted () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)

    const acrs = await this.$store.dispatch('acr/retrieveFilteredACR',
      {
        location_id: this.currUser.location_id,
        trx_date: this.currUser.trx_date.replace(' 00:00:00', ''),
        user_id: this.currUser.user_id
      })
    this.filteredACRs = acrs.data
    // that.checkUploadedFiles()
  }
}
</script>
