const isAuthenticated = state => state.isAuthenticated
const currUser = state => state.currUser
const token = state => state.token
const runSession = state => state.runSession
const authTimestamp = state => state.authTimestamp
const showRefreshPrompt = state => state.showRefreshPrompt
const isOTPpassed = state => state.isOTPpassed

export default {
  isAuthenticated,
  currUser,
  token,
  runSession,
  authTimestamp,
  showRefreshPrompt,
  isOTPpassed
}
