export default {
  billers: [],
  tpaId: '',
  peraDaliTransactions: [],
  peraDaliCurrentTrx: null,
  peraDaliCurrentPage: 0,
  collectionProviders: [],
  peraDaliResponseData: null,
  loanAccountNo: ''
}
