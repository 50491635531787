<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        class="ph-breadcrumbs"
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ph-transaction-container">
        <v-col cols="12" v-if="currentPage === 0">
          <DebitCardSearch />
        </v-col>
        <v-col cols="12" v-if="currentPage === 1">
          <DebitCardForm />
        </v-col>
        <v-col cols="12" v-if="currentPage === 2">
          <DebitCardSuccess />
        </v-col>
      </v-row>
      <CreateCustomerModal />
    </v-container>
 </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DebitCardSearch from '@/components/debitCard/DebitCardSearch'
import DebitCardForm from '@/components/debitCard/DebitCardForm'
import DebitCardSuccess from '@/components/debitCard/DebitCardSuccess'
import CreateCustomerModal from '@/components/customers/CreateCustomerModal'

export default {
  name: 'PeraBankNewTrx',
  components: {
    DebitCardSearch,
    DebitCardForm,
    DebitCardSuccess,
    CreateCustomerModal
  },
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Cards',
          disabled: false,
          href: '/cards'
        },
        {
          text: 'Pera Debit Card',
          disabled: false,
          href: '/cards/pera-debit-card/'
        },
        {
          text: 'New Transaction',
          disabled: true,
          href: '#'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'debitCard/debitCardCurrentPage'
    })
  },
  methods: {
  }
}
</script>
<style scoped>
  .ph-transaction-container .col {
    padding: 15px;
  }

  .ph-breadcrumbs {
    padding: 5px 5px;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }

    .ph-transaction-container .col {
      padding: 12px;
    }

    .ph-breadcrumbs {
      padding: 18px 12px;
      padding-left: 24px;
    }
  }
</style>
