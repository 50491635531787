export default {
  currentMonitoringView: null,
  showPromptDialog: false,
  showFlaggedPromptDialog: false,
  flaggedTransactionPayload: null,
  promptData: {
    type: '',
    transactionName: '',
    locationThreshold: 0,
    customerThreshold: 0
  },
  approverLevel: null,
  currentIncreaseRequestsView: null,
  selectedIncreaseLocationLimit: null,
  selectedIncreaseCustomerLimit: null,
  increaseLocationRequests: [],
  increaseCustomerRequests: [],
  currentApprovalRequestsView: null,
  selectedApprovalLocationLimit: null,
  selectedApprovalCustomerLimit: null,
  approvalLocationRequests: [],
  approvalCustomerRequests: [],
  currentView: null,
  selectedLocationLimit: null,
  selectedCustomerLimit: null,
  selectedApprover: null,
  selectedProvider: null,
  uploadedData: [],
  fileName: '',
  approvalLocationFormData: {
    branchID: '',
    provider: {
      provider_name: '',
      provider_code: '',
      service_name: '',
      service_type: ''
    },
    channel: '',
    amount: '',
    remarks: ''
  },
  approvalCustomerFormData: {
    customerID: '',
    customerName: '',
    provider: {
      provider_name: '',
      provider_code: '',
      service_name: '',
      service_type: ''
    },
    channel: '',
    amount: '',
    remarks: ''
  },
  increaseLocationFormData: {
    branchID: '',
    provider: {
      provider_name: '',
      provider_code: '',
      service_name: '',
      service_type: ''
    },
    channel: '',
    amount: '',
    remarks: ''
  },
  increaseCustomerFormData: {
    customerID: '',
    customerName: '',
    provider: {
      provider_name: '',
      provider_code: '',
      service_name: '',
      service_type: ''
    },
    channel: '',
    amount: '',
    remarks: ''
  },
  locationFormData: {
    branchID: '',
    provider: {
      provider_name: '',
      provider_code: '',
      service_name: '',
      service_type: ''
    },
    channel: '',
    amount: '',
    remarks: ''
  },
  customerFormData: {
    customerID: '',
    customerName: '',
    provider: {
      provider_name: '',
      provider_code: '',
      service_name: '',
      service_type: ''
    },
    channel: '',
    amount: '',
    remarks: ''
  },
  approverFormData: {
    userID: '',
    userName: '',
    userEmail: '',
    level: '',
    provider: {
      provider_name: '',
      provider_code: '',
      service_name: '',
      service_type: ''
    },
    channel: ''
  },
  providerFormData: {
    providerName: '',
    providerCode: '',
    serviceName: '',
    serviceType: '',
    minCustomerLimit: '',
    maxCustomerLimit: '',
    minLocationLimit: '',
    maxLocationLimit: '',
    channel: '',
    thresholdRecipient: ''
  }
}
