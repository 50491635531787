<template>
  <v-container class="px-0 pt-0" fluid>
    <Header title="Customers" img="customer-illustration" />
    <v-row class="mt-16 mb-4 pt-10 mx-2 mt-sm-4 pt-sm-0 mx-sm-2 mx-md-4" no-gutters>
      <v-col :cols="12" :sm="5" :md="4" class="pt-sm-2 ph-textbox-container">
        <v-form @submit.prevent="doSearch">
          <v-text-field
            v-model="searchData.keyword"
            class="ph-textbox"
            placeholder="Search Customer"
            append-icon="mdi-magnify"
            @click:append="doSearch"
            @input="hideResults"
            hint="Press ENTER or click the 🔍 to search"
            outlined
          />
        </v-form>
      </v-col>
    </v-row>
    <SearchResult v-if="searchData.keyword && searchData.results !== null" />
    <NewRequest />
    <RecentlyAdded />
    <v-fab-transition>
      <v-btn
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
        to="/customers/new"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '@/components/partials/Header.vue'
import SearchResult from '@/components/customers/SearchResult.vue'
import NewRequest from '@/components/customers/NewRequest.vue'
import RecentlyAdded from '@/components/customers/RecentlyAdded.vue'
import wacomMixin from '@/mixins/wacom.js'

export default {
  mixins: [wacomMixin],
  computed: {
    ...mapGetters({
      searchData: 'customers/searchData'
    })
  },
  components: {
    Header,
    SearchResult,
    NewRequest,
    RecentlyAdded
  },
  mounted () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    this.$store.commit('wacom/SET_WACOM_IDLE_DIALOG', true)
  },
  methods: {
    doSearch () {
      const formattedKeyword = []
      let keyword = this.searchData.keyword.split(',')

      for (let i = 0; i < keyword.length; i++) {
        formattedKeyword.push(keyword[i].replace(/(^\s+|\s+$)/g, ''))
      }

      keyword = formattedKeyword.join(',')

      if (keyword) {
        this.searchData.results = []
        this.$store.dispatch('customers/doSearch', keyword)
      } else {
        this.searchData.results = []
      }
    },
    hideResults () {
      this.searchData.results = null
    }
  }
}
</script>
