import { endpoints } from '@/api/endpoints'

const nonexAdj = endpoints.use('nonexAdj')

export default {
  getAdjApprovalList: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonexAdj.getAdjApprovalList(payload)
      .then(({ data }) => {
        commit('SET_ADJ_APPROVAL_LIST', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  customerCSLookup: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonexAdj.customerCSLookup(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getAdjRequestList: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonexAdj.getAdjRequestList(payload)
      .then(({ data }) => {
        commit('SET_ADJ_REQUEST_LIST', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  searchControlNumber: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonexAdj.searchControlNumber(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  confirmAdjustmentRequest: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonexAdj.confirmAdjustmentRequest(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  unpayUnitellerTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonexAdj.unpayUnitellerTrx(payload.data)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  unpayRemitlyTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonexAdj.unpayRemitlyTrx(payload.data)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  amendAyannahTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonexAdj.amendAyannahTrx(payload.data)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  cancelAyannahTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonexAdj.cancelAyannahTrx(payload.data)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateAdjustmentTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonexAdj.updateAdjustmentTrx(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
