import api from '@/api'

export default {
  remoteLocations (locationGroupId) {
    return api.get(`/v1/maintenance/api/remote/locations?location_group_id=${locationGroupId}`)
  },
  remoteUsers (locationId) {
    return api.get(`/v1/maintenance/api/remote/users?location_id=${locationId}`)
  },
  remoteValidate (payload) {
    return api.post('/v1/maintenance/api/remote/validate', payload)
  }
}
