const SET_WACOM_DIALOG = (state, payload) => {
  state.showWacom = payload
}

const SET_WACOM_CONSENT_DIALOG = (state, payload) => {
  state.showWacomConsent = payload
}

const SET_WACOM_DATA_CONSENT_FIRST_DIALOG = (state, payload) => {
  state.showWacomDataConsentFirst = payload
}

const SET_WACOM_DATA_CONSENT_SECOND_DIALOG = (state, payload) => {
  state.showWacomDataConsentSecond = payload
}

const SET_WACOM_DATA_CONSENT_THIRD_DIALOG = (state, payload) => {
  state.showWacomDataConsentThird = payload
}

const SET_WACOM_DATA_CONSENT_FOURTH_DIALOG = (state, payload) => {
  state.showWacomDataConsentFourth = payload
}

const SET_WACOM_DATA_CONSENT_FIFTH_DIALOG = (state, payload) => {
  state.showWacomDataConsentFifth = payload
}

const SET_WACOM_DATA_CONSENT_SIXTH_DIALOG = (state, payload) => {
  state.showWacomDataConsentSixth = payload
}

const SET_WACOM_DATA_CONSENT_SEVENTH_DIALOG = (state, payload) => {
  state.showWacomDataConsentSeventh = payload
}

const SET_WACOM_IDLE_DIALOG = (state, payload) => {
  state.showWacomIdle = payload
}

const SET_CLAUSE_VALUE = (state, payload) => {
  state.consentValues[payload.consent] = payload.value
}

const SET_CLAUSE_VALUE_DEFAULT = (state) => {
  state.consentValues = {
    first_clause: false,
    second_clause: false,
    third_clause: false,
    fourth_clause: false,
    fifth_clause: false
  }
}

export default {
  SET_WACOM_DIALOG,
  SET_WACOM_IDLE_DIALOG,
  SET_WACOM_CONSENT_DIALOG,
  SET_WACOM_DATA_CONSENT_FIRST_DIALOG,
  SET_WACOM_DATA_CONSENT_SECOND_DIALOG,
  SET_WACOM_DATA_CONSENT_THIRD_DIALOG,
  SET_WACOM_DATA_CONSENT_FOURTH_DIALOG,
  SET_WACOM_DATA_CONSENT_FIFTH_DIALOG,
  SET_WACOM_DATA_CONSENT_SIXTH_DIALOG,
  SET_WACOM_DATA_CONSENT_SEVENTH_DIALOG,
  SET_CLAUSE_VALUE,
  SET_CLAUSE_VALUE_DEFAULT
}
