import { render, staticRenderFns } from "./SendMoneyFulfill.vue?vue&type=template&id=81e0c558&scoped=true"
import script from "./SendMoneyFulfill.vue?vue&type=script&lang=js"
export * from "./SendMoneyFulfill.vue?vue&type=script&lang=js"
import style0 from "./SendMoneyFulfill.vue?vue&type=style&index=0&id=81e0c558&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81e0c558",
  null
  
)

export default component.exports