<template>
  <div class="px-2 px-md-2 mt-md-4 mb-16 pb-8 pb-md-0 mb-md-0">
    <v-btn @click="backToSearch" class="text-capitalize" text small>
      <v-icon class="mr-1" small>
        mdi-arrow-left
      </v-icon>
      Back to search
    </v-btn>
    <v-row no-gutters>
      <v-col class="pr-md-8" :cols="12" :md="8">
        <TransactionProfileCard />
        <strong>Transaction Details</strong>
        <v-form ref="form">
          <v-row class="mt-md-6 mb-md-5" no-gutters>
            <v-col class="pt-5 pt-md-0 pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
              <v-autocomplete
                v-model="transactionData.product"
                :items="products"
                item-text="ProductName"
                item-value="eload_id"
                :rules="requiredRules"
                validate-on-blur
                return-object
                class="ph-textbox"
                label="Product"
                append-icon="mdi-magnify"
                chips
                outlined
              />
            </v-col>
            <v-col class="pt-4 pt-md-0 pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
              <v-row class="d-flex justify-space-around" no-gutters>
                <v-col :cols="5" :md="4">
                  <v-checkbox
                    v-model="transactionData.freeLoad"
                    :disabled="transactionData.issueOAR"
                    @change="freeLoad"
                    label="FREE Load"
                    class="my-md-2 py-1"
                    hide-details
                  />
                </v-col>
                <v-col :cols="5" :md="4" v-if="currUser.location_type === '1'">
                  <v-checkbox
                    v-model="transactionData.issueOAR"
                    :disabled="transactionData.freeLoad"
                    @change="issueOAR"
                    label="Issue OAR"
                    class="my-md-2 py-1"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pt-4 pt-md-0 pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
              <v-text-field
                v-model="transactionData.mobileNumber"
                :rules="mobileRules"
                :counter="11"
                class="ph-textbox"
                label="Mobile Number"
                type="number"
                hide-spin-buttons
                validate-on-blur
                outlined
              />
            </v-col>
            <v-col
              v-if="transactionData.freeLoad"
              class="pt-4 pt-md-0 pl-md-2 pb-3 pb-md-8"
              :cols="12"
              :md="6"
            >
              <v-text-field
                v-model="transactionData.promoCode"
                :rules="promoCodeRules"
                validate-on-blur
                class="ph-textbox"
                label="Promo Code"
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <TransactionSummary />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isValidMobileNumber, renderToast } from '@/utils'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import TransactionSummary from '@/components/eload/TransactionSummary.vue'

export default {
  computed: {
    ...mapGetters({
      transactionData: 'eload/transactionData',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser',
      products: 'eload/products',
      receipt: 'receipt'
    })
  },
  data () {
    return {
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      mobileRules: [
        v => !!v || 'This field is required',
        v => isValidMobileNumber(v) || 'Invalid mobile number'
      ],
      promoCodeRules: [
        v => (!this.transactionData.freeLoad || (this.transactionData.freeLoad && !!v)) || 'This field is required'
      ]
    }
  },
  components: {
    TransactionProfileCard,
    TransactionSummary
  },
  methods: {
    backToSearch () {
      this.$store.dispatch('eload/resetForm')
      this.$store.commit('eload/SET_CURRENT_PAGE', 0)
    },
    freeLoad (value) {
      if (value === false) this.$store.commit('eload/SET_TRANSACTION_DATA', { promoCode: '' })
    },
    async issueOAR (value) {
      if (value && !this.receipt.oar_series_no) {
        const getReceipt = await this.$store.dispatch('getReceipt', {
          location_id: this.currUser.location_id,
          type: '2',
          application_id: this.currUser.application_id
        })

        if (getReceipt.code === 200) {
          this.$store.commit('eload/SET_TRANSACTION_DATA', { issueOAR: true })
        } else {
          renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
        }
      } else {
        this.$store.commit('eload/SET_TRANSACTION_DATA', { issueOAR: false })
      }
    }
  }
}
</script>
