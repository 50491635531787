<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <h2 class="title ph-title primary--text mx-6 my-2" style="font-size: 24px !important;">
      Offer Details
    </h2>
    <v-row class="mb-3 mx-2">
      <v-col :cols="3">
        <v-form ref="form">
          <v-row class="mt-1">
            <v-col :cols="12">
              <v-text-field
                v-model="formData.code"
                class="ph-textbox"
                label="Code"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.name"
                class="ph-textbox"
                label="Name"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.frequency"
                class="ph-textbox"
                label="Frequency"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-textarea
                v-model="formData.spiel"
                class="ph-textarea"
                label="Spiel"
                rows="5"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="save" class="font-weight-bold" color="primary" block x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      selectedItem: {},
      formData: {
        code: '',
        name: '',
        frequency: '',
        spiel: ''
      },
      requiredRules: [v => !!v || 'This field is required'],
      breadcrumbsItems: [
        {
          text: 'Prompt Maintenance',
          disabled: false,
          exact: true,
          to: '/prompt'
        },
        {
          text: 'Offers',
          disabled: false,
          exact: true,
          to: '/prompt/offers'
        },
        {
          text: 'Update Entry',
          disabled: true
        }
      ]
    }
  },
  methods: {
    async save () {
      if (this.$refs.form.validate()) {
        const payload = {
          code: this.formData.code,
          name: this.formData.name,
          frequency: this.formData.frequency,
          spiel: this.formData.spiel
        }

        const { code } = await this.$store.dispatch('prompt/updateOffer', {
          id: this.$route.params.id,
          payload
        })

        if (code === 200) {
          renderToast('success', 'Successful', 'Record has been updated')
          this.cancel()
        } else {
          renderToast('error', 'Error', 'Failed to update record')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill out all required fields')
      }
    },
    cancel () {
      this.$router.push('/prompt/offers')
    }
  },
  async mounted () {
    const { code, result } = await this.$store.dispatch('prompt/retrieveOfferById', this.$route.params.id)

    if (code === 200) {
      this.selectedItem = result
      this.formData.code = result.code
      this.formData.name = result.name
      this.formData.frequency = result.frequency
      this.formData.spiel = result.spiel
    } else {
      this.$router.push('/prompt/offers')
    }
  }
}
</script>
