<template>
  <div v-if="printData" id="OR">
    <div id="topdiv">
      <div id='logo'>
        <img style="width: 100%" src="@/assets/images/logo-horizontal-small.png" />
      </div>
      <div id='address'>
        <div>
          <p style="font-size: 20px;"><b>PETNET, Inc.</b></p>
          <p style="font-size: 20px;"><b></b></p>
          <p></p>
          <p>VAT REG TIN: 201-200-777-000</p>
          <p>VAT ZERO RATED</p>
        </div>
      </div>
      <div id='numDate'>
        <div id='number' class="col-xs-12 padd_zero">
          <div style="float: left; width: 80px;" class="noprint padd_two">
            <h4>No.:</h4>
          </div>
          <div style="float: left; width: 120px;" class="padd_zero noprint">
            <input style="font-size: 20px" type="text" :value="printData.form_number" readonly>
          </div>
        </div>
        <div id='date' class="padd_zero">
          <div class="padd_zero noprint" style="float:left;">Date:</div>
          <div class="padd_zero print" id="dateor">
            <input type="text" :value="printData.trx_date" readonly />
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div id="middleDiv">
      <!-- Settlement Info -->
      <div id='middleDiv_a' class="bor_2 padd_zero">
        <div class="text-center bor_1 padd_zero">
          <p class="noprint">In settlement of the following:</p>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 144px">&nbsp;</div>
        <div class="noprint bor_1 text-center padd_two" style="float: left; width: 114px">Amount</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">Total Sales(VAT Inclusive)</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print" id="divTotalSalesVATInclusive">{{totalSalesVatInclusive}}</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">Less: VAT</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print" id="divLessVAT">{{lessVAT}}</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">Total</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print" id="divNetVAT">{{netVAT}}</span></div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">Less:SC/PWD Disscount</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print">0.00</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">Total Due</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print" id="divTotalDue">{{totalDue}}</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">Less: Withholding Tax</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print">0.00</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">Amount Due</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print" id="divAmountDue">{{amountDue}}</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>
        <div class="bor_1 padd_two" style="float: left; width: 144px">&nbsp;</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px">&nbsp;</div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">VATable Sale</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print" id="divVatableSale">{{vatableSale}}</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">VAT Exempt Sale</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print">0.00</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">Zero Rated Sale</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print">0.00</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">VAT Amount</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print" id="divVATAmount">{{vatAmount}}</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>

        <div class="noprint bor_1 padd_two" style="float: left; width: 144px">Total Sales</div>
        <div class="bor_1 padd_two" style="float: left; width: 90px; text-align: right;">
          <span class="print" id="divTotalSales">{{totalSales}}</span>
        </div>
        <div class="bor_1 padd_two" style="float: left; width: 24px">&nbsp;</div>
      </div>

      <div style="float:left; width:429px;" class="orDiv padd_zero">
        <div class="padd_zero" style="width:200px">
          <div style="width:400px; padding:0 5px;">
            <div style="width:35px; float: left; text-align: center" class="padd_zero bor_2">
              <p class="bor_2" style="font-size: 12px">&nbsp;</p>
            </div>
            <div style="width:135px; float: left" class="padd_two">
              <p style="font-size: 14px;" class="noprint">Western Union</p>
            </div>
            <div style="width:152px; float: right;" class="padd_two">
              <!-- <p style="font-size: 12px;" class="noprint">Customer Number:</p> -->
            </div>
          </div>
          <div class="clearfix"></div>
          <div style="width:400px; padding: 0 5px;">
            <div style="width:35px; float: left; text-align: center" class="padd_zero bor_2">
              <p class="bor_2" style="font-size: 12px;" id="avp">X</p>
            </div>
            <div style="width:35px; float: left" class="padd_two">
              <p style="font-size: 14px;" class="noprint">AVP</p>
            </div>
            <div style="width:100px; float: left" class="padd_zero underline">
              <p class="underline" style="font-size: 12px;">
                &nbsp;
              </p>
            </div>
            <div style="width:150px; float: right;" class="padd_zero">
              <p style="font-size: 10px;font-weight: bold;"><br>
                <br>
                **DOWNLOAD THE PERA HUB MOBILE APP AND LINK YOUR CRN**
              </p>
            </div>
          </div>
          <div class="clearfix"></div>
          <div style="width:200px; padding: 0 5px;">
            <div style="width:35px; float: left" class="padd_zero bor_2">
              <p class="bor_2" style="font-size: 12px; text-align: center">&nbsp;</p>
            </div>
            <div style="width:50px; float: left" class="padd_zero">
              <p class="noprint txtsize_15" style="font-size: 14px;">Others</p>
            </div>
            <div style="width:85px; float: left" class="padd_zero underline">
              <p class="underline" style="font-size: 12px;">&nbsp;</p>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="padd_zero" style="margin: 7px 0 0 0; width: 505px" id="orbody">
          <div class="left" style="float: left; width:165px; padding: 0 5px; font-size: 14px;">
            <p class="noprint">Received from</p>
          </div>
          <div class="underline" style="float: left; width:305px; font-size:12px;">
            <p class="underline print">{{this.printData.customer_name}}</p>
          </div>
          <!--col-md-7 -->

          <div class="clearfix"></div>
          <div class="left" style="float: left; width:165px; padding: 0 5px; font-size: 14px;">
            <p class="noprint">TIN</p>
          </div>
          <div class="underline" style="float: left; width:305px; font-size:12px;">
            <p class="underline print"> &nbsp;</p>
          </div>

          <div class="clearfix"></div>
          <div class="left" style="float: left; width:165px; padding: 0 5px; font-size: 14px;">
            <p class="noprint">the amount of</p>
          </div>
          <div class="underline" style="float: left; width:265px; font-size:10px;">
            <p class="underline print" id='divAmtWords'>{{amountInWords}}</p>
          </div>

          <div class="clearfix"></div>
          <div class="left" style="float: left; width:165px; padding: 0 5px; font-size: 14px;">
            <p>&nbsp;</p>
          </div>
          <div class="underline" style="float: left; width:305px; font-size:12px;">
            <p class="underline print">&nbsp;</p>
          </div>

          <div class="clearfix"></div>
          <div class="left" style="float: left; width:165px; padding: 0 5px; font-size: 14px;">
            <p class="noprint">address</p>
          </div>
          <div class="underline" style="float: left; width:265px; font-size:12px;">
            <p class="underline print">{{address}}</p>
          </div>
          <div class="clearfix"></div>
          <div class="left" style="float: left; width:165px; padding: 0 5px; font-size: 14px;">
            <p class="noprint">in partial/full payment of</p>
          </div>
          <div class="underline" style="float: left; width:305px; font-size:12px;">
            <p class="underline print">{{particulars}}</p>
          </div>

          <div class="clearfix"></div>
          <div class="left" style="float: left; width:165px; padding: 0 5px; font-size: 14px;">
            <p class="noprint">Business Style/Name</p>
          </div>
          <div class="underline" style="float: left; width:305px; font-size:12px;">
            <p class="underline print">&nbsp;</p>
          </div>

          <div class="clearfix"></div>
          <div class="left" style="float: left; width:165px; padding: 0 5px; font-size: 14px;">
            <p class="noprint">OSCA/PWD ID No.:</p>
          </div>
          <div class="underline" style="float: left; width:305px; font-size:12px;">
            <p class="underline print">&nbsp;</p>
          </div>

          <div class="clearfix"></div>
          <div class="left" style="float: left; width:165px; padding: 0 5px; font-size: 14px;">
            <p class="noprint">Cardholder's Signature</p>
          </div>
          <div class="underline" style="float: left; width:305px; font-size:12px;">
            <p class="underline print">&nbsp;</p>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="padd_zero" style="width:124px; float: left">
          <div style="width:200px; padding:0 5px;">
            <div style="width:35px; float: left" class="padd_zero bor_2">
              <p class="bor_2" style="font-size: 12px; text-align: center;" id="cash">X</p>
            </div>
            <div style="width:135px; float: left" class="padd_two">
              <p style="font-size: 14px;" class="noprint">CASH</p>
            </div>
          </div>
          <div class="clearfix"></div>
          <div style="width:200px; padding:0 5px;">
            <div style="width:35px; float: left" class="padd_zero bor_2">
              <p class="bor_2" style="font-size: 12px; text-align: center">&nbsp;</p>
            </div>
            <div style="width:135px; float: left" class="padd_two">
              <p style="font-size: 14px;" class="noprint">CHECK</p>
            </div>
          </div>
        </div>
        <div class="padd_zero" style="width:265px; float: right">
          <div class="padd_zero" style="font-size: 14px;">
            <span class="noprint">Received by:</span>
          </div>
          <div class="underline" style="text-align: center">
            <p class="underline print">{{printData.customer_name}}</p>
          </div>
          <div class="text-center">
            <h5 class="noprint">Signature Over Printed Name</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { toWords } from '@/utils'

export default {
  data () {
    return {
      totalSalesVatInclusive: '-',
      lessVAT: '-',
      netVAT: '-',
      totalDue: '-',
      amountDue: '-',
      vatableSale: '-',
      vatAmount: '-',
      totalSales: '-',
      amountInWords: '-',
      particulars: '-',
      address: '-'
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  props: {
    printData: {
      type: Object,
      default: null
    }
  },
  mounted () {
    const data = this.printData
    const vat = parseFloat(data.amount) * 0.12
    const netVatAmount = parseFloat(data.amount) - parseFloat(vat)

    this.totalSalesVatInclusive = this.formatAmount(data.amount)
    this.lessVAT = this.formatAmount(vat)
    this.netVAT = this.formatAmount(netVatAmount)
    this.totalDue = this.formatAmount(data.amount)
    this.amountDue = this.formatAmount(data.amount)
    this.vatableSale = this.formatAmount(netVatAmount)
    this.vatAmount = this.formatAmount(vat)
    this.totalSales = this.formatAmount(data.amount)
    this.amountInWords = `${this.toWords(data.amount)} (${data.amount})`
  },
  methods: {
    toWords,
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    }
  }
}
</script>

<style scoped>
input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

input {
  border: none;
  width: 100%;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

.txtsize_15 {
  font-size: 15px;
  margin: 0 !important;
}

.input-lg {
  padding: 22px 0 0 0 !important;
  font-size: 13px !important;
  text-align: center;
  font-weight: bold;
}

#main {
  margin-top: 10px;
}

#main div.row {
  border: 5px solid #000;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#main div.row2 {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  width: 816px;
}

#OAR {
  height: 528px;
  width: 816px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  padding: 3px 80px;
}

#OR {
  height: 520px;
  width: 816px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  padding:3px 10px;
}

#voucher {
  height: 528px;
  width: 816px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  padding:3px 18px;
}

.underline {
  border-radius: 0 !important;
  border-bottom: 1px solid #000;
}

.padd_zero {
  padding: 0 !important;
}

.padd_two {
  padding: 0 1px !important;
}

.padd_15 {
  padding: 0 15px !important;
}

div.bor_0 {
  border: 1px solid #000;
  border-left: none;
  border-bottom: none;
}

.bor_1, div.bor_1 {
  border: 1px solid #000 !important;
  font-size: 12px;
}

.bor_2, div.bor_2 {
  border: 1px solid #000 !important;
}

.wd_144 {
  width: 144px;
}

.wd_145 {
  width: 145px;
}

table.dataTable thead th, table.dataTable thead td,
table.dataTable tbody th, table.dataTable tbody td {
  padding: 3px !important;
  border: 1px solid #000 !important;
}

table.dataTable tbody tr td {
  text-align: right !important;
}

table.dataTable tbody tr td:nth-child(1) {
  text-align: left !important;
}

table.dataTable tbody tr:last-child td:nth-child(1) {
  font-weight: bold !important;
}

@media print
{
  .noprint {
    visibility: hidden;
  }

  .print {
    visibility: visible;
    font-size: 16px;
  }

  #OR .print {
    visibility: visible;
    font-size: 16px;
  }

  #dateprint {
      position:fixed;
  }

  #tellerprint {
    padding-top: 20px;
    padding-left: 40px;
    position:fixed;
  }

  .bor_1, div.bor_1 {
    visibility: hidden;
    border: 1px solid #fff !important;
    font-size: 13px;
  }

  .bor_2, div.bor_2 {
    /*visibility: hidden;*/
    border: 1px solid #fff !important;
  }

  #topdiv {
    height: 135px;
    width: 750px;
    overflow: hidden;
    padding:10px 1px 0 1px;
  }

  #OAR div#topdiv {
    width: 695px;
    border: 5px solid #fff;
    overflow: hidden;
    padding:0;
    margin: 0px auto 0 auto;
  }

  #OAR div.row {
    margin: 5px auto;
    width: 695px;
    border: 5px solid #fff;
    overflow: hidden;
    padding: 0;
  }

  #OAR div.row div.bor_right {
    border-right: 5px solid #fff;
  }

  #OAR input {
    border-radius: 0;
    border-top: none;
    border-bottom: 1px solid #fff;
    border-left: none;
    border-right: none;
    box-shadow: none;
    width: 75%;
  }

  #logo {
    visibility: hidden;
    overflow: hidden;
    margin-left: 60px;
    width: 207px;
    float: left;
  }

  #logo img {
    width: 100%;
  }

  #address {
    width: 246px;
    float: left;
    text-align: center;
    font-size: 12px;
    line-height: 1.5em;
    overflow: hidden;
    visibility: hidden;
  }

  #numDate {
    width: 215px;
    height: 37px;
    float: right;
  }

  #number {
    border:none;
  }

  #date {
    width:190px;
    margin:30px 0 0 0;
    padding-left:65px !important;
    overflow: hidden;
    float: right;
  }

  #date div.padd_zero:nth-child(2) {
    float:right;
    border: none;
  }

  #middleDiv {
    width: 750px;
    overflow: hidden;
    padding:117px 0px 0px 0px;
    margin-left: 0px;
  }

  #middleDiv_a {
    float:left;
    width: 260px;
    margin-right: 40px;
  }

  .underline {
    border-radius: 0 !important;
    border-bottom: 1px solid #fff;
  }

  .inputOAR {
    visibility: hidden;
  }

  #OR {
    padding-left:170px;
    width : 1000px;
    height: 565px;
  }

  #orbody {
    padding-top: 25px !important;
    padding-left: 35px !important;
    height: 255px ;
  }

  #avp {
    font-weight:bold;
    font-size: 14px;
    text-align: left;
  }

  #cash {
    font-weight:bold;
    font-size: 14px;
    text-align: left;
  }

  #cash2 {
    font-weight:bold;
    font-size: 14px;
    text-align: left;
    padding-left:0px !important;
    margin-left:0px !important;
  }

  #checkedbox {
    padding-top: 15px !important;
    height: 42px !important;
    padding-left:0px !important;
    margin-left:0px !important;
  }

  #OAR {
    width: 816px ;
    padding-left:3px 80px !important ;
  }

  #custdetails{
    padding-left: 100px !important;
  }
}

@media screen
{
  #topdiv {
    height: 135px;
    width: 750px;
    overflow: hidden;
    padding:10px 1px 0 1px;
  }

  #OAR div#topdiv {
    width: 695px;
    border: 5px solid #000;
    overflow: hidden;
    padding:0;
    margin: 0px auto 0 auto;
  }

  #OAR div.row {
    margin: 5px auto;
    width: 695px;
    border: 5px solid #000;
    overflow: hidden;
    padding: 0;
  }

  #OAR div.row div.bor_right {
    border-right: 5px solid #000;
  }

  #OAR input {
    border-radius: 0;
    border-top: none;
    border-bottom: 1px solid #000;
    border-left: none;
    border-right: none;
    box-shadow: none;
    width: 75%;
  }

  #logo {
    overflow: hidden;
    margin-left: 60px;
    width: 207px;
    float: left;
  }

  #logo img {
    width: 205px;
  }

  #address {
    width: 246px;
    float: left;
    text-align: center;
    font-size: 12px;
    line-height: 1.5em;
    overflow: hidden;
  }

  #numDate {
    width: 215px;
    height: 37px;
    float: right;
  }

  #number {
    height: 100%;
    border:2px solid #000;
  }

  #date {
    width: 210px;
    margin: 30px 0 0 0;
    overflow: hidden;
    float: right;
  }

  #date div.padd_zero:nth-child(2) {
    float:right;
    border-bottom: 2px solid #000;
  }

  #middleDiv {
    width: 750px;
    overflow: hidden;
    padding:0 1px 10px 60px;
  }

  #middleDiv_a {
    float:left;
    width: 260px;
  }

  .underline {
    border-radius: 0 !important;
    border-bottom: 1px solid #000;
  }
}

#coc {
  height: 480px;
  width: 100%;
  border: 2px solid #000;
  padding: 20px;
  font-size: 10.3px;
  overflow: hidden;
}

#coc_inner {
  padding: 2px;
  overflow: hidden;
}

#coc_inner div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#coc_inner div h2 {
  margin: 0;
}

div.innDiv {
  width: 100%;
  overflow: hidden;
}

div.innDiv:nth-child(2) {
  margin: 10px auto;
}

div.Div33 {
  width: 33%;
  float: left;
}

div.Div50 {
  float: left;
  width: 50%;
}

div.innDiv table {
  border: 2px solid #000;
}

div.innDiv table tbody tr:first-child {
  background-color: #424242;
}

div.innDiv table tbody tr:first-child th {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #000;
}

div.innDiv table tbody tr td {
  padding: 1px 15px;
  font-weight: bold;
}

div.innDiv table tbody tr td:last-child {
  border-left: 2px solid #000;
  text-align: center;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
</style>
