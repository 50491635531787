<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ma-3">
        <v-col :cols="12" :md="4">
          <v-form ref="ibtForm">
            <v-row class="ph-ibt-form">
              <v-col cols="12">
                <v-text-field
                  v-model="ibtForm.reference_no"
                  label="Reference No."
                  color="gray darken-1"
                  class="ph-textbox"
                  outlined
                  :hide-spin-buttons="true"
                  :rules="requiredRules"
                  readonly
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  @change="getSelectedLocUsers(ibtForm.to_branch)"
                  v-model="ibtForm.to_branch"
                  :items="collections.locations"
                  item-text="location_name"
                  item-value="location_id"
                  class="ph-textbox"
                  label="To Branch"
                  :rules="requiredRules"
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="ibtForm.received_by"
                  :items="usersByBorrowerLocation"
                  item-value="user_id"
                  class="ph-textbox"
                  label="Received by:"
                  :rules="requiredRules"
                  outlined
                >
                <template slot="selection" slot-scope="{ item }">
                  {{item.first_name}} {{item.last_name}}
                </template>
                <template slot="item" slot-scope="{ item }">
                  {{item.first_name}} {{item.last_name}}
                </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="ibtForm.type"
                  :items="typeList"
                  item-text="text"
                  item-value="value"
                  class="ph-textbox"
                  label="Type"
                  :rules="requiredRules"
                  outlined
                />
              </v-col>
              <v-col cols="12" v-if="ibtForm.type === '0'">
                <v-select
                  v-model="ibtForm.to_bank"
                  :items="borrowerBanks"
                  :item-text="getBankName"
                  item-value="locations_bank_id"
                  class="ph-textbox"
                  label="To Bank"
                  :rules="requiredRules"
                  @change="setCurrency"
                  outlined
                />
              </v-col>
              <v-col cols="12" v-if="ibtForm.type === '1'">
                <v-select
                  v-model="ibtForm.currency"
                  :items="currencyList"
                  item-text="text"
                  item-value="value"
                  class="ph-textbox"
                  label="Currency"
                  :rules="requiredRules"
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="ibtForm.amount"
                  class="ph-textbox"
                  label="Amount"
                  type="number"
                  hide-spin-buttons
                  :rules="requiredRules"
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="ibtForm.reason"
                  :items="reasonList"
                  item-text="reasons"
                  item-value="reasons"
                  class="ph-textbox"
                  label="Reason"
                  :rules="requiredRules"
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-row class="ph-ibt-action-buttons">
                  <v-col cols="6" class="ph-ibt-action-button">
                    <v-btn
                      color="primary"
                      class="ph-action-button"
                      outlined
                      @click="ibtSubmitAction('cancel')"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="ph-ibt-action-button">
                    <v-btn
                      color="primary"
                      class="ph-action-button ph-action-button-proceed-confirm"
                      elevation="0"
                      @click="ibtSubmitAction('submit')"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-dialog
        v-model="confirmDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            {{ confirmDialogTitle }}
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            {{ confirmDialogText }}
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="confirmAction(confirmActionType, false)"
              class="ph-action-button"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="confirmAction(confirmActionType, true)"
              class="ph-action-button"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import { renderToast } from '@/utils'

export default {
  data () {
    return {
      toBranchList: [],
      receivedByList: [],
      typeList: [
        { text: 'Deposit to Account', value: '0' },
        { text: 'Deliver/Pick-up by cash', value: '1' }
      ],
      bankList: [],
      currencyList: [
        { text: 'Philippine Peso - PHP', value: '1' },
        { text: 'US Dollar - USD', value: '2' }
      ],
      ibtForm: {},
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Inter Branch Transfer',
          disabled: false,
          href: '/inter-branch-transfer'
        },
        {
          text: 'New Entry',
          disabled: false,
          href: '#'
        }
      ],
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      confirmActionType: ''
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      borrowerBanks: 'interBranchTransfer/borrowerBanks',
      usersByBorrowerLocation: 'interBranchTransfer/usersByBorrowerLocation',
      collections: 'collections',
      ibtList: 'interBranchTransfer/ibtList',
      reasonList: 'interBranchTransfer/reasonList'
    })
  },
  async mounted () {
    this.ibtForm.reference_no = await this.generateVoucherNumber()
    await this.$store.dispatch('getAllLocations', 0)
    await this.$store.dispatch('interBranchTransfer/getReasonList')
  },
  methods: {
    renderToast,
    async ibtSubmitAction (type) {
      this.confirmActionType = type
      switch (type) {
        case 'submit':
          if (this.$refs.ibtForm.validate()) {
            this.confirmDialog = true
            this.confirmDialogTitle = 'Save Entry'
            this.confirmDialogText = 'Are you sure you want to save your entry?'
          } else {
            this.renderToast('error', 'Validation Error', 'You must complete the following fields')
          }
          break
        case 'cancel':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Cancel Entry'
          this.confirmDialogText = 'Are you sure you want to cancel?'
          break
      }
    },
    confirmAction (type, isConfirm) {
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''
      if (isConfirm) {
        switch (type) {
          case 'submit':
            this.submitRequest()
            break
          case 'cancel':
            this.backToMainPage()
            break
        }
      }
    },
    submitRequest () {
      const payload = {
        reference_no: this.ibtForm.reference_no,
        trx_date: this.currUser.trx_date,
        location_from: this.currUser.location_id,
        location_to: this.ibtForm.to_branch,
        currency_id: this.ibtForm.currency,
        amount: this.ibtForm.amount,
        tranfer_by: this.currUser.user_id,
        client_ip: this.currUser.ip_address,
        is_cash: this.ibtForm.type,
        locations_bank_id: this.ibtForm.type === '0' ? this.ibtForm.to_bank : '0',
        received_by: this.ibtForm.received_by,
        reason: this.ibtForm.reason,
        is_borrower: 0,
        is_lender: 1
      }
      console.log('AAAAAAAAAAA', payload)

      this.$store.dispatch('interBranchTransfer/requestTransfer', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.renderToast('success', 'Request Successful', 'Inter Branch Transfer Request was successful')
          this.backToMainPage()
        } else {
          this.renderToast('error', 'Request Error', res.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async generateVoucherNumber () {
      const currentYear = format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy')
      const currentMonth = format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'MM')
      const payload = {
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id
      }

      let trxCount = 0

      await this.$store.dispatch('interBranchTransfer/getIBTCount', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          trxCount = this.generateTrxCount(res.result, 4)
        } else {
          trxCount = this.generateTrxCount(0, 4)
        }
      })

      const refNumberNew = `${this.currUser.location_code}${currentMonth}${currentYear}-${trxCount}`
      console.log(refNumberNew)
      return refNumberNew
    },
    backToMainPage () {
      this.$router.push({ path: '/inter-branch-transfer' })
    },
    generateTrxCount (num, places) {
      return String(num).padStart(places, '0')
    },
    async getSelectedLocUsers (locationId) {
      await this.$store.dispatch('interBranchTransfer/getUsersOnBorrowerLocations', { location_id: locationId })
      await this.$store.dispatch('interBranchTransfer/getBorrowerBank', { location_id: this.ibtForm.to_branch })
    },
    getBankName (item) {
      return `${item.bank_name} (${item.account_number}) - ${item.currency_code}`
    },
    setCurrency () {
      const selectedBank = this.borrowerBanks.find(bank => bank.locations_bank_id === this.ibtForm.to_bank)
      this.ibtForm.currency = selectedBank.currency_id
    }
  }
}
</script>
<style scoped>
.ph-ibt-action-button:nth-of-type(2) {
  display: flex;
}

.ph-action-button-proceed-confirm {
  margin-left: auto;
}
</style>
