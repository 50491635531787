<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mb-3 mx-2">
      <v-col :cols="3">
        <v-form ref="form">
          <v-row class="mt-1">
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.teller"
                :items="tellers"
                class="ph-textbox"
                label="Teller"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.currencyId"
                :items="collections.currencies"
                class="ph-textbox"
                label="Currency"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.amount"
                class="ph-textbox"
                label="Amount"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="saveData" class="font-weight-bold" color="primary" block x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections'
    })
  },
  data () {
    return {
      tellers: [],
      requiredRules: [v => !!v || 'This field is required'],
      formData: {
        teller: '',
        currencyId: '',
        amount: ''
      },
      breadcrumbsItems: [
        {
          text: 'BOS',
          disabled: true
        },
        {
          text: 'Cash Transfer',
          disabled: false,
          exact: true,
          to: '/cash-transfer'
        },
        {
          text: 'Form',
          disabled: true
        }
      ]
    }
  },
  methods: {
    async saveData () {
      if (this.$refs.form.validate()) {
        try {
          const { code } = await this.$store.dispatch('cashTransfer/saveData', {
            location_id: this.currUser.location_id,
            user_id: this.currUser.user_id,
            trx_date: this.currUser.trx_date,
            currency_id: this.formData.currencyId,
            total_amount: this.formData.amount,
            date_encoded: this.currUser.trx_date,
            client_ip: this.currUser.ip_address,
            acknowledge_by: this.formData.teller,
            date_acknowledge: this.currUser.trx_date,
            transfer_by_admin: 0
          })

          if (code === 200) {
            renderToast('success', 'Successful', 'Cash Transfer has been saved')
            this.cancel()
          } else {
            renderToast('error', 'Request Error', 'Failed saving the given data')
          }
        } catch (e) {
          renderToast('error', 'USP Error', 'Failed saving the given data')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill out all required fields')
      }
    },
    cancel () {
      this.$router.push('/cash-transfer')
    }
  },
  async beforeMount () {
    const { code, result } = await this.$store.dispatch('cashTransfer/getUsers', {
      locationId: this.currUser.location_id,
      trxDate: this.currUser.trx_date
    })

    if (code === 200) {
      const filteredResult = result.filter(user => user.user_id !== this.currUser.user_id)
      this.tellers = filteredResult.map(user => {
        return { text: `${user.last_name}, ${user.first_name}`, value: user.user_id }
      })
    } else {
      renderToast('error', 'USP Error', 'Failed fetching the required data')
    }
  }
}
</script>
