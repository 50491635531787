<template>
  <div class="px-2 px-md-2 mt-md-4 mb-16 pb-8 pb-md-0 mb-md-0">
    <v-btn @click="backToSearch" class="text-capitalize" text small>
      <v-icon class="mr-1" small>
        mdi-arrow-left
      </v-icon>
      Back to search
    </v-btn>
    <v-row no-gutters>
      <v-col class="pr-md-8" :cols="12" :md="8">
        <TransactionProfileCard />
        <strong>Select Airline</strong>
        <v-row class="mt-md-5 mb-md-5" no-gutters>
          <v-col
            v-for="provider in providers"
            :key="provider.id"
            class="py-1 py-md-0 pr-md-2 pb-md-2"
            :cols="12"
            :sm="6"
            :md="4"
          >
            <v-card
              @click="selectProvider(provider.id)"
              :class="['rounded-lg py-1 selection', { 'selected': transactionData.provider.id === provider.id }]"
              outlined
              flat
            >
              <v-card-text>
                <v-row align="center" no-gutters>
                  <v-col class="d-flex flex-column align-center" :cols="12">
                    <img
                      v-if="provider.logo"
                      style="max-height: 60px;"
                      :src="require(`@/assets/images/airline/${provider.logo}`)"
                      :alt="provider.name"
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <TransactionDetails ref="currentForm" />
        <div class="mt-6">
          <v-btn
            @click="cancelDialog = true"
            class="rounded-lg font-weight-bold"
            color="primary"
            outlined
            large
          >
            Cancel
          </v-btn>
        </div>
      </v-col>
      <TransactionSummary />
    </v-row>
    <v-dialog
      v-model="cancelDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Discard Transaction
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to discard this transaction?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="cancelDialog = false"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="cancel"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import TransactionSummary from '@/components/airlines/TransactionSummary.vue'
import TransactionDetails from '@/components/airlines/TransactionDetails.vue'

export default {
  computed: {
    ...mapGetters({
      transactionData: 'airlines/transactionData',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser',
      providers: 'airlines/providers',
      oar: 'oar'
    })
  },
  data () {
    return {
      cancelDialog: false,
      requiredRules: [
        v => !!v || 'This field is required'
      ]
    }
  },
  components: {
    TransactionProfileCard,
    TransactionDetails,
    TransactionSummary
  },
  methods: {
    cancel () {
      this.$store.dispatch('airlines/resetForm')
      this.$router.push({ path: '/pera-travel' })
    },
    backToSearch () {
      this.$store.dispatch('airlines/resetForm')
      this.$store.commit('airlines/SET_CURRENT_PAGE', 0)
    },
    async selectProvider (id) {
      const filteredProvider = this.providers.filter(item => item.id === id)

      if (filteredProvider.length === 1) {
        this.$store.commit('airlines/SET_TRANSACTION_DATA', {
          provider: filteredProvider[0]
        })

        this.$refs.currentForm.$refs.form.resetValidation()
      }
    }
  },
  async beforeMount () {
    const req = await this.$store.dispatch('airlines/avpServiceFee', this.currUser.location_id)

    if (req.code === 200) {
      this.$store.commit('airlines/SET_SERVICE_FEES', req.result)
    }
  }
}
</script>
