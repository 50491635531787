<template>
  <div>
    <div style="float: left; width:250px; overflow: hidden;">
        &nbsp;
    </div>
    <div id="dtrstoptbl" style="float: right; width:1099px; overflow: hidden;">
      <div style="float: left; width: 549.5px;">
        <div class="dtrslabel">
          <div style="padding: 3px 5px;">REVOLVING FUNDS (exclusive of bank balance):</div>
          <div style="padding: 3px 5px;">BEGINNING BALANCE:</div>
          <div style="padding: 3px 5px;">REPLENISHMENT TODAY (Including Cash Advance):</div>
        </div>
        <div style="float: left; width: 274.75px;">
          <table id="dtrsInfoTable" style="width: 100%">
            <tbody>
              <tr>
                <th>PHP PESO</th>
                <th>$ Dollar</th>
              </tr>
              <tr>
                <td class="digit">{{ formatData(cpr.revolvingFunds.peso, 'float') }}</td>
                <td class="digit">{{ formatData(cpr.revolvingFunds.dollar, 'float') }}</td>
              </tr>
              <tr>
                <td class="digit">{{ formatData(cpr.beginningBalance.peso, 'float') }}</td>
                <td class="digit">{{ formatData(cpr.beginningBalance.dollar, 'float') }}</td>
              </tr>
              <tr>
                <td class="digit">{{ formatData(cpr.replenishmentToday.peso, 'float') }}</td>
                <td class="digit">{{ formatData(cpr.replenishmentToday.dollar, 'float') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style="float: right; width: 549.5px;">
        <div class="dtrslabel">
          <div style="padding: 3px 5px;">WU Send-Out Deposit:</div>
          <div style="padding: 3px 5px;">Money Changer Deposit:</div>
          <div style="padding: 3px 5px;">TOTAL DEPOSIT TODAY</div>
        </div>
        <div style="float: left; width: 274.75px;">
          <table id="dtrsInfoTable2" style="width: 100%">
            <tbody>
              <tr>
                <th>PHP PESO</th>
                <th>$ Dollar</th>
              </tr>
              <tr>
                <td class="digit">{{ formatData(cpr.wuSODeposit.peso, 'float') }}</td>
                <td class="digit">{{ formatData(cpr.wuSODeposit.dollar, 'float') }}</td>
              </tr>
              <tr>
                <td class="digit">0.00</td>
                <td class="digit">0.00</td>
              </tr>
              <tr>
                <td class="digit">{{ formatData(cpr.totalDepositToday.peso, 'float') }}</td>
                <td class="digit">{{ formatData(cpr.totalDepositToday.dollar, 'float') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style="float: left; width: 549.5px;">&nbsp;</div>
      <div style="float: right; width: 549.5px;">
        <div style="float: left; width: 274.75px; padding: 16px 0 0 0; font-weight: bold; text-align: right;">(Attached Deposit Slip)</div>
        <div style="float: left; width: 274.75px; padding: 16px 0 0 0; font-weight: bold; text-align: center;">INTER-BRANCH TRANSFER</div>
        <div class="dtrslabel">
          <div style="padding: 3px 5px;">Debit:</div>
          <div style="padding: 3px 5px;">Credit:</div>
          <div style="padding: 3px 5px;">ENDING BALANCE</div>
        </div>
        <div style="float: left; width: 274.75px;">
          <table id="dtrsInfoTable3" style="width: 100%;">
            <tbody>
              <tr>
                <th>PHP PESO</th>
                <th>$ Dollar</th>
              </tr>
              <tr>
                <td class="digit">{{ formatData(cpr.debit.peso, 'float') }}</td>
                <td class="digit">{{ formatData(cpr.debit.dollar, 'float') }}</td>
              </tr>
              <tr>
                <td class="digit">{{ formatData(cpr.credit.peso, 'float') }}</td>
                <td class="digit">{{ formatData(cpr.credit.dollar, 'float') }}</td>
              </tr>
              <tr>
                <td class="digit">{{ formatData(cpr.endingBalance.peso, 'float') }}</td>
                <td class="digit">{{ formatData(cpr.endingBalance.dollar, 'float') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div style="clear: both; padding: 20px 0; font-weight: bold; font-size: 12px;">All information entered in this report are TRUE and ACCURATE. Recording of false information with the intention of misrepresenting the accuracy of Revolving Fund and Cash Available is a VIOLATION.</div>
    <div>
      <table id="tblDTRS" border="0" cellpadding="1" cellspacing="1" style="border-bottom: none !important;">
        <tbody>
          <tr>
            <th>Date</th>
            <th>MTCN/Voucher</th>
            <th>Name</th>
            <th>IMT DMT</th>
            <th>Principal<br>Amount</th>
            <th>WU Charges</th>
            <th>DS<br>Tax</th>
            <th>Net<br>Amount</th>
            <th>USD Adj</th>
            <th>USD Release<br>/Received</th>
            <th>$/P Conv<br>to Php/U$D</th>
            <th>WU Sell<br>Rate</th>
            <th>MC Rate<br>Buy/Sell</th>
            <th>PHP Adj</th>
            <th>PHP Release<br>/Received</th>
            <th class="hideObj"></th>
          </tr>

          <!-- Payout Dollar Start -->
          <tr v-if="dtrs.payoutDollar.data.length > 0">
            <td colspan="14"><b>Payout Dollar</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.payoutDollar.data" :key="`payoutDollar_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.ds_tax, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.net_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.usd_released, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.buy_back, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.wu_sell_rate, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.mc_rate, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.payoutDollar.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.payoutDollar.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="2">{{ formatData(dtrs.payoutDollar.totals.dsTax, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.payoutDollar.totals.netAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="2"> {{ formatData(dtrs.payoutDollar.totals.usdReleaseReceived, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.payoutDollar.totals.convToPhpUsd, 'accounting') }}</td>
            <td class="bordertd digit" colspan="4">{{ formatData(dtrs.payoutDollar.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.payoutDollar.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.payoutDollar.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- Payout Dollar End -->

          <!-- Payout Peso Start -->
          <tr v-if="dtrs.payoutPeso.data.length > 0">
            <td colspan="14"><b>Payout Peso</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.payoutPeso.data" :key="`payoutPeso_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.ds_tax, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.net_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.payoutPeso.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.payoutPeso.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="2">{{ formatData(dtrs.payoutPeso.totals.dsTax, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.payoutPeso.totals.netAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="7">{{ formatData(dtrs.payoutPeso.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.payoutPeso.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.payoutPeso.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- Payout Peso End -->

          <!-- Nonex Sendout Start -->
          <!-- <tr v-if="dtrs.nonexSendout.data.length > 0">
            <td colspan="14"><b>Nonex Sendout</b></td>
          </tr> -->
          <tr v-for="(data, index) in dtrs.nonexSendout.data" :key="`nonexSendout_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.ds_tax, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.net_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.usd_released, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.buy_back, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.wu_sell_rate, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.mc_rate, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.nonexSendout.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.nonexSendout.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="2">{{ formatData(dtrs.nonexSendout.totals.dsTax, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.nonexSendout.totals.netAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="2"> {{ formatData(dtrs.nonexSendout.totals.usdReleaseReceived, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.nonexSendout.totals.convToPhpUsd, 'accounting') }}</td>
            <td class="bordertd digit" colspan="4">{{ formatData(dtrs.nonexSendout.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.nonexSendout.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.nonexSendout.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- Nonex Sendout End -->

          <!-- Nonex Payout Start -->
          <!-- <tr v-if="dtrs.nonexPayout.data.length > 0">
            <td colspan="14"><b>Nonex Payout</b></td>
          </tr> -->
          <tr v-for="(data, index) in dtrs.nonexPayout.data" :key="`nonexPayout_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.ds_tax, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.net_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.usd_released, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.buy_back, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.wu_sell_rate, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.mc_rate, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.nonexPayout.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.nonexPayout.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="2">{{ formatData(dtrs.nonexPayout.totals.dsTax, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.nonexPayout.totals.netAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="2"> {{ formatData(dtrs.nonexPayout.totals.usdReleaseReceived, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.nonexPayout.totals.convToPhpUsd, 'accounting') }}</td>
            <td class="bordertd digit" colspan="4">{{ formatData(dtrs.nonexPayout.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.nonexPayout.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.nonexPayout.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- Nonex Payout End -->

          <!-- Pay @WU : REFUND Start -->
          <tr v-if="dtrs.payAtWURefund.data.length > 0">
            <td colspan="14"><b>Pay @WU : REFUND</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.payAtWURefund.data" :key="`payAtWURefund_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.payAtWURefund.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.payAtWURefund.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="10">{{ formatData(dtrs.payAtWURefund.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.payAtWURefund.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.payAtWURefund.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- Pay @WU : REFUND End -->

          <!-- Less: SendOut Peso Start -->
          <tr v-if="dtrs.lessSendoutPeso.data.length > 0">
            <td colspan="14"><b>Less: SendOut Peso</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.lessSendoutPeso.data" :key="`lessSendoutPeso_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.wu_charges, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.net_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.lessSendoutPeso.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.lessSendoutPeso.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.lessSendoutPeso.totals.wuCharges, 'accounting') }}</td>
            <td class="bordertd digit" colspan="2">{{ formatData(dtrs.lessSendoutPeso.totals.netAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="7">{{ formatData(dtrs.lessSendoutPeso.totals.phpAdj, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.lessSendoutPeso.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.lessSendoutPeso.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- Less: SendOut Peso End -->

          <!-- Less Pay@WU : PAYMENT Start -->
          <tr v-if="dtrs.lessPayAtWUPayment.data.length > 0">
            <td colspan="14"><b>Less Pay@WU : PAYMENT</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.lessPayAtWUPayment.data" :key="`lessPayAtWUPayment_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.lessPayAtWUPayment.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.lessPayAtWUPayment.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="10">{{ formatData(dtrs.lessPayAtWUPayment.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.lessPayAtWUPayment.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.lessPayAtWUPayment.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- Less Pay@WU : PAYMENT End -->

          <!-- Money Changing - (Buy USD) Start -->
          <tr v-if="dtrs.mcBuyUSD.data.length > 0">
            <td colspan="14"><b>Money Changing - (Buy USD)</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.mcBuyUSD.data" :key="`mcBuyUSD_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.usd_released, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.buy_back, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.mc_rate, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.mcBuyUSD.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData((dtrs.mcBuyUSD.totals.sellTotal - dtrs.mcBuyUSD.totals.buyTotal) * -1, 'accounting') }}</td>
            <td class="bordertd digit" colspan="5">{{ formatData(dtrs.mcBuyUSD.totals.usdAdj, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.mcBuyUSD.totals.usdReleaseReceived, 'accounting') }}</td>
            <td class="bordertd digit" colspan="4">{{ formatData(dtrs.mcBuyUSD.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.mcBuyUSD.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.mcBuyUSD.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- Money Changing - (Buy USD) End -->

          <!-- AVP Sales Start -->
          <tr v-if="dtrs.avpSalesCat5.data.length > 0">
            <td colspan="14"><b>AVP Sales</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.avpSalesCat5.data" :key="`avpSalesCat5_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat5.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.avpSalesCat5.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="10">{{ formatData(dtrs.avpSalesCat5.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat5.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.avpSalesCat5.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- AVP Sales End -->

          <!-- AVP Sales Start -->
          <tr v-if="dtrs.avpSalesCat6Or16.data.length > 0">
            <td colspan="14"><b>AVP Sales</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.avpSalesCat6Or16.data" :key="`avpSalesCat6Or16_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.wu_charges, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat6Or16.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.avpSalesCat6Or16.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.avpSalesCat6Or16.totals.wuCharges, 'accounting') }}</td>
            <td class="bordertd digit" colspan="9">{{ formatData(dtrs.avpSalesCat6Or16.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat6Or16.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.avpSalesCat5.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- AVP Sales End -->

          <!-- AVP Sales Start -->
          <tr v-if="dtrs.avpSalesCat7.data.length > 0">
            <td colspan="14"><b>AVP Sales</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.avpSalesCat7.data" :key="`avpSalesCat7_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat7.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.avpSalesCat7.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="10">{{ formatData(dtrs.avpSalesCat7.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat7.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.avpSalesCat7.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- AVP Sales End -->

          <!-- AVP Sales Start -->
          <tr v-if="dtrs.avpSalesCat8.data.length > 0">
            <td colspan="14"><b>AVP Sales</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.avpSalesCat8.data" :key="`avpSalesCat8_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat8.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.avpSalesCat8.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="10">{{ formatData(dtrs.avpSalesCat8.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat8.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.avpSalesCat8.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- AVP Sales End -->

          <!-- AVP Sales Start -->
          <tr v-if="dtrs.avpSalesCat9.data.length > 0">
            <td colspan="14"><b>AVP Sales</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.avpSalesCat9.data" :key="`avpSalesCat9_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat9.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.avpSalesCat9.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="10">{{ formatData(dtrs.avpSalesCat9.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat9.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.avpSalesCat9.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- AVP Sales End -->

          <!-- AVP Sales Start -->
          <tr v-if="dtrs.avpSalesCat10Or11.data.length > 0">
            <td colspan="14"><b>AVP Sales</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.avpSalesCat10Or11.data" :key="`avpSalesCat10Or11_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat10Or11.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.avpSalesCat10Or11.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="10">{{ formatData(dtrs.avpSalesCat10Or11.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat10Or11.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.avpSalesCat10Or11.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- AVP Sales End -->

          <!-- AVP Sales Start -->
          <tr v-if="dtrs.avpSalesCat13Or12Point1.data.length > 0">
            <td colspan="14"><b>AVP Sales</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.avpSalesCat13Or12Point1.data" :key="`avpSalesCat13Or12Point1_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat13Or12Point1.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.avpSalesCat13Or12Point1.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="10">{{ formatData(dtrs.avpSalesCat13Or12Point1.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat13Or12Point1.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.avpSalesCat13Or12Point1.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- AVP Sales End -->

          <!-- AVP Sales Start -->
          <tr v-if="dtrs.avpSalesCat14Or12Point2.data.length > 0">
            <td colspan="14"><b>AVP Sales</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.avpSalesCat14Or12Point2.data" :key="`avpSalesCat14Or12Point2_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat14Or12Point2.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.avpSalesCat14Or12Point2.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="10">{{ formatData(dtrs.avpSalesCat14Or12Point2.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.avpSalesCat14Or12Point2.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.avpSalesCat14Or12Point2.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- AVP Sales End -->

          <!-- PeraBank Start -->
          <tr v-if="dtrs.perabank.data.length > 0">
            <td colspan="14"><b>PeraBank</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.perabank.data" :key="`perabank_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.wu_charges, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.ds_tax, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.net_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.perabank.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.perabank.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.perabank.totals.wuCharges, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.perabank.totals.dsTax, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.perabank.totals.netAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="7">{{ formatData(dtrs.perabank.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.perabank.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.perabank.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- PeraBank End -->

          <!-- BayadCenter Start -->
          <tr v-if="dtrs.bayadCenter.data.length > 0">
            <td colspan="14"><b>BayadCenter</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.bayadCenter.data" :key="`bayadCenter_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.wu_charges, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.ds_tax, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.net_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.bayadCenter.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.bayadCenter.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.bayadCenter.totals.wuCharges, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.bayadCenter.totals.dsTax, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.bayadCenter.totals.netAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="7">{{ formatData(dtrs.bayadCenter.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.bayadCenter.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.bayadCenter.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- BayadCenter End -->

          <!-- CTPL Start -->
          <tr v-if="dtrs.ctpl.data.length > 0">
            <td colspan="14"><b>CTPL</b></td>
          </tr>
          <tr v-for="(data, index) in dtrs.ctpl.data" :key="`ctpl_${index}`">
            <td class="date">{{ data.trx_date }}</td>
            <td>{{ data.reference_no }}</td>
            <td>{{ data.customer_name }}</td>
            <td>{{ data.trx_type }}</td>
            <td class="digit">{{ formatData(data.principal_amount, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.wu_charges, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.ds_tax, 'accounting') }}</td>
            <td class="digit">{{ formatData(data.net_amount, 'accounting') }}</td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit"></td>
            <td class="digit">{{ formatData(data.php_released, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.ctpl.data.length > 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="bordertd digit">{{ formatData(dtrs.ctpl.totals.principalAmount, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.ctpl.totals.wuCharges, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.ctpl.totals.dsTax, 'accounting') }}</td>
            <td class="bordertd digit">{{ formatData(dtrs.ctpl.totals.netAmount, 'accounting') }}</td>
            <td class="bordertd digit" colspan="7">{{ formatData(dtrs.ctpl.totals.phpReleaseReceived, 'accounting') }}</td>
          </tr>
          <tr v-if="dtrs.ctpl.data.length > 0">
            <td colspan="16" class="borderdouble"></td>
          </tr>
          <tr v-if="dtrs.ctpl.data.length > 0">
            <td colspan="4"></td>
            <td colspan="12" style="border-top: 1px solid #000;"></td>
          </tr>
          <!-- CTPL End -->

          <tr>
            <td colspan="2"><strong>Total of Petty cash, Expenses and Adjustment</strong></td>
            <td class="digit">{{ formatData(totalExpensesAdjustment, 'float') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  <div>
    <table id="tblDTRS2">
      <tbody>
        <tr>
          <th></th>
          <th>WU<br>PAYOUT</th>
          <th>WU<br>SENDOUT</th>
          <th>BEG.<br>BALANCE</th>
          <th>WU<br>REPL.</th>
          <th>MC<br>REPL</th>
          <th>MC (3RD<br>CURRENCY) REPL.</th>
          <th>REPLENISH-<br>MENT</th>
          <th>DEPED<br>PARTIAL</th>
          <th>AVP<br>COLLECTION</th>
          <th>NET<br>REPLENISHMENT</th>
        </tr>
        <tr>
          <td>Dollar</td>
          <td class="digit">{{ formatData(cpr.wuPayoutTotal.dollar, 'accounting') }}</td>
          <td class="digit">{{ formatData(cpr.wuSODeposit.dollar, 'accounting') }}</td>
          <td class="digit">{{ formatData(cpr.beginningBalance.dollar, 'accounting') }}</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td class="digit">{{ formatData(cpr.replenishmentToday.dollar, 'accounting') }}</td>
          <td class="digit">{{ formatData(cpr.depedPartial.dollar, 'accounting') }}</td>
          <td class="digit">{{ formatData(cpr.avp.dollar, 'accounting') }}</td>
          <td>{{ formatData(netReplenishmentDollar, 'accounting') }}</td>
        </tr>
        <tr>
          <td>Peso</td>
          <td class="digit">{{ formatData(cpr.wuPayoutTotal.peso, 'accounting') }}</td>
          <td class="digit">{{ formatData(cpr.wuSODeposit.peso, 'accounting') }}</td>
          <td class="digit">{{ formatData(cpr.beginningBalance.peso, 'accounting') }}</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>0.00</td>
          <td class="digit">{{ formatData(cpr.replenishmentToday.peso, 'accounting') }}</td>
          <td class="digit">{{ formatData(cpr.depedPartial.peso, 'accounting') }}</td>
          <td class="digit">{{ formatData(cpr.avp.peso, 'accounting') }}</td>
          <td>{{ formatData(netReplenishmentPeso, 'accounting') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
    <div style="width: 1200px; margin: 0 auto; overflow: hidden; border: 2px solid #000; border-top: none; border-bottom: none;">
        <div style="font-size: 10px; float:left; width: 350px; border-right: 2px solid #000;">
            <table id="tblDTRS3">
                <tbody><tr>
                    <th></th>
                    <th></th>
                    <th colspan="2">AMOUNT</th>
                </tr>
                <tr>
                    <td>MT</td>
                    <td>TXN #</td>
                    <td>Php</td>
                    <td>U$D</td>
                </tr>
                <tr>
                    <td>IMT Total</td>
                    <td>{{ dtrs.imt.payoutCount + dtrs.imt.sendoutCount }}</td>
                    <td>{{ formatData((dtrs.imt.payoutPeso + dtrs.imt.sendoutPeso), 'float') }}</td>
                    <td>{{ formatData((dtrs.imt.payoutDollar + dtrs.imt.sendoutDollar), 'float') }}</td>
                </tr>
                <tr>
                    <td>PO</td>
                    <td>{{ dtrs.imt.payoutCount }}</td>
                    <td>{{ formatData(dtrs.imt.payoutPeso, 'float') }}</td>
                    <td>{{ formatData(dtrs.imt.payoutDollar, 'float') }}</td>
                </tr>
                <tr>
                    <td>SO</td>
                    <td>{{ dtrs.imt.sendoutCount }}</td>
                    <td>{{ formatData(dtrs.imt.sendoutPeso, 'float') }}</td>
                    <td>{{ formatData(dtrs.imt.sendoutDollar, 'float') }}</td>
                </tr>

            </tbody></table>
        </div>
        <div id="dtrsDiv">
            <div><span class="leftarrow">⊲</span> IMT</div>
            <div>&nbsp;</div>
            <div>DMT <span class="rightarrow">⊳</span></div>
        </div>
        <div style="font-size: 10px; float:left; width: 350px; border-right: 2px solid #000;">
            <table id="tblDTRS4">
                <tbody><tr>
                    <th></th>
                    <th></th>
                    <th colspan="2">AMOUNT</th>
                </tr>
                <tr>
                    <td>MT</td>
                    <td>TXN #</td>
                    <td>Php</td>
                    <td>U$D</td>
                </tr>
                <tr>
                    <td>DMT Total</td>
                    <td>{{ dtrs.dmt.payoutCount + dtrs.dmt.sendoutCount }}</td>
                    <td>{{ formatData((dtrs.dmt.payoutPeso + dtrs.dmt.sendoutPeso), 'float') }}</td>
                    <td>{{ formatData((dtrs.dmt.payoutDollar + dtrs.dmt.sendoutDollar), 'float') }}</td>
                </tr>
                <tr>
                    <td>PO</td>
                    <td>{{ dtrs.dmt.payoutCount }}</td>
                    <td>{{ formatData(dtrs.dmt.payoutPeso, 'float') }}</td>
                    <td>{{ formatData(dtrs.dmt.payoutDollar, 'float') }}</td>
                </tr>
                <tr>
                    <td>SO</td>
                    <td>{{ dtrs.dmt.sendoutCount }}</td>
                    <td>{{ formatData(dtrs.dmt.sendoutPeso, 'float') }}</td>
                    <td>{{ formatData(dtrs.dmt.sendoutDollar, 'float') }}</td>
                </tr>
            </tbody></table>
        </div>
        <div id="dtrsDiv2">
            <div class="dtrsDiv2child">
                <div class="dtrsDiv2child2">
                    <div>DOLLAR(Short)/Over</div>
                    <div>PESO(Short)/Over</div>
                </div>
                <div class="dtrsDiv2child2">
                    <div>0.00</div>
                    <div>0.00</div>
                </div>
            </div>
            <div class="dtrsDiv2child">
                <div class="dtrsDiv2child2">
                    <div>TOTAL PESO TXN:</div>
                    <div>TOTAL DOLLAR TXN:</div>
                    <div>TOTAL TXN FOR THE DAY</div>
                </div>
                <div class="dtrsDiv2child2">
                    <div>{{ dtrs.countPeso }}</div>
                    <div>{{ dtrs.countDollar }}</div>
                    <div>{{ (dtrs.countPeso + dtrs.countDollar) }}</div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <table id="tblDTRS5">
            <tbody><tr>
                <th colspan="11">MONEY CHANGING DETAILS (FOR AVP USE ONLY)</th>
            </tr>
            <tr>
                <td rowspan="4">{{ dateToday }}<br>Date</td>
                <td rowspan="4">{{ params.location_name }}<br>Branch</td>
                <td></td>
                <td colspan="2">WESTERN UNION</td>
                <td colspan="2">MONEY CHANGING</td>
                <td colspan="2">TOTAL</td>
                <td colspan="2">Other Notes:</td>
            </tr>
            <tr>
                <td></td>
                <td># of trxn</td>
                <td>Amount</td>
                <td># of trxn</td>
                <td>Amount</td>
                <td># of trxn</td>
                <td>Amount</td>
                <td colspan="2" rowspan="3"></td>
            </tr>
            <tr>
                <td>BUY:</td>
                <td>{{ dtrs.countDollar }}</td>
                <td>{{ dtrs.payoutDollar.totals.convToPhpUsd }}</td>
                <td>{{ dtrs.mcBuyUSD.totals.buyCount }}</td>
                <td>{{ dtrs.mcBuyUSD.totals.buyTotal }}</td>
                <td>{{ (dtrs.countDollar + dtrs.mcBuyUSD.totals.buyCount) }}</td>
                <td>{{ (dtrs.payoutDollar.totals.convToPhpUsd + dtrs.mcBuyUSD.totals.buyTotal) }}</td>
            </tr>
            <tr>
                <td>SELL:</td>
                <td>0</td>
                <td>0.00</td>
                <td>{{ dtrs.mcBuyUSD.totals.sellCount }}</td>
                <td>{{ dtrs.mcBuyUSD.totals.sellTotal }}</td>
                <td>{{ dtrs.mcBuyUSD.totals.sellCount }}</td>
                <td>{{ dtrs.mcBuyUSD.totals.sellTotal }}</td>
            </tr>
        </tbody>
      </table>
    </div>
</div>
</template>

<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { format, parse } from 'date-fns'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    }),
    dateToday () {
      return (this.currUser.trx_date ? format(parse(this.currUser.trx_date, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy') : '')
    },
    dtrs () {
      const totalsDefault = {
        principalAmount: 0,
        wuCharges: 0,
        dsTax: 0,
        netAmount: 0,
        usdAdj: 0,
        usdReleaseReceived: 0,
        convToPhpUsd: 0,
        wuSellRate: 0,
        mcRateBuySell: 0,
        phpAdj: 0,
        phpReleaseReceived: 0
      }

      const payoutDollar = { data: [], totals: { ...totalsDefault } }
      const payoutPeso = { data: [], totals: { ...totalsDefault } }
      const nonexSendout = { data: [], totals: { ...totalsDefault } }
      const nonexPayout = { data: [], totals: { ...totalsDefault } }
      const payAtWURefund = { data: [], totals: { ...totalsDefault } }
      const lessSendoutPeso = { data: [], totals: { ...totalsDefault } }
      const lessPayAtWUPayment = { data: [], totals: { ...totalsDefault } }
      const mcBuyUSD = { data: [], totals: { ...totalsDefault, sellTotal: 0, buyTotal: 0, sellCount: 0, buyCount: 0 } }
      const avpSalesCat5 = { data: [], totals: { ...totalsDefault } }
      const avpSalesCat6Or16 = { data: [], totals: { ...totalsDefault } }
      const avpSalesCat7 = { data: [], totals: { ...totalsDefault } }
      const avpSalesCat8 = { data: [], totals: { ...totalsDefault } }
      const avpSalesCat9 = { data: [], totals: { ...totalsDefault } }
      const avpSalesCat10Or11 = { data: [], totals: { ...totalsDefault } }
      const avpSalesCat13Or12Point1 = { data: [], totals: { ...totalsDefault } }
      const avpSalesCat14Or12Point2 = { data: [], totals: { ...totalsDefault } }
      const perabank = { data: [], totals: { ...totalsDefault } }
      const bayadCenter = { data: [], totals: { ...totalsDefault } }
      const ctpl = { data: [], totals: { ...totalsDefault } }
      const imt = { payoutCount: 0, payoutDollar: 0, payoutPeso: 0, sendoutCount: 0, sendoutDollar: 0, sendoutPeso: 0 }
      const dmt = { payoutCount: 0, payoutDollar: 0, payoutPeso: 0, sendoutCount: 0, sendoutDollar: 0, sendoutPeso: 0 }
      let countPeso = 0
      let countDollar = 0

      if (this.reportData && this.reportData.dtrs && this.reportData.dtrs.length > 0) {
        for (const data of this.reportData.dtrs) {
          if (data.category === 1) {
            payoutDollar.data.push(data)
            payoutDollar.totals.principalAmount += data.principal_amount
            // payoutDollar.totals.wuCharges += data.
            payoutDollar.totals.dsTax += data.ds_tax
            payoutDollar.totals.netAmount += data.net_amount
            // payoutDollar.totals.usdAdj += data.
            payoutDollar.totals.usdReleaseReceived += data.usd_released
            payoutDollar.totals.convToPhpUsd += data.buy_back
            payoutDollar.totals.wuSellRate += data.wu_sell_rate
            payoutDollar.totals.mcRateBuySell += data.mc_rate
            // payoutDollar.totals.phpAdj += data.
            payoutDollar.totals.phpReleaseReceived += data.php_released

            if (data.trx_type === 'PO-IMT') {
              imt.payoutCount += 1
              imt.payoutDollar += data.usd_released
              imt.payoutPeso += data.php_released
            }

            countDollar += 1
          }

          if (data.category === 2) {
            payoutPeso.data.push(data)
            payoutPeso.totals.principalAmount += data.principal_amount
            // payoutPeso.totals.wuCharges += data.
            payoutPeso.totals.dsTax += data.ds_tax
            payoutPeso.totals.netAmount += data.net_amount
            // payoutPeso.totals.usdAdj += data.
            // payoutPeso.totals.usdReleaseReceived += data.
            // payoutPeso.totals.convToPhpUsd += data.
            // payoutPeso.totals.wuSellRate += data.
            // payoutPeso.totals.mcRateBuySell += data.
            // payoutPeso.totals.phpAdj += data.
            payoutPeso.totals.phpReleaseReceived += data.php_released

            if (data.trx_type === 'PO-IMT') {
              imt.payoutCount += 1
              imt.payoutDollar += data.usd_released
              imt.payoutPeso += data.php_released
            }

            if (data.trx_type === 'PO-DMT') {
              dmt.payoutCount += 1
              dmt.payoutDollar += data.usd_released
              dmt.payoutPeso += data.php_released
            }

            countPeso += 1
          }

          if (data.category > 100 && data.category < 300) {
            nonexSendout.data.push(data)
            nonexSendout.totals.principalAmount += data.principal_amount
            // nonexSendout.totals.wuCharges += data.
            nonexSendout.totals.dsTax += data.ds_tax
            nonexSendout.totals.netAmount += data.net_amount
            // nonexSendout.totals.usdAdj += data.
            nonexSendout.totals.usdReleaseReceived += data.usd_released
            nonexSendout.totals.convToPhpUsd += data.buy_back
            nonexSendout.totals.wuSellRate += data.wu_sell_rate
            nonexSendout.totals.mcRateBuySell += data.mc_rate
            // nonexSendout.totals.phpAdj += data.
            nonexSendout.totals.phpReleaseReceived += data.php_released

            if (data.trx_type === 'SO-IMT') {
              imt.sendoutCount += 1
              imt.sendoutDollar += data.usd_released
              imt.sendoutPeso += data.php_released
            }

            if (data.trx_type === 'SO-DMT') {
              dmt.sendoutCount += 1
              dmt.sendoutDollar += data.usd_released
              dmt.sendoutPeso += data.php_released
            }

            countPeso += 1
          }

          if (data.category > 300) {
            nonexPayout.data.push(data)
            nonexPayout.totals.principalAmount += data.principal_amount
            // nonexPayout.totals.wuCharges += data.
            nonexPayout.totals.dsTax += data.ds_tax
            nonexPayout.totals.netAmount += data.net_amount
            // nonexPayout.totals.usdAdj += data.
            nonexPayout.totals.usdReleaseReceived += data.usd_released
            nonexPayout.totals.convToPhpUsd += data.buy_back
            nonexPayout.totals.wuSellRate += data.wu_sell_rate
            nonexPayout.totals.mcRateBuySell += data.mc_rate
            // nonexPayout.totals.phpAdj += data.
            nonexPayout.totals.phpReleaseReceived += data.php_released

            if (data.trx_type === 'PO-IMT') {
              imt.payoutCount += 1
              imt.payoutDollar += data.usd_released
              imt.payoutPeso += data.php_released
            }

            if (data.trx_type === 'PO-DMT') {
              dmt.payoutCount += 1
              dmt.payoutDollar += data.usd_released
              dmt.payoutPeso += data.php_released
            }

            countPeso += 1
          }

          if (data.category === 17) {
            payAtWURefund.data.push(data)
            payAtWURefund.totals.principalAmount += data.principal_amount
            // payAtWURefund.totals.wuCharges += data.
            // payAtWURefund.totals.dsTax += data.
            // payAtWURefund.totals.netAmount += data.
            // payAtWURefund.totals.usdAdj += data.
            // payAtWURefund.totals.usdReleaseReceived += data.
            // payAtWURefund.totals.convToPhpUsd += data.
            // payAtWURefund.totals.wuSellRate += data.
            // payAtWURefund.totals.mcRateBuySell += data.
            // payAtWURefund.totals.phpAdj += data.
            payAtWURefund.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 3) {
            lessSendoutPeso.data.push(data)
            lessSendoutPeso.totals.principalAmount += data.principal_amount
            lessSendoutPeso.totals.wuCharges += data.wu_charges
            // lessSendoutPeso.totals.dsTax += data.
            lessSendoutPeso.totals.netAmount += data.net_amount
            // lessSendoutPeso.totals.usdAdj += data.
            // lessSendoutPeso.totals.usdReleaseReceived += data.
            // lessSendoutPeso.totals.convToPhpUsd += data.
            // lessSendoutPeso.totals.wuSellRate += data.
            // lessSendoutPeso.totals.mcRateBuySell += data.
            lessSendoutPeso.totals.phpAdj += data.php_released
            // lessSendoutPeso.totals.phpReleaseReceived += data.

            if (data.trx_type === 'SO-IMT') {
              imt.sendoutCount += 1
              imt.sendoutDollar += data.usd_released
              imt.sendoutPeso += data.php_released
            }

            if (data.trx_type === 'SO-DMT') {
              dmt.sendoutCount += 1
              dmt.sendoutDollar += data.usd_released
              dmt.sendoutPeso += data.php_released
            }

            countPeso += 1
          }

          if (data.category === 12) {
            lessPayAtWUPayment.data.push(data)
            lessPayAtWUPayment.totals.principalAmount += data.principal_amount
            // lessPayAtWUPayment.totals.wuCharges += data.
            // lessPayAtWUPayment.totals.dsTax += data.
            // lessPayAtWUPayment.totals.netAmount += data.
            // lessPayAtWUPayment.totals.usdAdj += data.
            // lessPayAtWUPayment.totals.usdReleaseReceived += data.
            // lessPayAtWUPayment.totals.convToPhpUsd += data.
            // lessPayAtWUPayment.totals.wuSellRate += data.
            // lessPayAtWUPayment.totals.mcRateBuySell += data.
            // lessPayAtWUPayment.totals.phpAdj += data.
            lessPayAtWUPayment.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 4.2) {
            mcBuyUSD.data.push(data)
            mcBuyUSD.totals.principalAmount += data.principal_amount
            if (data.trx_type === 'BUY' || data.trx_type === 'MC-BUY (CANCELLED)') {
              mcBuyUSD.totals.buyTotal += data.principal_amount
              mcBuyUSD.totals.buyCount += 1
            }
            if (data.trx_type === 'SELL') {
              mcBuyUSD.totals.sellTotal += data.principal_amount
              mcBuyUSD.totals.sellCount += 1
            }
            // mcBuyUSD.totals.wuCharges += data.
            // mcBuyUSD.totals.dsTax += data.
            // mcBuyUSD.totals.netAmount += data.
            // mcBuyUSD.totals.usdAdj += data.
            mcBuyUSD.totals.usdReleaseReceived += data.usd_released
            mcBuyUSD.totals.convToPhpUsd += data.buy_back
            // mcBuyUSD.totals.wuSellRate += data.
            mcBuyUSD.totals.mcRateBuySell += data.mc_rate
            // mcBuyUSD.totals.phpAdj += data.
            mcBuyUSD.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 5) {
            avpSalesCat5.data.push(data)
            avpSalesCat5.totals.principalAmount += data.principal_amount
            // avpSalesCat5.totals.wuCharges += data.
            // avpSalesCat5.totals.dsTax += data.
            // avpSalesCat5.totals.netAmount += data.
            // avpSalesCat5.totals.usdAdj += data.
            // avpSalesCat5.totals.usdReleaseReceived += data.
            // avpSalesCat5.totals.convToPhpUsd += data.
            // avpSalesCat5.totals.wuSellRate += data.
            // avpSalesCat5.totals.mcRateBuySell += data.
            // avpSalesCat5.totals.phpAdj += data.
            avpSalesCat5.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 6 || data.category === 16) {
            avpSalesCat6Or16.data.push(data)
            avpSalesCat6Or16.totals.principalAmount += data.principal_amount
            avpSalesCat6Or16.totals.wuCharges += data.wu_charges
            // avpSalesCat6Or16.totals.dsTax += data.
            // avpSalesCat6Or16.totals.netAmount += data.
            // avpSalesCat6Or16.totals.usdAdj += data.
            // avpSalesCat6Or16.totals.usdReleaseReceived += data.
            // avpSalesCat6Or16.totals.convToPhpUsd += data.
            // avpSalesCat6Or16.totals.wuSellRate += data.
            // avpSalesCat6Or16.totals.mcRateBuySell += data.
            // avpSalesCat6Or16.totals.phpAdj += data.
            avpSalesCat6Or16.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 7) {
            avpSalesCat7.data.push(data)
            avpSalesCat7.totals.principalAmount += data.principal_amount
            // avpSalesCat7.totals.wuCharges += data.
            // avpSalesCat7.totals.dsTax += data.
            // avpSalesCat7.totals.netAmount += data.
            // avpSalesCat7.totals.usdAdj += data.
            // avpSalesCat7.totals.usdReleaseReceived += data.
            // avpSalesCat7.totals.convToPhpUsd += data.
            // avpSalesCat7.totals.wuSellRate += data.
            // avpSalesCat7.totals.mcRateBuySell += data.
            // avpSalesCat7.totals.phpAdj += data.
            avpSalesCat7.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 8) {
            avpSalesCat8.data.push(data)
            avpSalesCat8.totals.principalAmount += data.principal_amount
            // avpSalesCat8.totals.wuCharges += data.
            // avpSalesCat8.totals.dsTax += data.
            // avpSalesCat8.totals.netAmount += data.
            // avpSalesCat8.totals.usdAdj += data.
            // avpSalesCat8.totals.usdReleaseReceived += data.
            // avpSalesCat8.totals.convToPhpUsd += data.
            // avpSalesCat8.totals.wuSellRate += data.
            // avpSalesCat8.totals.mcRateBuySell += data.
            // avpSalesCat8.totals.phpAdj += data.
            avpSalesCat8.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 9) {
            avpSalesCat9.data.push(data)
            avpSalesCat9.totals.principalAmount += data.principal_amount
            // avpSalesCat9.totals.wuCharges += data.
            // avpSalesCat9.totals.dsTax += data.
            // avpSalesCat9.totals.netAmount += data.
            // avpSalesCat9.totals.usdAdj += data.
            // avpSalesCat9.totals.usdReleaseReceived += data.
            // avpSalesCat9.totals.convToPhpUsd += data.
            // avpSalesCat9.totals.wuSellRate += data.
            // avpSalesCat9.totals.mcRateBuySell += data.
            // avpSalesCat9.totals.phpAdj += data.
            avpSalesCat9.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 10 || data.category === 11) {
            avpSalesCat10Or11.data.push(data)
            avpSalesCat10Or11.totals.principalAmount += data.principal_amount
            // avpSalesCat10Or11.totals.wuCharges += data.
            // avpSalesCat10Or11.totals.dsTax += data.
            // avpSalesCat10Or11.totals.netAmount += data.
            // avpSalesCat10Or11.totals.usdAdj += data.
            // avpSalesCat10Or11.totals.usdReleaseReceived += data.
            // avpSalesCat10Or11.totals.convToPhpUsd += data.
            // avpSalesCat10Or11.totals.wuSellRate += data.
            // avpSalesCat10Or11.totals.mcRateBuySell += data.
            // avpSalesCat10Or11.totals.phpAdj += data.
            avpSalesCat10Or11.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 13 || data.category === 12.1) {
            avpSalesCat13Or12Point1.data.push(data)
            avpSalesCat13Or12Point1.totals.principalAmount += data.principal_amount
            // avpSalesCat13Or12Point1.totals.wuCharges += data.
            // avpSalesCat13Or12Point1.totals.dsTax += data.
            // avpSalesCat13Or12Point1.totals.netAmount += data.
            // avpSalesCat13Or12Point1.totals.usdAdj += data.
            // avpSalesCat13Or12Point1.totals.usdReleaseReceived += data.
            // avpSalesCat13Or12Point1.totals.convToPhpUsd += data.
            // avpSalesCat13Or12Point1.totals.wuSellRate += data.
            // avpSalesCat13Or12Point1.totals.mcRateBuySell += data.
            // avpSalesCat13Or12Point1.totals.phpAdj += data.
            avpSalesCat13Or12Point1.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 14 || data.category === 12.2) {
            avpSalesCat14Or12Point2.data.push(data)
            avpSalesCat14Or12Point2.totals.principalAmount += data.principal_amount
            // avpSalesCat14Or12Point2.totals.wuCharges += data.
            // avpSalesCat14Or12Point2.totals.dsTax += data.
            // avpSalesCat14Or12Point2.totals.netAmount += data.
            // avpSalesCat14Or12Point2.totals.usdAdj += data.
            // avpSalesCat14Or12Point2.totals.usdReleaseReceived += data.
            // avpSalesCat14Or12Point2.totals.convToPhpUsd += data.
            // avpSalesCat14Or12Point2.totals.wuSellRate += data.
            // avpSalesCat14Or12Point2.totals.mcRateBuySell += data.
            // avpSalesCat14Or12Point2.totals.phpAdj += data.
            avpSalesCat14Or12Point2.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 201) {
            perabank.data.push(data)
            perabank.totals.principalAmount += data.principal_amount
            perabank.totals.wuCharges += data.wu_charges
            perabank.totals.dsTax += data.ds_tax
            perabank.totals.netAmount += data.net_amount
            // perabank.totals.usdAdj += data.
            // perabank.totals.usdReleaseReceived += data.
            // perabank.totals.convToPhpUsd += data.
            // perabank.totals.wuSellRate += data.
            // perabank.totals.mcRateBuySell += data.
            // perabank.totals.phpAdj += data.
            perabank.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 51) {
            bayadCenter.data.push(data)
            bayadCenter.totals.principalAmount += data.principal_amount
            bayadCenter.totals.wuCharges += data.wu_charges
            bayadCenter.totals.dsTax += data.ds_tax
            bayadCenter.totals.netAmount += data.net_amount
            // bayadCenter.totals.usdAdj += data.
            // bayadCenter.totals.usdReleaseReceived += data.
            // bayadCenter.totals.convToPhpUsd += data.
            // bayadCenter.totals.wuSellRate += data.
            // bayadCenter.totals.mcRateBuySell += data.
            // bayadCenter.totals.phpAdj += data.
            bayadCenter.totals.phpReleaseReceived += data.php_released
          }

          if (data.category === 81) {
            ctpl.data.push(data)
            ctpl.totals.principalAmount += data.principal_amount
            ctpl.totals.wuCharges += data.wu_charges
            ctpl.totals.dsTax += data.ds_tax
            ctpl.totals.netAmount += data.net_amount
            // ctpl.totals.usdAdj += data.
            // ctpl.totals.usdReleaseReceived += data.
            // ctpl.totals.convToPhpUsd += data.
            // ctpl.totals.wuSellRate += data.
            // ctpl.totals.mcRateBuySell += data.
            // ctpl.totals.phpAdj += data.
            ctpl.totals.phpReleaseReceived += data.php_released
          }
        }
      }

      return {
        payoutDollar,
        payoutPeso,
        nonexSendout,
        nonexPayout,
        payAtWURefund,
        lessSendoutPeso,
        lessPayAtWUPayment,
        mcBuyUSD,
        avpSalesCat5,
        avpSalesCat6Or16,
        avpSalesCat7,
        avpSalesCat8,
        avpSalesCat9,
        avpSalesCat10Or11,
        avpSalesCat13Or12Point1,
        avpSalesCat14Or12Point2,
        perabank,
        bayadCenter,
        ctpl,
        imt,
        dmt,
        countPeso,
        countDollar
      }
    },
    cpr () {
      const revolvingFunds = { peso: 0, dollar: 0 }
      const beginningBalance = { peso: 0, dollar: 0 }
      const replenishmentToday = { peso: 0, dollar: 0 }
      const wuSODeposit = { peso: 0, dollar: 0 }
      const mcDeposit = { peso: 0, dollar: 0 }
      const totalDepositToday = { peso: 0, dollar: 0 }
      const wuPayoutTotal = { peso: 0, dollar: 0 }
      const debit = { peso: 0, dollar: 0 }
      const credit = { peso: 0, dollar: 0 }
      const endingBalance = { peso: 0, dollar: 0 }
      const depedPartial = { peso: 0, dollar: 0 }
      const avp = { peso: 0, dollar: 0 }

      if (this.reportData && this.reportData.cpr && this.reportData.cpr.length > 0) {
        for (const data of this.reportData.cpr) {
          if (data.currency_id === 1) {
            revolvingFunds.peso = data.rfa_peso
            beginningBalance.peso = data.beg_balance
            replenishmentToday.peso += data.replenishment
            wuSODeposit.peso += data.wu_sendout
            mcDeposit.peso += data.mc
            totalDepositToday.peso += data.deposits
            wuPayoutTotal.peso += data.wu_payout
            debit.peso += data.ibt_debit
            credit.peso += data.ibt_credit
            endingBalance.peso = data.ending_balance
            depedPartial.peso += data.deped_partial
            avp.peso += data.avp
          }

          if (data.currency_id === 2) {
            revolvingFunds.dollar = data.rfa_dollar
            beginningBalance.dollar = data.beg_balance
            replenishmentToday.dollar += data.replenishment
            wuSODeposit.dollar += data.wu_sendout
            mcDeposit.dollar += data.mc
            totalDepositToday.dollar += data.deposits
            wuPayoutTotal.dollar += data.wu_payout
            debit.dollar += data.ibt_debit
            credit.dollar += data.ibt_credit
            endingBalance.dollar = data.ending_balance
            depedPartial.dollar += data.deped_partial
            avp.dollar += data.avp
          }
        }
      }

      return {
        revolvingFunds,
        beginningBalance,
        replenishmentToday,
        wuSODeposit,
        mcDeposit,
        totalDepositToday,
        wuPayoutTotal,
        debit,
        credit,
        endingBalance,
        depedPartial,
        avp
      }
    },
    netReplenishmentDollar () {
      return (this.dtrs.payoutDollar.totals.netAmount - this.dtrs.payoutDollar.totals.convToPhpUsd + this.dtrs.mcBuyUSD.totals.sellTotal - this.dtrs.mcBuyUSD.totals.buyTotal)
    },
    netReplenishmentPeso () {
      return (this.dtrs.payoutDollar.totals.phpReleaseReceived + this.dtrs.payoutPeso.totals.phpReleaseReceived - this.dtrs.lessSendoutPeso.totals.phpReleaseReceived + this.dtrs.mcBuyUSD.totals.phpReleaseReceived - this.dtrs.avpSalesCat5.totals.phpReleaseReceived - this.dtrs.avpSalesCat6Or16.totals.phpReleaseReceived - this.dtrs.avpSalesCat7.totals.phpReleaseReceived - this.dtrs.avpSalesCat8.totals.phpReleaseReceived + this.dtrs.avpSalesCat9.totals.phpReleaseReceived - this.dtrs.avpSalesCat10Or11.totals.phpReleaseReceived - this.dtrs.lessPayAtWUPayment.totals.principalAmount - this.dtrs.avpSalesCat13Or12Point1.totals.phpReleaseReceived + this.dtrs.avpSalesCat14Or12Point2.totals.phpReleaseReceived + this.dtrs.payAtWURefund.totals.principalAmount + this.dtrs.nonexPayout.totals.phpReleaseReceived - this.dtrs.nonexSendout.totals.phpReleaseReceived - this.dtrs.perabank.totals.phpReleaseReceived - this.dtrs.bayadCenter.totals.phpReleaseReceived - this.dtrs.ctpl.totals.phpReleaseReceived - this.cpr.wuSODeposit.peso)
    },
    totalExpensesAdjustment () {
      if (this.reportData && this.reportData.location_expense && this.reportData.location_expense.total_expenses_adjustment) {
        return this.reportData.location_expense.total_expenses_adjustment
      }
      return 0
    }
  },
  props: [
    'isLoading',
    'reportData',
    'columns',
    'params'
  ],
  methods: {
    formatData (data, type) {
      if (type === 'float') return numeral(data).format('0,0.00')
      if (type === 'accounting') return this.getAccountingFormat(data)

      return data
    },
    getAccountingFormat (amount) {
      const number = Number(amount)

      if (number < 0) {
        return `(${numeral(amount).format('0,0.00')})`
      }

      return numeral(amount).format('0,0.00')
    }
  }
}
</script>

<style scoped>
body {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFFFFF), color-stop(1, #FFFFFF));
  background-image: -o-linear-gradient(bottom, #FFFFFF 0%, #FFFFFF 100%);
  background-image: -moz-linear-gradient(bottom, #FFFFFF 0%, #FFFFFF 100%);
  background-image: -webkit-linear-gradient(bottom, #FFFFFF 0%, #FFFFFF 100%);
  background-image: -ms-linear-gradient(bottom, #FFFFFF 0%, #FFFFFF 100%);
  background-image: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 100%);
}

table
{
    border-bottom: 1px solid #ddd !important;
    background-color: #fff !important;
}

@media screen {
  #intVal {
      visibility: visible;
  }
}

@media print {
  thead {
      display: table-header-group;
  }
}

#tbllsgreport, #tblgwautocashin, #tblsales, #tblDTRS, #tblCPR, #tblCRS, #tblDTRS2, #tblDTRS5, #tblcprs, #tblavpmc, #compstrd, #tblavpdaily, #tblrecadj, #tblnfc, #tblnonex, #tblcebreport, #tblbegbalreport, #tblbranchvarreport, #tblbpdtrreport, #tblchangerateproductreport, #tblmonthlyregionreport, #tblnewavpdailyreport, #tblnewavpmonthlyreport, #tblibtreport, #tblreportbalancereset, #tblreportpwdreset, #slsbillpay, #tblbananapay, #tblreconvarreport, #tblreconadjreport, #tblloyaltypromoreport, #tblinstantcash, #tblctpl, #tblctplcancel, #tblctplammend, #tblbayadcenterreport, #tbljournal, #tblcicolimit, #tblcicolimitlog {
  width: 99.9%;
}

#tblbpwubb, #tblbpins, #tblbpload, #tblbpsumloadl, #tblbpsumloadd, #tblbpsuminsl, #tblbpsuminsd, #tblbpsumwubbd, #tblbpsumwubbl, #tblmbbtbp, #tbldetwubbfc, #tblsumwubbfc, #tblexpnses {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

#tbllsgreport thead tr th, #tblgwautocashin thead tr th, #tblavpdaily thead tr th, #tblcebreport thead tr th, #tblbegbalreport thead tr th, #tblbranchvarreport thead tr th, #tblbpdtrreport thead tr th, #tblchangerateproductreport thead tr th, #tblmonthlyregionreport thead tr th, #tblnewavpdailyreport thead tr th, #tblnewavpmonthlyreport thead tr th, #tblibtreport thead tr th, #tblreportbalancereset thead tr th, #tblreportpwdreset thead tr th, #slsbillpay thead tr th, #tblbananapay, thead tr th, #tblreconvarreport thead tr th, #tblrecadj thead tr th, #tblnfc thead tr th, #tblsales thead tr th, #tblnonex thead tr th, #tblcompctr thead tr th, #tblDTRS thead tr th, #tblCPR thead tr th, #tblTrxLogs thead tr th, #tblCRS thead tr th, #tblbpwubb thead tr th, #tblbpins thead tr th, #tblbpload thead tr th, #tblbpsumloadl thead tr th, #tblbpsumloadd thead tr th, #tblbpsuminsl thead tr th, #tblbpsuminsd thead tr th, #tblbpsumwubbd thead tr th, #tblbpsumwubbl thead tr th, #tblmbbtbp thead tr th, #tbldetwubbfc thead tr th, #tblsumwubbfc thead tr th, #tblexpnses thead tr th, #tblinstantcash thead tr th, #tblctpl thead tr th, #tblctplcancel thead tr th, #tblctplammend thead tr th, #tblbayadcenterreport thead tr th, #tbljournal thead tr th, #tblcicolimit thead tr th, #tblcicolimitlog thead tr th {
  padding: 3px;
}

#tblcprs thead tr th {
  padding: 3px;
}

#tbllsgreport tbody tr th, #tblgwautocashin tbody tr th, #tblsales tbody tr th, #tblnonex tbody tr th, #tblrecadj tbody tr th, #tblnfc tbody tr th, #tblavpdaily tbody tr th, #tblcebreport tbody tr th, #tblbegbalreport tbody tr th, #tblbranchvarreport tbody tr th, #tblbpdtrreport tbody tr th, #tblchangerateproductreport tbody tr th, #tblmonthlyregionreport tbody tr th, #tblnewavpdailyreport tbody tr th, #tblnewavpmonthlyreport tbody tr th, #tblibtreport tbody tr th, #tblreportbalancereset tbody tr th, #tblreportpwdreset tbody tr th, #slsbillpay tbody tr th, #tblbananapay tbody tr th, #tblreconvarreport tbody tr th, #tblreconadjreport tbody tr th, #tblloyaltypromoreport tbody tr th, #tblcompctr tbody tr th, #tblDTRS tbody tr th, #tblCPR tbody tr th, #tblCRS tbody tr th, #tblTrxLogs tbody tr th, #tblinstantcash tbody tr th, #tblctpl tbody tr th, #tblctplcancel tbody tr th, #tblctplammend tbody tr th, #tblbayadcenterreport tbody tr th, #tbljournal tbody tr th, #tblcicolimit tbody tr th, #tblcicolimitlog tbody tr th {
  text-align: center !important;
  font-size: 10px;
}

#tblbpwubb tbody tr th, #tblbpins tbody tr th, #tblbpload tbody tr th, #tblbpsumloadl tbody tr th, #tblbpsumloadd tbody tr th, #tblbpsuminsl tbody tr th, #tblbpsuminsd tbody tr th, #tblbpsumwubbd tbody tr th, #tblbpsumwubbl tbody tr th, #tblmbbtbp tbody tr th, #tbldetwubbfc tbody tr th, #tblsumwubbfc tbody tr th, #tblexpnses tbody tr th {
  text-align: center !important;
  font-size: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: #006dcc;
}

#tblcprs tbody tr th {
  text-align: center !important;
  font-size: 10px;
}

#tblbpwubb tbody tr:nth-child(even), #tblbpins tbody tr:nth-child(even), #tblbpload tbody tr:nth-child(even), #tblbpsumloadl tbody tr:nth-child(even), #tblbpsumloadd tbody tr:nth-child(even), #tblbpsuminsl tbody tr:nth-child(even), #tblbpsuminsd tbody tr:nth-child(even), #tblbpsumwubbd tbody tr:nth-child(even), #tblbpsumwubbl tbody tr:nth-child(even), #tblmbbtbp tbody tr:nth-child(even), #tblsumwubbfc tbody tr:nth-child(even), #tbldetwubbfc tbody tr:nth-child(even), #tblexpnses tbody tr:nth-child(even) {
  background-color: #f2f2f2
}

#tbllsgreport tbody tr td, #tblgwautocashin tbody tr td, #tblsales tbody tr td, #tblnonex tbody tr td, #tblnfc tbody tr td, #tblrecadj tbody tr td, #tblavpdaily tbody tr td, #tblcebreport tbody tr td, #tblbegbalreport tbody tr td, #tblbranchvarreport tbody tr td, #tblbpdtrreport tbody tr td, #tblchangerateproductreport tbody tr td, #tblmonthlyregionreport tbody tr td, #tblnewavpdailyreport tbody tr td, #tblnewavpmonthlyreport tbody tr td, #tblibtreport tbody tr td, #tblreportbalancereset tbody tr td, #tblreportpwdreset tbody tr td, #slsbillpay tbody tr td, #tblbananapay tbody tr td, #tblreconvarreport tbody tr td, #tblreconadjreport tbody tr td, #tblloyaltypromoreport tbody tr td, #tblcompctr tbody tr td, #tblDTRS tbody tr td, #tblCPR tbody tr td, #tblCRS tbody tr td, #tblTrxLogs tbody tr td, #tblinstantcash tbody tr td, #tblctpl tbody tr td, #tblctplcancel tbody tr td, #tblctplammend tbody tr td, #tblbayadcenterreport tbody tr td, #tbljournal tbody tr td, #tblcicolimit tbody tr td, #tblcicolimitlog tbody tr td {
  padding: 3px;
  font-size: 10px;
}

#tblbpwubb tbody tr td, #tblbpins tbody tr td, #tblbpload tbody tr td, #tblbpsumloadl tbody tr td, #tblbpsumloadd tbody tr td, #tblbpsuminsl tbody tr td, #tblbpsuminsd tbody tr td, #tblbpsumwubbd tbody tr td, #tblbpsumwubbl tbody tr td, #tblmbbtbp tbody tr td, #tblsumwubbfc tbody tr td, #tbldetwubbfc tbody tr td, #tblexpnses tbody tr td {
  padding: 7px;
  font-size: 11px;
  border-style: dotted;
  border-width: 1px;
  border-color: #428bca;
}

.digit {
  text-align: right;
}

.date {
  text-align: left;
}

.bordertd {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  /*double;*/
}

.borderdouble {
  padding: 2px 0 !important;
}

#tblcprDiv {
  margin: 20px auto 0 auto;
  width: 80%;
}

#tblcprDiv table {
  width: 100%;
  font-size: 12px;
  border: 1px solid #000;
}

#tblcprDiv table tr:nth-child(1) th {
  background-color: #ddd;
  text-align: center;
}

#tblcprDiv table tr td {
  padding: 3px;
}

#dtrstoptbl {
  font-size: 10px;
}

div.dtrslabel {
  float: left;
  width: 274.75px;
  padding: 16px 0 0 0;
  font-weight: bold;
  text-align: right;
}

#dtrstoptbl table tr:first-child {
  border-bottom: 1px solid #000;
}

#dtrstoptbl table tr:last-child {
  border-bottom: 1px solid #000;
}

#dtrstoptbl table tr:first-child th {
  text-align: center;
}

#dtrstoptbl table tr:nth-child(1) td {
  border: none;
}

#dtrstoptbl table tr td {
  padding: 3px 5px;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

#tblDTRS2 {
  margin: 30px 0 0 0;
  font-size: 10px;
}

#tblDTRS2 tbody tr {
  border: 3px solid #000 !important;
}

#tblDTRS2 tbody tr:first-child {
  border: none !important;
}

#tblDTRS2 tbody tr:first-child th {
  border-right: none;
}

#tblDTRS2 tbody tr th, #tblDTRS2 tbody tr td {
  padding: 3px;
  text-align: right;
}

#tblDTRS2 tbody tr td:nth-child(2) {
  border-left: 2px solid #000;
}

#tblDTRS2 tbody tr td:nth-child(3) {
  border-right: 2px solid #000;
}

#tblDTRS2 tbody tr td:nth-child(1) {
  font-weight: bold;
  text-align: center;
}

#tblDTRS3, #tblDTRS4 {
  width: 100%;
}

#tblDTRS3 tbody tr td, #tblDTRS4 tbody tr td {
  padding: 3px;
}

#tblDTRS3 tbody tr td:nth-child(1), #tblDTRS4 tbody tr td:nth-child(1) {
  font-weight: bold;
}

#tblDTRS3 tbody tr:nth-child(1) th:nth-child(3), #tblDTRS4 tbody tr:nth-child(1) th:nth-child(3) {
  text-align: center;
}

#tblDTRS3 tbody tr:nth-child(2) td, #tblDTRS4 tbody tr:nth-child(2) td {
  text-align: center;
  font-weight: bold;
}

#dtrsDiv {
  float: left;
  width: 100px;
  border-right: 2px solid #000;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  padding: 3px;
}

#dtrsDiv div:nth-child(2) {
  width: 100%;
  background-color: #999;
}

#dtrsDiv2 {
  font-size: 10px;
  float: left;
  width: 396px;
}

div.dtrsDiv2child {
  width: 100%;
  border-bottom: 2px solid #000;
  overflow: hidden;
  padding: 3px;
}

div.dtrsDiv2child:last-child {
  border-bottom: none;
}

div.dtrsDiv2child2 {
  float: left;
  width: 50%;
}

div.dtrsDiv2child2:first-child {
  font-weight: bold;
}

#tblDTRS5 {
  border: 3px solid #000;
  font-size: 10px;
}

#tblDTRS5 tbody tr:first-child {
  border-bottom: 3px solid #000;
}

#tblDTRS5 tbody tr th, #tblDTRS5 tbody tr td {
  padding: 3px;
}

#tblDTRS5 tbody tr:first-child th {
  text-align: center;
}

#tblDTRS5 tbody tr:nth-child(2) td:first-child {
  border-right: none;
  border-bottom: 3px solid #000;
}

#tblDTRS5 tbody tr:nth-child(2) td:nth-child(2) {
  border-bottom: 3px solid #000;
}

#tblDTRS5 tbody tr:nth-child(2) td, #tblDTRS5 tbody tr:nth-child(3) td {
  border-right: 3px solid #000;
  text-align: center;
  font-weight: bold;
}

#tblDTRS5 tbody tr:nth-child(3) td {
  border-bottom: 3px solid #000;
}

#tblDTRS5 tbody tr:nth-child(3) td:first-child {
  border-bottom: none;
}

#tblDTRS5 tbody tr:nth-child(3) td:nth-child(2), #tblDTRS5 tbody tr:nth-child(3) td:nth-child(4), #tblDTRS5 tbody tr:nth-child(3) td:nth-child(6) {
  border-right: none;
}

#tblDTRS5 tbody tr:nth-child(4) td, #tblDTRS5 tbody tr:last-child td {
  border-right: 3px solid #000;
}

#tblDTRS5 tbody tr:nth-child(4) td:first-child, #tblDTRS5 tbody tr:last-child td:first-child {
  text-align: right;
  font-weight: bold;
}

#tblDTRS5 tbody tr:last-child td {
  border-bottom: 3px solid #000;
}

.hideObj {
  display: none !important;
}
</style>
