<template>
  <v-dialog
    v-model="showPromptDialog"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title class="ph-dialog-title">
        {{ toProperCase(promptData.type) }} Limit Reached
      </v-card-title>
      <br>
      <v-card-text class="ph-dialog-header">
        <p class="text-center">
          Unable to process this transaction, please submit an increase request to proceed.
          <br>
          <br>
          <span v-if="promptData.type === 'location'">
            Location's current limit for {{ promptData.transactionName }} is {{ toMoney(promptData.locationThreshold) }}
          </span>
          <span v-if="promptData.type === 'customer'">
            Customer's current limit for {{ promptData.transactionName }} is {{ toMoney(promptData.customerThreshold) }}
          </span>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="danger"
          text
          @click="closePromptDialog"
          class="ph-action-button"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          @click="createRequest"
          class="ph-action-button"
        >
          Create Request
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { toProperCase, toMoney } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      showPromptDialog: 'transactionLimit/showPromptDialog',
      promptData: 'transactionLimit/promptData'
    })
  },
  methods: {
    closePromptDialog () {
      this.$store.commit('transactionLimit/SET_SHOW_PROMPT_DIALOG', false)
      this.$store.commit('transactionLimit/SET_PROMPT_DATA', {
        type: '',
        transactionName: '',
        locationThreshold: 0,
        customerThreshold: 0
      })
    },
    createRequest () {
      if (this.promptData.type) {
        this.$router.push(`/transaction-limit/increase-requests/${this.promptData.type}`)
        this.closePromptDialog()
      }
    },
    toProperCase,
    toMoney
  }
}
</script>
