export default {
  SET_REMOTE_LOCATIONS (state, payload) {
    state.remoteLocations = payload
  },
  SET_REMOTE_USERS (state, payload) {
    state.remoteUsers = payload
  },
  SET_REMOTE_DATA (state, payload) {
    state.remoteData = payload
  }
}
