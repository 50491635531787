<template>
  <v-row class="ph-cico-cashin-forms-container">
    <v-col cols="12" md="8" class="ph-cico-cashin-form">
      <TransactionProfileCard />
      <div class="ph-cico-fields-title">
        <b>Transaction Details</b>
      </div>
      <div class="ph-cico-providers">
        <v-col
          v-for="(provider, index) in providersList"
          :key="index"
          :cols="12"
          :sm="6"
          :md="4"
          class="ph-cico-provider-list"
        >
          <v-card
            @click="selectPartner(provider.code, provider.name)"
            outlined
            flat
            :class="['ph-cico-partner-container', { 'selected': currentProvider === provider.code }]"
          >
            <img :class="provider.class + ' ph-cico-partner-icon-img'" :src="require('../../assets/images/' + provider.image)" />
          </v-card>
        </v-col>
      </div>
      <v-form ref="paymentFields">
        <v-row class="ph-cico-fields" v-if="currentProvider">
          <v-col cols="12">
            <v-autocomplete
              v-model="transactionData.biller"
              label="Biller"
              :items="billers"
              :rules="transactionData.requiredField"
              item-text="name"
              item-value="code"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Biller"
              @change="getBillerInfo($event)"
              :return-object="true"
            >
              <template v-slot:selection="{ item }">
                {{ item.name }} - {{ item.category }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.name }} - {{ item.category }}
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="ph-cico-fields">
          <v-col v-for="(item, index) in billerFields" :key="index" cols="12" md="4">
            <!-- If Option does not exist, the input would be text field -->
            <v-text-field
              v-if="!item.is_option"
              v-model="transactionData[item.name]"
              :label="item.label"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              :placeholder="item.label"
              @change="getRefs(item.name)"
              :readonly="item.name === 'otherCharges'"
              :rules="item.name !== 'otherCharges' ? (item.required ? [v => !!v || item.label + ' is required'] : '') : []"
            />

            <!-- Otherwise, it would be autocomplete -->
            <v-autocomplete
              v-if="item.is_option"
              v-model="transactionData[item.name]"
              :label="item.label"
              :items="item.options"
              item-text="name"
              item-value="code"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              :placeholder="item.label"
              @change="getRefs(item.name)"
              :rules="item.required ? [v => !!v || item.label + ' is required'] : []"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="ph-cico-back-button">
        <v-col cols="12" class="ph-stepper-action-buttons">
          <v-btn
            color="danger"
            outlined
            text
            light
            class="ph-primary-button ph-stepper-button ph-action-button"
            @click="cancelButton"
          >
            Cancel
          </v-btn>
          <v-spacer/>
          <v-spacer/>
          <v-btn
              color="primary"
              elevation="0"
              class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
              @click="summaryDialog = true"
              v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            >
              Next
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="4" class="ph-cico-cashin-summary">
      <v-card
        elevation="0"
        color="rgba(26, 39, 145, 0.05)"
      >
        <div class="ph-transfer-summary-header">
          <b>Summary Overview</b>
        </div>
        <br>
        <div class="ph-transfer-summary-content">
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in billsSummaryOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="ph-transfer-summary-action">
          <v-btn
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn ph-validate-cash-in-desktop"
            @click="showWacomConsent"
          >
            Confirm
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-cico-cashin-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-cico-cashin-summary-minified">
          <div class="ph-cico-cashin-summary-content">
            <div class="ph-cico-cashin-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>PHP {{transactionData.fullName.amount > 0 ? formatAmount(parseFloat(transactionData.fullName.amount) + parseFloat(transactionData.fullName.otherCharges ? transactionData.fullName.otherCharges : '0')) : '-'}}</b></p>
            </div>
            <div class="ph-cico-cashin-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <div class="ph-transfer-summary-content">
                <p class="ph-transfer-summary-content-title">Please confirm if the payment details are correct</p>
                <v-simple-table class="ph-transfer-summary-table">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in billsSummaryOverview"
                        :key="item.name"
                      >
                        <td class="transfer-info-title">{{ item.name }}</td>
                        <td class="transfer-info-value">{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="ph-transfer-summary-action">
                <v-btn
                  color="primary"
                  elevation="0"
                  class="ph-primary-button ph-primary-execute-btn ph-validate-cash-in"
                  @click="showWacomConsent"
                >
                  Confirm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noIDFlag"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          No identification card found
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="noIDFlag = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="updateDetails(selectedCustomer)"
          >
            Update Customer Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- OTP -->
    <v-dialog
      v-model="billsOTPDialog"
      persistent
      :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '30%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="billsOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <br>
          <p v-if="!showAmount"><b>Amount to Cash-In: </b>PHP {{formatAmount(transactionData.fullName.amount)}}</p>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <wacom-transaction
      v-if="currUser.has_wacom"
      @submit-sig="submitSig" type="transactional"
      :referenceSignatures="customerSignaturesReference"
      :trxData="{sender_name: selectedCustomer.full_name, receiver_name: 'N/A', total_amount: parseFloat(transactionData.amount) + parseFloat(transactionData.otherCharges ? transactionData.otherCharges : '0')}"
    />
    <wacom-data-consent-first />
    <wacom-data-consent-second />
    <wacom-data-consent-third />
    <wacom-data-consent-fourth />
    <wacom-data-consent-fifth />
    <wacom-data-consent-sixth />
    <wacom-data-consent-seventh
      :remco="'Bills Pay'"
    />
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { avatarColor, avatarInitial, getForeignRefNo, renderToast } from '@/utils'
import { format, parseISO } from 'date-fns'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import wacomMixin from '@/mixins/wacom.js'
import wacomTransaction from '@/components/wacom/wacomTransaction'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'

export default {
  name: 'BillsPaymentForm',
  mixins: wacomMixin,
  data () {
    return {
      noIDFlag: false,
      billsOTPDialog: false,
      isOTPLoading: false,
      otpInvalid: false,
      otp: '',
      otpMessage: '',
      summaryDialog: false,
      isNotCustomerPage: true,
      showAmount: true,
      billsSummaryOverview: [],
      providersList: [],
      mobileNumber: '',
      amount: '',
      billId: '',
      foreignRefNo: '',
      petnetTrackingNo: '',
      currentProvider: '',
      currentProviderAmountPlaceholder: '',
      currentProviderTooltip: '',
      transactionData: {},
      validateData: {},
      otherInfo: {},
      billerFields: [],
      clientRefNo: '',
      customerSignaturesReference: [],
      hasCustomerDataConsent: false,
      customerSignature: ''
    }
  },
  computed: {
    ...mapGetters({
      billers: 'bills/billers',
      tpaId: 'bills/tpaId',
      billsPaymentCurrentTrx: 'bills/billsPaymentCurrentTrx',
      collections: 'collections',
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer',
      billsPaymentTransactions: 'bills/billsPaymentTransactions',
      consentValues: 'wacom/consentValues'
    })
  },
  components: {
    TransactionProfileCard,
    wacomTransaction,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  watch: {
    transactionData: {
      handler (val) {
        this.updateOverview(val)
      },
      deep: true
    }
  },
  async mounted () {
    if (this.selectedCustomer) {
      await this.$store.dispatch('bills/getTransactions', {
        trx_date: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        location_id: this.currUser.location_id
      })
      await this.$store.dispatch('bills/getBillers')
      this.foreignRefNo = this.getForeignRefNo()

      this.billsSummaryOverview = [
        { name: 'Payment Provider', value: '-' },
        { name: 'Biller', value: '-' },
        { name: 'Reference Number', value: '-' },
        { name: 'Service Fee', value: '-' },
        { name: 'Amount', value: '-' },
        { name: 'Total Amount', value: '-' }
      ]

      this.providersList = [
        { code: 'Bayad Center', name: 'Bayad Center', class: 'partner-bayad', image: 'bills-bayad-center.svg' }
      ]

      if (this.providersList.length === 1) {
        this.selectPartner(this.providersList[0].code, this.providersList[0].name)
      }

      await this.$store.dispatch('bills/getTransactions', {
        trx_date: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        location_id: this.currUser.location_id
      })

      this.clientRefNo = this.generateClientRefNo()

      const that = this
      setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
      setTimeout(wacomMixin.methods.checkForWacom, 2000)
      const signatures = await that.$store.dispatch('customers/retrieveSignatures', { customer_id: that.selectedCustomer.customer_id })
      that.customerSignaturesReference = signatures.result
      this.checkConsent()
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    getForeignRefNo,
    renderToast,
    submitSig (emitted) {
      this.customerSignature = emitted
      this.generateConsent()
      this.validatePayment()
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
    },
    showWacomConsent () {
      if (this.currUser.has_wacom && !this.hasCustomerDataConsent) {
        this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
      } else {
        this.validatePayment()
      }
    },
    generateClientRefNo () {
      const trxCount = this.billsPaymentTransactions.length + 1
      const date = new Date()
      const getYear = format(date, 'yy')
      const getTimeZone = format(date, 'd') + 1

      const trxCountString = trxCount.toString()
      const locationIDString = this.currUser.location_id.toString()

      const referenceNo = `${this.tpaId}${getYear}${getTimeZone.padStart(3, '0')}${locationIDString.substring(0, 3)}${trxCountString.padStart(4, '0')}`

      console.log(referenceNo)

      return referenceNo
    },
    getFormInfo (item, propertyName) {
      return item[propertyName]
    },
    getFormRules (rules) {
      const ruleKeys = Object.keys(rules)
      const sortRules = ruleKeys.sort()
      const getPropertyName = sortRules[0]

      return rules[getPropertyName]
    },
    getFormOptions (options) {
      const convertToArray = Object.keys(options).map((key) => ({
        code: key,
        name: options[key]
      }))

      return convertToArray
    },
    getRefs (inputName) {
      if (inputName === 'amount') {
        this.getServiceFee()
      }
    },
    async getServiceFee () {
      this.transactionData.otherCharges = ''
      const trxData = this.transactionData

      const payload = {
        code: trxData.biller.code,
        amount: trxData.amount,
        user_id: this.currUser.user_id,
        location_id: this.currUser.location_id
      }

      let charges = '0.00'

      await this.$store.dispatch('bills/getOtherFees', payload).then(res => {
        charges = res.otherCharges
      }).catch(e => {
        charges = '0.00'
        this.renderToast('error', 'Get Fees Error', 'An error has occured when retrieving the service fee. Please try again later')
      })

      const addToPayload = {
        otherCharges: charges
      }

      this.transactionData = Object.assign({}, this.transactionData, addToPayload)
    },
    generateForms (fields) {
      console.log(fields)
      const billerFieldItems = []

      fields.forEach((item, index) => {
        billerFieldItems.push({
          name: Object.keys(item)[0],
          label: this.getFormInfo(item, Object.keys(item)[0]).label,
          is_option: this.checkIfFormHasOptions(this.getFormRules(this.getFormInfo(item, Object.keys(item)[0]).rules)),
          required: !!this.getFormInfo(item, Object.keys(item)[0]).rules?.required
        })

        if (billerFieldItems[index].is_option === true) {
          billerFieldItems[index].options = this.getFormOptions(this.getFormRules(this.getFormInfo(item, Object.keys(item)[0]).rules).options)
        }
      })

      this.billerFields = billerFieldItems
    },
    checkIfFormHasOptions (rules) {
      return Object.prototype.hasOwnProperty.call(rules, 'options')
    },
    async getBillerInfo (billerInfo) {
      console.log(billerInfo)
      const payload = {
        code: billerInfo.code,
        user_id: this.currUser.user_id,
        location_id: this.currUser.location_id
      }

      await this.$store.dispatch('bills/getBillerInfo', payload).then(res => {
        this.generateForms(res.result.parameters.verify)
        this.billId = res.remco_id
      }).catch(e => {
        console.log(e)
      })
    },
    formatPayload (data) {
      const otherInfoData = {}

      Object.entries(data).forEach(item => {
        const currentProp = item[0]
        const splitObject = currentProp.split('.')
        const getParentObject = splitObject[0]
        const getChildItem = splitObject[1]

        if (!otherInfoData?.otherInfo) {
          otherInfoData[getParentObject] = {
            [getChildItem]: item[1]
          }
        } else {
          const otherInfoItem = otherInfoData.otherInfo
          otherInfoItem[getChildItem] = item[1]
        }
      })

      data.otherInfo = otherInfoData.otherInfo

      return data
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    updateOverview (data) {
      const getTotalAmount = parseFloat(this.transactionData.amount) + parseFloat(this.transactionData.otherCharges ? this.transactionData.otherCharges : '0')

      this.billsSummaryOverview = [
        { name: 'Payment Provider', value: this.currentProvider },
        { name: 'Biller', value: this.transactionData.biller.name ? this.transactionData.biller.name : '-' },
        { name: 'Reference Number', value: this.transactionData.referenceNumber ? this.transactionData.referenceNumber : '-' },
        { name: 'Service Fee', value: this.transactionData.otherCharges ? this.transactionData.otherCharges : '-' },
        { name: 'Amount', value: this.transactionData.amount ? this.formatAmount(this.transactionData.amount) : '-' },
        { name: 'Total Amount', value: this.transactionData.amount ? this.formatAmount(getTotalAmount) : '-' }
      ]
    },
    selectPartner (code, provider) {
      this.currentProvider = code
      this.renderToast('info', 'Provider selected', `${provider} was selected.`)
    },
    async validatePayment () {
      if (this.$refs.paymentFields.validate()) {
        let hasOtherInfo = false
        let payload = {}

        await Object.entries(this.transactionData).forEach(item => {
          const currentProp = item[0]
          const isPropObject = currentProp.includes('.')

          if (isPropObject) {
            hasOtherInfo = true
          } else {
            hasOtherInfo = false
          }
        })

        if (hasOtherInfo) {
          payload = this.formatPayload(this.transactionData)
        } else {
          payload = this.transactionData
        }

        payload.accountNumber = payload.referenceNumber
        payload.code = payload.biller.code

        this.$store.dispatch('bills/validateTrx', payload).then(res => {
          console.log(res)
          if (res?.result) {
            if (res.result.valid === true) {
              this.renderToast('success', 'Transaction Verify Success', 'The transaction has been verified successfully.')
              this.openVerifyOTP()
              this.validateData = res.result
            }
          } else {
            const errorDetails = res.error.moreInfo

            const errorMessages = []

            Object.entries(errorDetails.errors).forEach((item, index) => {
              const errorItem = item[1]
              errorMessages.push(errorItem[0].message)
            })

            this.renderToast('error', 'Validation Error', errorMessages)
          }
        }).catch(e => {
          this.renderToast('error', 'Transaction Verify Error', 'An error has occured when pre-processing the transaction. Please try again later')
        })
      } else {
        this.renderToast('error', 'Validation Error', 'You must complete the following fields')
      }
    },
    async transactPayment () {
      const currentCustomer = this.selectedCustomer
      const currentUser = this.currUser
      const transactionData = this.transactionData

      console.log(this.clientRefNo)

      const payload = {
        user_id: currentUser.user_id.toString(),
        customer_id: currentCustomer.customer_id,
        location_id: currentUser.location_id.toString(),
        location_name: currentUser.location_name,
        coy: 'usp',
        callbackUrl: null,
        bill_id: this.billId,
        biller_tag: this.transactionData.biller.code,
        biller_name: this.transactionData.biller.name,
        trx_date: currentUser.trx_date,
        amount: transactionData.amount,
        service_charge: transactionData.otherCharges,
        partner_charge: '0',
        total_amount: parseFloat(transactionData.amount) + parseFloat(transactionData.otherCharges),
        identifier: currentCustomer.full_name,
        account_number: transactionData.referenceNumber,
        payment_method: transactionData.paymentMethod,
        client_reference_number: this.clientRefNo,
        reference_number: transactionData.referenceNumber,
        validation_number: this.validateData.validationNumber,
        receipt_validation_number: this.clientRefNo,
        tpa_id: this.tpaId,
        currency_id: '1',
        form_type: this.currUser.location_type === '1' ? 'OAR' : '',
        form_number: '',
        otherInfo: this.transactionData.otherInfo ? this.transactionData.otherInfo : '',
        type: 'Batch',
        api_request: {
          user_id: currentUser.user_id.toString(),
          customer_id: currentCustomer.customer_id,
          location_id: currentUser.location_id.toString(),
          location_name: currentUser.location_name,
          coy: 'usp',
          callbackUrl: null,
          bill_id: this.billId,
          biller_tag: this.transactionData.biller.code,
          biller_name: this.transactionData.biller.name,
          trx_date: currentUser.trx_date,
          amount: transactionData.amount,
          service_charge: transactionData.otherCharges,
          partner_charge: '0',
          total_amount: parseFloat(transactionData.amount) + parseFloat(transactionData.otherCharges),
          identifier: currentCustomer.full_name,
          account_number: transactionData.referenceNumber,
          payment_method: transactionData.paymentMethod,
          client_reference_number: this.clientRefNo,
          reference_number: transactionData.referenceNumber,
          validation_number: this.validateData.validationNumber,
          receipt_validation_number: this.clientRefNo,
          tpa_id: this.tpaId,
          currency_id: '1',
          form_type: 'OAR',
          form_number: '',
          otherInfo: this.transactionData.otherInfo,
          type: 'batch'
        }
      }

      await this.$store.dispatch('bills/executeTrx', payload).then(res => {
        console.log(res)

        if (res.message === 'Success') {
          this.$store.commit('bills/SET_BILLS_PAYMENT_CURRENT_TRX', payload)
          this.$store.commit('bills/SET_BILLS_PAYMENT_CURRENT_PAGE', 2)
        } else {
          this.renderToast('error', 'Transaction Failed', 'Something went wrong when processing the transaction, please try again.')
        }
      }).catch(e => {
        console.log(e)
        this.renderToast('error', 'Transaction Failed', 'Something went wrong when processing the transaction, please try again.')
      })
    },
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    openVerifyOTP () {
      this.otp = ''
      this.billsOTPDialog = true

      console.log(this.billsOTPDialog)
    },
    updateDetails (customerDetails) {
      this.noIDFlag = false

      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    cancelButton () {
      this.$store.commit('bills/SET_BILLS_PAYMENT_CURRENT_PAGE', 0)
    },
    async doVerify (pin) {
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        const payload = {
          user_name: this.currUser.user_name,
          otp_code: this.otp,
          pin: this.currUser.otp_pin,
          module: 'send'
        }

        const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)
        const isOTPVerified = OTPVerification.result?.value || false

        if (isOTPVerified) {
          this.otpInvalid = false
          this.isOTPLoading = false
          this.billsOTPDialog = false
          this.transactPayment()
        } else {
          this.otp = ''
          this.otpInvalid = true
          this.isOTPLoading = false
          this.otpMessage = 'Incorrect OTP PIN, please try again.'
        }
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    },
    async generateConsent () {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: 'Pera Bills' + this.currentProvider,
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>
<style scoped>
  .v-stepper.ph-wu-quickpay-stepper .v-stepper__header {
    box-shadow: none !important;
  }

  .ph-cico-cashin-summary .v-card {
    padding: 20px;
  }

  .ph-transfer-summary-header {
    font-size: 18px;
    text-align: center;
  }

  .ph-transfer-summary-content-title {
    font-size: 14px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
    border: 2px solid #1A2791;
  }

  .ph-transaction-container {
    padding: 0;
  }

  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 100px;
  }

  .ph-cico-cashin-summary-minified {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F4F4FA;
  }

  .ph-cico-cashin-summary-content {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
  }

  .ph-cico-cashin-summary-minified-info {
    margin-right: auto;
  }

  .ph-cico-cashin-summary-minified-info p {
    margin-bottom: 0;
  }

  .ph-cico-cashin-summary-minified-action {
    margin-left: auto;
  }

  .ph-primary-button.ph-stepper-button {
    height: 40px !important;
    width: 115px;
  }

  .ph-cico-cashin-form {
    padding: 12px 0;
  }

  /* @media only screen and (min-width: 600px) {
    .v-stepper.ph-wu-quickpay-stepper .v-stepper__step {
      padding-left: 0;
    }
  } */

  .transfer-info-title,
  .transfer-info-value {
    border-bottom: thin dashed rgba(0,0,0,.12) !important;
  }

  .ph-cico-providers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .ph-cico-partner-container {
    height: 100px;
    display: flex;
    z-index: 2;
    border: 1px solid transparent;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08) !important;
    border-radius: 12px !important;
    transition: all .2s;
    cursor: pointer;
  }

  .ph-cico-partner-container:hover {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  .ph-cico-partner-icon-img {
    margin: auto;
    object-fit: fill;
    z-index: 1;
  }

  .ph-cico-partner-icon-img.partner-paymaya {
    height: 50px;
    width: auto;
    /* position: relative;
    bottom: 15px; */
  }

  .ph-cico-partner-icon-img.partner-diskartech {
    height: 45px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-dragonpay {
    height: 65px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-ph,
  .ph-cico-partner-icon-img.partner-eon {
    height: 30px;
    width: auto;
  }

  .ph-cico-fields,
  .ph-cico-back-button {
    margin: 0;
  }

  .ph-cico-partner-container.selected {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  @media only screen and (min-width: 960px) {
    .ph-stepper-action-buttons {
      display: flex;
      margin-bottom: 0;
    }

    .ph-cico-cashin-forms-container {
      margin: 0;
    }
  }
</style>
