<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        class="ph-breadcrumbs"
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ph-transaction-container">
        <v-col cols="12" v-if="showPayoutSearchForm">
          <ReceiveMoneyWUSearch />
        </v-col>
        <v-col cols="12" v-if="showPayoutResultForm">
          <ReceiveMoneyWUForm />
        </v-col>
        <v-col cols="12" v-if="showSuccessPayoutScreen">
          <ReceiveMoneyWUSuccess />
        </v-col>
      </v-row>
      <ReceiveMoneyWUSearchResult />
    </v-container>
 </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ReceiveMoneyWUSearch from '@/components/wu/ReceiveMoneyWUSearch'
import ReceiveMoneyWUSearchResult from '@/components/wu/ReceiveMoneyWUSearchResult'
import ReceiveMoneyWUForm from '@/components/wu/ReceiveMoneyWUForm'
import ReceiveMoneyWUSuccess from '@/components/wu/ReceiveMoneyWUSuccess'

export default {
  name: 'ReceiveMoneyNewWUTrx',
  components: {
    ReceiveMoneyWUSearch,
    ReceiveMoneyWUSearchResult,
    ReceiveMoneyWUForm,
    ReceiveMoneyWUSuccess
  },
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Receive Money',
          disabled: false,
          href: '/pera-remit/receive'
        },
        {
          text: 'Create WU',
          disabled: true,
          href: '#'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      showPayoutSearchForm: 'wu/showPayoutSearchForm',
      showPayoutResultForm: 'wu/showPayoutResultForm',
      showSuccessPayoutScreen: 'wu/showSuccessPayoutScreen'
    })
  },
  methods: {
  }
}
</script>
<style scoped>
  .ph-transaction-container .col {
    padding: 15px;
  }

  .ph-breadcrumbs {
    padding: 5px 5px;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }

    .ph-transaction-container .col {
      padding: 12px;
    }

    .ph-breadcrumbs {
      padding: 18px 12px;
      padding-left: 24px;
    }
  }
</style>
