import { endpoints } from '@/api/endpoints'

const dataConsent = endpoints.use('dataConsent')

export default {
  generateConsent: ({ commit }, payload) => new Promise((resolve, reject) => {
    dataConsent.generateConsent(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  checkCustomerConsent: ({ commit }, payload) => new Promise((resolve, reject) => {
    dataConsent.checkCustomerConsent(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getLatestConsent: ({ commit }, payload) => new Promise((resolve, reject) => {
    dataConsent.getLatestConsent(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
