<template>
  <v-row class="ph-cico-cashout-forms-container">
    <v-col cols="12" md="8" class="ph-cico-cashout-form">
      <TransactionProfileCard />
      <div class="ph-cico-providers">
        <v-col
          v-for="(provider, index) in providersList"
          :key="index"
          :cols="12"
          :sm="6"
          :md="4"
          class="ph-cico-provider-list"
        >
          <v-card
            @click="selectPartner(provider.provider_code, provider.provider_name, provider)"
            outlined
            flat
            :class="['ph-cico-partner-container', { 'selected': currentProvider === provider.provider_code }]"
          >
          <img :class="provider.class + ' ph-cico-partner-icon-img'" :src="require('../../assets/images/' + provider.image)" />
            <p class="phv-text" v-if="provider.provider_code === 'PHV'"><b>VISA Card</b></p>
          </v-card>
        </v-col>
      </div>
      <v-row class="ph-cico-fields" v-if="currentProvider">
        <v-col cols="12">
          <div class="ph-cico-fields-title">
            <b>Wallet Information</b>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="transactionData.mobileNumber"
            :label="currentProviderAmountPlaceholder"
            color="gray darken-1"
            class="ph-textbox"
            outlined
            @keydown="formatControl($event)"
            :maxlength="currentProvider === 'DISKARTECH' ? 15 : ''"
          />
          <br>
          <p>{{currentProviderTooltip}}</p>
        </v-col>
        <v-col cols="12" md="6" v-if="showAmount">
          <v-text-field
            v-model="transactionData.amount"
            label="Amount"
            color="gray darken-1"
            class="ph-textbox"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="ph-cico-back-button">
        <v-col cols="12" class="ph-stepper-action-buttons">
          <v-btn
            color="danger"
            outlined
            text
            light
            class="ph-primary-button ph-stepper-button ph-action-button"
            @click="cancelButton"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="4" class="ph-cico-cashout-summary">
      <v-card
        elevation="0"
        color="rgba(26, 39, 145, 0.05)"
      >
        <div class="ph-transfer-summary-header">
          <b>Summary Overview</b>
        </div>
        <br>
        <div class="ph-transfer-summary-content">
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in cicoSummaryOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <wacom-transaction
          v-if="currUser.has_wacom"
          @submit-sig="submitSig" type="transactional"
          :referenceSignatures="customerSignaturesReference"
          :trxData="{sender_name: `N/A`, receiver_name: `N/A`, total_amount: transactionData.amount}"
        />
        <wacom-consent />
        <wacom-data-consent-first />
        <wacom-data-consent-second />
        <wacom-data-consent-third />
        <wacom-data-consent-fourth />
        <wacom-data-consent-fifth />
        <wacom-data-consent-sixth />
        <wacom-data-consent-seventh />
        <div class="ph-transfer-summary-action">
          <v-btn
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn"
            @click="printVoucher"
          >
            Confirm
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-cico-cashout-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-cico-cashout-summary-minified">
          <div class="ph-cico-cashout-summary-content">
            <div class="ph-cico-cashout-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>PHP {{amount > 0 ? amount : '-'}}</b></p>
            </div>
            <div class="ph-cico-cashout-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <div class="ph-transfer-summary-content">
                <p class="ph-transfer-summary-content-title">Please confirm if the sendout details are correct</p>
                <v-simple-table class="ph-transfer-summary-table">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in cicoSummaryOverview"
                        :key="item.name"
                      >
                        <td class="transfer-info-title">{{ item.name }}</td>
                        <td class="transfer-info-value">{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="ph-transfer-summary-action">
                <v-btn
                  color="primary"
                  elevation="0"
                  class="ph-primary-button ph-primary-execute-btn"
                  @click="printVoucher"
                >
                  Confirm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noIDFlag"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          No identification card found
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="noIDFlag = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="updateDetails(selectedCustomer)"
          >
            Update Customer Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- OTP -->
    <v-dialog
      v-model="cashOutOTPDialog"
      persistent
      :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '30%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="cashOutOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <br>
          <p v-if="!showAmount"><b>Amount to Cash-Out: </b>PHP {{formatAmount(transactionData.amount)}}</p>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Customer OTP for Diskartech and PHV -->
    <v-dialog
      v-model="customerOTPDialog"
      persistent
      :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '30%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="customerOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            Customer's OTP
          </h3>
          <p>
            Input your customer's generated OTP Code to continue
          </p>
          <p><i><b>Note:</b> The OTP Code will be provided by your customer.</i></p>
          <br>
          <p v-if="!showAmount"><b>Amount to Cash-Out: </b>PHP {{formatAmount(transactionData.amount)}}</p>
          <v-otp-input
            v-model="customerOTP"
            id="customerOTP"
            type="password"
            :disabled="isCustomerOTPLoading"
            @finish="onFinishCustomerOTP"
          />
          <p class="red--text">
            {{ customerOTPMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <flaggingPrompt @callback="executeCashOut" />
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { avatarColor, avatarInitial, getForeignRefNo, renderToast } from '@/utils'
import wacomTransaction from '@/components/wacom/wacomTransaction.vue'
import wacomConsent from '@/components/wacom/wacomConsent.vue'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'
import wacomMixin from '@/mixins/wacom.js'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import { format, parseISO } from 'date-fns'
import CryptoJS from 'crypto-js'
import flaggingPrompt from '@/components/transactionLimit/flaggingPrompt.vue'

export default {
  name: 'CashOutForm',
  mixins: wacomMixin,
  data () {
    return {
      noIDFlag: false,
      cashOutOTPDialog: false,
      customerOTPDialog: false,
      isOTPLoading: false,
      otpInvalid: false,
      otp: '',
      otpMessage: '',
      isCustomerOTPLoading: false,
      customerOTPInvalid: false,
      customerOTP: '',
      customerOTPMessage: '',
      summaryDialog: false,
      isNotCustomerPage: true,
      showAmount: true,
      cicoSummaryOverview: [],
      providersList: [],
      mobileNumber: '',
      amount: '',
      currentId: '',
      foreignRefNo: '',
      petnetTrackingNo: '',
      currentProvider: '',
      currentProviderAmountPlaceholder: '',
      currentProviderTooltip: '',
      transactionData: {},
      customerSignature: '',
      customerSignaturesReference: '',
      successTransactionData: {},
      executeData: {},
      minAmount: '',
      maxAmount: '',
      hasCustomerDataConsent: false,
      eonCashOutPayload: {}
    }
  },
  computed: {
    ...mapGetters({
      collections: 'collections',
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer',
      cashOutCurrentTrx: 'cico/cashOutCurrentTrx',
      oarNumber: 'oar/oarNumber',
      consentValues: 'wacom/consentValues'
    })
  },
  components: {
    TransactionProfileCard,
    wacomTransaction,
    wacomConsent,
    flaggingPrompt,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  watch: {
    transactionData: {
      handler (val) {
        this.updateOverview(val)
      },
      deep: true
    }
  },
  async mounted () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    const signatures = await this.$store.dispatch('customers/retrieveSignatures', { customer_id: this.selectedCustomer.customer_id })
    this.customerSignaturesReference = signatures.result
    this.checkConsent()
    if (this.selectedCustomer) {
      this.foreignRefNo = this.getForeignRefNo()

      this.cicoSummaryOverview = [
        { name: 'Customer Name', value: this.selectedCustomer.full_name },
        { name: 'Wallet', value: this.mobileNumber ? this.mobileNumber : '-' },
        { name: 'Amount to Cash-Out', value: this.amount ? this.amount : '-' }
      ]

      await this.$store.dispatch('cico/getCICOProviders').then(res => {
        if (res?.result) {
          const getCashOutItems = res.result.filter(res => res.cash_out === 1)

          this.providersList = getCashOutItems
        }
      }).catch(e => {
        console.log(e)
      })
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    getForeignRefNo,
    renderToast,
    flagChecker () {
      if (this.currUser.first_name === this.selectedCustomer.first_name && this.currUser.last_name === this.selectedCustomer.last_name) {
        renderToast('error', 'Blocked Transaction', 'You are not allowed to process this transaction for this customer')
        return false
      }

      if (this.currUser.last_name === this.selectedCustomer.last_name) {
        this.$store.commit('transactionLimit/SET_FLAGGED_TRANSACTION_PAYLOAD', {
          location_id: this.currUser.location_id,
          user_id: this.currUser.user_id,
          customer_id: this.selectedCustomer.customer_id,
          service_name: 'CICO',
          service_type: 'INBOUND',
          provider_name: this.currentProvider,
          provider_code: this.currentProvider
        })
        this.$store.commit('transactionLimit/SET_SHOW_FLAGGED_PROMPT_DIALOG', true)
      } else {
        this.executeCashOut()
      }
    },
    printVoucher () {
      this.validateCashOut()
    },
    submitSig (emitted) {
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
      this.customerSignature = emitted
      if (this.hasCustomerDataConsent === false) {
        this.generateConsent()
      }
      this.openVerifyOTP()
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    updateOverview (data) {
      if ((data.mobileNumber.length === 8 && this.currentProvider === 'GCASH') || this.currentProvider === 'DRAGONPAY' || this.currentProvider === 'PERAHUB') {
        this.showAmount = false
      } else {
        this.showAmount = true
      }

      let amount = ''

      if (data?.trx?.principal_amount) {
        amount = data.trx.principal_amount.toString()
      } else if (data.amount) {
        amount = data.amount
      } else if (data.amount < 1) {
        amount = '-'
      } else {
        amount = '-'
      }

      this.cicoSummaryOverview = [
        { name: 'Customer Name', value: this.selectedCustomer.full_name },
        { name: 'Wallet', value: data.mobileNumber ? data.mobileNumber : '-' },
        { name: 'Amount to Cash-Out', value: ((amount !== '' || amount !== '-') && data.amount > 0) ? `PHP ${this.formatAmount(amount)}` : '-' }
      ]
    },
    selectPartner (code, provider, providerInfo) {
      this.currentProvider = code

      this.minAmount = providerInfo.min_cash_in
      this.maxAmount = providerInfo.max_cash_in

      switch (code) {
        case 'GCASH':
          this.showAmount = true
          this.currentProviderAmountPlaceholder = 'Mobile Number'
          this.currentProviderTooltip = 'Enter 11-digit Mobile No.'
          break
        case 'DRAGONPAY':
          this.showAmount = false
          this.currentProviderAmountPlaceholder = 'Control Number'
          this.currentProviderTooltip = 'Enter a Valid Control Number to transact'
          this.amount = ''
          break
        case 'PERAHUB':
          this.showAmount = false
          this.currentProviderAmountPlaceholder = 'Control Number'
          this.currentProviderTooltip = 'Enter a Valid Control Number to transact'
          this.amount = ''
          break
        case 'DISKARTECH':
          this.showAmount = true
          this.currentProviderAmountPlaceholder = 'Control Number'
          this.currentProviderTooltip = 'Enter a Valid Control Number to transact'
          break
        case 'PHV':
          this.showAmount = true
          this.currentProviderAmountPlaceholder = 'Account Number'
          this.currentProviderTooltip = ''
          this.transactionData.mobileNumber = this.selectedCustomer.ub_account_no
          break
        case 'EON':
          break
      }

      this.renderToast('info', 'Provider selected', `${provider} was selected.`)
    },
    async validateCashOut () {
      if (!this.transactionData.mobileNumber) {
        this.renderToast('error', 'Transaction Error', 'Mobile or Control Number is required')
      } else if (this.transactionData.amount < this.minAmount) {
        this.renderToast('error', 'Transaction Error', 'The minimum amount for this transaction must be PHP ' + this.formatAmount(this.minAmount))
      } else if (this.transactionData.amount > this.maxAmount) {
        this.renderToast('error', 'Transaction Error', 'The maximum amount for this transaction must be PHP ' + this.formatAmount(this.maxAmount))
      } else {
        if (this.showAmount === true && (!this.transactionData.amount || this.transactionData.amount < 1)) {
          this.renderToast('error', 'Transaction Error', 'Amount field is required and must not be 0')
        } else {
          const currentCustomer = this.selectedCustomer
          const currentUser = this.currUser

          const idList = currentCustomer.img ? JSON.parse(currentCustomer.img) : []
          let currentId = idList['1']
          currentId = idList['1'] ? idList['1'] : {}
          this.currentId = currentId

          const foreignRefNo = getForeignRefNo()

          const payload = {
            partner_code: 'USP',
            trx: {
              provider: this.currentProvider,
              reference_number: this.transactionData.mobileNumber,
              trx_type: 'Cash Out'
            },
            customer: {
              customer_id: currentCustomer?.customer_id ? currentCustomer.customer_id.toString() : '',
              customer_firstname: currentCustomer.first_name,
              customer_lastname: currentCustomer.last_name,
              curr_address: currentCustomer?.address ? currentCustomer.address : '',
              curr_barangay: currentCustomer?.barangay ? currentCustomer.barangay : '',
              curr_city: currentCustomer?.city1 ? currentCustomer.city1 : '',
              curr_province: currentCustomer?.province ? currentCustomer.province : '',
              curr_country: currentCustomer?.country ? currentCustomer.country : '',
              birth_date: currentCustomer.birth_date,
              birth_country: currentCustomer.country_birth,
              contact_no: (this.transactionData.mobileNumber.length > 8 && this.currentProvider !== 'PHV') ? this.transactionData.mobileNumber : currentCustomer.mobile_no,
              id_type: this.currentId.id_type,
              id_number: this.currentId.id_number
            },
            usp: {
              location_id: currentUser.location_id.toString(),
              location_name: currentUser.location_name,
              user_id: currentUser.user_id,
              location_group_id: currentUser.location_group_id,
              form_type: 'OAR',
              form_number: foreignRefNo,
              ip_address: currentUser.ip_address,
              risk_score: currentCustomer.risk_score,
              risk_criteria: currentCustomer.risk_criteria ? currentCustomer.risk_criteria : '{}'
            }
          }

          if (this.currentProvider === 'DISKARTECH') {
            payload.customer.contact_no = currentCustomer.mobile_no
          }

          if (this.currentProvider === 'PHV') {
            payload.trx.other_info = {
              charges: 0,
              customer_number: currentCustomer.customer_number,
              user_name: this.currUser.user_name
            }
          }

          if (this.transactionData.mobileNumber.length > 8 && this.currentProvider !== 'DRAGONPAY') {
            payload.trx.principal_amount = this.transactionData.amount.toString()
          } else {
            payload.trx.principal_amount = this.transactionData.amount
          }

          if (this.currentProvider === 'EON' || this.currentProvider === 'PHV') {
            this.openVerifyOTP()
            this.eonCashOutPayload = payload
            this.$store.commit('cico/SET_CASHOUT_CURRENT_TRX', payload)
          } else {
            await this.$store.dispatch('cico/trxCICOValidate', payload).then(res => {
              if (res.code === 500) {
                this.renderErrorMessage(res)
              } else if (res === 'Service Unavailable') {
                this.renderToast('error', 'Cash-Out Error', res)
              } else if (res.code === 402 && res?.error) {
                this.renderToast('error', 'Cash-Out Error', res.error.status_message)
              } else {
                this.executeData = payload
                this.petnetTrackingNo = res.result.petnet_trackingno
                payload.trx.principal_amount = res.result.principal_amount.toString()
                this.transactionData.amount = res.result.principal_amount.toString()
                this.updateOverview(this.transactionData)

                this.$store.commit('cico/SET_CASHOUT_CURRENT_TRX', payload)
                if (this.currUser.has_wacom && !this.hasCustomerDataConsent) {
                  this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
                } else {
                  this.openVerifyOTP()
                }
              }
            }).catch(err => {
              console.log(err)
            })
          }
        }
      }
    },
    async executeEONCashOut (payload) {
      const currentDate = format(parseISO(this.currUser.trx_date), 'yyyy-MM-dd HH:mm:ss')
      const clientRefererenceConvert = CryptoJS.MD5(currentDate).toString()
      const clientRefererenceNo = clientRefererenceConvert.substr(0, 20)

      const eonPayload = {
        customer_number: this.selectedCustomer.customer_number,
        card_no: payload.trx.reference_number,
        ub_amount: payload.trx.principal_amount,
        service_fee: 0,
        total_amount: payload.trx.principal_amount,
        otp_code: this.customerOTP,
        client_reference_no: clientRefererenceNo,
        coy: 'usp',
        user_name: this.currUser.user_name,
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        customer_id: payload.customer.customer_id,
        customer_name: `${payload.customer.customer_lastname}, ${payload.customer.customer_firstname}`,
        form_type: payload.usp.form_type,
        form_number: payload.usp.form_number,
        trx_type: 'D',
        trx_category: this.currentProvider === 'PHV' ? 'PeraHubCard' : 'Regular',
        date_encoded: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        currency_id: 1,
        // client_ip: this.currUser.ip_address,
        pending_status: 0
      }

      const that = this

      await this.$store.dispatch('cico/trxEONCashOut', eonPayload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          const voucherPayload = {
            vlocation_name: that.currUser.location_name,
            vref_no: that.petnetTrackingNo,
            has_wacom: that.currUser.has_wacom,
            vpayee: `${that.cashOutCurrentTrx.customer.customer_lastname}, ${that.cashOutCurrentTrx.customer.customer_firstname}`,
            vparticulars: that.cashOutCurrentTrx.trx.provider + ':' + ' ' + that.cashOutCurrentTrx.trx.trx_type,
            vamount: that.cashOutCurrentTrx.trx.principal_amount.toString(),
            fla: `${that.currUser.last_name}, ${that.currUser.first_name}`,
            trx_type: that.cashOutCurrentTrx.trx.trx_type,
            remco_name: that.cashOutCurrentTrx.trx.provider,
            control_number: that.petnetTrackingNo,
            customer_id: that.selectedCustomer.customer_id.toString(),
            location_id: that.currUser.location_id.toString(),
            location_name_uploaded: that.currUser.location_name,
            crn: that.selectedCustomer.customer_number,
            uploaded_by: that.currUser.user_id,
            first_name: that.cashOutCurrentTrx.customer.customer_firstname,
            last_name: that.cashOutCurrentTrx.customer.customer_lastname,
            product: that.cashOutCurrentTrx.trx.provider
          }

          that.executePrintVoucher(voucherPayload)
          that.renderToast('success', 'Cash-Out Successful', res.message)
          that.$store.commit('cico/SET_CASHOUT_CURRENT_PAGE', 2)
        } else {
          that.renderToast('error', 'Cash-Out Error', res.message)
          this.customerOTP = ''
          this.customerOTPInvalid = true
          this.customerOTPMessage = 'Incorrect OTP PIN, please try again.'
          this.isCustomerOTPLoading = false
        }
      }).catch(err => {
        console.log(err)
        this.customerOTP = ''
        this.customerOTPInvalid = true
        this.customerOTPMessage = 'Incorrect OTP PIN, please try again.'
        this.isCustomerOTPLoading = false
      })
    },
    async executeCashOut () {
      const validateTrxData = this.executeData
      const currentUser = this.currUser

      try {
        const isProcessable = await this.$store.dispatch('transactionLimit/checkLimit', {
          transactionName: this.currentProvider,
          payload: {
            customer_id: this.selectedCustomer.customer_id,
            provider_code: this.currentProvider,
            service_name: 'CICO',
            service_type: 'INBOUND',
            amount: this.cashOutCurrentTrx.trx.principal_amount.toString()
          }
        })

        if (isProcessable) {
          const payload = {
            location_id: validateTrxData.usp.location_id.toString(),
            petnet_trackingno: this.petnetTrackingNo,
            trx_date: currentUser.trx_date,
            partner_code: 'USP',
            location_name: validateTrxData.usp.location_name
            // deviceIP: currentUser.ip_address,
            // machineId: validateTrxData.usp.location_id.toString()
          }

          const that = this

          setTimeout(function () {
            that.$store.dispatch('cico/trxCICOExecute', payload).then(res => {
              if (res.code >= 200 && res.code <= 299) {
                // const that = that

                const voucherPayload = {
                  vlocation_name: that.currUser.location_name,
                  vref_no: that.petnetTrackingNo,
                  has_wacom: that.currUser.has_wacom,
                  vpayee: `${that.cashOutCurrentTrx.customer.customer_lastname}, ${that.cashOutCurrentTrx.customer.customer_firstname}`,
                  vparticulars: that.cashOutCurrentTrx.trx.provider + ':' + ' ' + that.cashOutCurrentTrx.trx.trx_type,
                  vamount: that.cashOutCurrentTrx.trx.principal_amount.toString(),
                  fla: `${that.currUser.last_name}, ${that.currUser.first_name}`,
                  trx_type: that.cashOutCurrentTrx.trx.trx_type,
                  remco_name: that.cashOutCurrentTrx.trx.provider,
                  control_number: that.petnetTrackingNo,
                  customer_id: that.selectedCustomer.customer_id.toString(),
                  location_id: that.currUser.location_id.toString(),
                  location_name_uploaded: that.currUser.location_name,
                  crn: that.selectedCustomer.customer_number,
                  uploaded_by: that.currUser.user_id,
                  first_name: that.cashOutCurrentTrx.customer.customer_firstname,
                  last_name: that.cashOutCurrentTrx.customer.customer_lastname,
                  product: that.cashOutCurrentTrx.trx.provider
                }

                if (that.currentProvider === 'DISKARTECH') {
                  that.customerOTPDialog = true
                  that.successTransactionData = res.result
                } else {
                  that.executePrintVoucher(voucherPayload)
                  that.renderToast('success', 'Cash-Out Successful', res.message)
                  that.$store.commit('cico/SET_CASHOUT_CURRENT_PAGE', 2)
                }
              } else if (res === 'Service Unavailable') {
                that.renderToast('error', 'Cash-Out Error', res)
                setTimeout(function () {
                  window.location.href = '/pera-cico'
                }, 1000)
              } else {
                that.renderErrorMessage(res)
                setTimeout(function () {
                  window.location.href = '/pera-cico'
                }, 1000)
              }
            }).catch(err => {
              console.log(err)
            })
          }, 3000)
        }
      } catch (error) {
        renderToast('error', 'Checking Failed', 'Failed to check current limit, please submit a ticket to IT Support')
      }
    },
    renderErrorMessage (res) {
      switch (this.currentProvider) {
        case 'GCASH':
        case 'DRAGONPAY':
        case 'DISKARTECH':
          this.renderToast('error', 'Cash-Out Error', res.error?.status_message ? res.error.status_message : res.message)
          break
        case 'PERAHUB':
        case 'EON':
          this.renderToast('error', 'Cash-Out Error', res.message)
          break
      }
    },
    async executePrintVoucher (voucherPayload) {
      const reqVoucher = await this.$store.dispatch('voucher/printVoucher', voucherPayload)
      console.log(reqVoucher)
      var decoded = Buffer.from(reqVoucher.result, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
    },
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    onFinishCustomerOTP (pin) {
      this.customerOTPMessage = ''
      this.isCustomerOTPLoading = true
      setTimeout(() => {
        this.doVerifyCustomerOTP(pin)
      }, 3500)
    },
    openVerifyOTP () {
      this.otp = ''
      this.cashOutOTPDialog = true

      console.log(this.cashOutOTPDialog)
    },
    updateDetails (customerDetails) {
      this.noIDFlag = false

      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    cancelButton () {
      this.$store.commit('cico/SET_CASHOUT_CURRENT_PAGE', 0)
    },
    async doVerify (pin) {
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        const payload = {
          user_name: this.currUser.user_name,
          otp_code: this.otp,
          pin: this.currUser.otp_pin,
          module: 'send'
        }

        const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)
        const isOTPVerified = OTPVerification.result?.value || false

        if (isOTPVerified) {
          this.otpInvalid = false
          this.isOTPLoading = false
          this.cashOutOTPDialog = false

          if (this.currentProvider === 'EON' || this.currentProvider === 'PHV') {
            const otpSendPayload = {
              customer_number: this.selectedCustomer.customer_number,
              mobile_no: this.selectedCustomer.mobile_no,
              cash_out: parseFloat(this.transactionData.amount),
              trx_type: 'cashout_verification'
            }
            await this.$store.dispatch('cico/trxOTPSend', otpSendPayload).then(res => {
              if (!res.result) {
                this.renderToast('error', 'Error', 'There was an error in generating the customer OTP Code, please try again.')
              } else {
                this.customerOTP = ''
                this.customerOTPDialog = true
              }
            })
          }
          this.flagChecker()
        } else {
          this.otp = ''
          this.otpInvalid = true
          this.isOTPLoading = false
          this.otpMessage = 'Incorrect OTP PIN, please try again.'
        }
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    },
    async doVerifyCustomerOTP (pin) {
      this.customerOTP = pin
      if (this.customerOTP && this.customerOTP.length === 6) {
        // const validateTrxData = this.transactionData

        if (this.currentProvider === 'EON' || this.currentProvider === 'PHV') {
          this.executeEONCashOut(this.eonCashOutPayload)
        } else {
          const currentUser = this.currUser
          const successData = this.successTransactionData

          const voucherPayload = {
            vlocation_name: this.currUser.location_name,
            vref_no: this.petnetTrackingNo,
            has_wacom: this.currUser.has_wacom,
            vpayee: `${this.cashOutCurrentTrx.customer.customer_lastname}, ${this.cashOutCurrentTrx.customer.customer_firstname}`,
            vparticulars: this.cashOutCurrentTrx.trx.provider + ':' + ' ' + this.cashOutCurrentTrx.trx.trx_type,
            vamount: this.cashOutCurrentTrx.trx.principal_amount.toString(),
            fla: `${this.currUser.last_name}, ${this.currUser.first_name}`,
            trx_type: this.cashOutCurrentTrx.trx.trx_type,
            remco_name: this.cashOutCurrentTrx.trx.provider,
            control_number: this.petnetTrackingNo,
            customer_id: this.selectedCustomer.customer_id.toString(),
            location_id: this.currUser.location_id,
            location_name_uploaded: this.currUser.location_name,
            crn: this.selectedCustomer.customer_number,
            uploaded_by: this.currUser.user_id,
            first_name: this.cashOutCurrentTrx.customer.customer_firstname,
            last_name: this.cashOutCurrentTrx.customer.customer_lastname,
            product: this.cashOutCurrentTrx.trx.provider
          }

          const otpPayload = {
            location_id: this.currentProvider === 'DISKARTECH' ? successData.location_id : currentUser.location_id.toString(),
            location_name: this.currentProvider === 'DISKARTECH' ? successData.location_name : currentUser.location_name,
            partner_code: 'USP',
            petnet_trackingno: this.petnetTrackingNo,
            trx_date: currentUser.trx_date,
            otp: this.customerOTP,
            otp_payload: this.currentProvider === 'DISKARTECH' ? successData.otp_payload : null
          }

          await this.$store.dispatch('cico/trxOTPCustomer', otpPayload).then(res => {
            console.log(res)
            if (res?.error) {
              this.customerOTP = ''
              this.customerOTPInvalid = true
              this.isCustomerOTPLoading = false

              if (res?.error?.error?.message?.responseDescription === 'IBL_INSUFFICIENT_FUNDS') {
                this.customerOTPMessage = 'The account has insufficient funds.'
              } else if (res?.error?.error?.message) {
                this.customerOTPMessage = res.error.error.message
              } else {
                this.customerOTPMessage = 'Failed to Verify Account. Please double check the Account Number.'
              }
            } else {
              this.customerOTPInvalid = false
              this.isCustomerOTPLoading = false
              this.customerOTPDialog = false
              this.executePrintVoucher(voucherPayload)
              this.successTransactionData = {}
              this.renderToast('success', 'Cash-Out Successful', res.message)
              this.$store.commit('cico/SET_CASHOUT_CURRENT_PAGE', 2)
            }
          })
        }
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    },
    formatControl (e) {
      if (this.currentProvider === 'DISKARTECH') {
        var key = e.charCode || e.keyCode || 0
        if (key !== 8 && key !== 9) {
          if (this.transactionData.mobileNumber.length === 6) {
            this.transactionData.mobileNumber = this.transactionData.mobileNumber + '-'
          }
          if (this.transactionData.mobileNumber.length === 13) {
            this.transactionData.mobileNumber = this.transactionData.mobileNumber + '-'
          }
        }

        return (key === 8 || key === 9 || key === 46 || key === 13 || key === 37 || key === 39 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105))
      }
    },
    async executePHVCashout () {
      const currentUser = this.currUser
      const currentDate = format(parseISO(currentUser.trx_date), 'yyyy-MM-dd')
      const clientRefererenceConvert = CryptoJS.MD5(currentDate).toString()
      const clientRefererenceNo = clientRefererenceConvert.substr(0, 20)

      const payload = {
        customer_number: this.selectedCustomer.customer_number,
        source_account: this.transactionData.mobileNumber,
        amount: parseFloat(this.transactionData.amount),
        service_fee: '0',
        otp_code: this.customerOTP,
        client_reference_no: clientRefererenceNo,
        coy: 'usp',
        user_name: currentUser.user_id
      }

      const voucherPayload = {
        vlocation_name: this.currUser.location_name,
        vref_no: this.petnetTrackingNo,
        has_wacom: this.currUser.has_wacom,
        vpayee: `${this.cashOutCurrentTrx.customer.customer_lastname}, ${this.cashOutCurrentTrx.customer.customer_firstname}`,
        vparticulars: this.cashOutCurrentTrx.trx.provider + ':' + ' ' + this.cashOutCurrentTrx.trx.trx_type,
        vamount: this.cashOutCurrentTrx.trx.principal_amount.toString(),
        fla: `${this.currUser.last_name}, ${this.currUser.first_name}`,
        trx_type: this.cashOutCurrentTrx.trx.trx_type,
        remco_name: this.cashOutCurrentTrx.trx.provider,
        control_number: this.petnetTrackingNo,
        customer_id: this.selectedCustomer.customer_id.toString(),
        location_id: this.currUser.location_id,
        location_name_uploaded: this.currUser.location_name,
        crn: this.selectedCustomer.customer_number,
        uploaded_by: this.currUser.user_id,
        first_name: this.cashOutCurrentTrx.customer.customer_firstname,
        last_name: this.cashOutCurrentTrx.customer.customer_lastname,
        product: this.cashOutCurrentTrx.trx.provider
      }

      const response = await this.$store.dispatch('cico/trxPHVCashOutExecute', payload).then(res => {
        if (res.code === 500) {
          this.renderToast('error', 'Error', 'Cash-out Failed')
        } else {
          this.executePrintVoucher(voucherPayload)
          this.renderToast('success', 'Cash-Out Successful', res.message)
          this.$store.commit('cico/SET_CASHOUT_CURRENT_PAGE', 2)
        }
      })

      return response
    },
    async generateConsent () {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: this.cashOutCurrentTrx.trx.provider + ':' + ' ' + this.cashOutCurrentTrx.trx.trx_type,
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    }
  }
}
</script>
<style scoped>
  .v-stepper.ph-wu-quickpay-stepper .v-stepper__header {
    box-shadow: none !important;
  }

  .ph-cico-cashout-summary .v-card {
    padding: 20px;
  }

  .ph-transfer-summary-header {
    font-size: 18px;
    text-align: center;
  }

  .ph-transfer-summary-content-title {
    font-size: 14px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
    border: 2px solid #1A2791;
  }

  .ph-transaction-container {
    padding: 0;
  }

  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 100px;
  }

  .ph-cico-cashout-summary-minified {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F4F4FA;
  }

  .ph-cico-cashout-summary-content {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
  }

  .ph-cico-cashout-summary-minified-info {
    margin-right: auto;
  }

  .ph-cico-cashout-summary-minified-info p {
    margin-bottom: 0;
  }

  .ph-cico-cashout-summary-minified-action {
    margin-left: auto;
  }

  .ph-primary-button.ph-stepper-button {
    height: 40px !important;
    width: 115px;
  }

  .ph-cico-cashout-form {
    padding: 12px 0;
  }

  /* @media only screen and (min-width: 600px) {
    .v-stepper.ph-wu-quickpay-stepper .v-stepper__step {
      padding-left: 0;
    }
  } */

  .transfer-info-title,
  .transfer-info-value {
    border-bottom: thin dashed rgba(0,0,0,.12) !important;
  }

  .ph-cico-providers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .ph-cico-partner-container {
    height: 100px;
    display: flex;
    z-index: 2;
    border: 1px solid transparent;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08) !important;
    border-radius: 12px !important;
    transition: all .2s;
    cursor: pointer;
  }

  .ph-cico-partner-container:hover {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  .ph-cico-partner-icon-img {
    margin: auto;
    object-fit: fill;
    z-index: 1;
    border-radius: 0 !important;
  }

  .ph-cico-partner-icon-img.partner-paymaya {
    height: 50px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-diskartech {
    height: 45px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-dragonpay {
    height: 65px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-ph,
  .ph-cico-partner-icon-img.partner-eon {
    height: 30px;
    width: auto;
  }

  .ph-cico-partner-icon-img.partner-phv {
    height: 30px;
    width: auto;
    margin-right: 10px;
  }

  .phv-text {
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: auto !important;
    font-size: 25px;
    font-family: 'Proxima Nova Bold';
  }

  .ph-cico-fields,
  .ph-cico-back-button {
    margin: 0;
  }

  .ph-cico-partner-container.selected {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  @media only screen and (min-width: 960px) {
    .ph-stepper-action-buttons {
      display: flex;
      margin-bottom: 0;
    }

    .ph-cico-cashout-forms-container {
      margin: 0;
    }
  }
</style>
