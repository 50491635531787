<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-card-title class="ph-nonex-datatable-title">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="ph-textbox ph-textbox-grid-search"
                outlined
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="transactions"
              :search="search"
              :calculate-widths="true"
              class="ph-nonex-datatable"
            >
              <template v-slot:[`item.form_number`]="{ item }">
                {{item.form_number !== '' ? item.form_number : '-'}}
              </template>
              <template v-slot:[`item.serial_number`]="{ item }">
                {{item.serial_number !== '' ? item.serial_number : '-'}}
              </template>
              <template v-slot:[`item.customer_name`]="{item}">
                <div class="ph-nonex-sender-container">
                  <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.customer_name)">
                    <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
                  </v-avatar>
                  <p class="ph-nonex-table-names-text">{{item.customer_name}}</p>
                </div>
              </template>
              <template v-slot:[`item.principal_amount`]="{item}">
                <p class="ph-nonex-table-names-text text-amount">{{item.currency_id == 1 ? 'PHP' : 'USD'}} {{formatAmount(item.principal_amount)}}</p>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.pending_status === '0'">
                  <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.pending_status === 1">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Successful
                </span>
              </template>
              <template v-slot:[`item.action`]="{item}">
                <v-icon class="no-oar-button" left v-if="currUser.location_type !== '1' && item.pending_status === 1 && item.form_number === ''">
                  mdi-note-remove
                </v-icon>
                <v-tooltip left v-if="currUser.location_type === '1' && item.pending_status === 1 && item.form_number !== ''">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="generateOR(item)"
                    >
                      <v-icon>mdi-note-text-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Generate OR</span>
                </v-tooltip>
                <v-tooltip left v-if="item.pending_status === '0'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="checkEmail(item)"
                    >
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>Retry</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="showSearchDialog"
        persistent
        max-width="390"
        justify="center"
      >
        <v-card>
          <v-toolbar
            color="#FFFFFF"
            elevation="0"
            light
          >
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title class="text-h5 ph-search-dialog-title">
            {{searchTitle}}
          </v-card-title>
          <v-card-text class="ph-search-dialog-text" v-if="searchType === 'qr'">
            <img v-if="isSearching" src="@/assets/images/ph-scan-qr-code.gif" />
            <v-simple-table v-if="!isSearching">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Customer Name
                    </th>
                    <th class="text-left">
                      Reference No.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr @click="searchKYC(customerSearchQRResult)">
                    <td>{{ customerSearchQRResult.first_name }} {{ customerSearchQRResult.last_name }}</td>
                    <td>{{ customerSearchQRResult.customer_number }}</td>
                  </tr>
                  <tr v-if="customerSearchQRResult === null || customerSearchQRResult === {}">
                    <td colspan="2">
                      No Record Found
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-text class="ph-search-dialog-text" v-if="searchType === 'crn'">
            <v-text-field
              v-model="crnSearch"
              append-icon="mdi-magnify"
              label="Number"
              single-line
              hide-details
              class="ph-textbox"
              outlined
              v-if="isSearching"
              @keyup.enter="searchCustomerRecord"
            ></v-text-field>
            <v-simple-table v-if="!isSearching">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Customer Name
                    </th>
                    <th class="text-left">
                      Reference No.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="customerSearchQRResult !== null" @click="searchKYC(customerSearchQRResult)">
                    <td>{{ customerSearchQRResult.first_name }} {{ customerSearchQRResult.last_name }}</td>
                    <td>{{ customerSearchQRResult.customer_number }}</td>
                  </tr>
                  <tr v-if="customerSearchQRResult === null || customerSearchQRResult === {}">
                    <td colspan="2">
                      No Records
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions class="ph-search-dialog-actions">
            <v-spacer></v-spacer>
             <v-btn
              color="primary"
              outlined
              text
              class="ph-primary-button ph-primary-execute-btn ph-search-dialog-cancel-button"
              @click="searchCustomerRecord()"
              v-if="searchType === 'crn' && isSearching"
            >
              Search
            </v-btn>
            <v-btn
              color="danger"
              outlined
              text
              class="ph-primary-button ph-action-button ph-search-dialog-cancel-button"
              @click="closeDialog()"
              v-if="searchType === 'qr'"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ProgressDialog
        v-bind:showProgressDialog.sync="showProgressDialog"
        v-bind:setPercentage.sync="setPercentage"
        v-bind:setDescription.sync="setDescription"
        v-bind:setColor.sync="setColor"
      />
      <v-dialog
        v-model="withoutEmailPrompt"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Transaction Confirmation
          </v-card-title>

          <v-card-text class="ph-dialog-header">
            <br>
            No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="updateDetails(selectedCustomer, 'ereceipt')"
              color="primary"
              text
            >
              Update Customer Record
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="proceedTransaction"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="withEmailPrompt"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Transaction Confirmation
          </v-card-title>

          <v-card-text class="ph-dialog-header">
            <br>
            Receipt will send to the client/s email address. Would you like to proceed this transaction?
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="printReceiptInstead"
              color="primary"
              text
            >
              I want a printed Receipt
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="proceedTransaction"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-speed-dial
        v-model="fab"
        dark
        fixed
        bottom
        right
        class="mb-5"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="#1A2791"
            dark
            fab
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="#8B51FF"
              v-bind="attrs"
              v-on="on"
              to="/cards/pera-debit-card/new"
            >
              <v-icon>mdi-account-search-outline</v-icon>
            </v-btn>
          </template>
          <span>Enter Customer Name</span>
        </v-tooltip>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#1A2791"
              v-bind="attrs"
              v-on="on"
              small
              fab
              @click="searchByCRN"
            >
              <img src="@/assets/images/ph-logo-ref-number.svg" width="18" />
            </v-btn>
          </template>
          <span>Enter Reference No.</span>
        </v-tooltip>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              light
              small
              color="#FFDD00"
              v-bind="attrs"
              v-on="on"
              @click="scanQRCode"
            >
              <v-img class="ph-nonex-logo" src="@/assets/images/ph-logo-qr.svg"></v-img>
            </v-btn>
          </template>
          <span>Scan QR</span>
        </v-tooltip>
      </v-speed-dial>
    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import ProgressDialog from '@/components/partials/ProgressDialog.vue'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'PeraDebitCardIndex',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Cards',
          disabled: false,
          href: '/cards'
        },
        {
          text: 'Pera Debit Card',
          disabled: false,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'customer_name'
        },
        {
          text: 'OR Number',
          sortable: true,
          value: 'form_number'
        },
        {
          text: 'Serial Number',
          sortable: true,
          value: 'serial_number'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      filteredACRs: [],
      search: '',
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      transition: 'slide-y-reverse-transition',
      successTransactionData: {},
      searchType: '',
      showSearchDialog: false,
      customerQRData: '',
      customerSearchQRResult: {},
      isSearching: false,
      searchTitle: '',
      crnSearch: '',
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      showProgressDialog: false,
      setPercentage: 0,
      setDescription: '',
      setColor: '#1A2791',
      currentRetryData: {}
    }
  },
  components: {
    ProgressDialog
  },
  computed: {
    ...mapGetters({
      transactions: 'debitCard/debitCardTransactions',
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    const acrs = await this.$store.dispatch('acr/retrieveFilteredACR',
      {
        location_id: this.currUser.location_id,
        trx_date: this.currUser.trx_date.replace(' 00:00:00', ''),
        user_id: this.currUser.user_id
      })
    this.filteredACRs = acrs.data
    const payload = {
      trx_date: this.currUser.trx_date,
      location_id: this.currUser.location_id
    }

    await this.$store.dispatch('debitCard/getCardTransactions', payload)

    // const getCardDetails = await this.$store.dispatch('customerProfileV3/getCardInfo', 'KB010195A4F1').then(res => {
    //   if (!(res.code >= 200 && res.code <= 299)) {
    //   } else {
    //     return res
    //   }
    // }).catch(err => {
    //   return err
    // })
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    updateProgress (percentage, description, color) {
      this.setPercentage = percentage
      this.setDescription = description
      this.setColor = color
    },
    async retrieveUploaded (item) {
      let filtered = {}
      filtered = this.filteredACRs.filter(function (e) {
        return e.ref_number === item.petnet_trackingno && e.type === 'vou'
      })

      const payload = {
        file: btoa(filtered[0].bucket_path)
      }
      const res = await this.$store.dispatch('acr/retrieveACRFile', payload)
      var extension = res.result.extension
      var file = Buffer.from(res.result.file, 'base64')
      var blob = ''
      if (extension === 'pdf') {
        blob = new Blob([file], { type: 'application/pdf' })
      } else if (extension === 'jpg') {
        blob = new Blob([file], { type: 'image/jpg' })
      } else if (extension === 'png') {
        blob = new Blob([file], { type: 'image/png' })
      } else if (extension === 'jpeg') {
        blob = new Blob([file], { type: 'image/jpeg' })
      }
      var fileURL = URL.createObjectURL(blob)
      window.open(fileURL, '_blank')
    },
    async reprintVoucherNoWacom (item) {
      console.log(item)
      const voucherPayload = {
        vlocation_name: this.currUser.location_name,
        vref_no: item.petnet_trackingno,
        has_wacom: false,
        vpayee: item.customer_name,
        vparticulars: item.provider + ':' + ' ' + item.trx_type,
        vamount: item.principal_amount,
        fla: `${this.currUser.last_name}, ${this.currUser.first_name}`,
        trx_type: item.trx_type,
        remco_name: item.provider,
        control_number: item.petnetTrackingNo,
        customer_id: item.id_number.toString(),
        location_id: this.currUser.location_id,
        location_name_uploaded: this.currUser.location_name,
        crn: item.id_number,
        uploaded_by: this.currUser.user_id,
        first_name: '-',
        last_name: '-',
        product: item.provider
      }
      const reqVoucher = await this.$store.dispatch('voucher/printVoucher', voucherPayload)
      console.log(reqVoucher)
      var decoded = Buffer.from(reqVoucher.result, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    async inquirePeraDebitCardTrx (data) {
      let provider = ''
      let trxType = ''

      if (data.provider === 'PeraHubCard') {
        provider = 'PHV'
        trxType = 'Cash In'
      } else if (data.provider === 'Regular') {
        provider = 'EON'
        trxType = 'Cash In'
      } else {
        provider = data.provider
        trxType = data.trx_type
      }

      const payload = {
        provider: provider.toUpperCase(),
        location_id: data.location_id,
        trx_type: trxType,
        reference_number: data.mobile_no,
        partner_code: 'USP',
        petnet_trackingno: data.petnet_trackingno
      }

      await this.$store.dispatch('cico/trxCICOInquire', payload).then(res => {
        if (res.code === 500) {
          this.renderToast('error', 'Inquire Error', res.message)
        } else {
          this.renderToast('info', 'Inquire Current Status', `This ${res.result.trx_type} transaction status is ${res.result.status_message}`)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async updatePendingTransaction (id, data) {
      const pendingData = JSON.parse(data.pending_data)

      const getCardDetails = await this.$store.dispatch('customerProfileV3/getCardInfo', pendingData.customer_code).then(res => {
        if (!(res.code >= 200 && res.code <= 299)) {
        } else {
          return res
        }
      }).catch(err => {
        return err
      })

      if (getCardDetails.code >= 200 && getCardDetails.code <= 299) {
        const updatePayload = {
          id: id,
          params: {
            pending_status: 1,
            pending_desc: '',
            pending_data: '',
            id_ub_card: id,
            customer_id: data.customer_id,
            loyaltycard: '',
            ub_card_no: getCardDetails.result.debitcardno
          }
        }

        let result = {}

        await this.$store.dispatch('debitCard/updatePendingDebitCard', updatePayload).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.renderToast('error', 'Error', res.message)
            result = res
          } else {
            result = res
          }
        })
        return result
      } else {
        return getCardDetails
      }
    },
    async retryPeraDebitCardTrx (data) {
      const pendingData = JSON.parse(data.pending_data)
      this.showProgressDialog = true
      this.updateProgress(0, 'Retrying the transaction. Please wait...', '#FFCA28')
      let payload = {}

      console.log(data.trx_type)

      if (data.trx_type === 'virtual') {
        payload = {
          customer_id: data.customer_id
        }
      } else {
        payload = {
          customer_id: data.customer_id,
          new_card_number: data.card_number,
          apiNewPin: data.card_number.slice(-6)
        }
      }

      const retryTransaction = await this.$store.dispatch('customerProfileV3/retryTransaction', payload).then(res => {
        if (!(res.code >= 200 && res.code <= 299)) {
          this.updateProgress(100, res.message, '#B71C1C')
          this.renderToast('error', 'Retry Error', res.message)
          this.withoutEmailPrompt = false
          this.withEmailPrompt = false
        } else {
          return res
        }
      }).catch(err => {
        console.log(err)
      })

      let updatePending = {}

      if (retryTransaction.code >= 200 && retryTransaction.code <= 299) {
        this.updateProgress(90, 'Finishing transaction...', '#FFCA28')
        updatePending = await this.updatePendingTransaction(data.id, data).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.updateProgress(0, res.message, '#B71C1C')
            this.renderToast('error', 'Error', res.message)
          } else {
            return res
          }
        })
      }

      if (updatePending.code >= 200 && updatePending.code <= 299) {
        this.updateProgress(95, 'Generating E-Receipt...', '#FFCA28')

        const address = pendingData.address
        const barangay = pendingData.barangay
        const city = pendingData.city
        const province = pendingData.province
        const zipCode = pendingData.zipCode

        const saveReceipt = await this.$store.dispatch('saveReceipt', {
          ereceipt_id: pendingData.ereceipt_id,
          application_id: this.currUser.application_id,
          application_header_name: this.currUser.branchDetail.header_name,
          application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
          receipt_type: '1',
          customer_id: data.customer_id,
          customer_name: data.customer_name,
          customer_tin_no: '',
          customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
          customer_email_address: pendingData.email_add,
          cashier_id: this.currUser.user_id,
          cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
          or_receipt_no: pendingData.or_receipt_no,
          or_number: pendingData.or_series_no,
          or_service: 'Pera Debit Card',
          or_currency: 'PHP',
          or_principal_amount: Number(data.amount),
          or_vat_percent: 0,
          or_vat_service_fee: 0,
          or_vat_exempt_fee: 0,
          or_vat_zero_rated_fee: 0,
          or_vat_discount: 0,
          or_vat_amount: 0,
          or_total: Number(data.amount),
          grand_total: Number(data.amount),
          print_email_hold: 0,
          is_forex_logo: 0
        })

        if (saveReceipt.code === 200) {
          const that = this

          if (saveReceipt.result.receipt_status === 1) {
            this.updateProgress(100, 'Success!', '#FFCA28')
            this.renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')

            setTimeout(function () {
              that.showProgressDialog = false
              that.currentRetryData = {}
              that.updateProgress(0, '')
            }, 1500)
          } else if (saveReceipt.result.receipt_status === 2) {
            this.updateProgress(100, 'Success!', '#FFCA28')
            const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
              application_id: this.currUser.application_id,
              ereceipt_api_id: saveReceipt.result.ereceipt_api_id
            })
            var newBlob = new Blob([res], { type: 'application/pdf' })
            var newFileURL = URL.createObjectURL(newBlob)
            window.open(newFileURL, '_blank')
            this.updateProgress(100, 'Success!', '#FFCA28')
            setTimeout(function () {
              that.showProgressDialog = false
              that.currentRetryData = {}
              that.updateProgress(0, '')
            }, 2500)
          } else {
            this.updateProgress(0, 'Failed to get save receipt data', '#B71C1C')
            this.renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
          }
        } else {
          this.updateProgress(0, 'Failed to get save receipt data', '#B71C1C')
          this.renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
        }
      }
    },
    checkIfUploaded (item) {
      console.log(item, 'item from table')
      console.log(this.filteredACRs, 'filtered acr')
      var filtered = this.filteredACRs.filter(function (e) {
        return e.ref_number === item.petnet_trackingno
      })
      if (filtered.length !== 0) {
        return false
      } else {
        return true
      }
    },
    async generateOR (data) {
      console.log(data)
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: '1',
          or_series_no: data.form_number,
          oar_series_no: '',
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    },
    searchByCRN () {
      this.showSearchDialog = true
      this.searchType = 'crn'
      this.isSearching = true
      this.searchTitle = 'Enter Reference No.'
      this.$store.commit('customers/SET_SELECTED_MOBILE_USER', null)
    },
    scanQRCode () {
      this.showSearchDialog = true
      this.searchType = 'qr'
      this.isSearching = true
      this.searchTitle = 'Scanning...'
      this.$store.commit('customers/SET_SELECTED_MOBILE_USER', null)
      window.addEventListener('keydown', this.checkScannedData, false)
    },
    async checkScannedData (e) {
      if (e.key === 'Enter' && this.searchType === 'qr') {
        // Check if QR data has the following values
        const mobileAppDataProp = [
          '"customer_code":',
          '"first_name":',
          '"middle_name":',
          '"last_name":',
          '"birth_date":'
        ]
        const checkIfValidQR = mobileAppDataProp.some(el => this.customerQRData.includes(el))

        if (checkIfValidQR) {
          const qrData = JSON.parse(this.customerQRData)
          const that = this
          await this.$store.dispatch('customerProfileV3/searchByCRN', qrData.customer_code).then(res => {
            this.isSearching = false
            if (res.result === null) {
              that.searchTitle = 'No Customer Found'
              this.customerSearchQRResult = null
              this.renderToast('error', 'Error', 'No customer found. Kindly double check the QR code and try again.')
            } else {
              that.customerSearchQRResult = res.result
              this.searchTitle = 'User Found'
              this.customerQRData = ''
              window.removeEventListener('keydown', this.checkScannedData)
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          this.customerQRData = ''
          this.renderToast('error', 'Invalid QR Code', 'The scanned QR code is invalid. Please try again.')
        }
      } else {
        if (e.key !== 'Shift') {
          this.customerQRData += e.key
        }
      }
    },
    async searchCustomerRecord () {
      if (this.crnSearch === '') {
        this.renderToast('error', 'Error', 'CRN is required')
      } else {
        const that = this
        await this.$store.dispatch('customerProfileV3/searchByCRN', this.crnSearch).then(res => {
          console.log(res.result)
          this.isSearching = false
          if (res.result === null) {
            that.searchTitle = 'No Customer Found'
            this.customerSearchQRResult = null
            this.renderToast('error', 'Error', 'No customer found. Kindly double check the CRN and try again.')
          } else {
            that.searchTitle = 'User Found'
            this.customerSearchQRResult = res.result
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    async searchKYC (item) {
      this.$store.commit('customers/SET_SELECTED_MOBILE_USER', item)
      this.$router.push(`/cards/pera-debit-card/new?fname=${item.first_name}&lname=${item.last_name}&isMobileUser=true`)
    },
    closeDialog () {
      this.showSearchDialog = false
      this.crnSearch = ''

      setTimeout(function () {
        this.searchType = ''
      }, 1000)
    },
    checkEmail (data) {
      this.currentRetryData = data
      const pendingData = JSON.parse(data.pending_data)
      if (this.currUser.location_type === '1') {
        this.sendViaEmail = true
        if (pendingData.email) {
          this.withoutEmailPrompt = false
          this.withEmailPrompt = true
        } else {
          this.withoutEmailPrompt = true
          this.withEmailPrompt = false
          this.sendViaEmail = false
        }
      } else {
        this.proceedTransaction()
      }
    },
    proceedTransaction () {
      this.retryPeraDebitCardTrx(this.currentRetryData)
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.retryPeraDebitCardTrx(this.currentRetryData)
    }
  }
}
</script>
<style scoped>
.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.7);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

.ph-nonex-table-names-text.text-amount {
  margin-left: 0 !important;
}

.text-inquire-button {
  cursor: pointer;
}

.ph-search-dialog-title {
  text-align: center;
  display: block;
  font-family: 'Baloo Extra Bold' !important;
  color: #1A2791;
  padding: 20px !important;
}

.ph-search-dialog-text {
  display: flex;
  flex-direction: column;
}

.ph-search-dialog-text img {
  margin: 0 auto;
}

.ph-primary-button.ph-search-dialog-cancel-button {
  margin-right: 0;
}

.ph-primary-button.ph-primary-execute-btn {
  background: #1A2791;
  color: #fff !important;
  /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
  font-size: 14px;
  border: 2px solid #1A2791;
}

.ph-primary-button.ph-primary-execute-btn:hover {
  background: #fff !important;
  color: #1A2791 !important;
  border: 2px solid #1A2791;
}

.no-oar-button {
  margin-top: 3px;
  margin-left: 5px;
}
</style>
