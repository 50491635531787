<template>
  <v-row v-if="customerInfo">
    <v-col :cols="12" :md="3">
      Name<br>
      <strong>{{ customerInfo.first_name || '—' }} {{ customerInfo.last_name }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Gender<br>
      <strong>{{ customerInfo.gender || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Civil Status<br>
      <strong>{{ customerInfo.civil_status || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Current Address<br>
      <strong>{{ customerInfo.address || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Birth Date<br>
      <strong>{{ customerInfo.birth_date || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Birth Place<br>
      <strong>{{ customerInfo.birth_place || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Email Address<br>
      <strong>{{ customerInfo.email_add || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Mobile Number<br>
      <strong>{{ customerInfo.mobile_no || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Occupation<br>
      <strong>{{ customerInfo.occupation || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Position Level<br>
      <strong>{{ customerInfo.position_level || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Employment<br>
      <strong>{{ customerInfo.employment || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Employer Name<br>
      <strong>{{ customerInfo.employer_name || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Nature Of Work<br>
      <strong>{{ customerInfo.nature_work || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Source Of Funds<br>
      <strong>{{ customerInfo.source_fund || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Purpose Of Transaction<br>
      <strong>{{ customerInfo.purpose_transaction || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Relationship To Sender/Receiver<br>
      <strong>{{ customerInfo.relation_to || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Risk Level<br>
      <strong>{{ customerInfo.risk_level || '—' }}</strong>
    </v-col>
    <v-col :cols="12" :md="3">
      Tags<br>
      <strong>{{ customerInfo.tags || '—' }}</strong>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    customerInfo: {
      type: Object,
      default: null
    },
    customerTransactions: {
      type: Array,
      default: null
    },
    customerRiskLog: {
      type: Array,
      default: null
    }
  }
}
</script>
