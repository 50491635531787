<template>
  <v-container fluid>
    <RemoteAgent v-if="!hasAgent" />
    <CustomerSearch v-if="currentPage === 0 && hasAgent" />
    <NewTransaction v-if="currentPage === 1 && hasAgent" />
    <SuccessfulTransaction v-if="currentPage === 2 && hasAgent" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import RemoteAgent from '@/components/send/RemoteAgent.vue'
import CustomerSearch from '@/components/send/CustomerSearch.vue'
import NewTransaction from '@/components/send/NewTransaction.vue'
import SuccessfulTransaction from '@/components/send/SuccessfulTransaction.vue'

export default {
  name: 'SendMoney',
  computed: {
    ...mapGetters({
      currentPage: 'send/currentPage',
      hasAgent: 'send/hasAgent',
      remoteData: 'send/remoteData'
    })
  },
  components: {
    RemoteAgent,
    CustomerSearch,
    NewTransaction,
    SuccessfulTransaction
  },
  async mounted () {
    if (this.$route.query.remote && !this.remoteData) {
      this.$store.commit('send/SET_HAS_AGENT', false)
    }

    await this.$store.dispatch('getIDList')
    await this.$store.dispatch('nonex/getRemcos')
  }
}
</script>
