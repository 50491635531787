<template>
  <v-app>
    <SideBar />
    <AppBar />
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import SideBar from '@/components/partials/Sidebar.vue'
import AppBar from '@/components/partials/Appbar.vue'

export default {
  name: 'Default',
  components: {
    SideBar,
    AppBar
  }
}
</script>
