<template>
  <div>
    <div class="btn-container">
      <div id="btn-receipt" class="div-button" @click="finalPrint()">
        <v-btn class="ph-primary-button ph-stepper-button ph-primary-execute-btn" outlined text light>Print Receipt</v-btn>
      </div>
    </div>
    <div class="billsCollectionVoucherPrint">
      <div id="voucher">
        <div id="topdiv" style="width: 780px; border: 5px solid #000;overflow: hidden; padding: 5px 0;">
          <div class="APrint" style="width: 198px; padding: 5px 5px 5px 15px;float: left;">
            <img style="width: 100%" src="@/assets/images/ph-logo-horizontal.svg"/>
            <p class="text-left" style="font-size: 9px; padding: 20px 0 0 0">
              Head Office: East Offices Building<br/>
              114 Aguirre Street, Legaspi Village<br/>
              Makati City 1229<br/>
              <b>VAT REG. TIN: 201-200-777-00000</b>
            </p>
          </div>
          <div style="width: 515px; float: right;">
            <div style="height: 40px; text-align: center; overflow:hidden;">
              <p style="font-weight: bold;font-size: 16px;">Pera Dali Voucher : Branch Copy</p>
            </div>
            <div style="width: 250px; float:left;">
              <div style="float: left; width: 75px; padding: 27px 0 0 0;">
                <h4 style="line-height: 0">Branch:</h4>
              </div>
              <div style="float: left; width: 160px; padding: 27px 0 0 0; border-bottom: 2px solid #000">
                <span style="line-height: 0">{{currUser.location_name}}</span>
              </div>
            </div>
            <div style="width: 265px; float:left;">
              <div style="float: left; width: 77px; padding: 27px 0 0 0;">
                <h4 style="line-height: 0">Ref No.:</h4>
              </div>
              <div style="float: left; width: 160px; padding: 27px 0 0 0; border-bottom: 2px solid #000">
                <span style="line-height: 0">{{billsCollectionVoucherData.voucher_data.reference_no}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="row" style="margin: 5px auto; width: 780px; border: 5px solid #000;overflow: hidden; padding: 5px 0;">
          <div style="width:100%; overflow: hidden; padding: 3px 5px; text-align: left; margin-left: 75%;">
            <span style="font-size: 19px; ">Date:</span>
            <span style="border-bottom: 2px solid #000; padding: 0 10px;">{{billsCollectionVoucherData.trx_data.trx_date}}</span>
          </div>
          <div style="width:100%; overflow: hidden; padding: 5px; overflow: hidden;">
            <div style="float: left; width: 20%;">Payee:</div>
            <div style="float: left; width: 80%; border-bottom: 2px solid #000; padding: 3px 0;">{{billsCollectionVoucherData.voucher_data.customer_name}} {{billsCollectionVoucherData.trx_data.provider.includes('Loan Paycenter') ? `- ${billsCollectionVoucherData.trx_data.print_receipt}` : ''}}</div>
          </div>
          <div style="width:100%; overflow: hidden; padding: 5px; overflow: hidden;">
            <div style="float: left; width: 20%;">Particulars:</div>
            <div style="float: left; width: 80%; border-bottom: 2px solid #000; padding: 3px 0;">{{billsCollectionVoucherData.voucher_data.particulars}}</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="row" style="margin: 5px auto; width: 780px; border: 5px solid #000;overflow: hidden; padding: 5px 0;">
          <div style="width: 100%;">
            <div style="float: left; width: 100%;">
              <div style="float: left; width: 15%;">Cash-out Amount:</div>
              <div style="float: left; width: 70%; border-bottom: 2px solid #000; padding: 3px 0; margin-left: 15px">PHP{{formatAmount(billsCollectionVoucherData.voucher_data.total_amount)}}</div>
            </div>
          </div><br>
          <div style="width: 100%;">
            <div style="float: left; width: 30%; margin: 0 10%; text-align: center; padding-top: 8px;">
              <div>Received by:</div>
              <div style="height: 35px;"><p style=" padding-top: 15px;">{{billsCollectionVoucherData.voucher_data.customer_name}}</p></div>
              <div></div>
              <div style="border-top: 2px solid #000">Signature</div>
            </div>
            <div style="float: right; width: 30%; margin: 0 10%; text-align: center; padding-top: 8px;">
              <div>Released By :</div>
              <div style="height: 35px;"><p style=" padding-top: 15px;">{{currUser.first_name}} {{currUser.last_name}}</p></div>
              <div></div>
              <div style="border-top: 2px solid #000">Signature</div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div id="voucher">
        <div id="topdiv" style="width: 780px; border: 5px solid #000;overflow: hidden; padding: 5px 0;">
          <div class="APrint" style="width: 198px; padding: 5px 5px 5px 15px;float: left;">
            <img style="width: 100%" src="@/assets/images/ph-logo-horizontal.svg"/>
            <p class="text-left" style="font-size: 9px; padding: 20px 0 0 0">
              Head Office: East Offices Building<br/>
              114 Aguirre Street, Legaspi Village<br/>
              Makati City 1229<br/>
              <b>VAT REG. TIN: 201-200-777-00000</b>
            </p>
          </div>
          <div style="width: 515px; float: right;">
            <div style="height: 40px; text-align: center; overflow:hidden;">
              <p style="font-weight: bold;font-size: 16px;">Pera Dali Voucher : Customer's Copy</p>
            </div>
            <div style="width: 250px; float:left;">
              <div style="float: left; width: 75px; padding: 27px 0 0 0;">
                <h4 style="line-height: 0">Branch:</h4>
              </div>
              <div style="float: left; width: 160px; padding: 27px 0 0 0; border-bottom: 2px solid #000">
                <span style="line-height: 0">{{currUser.location_name}}</span>
              </div>
            </div>
            <div style="width: 265px; float:left;">
              <div style="float: left; width: 77px; padding: 27px 0 0 0;">
                <h4 style="line-height: 0">Ref No.:</h4>
              </div>
              <div style="float: left; width: 160px; padding: 27px 0 0 0; border-bottom: 2px solid #000">
                <span style="line-height: 0">{{billsCollectionVoucherData.voucher_data.reference_no}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="row" style="margin: 5px auto; width: 780px; border: 5px solid #000;overflow: hidden; padding: 5px 0;">
          <div style="width:100%; overflow: hidden; padding: 3px 5px; text-align: left; margin-left: 75%;">
            <span style="font-size: 19px; ">Date:</span>
            <span style="border-bottom: 2px solid #000; padding: 0 10px;">{{billsCollectionVoucherData.trx_data.trx_date}}</span>
          </div>
          <div style="width:100%; overflow: hidden; padding: 5px; overflow: hidden;">
            <div style="float: left; width: 20%;">Payee:</div>
            <div style="float: left; width: 80%; border-bottom: 2px solid #000; padding: 3px 0;">{{billsCollectionVoucherData.voucher_data.customer_name}} {{billsCollectionVoucherData.trx_data.provider.includes('Loan Paycenter') ? `- ${billsCollectionVoucherData.trx_data.print_receipt}` : ''}}</div>
          </div>
          <div style="width:100%; overflow: hidden; padding: 5px; overflow: hidden;">
            <div style="float: left; width: 20%;">Particulars:</div>
            <div style="float: left; width: 80%; border-bottom: 2px solid #000; padding: 3px 0;">{{billsCollectionVoucherData.voucher_data.particulars}}</div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="row" style="margin: 5px auto; width: 780px; border: 5px solid #000;overflow: hidden; padding: 5px 0;">
          <div style="width: 100%;">
            <div style="float: left; width: 100%;">
              <div style="float: left; width: 15%;">Cash-out Amount:</div>
              <div style="float: left; width: 70%; border-bottom: 2px solid #000; padding: 3px 0; margin-left: 15px">PHP{{formatAmount(billsCollectionVoucherData.voucher_data.total_amount)}}</div>
            </div>
          </div><br>
          <div style="width: 100%;">
            <div style="float: left; width: 30%; margin: 0 10%; text-align: center;  padding-top: 8px;">
              <div>Received by:</div>
              <div style="height: 35px;"><p style=" padding-top: 15px;">{{billsCollectionVoucherData.voucher_data.customer_name}}</p></div>
              <div></div>
              <div style="border-top: 2px solid #000">Signature</div>
            </div>
            <div style="float: right; width: 30%; margin: 0 10%; text-align: center; padding-top: 8px;">
              <div>Released By :</div>
              <div style="height: 35px;"><p style=" padding-top: 15px;">{{currUser.first_name}} {{currUser.last_name}}</p></div>
              <div></div>
              <div style="border-top: 2px solid #000">Signature</div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'

export default {
  name: 'Voucher',
  data () {
    return {
      customerName: '',
      dateEncoded: '',
      particulars: '',
      referenceNo: '',
      totalAmount: '',
      userFullName: ''
    }
  },
  computed: {
    ...mapGetters({
      billsCollectionVoucherData: 'bills/billsCollectionVoucherData',
      currUser: 'auth/currUser'
    })
  },
  watch: {
    billsCollectionVoucherData: {
      handler (item) {
        console.log(item)
      },
      deep: true
    }
  },
  async mounted () {
    if (this.billsCollectionVoucherData === {}) {
      window.location.href = '/pera-bills/collections'
    }

    console.log(this.billsCollectionVoucherData)
  },
  methods: {
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    finalPrint () {
      window.print()
    }
  }
}
</script>
<style>
#topdiv {
  margin: auto;
}

@media print{
  .btn-container {
    display: none;
  }

  #topdiv {
    height: 135px;
    width: 760px;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  #main {
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
  }
}
</style>
