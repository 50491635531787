import api from '@/api'
// import newkycgatewayv2 from '@/api/newkycgatewayv2'
// import uspmaintenance from '@/api/uspmaintenance'

export default {
  getCICOList (payload) {
    return api.get(`/v1/transactions/api/trx/cashincashout?trx_date=${payload.trxDate}&location_id=${payload.locationId}`)
  },
  trxCICOValidate (payload) {
    return api.post('/v1/cico/wrapper/validate', payload)
  },
  trxCICOExecute (payload) {
    return api.post('/v1/cico/wrapper/execute', payload)
  },
  trxCICOInquire (payload) {
    return api.post('/v1/cico/wrapper/inquiry', payload)
  },
  trxCICORetry (payload) {
    return api.post('/v1/cico/wrapper/retry', payload)
  },
  trxOTPCustomer (payload) {
    return api.post('/v1/cico/wrapper/otp', payload)
  },
  trxEONCashIn (payload) {
    return api.post('/v1/cico/eon/ibl/cashin', payload)
  },
  trxEONCashOut (payload) {
    return api.post('/v1/cico/eon/ibl/cashout', payload)
  },
  trxEONRetry (payload) {
    return api.post('/v1/cico/eon/ibl/retry', payload)
  },
  trxOTPSend (payload) {
    return api.post('/v1/maintenance/api/otp-cashout/verification', payload)
  },
  trxOTPPerahub (payload) {
    return api.post('/v1/maintenance/api/otp-cashout/fla-verification', payload)
  },
  trxPHVCashOutExecute (payload) {
    return api.post('v1/cico/eon/ibl/debit-account', payload)
  },
  trxPHVCashOutRetry (payload) {
    return api.post('v1/cico/eon/ibl/transaction-inquiry', payload)
  },
  generateCICOVoucher (payload) {
    return api.post('/v1/maintenance/api/receipt/print-voucher', payload)
  },
  getCICOProviders () {
    return api.get('/v1/maintenance/api/cico/providers')
  }
}
