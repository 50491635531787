<template>
  <v-row class="ph-wu-quickpay-transfer-info">
    <!-- Company Details -->
    <v-col cols="12" sm="6">
      <v-autocomplete
        v-model="quickPayTrxData.company"
        :rules="quickPayTrxData.requiredField"
        label="Company"
        @change="syncCompanyInfo($event)"
        :items="collections.qpCompanies"
        item-text="company_name"
        return-object
        color="gray darken-1"
        class="ph-textbox"
        outlined
      />
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model="quickPayTrxData.codeCity"
        :rules="quickPayTrxData.requiredField"
        label="Code City"
        color="gray darken-1"
        class="ph-textbox"
        outlined
      />
      <br>
      <p class="ph-qp-country">Country: <b>{{quickPayTrxData.country ? quickPayTrxData.country : '-'}}</b></p>
    </v-col>
    <v-col cols="12" sm="6" v-if="collections.qpStateCities.length > 0">
      <v-autocomplete
        v-model="quickPayTrxData.payoutState"
        :rules="quickPayTrxData.requiredField"
        label="Payout State"
        @change="syncStateCities($event)"
        :items="collections.qpStateCities"
        item-text="state_name"
        return-object
        color="gray darken-1"
        class="ph-textbox"
        outlined
      />
    </v-col>
    <v-col cols="12" :sm="collections.qpStateCities.length > 0 ? 6 : 12">
      <v-text-field
        v-model="quickPayTrxData.payoutCity"
        :rules="quickPayTrxData.requiredField"
        label="Payout City"
        color="gray darken-1"
        class="ph-textbox"
        outlined
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="quickPayTrxData.sendingCurrency"
        :rules="quickPayTrxData.requiredField"
        label="Sending Currency"
        color="gray darken-1"
        class="ph-textbox"
        outlined
        readonly
      />
      <!-- <v-autocomplete
        v-model="quickPayTrxData.currency"
        label="Sending Currency"
        :items="currency"
        item-text="currency_text"
        item-value="currency_value"
        color="gray darken-1"
        class="ph-textbox"
        outlined
      /> -->
    </v-col>
    <!-- Country Information and Exchange Rate -->
    <v-col cols="12" sm="6">
      <v-btn text color="primary" @click="generateF2Zoom(quickPayTrxData.countryCode)">View Country Information</v-btn>
    </v-col>
    <v-col cols="12" sm="6">
      <p class="ph-qp-rate">Exchange Rate: <b>{{quickPayTrxData.exchangeRate ? quickPayTrxData.exchangeRate : '-'}}</b></p>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model="quickPayTrxData.sendAmount"
        :rules="quickPayTrxData.requiredField"
        @blur="feeInquiry('N')"
        class="ph-textbox"
        label="Amount to Send (in Philippine Peso)"
        outlined
        :disabled="!this.quickPayTrxData.company"
      />

      <!-- :disabled="!transactionData.destinationCountryID" -->
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model="quickPayTrxData.receiveAmount"
        :rules="quickPayTrxData.requiredField"
        @blur="feeInquiry('F')"
        class="ph-textbox"
        label="Amount to Receive (in Foreign Currency)"
        outlined
        :disabled="!this.quickPayTrxData.company"
      />
        <!-- :disabled="!transactionData.destinationCountryID"
        v-if="transactionData.destinationCountryID && transactionData.destinationCountryID !== 208" -->
    </v-col>
    <v-col cols="12" sm="6">
       <v-checkbox
        v-model="quickPayTrxData.isPromo"
        label="Promo"
      ></v-checkbox>
      <v-text-field
        v-if="quickPayTrxData.isPromo === true"
        v-model="quickPayTrxData.promoCode"
        label="Promo Code"
        color="gray darken-1"
        class="ph-textbox"
        outlined
      />
    </v-col>
    <v-col cols="12" sm="6">
      <p class="ph-qp-transfer-fee">Transfer Fee: <b>{{ quickPayTrxData.transferFee ? `${quickPayTrxData.sendingCurrency} ${quickPayTrxData.transferFee}` : '-' }}</b></p>
    </v-col>
    <!-- Total Amount -->
    <v-col cols="12">
      <v-text-field
        v-model="quickPayTrxData.totalAmount"
        :rules="quickPayTrxData.requiredField"
        label="Total Amount"
        color="gray darken-1"
        class="ph-textbox"
        outlined
        readonly
      />
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model="quickPayTrxData.accountNumber"
        :rules="quickPayTrxData.requiredField"
        label="Account Number"
        color="gray darken-1"
        class="ph-textbox"
        outlined
      />
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model="quickPayTrxData.reference"
        label="Reference"
        color="gray darken-1"
        class="ph-textbox"
        outlined
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="quickPayTrxData.attention"
        label="Attention"
        color="gray darken-1"
        class="ph-textbox"
        outlined
      />
    </v-col>
    <v-dialog
      v-model="showCountryInfo"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          View Country Information
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          <v-textarea
            v-model="countryDetails"
            name="input-7-1"
            filled
            auto-grow
            outlined
            readonly
            value=""
            class="ph-wu-f2zoom-textarea"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="showCountryInfo = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { getForeignRefNo, renderToast } from '@/utils'

export default {
  name: 'QuickPayTransferInfo',
  props: {
    quickPayTrxData: Object
  },
  data () {
    return {
      terminalId: '',
      operatorId: '',
      locationId: '',
      countryDetails: 'Please select a country to continue',
      showCountryInfo: false
    }
  },
  computed: {
    ...mapGetters({
      collections: 'collections',
      currUser: 'auth/currUser',
      remoteData: 'obo/remoteData'
    })
  },
  beforeMount () {
    [
      'getQPCompanies'
    ].forEach(action => this.$store.dispatch(action))
  },
  async mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Transfer Information')

    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData?.remote_location?.location_id
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    }

    this.quickPayTrxData.reference = this.getForeignRefNo()
    this.$store.commit('wu/SET_QP_FOREIGN_REF_NO', this.quickPayTrxData.reference)
    this.quickPayTrxData.fixedAmountFlag = ''
  },
  methods: {
    getForeignRefNo,
    renderToast,
    async syncCompanyInfo (data) {
      console.log(data)
      this.quickPayTrxData.company = data.company_name
      this.quickPayTrxData.codeCity = data.client_id
      this.quickPayTrxData.sendingCurrency = 'PHP'
      this.quickPayTrxData.country = data.country
      this.quickPayTrxData.receivingCurrency = data.currency_cd
      this.quickPayTrxData.countryCode = data.iso_country_cd
      await this.$store.dispatch('getQPStateCities', data.iso_country_cd)
    },
    syncStateCities (data) {
      this.quickPayTrxData.payoutState = data
      this.quickPayTrxData.city = data.city
    },
    async feeInquiry (flag) {
      const {
        countryCode,
        receivingCurrency,
        sendAmount,
        receiveAmount,
        promoCode,
        reference
      } = this.quickPayTrxData

      if (flag) {
        // this.$store.commit('send/SET_TRANSACTION_DATA', {
        //   prop: 'fixedAmountFlag',
        //   value: flag
        // })

        this.quickPayTrxData.fixedAmountFlag = flag
      }

      const { fixedAmountFlag } = this.quickPayTrxData

      if (countryCode && fixedAmountFlag && (sendAmount || receiveAmount) && (Number(sendAmount) > 0 || Number(receiveAmount) > 0)) {
        let principalAmount

        console.log(fixedAmountFlag)

        if (fixedAmountFlag === 'N') principalAmount = sendAmount
        if (fixedAmountFlag === 'F') principalAmount = receiveAmount

        if (principalAmount) {
          const payload = {
            principal_amount: principalAmount,
            agent_code: this.currUser.location_code,
            // agent_code: '01030063',
            branch_code: this.currUser.location_code,
            foreign_reference_no: reference,
            fixed_amount_flag: fixedAmountFlag,
            destination_country_code: countryCode,
            destination_currency_code: receivingCurrency,
            transaction_type: 'QP',
            city_code: this.quickPayTrxData.codeCity,
            company_name: this.quickPayTrxData.company,
            promo_code: promoCode || '',
            message: [],
            message_line_count: 0,
            terminal_id: this.terminalId,
            operator_id: this.operatorId,
            location_id: this.locationId
          }

          const req = await this.$store.dispatch('wu/getQPFeeInquiry', payload)

          console.log(req.code)

          if (req.code === 200) {
            this.quickPayTrxData.exchangeRate = `1 ${this.quickPayTrxData.sendingCurrency} = ${req.result.exchange_rate}`
            this.quickPayTrxData.exchangeRateValue = req.result.exchange_rate
            console.log(principalAmount)
            this.quickPayTrxData.principalAmount = principalAmount
            this.quickPayTrxData.transferFee = Number(req.result.charges) / 100

            if (countryCode !== 'PH') {
              this.quickPayTrxData.sendAmount = Number(req.result.originators_principal_amount) / 100
              this.quickPayTrxData.receiveAmount = Number(req.result.pay_amount) / 100
            }

            this.quickPayTrxData.totalAmount = Number(req.result.gross_total_amount) / 100

            if (countryCode === 'PH') {
              this.quickPayTrxData.exchangeRate = '1 PHP = 1.0000000 PHP'
              this.quickPayTrxData.exchangeRateValue = 1.0000000
              this.quickPayTrxData.receiveAmount = this.quickPayTrxData.principalAmount
            }

            // remcos.forEach(remco => {
            //   // this.$store.commit('send/SET_REMCO_FEE', {
            //   //   id: remco.id,
            //   //   serviceFee: remco.service_fee,
            //   //   body: remco.body
            //   // })

            //   this.quickPayTrxData.transferFee = remco.service_fee

            //   if (remco.id === 0) {
            //     // this.$store.commit('send/SET_FEE_INQUIRY_DATA', remco.body)
            //     // this.$store.commit('send/SET_TRANSACTION_DATA', {
            //     //   prop: 'exchangeRate',
            //     //   value: this.feeInquiryData.exchange_rate
            //     // })

            //     this.quickPayTrxData.exchangeRate = `1 ${this.quickPayTrxData.sendingCurrency} = ${remco.body.exchange_rate}`
            //     this.quickPayTrxData.exchangeRateValue = remco.body.exchange_rate
            //     console.log(principalAmount)
            //     this.quickPayTrxData.principalAmount = principalAmount

            //     if (countryCode !== 'PH') {
            //       // this.$store.commit('send/SET_TRANSACTION_DATA', {
            //       //   prop: 'sendAmount',
            //       //   value: Number(this.feeInquiryData.originators_principal_amount) / 100
            //       // })

            //       this.quickPayTrxData.sendAmount = Number(remco.body.originators_principal_amount) / 100

            //       // this.$store.commit('send/SET_TRANSACTION_DATA', {
            //       //   prop: 'receiveAmount',
            //       //   value: Number(this.feeInquiryData.pay_amount) / 100
            //       // })

            //       this.quickPayTrxData.receiveAmount = Number(remco.body.pay_amount) / 100
            //     }

            //     this.quickPayTrxData.totalAmount = Number(remco.body.gross_total_amount) / 100
            //   }
            // })
          } else {
            // Error handler
          }
        }
      }
    },
    async generateF2Zoom (country) {
      if (country) {
        let getCurrency = ''

        await this.collections.countries.filter(data => {
          if (data.two_iso_code === country) {
            getCurrency = data.currency
          }
        })

        const payload = {
          location_code: this.currUser.location_code,
          user_code: this.currUser.user_id,
          country: `${country} ${getCurrency}`,
          terminal_id: this.currUser.fs_id,
          operator_id: this.currUser.user_id,
          location_id: this.currUser.location_id
        }

        this.$store.dispatch('wu/getF2ZoomResult', payload).then(res => {
          this.countryDetails = this.renderCountryInformation(res.result)
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.renderToast('error', 'Error', 'You must choose a company first!')
      }
    },
    renderCountryInformation (value) {
      const textCountry = []
      const countryData = value

      for (var i = 0; i < countryData.length; i++) {
        var _countrDyata = countryData[i].INFO
        if (typeof _countrDyata !== 'undefined' && _countrDyata !== null) {
          textCountry.push(countryData[i].INFO)
        } else {
          textCountry.push('')
        }
      }

      const string = textCountry.toString()
      const countryInfo = string.replace(/,/g, '\n')

      console.log(countryInfo)

      this.showCountryInfo = true

      return countryInfo
    }
  }
}
</script>
<style scoped>
  /* .custom-card .caption {
    font-family: 'Proxima Nova' !important;
  }
  .ph-id-detail-title {
    margin-bottom: 15px;
  }

  .ph-selected-customer-name {
    font-size: 14px;
  } */

  .ph-wu-quickpay-transfer-info {
    padding: 10px 0;
  }

  .ph-qp-country,
  .ph-qp-rate,
  .ph-qp-transfer-fee {
    text-align: right;
  }

  .ph-qp-transfer-fee {
    margin-top: 20px;
  }

  .ph-wu-f2zoom-textarea {
    font-family: 'consolas' !important;
    font-size: 14px;
    line-height: 1.5;
  }
</style>
