<template>
  <v-col :cols="0" :md="4">
    <v-card id="summaryOverview" class="d-none d-md-block px-6 py-6 grey--text text--darken-2" flat>
      <h3 class="text-center mb-md-8">Transaction Summary</h3>
      <v-row no-gutters>
        <v-col v-if="alert.isVisible" :cols="12">
          <v-alert
            outlined
            :type="alert.type"
            prominent
            border="left"
          >
            {{ alert.message }}
          </v-alert>
        </v-col>
        <v-col v-for="data in summaryData" :key="data.text" :cols="12">
          <div class="d-flex justify-space-between">
            <div>{{ data.text }}</div>
            <div>
              <strong>{{ data.value || '—' }}</strong>
            </div>
          </div>
          <v-divider class="my-md-2" style="border-style: dashed" />
        </v-col>
      </v-row>
      <v-btn
        @click="doRiskProfiling"
        class="rounded-lg mt-md-4 font-weight-black white--text"
        :disabled="!completedForm"
        :color="(transactionData.type === 'BUY') ? '#46B746' : '#F76F34'"
        block
        x-large
      >
        Confirm {{ transactionData.type }}
      </v-btn>
    </v-card>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-wu-receive-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-wu-receive-summary-minified">
          <div class="ph-wu-receive-summary-content">
            <div class="ph-wu-receive-summary-minified-info">
              <p><b>Transaction Summary</b></p>
              <p>{{ summaryMobileLabel }}: &nbsp;<b>{{ summaryMobileValue }}</b></p>
            </div>
            <div class="ph-wu-receive-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Transaction Summary</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row no-gutters>
          <v-col class="px-2 py-2" v-if="alert.isVisible" :cols="12">
            <v-alert
              outlined
              :type="alert.type"
              prominent
              border="left"
            >
              {{ alert.message }}
            </v-alert>
          </v-col>
          <v-col v-for="data in summaryData" :key="data.text" :cols="12">
            <div class="my-2 px-2 my-md-0 mx-md-0 d-flex justify-space-between">
              <div>{{ data.text }}</div>
              <div>
                <strong>{{ data.value || '—' }}</strong>
              </div>
            </div>
            <v-divider class="my-md-2" style="border-style: dashed" />
          </v-col>
        </v-row>
        <div class="px-1">
          <v-btn
            @click="doRiskProfiling"
            class="rounded-lg mt-6 font-weight-black white--text"
            :disabled="!completedForm"
            :color="(transactionData.type === 'BUY') ? '#46B746' : '#F76F34'"
            block
            x-large
          >
            Confirm {{ transactionData.type }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withoutEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updateDetails(selectedCustomer)"
            color="primary"
            text
          >
            Update Customer Record
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Receipt will send to the client/s email address. Would you like to proceed this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="printReceiptInstead"
            color="primary"
            text
          >
            I want a printed Receipt
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noIDFlag"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          No identification card found
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="noIDFlag = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="updateDetails(selectedCustomer)"
          >
            Update Customer Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <wacom-transaction
      v-if="currUser.has_wacom"
      @submit-sig="submitSig" type="transactional"
      :referenceSignatures="customerSignaturesReference"
      :trxData="{sender_name: selectedCustomer.full_name, receiver_name: 'N/A', total_amount: transactionData.releaseAmount}"
    />
    <wacom-data-consent-first />
    <wacom-data-consent-second />
    <wacom-data-consent-third />
    <wacom-data-consent-fourth />
    <wacom-data-consent-fifth />
    <wacom-data-consent-sixth />
    <wacom-data-consent-seventh
      :remco="'Pera Palit'"
    />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { toMoney, renderToast } from '@/utils'
import wacomMixin from '@/mixins/wacom.js'
import wacomTransaction from '@/components/wacom/wacomTransaction'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'

export default {
  mixins: wacomMixin,
  components: {
    wacomTransaction,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  computed: {
    ...mapGetters({
      transactionData: 'moneyChange/transactionData',
      selectedCustomer: 'customers/selectedCustomer',
      rates: 'moneyChange/rates',
      currUser: 'auth/currUser',
      alert: 'moneyChange/alert',
      receipt: 'receipt',
      consentValues: 'wacom/consentValues',
      riskData: 'compliance/riskData',
      collections: 'collections'
    }),
    completedForm () {
      let isValidAmount = false
      let isValidTransaction = false
      const {
        type,
        currencyID,
        receiveAmount,
        receiveCurrency,
        releaseAmount,
        releaseCurrency,
        rateID
      } = this.transactionData

      if (type === 'BUY') {
        isValidTransaction = true
        isValidAmount = (receiveAmount >= 2)
      }
      if (type === 'SELL') {
        isValidTransaction = true
        isValidAmount = (releaseAmount >= 16)
      }

      return (
        isValidTransaction &&
        type &&
        currencyID &&
        receiveAmount &&
        receiveCurrency &&
        releaseAmount &&
        releaseCurrency &&
        rateID &&
        isValidAmount
      )
    },
    summaryData () {
      const fullData = this.transactionData
      const summary = {
        type: { text: 'Transaction Type', value: null },
        rateType: { text: 'Rate Type', value: null },
        rateAmount: { text: 'Rate Amount', value: null },
        receiveAmount: { text: 'Amount to Receive', value: null },
        releaseAmount: { text: 'Amount to Release', value: null }
      }

      const fields = Object.keys(summary)

      fields.forEach(field => {
        switch (field) {
          case 'rateType':
            if (fullData.rateID) {
              summary[field].value = this.rateData(fullData.rateID).category
            }
            break
          case 'rateAmount':
            if (fullData.rateID) {
              if (fullData.type === 'BUY') {
                summary[field].value = toMoney(this.rateData(fullData.rateID).buy_am_rate)
              } else {
                summary[field].value = toMoney(this.rateData(fullData.rateID).sell_am_rate)
              }
            }
            break
          case 'receiveAmount':
            if (fullData.receiveAmount) {
              summary[field].value = toMoney(fullData.receiveAmount, fullData.receiveCurrency)
            }
            break
          case 'releaseAmount':
            if (fullData.releaseAmount) {
              summary[field].value = toMoney(fullData.releaseAmount, fullData.releaseCurrency)
            }
            break
          default:
            if (fullData[field]) {
              summary[field].value = fullData[field]
            }
            break
        }
      })

      return Object.values(summary)
    },
    summaryMobileLabel () {
      if (this.transactionData.type === 'BUY') {
        return 'Release Amount'
      } else {
        return 'Receive Amount'
      }
    },
    summaryMobileValue () {
      if (this.transactionData.type === 'BUY') {
        return toMoney(this.transactionData.releaseAmount)
      } else {
        return toMoney(this.transactionData.receiveAmount)
      }
    }
  },
  data () {
    return {
      summaryDialog: false,
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      customerSignaturesReference: [],
      hasCustomerDataConsent: false,
      customerSignature: '',
      noIDFlag: false
    }
  },
  methods: {
    async doRiskProfiling () {
      if (this.selectedCustomer.img === '{}') {
        this.noIDFlag = true
      } else {
        if (
          this.currUser.cisrat === 1 &&
          String(this.selectedCustomer.edd_upload_status) !== '3'
        ) {
          const [sourceOfFund] = this.collections.sourceOfFund.filter(item => item.source_of_funds === this.selectedCustomer.source_fund)
          const [purposeOfTransaction] = this.collections.purposeOfTransaction.filter(item => item.purpose === this.selectedCustomer.purpose_transaction)
          const [relationship] = this.collections.relationship.filter(item => item.relationship === this.selectedCustomer.relation_to)
          const [occupation] = this.collections.occupation.filter(item => item.occupation_value === this.selectedCustomer.occupation)

          this.$store.commit('compliance/UPDATE_RISK_DATA', {
            spro: {
              source_score: sourceOfFund?.risk_score || '1',
              purpose_score: purposeOfTransaction?.risk_score || '1',
              relationship_score: relationship?.risk_score || '1',
              occupation_score: occupation?.risk_score || '1'
            }
          })

          const payload = {
            trans_details: {
              dom_int: 'MC',
              source_fund: sourceOfFund ? String(sourceOfFund.id) : '',
              purpose_transaction: purposeOfTransaction ? String(purposeOfTransaction.id) : '',
              relation_to: relationship ? String(relationship.id) : '',
              occupation: occupation ? String(occupation.id) : '',
              wu_compliance: `{"edd_upload_status": "${this.selectedCustomer.edd_upload_status}"}`,
              principal_amount: this.transactionData.type === 'BUY' ? parseFloat(this.transactionData.releaseAmount) : parseFloat(this.transactionData.receiveAmount),
              wu_country_code_paid: 'PH'
            }
          }

          if (this.riskData.spro) payload.spro = JSON.stringify(this.riskData.spro)
          if (this.riskData.getSum) payload.tf = JSON.stringify(this.riskData.getSum)
          if (this.riskData.searchPNA) payload.pna = JSON.stringify(this.riskData.searchPNA)

          const { code, result } = await this.$store.dispatch('compliance/riskProfiling', payload)

          if (code === 200) {
            let eddStatus
            const reasons = []

            this.$store.commit('compliance/UPDATE_RISK_DATA', { riskProfiling: result })

            if (result.risk_criteria) {
              const riskCriteria = result.risk_criteria

              riskCriteria.forEach(item => {
                if (item.criteria !== 'Fraudsters' && item.criteria !== 'Negative List' && item.criteria !== 'PEP') {
                  reasons.push(item.criteria)
                }
              })
            }

            if (String(this.selectedCustomer.edd_upload_status) === '1') eddStatus = 'Need to Upload Documents'
            if (String(this.selectedCustomer.edd_upload_status) === '2') eddStatus = 'Documents under review for compliance'
            if (String(this.selectedCustomer.edd_upload_status) === '4') eddStatus = 'Not Approve/Pending Documents'

            if (String(result.risk_score) === '3' || String(result.risk_score) === '4') {
              await this.$store.dispatch('compliance/saveRisk', {
                trans_details: {
                  dom_int: 'MC',
                  trx_type: `MC - ${this.transactionData.type}`,
                  trx_date: this.currUser.trx_date,
                  customer_name: this.selectedCustomer.full_name,
                  risk_score: result.risk_score,
                  curr_risk_score: this.selectedCustomer.risk_score,
                  risk_criteria: (result.risk_criteria) ? JSON.stringify(result.risk_criteria) : '[]',
                  customer_id: this.selectedCustomer.customer_id,
                  monitoring_type: '1',
                  principal_amount: this.transactionData.type === 'BUY' ? parseFloat(this.transactionData.releaseAmount) : parseFloat(this.transactionData.receiveAmount)
                },
                location_name: this.currUser.location_name,
                location_id: this.currUser.location_id,
                user_id: this.currUser.user_id,
                service_id: '2',
                update_status_by: this.currUser.user_id,
                first_name: this.selectedCustomer.first_name,
                last_name: this.selectedCustomer.last_name,
                compliance_remarks: this.selectedCustomer.compliance_edd_remarks,
                fp_remarks: '',
                false_positive: '',
                edd_alert: 'true',
                _is_not_fraud: '0',
                criteria: (result.risk_criteria) ? JSON.stringify(result.risk_criteria) : '[]',
                reference_no: '',
                actual_date_trx: this.currUser.trx_date
              })
            }

            if (String(result.risk_score) === '4') {
              this.$store.commit('compliance/UPDATE_HIGH_RISK_DIALOG', {
                isShow: true,
                type: 'FOUND_HIGH_RISK_STOP',
                details: {
                  status: eddStatus,
                  remarks: this.selectedCustomer.compliance_edd_remarks,
                  reasons: reasons.join(', ')
                },
                customer: this.selectedCustomer
              })
              this.$router.push('/pera-palit')
              return true
            }
            if (String(result.risk_score) === '3') {
              this.$store.commit('compliance/UPDATE_HIGH_RISK_DIALOG', {
                isShow: true,
                type: 'FOUND_HIGH_RISK_GO',
                details: {
                  status: eddStatus,
                  remarks: this.selectedCustomer.compliance_edd_remarks,
                  reasons: reasons.join(', ')
                },
                customer: this.selectedCustomer
              })
            }
            this.checkEmail()
          } else {}
        } else {
          this.checkEmail()
        }
      }
    },
    submitSig (emitted) {
      this.customerSignature = emitted
      this.generateConsent()
      this.saveTrxWacom()
      this.withEmailPrompt = false
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
    },
    async saveTrxWacom () {
      if (this.transactionData.type === 'SELL') {
        if (this.currUser.location_type !== '1') {
          this.doSave()
        } else {
          const getReceipt = await this.$store.dispatch('getReceipt', {
            location_id: this.currUser.location_id,
            type: '1',
            application_id: this.currUser.application_id
          })

          if (getReceipt.code === 200) {
            this.doSave()
          } else {
            renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
          }
        }
      } else {
        this.doSave()
      }
    },
    async proceedTransaction () {
      if (this.currUser.has_wacom && !this.hasCustomerDataConsent) {
        this.eSignature()
      } else {
        this.withoutEmailPrompt = false
        this.withEmailPrompt = false
        if (this.currUser.location_type !== '1') {
          this.doSave()
        } else {
          const getReceipt = await this.$store.dispatch('getReceipt', {
            location_id: this.currUser.location_id,
            type: '1',
            application_id: this.currUser.application_id
          })

          if (getReceipt.code === 200) {
            this.doSave()
          } else {
            renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
          }
        }
      }
    },
    checkEmail () {
      if (this.transactionData.type === 'SELL') {
        if (this.currUser.location_type === '1') {
          this.sendViaEmail = true
          if (this.selectedCustomer.email_add) {
            this.withoutEmailPrompt = false
            this.withEmailPrompt = true
          } else {
            this.withoutEmailPrompt = true
            this.withEmailPrompt = false
            this.sendViaEmail = false
          }
        } else {
          this.proceedTransaction()
        }
      } else {
        if (this.currUser.has_wacom && !this.hasCustomerDataConsent) {
          this.eSignature()
        } else {
          this.doSave()
        }
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.proceedTransaction()
    },
    updateDetails (customerDetails) {
      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    async doSave () {
      let forexRate, forexAmount, pesoAmount, forexRateCategory

      if (this.transactionData.type === 'BUY') {
        forexAmount = parseFloat(this.transactionData.receiveAmount)
        pesoAmount = parseFloat(this.transactionData.releaseAmount)
        forexRate = parseFloat(this.rateData(this.transactionData.rateID).buy_am_rate)
        forexRateCategory = this.rateData(this.transactionData.rateID).category
      } else if (this.transactionData.type === 'SELL') {
        forexAmount = parseFloat(this.transactionData.releaseAmount)
        pesoAmount = parseFloat(this.transactionData.receiveAmount)
        forexRate = parseFloat(this.rateData(this.transactionData.rateID).sell_am_rate)
        forexRateCategory = this.rateData(this.transactionData.rateID).category
      } else {
        return false
      }

      const payload = {
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        trx_date: this.currUser.trx_date,
        customer_type: 'walkin',
        type: this.transactionData.type,
        currency_id: this.transactionData.currencyID,
        forex_amount: forexAmount,
        forex_rate: forexRate,
        peso_amount: pesoAmount,
        date_encoded: this.formatDateTime(new Date()),
        form_type: (this.transactionData.type === 'SELL') ? (this.currUser.location_type === '1' ? 'OR' : '') : '',
        form_number: (this.transactionData.type === 'SELL') ? (this.currUser.location_type === '1' ? this.receipt.or_series_no : '') : this.transactionData.formNumber,
        buy_back: '',
        customer_id: this.selectedCustomer.customer_id,
        customer_name: this.selectedCustomer.full_name,
        rate_category: forexRateCategory,
        mc_rate_id: this.transactionData.rateID,
        risk_score: this.selectedCustomer.risk_score,
        risk_criteria: this.selectedCustomer.risk_criteria
      }

      try {
        const req = await this.$store.dispatch('moneyChange/save', payload)

        if (req.code === 200) {
          this.$store.commit('moneyChange/SET_SUCCESS_DATA', req.result)
          this.$store.commit('moneyChange/SET_CURRENT_PAGE', 2)
          window.open(`/receipt?ref=${req.result.form_number}&trx_type=mc&type=PRE-PRINT`, '_blank')
          console.log(req.result.type === 'SELL', this.currUser.location_type === '1')
          if (req.result.type === 'SELL' && this.currUser.location_type === '1') {
            const address = this.selectedCustomer.address
            const barangay = this.selectedCustomer.barangay
            const city = this.selectedCustomer.municipalty
            const province = this.selectedCustomer.province
            const zipCode = this.selectedCustomer.zip_code
            const saveReceipt = await this.$store.dispatch('saveReceipt', {
              ereceipt_id: this.receipt.ereceipt_id,
              application_id: this.currUser.application_id,
              application_header_name: this.currUser.branchDetail.header_name,
              application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
              receipt_type: this.receipt.type,
              customer_id: this.selectedCustomer.customer_id,
              customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
              customer_tin_no: '',
              customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
              customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
              cashier_id: this.currUser.user_id,
              cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
              or_receipt_no: this.receipt.or_receipt_no,
              or_number: this.receipt.or_series_no,
              or_service: 'Pera Palit - SELL',
              or_currency: 'PHP',
              or_principal_amount: pesoAmount,
              or_vat_percent: 0,
              or_vat_service_fee: 0,
              or_vat_exempt_fee: 0,
              or_vat_zero_rated_fee: 0,
              or_vat_discount: 0,
              or_vat_amount: 0,
              or_total: pesoAmount,
              grand_total: pesoAmount,
              print_email_hold: 0,
              is_forex_logo: 0
            })

            if (saveReceipt.code === 200) {
              if (saveReceipt.result.receipt_status === 1) {
                renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
              } else if (saveReceipt.result.receipt_status === 2) {
                const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                  application_id: this.currUser.application_id,
                  ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                })
                var newBlob = new Blob([res], { type: 'application/pdf' })
                var newFileURL = URL.createObjectURL(newBlob)
                window.open(newFileURL, '_blank')
              } else {
                renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
              }
            } else {
              renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
            }
          }
        } else {
          renderToast('error', 'Transaction Failed', 'Failed to process this transaction, please reload and try again')
        }
      } catch (e) {
        console.log(e)
        renderToast('error', 'Internal Error', 'An error occurred, please report to technical team')
      }
    },
    rateData (rateID) {
      const filter = this.rates.filter(item => item.mc_rate_id === rateID)

      if (filter.length === 1) return filter[0]
      else return []
    },
    formatDateTime (date) {
      return format(date, 'yyyy-MM-dd HH:mm:ss')
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    },
    async generateConsent () {
      console.log(this.selectedCustomer)
      console.log(this.transactionData)
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: 'Pera Palit - ' + this.transactionData.type,
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    eSignature () {
      if (this.hasCustomerDataConsent) {
        this.$store.commit('wacom/SET_WACOM_DIALOG', true)
      } else {
        this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
      }
    },
    formatAmount (amount, currency) {
      if (amount) {
        return toMoney(amount, currency)
      } else {
        return ''
      }
    }
  },
  async mounted () {
    [
      'getPurposeOfTransaction',
      'getOccupation'
    ].forEach(action => this.$store.dispatch(action))
    const that = this
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    const signatures = await that.$store.dispatch('customers/retrieveSignatures', { customer_id: that.selectedCustomer.customer_id })
    that.customerSignaturesReference = signatures.result
    this.checkConsent()
  }
}
</script>

<style scoped>
#summaryOverview {
  position: sticky !important;
  top: 80px !important;
  background: #f4f4fa !important;
  border-radius: 5px !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:100%;
}
.v-stepper.ph-wu-receive-stepper .v-stepper__header {
  box-shadow: none !important;
}

.ph-wu-receive-summary .v-card {
  padding: 20px;
}

.ph-transfer-summary-header {
  font-size: 18px;
  text-align: center;
}

.ph-transfer-summary-content-title {
  font-size: 14px;
}

.transfer-info-title,
.transfer-info-value {
  border-bottom: thin dashed rgba(0,0,0,.12) !important;
}

.transfer-info-title {
  font-weight: bolder;
  padding-left: 0 !important;
}

.transfer-info-value {
  text-align: right;
  padding-right: 0 !important;
}

.ph-transfer-summary-table {
  background-color: transparent !important;
}

.ph-primary-button {
  background: #FFF;
  color: #1A2791 !important;
  /* border: 2px solid #1A2791; */
  border-radius: 10px;
  font-family: 'Proxima Nova';
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 23px;
  transition: .2s;
  height: 60px !important;
  width: 100%;
  margin: 10px 0;
}

.ph-primary-button:hover {
  background: #1A2791 !important;
  color: #fff !important;
  /* border: 2px solid #1A2791; */
}

.ph-primary-button.ph-primary-execute-btn {
  background: #1A2791;
  color: #fff !important;
  /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
  font-size: 14px;
}

.ph-primary-button.ph-primary-execute-btn:hover {
  background: #fff !important;
  color: #1A2791 !important;
  border: 2px solid #1A2791;
}

.ph-transaction-container {
  padding: 0;
}

.ph-stepper-action-buttons {
  display: flex;
  margin-bottom: 100px;
}

.ph-primary-button.ph-stepper-button {
  height: 40px !important;
  width: 115px;
}

/* @media only screen and (min-width: 600px) {
  .v-stepper.ph-wu-receive-stepper .v-stepper__step {
    padding-left: 0;
  }
} */

.ph-wu-receive-summary-minified {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #F4F4FA;
}

.ph-wu-receive-summary-content {
  display: flex;
  flex-direction: row;
  padding: 15px 30px;
}

.ph-wu-receive-summary-minified-info {
  margin-right: auto;
}

.ph-wu-receive-summary-minified-info p {
  margin-bottom: 0;
}

.ph-wu-receive-summary-minified-action {
  margin-left: auto;
}

.ph-wu-receive-form {
  padding: 12px 0;
}

@media only screen and (min-width: 960px) {
  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 0;
  }
}
</style>
