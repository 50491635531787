<template>
<div class="ph-container">
  <Header :title="header.title" :img="header.img" />
  <v-container fluid>
    <v-btn v-if="currUser.userSignature">Signature Already Uploaded!</v-btn>
    <wacom type="user-settings" v-else />
     <v-checkbox
      v-if="!currUser.has_wacom_sdk"
      v-model="hasWacomCookie"
      label="Turn Off Wacom SDK Reminder"
      @change="check($event)"
    ></v-checkbox>
  </v-container>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import wacom from '@/components/wacom/wacom'
import Header from '@/components/partials/Header.vue'
import VueCookies from 'vue-cookies'
import { renderToast } from '@/utils'
export default {
  name: 'Account',
  data () {
    return {
      hasWacomCookie: '',
      locationGroup: true,
      header: {
        title: 'Account',
        img: 'nonex-illustration'
      }
    }
  },
  mounted () {
    console.log(VueCookies.get('dont_remind_wacom'))
    var isTrueSet = (VueCookies.get('dont_remind_wacom') === 'true')
    this.hasWacomCookie = isTrueSet
    console.log(this.hasWacomCookie)
  },
  components: {
    Header,
    wacom
  },
  computed: {
    ...mapGetters({
      clientDatas: 'report/clientDatas',
      sendOutDatas: 'report/sendOutDatas',
      payOutDatas: 'report/payOutDatas',
      currUser: 'auth/currUser'
    })

  },
  methods: {
    renderToast,
    check (e) {
      console.log(e)
      if (e === true) {
        VueCookies.set('dont_remind_wacom', true, -1)
        this.renderToast('success', 'Success!', 'You will be not be reminded to download the Wacom SDK upon login')
      } else {
        VueCookies.set('dont_remind_wacom', false, -1)
        this.renderToast('success', 'Success!', 'You will be reminded to download the Wacom SDK upon login')
      }
    }
  }
}
</script>
