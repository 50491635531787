export default {
  SET_PERA_LOAN_TPA_ID (state, payload) {
    state.tpaId = payload
  },
  SET_PERA_LOAN_CURRENT_PAGE (state, payload) {
    state.peraLoanCurrentPage = payload
  },
  SET_PERA_LOAN_TRANSACTIONS (state, payload) {
    state.peraLoanTransactions = payload
  },
  SET_PERA_LOAN_RESPONSE_DATA (state, payload) {
    state.peraLoanResponseData = payload
  },
  SET_PERA_LOAN_CURRENT_TRX (state, payload) {
    state.peraLoanCurrentTrx = payload
  },
  SET_PERA_LOAN_PROVIDERS (state, payload) {
    state.collectionProviders = payload
  },
  SET_VOUCHER_DETAILS (state, payload) {
    state.voucherDetails = payload
  },
  SET_PERA_LOAN_ACCOUNT_NO (state, payload) {
    state.loanAccountNo = payload
  }
}
