import { render, staticRenderFns } from "./BillsPaymentIndex.vue?vue&type=template&id=42b8942a&scoped=true"
import script from "./BillsPaymentIndex.vue?vue&type=script&lang=js"
export * from "./BillsPaymentIndex.vue?vue&type=script&lang=js"
import style0 from "./BillsPaymentIndex.vue?vue&type=style&index=0&id=42b8942a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b8942a",
  null
  
)

export default component.exports