<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Refresh Session
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          <p>Your session will expire soon, please click <b>"Refresh Session"</b> to continue.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="ph-dialog-actions">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="ph-action-button"
            text
            @click="logout('byButton')"
          >
            Logout
            &nbsp;
            <v-progress-circular
              :rotate="360"
              :value="progressValue"
              color="primary"
            >
              {{ countdown }}
            </v-progress-circular>
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button"
            @click="refreshToken"
          >
            Refresh Session
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { renderToast, aesEncrypt } from '@/utils'
import wacomMixin from '@/mixins/wacom.js'

export default {
  name: 'TokenReminder',
  data () {
    return {
      dialog: false,
      interval: {},
      countdown: 60,
      progressValue: 0
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      showRefreshPrompt: 'auth/showRefreshPrompt',
      authTimestamp: 'auth/authTimestamp'
    })
  },
  watch: {
    currUser: {
      handler () {
      },
      deep: true
    },
    showRefreshPrompt: {
      handler (value) {
        console.log('dialog value:' + value)
        this.dialog = value

        if (this.dialog === true && this.$route.name !== 'Login') {
          this.startPromptCountdown()
        }
      },
      deep: true
    },
    countdown: {
      handler (count) {
        if (count <= 0) {
          const that = this
          this.$store.commit('auth/SET_SHOW_REFRESH', false)
          this.$store.commit('app/TOGGLE_LOADING', true)

          setTimeout(function () {
            that.progressValue = 0
            clearInterval(that.interval)

            that.$cookies.remove('USP_DATA', '/', that.$uspBaseDomain)
            that.$cookies.remove('USP_TOKEN', '/', that.$uspBaseDomain)
            that.$cookies.remove('USP_MODULES', '/', that.$uspBaseDomain)
            that.$cookies.remove('USP_AUTH_TIMESTAMP', '/', that.$uspBaseDomain)

            that.$store.commit('auth/SET_IS_OTP_PASSED', false)
            that.$store.commit('auth/SET_RUN_SESSION', false)
            that.$store.commit('auth/SET_SHOW_REFRESH', false)
            that.$store.commit('auth/SET_CURRENT_USER', {})
            that.$store.commit('auth/AUTH_SUCCESS', false)
            that.$store.commit('app/TOGGLE_LOADING', false)
            window.location.href = `${that.$uspAuthAppURL}/login`
            localStorage.setItem('logout_message', 'Your session has expired. Please login again to continue.')
          }, 1000)
        }
      }
    }
  },
  mounted () {
    this.dialog = this.showRefreshPrompt
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)

    if (this.dialog && this.$route.name !== 'Login') {
      this.startPromptCountdown()
    }
  },
  methods: {
    renderToast,
    startPromptCountdown () {
      const currentTime = new Date()
      const loggedInTime = new Date(this.authTimestamp.timeLoggedIn)
      const actualExpireTime = new Date(loggedInTime.getTime() + (23 * 60 * 1000))

      if (currentTime.getTime() > actualExpireTime.getTime()) {
        this.logout('byTokenExpiry')
      } else {
        this.interval = setInterval(() => {
          if (this.progressValue === 99.99 && this.countdown === 0) {
            return (this.progressValue = 0)
          }
          this.progressValue += 1.66666666667
          this.countdown -= 1
        }, 1000)
      }
    },
    async refreshToken () {
      clearInterval(this.interval)
      this.$store.commit('auth/SET_SHOW_REFRESH', false)
      this.$store.commit('app/TOGGLE_LOADING', true)

      this.$store.dispatch('auth/doRefreshToken', this.currUser.token).then(res => {
        this.$store.commit('app/TOGGLE_LOADING', false)
        if (res.result) {
          const currentUserData = this.currUser
          const updateUserData = currentUserData
          updateUserData.token = res.result.token
          this.$store.commit('auth/SET_CURRENT_USER', updateUserData)

          const currentTime = new Date()
          const expireTime = new Date(currentTime.getTime() + (23 * 60 * 1000))

          this.$store.commit('auth/SET_AUTH_TIMESTAMP', {
            timeLoggedIn: currentTime,
            timeToExpire: expireTime
          })

          const userData = { ...currentUserData }
          const userModules = userData.modules
          const userToken = userData.token
          const userAuthTimeStamp = this.authTimestamp

          delete userData.modules
          delete userData.token

          this.$cookies.remove('USP_DATA', '/', this.$uspBaseDomain)
          this.$cookies.remove('USP_TOKEN', '/', this.$uspBaseDomain)
          this.$cookies.remove('USP_MODULES', '/', this.$uspBaseDomain)
          this.$cookies.remove('USP_AUTH_TIMESTAMP', '/', this.$uspBaseDomain)

          this.$cookies.config((60 * 20), '/', this.$uspBaseDomain, (process.env.NODE_ENV === 'production'))

          this.$cookies.set('USP_DATA', aesEncrypt(JSON.stringify(userData)))
          this.$cookies.set('USP_TOKEN', aesEncrypt(JSON.stringify(userToken)))
          this.$cookies.set('USP_MODULES', aesEncrypt(JSON.stringify(userModules)))
          this.$cookies.set('USP_AUTH_TIMESTAMP', aesEncrypt(JSON.stringify(userAuthTimeStamp)))

          this.renderToast('info', 'Session Refreshed', 'The Session has been renewed successfully.')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async logout (type) {
      const that = this
      const payload = {
        username: this.currUser.user_id,
        ip_address: this.currUser.ip_address
      }

      this.$store.commit('auth/SET_IS_OTP_PASSED', false)
      this.$store.commit('auth/SET_SHOW_REFRESH', false)
      this.$store.commit('app/TOGGLE_LOADING', true)
      this.$store.commit('auth/SET_RUN_SESSION', false)

      await this.$store.dispatch('auth/doLogout', payload).then(res => {
        that.$store.commit('auth/SET_CURRENT_USER', {})
        that.$store.commit('auth/AUTH_SUCCESS', false)
        that.$store.commit('app/TOGGLE_LOADING', false)

        if (type === 'byTokenExpiry') {
          localStorage.setItem('logout_message', 'Your session has expired. Please login again to continue.')
        } else {
          localStorage.setItem('logout_message', 'You are now logged-out.')
        }
        location.href = `${that.$uspAuthAppURL}/login`
      }).catch(() => {
        that.$store.commit('auth/SET_CURRENT_USER', {})
        that.$store.commit('auth/AUTH_SUCCESS', false)
        that.$store.commit('app/TOGGLE_LOADING', false)
        that.renderToast('error', 'Error logging-out', 'An error has occured, please try again.')
        window.location.href = `${that.$uspAuthAppURL}/login`
      })
    }
  }
}
</script>
<style scoped>
@media only screen and (max-width: 599px) {
  .ph-dialog-actions {
    display: flex;
    flex-direction: column;
  }

  .ph-dialog-actions .ph-action-button {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 10px;
  }
}
</style>
