export default {
  SET_CURRENT_PAGE (state, payload) {
    state.currentPage = payload
  },
  SET_PRODUCTS (state, payload) {
    state.products = payload
  },
  SET_SUCCESS_DATA (state, payload) {
    state.successData = payload
  },
  SET_TRANSACTION_DATA (state, newData) {
    state.transactionData = {
      ...state.transactionData,
      ...newData
    }
  },
  SET_ALERT (state, newData) {
    state.alert = {
      ...state.alert,
      ...newData
    }
  }
}
