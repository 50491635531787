import { endpoints } from '@/api/endpoints'

const loans = endpoints.use('loans')

export default {
  getTPARequest: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.getTPARequest(payload)
      .then(({ data }) => {
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getCollectionTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.getCollectionTrx(payload)
      .then(({ data }) => {
        commit('SET_LOAN_COLLECTION_TRANSACTIONS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getLoanProvider: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.getLoanProvider(payload)
      .then(({ data }) => {
        commit('SET_LOAN_COLLECTION_PROVIDERS', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getOtherProvider: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.getOtherProvider(payload)
      .then(({ data }) => {
        commit('SET_LOAN_COLLECTION_PROVIDERS', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  executeCollectionTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.executeCollectionTrx(payload)
      .then(({ data }) => {
        commit('SET_LOAN_COLLECTION_RESPONSE_DATA', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  loanAccountLookup: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.loanAccountLookup(payload)
      .then(({ data }) => {
        commit('SET_LOAN_ACCOUNT_NO', payload.account_number)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  generateLoanCollectionVoucher: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.generateLoanCollectionVoucher(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
