import { render, staticRenderFns } from "./BillsCollectionForm.vue?vue&type=template&id=24e16222&scoped=true"
import script from "./BillsCollectionForm.vue?vue&type=script&lang=js"
export * from "./BillsCollectionForm.vue?vue&type=script&lang=js"
import style0 from "./BillsCollectionForm.vue?vue&type=style&index=0&id=24e16222&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24e16222",
  null
  
)

export default component.exports