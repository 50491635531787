<template>
  <v-form ref="form">
    <v-row class="px-8 py-8" no-gutters>
      <v-col class="pb-4 pb-sm-6" :cols="12">
        <span class="text-caption">
          Please complete the necessary fields*.
        </span>
      </v-col>
      <v-col class="pb-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.first_name"
          class="ph-textbox"
          color="gray darken-1"
          label="First Name *"
          :rules="requiredField"
          :disabled="isUpdating"
          @input="convertToProperCase('first_name')"
          autofocus
          outlined
        />
      </v-col>
      <v-col class="pb-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.middle_name"
          class="ph-textbox mb-6"
          color="gray darken-1"
          label="Middle Name *"
          :disabled="isUpdating || tag_no_middlename"
          :rules="middleNameRules"
          @input="convertToProperCase('middle_name')"
          outlined
        />
        <div class="d-flex flex-row justify-end">
          <v-checkbox
            v-model="tag_no_middlename"
            @change="toggleNoMiddleName"
            class="my-0 py-0"
            :ripple="false"
            label="No Middle Name"
            on-icon="mdi-check-circle"
            off-icon="mdi-checkbox-blank-circle-outline"
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pb-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.last_name"
          class="ph-textbox"
          color="gray darken-1"
          label="Last Name *"
          :disabled="isUpdating"
          :rules="requiredField"
          @input="convertToProperCase('last_name')"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.birth_date"
          @click:append="openDatePicker"
          @input="syncDate"
          class="ph-textbox"
          label="Birth Date *"
          :disabled="isUpdating"
          append-icon="mdi-calendar"
          :rules="birthDateRules"
          hint="Type or pick a date by clicking the calendar icon"
          outlined
        />
        <v-dialog v-model="birthDatePicker" width="300">
          <v-date-picker
            v-model="tempDate"
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            min="1900-01-01"
            @input="birthDatePicker = false"
          />
        </v-dialog>
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.country_birth"
          :items="collections.countries"
          item-text="country"
          item-value="country"
          :return-object="false"
          label="Country of Birth *"
          class="ph-textbox"
          @change="resetBirthPlace"
          :rules="requiredField"
          outlined
        />
        <v-checkbox class="ml-12 my-0 py-0 pt-2" hide-details style="visibility: hidden" />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.birth_place"
          :items="birthPlaces"
          item-text="text"
          item-value="text"
          :return-object="false"
          label="Birth Place *"
          class="ph-textbox"
          :rules="requiredField"
          v-if="formData.country_birth === 'Philippines'"
          outlined
        />
        <v-text-field
          v-model="formData.birth_place"
          class="ph-textbox"
          color="gray darken-1"
          label="Birth Place *"
          :rules="requiredField"
          @input="convertToProperCase('birth_place')"
          outlined
          v-else
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.gender"
          :items="collections.gender"
          item-text="text"
          item-value="value"
          :return-object="false"
          label="Gender *"
          class="ph-textbox"
          :rules="requiredField"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.civil_status"
          :items="collections.civilStatus"
          label="Civil Status *"
          color="gray darken-1"
          class="ph-textbox"
          :rules="requiredField"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-autocomplete
          v-model="formData.nationality"
          :items="collections.countries"
          item-text="nationality"
          item-value="nationality"
          class="ph-textbox mb-4 mb-md-6 ml-md-1"
          label="Nationality"
          :rules="requiredField"
          outlined
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { trim, toProperCase } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      formData: 'customers/formData',
      collections: 'collections'
    }),
    tag_no_middlename: {
      get () {
        return (this.formData.tag_no_middlename === '1' || this.formData.tag_no_middlename === 1)
      },
      set (val) {
        const tag = (val) ? '1' : '0'
        this.formData.tag_no_middlename = tag
      }
    },
    birthPlaces () {
      return this.collections.cities.map(item => {
        return {
          city: item.city,
          province: item.province,
          text: `${item.city} , ${item.province}`
        }
      })
    }
  },
  data () {
    return {
      isUpdating: false,
      tempDate: null,
      birthDatePicker: false,
      requiredField: [
        v => !!v || 'This field is required'
      ],
      middleNameRules: [
        v => ((!!v && !this.tag_no_middlename) || (!v && this.tag_no_middlename)) || 'This field is required'
      ],
      birthDateRules: [
        v => !!v || 'This field is required',
        v => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v) || 'Please use YYYY-MM-DD format',
        v => /^(19|2[0-9])/g.test(v) || 'Please select a valid year'
      ]
    }
  },
  watch: {
    tempDate (val) {
      this.formData.birth_date = val
    }
  },
  methods: {
    syncDate (val) {
      if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(val)) {
        this.tempDate = val
      }
    },
    openDatePicker () {
      this.birthDatePicker = true
    },
    convertToProperCase (field) {
      if (this.formData[field]) {
        this.formData[field] = toProperCase(trim(this.formData[field]))
      }
    },
    toggleNoMiddleName () {
      this.formData.middle_name = ''
      this.$refs.form.resetValidation()
    },
    resetBirthPlace () {
      this.formData.birth_place = ''
    }
  },
  mounted () {
    if (Object.prototype.hasOwnProperty.call(this.$route.params, 'crn')) {
      this.isUpdating = true
    } else {
      this.isUpdating = false
    }
  }
}
</script>
