<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row v-if="approverLevel === 'ADMIN' || approverLevel === 'FINANCE' || approverLevel === 'NETOPS'" class="px-2 pt-md-2 px-md-5 mb-md-12">
      <v-col :cols="12">
        <v-tabs v-model="currentView" class="noBottomLine">
          <v-tab class="font-weight-bold text-subtitle-1 text-capitalize tabText">
            Location
            <v-chip
              v-if="pendingLocationRequests > 0"
              class="ml-1 font-weight-regular tabChipText"
              color="red"
              text-color="white"
              small
            >
              <span v-if="pendingLocationRequests < 100">{{ pendingLocationRequests }}</span>
              <span v-else>99+</span>
            </v-chip>
          </v-tab>
          <v-tab class="font-weight-bold text-subtitle-1 text-capitalize tabText">
            Customer
            <v-chip
              v-if="pendingCustomerRequests > 0"
              class="ml-1 font-weight-regular tabChipText"
              color="red"
              text-color="white"
              small
            >
              <span v-if="pendingCustomerRequests < 100">{{ pendingCustomerRequests }}</span>
              <span v-else>99+</span>
            </v-chip>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col class="pt-4" :cols="12">
        <v-tabs-items v-model="currentView" class="py-2">
          <v-tab-item>
            <LocationRequests />
          </v-tab-item>
          <v-tab-item>
            <CustomerRequests />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'
import LocationRequests from '@/views/TransactionLimit/LimitApproval/LocationRequests.vue'
import CustomerRequests from '@/views/TransactionLimit/LimitApproval/CustomerRequests.vue'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      approverLevel: 'transactionLimit/approverLevel',
      approvalLocationRequests: 'transactionLimit/approvalLocationRequests',
      approvalCustomerRequests: 'transactionLimit/approvalCustomerRequests'
    }),
    pendingLocationRequests () {
      if (this.approverLevel === 'FINANCE' || this.approverLevel === 'ADMIN') return this.approvalLocationRequests.filter(item => (item.status === 1 || item.status === 4 || item.status === 5)).length
      if (this.approverLevel === 'NETOPS') return this.approvalLocationRequests.filter(item => item.status === 2).length
      return 0
    },
    pendingCustomerRequests () {
      if (this.approverLevel === 'FINANCE' || this.approverLevel === 'ADMIN') return this.approvalCustomerRequests.filter(item => item.status === 1 || item.status === 4 || item.status === 5).length
      if (this.approverLevel === 'NETOPS') return this.approvalCustomerRequests.filter(item => item.status === 2).length
      return 0
    },
    currentView: {
      get () {
        return this.$store.state.transactionLimit.currentApprovalRequestsView
      },
      set (val) {
        this.$store.commit('transactionLimit/SET_CURRENT_APPROVAL_REQUESTS_VIEW', val)
      }
    }
  },
  components: {
    LocationRequests,
    CustomerRequests
  },
  data () {
    return {
      data: [],
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Limit Approval',
          disabled: true
        }
      ]
    }
  },
  methods: {
    async getLocReq () {
      const { code, result } = await this.$store.dispatch('transactionLimit/getApprovalLocationRequests')

      if (code === 200) {
        this.$store.commit('transactionLimit/SET_APPROVAL_LOCATION_REQUESTS', result.sort((x, y) => {
          return (new Date(y.created_at).getTime() - new Date(x.created_at).getTime())
        }))
      }
    },
    async getCustReq () {
      const { code, result } = await this.$store.dispatch('transactionLimit/getApprovalCustomerRequests')

      if (code === 200) {
        this.$store.commit('transactionLimit/SET_APPROVAL_CUSTOMER_REQUESTS', result.sort((x, y) => {
          return (new Date(y.created_at).getTime() - new Date(x.created_at).getTime())
        }))
      }
    }
  },
  async mounted () {
    const { code, result } = await this.$store.dispatch('transactionLimit/getApprover', this.currUser.user_id)

    if (code === 200) {
      if (result.length > 0) {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', result[0].approver_level)
      } else {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', '')
      }
    }

    if (this.approverLevel !== 'ADMIN' && this.approverLevel !== 'FINANCE' && this.approverLevel !== 'NETOPS') {
      renderToast('error', 'No access', 'You don\'t have access to this module')
    }

    this.getLocReq()
    this.getCustReq()
  }
}
</script>

<style lang="css" scoped>
.noBottomLine {
  border-bottom: none !important;
}

.tabText {
  font-family: 'Baloo Extra Bold' !important;
  /* min-width: 150px !important; */
  margin-right: 10px !important;
}

.tabChipText {
  font-family: Roboto, Arial, Helvetica, sans-serif !important;
}
</style>
