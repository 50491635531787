import api from '@/api'

export default {
  getCardTransactions (payload) {
    return api.get(`/v1/transactions/api/trx/debitcard?trx_date=${payload.trx_date}&location_id=${payload.location_id}`)
  },
  registerPHVCard (payload) {
    return api.post('/v1/banks/eon/api/eon/register/card', payload)
  },
  getCardStatus (payload) {
    return api.get(`/v1/maintenance/api/lookup-card?card_number=${payload}`)
  },
  getEONSourceOfFund () {
    return api.get('/v1/maintenance/api/collections/eon-source-of-fund')
  },
  getEONEmployment () {
    return api.get('/v1/maintenance/api/collections/eon-employment')
  },
  getEONNatureWork () {
    return api.get('/v1/maintenance/api/collections/eon-nature-of-work')
  },
  getEONIdList () {
    return api.get('/v1/maintenance/api/collections/eon-id-list')
  },
  getCardInventory (payload) {
    return api.get(`/v1/maintenance/api/cards?location_id=${payload.location_id}&card_category=${payload.card_category}&current_status=${payload.current_status}`)
  },
  updateCardInventory (payload) {
    return api.put(`/v1/maintenance/api/cards/${payload.id}`, payload.data)
  },
  requestCardEmail (payload) {
    return api.post('/v1/maintenance/api/request-cards', payload)
  },
  inquireCardNumber (payload) {
    return api.post('/v1/maintenance/api/card-inquiry', payload)
  },
  savePendingVirtualCard (payload) {
    return api.post('/v1/transactions/api/trx/virtualcard', payload)
  },
  updatePendingVirtualCard (id, payload) {
    return api.put(`/v1/transactions/api/trx/virtualcard/${id}`, payload)
  },
  savePendingDebitCard (payload) {
    return api.post('/v1/transactions/api/trx/debitcard', payload)
  },
  updatePendingDebitCard (id, payload) {
    return api.put(`/v1/transactions/api/trx/debitcard/${id}`, payload)
  }
}
