export default {
  SET_SEARCH_RESULT (state, payload) {
    state.searchData.results = payload
  },

  SET_REQUESTS (state, payload) {
    state.requests = payload
  },

  SET_RECENTLY_ADDED (state, payload) {
    state.recentlyAdded = payload
  },

  SET_SELECTED_CUSTOMER (state, payload) {
    state.selectedCustomer = payload
  },

  SET_OLD_MOBILE_NUMBER (state, payload) {
    state.oldMobileNumber = payload
  },

  SET_POLICY_LIST (state, payload) {
    state.policyList = payload
  },

  SET_FORM_DATA: (state, payload) => {
    state.formData = payload
  },

  SET_FORM_DATA_VALUE: (state, payload) => {
    state.formData = {
      ...state.formData,
      payload
    }
  },

  SET_CURRENT_VIEW: (state, payload) => {
    state.currentView = payload
  },

  SET_CUSTOMER_SIGNATURE: (state, payload) => {
    state.formData.customer_signatures = [...state.formData.customer_signatures, payload]
  },

  SET_CUSTOMER_CREATION_MODAL: (state, payload) => {
    state.showCustomerCreationModal = payload
  },
  SET_SELECTED_MOBILE_USER (state, payload) {
    state.selectedMobileUser = payload
  }
}
