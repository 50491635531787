import api from '@/api'

export default {
  grid (trxDate, locationID) {
    return api.get(`/v1/transactions/api/trx/trxair?trx_date=${trxDate}&location_id=${locationID}`)
  },
  saveAirAsia (payload) {
    return api.post('/v1/transactions/api/trx/pera-dali', payload)
  },
  saveCebuPacific (payload) {
    return api.post('/v1/transactions/api/trx/trxair', payload)
  },
  avpServiceFee (locationID) {
    return api.get(`/v1/maintenance/api/avp-sf?location_id=${locationID}&service_id=31`)
  }
}
