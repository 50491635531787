<template>
  <v-card
    @dragover.prevent="dragOverHandler"
    @dragleave.prevent="dragLeaveHandler"
    @drop.prevent="dropHandler"
    @click="chooseFile"
    id="dropZone"
    class="rounded-lg"
    style="min-height: 140px;"
    outlined
  >
    <v-row class="d-flex flex-column align-center pt-4 pb-6" no-gutters>
      <v-col class="d-flex justify-center align-center" :cols="3">
        <img src="@/assets/images/compliance/upload.svg" alt="">
      </v-col>
      <v-col class="pt-2 text-center" :cols="8">
        <span v-if="isDragging">
          <strong>DROP HERE</strong>
        </span>
        <span v-else>
          <strong class="primary--text">Click to upload</strong> or drag and drop {{ guideText }}
        </span>
      </v-col>
      <v-col class="d-flex justify-center pt-5" :cols="3">
        <v-btn
          v-if="hasImportButton"
          color="primary"
          elevation="0"
          large
        >
          Import
        </v-btn>
      </v-col>
    </v-row>
    <input
      @change.prevent="fileSelectHandler"
      ref="fileUpload"
      type="file"
      style="display: none;"
      :accept="accept"
      :multiple="isMultiple"
    />
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      files: [],
      isDragging: false
    }
  },
  props: {
    guideText: {
      type: String,
      default: 'file/s'
    },
    accept: {
      type: String,
      default: null
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    isAutoUpload: {
      type: Boolean,
      default: false
    },
    hasImportButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    chooseFile () {
      this.$refs.fileUpload.click()
    },
    dragOverHandler (e) {
      this.isDragging = true
    },
    dragLeaveHandler (e) {
      if (e.relatedTarget.offsetParent.id !== 'dropZone') {
        this.isDragging = false
      }
    },
    dropHandler (e) {
      if (e.dataTransfer.items) {
        [...e.dataTransfer.items].forEach((item) => {
          if (item.kind === 'file') {
            const file = item.getAsFile()

            if (this.isMultiple) {
              this.files.push(file)
            } else {
              this.files = [file]
            }
          }
        })
        this.$emit('onFileChange', this.files)
      }
      this.isDragging = false
    },
    fileSelectHandler ({ target: { files } }) {
      this.files = files
      this.$emit('onFileChange', this.files)
    }
  }
}
</script>
