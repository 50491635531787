<template>
  <v-dialog
    v-model="highRiskDialog.isShow"
    width="40%"
    persistent
  >
    <v-card class="px-2 py-3 pb-6">
      <v-card-title class="primary--text">
        <h2 class="title ph-title" style="font-size: 24px !important;">
          Compliance Notification
        </h2>
      </v-card-title>
      <v-card-text class="pt-6" style="font-size: 15px !important;">
        <v-row>
          <v-col :cols="12" v-if="highRiskDialog.type === 'FOUND_HIGH_RISK_GO'">
            <v-row>
              <v-col class="pb-6" :cols="12">
                Please advice the client to submit the following document on their next transaction.
                <br><br>
                For compliance process and procedure, click <a class="font-weight-bold" href="#" onclick='window.open("/comp/high_go.pdf", "blank", "fullscreen=yes"); return false;'>HERE</a>
                <br><br>
                For immediate concern, please send an email to <a class="font-weight-bold" :href="`mailto:asley.fontanoza@petnet.com.ph?subject=High Risk Go! - ${currUser.location_name}`">Compliance Team</a> or call 892-0971 or 812-9208 loc. 232.
              </v-col>
              <v-col :cols="6">
                <strong>
                  <span class="red--text">Documents</span>
                  <br>
                  Enhanced Due Diligence (EDD)
                </strong>
              </v-col>
              <v-col :cols="6">
                <strong>
                  <span class="red--text">Reasons</span>
                  <br>
                  {{ (highRiskDialog.details.reasons.length > 0) ? highRiskDialog.details.reasons : '—' }}
                </strong>
              </v-col>
            </v-row>
            <br>
          </v-col>
          <v-col :cols="12" v-if="highRiskDialog.type === 'FOUND_HIGH_RISK_STOP'">
            <v-row>
              <v-col :cols="12">
                <v-chip class="red--text px-5 py-5" color="rgba(255, 3, 3, 0.2)" style="font-weight: 600 !important;border-radius: 25px !important;">
                  Customer is High Risk
                </v-chip>
              </v-col>
              <v-col class="pb-6" :cols="12">
                Transaction cannot proceed. Please contact CSC/Compliance for further information.
                <br><br>
                Customer needs to submit Enhanced Due Diligence (EDD) for and other supporting documents before proceeding with the transaction.
                <br><br>
                For compliance process and procedure, click <a class="font-weight-bold" href="#" onclick='window.open("/comp/high_stop.pdf", "blank", "fullscreen=yes"); return false;'>HERE</a>
                <br><br>
                For immediate concern, please send an email to <a class="font-weight-bold" :href="`mailto:asley.fontanoza@petnet.com.ph?subject=High Risk Go! - ${currUser.location_name}`">Compliance Team</a> or call 892-0971 or 812-9208 loc. 232.
              </v-col>
              <v-col :cols="6">
                <strong>
                  <span class="red--text">Reasons</span>
                  <br>
                  {{ (highRiskDialog.details.reasons.length > 0) ? highRiskDialog.details.reasons : '—' }}
                </strong>
              </v-col>
              <v-col :cols="6">
                <strong>
                  <span class="red--text">EDD Status</span>
                  <br>
                  {{ highRiskDialog.details.status || '—'}}
                </strong>
              </v-col>
              <v-col :cols="6">
                <strong>
                  <span class="red--text">Remarks</span>
                  <br>
                  {{ highRiskDialog.details.remarks || '—' }}
                </strong>
              </v-col>
            </v-row>
            <br>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-8">
        <v-spacer />
        <v-btn
          @click="ok"
          color="primary"
          elevation="0"
          class="ph-action-button px-4 "
          x-large
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections',
      highRiskDialog: 'compliance/highRiskDialog',
      riskData: 'compliance/riskData'
    })
  },
  methods: {
    async ok () {
      if (!Object.prototype.hasOwnProperty.call(this.riskData, 'riskProfiling')) {
        const trx = { domInt: '', type: '', serviceId: '' }

        if (this.$route.fullPath.includes('pera-remit/send')) {
          trx.domInt = 'DMT'
          trx.type = 'WU Sendout'
          trx.serviceId = '1'
        }

        if (this.$route.fullPath.includes('pera-remit/receive')) {
          trx.domInt = 'IMT'
          trx.type = 'WU Receive'
          trx.serviceId = '1'
        }

        if (this.$route.fullPath.includes('pera-palit')) {
          trx.domInt = 'MC'
          trx.type = 'MC'
          trx.serviceId = '2'
        }

        if (this.$route.fullPath.includes('pera-cico/cash-in')) {
          trx.domInt = 'CICO'
          trx.type = 'CICO CashIN'
          trx.serviceId = '3'
        }

        if (this.$route.fullPath.includes('pera-cico/cash-out')) {
          trx.domInt = 'CICO'
          trx.type = 'CICO CashOUT'
          trx.serviceId = '3'
        }

        await this.$store.dispatch('compliance/saveRisk', {
          trans_details: {
            dom_int: trx.domInt,
            trx_type: trx.type,
            trx_date: this.currUser.trx_date,
            customer_name: this.highRiskDialog.customer.full_name,
            risk_score: this.riskData?.riskProfiling?.risk_score || 4,
            curr_risk_score: this.highRiskDialog.customer.risk_score,
            risk_criteria: this.highRiskDialog.customer.risk_criteria || '[]',
            customer_id: this.highRiskDialog.customer.customer_id,
            monitoring_type: '1',
            principal_amount: ''
          },
          location_name: this.currUser.location_name,
          location_id: this.currUser.location_id,
          user_id: this.currUser.user_id,
          service_id: trx.serviceId,
          update_status_by: this.currUser.user_id,
          first_name: this.highRiskDialog.customer.first_name,
          last_name: this.highRiskDialog.customer.last_name,
          compliance_remarks: this.highRiskDialog.customer.compliance_edd_remarks,
          fp_remarks: '',
          false_positive: '',
          edd_alert: 'true',
          _is_not_fraud: '0',
          criteria: this.highRiskDialog.customer.risk_criteria || '[]',
          reference_no: '',
          actual_date_trx: this.currUser.trx_date
        })
      }

      if (this.highRiskDialog.type === 'FOUND_HIGH_RISK_STOP') this.$store.dispatch('compliance/resetHighRiskDialog')
      if (this.highRiskDialog.type === 'FOUND_HIGH_RISK_GO') this.$store.dispatch('compliance/resetHighRiskDialog')
    }
  }
}
</script>
