import api from '@/api'

export default {
  search (keyword) {
    return api.get(`/v1/maintenance/api/customer-search?name=${keyword}`)
  },
  save (details) {
    return api.post('/v1/maintenance/api/customer-save', details)
  },
  update (details) {
    return api.post('/v1/maintenance/api/customer-update', details)
  },
  delete (customerID, userID) {
    return api.delete(`/v1/maintenance/api/customer/${customerID}?user_id=${userID}`)
  },
  transactionLogs (customerID, yearNo = 5) {
    return api.get(`/v1/maintenance/api/customer-trx-log?customer_id=${customerID}&year_no=${yearNo}`)
  },
  transactionCount (customerID, yearNo = 5) {
    return api.get(`/v1/maintenance/api/customer-trx-cnt-log?customer_id=${customerID}&year_no=${yearNo}`)
  },
  recentlyAdded (locationCode) {
    return api.get(`/v1/maintenance/api/customer-recently-added?branchcode=${locationCode}`)
  },
  dynamicFields (module) {
    return api.get(`/v1/maintenance/api/customer-module-field?module=${module}`)
  },
  generateACR (payload) {
    return api.post('/v1/maintenance/api/customer-acr-nonex', payload)
  },
  generateACRMoneygram (payload) {
    return api.post('/v1/maintenance/api/customer-acr-moneygram', payload)
  },
  retrieveSignatures (payload) {
    return api.post('/v1/maintenance/api/customer-signature-search', payload)
  },
  newRequests (branchCode) {
    return api.get(`/v1/maintenance/api/staging/customer?branchcode=${branchCode}`)
  },
  deleteNewRequest (id) {
    return api.delete(`/v1/maintenance/api/staging/customer/${id}`)
  },
  clientPolicyList (firstName, lastName, birthDate) {
    return api.get(`/v1/transactions/api/trx/insurance/get-policy-list?first_name=${firstName}&last_name=${lastName}&birthdate=${birthDate}`)
  },
  checkIfDuplicate (mobileNo) {
    return api.get(`/v1/maintenance/api/customer-contacts?search_by=mobile&value=${mobileNo}`)
  }
}
