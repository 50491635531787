<template>
  <v-row class="ph-wu-success-page-container">
    <v-col cols="12">
      <h1 class="ph-wu-form-title">Success!</h1>
      <p class="ph-wu-form-subtitle">Thank you. Payment transaction has been completed.</p>
    </v-col>
    <v-col cols="12" md="8">
      <p class="ph-wu-success-receiver-name"><b>Summary Overview:</b></p>
      <v-row class="ph-wu-success-details">
        <v-col cols="12" sm="4">
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in billsPaymentOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        elevation="0"
        class="ph-primary-button ph-primary-execute-btn"
        @click="backToBillsPayment"
      >
        Back to Bills Payment
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { formatMTCN, renderAVPSpiel, getAge } from '@/utils'
import numeral from 'numeral'

export default {
  name: 'BillsPaymentSuccess',
  data () {
    return {
      receiverName: '',
      loyaltyCardNumber: '',
      loyaltyPoints: '',
      mtcn: '',
      crossSellingData: {},
      billsPaymentOverview: [
        { name: 'Transaction Type', value: '-' },
        { name: 'Payment Provider', value: '-' },
        { name: 'Biller', value: '-' },
        { name: 'Reference Number', value: '-' },
        { name: 'Total Amount', value: '-' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      billsPaymentCurrentTrx: 'bills/billsPaymentCurrentTrx',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser',
      tpaId: 'bills/tpaId',
      billsPaymentResponseData: 'bills/billsPaymentResponseData'
    })
  },
  async mounted () {
    if (this.selectedCustomer) {
      this.receiverName = `${this.selectedCustomer.first_name} ${this.selectedCustomer.last_name}`
      const trxType = 'Payment'

      // const payload = {
      //   product: 'cash-in',
      //   amount: currentTrx.principal_amount,
      //   gender: this.selectedCustomer.gender,
      //   age: this.getAge(this.selectedCustomer.birth_date),
      //   type: this.selectedCustomer.civil_status
      // }

      // await this.$store.dispatch('getCrossSellingSpiel', payload).then(res => {
      //   this.crossSellingData = res.result[0]
      //   this.renderAVPSpiel(this.crossSellingData)
      // }).catch(err => {
      //   console.log(err)
      // })

      this.billsPaymentOverview = [
        { name: 'Transaction Type', value: trxType || '-' },
        { name: 'Payment Provider', value: this.billsPaymentCurrentTrx.bill_id === 2 ? 'Bayad Center' : '-' },
        { name: 'Biller', value: this.billsPaymentCurrentTrx.biller_name ? this.billsPaymentCurrentTrx.biller_name : '-' },
        { name: 'Reference Number', value: this.billsPaymentCurrentTrx.reference_number ? this.billsPaymentCurrentTrx.reference_number : '-' },
        { name: 'Total Amount', value: this.billsPaymentCurrentTrx.total_amount ? this.formatAmount(this.billsPaymentCurrentTrx.total_amount) : '-' }
      ]

      this.printBillsReceipt(this.tpaId, this.billsPaymentCurrentTrx)
    }
  },
  methods: {
    formatMTCN,
    renderAVPSpiel,
    getAge,
    backToBillsPayment () {
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
      this.$store.commit('bills/SET_BILLS_PAYMENT_CURRENT_PAGE', null)
      this.$store.commit('bills/SET_BILLS_PAYMENT_RECEIPT_DATA', {})
      this.$store.commit('bills/SET_BILLS_TPA_ID', '')
      this.$store.commit('bills/SET_BILLS_PAYMENT_RESPONSE_DATA', null)

      window.location.href = '/pera-bills/payments'
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    async printBillsReceipt (tpaId, item) {
      const transactionRefNo = `TR#${this.billsPaymentResponseData.result.transactionId} ${this.currUser.user_name}/${tpaId}`
      const transactionTimestamp = `${format(new Date(this.billsPaymentResponseData.result.createdAt), 'yyyy-MM-dd HH:mm:SS')}`
      const getAPIRequest = item.api_request

      const data = {
        billerName: getAPIRequest.biller_name,
        transactionRefNo: transactionRefNo,
        transactionTimestamp: transactionTimestamp,
        referenceNo: item.reference_number,
        paymentMethod: item.payment_type === 0 ? 'CASH' : '',
        amount: this.formatAmount(this.billsPaymentCurrentTrx.total_amount),
        trxId: item.transaction_id,
        tpaId: tpaId
      }

      this.$store.commit('bills/SET_BILLS_PAYMENT_RECEIPT_DATA', data)

      setTimeout(function () {
        window.open('/pera-bills/receipt', '_blank')
      }, 2500)
    }
  }
}
</script>
<style scoped>
  .ph-wu-form-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-wu-success-detail {
    border: 1px solid #CCC !important;
    border-radius: 10px !important;
    display: flex;
    padding: 10px 18px;
  }

  .ph-wu-success-detail-text {
    margin-left: 10px;
    margin-top: 4px;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    border: 2px solid #1A2791;
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    border: 2px solid #fff;
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    font-size: 14px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-action-button {
    margin-right: 10px;
  }

  @media only screen and (min-width: 600px) {
    .ph-primary-button {
      width: 250px;
      margin-left: auto;
    }
  }
</style>
