<template>
  <div class="payment-receipt-container">
    <div class="btn-container">
        <div id="btn-receipt" class="div-button" @click="finalPrint()">
            <v-btn class="ph-primary-button ph-stepper-button ph-primary-execute-btn" outlined text light>Print Receipt</v-btn>
        </div>
    </div>
    <div id="perabills_receipt">
        <div id="branch-copy">
            <pre id="pre-branch-copy" style="padding-left: 25px; font-size: 11px; letter-spacing: 2px; font-family: monospace, arial;">
              {{bayadReceiptData.billerName}}<br>
              {{bayadReceiptData.transactionRefNo}}<br>
              {{bayadReceiptData.transactionTimestamp}}<br>
              {{bayadReceiptData.referenceNo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{bayadReceiptData.paymentMethod}}: Php {{bayadReceiptData.amount}}<br>
              THANK YOU FOR PAYING AT PeraHUB.<br>
              AUTHORIZED PARTNER OF BAYAD CENTER.
            </pre>
        </div>

        <div id="customer-copy">
            <pre id="pre-customer-copy" style="padding-left: 25px; font-size: 11px; letter-spacing: 2px; font-family: monospace, arial;">
              {{bayadReceiptData.billerName}}<br>
              {{bayadReceiptData.transactionRefNo}}<br>
              {{bayadReceiptData.transactionTimestamp}}<br>
              {{bayadReceiptData.referenceNo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{bayadReceiptData.paymentMethod}}: Php {{bayadReceiptData.amount}}<br>
              THANK YOU FOR PAYING AT PeraHUB.<br>
              AUTHORIZED PARTNER OF BAYAD CENTER.
            </pre>
        </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PeraBillsReceipt',
  data () {
    return {
      billerName: '',
      transactionRefNo: '',
      transactionTimestamp: '',
      referenceNo: '',
      paymentMethod: '',
      amount: '',
      trxId: '',
      tpaId: ''
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      bayadReceiptData: 'bills/bayadReceiptData'
    })
  },
  watch: {
    bayadReceiptData: {
      handler (item) {
        console.log(item)
      },
      deep: true
    }
  },
  mounted () {
    console.log(this.bayadReceiptData)
    if (this.bayadReceiptData === {}) {
      window.location.href = '/pera-bills/payments'
    }
  },
  methods: {
    finalPrint () {
      const printContents = document.getElementById('perabills_receipt').innerHTML
      const originalContents = document.body.innerHTML

      document.body.innerHTML = printContents

      window.print()

      document.body.innerHTML = originalContents
    }
  }
}
</script>
<style>
  .payment-receipt-container{
    padding: 0;
    margin: 0;
    box-sizing: content-box;
    font-family: monospace, arial;
  }

  .btn-container{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .div-button button {
    background-color: rgb(14, 130, 238);
    color: white;
    border-radius: 5px;
    border:none;
    padding: .75rem 30px;
    margin-right: 10px;
  }

  #perabills_receipt{
    padding-left: 20px;
    font-family: monospace, arial;
  }

  #branch-copy{
    margin-bottom: 50px;
  }

  #perabills_receipt pre {
    font-size: 11px;
    letter-spacing: 2px;
    line-height: 0.6;
    white-space: pre-line;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    margin: 10px 0;
    margin-right: 10px;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
  }

  @media print {
    #perabills_receipt{
      position: relative;
      font-family: monospace, arial;
      padding-left: 25px;
    }

    #pre-branch-copy {
      line-height: 0.6;
      white-space: pre-line;
    }

    #pre-customer-copy {
      line-height: 0.6;
      white-space: pre-line;
    }

    #branch-copy{
      position:absolute;
      top:735px;
    }

    #customer-copy{
      position: absolute;
      bottom:23px;
    }
  }
</style>
