<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-6 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="12" :sm="4" :md="3">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-7" :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:[`item.customer_name`]="{ item }">
            <v-avatar class="mr-1" size="26px" :style="avatarColor(item.customer_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.customer_name) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              @click="editItem(item)"
              color="primary"
              icon
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              @click="showWhitelistDialog(item)"
              color="primary"
              icon
            >
              <v-icon>mdi-account-check</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="newEntry"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="isWhitelistDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Profiling
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header text-center">
          Are you sure you want to profile this customer?
          <br><br>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeWhitelistDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="doWhitelist"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarInitial,
  toProperCase,
  avatarColor,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      search: '',
      fab: false,
      selectedItem: null,
      isWhitelistDialog: false,
      breadcrumbsItems: [
        {
          text: 'Compliance Module',
          disabled: false,
          exact: true,
          to: '/compliance'
        },
        {
          text: 'PEP',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customer_name',
          width: '180px'
        },
        {
          text: 'Category',
          sortable: true,
          value: 'category',
          width: '120px'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date',
          width: '120px'
        },
        {
          text: 'Position',
          sortable: true,
          value: 'position',
          width: '150px'
        },
        {
          text: 'Area Covered',
          sortable: true,
          value: 'area_covered',
          width: '150px'
        },
        {
          text: 'Link to PEP',
          sortable: true,
          value: 'link_pep_name',
          width: '150px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '80px'
        }
      ],
      items: []
    }
  },
  methods: {
    newEntry () {
      this.$router.push('/compliance/pep/new')
    },
    editItem (item) {
      this.$router.push(`/compliance/pep/${item.id}`)
    },
    showWhitelistDialog (item) {
      this.selectedItem = item
      this.isWhitelistDialog = true
    },
    closeWhitelistDialog () {
      this.selectedItem = null
      this.isWhitelistDialog = false
    },
    async doWhitelist () {
      const payload = {
        id: this.selectedItem.id,
        updated_by: this.currUser.user_id
      }

      const { code } = await this.$store.dispatch('compliance/whitelistPEPCustomer', payload)

      if (code === 200) {
        renderToast('success', 'Successful', 'This customer has been profiled')
      } else {
        renderToast('error', 'Failed', 'An error occurred while trying to profile the customer')
      }

      this.retrieveItems()
      this.closeWhitelistDialog()
    },
    async retrieveItems () {
      try {
        const { code, result } = await this.$store.dispatch('compliance/retrievePEP')

        if (code === 200) {
          this.items = result.map(item => {
            return {
              ...item,
              customer_name: `${item.first_name} ${item.last_name}`
            }
          })
        } else {
          renderToast('error', `[${code}] Error`, 'Failed to retrieve grid')
        }
      } catch (e) {
        renderToast('error', 'USP Error', 'Failed to retrieve grid')
      }
    },
    formatDate (date) {
      if (date) return date.substr(0, 10)
      return date
    },
    avatarInitial,
    toProperCase,
    avatarColor
  },
  mounted () {
    this.retrieveItems()
  }
}
</script>
