<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-form ref="form" v-if="formData.userID">
      <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
        <v-col :cols="3">
          <v-row>
            <v-col class="mb-md-2" :cols="12">
              <h6 class="text-h6 custom-heading primary--text">
                Approver Details
              </h6>
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.userName"
                @click:append="changeUser"
                append-icon="mdi-pencil"
                class="ph-textbox"
                label="User"
                :rules="requiredRules"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.level"
                :items="levels"
                :rules="requiredRules"
                class="ph-textbox"
                label="Approver Level"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.provider"
                :items="collections.providers"
                item-text="provider_name"
                item-value="id"
                :rules="requiredRules"
                class="ph-textbox"
                label="Provider"
                return-object
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.provider.service_name"
                class="ph-textbox"
                label="Service Name"
                :rules="requiredRules"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.provider.service_type"
                class="ph-textbox"
                label="Service Type"
                :rules="requiredRules"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.channel"
                :items="collections.channels"
                item-text="channel_name"
                item-value="channel_code"
                class="ph-textbox"
                label="Channel"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="save" class="font-weight-bold" color="primary" block x-large>
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row v-else class="px-md-1 mx-md-4 mt-md-5" no-gutters>
      <v-col :cols="12" :md="4">
        <h3 class="pb-2 pb-md-6 custom-heading primary--text">
          User Lookup
        </h3>
        <p class="text-body-2 mb-6">
          Search for a USP User
        </p>
      </v-col>
      <v-col class="pl-md-6" :cols="12" :md="8">
        <h4 class="mb-4 mb-md-5 grey--text text--darken-2">
          User's employee number or full name
        </h4>
        <v-text-field
          v-model="employeeNumber"
          class="ph-textbox mb-4 mb-md-6"
          label="Search"
          @input="convertToProperCase('employeeNumber')"
          @keyup.enter="doSearch"
          outlined
        />
        <div class="text-right">
          <v-btn @click="doSearch" :disabled="!employeeNumber" class="rounded-lg" color="primary" large>
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="resultDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="60%">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            USP User Results
          </h3>
          <v-spacer />
          <v-btn @click="resultDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select User</strong>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                class="ph-textbox rounded-lg"
                placeholder="Search"
                outlined
              />
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectUser"
            :headers="headers"
            :items="searchResult"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No users found"
          >
            <template v-slot:item.name="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(`${item.last_name}, ${item.first_name}`)">
                <span class="white--text text-body-2">{{ avatarInitial(`${item.last_name}, ${item.first_name}`) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarInitial,
  toProperCase,
  renderToast,
  avatarColor,
  trim
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections',
      searchResult: 'users/searchResult',
      formData: 'transactionLimit/approverFormData',
      selectedApprover: 'transactionLimit/selectedApprover'
    })
  },
  data () {
    return {
      searchText: '',
      employeeNumber: '',
      resultDialog: false,
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Approvers',
          disabled: false,
          exact: true,
          to: '/transaction-limit/approvers'
        },
        {
          text: 'Update Approver',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Employee Number',
          sortable: false,
          value: 'employee_number'
        },
        {
          text: 'Name',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Email Address',
          sortable: false,
          value: 'email_address'
        }
      ],
      levels: [
        { text: 'CH/ROH (Reviewer)', value: 'ROH' },
        { text: 'CS (Approver)', value: 'CS' },
        { text: 'Finance Committee (Reviewer)', value: 'FINANCE' },
        { text: 'NetOPS Head (Approver)', value: 'NETOPS' },
        { text: 'Admin', value: 'ADMIN' }
      ]
    }
  },
  methods: {
    async doSearch () {
      if (this.employeeNumber) {
        const req = await this.$store.dispatch('users/searchUser', {
          search: this.employeeNumber,
          user_id: this.currUser.user_id,
          module_id: 28,
          access_permission: 'view_access'
        })

        this.resultDialog = true

        if (req.code === 403) {
          renderToast('error', req.message, req.error.message)
        }
      }
    },
    changeUser () {
      this.resetSearchField()
      this.$store.commit('transactionLimit/SET_APPROVER_FORM_DATA', {
        userID: '',
        userName: '',
        userEmail: ''
      })
    },
    selectUser (data) {
      this.$store.commit('transactionLimit/SET_APPROVER_FORM_DATA', {
        userID: data.user_id,
        userName: `${data.last_name}, ${data.first_name}`,
        userEmail: data.email_address
      })
      this.resultDialog = false
    },
    async save () {
      if (this.$refs.form.validate()) {
        const payload = {
          user_id: this.formData.userID,
          approver_level: this.formData.level,
          provider_name: this.formData.provider.provider_name,
          provider_code: this.formData.provider.provider_code,
          service_name: this.formData.provider.service_name,
          service_type: this.formData.provider.service_type,
          channel: this.formData.channel,
          email: this.formData.userEmail
        }

        const { code } = await this.$store.dispatch('transactionLimit/updateApprover', {
          id: this.$route.params.id,
          payload
        })

        if (code === 200) {
          this.$store.dispatch('transactionLimit/resetApproverForm')
          renderToast('success', 'Successful', 'New approver has been updated')
          this.$router.push('/transaction-limit/approvers')
        } else {
          renderToast('error', 'Failed', 'Failed to update approver')
        }
      }
    },
    cancel () {
      this.$router.push('/transaction-limit/approvers')
    },
    convertToProperCase (field) {
      this[field] = toProperCase(trim(this[field]))
    },
    convertFieldToProperCase (field) {
      this.formData[field] = toProperCase(trim(this.formData[field]))
    },
    resetSearchField () {
      this.employeeNumber = ''
    },
    avatarInitial,
    toProperCase,
    avatarColor
  },
  async beforeMount () {
    this.$store.dispatch('transactionLimit/resetApproverForm')
    await this.$store.dispatch('getProviders')
    this.resetSearchField()

    if (this.selectedApprover) {
      const [selectedProvider] = this.collections.providers.filter(item => item.provider_code === this.selectedApprover.provider_code)

      this.$store.commit('transactionLimit/SET_APPROVER_FORM_DATA', {
        userID: this.selectedApprover.user_id,
        userName: this.selectedApprover.approver_name,
        userEmail: this.selectedApprover.email,
        level: this.selectedApprover.approver_level,
        provider: selectedProvider,
        channel: this.selectedApprover.channel
      })
    } else {
      // Implement getting the data when reload
    }
  }
}
</script>

<style scoped>
.custom-heading {
  font-family: 'Baloo Extra Bold' !important;
}
</style>
