<template>
  <v-app>
    <LoadingScreen />
    <slot />
  </v-app>
</template>

<script>
import LoadingScreen from '@/components/partials/LoadingScreen.vue'

export default {
  name: 'SinglePage',
  components: {
    LoadingScreen
  }
}
</script>
