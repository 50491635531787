<template>
  <div class="mt-2 mt-md-3 mx-2 mx-md-5">
    <v-card class="custom-card mt-6 mx-4">
      <v-card-text>
        <v-row class="px-4 py-3">
          <v-col class="d-flex align-center" :cols="12" :md="8">
            <v-avatar class="mr-md-8 primary" size="80px">
              <v-icon color="white" x-large>mdi-magnify</v-icon>
            </v-avatar>
            <div
              v-for="(data, index) in searchData"
              :key="index"
              class="d-flex flex-column ml-4 mr-8"
            >
              <div class="mb-1 primary--text"><strong>{{ data.label }}</strong></div>
              <h1>{{ data.value }}</h1>
            </div>
          </v-col>
          <v-col class="d-flex align-center" :cols="12" :md="4">
            <v-spacer />
            <v-btn
              @click="backToSearch"
              class="font-weight-bold mr-sm-2 mr-md-4 rounded-xl"
              color="primary"
              x-large
            >
              <v-icon class="mr-2">mdi-pencil</v-icon>
              Edit Search
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="mt-2 mt-sm-4 mt-md-6 mb-8" no-gutters>
      <v-col class="px-2 px-md-4" :cols="12">
        <v-card class="custom-card px-8 py-6">
          <v-data-table
            :headers="customerHeaders"
            :items="records"
            v-if="auditTrailData.type === 'cust'"
            @update:page="updateCustomerLogsData({ pageNumber: $event })"
            @update:items-per-page="updateCustomerLogsData({ perPage: $event })"
            :server-items-length="pagination.totalItems"
            :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
          />
          <v-data-table
            :headers="transactionHeaders"
            :items="records"
            @update:page="updateTransactionLogsData({ pageNumber: $event })"
            @update:items-per-page="updateTransactionLogsData({ perPage: $event })"
            :server-items-length="pagination.totalItems"
            :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
            v-if="auditTrailData.type === 'trx'"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { avatarInitial, avatarColor, renderToast } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      auditTrailData: 'compliance/auditTrailData'
    }),
    searchData () {
      const data = []
      const { type, payload } = this.auditTrailData
      const {
        filterBy,
        customerName,
        transactionType,
        userName,
        query
      } = payload
      const {
        date_from: dateFrom,
        date_to: dateTo,
        reference_no: referenceNumber,
        sanction_screening: screening
      } = query

      if (type === 'cust' || (type === 'trx' && filterBy === 'customer')) {
        data.push({ label: 'Customer Name', value: customerName })
      }

      if (type === 'trx' && filterBy === 'type') {
        data.push({ label: 'Transaction', value: transactionType })
      }

      if (type === 'trx' && (filterBy === 'date' || filterBy === 'type')) {
        data.push({ label: 'Date From', value: dateFrom })
        data.push({ label: 'Date To', value: dateTo })
      }

      if (type === 'trx' && filterBy === 'reference') {
        data.push({ label: 'Reference Number', value: referenceNumber })
      }

      if (type === 'trx' && filterBy === 'user') {
        data.push({ label: 'User', value: userName })
      }

      if (type === 'trx' && filterBy === 'approver') {
        data.push({ label: 'Approver', value: userName })
      }

      if (type === 'trx' && filterBy === 'screening') {
        data.push({ label: 'Sanction Screening', value: screening })
      }

      return data
    }
  },
  data () {
    return {
      pagination: { pageNumber: 1, perPage: 10, totalItems: 0 },
      records: [],
      transactionHeaders: [
        {
          text: 'TIME AND DATE OF TXN',
          sortable: true,
          value: 'trx_datetime'
        },
        {
          text: 'TRANSACTION TYPE',
          sortable: true,
          value: 'transaction_type'
        },
        {
          text: 'TRANS REFERENCE NUMBER',
          sortable: true,
          value: 'reference_number'
        },
        {
          text: 'AMOUNT (with Currency)',
          sortable: true,
          value: 'amountWithCurrency'
        },
        {
          text: 'CLIENT NAME/COUNTERPARTY',
          sortable: true,
          value: 'clientName'
        },
        {
          text: 'PROCESSED BY',
          sortable: true,
          value: 'processedBy'
        },
        {
          text: 'APPROVED BY',
          sortable: true,
          value: 'approvedBy'
        },
        {
          text: 'SANCTION SCREENING',
          sortable: true,
          value: 'sanction_screening'
        },
        {
          text: 'USERNAME',
          sortable: true,
          value: 'user_name'
        },
        {
          text: 'IP address and/or Workstation ID',
          sortable: true,
          value: 'ip_address'
        },
        {
          text: 'USER ID',
          sortable: true,
          value: 'employee_number'
        },
        {
          text: 'CHANGES MADE',
          sortable: true,
          value: 'changes_made'
        },
        {
          text: 'TIME AND DATE OF CHANGES',
          sortable: true,
          value: 'changes_datetime'
        }
      ],
      customerHeaders: [
        {
          text: 'ID TYPE, DATE OF EXPIRATION, ID NO.',
          sortable: true,
          value: 'id_info'
        },
        {
          text: 'USERNAME',
          sortable: true,
          value: 'username'
        },
        {
          text: 'IP address and/or Workstation ID',
          sortable: true,
          value: 'ip_address'
        },
        {
          text: 'USER ID',
          sortable: true,
          value: 'user_id'
        },
        {
          text: 'UPDATING/ CREATION / CHANGES MADE',
          sortable: true,
          value: 'changes_made'
        },
        {
          text: 'TIME AND DATE OF CHANGES',
          sortable: true,
          value: 'changes_datetime'
        }
      ]
    }
  },
  methods: {
    updateCustomerLogsData ({ pageNumber, perPage }) {
      if (pageNumber) {
        this.pagination.pageNumber = pageNumber
        this.auditTrailData.payload.query = {
          ...this.auditTrailData.payload.query,
          offset: ((pageNumber - 1) * this.pagination.perPage)
        }
      }

      if (perPage) {
        this.pagination.perPage = perPage
        this.auditTrailData.payload.query = {
          ...this.auditTrailData.payload.query,
          limit: perPage
        }
      }

      this.fetchCustomerLogs()
    },
    updateTransactionLogsData ({ pageNumber, perPage }) {
      if (pageNumber) {
        this.pagination.pageNumber = pageNumber
        this.auditTrailData.payload.query = {
          ...this.auditTrailData.payload.query,
          offset: ((pageNumber - 1) * this.pagination.perPage)
        }
      }

      if (perPage) {
        this.pagination.perPage = perPage
        this.auditTrailData.payload.query = {
          ...this.auditTrailData.payload.query,
          limit: perPage
        }
      }

      this.fetchTransactionLogs()
    },
    async fetchCustomerLogs () {
      const { code, result: { count, records } } = await this.$store.dispatch('compliance/getCustomerLogs', this.auditTrailData.payload)

      if (code === 200) {
        this.pagination.totalItems = count
        this.records = records
      } else {
        renderToast('error', 'Failed', 'An error encountered while fetching data, please try again later')
      }
    },
    async fetchTransactionLogs () {
      const { code, result: { count, records } } = await this.$store.dispatch('compliance/getTransactionLogs', this.auditTrailData.payload)

      if (code === 200) {
        this.pagination.totalItems = count
        this.records = records
      } else {
        renderToast('error', 'Failed', 'An error encountered while fetching data, please try again later')
      }
    },
    backToSearch () {
      this.$store.commit('compliance/SET_CURRENT_PAGE', 0)
    },
    avatarInitial,
    avatarColor
  },
  mounted () {
    if (this.auditTrailData.type === 'cust') {
      this.$store.commit('SET_PAGE_TITLE', 'Customer Logs')
      this.fetchCustomerLogs()
    } else if (this.auditTrailData.type === 'trx') {
      this.$store.commit('SET_PAGE_TITLE', 'Transaction Logs')
      this.fetchTransactionLogs()
    } else {
      this.backToSearch()
    }
  }
}
</script>
