export default {
  deposits: [],
  showDepositCreationModal: false,
  showDepositUpdateModal: false,
  branches: [],
  currencies: ['PESO', 'USD'],
  prpList: [],
  editedItem: {},
  editedIndex: ''
}
