import { endpoints } from '@/api/endpoints'

const eReceipt = endpoints.use('eReceipt')

export default {
  getEreceiptID: ({ commit }, payload) => new Promise((resolve, reject) => {
    eReceipt.getEreceiptID(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getEreceiptByID: ({ commit }, payload) => new Promise((resolve, reject) => {
    eReceipt.getEreceiptByID(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  downloadEreceipt: ({ commit }, payload) => new Promise((resolve, reject) => {
    eReceipt.downloadEreceipt(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getBranchDetail: ({ commit }, payload) => new Promise((resolve, reject) => {
    eReceipt.getBranchDetail(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  cancelEReceipt: ({ commit }, payload) => new Promise((resolve, reject) => {
    eReceipt.cancelEReceipt(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
