<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="12" :sm="4" :md="3">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col :cols="12" :sm="8" :md="9">
        <v-btn
          @click="retrieveData"
          class="text-capitalize font-weight-bold"
          color="primary"
          rounded
          large
          text
        >
          <v-icon class="mr-1">mdi-refresh</v-icon>
          Refresh
        </v-btn>
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-7" :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template #item.status="{ item }">
            {{ displayStatus(item.status) }}
          </template>
          <template #item.actions="{ item }">
            <v-btn
              @click="openUpdateStatusDialog(item)"
              color="primary"
              :disabled="item.status !== 0"
              icon
            >
              <v-icon>mdi-check-circle</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="updateStatusDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Update Status
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to update status of this customer?
          <br><br>
          Customer: <span v-if="selectedItem">{{ selectedItem.customer_name }}</span><br>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeUpdateStatusDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="doUpdateStatus"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      selectedItem: null,
      updateStatusDialog: false,
      search: '',
      items: [],
      breadcrumbsItems: [
        {
          text: 'Prompt Management',
          exact: true,
          to: '/prompt'
        },
        {
          text: 'Pre-Qualified Customers',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Customer Number',
          sortable: true,
          value: 'customer_number',
          width: '120px'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customer_name',
          width: '120px'
        },
        {
          text: 'Offer',
          sortable: true,
          value: 'offer_name',
          width: '120px'
        },
        {
          text: 'Customer Branch Name',
          sortable: true,
          value: 'clean_location_name',
          width: '120px'
        },
        {
          text: 'Added By',
          sortable: true,
          value: 'added_by',
          width: '120px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '120px'
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '120px'
        }
      ]
    }
  },
  methods: {
    displayStatus (status) {
      const statusText = [
        'Not yet offered to the client',
        'Already offered to the client'
      ]

      return statusText[status]
    },
    async doUpdateStatus () {
      try {
        const { code } = await this.$store.dispatch('prompt/updateQualifiedCustomer', {
          id: this.selectedItem.id,
          payload: {
            status: 1
          }
        })

        if (code === 200) {
          this.retrieveData()
          this.closeUpdateStatusDialog()
          renderToast('success', 'Successful', 'Status has been updated')
        } else {
          renderToast('error', 'Failed', 'Failed to update status')
        }
      } catch (e) {
        renderToast('error', 'Failed', 'Failed to delete offer')
      }
    },
    openUpdateStatusDialog (data) {
      this.selectedItem = data
      this.updateStatusDialog = true
    },
    closeUpdateStatusDialog () {
      this.selectedItem = null
      this.updateStatusDialog = false
    },
    async retrieveData () {
      try {
        const { code, result } = await this.$store.dispatch('prompt/retrieveQualifiedCustomers')

        if (code === 200) {
          this.items = result
        } else {
          renderToast('error', 'Request Error', 'Failed to retrieve grid')
        }
      } catch (e) {
        renderToast('error', 'Failed', 'Failed to retrieve grid')
      }
    }
  },
  mounted () {
    this.retrieveData()
  }
}
</script>
