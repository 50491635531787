import api from '@/api'

export default {
  getCloseList (payload) {
    return api.post('/v1/maintenance/api/closing/pending-task', payload)
  },
  getExcessFundReasonList () {
    return api.get('/v1/maintenance/api/excess-fund/list')
  },
  checkExcessFund (payload) {
    return api.get(`/v1/maintenance/api/excess-fund/check?trx_date=${payload.trx_date}&location_id=${payload.location_id}`)
  },
  excessFundRequest (payload) {
    return api.post('/v1/maintenance/api/excess-fund/request', payload)
  },
  updateExcessFundStatus (payload) {
    return api.put(`/v1/maintenance/api/excess-fund/status/${payload.id}`, payload.params)
  },
  getExcessFundRequestList (payload) {
    return api.get(`/v1/maintenance/api/excess-fund/approval?trx_date=${payload.trx_date}&status=${payload.status}&user_id=${payload.user_id}`)
  },
  waiveVariance (payload) {
    return api.post('/v1/maintenance/api/closing/waive-variance', payload)
  },
  depositExcessFunds (payload) {
    return api.post('/v1/maintenance/api/excess-fund/deposit', payload)
  }
}
