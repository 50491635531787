<template>
  <div class="ph-container">
    <v-container fluid class="ph-dashboard-container">
      <div class="ph-branch-info-container">
        <div class="ph-branch-details">
          <p class="d-inline">
            Branch:
          </p>
          <h2 class="d-inline">
            {{currUser.location_name}}
          </h2>
        </div>
        <div class="ph-branch-close">
          <v-btn color="primary" @click="showQRCodeDialog" text class="ph-action-button">
            <v-icon class="mr-1">mdi-qrcode-scan</v-icon>
            Show QR Code
          </v-btn>
          <v-btn
            @click="confirmCloseBranch"
            color="primary"
            elevation="0"
            class="ph-branch-close-button">
            Close Branch
          </v-btn>
        </div>
      </div>
      <br>
      <div v-if="locationGroup">
        <v-dialog v-model="qrCodeDialog" persistent  width="350">
          <v-card class="rounded-lg">
            <v-card-title class="py-md-6">
              <h3 class="custom-heading primary--text">
                Scan QR Code
              </h3>
              <v-spacer />
              <v-btn @click="qrCodeDialog = false" small icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="text-center pt-4 pb-12">
              <div v-html="qrCode" class="ph-qr-code"></div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col>
            <UserBalanceDataComponent></UserBalanceDataComponent>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <CloseBranchComponent></CloseBranchComponent>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <RHDataComponent></RHDataComponent>
      </div>
      <v-dialog
        persistent
        v-model="noWacom"
        width="500"
        >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Wacom SDK Not Installed
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            <p>The system has detected that your computer does not have the Wacom SDK for the signature pad to work. Kindly install the required files from the link below:</p>
            <v-icon
              medium
              style="text-decoration: none;"
              >
              mdi-file
            </v-icon>
            <a href="https://drive.google.com/file/d/1qRRd5HVqRpu10Ustjc-CYXgHoFWtkZnq/view?usp=sharing" target="blank">
              Wacom SDK
            </a>
            <v-checkbox
              v-model="remind"
              label="Don't remind me again"
            ></v-checkbox>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="handleClick"
            >
              Got it
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="closeBranchDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Close Branch
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            Are you sure you want to close today's transaction?
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="closeBranchDialog = false"
              class="ph-action-button"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="closeBranch"
              class="ph-action-button"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <no-signature-dialog />
  </div>
</template>
<script>
import CloseBranchComponent from './Dashboard/CloseBranchComponent.vue'
import noSignatureDialog from '@/components/wacom/noSignatureDialog.vue'
import { mapGetters } from 'vuex'
import RHDataComponent from './Dashboard/RHDataComponent.vue'
import UserBalanceDataComponent from './Dashboard/UserBalanceDataComponent.vue'
import VueCookies from 'vue-cookies'
import wacomMixin from '@/mixins/wacom.js'
import { renderToast } from '@/utils'
import { format, parseISO } from 'date-fns'
// import wacomIdle from '@/components/wacom/wacomIdle'
export default {
  name: 'Dashboard',
  mixins: wacomMixin,
  data () {
    return {
      qrCode: '',
      qrCodeDialog: false,
      remind: false,
      locationGroup: true,
      dialog: false,
      dialogSignature: false,
      closeBranchDialog: false,
      header: {
        title: 'Dashboard',
        img: 'nonex-illustration'
      }
    }
  },
  computed: {
    ...mapGetters({
      payoutFormData: 'nonex/payoutFormData',
      currUser: 'auth/currUser',
      closeList: 'closeBranch/closeList',
      excessFundInfo: 'closeBranch/excessFundInfo'
    }),
    noWacom () {
      return ((VueCookies.get('dont_remind_wacom') === null || VueCookies.get('dont_remind_wacom') === 'false') &&
        this.dialog &&
        !this.currUser.has_wacom_sdk &&
        (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl))
    }
  },
  beforeMount () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    this.populateCollections()
  },
  mounted () {
    // this.$store.commit('wacom/SET_WACOM_IDLE_DIALOG', true)
    if (VueCookies.get('dont_remind_wacom') === null && !this.currUser.has_wacom_sdk) {
      VueCookies.set('dont_remind_wacom', false, -1)
    }

    setTimeout(() => {
      this.dialog = true
    }, 1000)
    console.log(VueCookies.get('dont_remind_wacom'))
    // if (VueCookies.get('dont_remind_wacom') === 'false' && !this.currUser.has_wacom_sdk && (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl)) {
    //   this.dialog = true
    // }
  },
  components: {
    CloseBranchComponent,
    RHDataComponent,
    UserBalanceDataComponent,
    noSignatureDialog
    // wacomIdle
  },
  methods: {
    renderToast,
    formatDateTime (date) {
      if (date) return format(parseISO(date), 'yyyy-MM-dd')
      return ''
    },
    populateCollections () {
      [
        'getSourceOfFund',
        'getRelationshipDppv2',
        'getRelationship',
        'getCountries',
        'getNatureOfWork',
        'getPositionLevel',
        'getEmployment',
        'getCities'
      ].forEach(action => this.$store.dispatch(action))
    },
    handleClick () {
      if (this.remind) {
        VueCookies.set('dont_remind_wacom', true, -1)
        this.dialog = false
      } else {
        this.dialog = false
      }
    },
    handleClickSignature () {
      this.dialogSignature = false
      this.$router.push('/account')
    },
    confirmCloseBranch () {
      let closeHitCount = 0

      if (this.excessFundInfo?.Peso && this.excessFundInfo?.Dollar) {
        const pesoHitCount = this.excessFundInfo.Peso.filter(data => data.HitCount > 0).length
        const dollarHitCount = this.excessFundInfo.Dollar.filter(data => data.HitCount > 0).length

        if (this.closeList) {
          if (this.closeList.length > 0) {
            this.closeList.forEach(res => {
              if (res.HitCount > 0) {
                closeHitCount += 1
              }
            })
          }

          this.validateCloseBranch(closeHitCount > 0 || pesoHitCount > 0 || dollarHitCount > 0)
        } else {
          this.validateCloseBranch(pesoHitCount > 0 || dollarHitCount > 0)
        }
      } else {
        this.validateCloseBranch(false)
      }
    },
    validateCloseBranch (condition) {
      if (condition) {
        this.renderToast('error', 'Close Branch Error', 'You must close all the checklist below before closing the branch')
      } else {
        this.closeBranchDialog = true
      }
    },
    async closeBranch () {
      this.closeBranchDialog = false

      const payload = {
        locationID: this.currUser.location_id,
        trxDate: this.formatDateTime(this.currUser.trx_date),
        userID: this.currUser.user_id
      }

      const res = await this.$store.dispatch('auth/closeBranch', payload)

      if (res.code === 200) {
        this.logout()
      }
    },
    async logout () {
      const that = this
      this.closeBranchDialog = false

      const payload = {
        username: this.currUser.user_id,
        ip_address: this.currUser.ip_address
      }

      this.$store.commit('auth/SET_IS_OTP_PASSED', false)
      this.$store.commit('app/TOGGLE_LOADING', true)

      await this.$store.dispatch('auth/doLogout', payload).then(res => {
        console.log(res)
        that.$store.commit('auth/SET_CURRENT_USER', {})
        that.$store.commit('auth/AUTH_SUCCESS', false)
        that.$store.commit('auth/SET_SHOW_REFRESH', false)
        that.$store.commit('app/TOGGLE_LOADING', false)
        location.href = `${this.$uspAuthAppURL}/login`
        localStorage.setItem('logout_message', 'Transaction Date has been closed, please relogin your account')
      }).catch(() => {
        this.renderToast('error', 'Error logging-out', 'An error has occured, please try again.')
      })
    },
    async showQRCodeDialog () {
      const data = await this.$store.dispatch('getQRCode', this.currUser.location_id)

      this.qrCode = data
      this.qrCodeDialog = true
    }
  }
}
</script>
<style scoped>
.ph-branch-info-container {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}

.ph-branch-details {
  margin-right: auto;
}

.ph-branch-close {
  margin-right: auto;
  margin-left: 0;
}

.ph-branch-close-button {
  border-radius: 10px;
  font-family: 'Proxima Nova';
  text-transform: none;
  font-weight: bolder;
}

.ph-action-button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.ph-dashboard-container {
  padding-left: 16px;
  padding-right: 16px;
}

.ph-qr-code /deep/ svg {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .ph-dashboard-container {
    padding-left: 33px;
    padding-right: 33px;
  }

  .ph-action-button {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 701px) {
  .ph-branch-info-container {
    flex-direction: row;
  }

  .ph-branch-close {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
}
</style>
