export default {
  phID: '',
  hasAgent: true,
  currentPage: 0,
  currentStep: 1,
  receiverList: [],
  feeInquiryData: null,
  kycLookupData: null,
  validateData: null,
  storeData: null,
  remoteData: null,
  payoutCountryList: [],
  deliveryServiceTypes: [],
  fieldsList: [],
  combosList: [],
  remoteDetails: {
    locationID: '',
    userID: '',
    otp: ''
  },
  remcoList: [
    {
      id: 0,
      logo: 'wu.png',
      name: 'Western Union',
      fee: '',
      body: null
    },
    {
      id: 3,
      logo: 'smart-padala.png',
      name: 'Smart Padala',
      fee: '',
      body: null
    },
    {
      id: 9,
      logo: 'cebuana.jpg',
      name: 'Cebuana',
      fee: '',
      body: null
    },
    {
      id: 10,
      logo: 'ussc.png',
      name: 'USSC',
      fee: '',
      body: null
    },
    {
      id: 22,
      logo: 'ayannah.jpg',
      name: 'Ayannah',
      fee: '',
      body: null
    }
  ],
  transactionData: {
    oar: '',
    refNo: '',
    senderFirstName: '',
    senderMiddleName: '',
    senderLastName: '',
    senderBirthDate: '',
    destinationCountryID: '',
    destinationCountryCode: '',
    destinationCountryCurrency: '',
    destinationCountryName: '',
    exchangeRate: '',
    fixedAmountFlag: '',
    sendAmount: '',
    receiveAmount: '',
    serviceFee: '',
    messageCharge: '',
    discountAmount: '',
    totalAmount: '',
    remcoID: '',
    promoCode: '',
    message: [],
    deliveryService: 'MIM',
    testQuestion: '',
    answer: '',
    isOnBehalf: 'N',
    receiverFirstName: '',
    receiverMiddleName: '',
    receiverLastName: '',
    receiverMobileNumber: '',
    receiverAccountNumber: '',
    receiverState: '',
    receiverCity: '',
    galacticID: '',
    clientID: '',
    beneficiaryID: '',
    searchBy: 'by_id',
    agentVerifiedData: false
  }
}
