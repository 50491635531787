<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
      <v-col :cols="12">
        <v-card elevation="1" class="ph-nonex-datatable-container">
          <v-card-title class="ph-nonex-filter-section">
            <v-col :cols="12" class="pl-md-0 py-md-0">
              <p><strong>Filter Dropdown:</strong></p>
            </v-col>
            <v-col :cols="2" class="pl-md-0">
              <v-menu
                ref="dateFromPicker"
                v-model="dateFromPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFrom"
                    class="ph-textbox"
                    color="gray darken-1"
                    label="From"
                    append-icon="mdi-calendar"
                    hide-details
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    :rules="(dateTo === undefined || dateTo === '' || dateTo === null)  ? [] : requiredField"
                  />
                </template>
                <v-date-picker
                  v-model="dateFrom"
                  :active-picker.sync="activeDateFromPicker"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  @change="syncDateFrom"
                />
              </v-menu>
            </v-col>
            <v-col :cols="2">
              <v-menu
                ref="dateToPicker"
                v-model="dateToPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateTo"
                    class="ph-textbox"
                    color="gray darken-1"
                    label="To"
                    append-icon="mdi-calendar"
                    hide-details
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    :rules="(dateFrom === undefined || dateFrom === '' || dateFrom === null) ? [] : requiredField"
                  />
                </template>
                <v-date-picker
                  v-model="dateTo"
                  :active-picker.sync="activeDateToPicker"
                  :min="dateFrom ? dateFrom : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  @change="syncDateTo"
                />
              </v-menu>
            </v-col>
            <v-col :cols="3">
              <v-select
                v-model="ciStatus"
                :items="ciOptions"
                class="ph-textbox"
                label="CI Status"
                outlined
              />
            </v-col>
            <v-col :cols="2">
              <v-btn
                color="primary"
                elevation="0"
                class="ph-primary-button ph-primary-execute-btn"
                @click="filterList"
              >
                Filter
              </v-btn>
            </v-col>
          </v-card-title>
          <v-card-title class="ph-nonex-datatable-title">
            <div class="ph-title-datatable">
              <p><strong>Search by customer details:</strong></p>
            </div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Type here to search details..."
              single-line
              hide-details
              class="ph-textbox ph-textbox-grid-search"
              outlined
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="hitList"
            class="custom-table"
            :calculate-widths="true"
          >
            <template v-slot:[`item.givenname`]="{item}">
              <div class="ph-nonex-sender-container">
                <!-- <v-avatar
                  color="#2563EB"
                  size="24"
                  class="ph-nonex-table-avatar"
                >
                  <span class="white--text">{{item.sender_name.substring(0, 1)}}</span>
                </v-avatar> -->
                <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.givenname)">
                  <span class="white--text text-body-2">{{ avatarInitial(item.givenname) }}</span>
                </v-avatar>
                <p class="ph-nonex-table-names-text">{{item.surname}}, {{item.givenname}} {{item.middlename}}</p>
              </div>
            </template>
            <template v-slot:[`item.ci_status`]="{ item }">
              {{item.ci_status ? item.ci_status : '-' }}
            </template>
            <template v-slot:[`item.api_status_description`]="{ item }">
              {{item.api_status_description ? item.api_status_description : '-' }}
            </template>
            <template v-slot:[`item.trx_date`]="{ item }">
              {{formatDate(item.trx_date)}}
            </template>
            <template v-slot:[`item.is_physical_card`]="{ item }">
              {{item.is_physical_card === 1 ? 'Yes' : 'No' }}
            </template>
            <template v-slot:[`item.email`]="{ item }">
              {{item.email ? item.email : '-' }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn @click="eReceiptInit(item)" color="primary" icon v-if="item.transaction_id">
                <v-icon>mdi-account-search-outline</v-icon>
              </v-btn>
              <v-btn disabled color="error" icon v-if="item.transaction_id === null">
                <v-icon>mdi-account-cancel-outline</v-icon>
              </v-btn>
            </template>
            <!--
            <template v-slot:[`item.valid_date_to`]="{ item }">
              {{item.valid_date_to !== null ? item.valid_date_to : '-' }}
            </template>
            -->
          </v-data-table>
        </v-card>
      </v-col>
      <v-dialog
        v-model="withoutEmailPrompt"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Transaction Confirmation
          </v-card-title>

          <v-card-text class="ph-dialog-header">
            <br>
            No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="updateDetails(selectedCustomer, 'ereceipt')"
              color="primary"
              text
            >
              Update Customer Record
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="proceedTransaction"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="withEmailPrompt"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Transaction Confirmation
          </v-card-title>

          <v-card-text class="ph-dialog-header">
            <br>
            Receipt will send to the client/s email address. Would you like to proceed this transaction?
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="printReceiptInstead"
              color="primary"
              text
            >
              I want a printed Receipt
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="proceedTransaction"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ProgressDialog
        v-bind:showProgressDialog.sync="showProgressDialog"
        v-bind:setPercentage.sync="setPercentage"
        v-bind:setDescription.sync="setDescription"
        v-bind:setColor.sync="setColor"
      />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import ProgressDialog from '@/components/partials/ProgressDialog.vue'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  components: {
    ProgressDialog
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      hitList: 'customerProfileV3/hitList',
      selectedHit: 'customerProfileV3/selectedHit',
      receipt: 'receipt'
    })
  },
  data () {
    return {
      dateFrom: '',
      dateTo: '',
      dateFromPicker: false,
      dateToPicker: false,
      activeDateFromPicker: null,
      activeDateToPicker: null,
      ciStatus: '0',
      search: '',
      ciOptions: [
        { text: 'Select an item', value: '' },
        { text: 'Show All Status', value: '0' },
        { text: '1 - 4 - For Manual Review', value: '1' },
        { text: '5 - Issues with submitted ID\'s and Selfies', value: '5' },
        { text: '6 - Incomplete Customer Information', value: '6' },
        { text: '7 - Doesn\'t meet Bank Requirements', value: '7' },
        { text: '10 - Automatically Approved', value: '10' }
      ],
      breadcrumbsItems: [
        { text: 'Dashboard', disabled: false, href: '/' },
        { text: 'Customer Hit List', disabled: true }
      ],
      headers: [
        { text: 'Name', sortable: true, value: 'givenname' },
        { text: 'CI Status', sortable: true, value: 'ci_status' },
        // { text: 'Account Status', sortable: true, value: 'account_status' },
        { text: 'Is Physical Card?', sortable: true, value: 'is_physical_card' },
        // { text: 'API Status', sortable: true, value: 'api_status' },
        { text: 'Status', sortable: true, value: 'api_status' },
        { text: 'Birth Date', sortable: true, value: 'birth_date' },
        { text: 'Mobile Number', sortable: true, value: 'mobile_number' },
        { text: 'E-mail Address', sortable: true, value: 'email' },
        { text: 'Application Date', sortable: true, value: 'trx_date' },
        { text: 'Branch', sortable: true, value: 'location_name' },
        { text: 'Actions', sortable: false, width: '150px', value: 'action' }
      ],
      requiredField: [
        v => !!v || 'This field is required'
      ],
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      showProgressDialog: false,
      setPercentage: 0,
      setDescription: '',
      setColor: '#1A2791'
    }
  },
  async mounted () {
    this.initializeList()
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    formatDate (date) {
      return format(parseISO(date), 'yyyy-MM-dd')
    },
    async initializeList () {
      const hitListPayload = {
        location_id: (this.currUser.location_type === '2') ? '0' : this.currUser.location_id,
        location_type: this.currUser.location_type,
        ci_status: '0',
        date_from: format(new Date(), 'yyyy-MM-dd'),
        date_to: format(new Date(), 'yyyy-MM-dd')
      }
      await this.$store.dispatch('customerProfileV3/getHitList', hitListPayload)
    },
    eReceiptInit (item) {
      this.$store.commit('customerProfileV3/SET_SELECTED_HIT', item)
      if (this.currUser.location_type === '1') {
        this.sendViaEmail = true
        if (item.email) {
          this.withoutEmailPrompt = false
          this.withEmailPrompt = true
        } else {
          this.withoutEmailPrompt = true
          this.withEmailPrompt = false
          this.sendViaEmail = false
        }
      } else {
        this.proceedTransaction()
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.withoutEmailPrompt = false
      this.checkCustomer(this.selectedHit)
    },
    proceedTransaction () {
      this.withEmailPrompt = false
      this.checkCustomer(this.selectedHit)
    },
    async checkCustomer (item) {
      // this.checkEmail(item.email)
      let availType = ''

      if (item.is_physical_card === 1) {
        availType = 'phv'
      } else {
        availType = 'virtual'
      }

      if (item.ci_status === '10' && item.api_status === '1') {
        this.processCardCreation(item, availType)
      } else {
        this.showProgressDialog = true
        this.updateProgress(0, 'Inquiring Customer...', '#1A2791')

        await this.$store.dispatch('customerProfileV3/inquireStatus', item.transaction_id).then(res => {
          const result = res.result

          if (result.ciStatus === '10') {
            this.processCardCreation(item, availType)
          } else {
            this.updateProgress(0, 'Customer is still on the hit list. Please advise customer to wait for confirmation via SMS to proceed.', '#B71C1C')
          }
        })
      }
    },
    updateProgress (percentage, description, color) {
      this.setPercentage = percentage
      this.setDescription = description
      this.setColor = color
    },
    async processCardCreation (item, type) {
      this.showProgressDialog = true
      if (item.ci_status === '10' && item.api_status === '1') {
        this.updateProgress(0, 'Creating Virtual Card...', '#1A2791')
      } else {
        this.updateProgress(10, 'Creating Virtual Card...', '#1A2791')
      }

      const virtualAccountPayload = {
        customerId: item.customeridno,
        // productType: 'PRD9183' // DEV
        productType: 'PRD4190' // PROD
      }

      const createVirtualCard = await this.$store.dispatch('customerProfileV3/createVirtualAccount', virtualAccountPayload).then(res => {
        if (!(res.code >= 200 && res.code <= 299)) {
          if (item.ci_status === '10' && item.api_status === '1') {
            this.updateProgress(0, res.message, '#B71C1C')
          } else {
            this.updateProgress(10, res.message, '#B71C1C')
          }

          this.renderToast('error', 'Error', res.message)
        } else {
          this.renderToast('success', 'Virtual Card Registration Successful', res.message)
          return res
        }
      }).catch(err => {
        if (item.ci_status === '10' && item.api_status === '1') {
          this.updateProgress(0, err.message, '#B71C1C')
        } else {
          this.updateProgress(10, err.message, '#B71C1C')
        }
        return err
      })

      if (createVirtualCard.code >= 200 && createVirtualCard.code <= 210) {
        this.updateProgress(35, 'Generating Security...', '#1A2791')

        const generateCVV = await this.$store.dispatch('customerProfileV3/generateCVV', { customerId: item.customeridno }).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.renderToast('error', 'Error', res.message)
            this.updateProgress(35, res.message, '#B71C1C')
          } else {
            return res
          }
        }).catch(err => {
          this.updateProgress(35, err.message, '#B71C1C')
          return err
        })

        let lockCard = {}
        let replaceCard = {}
        let activateCard = {}
        let updatePending = {}

        if (type === 'phv' || type === 'replace') {
          if (generateCVV.code >= 200 && generateCVV.code <= 210) {
            this.updateProgress(45, 'Preparing Physical Card...', '#1A2791')
            lockCard = await this.$store.dispatch('customerProfileV3/lockCard', { customerId: item.customeridno }).then(res => {
              if (!(res.code >= 200 && res.code <= 299)) {
                this.updateProgress(45, res.message, '#B71C1C')
                this.renderToast('error', 'Error', res.message)
              } else {
                return res
              }
            }).catch(err => {
              this.updateProgress(45, err.message, '#B71C1C')
              return err
            })
          }

          if (lockCard.code >= 200 && lockCard.code <= 210) {
            this.updateProgress(60, 'Setting up Physical Card...', '#1A2791')

            const replaceCardPayload = {
              customerId: item.customeridno,
              new_card_number: item.card_number
            }

            replaceCard = await this.$store.dispatch('customerProfileV3/replaceCard', replaceCardPayload).then(res => {
              if (!(res.code >= 200 && res.code <= 299)) {
                this.updateProgress(60, res.message, '#B71C1C')
                this.renderToast('error', 'Error', res.message)
              } else {
                return res
              }
            }).catch(err => {
              this.updateProgress(60, err.message, '#B71C1C')
              return err
            })
          }

          if (replaceCard.code >= 200 && replaceCard.code <= 210) {
            this.updateProgress(75, 'Activating Physical Card...', '#1A2791')

            activateCard = await this.$store.dispatch('customerProfileV3/activateCard', { customerId: item.customeridno }).then(res => {
              if (!(res.code >= 200 && res.code <= 299)) {
                this.updateProgress(75, res.message, '#B71C1C')
                this.renderToast('error', 'Error', res.message)
              } else {
                return res
              }
            }).catch(err => {
              this.updateProgress(75, err.message, '#B71C1C')
              return err
            })
          }

          if (activateCard.code >= 200 && activateCard.code <= 210) {
            this.updateProgress(90, 'Finishing transaction...', '#1A2791')

            updatePending = await this.updatePendingTransaction(item.result.id, 'phv').then(res => {
              if (!(res.code >= 200 && res.code <= 299)) {
                this.updateProgress(90, res.message, '#B71C1C')
                this.renderToast('error', 'Error', res.message)
              } else {
                return res
              }
            }).catch(err => {
              this.updateProgress(90, err.message, '#B71C1C')
              return err
            })
          }

          if (updatePending.code >= 200 && updatePending.code <= 210) {
            this.updateProgress(100, 'Success!')
            this.generateEReceipt(item)
            this.$store.commit('customerProfileV3/SET_SELECTED_HIT', {})

            const that = this

            setTimeout(function () {
              that.showProgressDialog = false
              that.updateProgress(0, '')
            }, 2500)
          }
        } else {
          if (generateCVV.code >= 200 && generateCVV.code <= 210) {
            this.updateProgress(70, 'Finishing transaction...', '#1A2791')

            updatePending = await this.updatePendingTransaction(item.id, type).then(res => {
              if (!(res.code >= 200 && res.code <= 299)) {
                this.updateProgress(70, res.message, '#B71C1C')
                this.renderToast('error', 'Error', res.message)
              } else {
                return res
              }
            }).catch(err => {
              this.updateProgress(70, err.message, '#B71C1C')
              return err
            })
          }

          if (updatePending.code >= 200 && updatePending.code <= 210) {
            this.updateProgress(100, 'Success!')
            this.initializeList()
            this.$store.commit('customerProfileV3/SET_SELECTED_HIT', {})

            const that = this

            setTimeout(function () {
              that.showProgressDialog = false
              that.updateProgress(0, '')
            }, 2500)
          }
        }
      }
    },
    async updatePendingTransaction (id, data) {
      console.log(data)
      const getCardDetails = await this.$store.dispatch('customerProfileV3/getCardInfo', data.customer_number).then(res => {
        if (!(res.code >= 200 && res.code <= 299)) {
        } else {
          return res
        }
      }).catch(err => {
        return err
      })

      if (getCardDetails.code >= 200 && getCardDetails.code <= 299) {
        const updatePayload = {
          id: id,
          params: {
            pending_status: 1,
            pending_desc: '',
            pending_data: '',
            id_ub_card: id,
            customer_id: data.customer_id,
            loyaltycard: '',
            ub_card_no: getCardDetails.result.debitcardno
          }
        }

        let result = {}

        await this.$store.dispatch('debitCard/updatePendingDebitCard', updatePayload).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.renderToast('error', 'Error', res.message)
            result = res
          } else {
            result = res
          }
        })
        return result
      } else {
        return getCardDetails
      }
    },
    async generateEReceipt (item) {
      const address = item.present_address
      const barangay = ''
      const city = item.present_city
      const province = item.present_province
      const zipCode = item.present_postal_code

      const saveReceipt = await this.$store.dispatch('saveReceipt', {
        ereceipt_id: this.receipt.ereceipt_id,
        application_id: this.currUser.application_id,
        application_header_name: this.currUser.branchDetail.header_name,
        application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
        receipt_type: this.receipt.type,
        customer_id: item.customer_id,
        customer_name: `${item.givenname} ${item.middlename ? item.middlename : ''} ${item.surname}`,
        customer_tin_no: '',
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        customer_email_address: (this.sendViaEmail) ? item.email : null,
        cashier_id: this.currUser.user_id,
        cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
        or_receipt_no: this.receipt.or_receipt_no,
        or_number: this.receipt.or_series_no,
        or_service: 'Pera Debit Card',
        or_currency: 'PHP',
        or_principal_amount: Number(this.transactionData.amount),
        or_vat_percent: 0,
        or_vat_service_fee: 0,
        or_vat_exempt_fee: 0,
        or_vat_zero_rated_fee: 0,
        or_vat_discount: 0,
        or_vat_amount: 0,
        or_total: Number(this.transactionData.amount),
        grand_total: Number(this.transactionData.amount),
        print_email_hold: 0,
        is_forex_logo: 0
      })

      if (saveReceipt.code === 200) {
        if (saveReceipt.result.receipt_status === 1) {
          this.renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
        } else if (saveReceipt.result.receipt_status === 2) {
          const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
            application_id: this.currUser.application_id,
            ereceipt_api_id: saveReceipt.result.ereceipt_api_id
          })
          var newBlob = new Blob([res], { type: 'application/pdf' })
          var newFileURL = URL.createObjectURL(newBlob)
          window.open(newFileURL, '_blank')
        } else {
          this.renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
        }

        this.initializeList()
      } else {
        this.renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
      }
    },
    syncDateFrom (date) {
      this.$refs.dateFromPicker.save(date)
    },
    syncDateTo (date) {
      this.$refs.dateToPicker.save(date)
    },
    async filterList () {
      if (this.ciStatus === '' || this.ciStatus === null) {
        this.renderToast('error', 'Error', 'Please Select CI Status.')
      } else if (this.dateFrom === null || this.dateTo === null || this.dateFrom === '' || this.dateTo === '') {
        this.renderToast('error', 'Error', 'Date Range is required. Please fill-out your "From" and "To" dates.')
      } else {
        const hitListPayload = {
          location_id: this.currUser.location_id,
          location_type: this.currUser.location_type,
          ci_status: this.ciStatus,
          date_from: this.dateFrom,
          date_to: this.dateTo
        }
        await this.$store.dispatch('customerProfileV3/getHitList', hitListPayload)
      }
    }
  }
}
</script>
<style scoped>
.ph-nonex-datatable-title {
  display: flex;
  flex-direction: column;
}

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.35);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

.ph-primary-button {
  background: #FFF;
  color: #1A2791;
  /* border: 2px solid #1A2791; */
  border-radius: 10px;
  font-family: 'Proxima Nova';
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 15px;
  transition: .2s;
  height: 60px !important;
  width: 100%;
  margin: 10px 0;
}

.ph-primary-button:hover {
  background: #1A2791 !important;
  color: #fff !important;
  /* border: 2px solid #1A2791; */
}

.ph-primary-button.ph-primary-execute-btn {
  background: #1A2791;
  color: #fff;
  font-size: 14px;
  height: 50px !important;
}

.ph-nonex-receive-action-buttons {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 20px;
}

@media only screen and (min-width: 600px) {
  .ph-nonex-receive-action-buttons {
    flex-direction: row;
  }

  .ph-primary-button {
    width: 160px;
  }
}

.ph-textbox.ph-textbox-grid-search,
.ph-title-datatable {
  width: 100%;
}
</style>
