<template>
  <div id="OAR" v-if="printData">
    <div
      id="topdiv"
      style="
        margin-left: 0px !important;
        width: 695px;
        border: 3px solid #000;
        overflow: hidden;
        padding: 0;
        height: auto;
      "
    >
      <div
        class="APrint print"
        style="width: 192px; float: left; padding: 5px 5px 5px 15px"
      >
        <img
          style="width: 100%"
          src="@/assets/images/logo-horizontal-small.png"
        />
        <p class="text-left" style="font-size: 9px; padding: 20px 0 0 0">
          Head Office: 2/F East Offices Building,<br />
          114 Aguirre Street, Legaspi Village,<br />
          Makati City 1229<br />
          <b>VAT REG. TIN: 201-200-777-00000</b>
        </p>
      </div>
      <div style="width: 490px; float: right" class="text-center">
        <div class="APrint print">
          <!--style="height: 30px;">-->
          <h4 style="margin: 0"><b>PERA HUB</b></h4>
          <p><b>Official Acknowledgement Receipt</b></p>
          <p style="font-size: 11px">
            Head Office: 2/F East Offices Building, 114 Aguirre Street, Legaspi
            Village, Makati City 1229<br />
            <b>VAT REG. TIN: 201-200-777-00000</b>
          </p>
        </div>
        <div style="width: 245px; float: left">
          <div
            class="APrint print"
            style="float: left; width: 75px; padding: 27px 0 0 0"
          >
            <h4 style="line-height: 0">Branch:</h4>
          </div>
          <div
            class="APrint padd_zero print"
            style="float: left; width: 155px; border-bottom: 2px solid #000"
          >
            <input
              type="text"
              class="print printMe input-lg"
              style="width: 100%"
              v-model="locationName"
            />
          </div>
        </div>
        <div style="width: 245px; float: left">
          <div
            class="APrint print"
            style="float: left; width: 77px; padding: 27px 0 0 0"
          >
            <h4 style="line-height: 0">No:</h4>
          </div>
          <div
            class="APrint padd_zero print"
            style="float: left; width: 155px; border-bottom: 2px solid #000"
          >
            <input
              type="text"
              class="print printMe input-lg"
              style="width: 100%"
              v-model="formNumber"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div
      class="row"
      id="printOARdiv"
      style="
        margin-left: 0px !important;
        width: 695px;
        border: 3px solid #000;
        overflow: hidden;
        padding: 0;
      "
    >
      <div
        style="width: 100%; overflow: hidden; padding: 0 5px"
        class="text-right"
      >
        <span class="APrint print" style="font-size: 15px">DATE:</span
        ><!--<div style="float: right;"></div>-->
        <span
          class="APrint print"
          style="border-bottom: 2px solid #000; padding: 0 60px"
          ><span class="print printMe">{{ trxDate }} </span
        ></span>
      </div>
      <div
        style="width: 100%; overflow: hidden; padding: 0 5px; overflow: hidden"
      >
        <div style="width: 15%; float: left; margin: 0 5px 0 0">
          <div
            class="APrint print"
            style="
              float: left;
              width: 35px;
              border: 2px solid #000;
              padding: 0;
              font-size: 12px;
              text-align: center;
            "
          >
            <span class="print printMe">✔</span>
          </div>
          <div
            class="APrint print"
            style="float: left; width: 48%; margin: 0 0 0 10px"
          >
            <span style="font-size: 15px">CASH</span>
          </div>
        </div>
        <div style="width: 22%; float: left; margin: 0 5px 0 0">
          <div
            class="APrint print"
            style="
              float: left;
              width: 35px;
              border: 2px solid #000;
              padding: 0;
              font-size: 12px;
              text-align: center;
            "
          >
            <span class="print">&nbsp;</span>
          </div>
          <div
            class="APrint print"
            style="float: left; width: 50%; margin: 0 0 0 10px"
          >
            <span style="font-size: 15px">CHECK-BANK</span>
          </div>
        </div>
        <div style="width: 20%; float: left; margin: 0 5px 0 0">
          <div
            style="width: 100%; border-bottom: 2px solid #000; padding: 0"
            class="APrint print"
          >
            <!--&nbsp;--><span class="print">&nbsp;</span>
          </div>
        </div>
        <div style="width: 40%; float: right; margin: 0">
          <div class="APrint print" style="float: left; width: 32%; margin: 0">
            <span style="font-size: 15px">CHECK No.:</span>
          </div>
          <div
            class="APrint print"
            style="
              float: left;
              width: 68%;
              border-bottom: 2px solid #000;
              padding: 0;
              text-align: center;
            "
          >
            <!--&nbsp;--><span class="print">&nbsp;</span>
          </div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          overflow: hidden;
          padding: 0 5px 2px 5px;
          overflow: hidden;
          font-size: 12px;
        "
      >
        <div class="APrint print" style="width: 21%; float: left">
          Received from
        </div>
        <div
          class="APrint print"
          style="width: 79%; float: left; border-bottom: 2px solid #000"
        >
          <span class="print printMe">{{ customerName }}</span>
        </div>

        <div class="APrint print" style="width: 21%; float: left">Address</div>
        <div
          class="APrint print"
          style="width: 79%; float: left; border-bottom: 2px solid #000"
        >
          <span class="print printMe">{{ customerAddress }}</span>
        </div>

        <div class="APrint print" style="width: 21%; float: left">TIN</div>
        <div
          class="APrint print"
          style="width: 19%; float: left; border-bottom: 2px solid #000"
        >
          <span class="print">&nbsp;</span
          ><!--TIN-->
        </div>
        <div class="APrint print" style="width: 20%; float: left">
          Business Style(if any)
        </div>
        <div
          class="APrint print"
          style="width: 30%; float: left; border-bottom: 2px solid #000"
        >
          <span class="print">&nbsp;</span
          ><!--Business Style-->
        </div>

        <div class="APrint print" style="width: 21%; float: left">
          the amount of
        </div>
        <div
          class="APrint print"
          style="width: 79%; float: left; border-bottom: 2px solid #000"
        >
          <span class="print printMe" id="divVal"
            >{{ amountInWords }} ONLY - (P {{ totalAmount }})</span
          ><!--the amount of-->
        </div>

        <div class="APrint print" style="width: 21%; float: left">
          in partial/full payment of
        </div>
        <div
          class="APrint print"
          style="width: 79%; float: left; border-bottom: 2px solid #000"
        >
          <span class="print printMe">{{ particulars }}</span>
        </div>

        <div class="APrint print" style="width: 21%; float: left">
          OSCA/PWD ID No.
        </div>
        <div
          class="APrint print"
          style="width: 29%; float: left; border-bottom: 2px solid #000"
        >
          <span class="print">&nbsp;</span
          ><!--OSCA/PWD ID No.-->
        </div>
        <div class="APrint print" style="width: 20%; float: left">
          Cardholder's Signature
        </div>
        <div
          class="APrint print"
          style="width: 30%; float: left; border-bottom: 2px solid #000"
        >
          <span class="print">&nbsp;</span
          ><!--Cardholder's Signature-->
        </div>

        <div class="APrint print" style="width: 21%; float: left">
          SC/PWD TIN.
        </div>
        <div
          class="APrint print"
          style="width: 29%; float: left; border-bottom: 2px solid #000"
        >
          <span class="print">&nbsp;</span
          ><!--SC/PWD TIN.-->
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div
      class="row"
      style="
        margin-left: 0px !important;
        width: 695px;
        border: 3px solid #000;
        overflow: hidden;
        padding: 0;
      "
    >
      <div
        class="bor_right"
        style="border-right: 5px solid #000; float: left; width: 50%"
      >
        <div class="APrint print" style="width: 100%; text-align: center">
          <span style="font-size: 15px; font-weight: bold"
            >Payment Details</span
          >
        </div>
        <div class="APrint print" style="float: left; width: 40%">
          <span style="font-size: 15px">Principal:</span>
        </div>
        <div
          class="APrint print"
          style="float: left; width: 60%; text-align: right"
        >
          <div
            style="border-bottom: 2px solid #000; width: 80%; margin: 0 auto"
            id="divPrincipal"
          >
            <span class="printMe">P {{ principalAmount }}</span>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="APrint print" style="float: left; width: 40%">
          <span style="font-size: 15px">Charge:</span>
        </div>
        <div
          class="APrint print"
          style="float: left; width: 60%; text-align: right"
        >
          <div
            style="border-bottom: 2px solid #000; width: 80%; margin: 0 auto"
            id="divSC"
          >
            <span class="printMe">P {{ serviceCharge }}</span>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="APrint print" style="float: left; width: 40%">
          <span style="font-size: 15px">Others:</span>
        </div>
        <div
          class="APrint print"
          style="float: left; width: 60%; text-align: right"
        >
          <div
            style="border-bottom: 2px solid #000; width: 80%; margin: 0 auto"
            id="divOthers"
          >
            <span class="printMe">P {{ otherCharge }}</span>
          </div>
        </div>
        <div class="clearfix"></div>
        <div
          class="APrint print"
          style="float: left; width: 40%; padding: 0 0 2px 0"
        >
          <span style="font-size: 15px; font-weight: bold">Total:</span>
        </div>
        <div
          class="APrint print"
          style="float: left; width: 60%; text-align: right"
        >
          <div
            style="border-bottom: 2px solid #000; width: 80%; margin: 0 auto"
            id="divTotal"
          >
            <span class="printMe">P {{ totalAmount }}</span>
          </div>
        </div>
      </div>
      <div style="float: left; width: 50%">
        <div
          class="APrint print"
          style="width: 100%; text-align: center; padding: 10px 0"
        >
          <span style="font-weight: bold">Received by:</span>
        </div>
        <!-- <div class="signature">
          <img class="printMe" v-if="this.$store.state.auth.currUser.userSignature" :src="this.$store.state.auth.currUser.userSignature" alt="">
        </div> -->
        <div
          class="APrint print"
          style="
            width: 70%;
            margin: 0 auto;
            text-align: center;
            border-bottom: 2px solid;
            height: 45px;
            line-height: 4;
          "
        >
          <span class="print printMe">{{ userName }}</span>
        </div>
        <div class="APrint print" style="width: 100%; text-align: center">
          <span>Signature Over Printed Name</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import writtenNumber from 'written-number'
import { format, parseISO } from 'date-fns'
import numeral from 'numeral'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  props: {
    printData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      locationName: '',
      formNumber: '',
      transactionDate: '',
      amountInWords: '',
      totalAmount: '0.00',
      principalAmount: '0.00',
      serviceCharge: '0.00',
      otherCharge: '0.00',
      trxDate: '',
      userName: '',
      customerName: '',
      customerAddress: '',
      particulars: ''
    }
  },
  async beforeMount () {
    if (this.$route.query.ref && this.$route.query.trx_type && this.$route.query.type) {
      if (this.printData) {
        this.locationName = this.$store.state.auth.currUser.location_name
        this.formNumber = this.printData.form_number
        this.transactionDate = this.printData.trx_date
        this.totalAmount = numeral(this.printData.total_amount).format('0,0.00')
        if (Object.prototype.hasOwnProperty.call(this.printData, 'service_charge')) {
          this.serviceCharge = numeral(this.printData.service_charge).format('0,0.00')
        }
        if (Object.prototype.hasOwnProperty.call(this.printData, 'wu_total_charge')) {
          this.serviceCharge = numeral(this.printData.wu_total_charge).format('0,0.00')
        }
        this.principalAmount = numeral(this.printData.principal_amount).format('0,0.00')
        this.amountInWords = `${writtenNumber(
          this.printData.total_amount
        )} PESOS`.toUpperCase()
        this.trxDate = format(new Date(parseISO(this.printData.trx_date)), 'MM/dd/yyyy')
        this.userName = this.printData.user_fullname
        this.customerName = this.printData.customer_name
        this.customerAddress = this.printData.address
        this.particulars = this.printData.particulars

        if (this.$route.query.trx_type === 'eload') {
          this.principalAmount = numeral(this.printData.amount).format('0,0.00')
          this.serviceCharge = numeral(this.printData.charges).format('0,0.00')
          this.totalAmount = numeral(this.printData.total_amount).format('0,0.00')
        }
      } else {
        window.close()
      }
    } else {
      window.close()
    }
  }
}
</script>

<style scoped>
html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
}
input {
  border: none;
  width: 100%;
}
p {
  margin: 0 !important;
}
.txtsize_15 {
  font-size: 15px;
  margin: 0 !important;
}
.input-lg {
  padding: 22px 0 0 0 !important;
  font-size: 13px !important;
  text-align: center;
  font-weight: bold;
}
#main {
  margin-top: 10px;
}
#main div.row {
  border: 5px solid #000;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
#main div.row2 {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  width: 816px;
}
#OAR {
  height: 528px;
  width: 816px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  padding: 3px 80px;
}
#OR {
  height: 520px;
  width: 816px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  padding: 3px 10px;
}
#voucher {
  height: 528px;
  width: 816px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  padding: 3px 18px;
}
.underline {
  border-radius: 0 !important;
  border-bottom: 1px solid #000;
}
.padd_zero {
  padding: 0 !important;
}
.padd_two {
  padding: 0 1px !important;
}
.padd_15 {
  padding: 0 15px !important;
}
div.bor_0 {
  border: 1px solid #000;
  border-left: none;
  border-bottom: none;
}
.bor_1,
div.bor_1 {
  border: 1px solid #000 !important;
  font-size: 12px;
}
.bor_2,
div.bor_2 {
  border: 1px solid #000 !important;
}

.wd_144 {
  width: 144px;
}
.wd_145 {
  width: 145px;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 3px !important;
  border: 1px solid #000 !important;
}
table.dataTable tbody tr td {
  text-align: right !important;
}
table.dataTable tbody tr td:nth-child(1) {
  text-align: left !important;
}
table.dataTable tbody tr:last-child td:nth-child(1) {
  font-weight: bold !important;
}

@media print {
  #dateor {
    position: absolute;
  }
  .noprint {
    visibility: hidden;
  }
  .print {
    visibility: visible;
    font-size: 13px;
  }
  #dateprint {
    padding: 0 28px;
  }
  .bor_1,
  div.bor_1 {
    border: 1px solid #fff !important;
    font-size: 12px;
  }
  .bor_2,
  div.bor_2 {
    border: 1px solid #fff !important;
  }
  #topdiv {
    height: 43px;
    width: 760px;
    overflow: hidden;
    padding: 10px 1px 0 1px;
  }
  #OR #topdiv {
    height: 33px;
    width: 760px;
    overflow: hidden;
    padding: 10px 1px 0 1px;
    margin-left: 50px;
  }
  #OR {
    width: 835px;
    margin: 0px;
  }
  #OAR div#topdiv {
    width: 695px;
    border: 5px solid #fff;
    overflow: hidden;
    padding: 0;
    margin: 15px auto 0 auto;
  }
  #OAR div.row {
    margin: 5px auto;
    width: 695px;
    border: 5px solid #fff;
    overflow: hidden;
    padding: 0;
  }
  #OAR div.row div.bor_right {
    border-right: 5px solid #fff;
  }
  #OAR input {
    border-radius: 0;
    border-top: none;
    border-bottom: 1px solid #fff;
    border-left: none;
    border-right: none;
    box-shadow: none;
    width: 75%;
  }
  #logo {
    visibility: hidden;
    overflow: hidden;
    margin-left: 60px;
    width: 207px;
    float: left;
  }
  #logo img {
    width: 100%;
  }
  #address {
    width: 246px;
    float: left;
    text-align: center;
    font-size: 12px;
    line-height: 1.5em;
    overflow: hidden;
    visibility: hidden;
  }
  #numDate {
    width: 215px;
    height: 15px;
    float: right;
    padding-top: 0px;
  }
  #number {
    border: none;
  }
  #date {
    width: 190px;
    margin: 0px 0 0 0;
    padding-top: 0px;
    padding-left: 200px;
    overflow: hidden;
    float: right;
  }
  #date div.padd_zero:nth-child(2) {
    float: right;
    border: none;
  }
  #middleDiv {
    width: 830px;
    overflow: hidden;
    padding: 65px 10px;
  }
  #middleDiv_a {
    float: left;
    width: 260px;
    margin-right: 22px;
  }
  .underline {
    border-radius: 0 !important;
    border-bottom: 1px solid #fff;
  }
  .inputOAR {
    visibility: hidden;
  }
  .orDiv {
    float: left;
  }
  #orbody {
    margin: 7px 0px;
    height: 205px;
    /*width: 505px*/
  }
  #avp {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }
  #cash {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }
  #cash2 {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  /*    #checkedbox{
        position: static;
        padding-top: 15px !important;
        height: 42px !important;
        padding-left:0px !important;
        margin-left:0px !important;
    }*/
  #OAR {
    width: 830px !important;
    padding-left: 0px !important;
  }
  #checkedbox {
    padding-top: 17px !important;
    height: 42px !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  #printOARdiv {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  #voucher {
    height: 528px;
    width: 780px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    padding-left: 0;
    margin-left: 0;
  }
}

@media screen {
  .signature {
    position:absolute;
    bottom: 10px;
  }
  #topdiv {
    height: 135px;
    width: 750px;
    overflow: hidden;
    padding: 10px 1px 0 1px;
  }
  #OAR div#topdiv {
    width: 695px;
    border: 5px solid #000;
    overflow: hidden;
    padding: 0;
    margin: 0px auto 0 auto;
  }
  #OAR div.row {
    margin: 5px auto;
    width: 695px;
    border: 5px solid #000;
    overflow: hidden;
    padding: 0;
  }
  #OAR div.row div.bor_right {
    border-right: 5px solid #000;
  }
  #OAR input {
    border-radius: 0;
    border-top: none;
    border-bottom: 1px solid #000;
    border-left: none;
    border-right: none;
    box-shadow: none;
    width: 75%;
  }
  #logo {
    overflow: hidden;
    margin-left: 60px;
    width: 207px;
    float: left;
  }
  #logo img {
    width: 205px;
  }
  #address {
    width: 246px;
    float: left;
    text-align: center;
    font-size: 12px;
    line-height: 1.5em;
    overflow: hidden;
  }

  #numDate {
    width: 215px;
    height: 37px;
    float: right;
  }
  #number {
    border: 2px solid #000;
  }
  #date {
    width: 190px;
    margin: 30px 0 0 0;
    overflow: hidden;
    float: right;
  }
  #date div.padd_zero:nth-child(2) {
    float: right;
    border-bottom: 2px solid #000;
  }
  #middleDiv {
    width: 750px;
    overflow: hidden;
    padding: 0 1px 10px 60px;
  }
  #middleDiv_a {
    float: left;
    width: 260px;
  }
  .underline {
    border-radius: 0 !important;
    border-bottom: 1px solid #000;
  }
}

/*==============COC===============*/
#coc {
  height: 480px;
  width: 100%;
  border: 2px solid #000;
  padding: 20px;
  font-size: 10.3px;
  overflow: hidden;
}
#coc_inner {
  padding: 2px;
  overflow: hidden;
}

#coc_inner div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
#coc_inner div h2 {
  margin: 0;
}
div.innDiv {
  width: 100%;
  overflow: hidden;
}
div.innDiv:nth-child(2) {
  margin: 10px auto;
}
div.Div33 {
  width: 33%;
  float: left;
}
div.Div50 {
  float: left;
  width: 50%;
}
div.innDiv table {
  border: 2px solid #000;
}
div.innDiv table tbody tr:first-child {
  background-color: #424242;
}
div.innDiv table tbody tr:first-child th {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #000;
}
div.innDiv table tbody tr td {
  padding: 1px 15px;
  font-weight: bold;
}
div.innDiv table tbody tr td:last-child {
  border-left: 2px solid #000;
  text-align: center;
}
@media print {
  *:not(.printMe) {
    visibility: hidden;
  }

  .signature{
    position:absolute;
    bottom: -80px;
  }
  .noprint {
    visibility: hidden;
  }
  *.printMe {
    visibility: visible !important;
  }
  #OR .print {
    visibility: visible;
    font-size: 16px;
  }
  #dateprint {
    position: fixed;
  }
  #tellerprint {
    padding-top: 20px;
    padding-left: 40px;
    position: fixed;
  }
  .bor_1,
  div.bor_1 {
    visibility: hidden;
    border: 1px solid #fff !important;
    font-size: 13px;
  }
  .bor_2,
  div.bor_2 {
    /*visibility: hidden;*/
    border: 1px solid #fff !important;
  }
  #topdiv {
    height: 135px;
    width: 750px;
    overflow: hidden;
    padding: 10px 1px 0 1px;
  }
  #OAR div#topdiv {
    width: 695px;
    border: 5px solid #fff;
    overflow: hidden;
    padding: 0;
    margin: 0px auto 0 auto;
  }
  #OAR div.row {
    margin: 5px auto;
    width: 695px;
    border: 5px solid #fff;
    overflow: hidden;
    padding: 0;
  }
  #OAR div.row div.bor_right {
    border-right: 5px solid #fff;
  }
  #OAR input {
    border-radius: 0;
    border-top: none;
    border-bottom: 1px solid #fff;
    border-left: none;
    border-right: none;
    box-shadow: none;
    width: 75%;
  }
  #logo {
    visibility: hidden;
    overflow: hidden;
    margin-left: 60px;
    width: 207px;
    float: left;
  }
  #logo img {
    width: 100%;
  }
  #address {
    width: 246px;
    float: left;
    text-align: center;
    font-size: 12px;
    line-height: 1.5em;
    overflow: hidden;
    visibility: hidden;
  }
  #numDate {
    width: 215px;
    height: 37px;
    float: right;
  }
  #number {
    border: none;
  }
  #date {
    width: 190px;
    margin: 30px 0 0 0;
    padding-left: 65px !important;
    overflow: hidden;
    float: right;
  }
  #date div.padd_zero:nth-child(2) {
    float: right;
    border: none;
  }
  #middleDiv {
    width: 750px;
    overflow: hidden;
    padding: 117px 0px 0px 0px;
    margin-left: 0px;
  }
  #middleDiv_a {
    float: left;
    width: 260px;
    margin-right: 40px;
  }
  .underline {
    border-radius: 0 !important;
    border-bottom: 1px solid #fff;
  }
  .inputOAR {
    visibility: hidden;
  }
  #OR {
    padding-left: 170px;
    width: 1000px;
    height: 565px;
  }
  #orbody {
    padding-top: 25px !important;
    padding-left: 35px !important;
    height: 255px;
  }
  #avp {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }
  #cash {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }
  #cash2 {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  #cash2 {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  #checkedbox {
    padding-top: 15px !important;
    height: 42px !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  #OAR {
    width: 816px;
    padding-left: 3px 80px !important ;
  }
  #custdetails {
    padding-left: 100px !important;
  }
}
</style>
