import api from '@/api'

export default {
  getLocationLimit () {
    return api.get('/v1/maintenance/api/trxlimit/location-default')
  },
  saveLocationLimit (payload) {
    return api.post('/v1/maintenance/api/trxlimit/location-default', payload)
  },
  bulkSaveLocationLimit (payload) {
    return api.post('/v1/maintenance/api/trxlimit/location-default/bulk', payload)
  },
  bulkValidateLocationLimit (payload) {
    return api.post('/v1/maintenance/api/trxlimit/location-default/validate', payload)
  },
  updateLocationLimit (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/location-default/${id}`, payload)
  },
  deleteLocationLimit (id, userID) {
    return api.delete(`/v1/maintenance/api/trxlimit/location-default/${id}/${userID}`)
  },
  getCustomerLimit () {
    return api.get('/v1/maintenance/api/trxlimit/customer-default')
  },
  saveCustomerLimit (payload) {
    return api.post('/v1/maintenance/api/trxlimit/customer-default', payload)
  },
  updateCustomerLimit (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/customer-default/${id}`, payload)
  },
  deleteCustomerLimit (id, userID) {
    return api.delete(`/v1/maintenance/api/trxlimit/customer-default/${id}/${userID}`)
  },
  getApprovers () {
    return api.get('/v1/maintenance/api/trxlimit/approvers')
  },
  getApprover (id) {
    return api.get(`/v1/maintenance/api/trxlimit/approvers/userid?user_id=${id}`)
  },
  saveApprover (payload) {
    return api.post('/v1/maintenance/api/trxlimit/approvers', payload)
  },
  updateApprover (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/approvers/${id}`, payload)
  },
  deleteApprover (id) {
    return api.delete(`/v1/maintenance/api/trxlimit/approvers/${id}`)
  },
  getIncreaseLocationRequests () {
    return api.get('/v1/maintenance/api/trxlimit/location-increase?')
  },
  getIncreaseCustomerRequests () {
    return api.get('/v1/maintenance/api/trxlimit/customer-increase')
  },
  saveIncreaseLocationLimit (payload) {
    return api.post('/v1/maintenance/api/trxlimit/location-increase', payload)
  },
  saveIncreaseCustomerLimit (payload) {
    return api.post('/v1/maintenance/api/trxlimit/customer-increase', payload)
  },
  updateIncreaseLocationLimit (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/location-increase/${id}`, payload)
  },
  updateIncreaseCustomerLimit (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/customer-increase/${id}`, payload)
  },
  deleteIncreaseLocationLimit (id, userID) {
    return api.delete(`/v1/maintenance/api/trxlimit/location-increase/${id}/${userID}`)
  },
  deleteIncreaseCustomerLimit (id, userID) {
    return api.delete(`/v1/maintenance/api/trxlimit/customer-increase/${id}/${userID}`)
  },
  locationIncreaseReview (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/location-increase/review/${id}`, payload)
  },
  locationIncreaseApprove (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/location-increase/approve/${id}`, payload)
  },
  locationIncreaseDisapprove (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/location-increase/disapprove/${id}`, payload)
  },
  customerIncreaseReview (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/customer-increase/review/${id}`, payload)
  },
  customerIncreaseApprove (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/customer-increase/approve/${id}`, payload)
  },
  customerIncreaseDisapprove (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/customer-increase/disapprove/${id}`, payload)
  },
  locationApprovalReview (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/location-default/review/${id}`, payload)
  },
  locationApprovalApprove (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/location-default/approve/${id}`, payload)
  },
  locationApprovalDisapprove (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/location-default/disapprove/${id}`, payload)
  },
  customerApprovalReview (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/customer-default/review/${id}`, payload)
  },
  customerApprovalApprove (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/customer-default/approve/${id}`, payload)
  },
  customerApprovalDisapprove (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/customer-default/disapprove/${id}`, payload)
  },
  checkLimit (payload) {
    return api.post('/v1/maintenance/api/trxlimit/threshold', payload)
  },
  getProviders () {
    return api.get('/v1/maintenance/api/trxlimit/providers')
  },
  getProvider (id) {
    return api.get(`/v1/maintenance/api/trxlimit/providers/userid?user_id=${id}`)
  },
  saveProvider (payload) {
    return api.post('/v1/maintenance/api/trxlimit/providers', payload)
  },
  updateProvider (id, payload) {
    return api.put(`/v1/maintenance/api/trxlimit/providers/${id}`, payload)
  },
  deleteProvider (id) {
    return api.delete(`/v1/maintenance/api/trxlimit/providers/${id}`)
  },
  getLocationDashboardData (query) {
    return api.get(`/v1/maintenance/api/trxlimit/dashboard/location-request?${query}`)
  },
  getCustomerDashboardData (query) {
    return api.get(`/v1/maintenance/api/trxlimit/dashboard/customer-request?${query}`)
  },
  getLocationLimitsReportData (query) {
    return api.get(`/v1/reports/api/reports/trxlimit/location-limits?${query}`)
  },
  getCustomerLimitsReportData (query) {
    return api.get(`/v1/reports/api/reports/trxlimit/customer-limits?${query}`)
  },
  getRequestLogsReportData (query) {
    return api.get(`/v1/reports/api/reports/trxlimit/request-logs?${query}`)
  },
  getFlaggedTransactionReportData () {
    return api.get('/v1/maintenance/api/operationlimit/flagged/reports')
  },
  saveFlaggedTransaction (payload) {
    return api.post('/v1/maintenance/api/operationlimit/flagged', payload)
  }
}
