<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
        class="ph-breadcrumbs"
      ></v-breadcrumbs>
      <v-row class="ph-transaction-container">
        <v-col cols="12" v-if="showSendoutFulfillSearchForm">
          <SendMoneyFulfillSearch />
        </v-col>
        <v-col cols="12" v-if="showSendoutFulfillResultForm">
          <SendMoneyFulfillForm />
        </v-col>
        <v-col cols="12" v-if="showSuccessSendoutFulfillScreen">
          <SendMoneyFulfillSuccess />
        </v-col>
      </v-row>
      <SendMoneyFulfillSearchResult />
    </v-container>
 </div>
</template>
<script>
import SendMoneyFulfillSearch from '@/components/wu/SendMoneyFulfillSearch'
import SendMoneyFulfillSearchResult from '@/components/wu/SendMoneyFulfillSearchResult'
import SendMoneyFulfillSuccess from '@/components/wu/SendMoneyFulfillSuccess'
import SendMoneyFulfillForm from '@/components/wu/SendMoneyFulfillForm'
import { mapGetters } from 'vuex'

export default {
  name: 'ReceiveMoneyFulfill',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Send Money',
          disabled: false,
          href: '/pera-remit/send'
        },
        {
          text: 'Fulfill',
          disabled: true,
          href: '#'
        }
      ]
    }
  },
  components: {
    SendMoneyFulfillSearch,
    SendMoneyFulfillForm,
    SendMoneyFulfillSuccess,
    SendMoneyFulfillSearchResult
  },
  computed: {
    ...mapGetters({
      showSendoutFulfillSearchForm: 'wu/showSendoutFulfillSearchForm',
      showSendoutFulfillResultForm: 'wu/showSendoutFulfillResultForm',
      showSuccessSendoutFulfillScreen: 'wu/showSuccessSendoutFulfillScreen',
      currUser: 'auth/currUser'
    })
  },
  methods: {
  }
}
</script>
<style scoped>
  .ph-transaction-container .col {
    padding: 15px;
  }

  .ph-breadcrumbs {
    padding: 5px 5px;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }

    .ph-transaction-container .col {
      padding: 12px;
    }

    .ph-breadcrumbs {
      padding: 18px 12px;
      padding-left: 24px;
    }
  }
</style>
