import { endpoints } from '@/api/endpoints'

const eload = endpoints.use('eload')

export default {
  getGrid: ({ commit }, { trxDate, locationID }) => new Promise((resolve, reject) => {
    eload.grid(trxDate, locationID)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  getProducts: ({ commit }) => new Promise((resolve, reject) => {
    eload.products()
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  save: ({ commit }, payload) => new Promise((resolve, reject) => {
    eload.save(payload)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  inquire: ({ commit }, payload) => new Promise((resolve, reject) => {
    eload.inquire(payload)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  assignAllOAR: ({ commit }, payload) => new Promise((resolve, reject) => {
    eload.assignAllOAR(payload)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  resetForm ({ state, commit }) {
    commit('SET_CURRENT_PAGE', 0)
    commit('SET_SUCCESS_DATA', null)

    Object.keys(state.transactionData).forEach(prop => {
      let value = ''

      if (prop === 'product') value = null
      if (prop === 'freeLoad') value = false
      if (prop === 'issueOAR') value = false

      commit('SET_TRANSACTION_DATA', { [prop]: value })
    })
  }
}
