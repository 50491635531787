<template>
  <v-row class="ph-wu-receive-transfer-info">
    <v-col cols="12">
      <div class="ph-id-details-container payout-details-container">
        <div class="ph-id-detail-title">
          <b>Send Fulfill Details</b>
        </div>
        <TransactionProfileCard />
      </div>
      <div class="ph-id-detail-title">
        <b>Sender ID's</b>
      </div>
      <v-row fluid class="ph-id-detail-content">
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="transactionData.id_type"
            @change="syncIdInfo($event)"
            label="ID Type"
            :items="customerIdList"
            item-text="id_type"
            item-value="id_value"
            :return-object="false"
            :rules="transactionData.requiredField"
            color="gray darken-1"
            class="ph-textbox"
            outlined
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="transactionData.id_number"
            :rules="transactionData.requiredField"
            class="ph-textbox"
            outlined
            label="ID Number"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="issueDatePicker"
            v-model="issueDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="ph-textbox"
                color="gray darken-1"
                label="ID Date of Issue"
                append-icon="mdi-calendar"
                hide-details
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="transactionData.requiredField"
                v-model="transactionData.id_issue_date"
              />
            </template>
            <v-date-picker
              v-model="transactionData.id_issue_date"
              :active-picker.sync="activeIssueDatePicker"
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="syncIssueDate"
            />
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="expiryDatePicker"
            v-model="expiryDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="ph-textbox"
                color="gray darken-1"
                label="ID Date of Expiry"
                append-icon="mdi-calendar"
                hide-details
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="transactionData.requiredField"
                v-model="transactionData.id_expiration_date"
              />
            </template>
            <v-date-picker
              v-model="transactionData.id_expiration_date"
              :active-picker.sync="activeExpiryDatePicker"
              :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              @change="syncExpiryDate"
            />
          </v-menu>
        </v-col>
      </v-row>
      <br>
      <div class="ph-transfer-details-container payout-details-container">
        <div class="ph-transfer-detail-title">
          <b>Transaction Info</b>
        </div>
        <v-simple-table class="transfer-info-table">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in transactionInfo"
                :key="item.name"
              >
                <td class="transfer-info-title">{{ item.name }}</td>
                <td class="transfer-info-value">{{ item.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import { avatarColor, avatarInitial } from '@/utils'

export default {
  props: {
    transactionData: Object
  },
  data () {
    return {
      isNotCustomerPage: true,
      activeIssueDatePicker: null,
      issueDatePicker: false,
      activeExpiryDatePicker: null,
      expiryDatePicker: false,
      customerIdList: [],
      transactionInfo: [
        { name: 'First Name', value: '-' },
        { name: 'Middle Name', value: '-' },
        { name: 'Last Name', value: '-' }
      ],
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  computed: {
    ...mapGetters({
      collections: 'collections',
      selectedCustomer: 'customers/selectedCustomer'
    })
  },
  components: {
    TransactionProfileCard
  },
  beforeMount () {
    [
      'getSourceOfFund',
      'getPurposeOfTransaction',
      'getOccupation'
    ].forEach(action => this.$store.dispatch(action))
  },
  mounted () {
    if (this.selectedCustomer) {
      console.log(this.selectedCustomer)

      this.transactionInfo = [
        { name: 'First Name', value: this.selectedCustomer.first_name },
        { name: 'Middle Name', value: this.selectedCustomer.middle_name !== '' ? this.selectedCustomer.middle_name : '-' },
        { name: 'Last Name', value: this.selectedCustomer.last_name }
      ]

      this.customerIdList = []

      const currentIds = JSON.parse(this.selectedCustomer.img)

      for (const [key, value] of Object.entries(currentIds)) {
        const getIds = this.collections.wuIDList.filter(item => {
          return item.id_type === value.id_type
        })

        this.customerIdList.push(getIds[0])
        console.log(this.customerIdList, key)
      }
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    syncIssueDate (date) {
      this.$refs.issueDatePicker.save(date)
    },
    syncExpiryDate (date) {
      this.$refs.expiryDatePicker.save(date)
    },
    syncIdInfo (idValue) {
      const currentIds = JSON.parse(this.selectedCustomer.img)

      const getId = this.collections.wuIDList.filter(item => {
        return item.id_value === idValue
      })

      let getSelectedId = {}

      Object.keys(currentIds).forEach(function (key, index) {
        if (currentIds[key].id_type === getId[0].id_type) {
          getSelectedId = currentIds[key]
        }
      })

      this.transactionData.id_number = getSelectedId.id_number
      this.transactionData.id_issue_date = getSelectedId.issue
      this.transactionData.id_expiration_date = getSelectedId.expiry
      this.transactionData.id_country_of_issue = getSelectedId.country
      this.transactionData.Does_the_ID_have_an_expiration_date = getId[0].hasExpiration
    },
    updateDetails (customerDetails) {
      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    }
  }
}
</script>
<style scoped>
  .custom-card .caption {
    font-family: 'Proxima Nova' !important;
  }
  .ph-id-detail-title {
    margin-bottom: 15px;
  }

  .ph-selected-customer-name {
    font-size: 14px;
  }

  .ph-transfer-details-container {
    margin-bottom: 15px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }
</style>
