import { endpoints } from '@/api/endpoints'

const closeBranch = endpoints.use('closeBranch')

export default {
  getCloseList: ({ commit }, payload) => new Promise((resolve, reject) => {
    closeBranch.getCloseList(payload)
      .then(({ data }) => {
        commit('SET_CLOSE_LIST', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  checkExcessFund: ({ commit }, payload) => new Promise((resolve, reject) => {
    closeBranch.checkExcessFund(payload)
      .then(({ data }) => {
        commit('SET_EXCESS_FUND_INFO', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getExcessFundReasonList: ({ commit }) => new Promise((resolve, reject) => {
    closeBranch.getExcessFundReasonList()
      .then(({ data }) => {
        commit('SET_EXCESS_REASON_LIST', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  excessFundRequest: ({ commit }, payload) => new Promise((resolve, reject) => {
    closeBranch.excessFundRequest(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  updateExcessFundStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    closeBranch.updateExcessFundStatus(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getExcessFundRequestList: ({ commit }, payload) => new Promise((resolve, reject) => {
    closeBranch.getExcessFundRequestList(payload)
      .then(({ data }) => {
        commit('SET_EXCESS_REQUEST_LIST', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  waiveVariance: ({ commit }, payload) => new Promise((resolve, reject) => {
    closeBranch.waiveVariance(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  depositExcessFunds: ({ commit }, payload) => new Promise((resolve, reject) => {
    closeBranch.depositExcessFunds(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
