import { endpoints } from '@/api/endpoints'

const compliance = endpoints.use('compliance')

export default {
  retrieveAdverse: ({ commit }) => new Promise((resolve, reject) => {
    compliance.retrieveAdverse()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  retrieveAdverseItem: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.retrieveAdverseItem(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  addAdverseEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.addAdverseEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  addAdverseBulk: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.addAdverseBulk(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateAdverseEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.updateAdverseEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deleteAdverseEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.deleteAdverseEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  retrievePEP: ({ commit }) => new Promise((resolve, reject) => {
    compliance.retrievePEP()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  retrievePEPItem: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.retrievePEPItem(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  addPEPEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.addPEPEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  addPEPBulk: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.addPEPBulk(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updatePEPEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.updatePEPEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  retrieveNegativeList: ({ commit }) => new Promise((resolve, reject) => {
    compliance.retrieveNegativeList()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  retrieveNegativeListItem: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.retrieveNegativeListItem(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  addNegativeListEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.addNegativeListEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  addNegativeListBulk: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.addNegativeListBulk(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateNegativeListEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.updateNegativeListEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  retrieveComplianceMonitoring: ({ commit }, userCode) => new Promise((resolve, reject) => {
    compliance.retrieveComplianceMonitoring(userCode)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateEddStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.updateEddStatus(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  whitelistCustomer: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.whitelistCustomer(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  searchCustomer: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.searchCustomer(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  searchByCustomer: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.searchByCustomer(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  searchPNA: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.searchPNA(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveRisk: ({ rootState, commit }, payload) => new Promise((resolve, reject) => {
    payload = { ...payload, location_group_id: rootState.auth.currUser.location_group_id }
    compliance.saveRisk(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  riskProfiling: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.riskProfiling(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateRiskScore: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.updateRiskScore(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateEDDUploadStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.updateEDDUploadStatus(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getCustomerInfo: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.getCustomerInfo(payload)
      .then(({ data: { code, result } }) => {
        if (code === 200 && result.length === 1) resolve(result[0])
        else resolve([])
      }).catch((err) => {
        reject(err)
      })
  }),
  getCustomerTransactions: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.getCustomerTransactions(payload)
      .then(({ data: { code, result } }) => {
        if (code === 200) resolve(result)
        else resolve([])
      }).catch((err) => {
        reject(err)
      })
  }),
  getCustomerRiskLog: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.getCustomerRiskLog(payload)
      .then(({ data: { code, result } }) => {
        if (code === 200) resolve(result)
        else resolve([])
      }).catch((err) => {
        reject(err)
      })
  }),
  savePhoto: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.savePhoto(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  searchPEP: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.searchPEP(payload)
      .then(({ data: { code, result } }) => {
        if (code === 200) {
          resolve(result)
        } else resolve([])
      }).catch((err) => {
        reject(err)
      })
  }),
  getPEPCategories: ({ commit }) => new Promise((resolve, reject) => {
    compliance.getPEPCategories()
      .then(({ data: { code, result } }) => {
        if (code === 200) {
          resolve(result.map(item => {
            return {
              text: item.category_name,
              value: item.category_id
            }
          }))
        } else resolve([])
      }).catch((err) => {
        reject(err)
      })
  }),
  getPEPPositions: ({ commit }) => new Promise((resolve, reject) => {
    compliance.getPEPPositions()
      .then(({ data: { code, result } }) => {
        if (code === 200) {
          resolve(result.map(item => {
            return {
              text: item.position_name,
              value: item.position_id,
              category: item.category_id
            }
          }))
        } else resolve([])
      }).catch((err) => {
        reject(err)
      })
  }),
  getPredicateCrimes: ({ commit }) => new Promise((resolve, reject) => {
    compliance.getPredicateCrimes()
      .then(({ data: { code, result } }) => {
        if (code === 200) {
          resolve(result.map(item => {
            return {
              text: item.predicate_crime,
              value: item.predicate_crime
            }
          }))
        } else resolve([])
      }).catch((err) => {
        reject(err)
      })
  }),
  whitelistPEPCustomer: ({ commit }, payload) => new Promise((resolve, reject) => {
    compliance.whitelistPEPCustomer(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getCustomerLogs: ({ commit }, { customerId, query }) => new Promise((resolve, reject) => {
    compliance.getCustomerLogs(customerId, query)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getTransactionLogs: ({ commit }, { query }) => new Promise((resolve, reject) => {
    compliance.getTransactionLogs(query)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  resetPNAAlertDialog: ({ commit }) => {
    commit('UPDATE_PNA_ALERT_DIALOG', {
      isShow: false,
      type: null,
      details: null,
      customer: null,
      isFalsePositive: false
    })
    commit('UPDATE_RISK_DATA', {})
  },
  resetHighRiskDialog: ({ commit }) => {
    commit('UPDATE_HIGH_RISK_DIALOG', {
      isShow: false,
      type: null,
      details: null,
      customer: null
    })
    commit('UPDATE_RISK_DATA', {})
  }
}
