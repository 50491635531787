<template>
  <v-row class="ph-cico-fields">
    <v-col cols="12">
      <v-checkbox
        v-model="transactionData.is_walk_in"
        label="Walk-in"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="transactionData.application_number"
        label="Application Number"
        color="gray darken-1"
        class="ph-textbox"
        :rules="transactionData.pensionAppNumRequiredField"
        outlined
        maxlength="5"
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="transactionData.pa_agent_code"
        label="PA Agent Code"
        color="gray darken-1"
        class="ph-textbox"
        :disabled="transactionData.is_walk_in === true"
        :rules="transactionData.is_walk_in === true ? [] : transactionData.requiredField"
        outlined
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'PeraLoanPension',
  props: {
    transactionData: Object
  }
}
</script>
