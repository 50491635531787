import { endpoints } from '@/api/endpoints'

const cashTransfer = endpoints.use('cashTransfer')

export default {
  retrieveData: ({ commit }, { trxDate, locationId, userId }) => new Promise((resolve, reject) => {
    cashTransfer.retrieveData(trxDate, locationId, userId)
      .then(({ data }) => {
        commit('setItems', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveData: ({ commit }, payload) => new Promise((resolve, reject) => {
    cashTransfer.saveData(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getUsers: ({ commit }, { trxDate, locationId }) => new Promise((resolve, reject) => {
    cashTransfer.getUsers(trxDate, locationId)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
