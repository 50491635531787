import { endpoints } from '@/api/endpoints'

const obo = endpoints.use('obo')

export default {
  getRemoteLocations: ({ commit }, payload) => new Promise((resolve, reject) => {
    obo.remoteLocations(payload)
      .then(({ data }) => {
        commit('SET_REMOTE_LOCATIONS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getRemoteUsers: ({ commit }, payload) => new Promise((resolve, reject) => {
    obo.remoteUsers(payload)
      .then(({ data }) => {
        commit('SET_REMOTE_USERS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getRemoteData: ({ commit }, payload) => new Promise((resolve, reject) => {
    const validatePayload = {
      user_id: payload.user_id,
      otp: payload.otp
    }

    obo.remoteValidate(validatePayload)
      .then(({ data }) => {
        if (data.result) {
          commit('SET_REMOTE_DATA', {
            result: data.result,
            remote_user: payload.user_id,
            remote_location: payload.remote_location,
            ftid: payload.ftid,
            trx_type: payload.trx_type
          })
        }
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
