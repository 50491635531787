<template>
  <v-row class="ph-nonex-adjustment-form-container">
    <v-row class="ma-3">
      <v-col :cols="12" :md="3">
        <v-form ref="adjRequestFields">
          <v-row class="ph-ibt-form">
            <v-col cols="12" v-if="adjRequestForm.type === 'amend'">
              <v-text-field
                v-model="adjRequestForm.receiverFirstName"
                label="Receiver First Name"
                color="gray darken-1"
                class="ph-textbox"
                outlined
                :hide-spin-buttons="true"
                :rules="requiredRules"
              />
            </v-col>
            <v-col cols="12" v-if="adjRequestForm.type === 'amend'">
              <v-text-field
                v-model="adjRequestForm.receiverMiddleName"
                label="Receiver Middle Name"
                color="gray darken-1"
                class="ph-textbox"
                outlined
                :hide-spin-buttons="true"
                :rules="requiredRules"
              />
            </v-col>
            <v-col cols="12" v-if="adjRequestForm.type === 'amend'">
              <v-text-field
                v-model="adjRequestForm.receiverLastName"
                label="Receiver Last Name"
                color="gray darken-1"
                class="ph-textbox"
                outlined
                :hide-spin-buttons="true"
                :rules="requiredRules"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="adjRequestForm.nonConformingParty"
                :items="adjNonConformingList"
                item-text="name"
                item-value="value"
                class="ph-textbox"
                label="Non-conforming Party"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                @change="getSelectedReason(adjRequestForm.reason)"
                v-model="adjRequestForm.reason"
                :items="adjReasons"
                item-text="reason"
                item-value="reason"
                class="ph-textbox"
                label="Reason for Adjustment"
                :rules="requiredRules"
                :return-object="true"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="adjRequestForm.disposition"
                label="Disposition"
                color="gray darken-1"
                class="ph-textbox"
                outlined
                :hide-spin-buttons="true"
                :rules="requiredRules"
                readonly
              />
            </v-col>
            <v-col cols="12">
              <v-row class="ph-ibt-action-buttons">
                <v-col cols="6" class="ph-ibt-action-button">
                  <v-btn
                    color="primary"
                    x-large
                    class="ph-action-button"
                    outlined
                    @click="markAction('cancelRequest')"
                  >
                    Back
                  </v-btn>
                </v-col>
                <v-col cols="6" class="ph-ibt-action-button">
                  <v-btn
                    color="primary"
                    x-large
                    class="ph-action-button ph-action-button-proceed-confirm"
                    elevation="0"
                    @click="markAction('confirm')"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog
      v-model="confirmDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          {{ confirmDialogTitle }}
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          {{ confirmDialogText }}
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            class="ph-action-button"
            @click="doConfirmAction('cancelConfirm')"
          >
            No
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            class="ph-action-button"
            @click="doConfirmAction(confirmType)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { renderToast } from '@/utils'

export default {
  name: 'NonexAdjustmentAmend',
  props: {
    adjRequestForm: Object
  },
  data () {
    return {
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      adjReasons: [],
      adjNonConformingList: [
        { name: 'Branch', value: 'Branch' },
        { name: 'Subagent', value: 'Subagent' },
        { name: 'Client', value: 'Client' }
      ],
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      confirmType: ''
    }
  },
  beforeMount () {
    let setAdjReasons = []

    switch (this.adjRequestForm.type) {
      case 'amend':
        setAdjReasons = [
          { reason: 'Payee is no longer available', disposition: 'Change according to sender\'s request' },
          { reason: 'Client change mind', disposition: 'Change according to sender\'s request' },
          { reason: 'No valid ID', disposition: 'Change Name with valid ID' },
          { reason: 'Expired ID', disposition: 'Change Name with valid ID' },
          { reason: 'Wrong destination country', disposition: 'Change Destination Country (IMT)' },
          { reason: 'Wrong input of receiver\'s name', disposition: 'Input the correct name' },
          { reason: 'Wrong information given by the sender', disposition: 'Change Name with valid ID' },
          { reason: 'Wrong payout currency', disposition: 'Change information based on the sender\'s request' }
        ]
        break
      case 'cancel':
        setAdjReasons = [
          { reason: 'Agent Error', disposition: 'Canceled MT' },
          { reason: 'Business Decision', disposition: 'Refunded back to sender in full amount' },
          { reason: 'Compliance', disposition: 'Refunded back to sender in full amount' },
          { reason: 'Double Send Out Error', disposition: 'Canceled MT' },
          { reason: 'Exceeded Limit', disposition: 'Refunded back to sender in full amount' },
          { reason: 'Interchange name of receiver and sender', disposition: 'Canceled MT' },
          { reason: 'No money collected', disposition: 'Canceled MT' },
          { reason: 'Possible fraud', disposition: 'Refunded back to sender in full amount' },
          { reason: 'Rejected by banking system', disposition: 'Refunded back to sender in full amount' },
          { reason: 'Others (Sender\'s Request)', disposition: 'Refunded back to sender in full amount' },
          { reason: 'Under RTRA', disposition: 'Refunded back to sender in full amount' },
          { reason: 'Wrong destination', disposition: 'Canceled MT' },
          { reason: 'Wrong input of amount', disposition: 'Canceled MT' },
          { reason: 'Wrong sender\'s name', disposition: 'Canceled MT' },
          { reason: 'Test transaction', disposition: 'Canceled MT' }
        ]
        break
      case 'refund':
        setAdjReasons = [
          { reason: 'No valid ID', disposition: 'Refunded back to sender' },
          { reason: 'Sender\'s Request', disposition: 'Refunded back to sender' }
        ]
        break
      case 'unpay':
        setAdjReasons = [
          { reason: 'Agent Error', disposition: 'UNPAY MT' },
          { reason: 'Automatic payout due to system error', disposition: 'UNPAY MT' },
          { reason: 'Client left the branch', disposition: 'UNPAY MT' },
          { reason: 'Discrepancy on payout amount', disposition: 'UNPAY MT' },
          { reason: 'Discrepancy on receiver\'s name', disposition: 'UNPAY MT' },
          { reason: 'Insufficient fund  (peso/dollar)', disposition: 'UNPAY MT' },
          { reason: 'No valid ID', disposition: 'UNPAY MT' },
          { reason: 'Wrong currency (paid in dollar instead of peso)', disposition: 'UNPAY MT' },
          { reason: 'Wrong currency (paid in peso instead of dollar)', disposition: 'UNPAY MT' },
          { reason: 'Wrong input of pay out details', disposition: 'UNPAY MT' },
          { reason: 'Wrong receiver\'s name', disposition: 'UNPAY MT' }
        ]
        break
    }

    this.adjReasons = setAdjReasons
  },
  methods: {
    renderToast,
    getSelectedReason (item) {
      this.adjRequestForm.reason = item.reason
      this.adjRequestForm.disposition = item.disposition
    },
    backToSearchPage () {
      this.$emit('backButton', 'showSearchForm')
    },
    markAction (type) {
      switch (type) {
        case 'cancelRequest':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Cancel Entry'
          this.confirmDialogText = 'Are you sure you want to cancel the request?'
          this.confirmType = 'cancelRequest'
          break
        case 'confirm':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Request for Adjustment'
          this.confirmDialogText = 'Are you sure you want to submit this Adjustment request?'
          this.confirmType = 'confirmRequest'
          break
      }
    },
    doConfirmAction (type) {
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''

      if (type === 'confirmRequest') {
        this.submitRequest()
      } else if (type === 'cancelRequest') {
        this.backToSearchPage()
      }
    },
    submitRequest () {
      if (this.$refs.adjRequestFields.validate()) {
        this.$emit('submitAdjFormRequest')
      } else {
        this.renderToast('error', 'Incomplete Fields', 'Please fill-out the required fields.')
      }
    }
  }
}
</script>
<style scoped>
.ph-ibt-action-button:nth-of-type(2) {
  display: flex;
}

.ph-action-button-proceed-confirm {
  margin-left: auto;
}
</style>
