export default {
  UPDATE_MODULE (state, payload) {
    state.module = {
      ...state.module,
      ...payload
    }
  },
  SET_UPLOADED_DATA (state, payload) {
    state.uploadedData = [...payload]
  },
  SET_UPLOADED_FILE_NAME (state, payload) {
    state.uploadedFileName = payload
  },
  UPDATE_PNA_ALERT_DIALOG (state, payload) {
    state.pnaAlertDialog = {
      ...state.pnaAlertDialog,
      ...payload
    }
  },
  UPDATE_HIGH_RISK_DIALOG (state, payload) {
    state.highRiskDialog = {
      ...state.highRiskDialog,
      ...payload
    }
  },
  UPDATE_RISK_DATA (state, payload) {
    state.riskData = {
      ...state.riskData,
      ...payload
    }
  },
  UPDATE_SELECTED_MONITORING_ITEM (state, payload) {
    state.selectedMonitoringItem = { ...payload }
  },
  UPDATE_SELECTED_WHITELISTING_ITEM (state, payload) {
    state.selectedWhitelistingItem = { ...payload }
  },
  SET_CURRENT_PAGE (state, payload) {
    state.currentPage = payload
  },
  SET_AUDIT_TRAIL_DATA (state, payload) {
    state.auditTrailData = { ...state.auditTrailData, ...payload }
  }
}
