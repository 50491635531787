import api from '@/api'

export default {
  getEnrolledDevices (payload) {
    return api.get('/v1/maintenance/api/device/monitoring')
  },
  searchEnrolledDevice (payload) {
    return api.get(`/v1/maintenance/api/device/search?location_id=${payload.location_id}&status=${payload.status}`)
  },
  updateData (id, payload) {
    return api.put(`/v1/maintenance/api/device/monitoring/${id}`, payload)
  },
  updateStatus (id, payload) {
    return api.put(`/v1/maintenance/api/device/status/${id}`, payload)
  },
  deleteDevice (id) {
    return api.delete(`/v1/maintenance/api/device/monitoring/${id}`)
  }
}
