<template>
  <div class="px-2 px-md-2 mt-md-4 mb-16 pb-8 pb-md-0 mb-md-0">
    <v-btn @click="backToSearch" class="text-capitalize" text small>
      <v-icon class="mr-1" small>
        mdi-arrow-left
      </v-icon>
      Back to search
    </v-btn>
    <v-row no-gutters>
      <v-col class="pr-md-8" :cols="12" :md="8">
        <TransactionProfileCard />
        <strong>Transaction Details</strong>
        <v-row class="mt-md-6 mb-md-5" no-gutters>
          <v-col class="pt-5 pt-md-0 pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
            <v-select
              v-model="transactionData.currencyID"
              :items="currencies"
              item-text="text"
              item-value="value"
              :return-object="false"
              class="ph-textbox"
              label="Currency"
              disabled
              outlined
            />
          </v-col>
          <v-col class="pt-4 pt-md-0 pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
            <v-select
              v-model="transactionData.rateID"
              @change="changeRate"
              :items="rates"
              item-text="category"
              item-value="mc_rate_id"
              :return-object="false"
              class="ph-textbox"
              label="Rate"
              outlined
            >
              <template slot="selection" slot-scope="{ item }">
                {{ getRateText(item) }}
              </template>
              <template slot="item" slot-scope="{ item }">
                {{ getRateText(item) }}
              </template>
            </v-select>
          </v-col>
          <v-col class="pt-4 pt-md-0 pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
            <v-text-field
              v-model="transactionData.receiveAmount"
              @input="calculateReleaseAmount"
              :rules="receiveAmountRule"
              class="ph-textbox"
              :label="receiveLabel"
              :hint="receiveInWords"
              persistent-hint
              type="number"
              hide-spin-buttons
              :disabled="transactionData.type === 'SELL' || !transactionData.rateID"
              outlined
            />
          </v-col>
          <v-col class="pt-4 pt-md-0 pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
            <v-text-field
              v-model="transactionData.releaseAmount"
              @input="calculateReceiveAmount"
              :rules="releaseAmountRule"
              class="ph-textbox"
              :label="releaseLabel"
              :hint="releaseInWords"
              persistent-hint
              type="number"
              hide-spin-buttons
              :disabled="transactionData.type === 'BUY' || !transactionData.rateID"
              outlined
            />
          </v-col>
        </v-row>
      </v-col>
      <TransactionSummary />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { toMoney, toWords } from '@/utils'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import TransactionSummary from '@/components/moneyChange/TransactionSummary.vue'

export default {
  computed: {
    ...mapGetters({
      transactionData: 'moneyChange/transactionData',
      selectedCustomer: 'customers/selectedCustomer',
      currencies: 'moneyChange/currencies',
      rates: 'moneyChange/rates',
      currUser: 'auth/currUser',
      oar: 'oar'
    }),
    receiveLabel () {
      if (this.transactionData.type === 'BUY') {
        return 'Amount to Receive in USD'
      }
      return 'Amount to Receive in PHP'
    },
    receiveInWords () {
      if (this.transactionData.receiveAmount && this.transactionData.type === 'BUY') {
        return toWords(this.transactionData.receiveAmount, 'USD')
      }
      return toWords(this.transactionData.receiveAmount)
    },
    releaseLabel () {
      if (this.transactionData.type === 'BUY') {
        return 'Amount to Release in PHP'
      }
      return 'Amount to Release in USD'
    },
    releaseInWords () {
      if (this.transactionData.releaseAmount && this.transactionData.type === 'SELL') {
        return toWords(this.transactionData.releaseAmount, 'USD')
      }
      return toWords(this.transactionData.releaseAmount)
    }
  },
  components: {
    TransactionProfileCard,
    TransactionSummary
  },
  data () {
    return {
      receiveAmountRule: [],
      releaseAmountRule: []
    }
  },
  methods: {
    changeRate () {
      if (this.transactionData.type === 'BUY') {
        this.calculateReleaseAmount()
      } else {
        this.calculateReceiveAmount()
      }
    },
    backToSearch () {
      this.$store.dispatch('moneyChange/resetForm')
      this.$store.commit('moneyChange/SET_CURRENT_PAGE', 0)
    },
    getRateText (item) {
      if (this.transactionData.type === 'BUY') {
        return `${item.category} - ${toMoney(item.buy_am_rate)}`
      }
      return `${item.category} (${toMoney(item.sell_am_rate)})`
    },
    calculateReleaseAmount () {
      const { type, rateID, receiveAmount } = this.transactionData

      if (receiveAmount) {
        if (type === 'BUY') {
          const selectedRate = this.rates.filter(item => item.mc_rate_id === rateID)

          if (selectedRate.length === 1) {
            const rate = selectedRate[0]

            if (receiveAmount >= 10000) {
              this.$store.commit('moneyChange/SET_ALERT', {
                isVisible: true,
                type: 'warning',
                message: 'Please receive supporting document from the customer to ensure that this transaction is not a fraud.'
              })
            } else {
              this.$store.commit('moneyChange/SET_ALERT', {
                isVisible: false,
                type: '',
                message: ''
              })
            }

            this.$store.commit('moneyChange/SET_TRANSACTION_DATA', {
              prop: 'releaseAmount',
              value: (parseFloat(receiveAmount) * parseFloat(rate.buy_am_rate).toFixed(2))
            })
          } else {
            // Error
          }
        }
      } else {
        this.$store.commit('moneyChange/SET_TRANSACTION_DATA', {
          prop: 'releaseAmount',
          value: ''
        })
      }
    },
    calculateReceiveAmount () {
      const { type, rateID, releaseAmount } = this.transactionData

      if (releaseAmount) {
        if (type === 'SELL') {
          const selectedRate = this.rates.filter(item => item.mc_rate_id === rateID)

          if (selectedRate.length === 1) {
            const rate = selectedRate[0]
            this.$store.commit('moneyChange/SET_TRANSACTION_DATA', {
              prop: 'receiveAmount',
              value: (parseFloat(releaseAmount) * parseFloat(rate.sell_am_rate))
            })
          } else {
            // Error
          }
        }
      } else {
        this.$store.commit('moneyChange/SET_TRANSACTION_DATA', {
          prop: 'receiveAmount',
          value: ''
        })
      }
    }
  },
  async beforeMount () {
    if (this.transactionData.type === 'BUY') {
      const req = await this.$store.dispatch('moneyChange/getGrid', {
        trxDate: this.currUser.trx_date,
        locationID: this.currUser.location_id
      })

      if (req.code === 200) {
        const locationCode = this.currUser.location_code
        const currentDate = format(new Date(), 'MMddyy')
        const currentCount = String(req.result.length + 1).padStart(3, '0')

        this.$store.commit('moneyChange/SET_TRANSACTION_DATA', {
          prop: 'formNumber',
          value: `${locationCode}${currentDate}${currentCount}`
        })
      } else {
        this.renderToast('error', 'Error', 'Generating reference number failed')
      }
    }
    const rates = await this.$store.dispatch('moneyChange/getRates', {
      trxDate: this.currUser.trx_date,
      locationID: this.currUser.location_id,
      currencyID: this.transactionData.currencyID
    })

    if (rates.code === 200) {
      if (Array.isArray(rates.result)) {
        this.$store.commit('moneyChange/SET_RATES', rates.result)
      } else {
        this.$store.commit('moneyChange/SET_RATES', [rates.result])
      }
    } else {
      // No rates
    }

    if (this.transactionData.type === 'BUY') {
      this.receiveAmountRule = [
        v => v >= 2 || 'Minimum of 2 Dollars'
      ]
      this.releaseAmountRule = []
    } else {
      this.receiveAmountRule = []
      this.releaseAmountRule = [
        v => v >= 16 || 'Minimum of 16 Dollars'
      ]
    }

    this.$store.commit('SET_PAGE_TITLE', `PERA Palit - ${this.transactionData.type}`)
  }
}
</script>
