<template>
  <v-row class="ph-wu-receive-transfer-info">
    <v-col cols="12">
      <div class="ph-id-details-container payout-details-container">
        <div class="ph-id-detail-title">
          <b>Payout Details</b>
        </div>
        <TransactionProfileCard />
        <div class="ph-id-detail-title">
          <b>Receiver ID's</b>
        </div>
        <v-row fluid class="ph-id-detail-content">
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="transactionData.id_type"
              @change="syncIdInfo($event)"
              label="ID Type"
              :items="customerIdList"
              item-text="id_type"
              item-value="id_value"
              :return-object="false"
              :rules="transactionData.requiredField"
              color="gray darken-1"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="transactionData.id_number"
              :rules="transactionData.requiredField"
              class="ph-textbox"
              outlined
              label="ID Number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="issueDatePicker"
              v-model="issueDatePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="ph-textbox"
                  color="gray darken-1"
                  label="ID Date of Issue"
                  append-icon="mdi-calendar"
                  hide-details
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-model="transactionData.id_issue_date"
                />
              </template>
              <v-date-picker
                v-model="transactionData.id_issue_date"
                :active-picker.sync="activeIssueDatePicker"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="1950-01-01"
                @change="syncIssueDate"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              ref="expiryDatePicker"
              v-model="expiryDatePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="ph-textbox"
                  color="gray darken-1"
                  label="ID Date of Expiry"
                  append-icon="mdi-calendar"
                  hide-details
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-model="transactionData.id_expiration_date"
                />
              </template>
              <v-date-picker
                v-model="transactionData.id_expiration_date"
                :active-picker.sync="activeExpiryDatePicker"
                :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                @change="syncExpiryDate"
              />
            </v-menu>
          </v-col>
        </v-row>
        <br>
        <div class="ph-id-detail-title">
          <b>Receiver Details</b>
        </div>
        <v-row fluid class="ph-id-detail-content">
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="transactionData.Gender"
              label="Gender"
              :items="gender"
              :rules="transactionData.requiredField"
              item-text="gender_text"
              item-value="gender_value"
              color="gray darken-1"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="transactionData.occupation"
              label="Occupation"
              :items="collections.occupation"
              :rules="transactionData.requiredField"
              item-text="occupation"
              item-value="occupation_value"
              color="gray darken-1"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="transactionData.Purpose_of_Transaction"
              label="Purpose of Transaction"
              :items="collections.purposeOfTransaction"
              :rules="transactionData.requiredField"
              item-text="purpose"
              item-value="purpose"
              color="gray darken-1"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="transactionData.Source_of_Funds"
              label="Source of Funds"
              :items="collections.sourceOfFund"
              :rules="transactionData.requiredField"
              item-text="source_of_funds"
              item-value="source_of_funds"
              color="gray darken-1"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="transactionData.Relationship_to_Receiver"
              label="Relationship to Receiver"
              :items="collections.relationship"
              :rules="transactionData.requiredField"
              item-text="relationship"
              item-value="relationship_value"
              color="gray darken-1"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="transactionData.Country_of_Birth"
              label="Country of Birth"
              :items="collections.countries"
              :rules="transactionData.requiredField"
              item-text="country"
              item-value="country"
              @change="setNationality"
              color="gray darken-1"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="transactionData.nationality"
              :items="collections.countries"
              item-text="nationality"
              item-value="nationality"
              class="ph-textbox"
              label="Nationality"
              :rules="transactionData.requiredField"
              :return-object="true"
              outlined
            />
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import { avatarColor, avatarInitial } from '@/utils'

export default {
  props: {
    transactionData: Object
  },
  data () {
    return {
      isNotCustomerPage: true,
      activeIssueDatePicker: null,
      issueDatePicker: false,
      activeExpiryDatePicker: null,
      expiryDatePicker: false,
      customerIdList: [],
      gender: [
        { gender_text: 'Select Gender...', gender_value: '' },
        { gender_text: 'Male', gender_value: 'male' },
        { gender_text: 'Female', gender_value: 'female' }
      ],
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  computed: {
    ...mapGetters({
      collections: 'collections',
      selectedCustomer: 'customers/selectedCustomer'
    })
  },
  components: {
    TransactionProfileCard
  },
  watch: {
    expiryDatePicker (val) {
      val && setTimeout(() => (this.activeExpiryDatePicker = 'YEAR'))
    }
  },
  beforeMount () {
    [
      'getSourceOfFund',
      'getPurposeOfTransaction',
      'getOccupation'
    ].forEach(action => this.$store.dispatch(action))
  },
  mounted () {
    this.customerIdList = []
    console.log(this.collections.wuIDList)
    console.log(typeof this.selectedCustomer.img)

    const currentIds = JSON.parse(this.selectedCustomer.img)

    for (const [key, value] of Object.entries(currentIds)) {
      const getIds = this.collections.wuIDList.filter(item => {
        return item.id_type === value.id_type
      })

      this.customerIdList.push(getIds[0])
      console.log(this.customerIdList, key)
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    updateDetails (customerDetails) {
      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    syncIssueDate (date) {
      this.$refs.issueDatePicker.save(date)
    },
    syncExpiryDate (date) {
      this.$refs.expiryDatePicker.save(date)
    },
    syncIdInfo (idValue) {
      const currentIds = JSON.parse(this.selectedCustomer.img)

      const getId = this.collections.wuIDList.filter(item => {
        return item.id_value === idValue
      })

      let getSelectedId = {}

      Object.keys(currentIds).forEach(function (key, index) {
        if (currentIds[key].id_type === getId[0].id_type) {
          getSelectedId = currentIds[key]
        }
      })

      this.transactionData.id_number = getSelectedId.id_number
      this.transactionData.id_issue_date = getSelectedId.issue
      this.transactionData.id_expiration_date = getSelectedId.expiry
      this.transactionData.id_country_of_issue = getSelectedId.country
      this.transactionData.Does_the_ID_have_an_expiration_date = getId[0].hasExpiration
    },
    setNationality (val) {
      // this.transactionData.Country_of_Birth = ''
      const selectedCountry = this.collections.countries.filter(item => item.country === val)
      if (selectedCountry.length === 1) {
        this.transactionData.nationality = selectedCountry[0]
      }
    }
  }
}
</script>
<style scoped>
  .custom-card .caption {
    font-family: 'Proxima Nova' !important;
  }
  .ph-id-detail-title {
    margin-bottom: 15px;
  }

  .ph-selected-customer-name {
    font-size: 14px;
  }
</style>
