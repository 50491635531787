const reportsStructure = state => state.reportsStructure
const remcoDatas = state => state.remcoDatas
const rhDatas = state => state.rhDatas
const userBalanceDatas = state => state.userBalanceDatas

export default {
  reportsStructure,
  remcoDatas,
  rhDatas,
  userBalanceDatas
}
