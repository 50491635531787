<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-card-title class="ph-nonex-datatable-title">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="ph-textbox ph-textbox-grid-search"
                outlined
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="transactions"
              :search="search"
              :calculate-widths="true"
              class="ph-nonex-datatable"
            >
              <template v-slot:[`item.provider`]="{item}">
                <p class="ph-nonex-table-names-text text-amount">
                  {{(item.provider === 'PeraHubCard' || item.provider === 'Regular') ? (item.provider === 'Regular' ? 'Regular EON' : 'PHV Card') : item.provider}}
                </p>
              </template>
              <template v-slot:[`item.trx_type`]="{item}">
                <p class="ph-nonex-table-names-text text-amount">{{(item.trx_type === 'C' || item.trx_type === 'Cash In') ? 'Cash In' : 'Cash Out'}}</p>
              </template>
              <template v-slot:[`item.customer_name`]="{item}">
                <div class="ph-nonex-sender-container">
                  <!-- <v-avatar
                    color="#2563EB"
                    size="24"
                    class="ph-nonex-table-avatar"
                  >
                    <span class="white--text">{{item.sender_name.substring(0, 1)}}</span>
                  </v-avatar> -->
                  <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.customer_name)">
                    <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
                  </v-avatar>
                  <p class="ph-nonex-table-names-text">{{item.customer_name}}</p>
                </div>
              </template>
              <template v-slot:[`item.petnet_trackingno`]="{item}">
                <v-chip
                  class="ma-2 ph-nonex-table-names-text text-amount text-inquire-button"
                  color="primary"
                  v-if="item.status === 0 || (item.status === 1 && item.provider === 'PeraHubCard') || (item.status === 1 && item.provider === 'Regular')"
                  @click="inquireCICOTrx(item)"
                >
                  <v-icon left>
                    mdi-magnify
                  </v-icon>
                  {{item.petnet_trackingno}}
                </v-chip>
                <p v-if="item.provider !== 'Regular' && item.provider !== 'PeraHubCard' && item.status === 1" class="ph-nonex-table-names-text text-amount">{{item.petnet_trackingno}}</p>
              </template>
              <template v-slot:[`item.principal_amount`]="{item}">
                <p class="ph-nonex-table-names-text text-amount">{{item.currency_id == 1 ? 'PHP' : 'USD'}} {{formatAmount(item.principal_amount)}}</p>
              </template>
              <template v-slot:[`item.charges`]="{item}">
                <p class="ph-nonex-table-names-text text-amount">{{item.currency_id == 1 ? 'PHP' : 'USD'}} {{formatAmount(item.charges)}}</p>
              </template>
              <template v-slot:[`item.total_amount`]="{item}">
                <p class="ph-nonex-table-names-text text-amount">{{item.currency_id == 1 ? 'PHP' : 'USD'}} {{formatAmount(item.total_amount)}}</p>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.status === 0">
                  <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.status === 1">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Successful
                </span>
              </template>
              <template v-slot:[`item.action`]="{item}">
                <v-menu
                  bottom
                  left
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.status === 1"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-if="(item.trx_type === 'Cash Out') && checkIfUploaded(item)" @click="reprintVoucherNoWacom(item)">
                      <v-list-item-title>Reprint Voucher</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="(item.trx_type === 'Cash Out') && !checkIfUploaded(item)" @click="retrieveUploaded(item)">
                      <v-list-item-title>Reprint Voucher</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="item.trx_type === 'Cash In' && currUser.location_type === '1'"  @click="printOAR(item)">
                      <v-list-item-title>Print OAR</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-tooltip left v-if="item.status === 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="retryCICOTrx(item)"
                    >
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>Retry</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-speed-dial
        v-model="fab"
        dark
        fixed
        bottom
        right
        class="mb-5"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="#1A2791"
            dark
            fab
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#05ACE5"
              v-bind="attrs"
              v-on="on"
              small
              fab
              to="/pera-cico/cash-out"
            >
              <img src="@/assets/images/cash-out-icon.svg" width="18" />
            </v-btn>
          </template>
          <span>Cash Out</span>
        </v-tooltip>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="#D12C7F"
              v-bind="attrs"
              v-on="on"
              to="/pera-cico/cash-in"
            >
              <v-img class="ph-fulfill-logo" src="@/assets/images/cash-in-icon.svg"></v-img>
            </v-btn>
          </template>
          <span>Cash In</span>
        </v-tooltip>
      </v-speed-dial>
      <!-- Diskartech and PHV Customer OTP -->
      <v-dialog
        v-model="customerOTPDialog"
        persistent
        width="30%"
      >
        <v-card class="rounded-lg">
          <v-card-title class="pb-0">
            <v-spacer />
            <v-btn @click="customerOTPDialog = false" x-small icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="py-md-4 text-center">
            <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
            <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
              Customer OTP
            </h3>
            <p>
              Input your customer's generated OTP Code to continue
            </p>
            <p><i><b>Note:</b> The OTP Code will be provided by your customer.</i></p>
            <br>
            <v-otp-input
              v-model="customerOTP"
              id="customerOTP"
              type="number"
              :disabled="isCustomerOTPLoading"
              @finish="onFinishCustomerOTP"
            />
            <p class="red--text">
              {{ customerOTPMessage }}
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'CICOindex',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera CICO',
          disabled: false,
          href: '/pera-cico'
        }
      ],
      headers: [
        {
          text: '#',
          sortable: true,
          value: 'idtrx_cashin_cashout'
        },
        {
          text: 'Provider',
          sortable: true,
          value: 'provider'
        },
        {
          text: 'Type',
          sortable: true,
          value: 'trx_type'
        },
        {
          text: 'USP Tracking No.',
          sortable: true,
          value: 'petnet_trackingno'
        },
        {
          text: 'Provider\'s No.',
          sortable: true,
          value: 'provider_trackingno'
        },
        {
          text: 'Control No.',
          sortable: true,
          value: 'mobile_no'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'principal_amount'
        },
        {
          text: 'Charges',
          sortable: true,
          value: 'charges'
        },
        {
          text: 'Total',
          sortable: true,
          value: 'total_amount'
        },
        {
          text: 'Customer\'s Name',
          sortable: true,
          value: 'customer_name'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      filteredACRs: [],
      search: '',
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      transition: 'slide-y-reverse-transition',
      customerOTPDialog: false,
      isCustomerOTPLoading: false,
      customerOTPInvalid: false,
      customerOTP: '',
      customerOTPMessage: '',
      successTransactionData: {},
      currentTransactionData: {},
      isPHVCustomerOTPVerified: false,
      currentProvider: '',
      petnetTrackingNo: ''
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'cico/transactions',
      currUser: 'auth/currUser',
      receipt: 'receipt'
    })
  },
  async mounted () {
    const acrs = await this.$store.dispatch('acr/retrieveFilteredACR',
      {
        location_id: this.currUser.location_id,
        trx_date: this.currUser.trx_date.replace(' 00:00:00', ''),
        user_id: this.currUser.user_id
      })
    this.filteredACRs = acrs.data
    await this.getCICOList()
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    async getCICOList () {
      const payload = {
        trxDate: this.currUser.trx_date,
        locationId: this.currUser.location_id
      }

      await this.$store.dispatch('cico/getCICOList', payload)
    },
    async retrieveUploaded (item) {
      let filtered = {}
      filtered = this.filteredACRs.filter(function (e) {
        return e.ref_number === item.petnet_trackingno && e.type === 'vou'
      })

      const payload = {
        file: btoa(filtered[0].bucket_path)
      }
      const res = await this.$store.dispatch('acr/retrieveACRFile', payload)
      var extension = res.result.extension
      var file = Buffer.from(res.result.file, 'base64')
      var blob = ''
      if (extension === 'pdf') {
        blob = new Blob([file], { type: 'application/pdf' })
      } else if (extension === 'jpg') {
        blob = new Blob([file], { type: 'image/jpg' })
      } else if (extension === 'png') {
        blob = new Blob([file], { type: 'image/png' })
      } else if (extension === 'jpeg') {
        blob = new Blob([file], { type: 'image/jpeg' })
      }
      var fileURL = URL.createObjectURL(blob)
      window.open(fileURL, '_blank')
    },
    async reprintVoucherNoWacom (item) {
      console.log(item)
      const voucherPayload = {
        vlocation_name: this.currUser.location_name,
        vref_no: item.petnet_trackingno,
        has_wacom: false,
        vpayee: item.customer_name,
        vparticulars: item.provider + ':' + ' ' + item.trx_type,
        vamount: item.principal_amount,
        fla: `${this.currUser.last_name}, ${this.currUser.first_name}`,
        trx_type: item.trx_type,
        remco_name: item.provider,
        control_number: item.petnetTrackingNo,
        customer_id: item.id_number.toString(),
        location_id: this.currUser.location_id,
        location_name_uploaded: this.currUser.location_name,
        crn: item.id_number,
        uploaded_by: this.currUser.user_id,
        first_name: '-',
        last_name: '-',
        product: item.provider
      }
      const reqVoucher = await this.$store.dispatch('voucher/printVoucher', voucherPayload)
      console.log(reqVoucher)
      var decoded = Buffer.from(reqVoucher.result, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    async inquireCICOTrx (data) {
      let provider = ''
      let trxType = ''

      if (data.provider === 'PeraHubCard') {
        provider = 'PHV'
      } else if (data.provider === 'Regular') {
        provider = 'EON'
      } else if (data.provider === 'DRAGON') {
        provider = 'DRAGONPAY'
      } else {
        provider = data.provider
      }

      if (data.trx_type === 'C' || data.trx_type === 'Cash In') {
        trxType = 'Cash In'
      } else {
        trxType = 'Cash Out'
      }

      const payload = {
        provider: provider.toUpperCase(),
        location_id: data.location_id,
        trx_type: trxType,
        reference_number: data.mobile_no,
        partner_code: 'USP',
        petnet_trackingno: data.petnet_trackingno,
        usp: {
          location_id: data.location_id
        }
      }

      if (data.provider === 'PeraHubCard') {
        payload.principal_amount = data.principal_amount
        payload.trx_date = data.trx_date.replace(' 00:00:00', '')
      }

      await this.$store.dispatch('cico/trxCICOInquire', payload).then(res => {
        if (res.code === 500) {
          this.renderToast('error', 'Inquire Error', res.message)
        } else {
          if (res?.result) {
            this.renderToast('info', 'Inquire Current Status', `This ${trxType} transaction status is ${res.result.status_message}`)
          } else {
            this.renderToast('info', 'Inquire Current Status', `${res.error.message.message}`)
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async retryCICOTrx (data) {
      this.currentTransactionData = data
      this.petnetTrackingNo = data.petnet_trackingno
      this.currentProvider = data.provider.toUpperCase()

      if (this.currUser.location_type !== '1') {
        this.executeRetry(data)
      } else {
        let geteReceipt = {}

        if (data.trx_type === 'C' || data.trx_type === 'Cash In') {
          geteReceipt = await this.$store.dispatch('getReceipt', {
            location_id: this.currUser.location_id,
            type: '2',
            application_id: this.currUser.application_id
          })
        }

        if (!geteReceipt && (data.trx_type === 'C' || data.trx_type === 'Cash In')) {
          this.renderToast('error', 'Retry Error', 'An error has occured in generation of receipt data')
        } else {
          this.executeRetry(data)
        }
      }
    },
    async executeRetry (data) {
      const currentUser = this.currUser
      if (data.provider.toUpperCase() === 'PERAHUBCARD' || data.provider.toUpperCase() === 'REGULAR') {
        const eonPayload = {
          client_reference_no: data.petnet_trackingno,
          source_account: data.mobile_no,
          amount: data.total_amount,
          trx_date: format(new Date(), 'yyyy-MM-dd'),
          user_name: this.currUser.user_name,
          service_fee: data.charges,
          coy: 'usp'
        }

        await this.$store.dispatch('cico/trxEONRetry', eonPayload).then(res => {
          if (res.code >= 200 && res.code <= 299) {
            this.renderToast('info', 'Retry Successful', `${res.message}`)
            if (data.trx_type === 'Cash Out' || data.trx_type === 'D') {
              this.reprintVoucherNoWacom(res.result)
              this.isPHVCustomerOTPVerified = false
              this.petnetTrackingNo = ''
              this.currentTransactionData = {}
              setTimeout(function () {
                this.getCICOList()
              }, 3500)
            } else {
              if (this.currUser.location_type === '1') {
                this.printOAR(data)
              }
              this.getCICOList()
            }
          } else {
            this.renderToast('error', 'Retry Error', res.message)
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        const payload = {
          provider: data.provider.toUpperCase() === 'PERAHUBCARD' ? 'PHV' : data.provider.toUpperCase(),
          location_id: currentUser.location_id,
          location_name: currentUser.location_name,
          trx_type: (data.trx_type === 'D' || data.trx_type !== 'Cash In') ? 'Cash Out' : 'Cash In',
          trx_date: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
          partner_code: 'USP',
          petnet_trackingno: data.petnet_trackingno
        }

        await this.$store.dispatch('cico/trxCICORetry', payload).then(res => {
          if ((data.provider.toUpperCase() === 'DISKARTECH') && res?.result && (data.trx_type === 'Cash Out' || data.trx_type === 'D')) {
            this.customerOTPDialog = true
            this.successTransactionData = res.result
          } else {
            if (res.code >= 200 && res.code <= 299) {
              this.renderToast('info', 'Retry Successful', `${res.message}`)
              if (data.trx_type === 'Cash Out' || data.trx_type === 'D') {
                this.reprintVoucherNoWacom(res.result)
                this.isPHVCustomerOTPVerified = false
                this.petnetTrackingNo = ''
                this.currentTransactionData = {}
                setTimeout(function () {
                  this.getCICOList()
                }, 3500)
              } else {
                if (this.currUser.location_type === '1') {
                  this.generateReceipt(data)
                }
                this.getCICOList()
              }
            } else {
              this.renderToast('error', 'Retry Error', res.message)
            }
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    async generateReceipt (res) {
      const address = res.curr_address
      const barangay = res.curr_barangay
      const city = res.curr_city
      const province = res.curr_province
      const saveReceipt = await this.$store.dispatch('saveReceipt', {
        ereceipt_id: this.receipt.ereceipt_id,
        application_id: this.currUser.application_id,
        application_header_name: this.currUser.branchDetail.header_name,
        application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
        receipt_type: this.receipt.type,
        customer_id: res.customer_id,
        customer_name: res.customer_name,
        customer_tin_no: '',
        customer_address: `${address} ${barangay} ${city} ${province}`,
        customer_email_address: null,
        cashier_id: this.currUser.user_id,
        cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
        oar_receipt_no: this.receipt.oar_receipt_no,
        oar_number: this.receipt.oar_series_no,
        oar_service: `Pera CICO (${res.mobile_no})`,
        oar_currency: 'PHP',
        oar_principal_amount: Number(res.total_amount),
        oar_discount: 0,
        oar_total: Number(res.total_amount),
        grand_total: Number(res.total_amount),
        print_email_hold: 0,
        is_forex_logo: 0
      })

      if (saveReceipt.code === 200) {
        if (saveReceipt.result.receipt_status === 1) {
          renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
        } else if (saveReceipt.result.receipt_status === 2) {
          const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
            application_id: this.currUser.application_id,
            ereceipt_api_id: saveReceipt.result.ereceipt_api_id
          })
          var newBlob = new Blob([res], { type: 'application/pdf' })
          var newFileURL = URL.createObjectURL(newBlob)
          window.open(newFileURL, '_blank')
        } else {
          renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
        }
      } else {
        renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
      }
    },
    checkIfUploaded (item) {
      console.log(item, 'item from table')
      console.log(this.filteredACRs, 'filtered acr')
      var filtered = this.filteredACRs.filter(function (e) {
        return e.ref_number === item.petnet_trackingno
      })
      if (filtered.length !== 0) {
        return false
      } else {
        return true
      }
    },
    // printOAR (data) {
    //   if (data.provider === 'PeraHubCard' || data.provider === 'Regular') {
    //     window.open(`/receipt?ref=${data.form_number}&trx_type=ubp&type=OAR`, '_blank')
    //   } else {
    //     window.open(`/receipt?ref=${data.form_number}&trx_type=cico&type=OAR`, '_blank')
    //   }
    // },
    async printOAR (data) {
      console.log(data)
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: '2',
          or_series_no: '',
          oar_series_no: data.form_number,
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    },
    onFinishCustomerOTP (pin) {
      this.customerOTPMessage = ''
      this.isCustomerOTPLoading = true
      setTimeout(() => {
        this.doVerifyCustomerOTP(pin)
      }, 3500)
    },
    async doVerifyCustomerOTP (pin) {
      this.customerOTP = pin
      if (this.customerOTP && this.customerOTP.length === 6) {
        const currentUser = this.currUser
        const successData = this.successTransactionData

        const otpPayload = {
          location_id: currentUser.location_id,
          location_name: currentUser.location_name,
          partner_code: 'USP',
          petnet_trackingno: this.petnetTrackingNo,
          trx_date: currentUser.trx_date,
          otp: this.customerOTP,
          otp_payload: successData.otp_payload
        }

        if (this.currentProvider === 'PERAHUBCARD') {
          otpPayload.retry_test = true
        }

        await this.$store.dispatch('cico/trxOTPCustomer', otpPayload).then(res => {
          console.log(res)
          if (res.error.message === 'RETRY TEST - ERROR' && this.currentProvider === 'PERAHUBCARD') {
            this.customerOTPInvalid = false
            this.isCustomerOTPLoading = false
            this.customerOTPDialog = false
            this.isPHVCustomerOTPVerified = true
            const that = this
            setTimeout(function () {
              that.retryCICOTrx(that.currentTransactionData)
            }, 1500)
          } else if (res?.error) {
            this.customerOTP = ''
            this.customerOTPInvalid = true
            this.isCustomerOTPLoading = false
            this.customerOTPMessage = 'Incorrect OTP Code, please try again.'
          } else {
            this.customerOTPInvalid = false
            this.isCustomerOTPLoading = false
            this.customerOTPDialog = false
            this.reprintVoucherNoWacom(successData)
            this.renderToast('success', 'Cash-Out Successful', res.message)
            this.successTransactionData = {}
            this.currentTransactionData = {}

            setTimeout(function () {
              location.reload()
            }, 3500)
          }
        })
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    }
  }
}
</script>
<style scoped>
/* .ph-nonex-datatable-title {
  padding-left: 0;
} */

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.7);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

.ph-nonex-table-names-text.text-amount {
  margin-left: 0 !important;
}

.text-inquire-button {
  cursor: pointer;
}

/* .ph-textbox.ph-textbox-grid-search {
  width: 256px;
} */
</style>
