import { endpoints } from '@/api/endpoints'

const bills = endpoints.use('bills')

export default {
  getTransactions: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.getTransactions(payload)
      .then(({ data }) => {
        commit('SET_BILLS_PAYMENT_TRANSACTIONS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getTPARequest: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.getTPARequest(payload)
      .then(({ data }) => {
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getMisc: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.getMisc(payload)
      .then(({ data }) => {
        commit('SET_BILLS_MISC', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getBillers: ({ commit }) => new Promise((resolve, reject) => {
    bills.getBillers()
      .then(({ data }) => {
        commit('SET_BILLS_BILLERS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getBillerInfo: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.getBillerInfo(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getOtherFees: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.getOtherFees(payload)
      .then(({ data }) => {
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  validateTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.validateTrx(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  executeTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.executeTrx(payload)
      .then(({ data }) => {
        commit('SET_BILLS_PAYMENT_RESPONSE_DATA', data)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  inquireTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.inquireTrx(payload)
      .then(({ data }) => {
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  retryTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.retryTrx(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  assignSingleOAR: (context, payload) => new Promise((resolve, reject) => {
    const singleOARPayload = {
      location_id: payload.location_id,
      form_number: payload.form_number
    }

    bills.assignSingleOAR(payload.id, singleOARPayload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  assignMultipleOAR: (context, payload) => new Promise((resolve, reject) => {
    bills.assignMultipleOAR(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getCollectionTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.getCollectionTrx(payload)
      .then(({ data }) => {
        commit('SET_BILLS_COLLECTION_TRANSACTIONS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getLoanProvider: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.getLoanProvider(payload)
      .then(({ data }) => {
        commit('SET_BILLS_COLLECTION_PROVIDERS', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getOtherProvider: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.getOtherProvider(payload)
      .then(({ data }) => {
        commit('SET_BILLS_COLLECTION_PROVIDERS', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  executeCollectionTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.executeCollectionTrx(payload)
      .then(({ data }) => {
        commit('SET_BILLS_COLLECTION_RESPONSE_DATA', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  loanAccountLookup: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.loanAccountLookup(payload)
      .then(({ data }) => {
        commit('SET_BILLS_LOAN_ACCOUNT_NO', payload.account_number)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  generateBillsCollectionVoucher: ({ commit }, payload) => new Promise((resolve, reject) => {
    bills.generateBillsCollectionVoucher(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
