<template>
  <v-row>
    <v-col :cols="12" :md="3">
      <v-text-field
        v-model="search"
        class="ph-textbox"
        placeholder="Search"
        append-icon="mdi-magnify"
        hide-details
        outlined
      />
    </v-col>
    <v-col class="d-flex align-center" :cols="12" :md="9">
      <v-btn
        @click="getData"
        class="text-capitalize font-weight-bold"
        color="primary"
        rounded
        large
        text
      >
        <v-icon class="mr-1">mdi-refresh</v-icon>
        Refresh
      </v-btn>
    </v-col>
    <v-col :cols="12">
      <v-card flat>
        <v-data-table
          @click:row="view"
          :headers="headers"
          :items="data"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:item.max_increase_amount="{ item }">
            {{ toMoney(item.max_increase_amount) }}
          </template>
          <template v-slot:item.status="{ item }">
            <span :class="[getTransactionLimitStatus(item.status).class]">
              <v-icon
                :class="['mr-1', getTransactionLimitStatus(item.status).class]"
                x-small
              >
                mdi-circle
              </v-icon>
              {{ getTransactionLimitStatus(item.status).text }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom v-if="approverLevel === ''">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="view(item)"
                  color="primary"
                  :disabled="item.status !== 1 && item.status !== 4"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom v-if="approverLevel === ''">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="openDeleteDialog(item)"
                  color="primary"
                  :disabled="item.status !== 1 && item.status !== 4"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
            <v-tooltip bottom v-if="approverLevel === 'ROH' || approverLevel === 'CS'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="openApproveDialog(item)"
                  color="primary"
                  :disabled="!isApproveable(item.status)"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-check-circle</v-icon>
                </v-btn>
              </template>
              <span>Approve</span>
            </v-tooltip>
            <v-tooltip bottom v-if="approverLevel === 'ROH' || approverLevel === 'CS'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="openDisapproveDialog(item)"
                  color="red"
                  :disabled="!isApproveable(item.status)"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Disapprove</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog
        v-model="showDeleteDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Delete Request
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            Are you sure you want to delete this increase limit request?
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="danger"
              text
              @click="closeDeleteDialog"
              class="ph-action-button"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="doDelete"
              class="ph-action-button"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showApproveDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Approve Request
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            Are you sure you want to approve this increase limit request?
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="danger"
              text
              @click="closeApproveDialog"
              class="ph-action-button"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="approve"
              class="ph-action-button"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showDisapproveDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Disapprove Request
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            Are you sure you want to disapprove this increase limit request?
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="danger"
              text
              @click="closeDisapproveDialog"
              class="ph-action-button"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="disapprove"
              class="ph-action-button"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { getTransactionLimitStatus, renderToast, toMoney } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      data: 'transactionLimit/increaseLocationRequests',
      approverLevel: 'transactionLimit/approverLevel',
      selectedIncreaseLocationLimit: 'transactionLimit/selectedIncreaseLocationLimit'
    })
  },
  data () {
    return {
      search: '',
      showDeleteDialog: false,
      showApproveDialog: false,
      showDisapproveDialog: false,
      headers: [
        {
          text: 'Branch Name',
          value: 'location_name',
          width: '150px'
        },
        {
          text: 'Provider',
          value: 'provider_name',
          width: '150px'
        },
        {
          text: 'Service Name',
          value: 'service_name',
          width: '150px'
        },
        {
          text: 'Service Type',
          value: 'service_type',
          width: '150px'
        },
        {
          text: 'Current Limit',
          value: 'max_increase_amount',
          width: '150px'
        },
        {
          text: 'Channel',
          value: 'channel',
          width: '150px'
        },
        {
          text: 'Status',
          value: 'status',
          width: '150px'
        },
        {
          text: 'Action',
          value: 'action',
          width: '100px',
          sortable: false
        }
      ]
    }
  },
  methods: {
    openDeleteDialog (data) {
      this.$store.commit('transactionLimit/SET_SELECTED_INCREASE_LOCATION_LIMIT', data)
      this.showDeleteDialog = true
    },
    closeDeleteDialog () {
      this.$store.commit('transactionLimit/SET_SELECTED_INCREASE_LOCATION_LIMIT', null)
      this.showDeleteDialog = false
    },
    async doDelete () {
      if (this.selectedIncreaseLocationLimit) {
        const { code } = await this.$store.dispatch('transactionLimit/deleteIncreaseLocationLimit', {
          id: this.selectedIncreaseLocationLimit.id,
          userID: this.currUser.user_id
        })

        if (code === 200) {
          renderToast('success', 'Successful', 'Location Limit Request has been deleted')
          this.closeDeleteDialog()
          this.getData()
        } else {
          // Add error code
        }
      }
    },
    openApproveDialog (data) {
      this.$store.commit('transactionLimit/SET_SELECTED_INCREASE_LOCATION_LIMIT', data)
      this.showApproveDialog = true
    },
    closeApproveDialog () {
      this.$store.commit('transactionLimit/SET_SELECTED_INCREASE_LOCATION_LIMIT', null)
      this.showApproveDialog = false
    },
    async approve () {
      const action = {
        ROH: 'review',
        CS: 'approve'
      }

      if (action[this.approverLevel]) {
        const { code } = await this.$store.dispatch('transactionLimit/approveIncreaseLocationRequest', {
          type: action[this.approverLevel],
          id: this.selectedIncreaseLocationLimit.id,
          payload: {
            increase_amount: this.selectedIncreaseLocationLimit.max_increase_amount,
            reviewer: this.currUser.user_id,
            approver: this.currUser.user_id,
            remarks: this.selectedIncreaseLocationLimit.remarks
          }
        })

        if (code === 200) {
          renderToast('success', 'Successful', 'Increase Request has been approved')
        } else {
          renderToast('error', 'Failed', 'Unable to approve this request')
        }

        this.getData()
        this.closeApproveDialog()
      } else {
        renderToast('error', 'No Access', 'You are not allowed to make this action')
      }
    },
    openDisapproveDialog (data) {
      this.$store.commit('transactionLimit/SET_SELECTED_INCREASE_LOCATION_LIMIT', data)
      this.showDisapproveDialog = true
    },
    closeDisapproveDialog () {
      this.$store.commit('transactionLimit/SET_SELECTED_INCREASE_LOCATION_LIMIT', null)
      this.showDisapproveDialog = false
    },
    async disapprove () {
      const action = {
        ROH: 'disapprove',
        CS: 'disapprove'
      }

      if (action[this.approverLevel]) {
        const { code } = await this.$store.dispatch('transactionLimit/approveIncreaseLocationRequest', {
          type: action[this.approverLevel],
          id: this.selectedIncreaseLocationLimit.id,
          payload: {
            increase_amount: this.selectedIncreaseLocationLimit.max_increase_amount,
            reviewer: this.currUser.user_id,
            approver: this.currUser.user_id,
            remarks: this.selectedIncreaseLocationLimit.remarks
          }
        })

        if (code === 200) {
          renderToast('success', 'Successful', 'Increase Request has been disapproved')
        } else {
          renderToast('error', 'Failed', 'Unable to disapprove this request')
        }

        this.getData()
        this.closeDisapproveDialog()
      } else {
        renderToast('error', 'No Access', 'You are not allowed to make this action')
      }
    },
    view (data) {
      this.$store.commit('transactionLimit/SET_SELECTED_INCREASE_LOCATION_LIMIT', data)
      this.$router.push(`/transaction-limit/increase-requests/location/${data.id}`)
    },
    async getData () {
      const { code, result } = await this.$store.dispatch('transactionLimit/getIncreaseLocationRequests')

      if (code === 200) {
        this.$store.commit('transactionLimit/SET_INCREASE_LOCATION_REQUESTS', result.filter((item) => {
          if (this.currUser.trx_date === item.date_requested) {
            if (this.approverLevel === '') {
              if (this.currUser.location_id === parseInt(item.location_id)) return item
            } else {
              return item
            }
          }
        }).sort((x, y) => {
          return (new Date(y.created_at).getTime() - new Date(x.created_at).getTime())
        }))
      }
    },
    isApproveable (status) {
      if (this.approverLevel === 'ROH') {
        return (status === 1 || status === 5)
      }

      if (this.approverLevel === 'CS') {
        return (status === 2)
      }

      return false
    },
    getTransactionLimitStatus,
    toMoney
  }
}
</script>
