export default {
  isAuthenticated: false,
  token: '',
  runSession: false,
  showRefreshPrompt: false,
  authTimestamp: {
    timeLoggedIn: '',
    timeToExpire: ''
  },
  currUser: {},
  isOTPpassed: false
}
