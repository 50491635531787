<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row v-if="approverLevel === 'ADMIN' || approverLevel === 'ROH' || approverLevel === 'CS' || approverLevel === ''" class="px-2 pt-md-2 px-md-5 mb-md-12">
      <v-col :cols="12">
        <v-tabs v-model="currentView" class="noBottomLine">
          <v-tab class="font-weight-bold text-subtitle-1 text-capitalize tabText">
            Location
            <v-chip
              v-if="pendingLocationRequests > 0"
              class="ml-1 font-weight-regular tabChipText"
              color="red"
              text-color="white"
              small
            >
              <span v-if="pendingLocationRequests < 100">{{ pendingLocationRequests }}</span>
              <span v-else>99+</span>
            </v-chip>
          </v-tab>
          <v-tab class="font-weight-bold text-subtitle-1 text-capitalize tabText">
            Customer
            <v-chip
              v-if="pendingCustomerRequests > 0"
              class="ml-1 font-weight-regular tabChipText"
              color="red"
              text-color="white"
              small
            >
              <span v-if="pendingCustomerRequests < 100">{{ pendingCustomerRequests }}</span>
              <span v-else>99+</span>
            </v-chip>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col class="pt-4" :cols="12">
        <v-tabs-items v-model="currentView" class="py-2">
          <v-tab-item>
            <IncreaseLocationRequests />
          </v-tab-item>
          <v-tab-item>
            <IncreaseCustomerRequests />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-speed-dial
      v-model="fab"
      v-if="approverLevel === ''"
      bottom
      right
      fixed
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/transaction-limit/increase-requests/customer"
            color="#1A2791"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <v-icon color="white">mdi-account</v-icon>
          </v-btn>
        </template>
        <span>Customer</span>
      </v-tooltip>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/transaction-limit/increase-requests/location"
            color="#1A2791"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
          <v-icon color="white">mdi-map-marker</v-icon>
          </v-btn>
        </template>
        <span>Location</span>
      </v-tooltip>
    </v-speed-dial>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'
import IncreaseLocationRequests from '@/views/TransactionLimit/IncreaseRequests/IncreaseLocationRequests.vue'
import IncreaseCustomerRequests from '@/views/TransactionLimit/IncreaseRequests/IncreaseCustomerRequests.vue'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      approverLevel: 'transactionLimit/approverLevel',
      increaseLocationRequests: 'transactionLimit/increaseLocationRequests',
      increaseCustomerRequests: 'transactionLimit/increaseCustomerRequests'
    }),
    pendingLocationRequests () {
      if (this.approverLevel === '') return this.increaseLocationRequests.filter(item => (item.status === 1 || item.status === 4)).length
      if (this.approverLevel === 'ROH') return this.increaseLocationRequests.filter(item => (item.status === 1 || item.status === 5)).length
      if (this.approverLevel === 'CS') return this.increaseLocationRequests.filter(item => item.status === 2).length
      return 0
    },
    pendingCustomerRequests () {
      if (this.approverLevel === '') return this.increaseCustomerRequests.filter(item => (item.status === 1 || item.status === 4)).length
      if (this.approverLevel === 'ROH') return this.increaseCustomerRequests.filter(item => (item.status === 1 || item.status === 5)).length
      if (this.approverLevel === 'CS') return this.increaseCustomerRequests.filter(item => item.status === 2).length
      return 0
    },
    currentView: {
      get () {
        return this.$store.state.transactionLimit.currentIncreaseRequestsView
      },
      set (val) {
        this.$store.commit('transactionLimit/SET_CURRENT_INCREASE_REQUESTS_VIEW', val)
      }
    }
  },
  components: {
    IncreaseLocationRequests,
    IncreaseCustomerRequests
  },
  data () {
    return {
      fab: false,
      data: [],
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Increase Requests',
          disabled: true
        }
      ]
    }
  },
  methods: {
    async getLocReq () {
      const { code, result } = await this.$store.dispatch('transactionLimit/getIncreaseLocationRequests')

      if (code === 200) {
        this.$store.commit('transactionLimit/SET_INCREASE_LOCATION_REQUESTS', result.filter((item) => {
          if (this.currUser.trx_date === item.date_requested) {
            if (this.approverLevel === '') {
              if (this.currUser.location_id === parseInt(item.location_id)) return item
            } else {
              return item
            }
          }
        }).sort((x, y) => {
          return (new Date(y.created_at).getTime() - new Date(x.created_at).getTime())
        }))
      }
    },
    async getCustReq () {
      const { code, result } = await this.$store.dispatch('transactionLimit/getIncreaseCustomerRequests')

      if (code === 200) {
        this.$store.commit('transactionLimit/SET_INCREASE_CUSTOMER_REQUESTS', result.filter((item) => {
          if (this.currUser.trx_date === item.date_requested) {
            if (this.approverLevel === '') {
              if (this.currUser.location_id === parseInt(item.location_id)) return item
            } else {
              return item
            }
          }
        }).sort((x, y) => {
          return (new Date(y.created_at).getTime() - new Date(x.created_at).getTime())
        }))
      }
    }
  },
  async mounted () {
    const { code, result } = await this.$store.dispatch('transactionLimit/getApprover', this.currUser.user_id)

    if (code === 200) {
      if (result.length > 0) {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', result[0].approver_level)
      } else {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', '')
      }
    }

    if (this.approverLevel !== 'ADMIN' && this.approverLevel !== 'ROH' && this.approverLevel !== 'CS' && this.approverLevel !== '') {
      renderToast('error', 'No access', 'You don\'t have access to this module')
    }

    this.getLocReq()
    this.getCustReq()
  }
}
</script>

<style lang="css" scoped>
.noBottomLine {
  border-bottom: none !important;
}

.tabText {
  font-family: 'Baloo Extra Bold' !important;
  /* min-width: 150px !important; */
  margin-right: 10px !important;
}

.tabChipText {
  font-family: Roboto, Arial, Helvetica, sans-serif !important;
}
</style>
