import { endpoints } from '@/api/endpoints'

const wu = endpoints.use('wu')

export default {
  getPayoutSearchResult: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.receiveMoneySearch(payload.mtcn, payload.currency_code, payload.foreign_ref_no,
      payload.terminal_id, payload.operator_id, payload.location_id)
      .then(({ data }) => {
        commit('SET_CURRENT_TRANSACTION', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getFulfillSearchResult: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.receiveMoneyHoldSearch(payload)
      .then(({ data }) => {
        // commit('SET_CURRENT_TRANSACTION', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  selectFulfillTransaction: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.receiveMoneyHoldSelect(payload)
      .then(({ data }) => {
        // commit('SET_CURRENT_TRANSACTION', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  doPayoutTransaction: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.receiveMoneyPay(payload)
      .then(({ data }) => {
        if (data.result) {
          commit('SET_SHOW_PAYOUT_SUCCESS_DATA', data.result)
          commit('SET_SHOW_PAYOUT_RESULT_FORM', false)
          commit('SET_SHOW_PAYOUT_SUCCESS_SCREEN', true)
        }
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  doFulfillSendTransaction: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.sendMoneyRelease(payload)
      .then(({ data }) => {
        if (data.result) {
          commit('SET_SHOW_SENDOUT_FULFILL_SUCCESS_DATA', data.result)
          commit('SET_SHOW_SENDOUT_FULFILL_RESULT_FORM', false)
          commit('SET_SHOW_SENDOUT_FULFILL_SUCCESS_SCREEN', true)
        }
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  doFulfillPayoutTransaction: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.receiveMoneyRelease(payload)
      .then(({ data }) => {
        if (data.result) {
          commit('SET_SHOW_PAYOUT_FULFILL_SUCCESS_DATA', data.result)
          commit('SET_SHOW_PAYOUT_FULFILL_RESULT_FORM', false)
          commit('SET_SHOW_PAYOUT_FULFILL_SUCCESS_SCREEN', true)
        }
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getF2ZoomResult: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.f2ZoomSearch(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getPayoutStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.getPayStatus(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  kycLookup: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.kycLookup(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  generateACRWU: ({ commit }, payload) => new Promise((resolve) => {
    wu.generateACRWU(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  doValidateQPTransaction: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.qpValidate(payload)
      .then(({ data }) => {
        if (data.result) {
          commit('SET_QP_VALIDATE_DATA', data.result)
        }
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  doStoreQPTransaction: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.qpStore(payload)
      .then(({ data }) => {
        if (data.result) {
          commit('SET_QP_CURRENT_PAGE', 2)
          commit('SET_QP_SUCCESS_DATA', data.result)
        }
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getQPFeeInquiry: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.qpFeeInquiry(payload)
      .then(({ data }) => {
        // commit('SET_CURRENT_TRANSACTION', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getMCRate: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.mcRate(payload)
      .then(({ data }) => {
        commit('SET_MC_RATE_DATA', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  retryReceiveMoney: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.retryReceiveMoney(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  retrySendMoney: ({ commit }, payload) => new Promise((resolve, reject) => {
    wu.retrySendMoney(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
