<template>
  <div class="ph-wu-search-page-container">
    <v-form ref="searchMTCN" @submit.prevent="searchWUTransaction">
      <v-row class="ph-wu-search-container">
        <v-col cols="12" v-if="showRemoteNotice">
          <v-alert
            color="info"
            dark
            icon="mdi-remote"
            border="left"
            prominent
          >
            This transaction will be processed on behalf of <b>{{remoteData.remote_location.location_name}}</b> branch.
          </v-alert>
        </v-col>
        <v-col cols="12" md="4" class="ph-wu-search-header">
          <h1 class="ph-wu-form-title">Payout Money</h1>
          <router-link class="ph-wu-form-subtitle" to="/pera-remit/remote-location?trx_type=PO">Receive Money from a Different Location</router-link>
        </v-col>
        <v-col cols="12" md="8" class="ph-wu-search-form">
          <div class="ph-wu-search-fields">
            <v-text-field
              v-model="mtcn"
              @paste="formatMTCN"
              class="ph-textbox"
              single-line
              outlined
              placeholder="MTCN"
              :rules="mtcnRule"
              counter="10"
              persistent-hint
              maxlength="10"
            >
            </v-text-field>
          </div>
          <v-row class="ph-wu-search-fields">
            <v-col cols="12" md="2" class="ph-wu-currency-select-title">
              <b>Pay By:</b>
            </v-col>
            <v-col cols="12" md="10" class="ph-wu-currency-selector">
              <v-radio-group
                v-model="currency"
                mandatory
                row
              >
                <v-radio
                  label="PHP"
                  value="PHP"
                ></v-radio>
                <v-radio
                  label="USD"
                  value="USD"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="ph-wu-search-fields ph-wu-customer-search">
            <v-col cols="12">
              <b>Customer's name</b>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="first_name"
                class="ph-textbox"
                single-line
                outlined
                placeholder="First Name"
                persistent-hint
                :rules="requiredField"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                  v-model="last_name"
                  class="ph-textbox"
                  single-line
                  outlined
                  placeholder="Last Name"
                  persistent-hint
                  :rules="requiredField"
                  @keyup.enter="searchWUTransaction"
                >
                </v-text-field>
            </v-col>
          </v-row>
          <v-row class="ph-wu-search-fields ph-wu-action-container">
            <v-col cols="12" class="ph-wu-action-item">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                elevation="0"
                class="ph-primary-button ph-primary-execute-btn"
                @click="searchWUTransaction"
                :disabled="!mtcn || !first_name || !last_name"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getForeignRefNo, renderToast } from '@/utils'

export default {
  name: 'ReceiveMoneyWUSearch',
  data () {
    return {
      foreignRefNo: '',
      first_name: '',
      last_name: '',
      mtcn: '',
      currency: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      showRemoteNotice: false,
      mtcnRule: [v => v.length <= 10 || 'MTCN must be 10-digits'],
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      remoteData: 'obo/remoteData',
      selectedCustomer: 'customers/selectedCustomer',
      currentTrx: 'wu/currentTrx'
    })
  },
  watch: {
    selectedCustomer: {
      handler (data) {
        if (data) {
          this.renderToast('info', 'Customer Selected!', `${data.full_name} has been successfully selected.`)
          this.$store.commit('wu/SET_SHOW_PAYOUT_SEARCH_FORM', false)
          this.$store.commit('wu/SET_SHOW_PAYOUT_RESULT_FORM', true)
        } else {
          this.$store.commit('wu/SET_SHOW_PAYOUT_SEARCH_FORM', true)
          this.$store.commit('wu/SET_SHOW_PAYOUT_RESULT_FORM', false)
        }
      },
      deep: true
    }
  },
  mounted () {
    this.foreignRefNo = this.getForeignRefNo()
    console.log(this.foreignRefNo)

    this.$store.commit('wu/SET_FOREIGN_REF_NO', this.foreignRefNo)

    if (this.selectedCustomer && this.currentTrx?.result) {
      this.renderToast('info', 'Customer Selected!', `${this.selectedCustomer.full_name} has been successfully selected.`)
      this.$store.commit('wu/SET_SHOW_PAYOUT_SEARCH_FORM', false)
      this.$store.commit('wu/SET_SHOW_PAYOUT_RESULT_FORM', true)
    }

    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData.remote_location.location_id

      this.showRemoteNotice = true

      this.renderToast('info', 'Remote Transaction is Enabled', `This transaction will be processed on behalf of ${this.remoteData.remote_location.location_name} branch.`)
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id

      this.showRemoteNotice = false

      this.renderToast('warning', 'Remote Transaction data cannot be retrived', 'The remote transaction data cannot be found. Normal transaction settings will be applied for this transaction instead.')
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
      this.showRemoteNotice = false
    }
  },
  methods: {
    getForeignRefNo,
    renderToast,
    formatMTCN (event) {
      event.preventDefault()
      console.log(event.clipboardData.getData('text/plain'))

      let currentValue = ''
      let formattedValue = ''
      this.mtcn = ''

      currentValue = event.clipboardData.getData('text/plain')
      formattedValue = currentValue.replace(/-/g, '')

      console.log(this.mtcn)

      this.mtcn = formattedValue
    },
    async searchWUTransaction () {
      if (!this.mtcn || !this.first_name || !this.last_name) {
        this.renderToast('error', 'Search Error', 'The required fields must be completed.')
      } else {
        this.$store.commit('wu/SET_PAYOUT_CURRENT_CURRENCY', this.currency)
        const payload = {
          mtcn: this.mtcn,
          currency_code: this.currency,
          foreign_ref_no: this.foreignRefNo,
          terminal_id: this.terminalId,
          operator_id: this.operatorId,
          location_id: this.locationId
        }

        await this.$store.dispatch('getIDList', 'wu')
        await this.$store.dispatch('wu/getPayoutSearchResult', payload).then(res => {
          if (res?.result?.payment_transaction) {
            const payoutDetails = res.result.payment_transaction
            console.log(payoutDetails, 'payout details')
            const customerName = `${this.last_name}, ${this.first_name}`

            if (payoutDetails) {
              this.searchCustomer(customerName)
            }
          } else {
            this.renderToast('error', 'WU Error', res.message)
          }
        }).catch(err => {
          console.log(err)
          this.renderToast('error', 'WU Error', err.data.message)
        })
      }
    },
    async searchCustomer (customerName) {
      this.$store.dispatch('customers/doSearch', customerName).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style scoped>
  .ph-wu-form-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-wu-search-header {
    margin-top: 10px;
  }

  .ph-wu-search-fields {
    margin-top: 20px;
  }

  .ph-wu-currency-select-title {
    font-family: 'Proxima Nova Bold';
    padding-top: 18px;
  }

  .ph-wu-currency-selector .v-input {
    margin-top: 0
  }

  .ph-wu-customer-search {
    margin-top: 0;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    font-size: 14px;
  }

  .ph-wu-action-item {
    display: flex;
  }

  @media only screen and (min-width: 600px) {
    .ph-primary-button {
      width: 160px;
      margin-left: auto;
    }
  }
</style>
