import api from '@/api'

export default {
  retrieveData (trxDate, locationId, type) {
    return api.get(`/v1/maintenance/api/maintenance/trx-bank-transaction-grid?type=${type}&location_id=${locationId}&date_encoded=${trxDate}`)
  },
  saveData (payload) {
    return api.post('/v1/maintenance/api/maintenance/trx-bank-transaction-insert', payload)
  },
  updateData (payload) {
    return api.post('/v1/maintenance/api/maintenance/trx-bank-transaction-update', payload)
  },
  deleteRecord (id) {
    return api.delete(`/v1/maintenance/api/maintenance/trx-bank-transaction-delete/${id}`)
  },
  getBankList (locationId) {
    return api.get(`/v1/maintenance/api/maintenance/get-locations-bank-list?location_id=${locationId}`)
  }
}
