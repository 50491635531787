<template>
  <div class="px-2 px-md-2 mt-md-4">
    <v-btn @click="backToSearch" class="text-capitalize" text small>
      <v-icon class="mr-1" small>
        mdi-arrow-left
      </v-icon>
      Back to search
    </v-btn>
    <v-row no-gutters>
      <v-col class="pl-md-2 mt-5" cols="12" v-if="showRemoteNotice">
        <v-alert
          color="info"
          dark
          icon="mdi-remote"
          border="left"
          prominent
        >
          This transaction will be processed on behalf of <b>{{remoteData.remote_location.location_name}}</b> branch.
        </v-alert>
      </v-col>
      <v-col class="pr-md-8" :cols="12" :md="8">
        <v-stepper class="elevation-0 mt-md-4" v-model="currentStep">
          <v-stepper-header class="elevation-0">
            <v-stepper-step
              class="pl-4"
              :complete="currentStep > 1"
              step="1"
            >
              Transfer Information
            </v-stepper-step>

            <v-divider class="mx-md-1" />

            <v-stepper-step
              :complete="currentStep > 2"
              step="2"
            >
              KYC Lookup
            </v-stepper-step>

            <v-divider class="mx-md-1" />

            <v-stepper-step step="3">
              Receiver Details
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
        <TransferInformation v-if="currentStep === 1" />
        <KYCLookup v-if="currentStep === 2" />
        <ReceiverDetails v-if="currentStep === 3" />
      </v-col>
      <SummaryOverview />
      <v-dialog
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        v-model="summaryDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        class="ph-wu-receive-summary-dialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="ph-wu-receive-summary-minified">
            <div class="ph-wu-receive-summary-content">
              <div class="ph-wu-receive-summary-minified-info">
                <p><b>Summary Overview</b></p>
                <p>Total Amount: &nbsp;<b>{{ transactionData.destinationCountryCurrency }} {{ formatAmount(transactionData.totalAmount) }}</b></p>
              </div>
              <div class="ph-wu-receive-summary-minified-action">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </template>
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="summaryDialog = false"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-row no-gutters>
            <v-col :cols="12">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Provider</div>
                <div>{{ transactionData.remcoName || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Sender Name</div>
                <div>{{ senderName || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Sender Birthdate</div>
                <div>{{ transactionData.senderBirthDate || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Receiver Name</div>
                <div>{{ receiverName || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Destination</div>
                <div>{{ transactionData.destinationCountryName || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Exchange Rate</div>
                <div>{{ transactionData.exchangeRate || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Send Amount</div>
                <div>{{ formatAmount(transactionData.sendAmount) || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Transfer Fee</div>
                <div>{{ formatAmount(transactionData.serviceFee) || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12" v-if="hasDiscount">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Discount</div>
                <div>{{ formatAmount(transactionData.discountAmount) || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Pay Amount</div>
                <div>{{ formatAmount(transactionData.receiveAmount, transactionData.destinationCountryCurrency) || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="my-1 mx-2 my-md-0 mx-md-0 d-flex justify-space-between">
                <div>Total Amount</div>
                <div>{{ formatAmount(transactionData.totalAmount) || '—' }}</div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
          </v-row>
          <wacom
            v-if="currUser.has_wacom"
            @submit-sig="submitSig" type="transactional"
            :referenceSignatures="customerSignaturesReference"
            :trxData="{sender_name: selectedCustomer.full_name, receiver_name: `${transactionData.receiverLastName}, ${transactionData.receiverFirstName}`, total_amount: formatAmount(transactionData.totalAmount)}"
            />
          <v-checkbox
            v-model="transactionData.agentVerifiedData"
            class="my-0 py-0 mt-4 mt-md-6"
            label="I have Verified Customer Data"
            hide-details
          />
          <v-btn
            @click="preprint"
            class="rounded-lg mt-4 mt-md-4 mx-2 font-weight-black"
            color="primary"
            :disabled="!isCompleteForm"
            v-if="transactionData.remcoID === 0"
            outlined
            block
            x-large
          >
            <span v-if="transactionData.remcoID === 0">
              Pre-Print
            </span>
            <span v-else>
              E-Signature
            </span>
          </v-btn>
          <v-btn
            @click="otpDialog = true"
            class="rounded-lg mt-2 mt-md-4 mx-2 font-weight-black"
            color="primary"
            :disabled="(!isDonePreprint && transactionData.remcoID === 0) || (!isCompleteForm && transactionData.remcoID !== 0)"
            block
            x-large
          >
            Confirm
          </v-btn>
          <v-dialog v-model="otpDialog" width="30%" persistent>
            <v-card class="rounded-lg">
              <v-card-title class="pb-0">
                <v-spacer />
                <v-btn @click="otpDialog = false" x-small icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="py-md-4 text-center">
                <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
                <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
                  OTP
                </h3>
                <p>
                  Input your 6-digit One-time password PIN from the authentication app.
                </p>
                <v-otp-input
                  v-model="otp"
                  id="otp"
                  type="number"
                  :disabled="isOTPLoading"
                  @finish="verifyOTP"
                />
                <p class="red--text">
                  {{ otpMessage }}
                </p>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="noIDFlag"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            No identification card found
          </v-card-title>

          <v-card-text class="ph-dialog-header">
            <br>
            The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="danger"
              text
              @click="backToSearch"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="updateDetails(selectedCustomer)"
            >
              Update Customer Record
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TransferInformation from '@/components/send/TransferInformation.vue'
import KYCLookup from '@/components/send/KYCLookup.vue'
import ReceiverDetails from '@/components/send/ReceiverDetails.vue'
import SummaryOverview from '@/components/send/SummaryOverview.vue'
import {
  toMoney
} from '@/utils'
// import { format, parseISO } from 'date-fns'
// import CryptoJS from 'crypto-js'
// import VueCookies from 'vue-cookies'
// import wacomMixin from '@/mixins/wacom.js'
// import wacom from '@/components/wacom/wacomTransaction'

export default {
  computed: {
    ...mapGetters({
      remoteData: 'obo/remoteData',
      transactionData: 'send/transactionData',
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer'
    }),
    currentStep: {
      get () {
        return this.$store.state.send.currentStep
      },
      set (val) {
        this.$store.commit('send/SET_CURRENT_STEP', val)
      }
    },
    isCompleteForm () {
      const {
        destinationCountyID,
        sendAmount,
        remcoID,
        serviceFee,
        receiverFirstName,
        receiverLastName,
        agentVerifiedData
      } = this.transactionData

      return String(destinationCountyID) &&
        Number(sendAmount) > 0 &&
        String(remcoID) &&
        String(serviceFee) &&
        receiverFirstName &&
        receiverLastName &&
        agentVerifiedData
    },
    senderName () {
      const { senderFirstName, senderLastName } = this.transactionData
      if (senderFirstName && senderLastName) {
        return `${senderLastName}, ${senderFirstName}`
      }
      return ''
    },
    receiverName () {
      const { receiverFirstName, receiverLastName } = this.transactionData
      if (receiverFirstName && receiverLastName) {
        return `${receiverLastName}, ${receiverFirstName}`
      }
      return ''
    },
    hasDiscount () {
      const { discountAmount } = this.transactionData

      return (discountAmount && Number(discountAmount) > 0)
    }
  },
  data () {
    return {
      isDonePreprint: false,
      showRemoteNotice: false,
      summaryDialog: false,
      otpDialog: false,
      isOTPLoading: false,
      otpMessage: '',
      otp: '',
      noIDFlag: false
    }
  },
  components: {
    TransferInformation,
    KYCLookup,
    ReceiverDetails,
    SummaryOverview
  },
  methods: {
    async verifyOTP (val) {
      this.otp = val

      if (this.currUser) {
        const payload = {
          user_name: this.currUser.user_name,
          otp_code: this.otp,
          pin: this.currUser.otp_pin,
          module: 'send'
        }

        if (this.otp && this.otp.length === 6) {
          this.isOTPLoading = true
          const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)

          if (OTPVerification.result.status && OTPVerification.result.value) {
            this.otpMessage = ''
            this.confirm()
          } else {
            this.otpMessage = 'Wrong PIN!'
            this.isOTPLoading = false
          }
        } else {
          this.otpMessage = 'Wrong PIN!'
          this.isOTPLoading = false
        }
      } else {
        this.$store.commit('auth/SET_IS_OTP_PASSED', false)
        this.$store.commit('auth/SET_CURRENT_USER', {})
        this.$store.commit('auth/AUTH_SUCCESS', false)
        window.location.href = `${this.$uspAuthAppURL}/login`
      }
    },
    updateDetails (customerDetails) {
      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    backToSearch () {
      this.$store.dispatch('send/resetForm')
      this.$store.commit('send/SET_CURRENT_PAGE', 0)
    },
    formatAmount (amount, currency) {
      if (amount) {
        return toMoney(amount, currency)
      } else {
        return ''
      }
    }
  },
  async mounted () {
    let operatorId = ''

    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      operatorId = this.remoteData.remote_user
    } else {
      operatorId = this.currUser.user_id
    }

    const payload = {
      location_id: this.currUser.location_id,
      operator_id: operatorId,
      module: 'wudas',
      request: 'GetCountriesCurrencies',
      terminal_id: this.currUser.fs_id,
      OriginatingCountry: 'PH PHP'
    }

    const countryData = []

    await this.$store.dispatch('getWudasData', payload).then(res => {
      const apiResponse = res.result
      apiResponse.forEach((item, index) => {
        countryData.push({
          id: index + 1,
          country: item.COUNTRY_LONG,
          iso_country_code: item.ISO_COUNTRY_CD,
          currency_name: item.CURRENCY_NAME,
          currency_value: item.CURRENCY_CD
        })
      })
    })

    this.$store.commit('send/SET_PAYOUT_COUNTRIES_DATA', countryData)

    if (countryData && this.transactionData.destinationCountryID === '') {
      for (const data of countryData) {
        if (data.currency_value === 'PHP') {
          [
            { prop: 'destinationCountryID', value: data.id },
            { prop: 'destinationCountryCode', value: data.iso_country_code },
            { prop: 'destinationCountryCurrency', value: data.currency_value },
            { prop: 'destinationCountryName', value: data.country }
          ].map(item => this.$store.commit('send/SET_TRANSACTION_DATA', item))
          this.$store.commit('send/SET_PH_ID', data.id)
        }
      }
    }

    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.showRemoteNotice = true
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.showRemoteNotice = false
    } else {
      this.showRemoteNotice = false
    }

    if (this.selectedCustomer) {
      if (this.selectedCustomer.img === '{}' || !this.selectedCustomer.img) {
        this.noIDFlag = true
      }
    }
  }
}
</script>

<style scoped>
  .v-stepper.ph-wu-receive-stepper .v-stepper__header {
    box-shadow: none !important;
  }

  .ph-wu-receive-summary .v-card {
    padding: 20px;
  }

  .ph-transfer-summary-header {
    font-size: 18px;
    text-align: center;
  }

  .ph-transfer-summary-content-title {
    font-size: 14px;
  }

  .transfer-info-title,
  .transfer-info-value {
    border-bottom: thin dashed rgba(0,0,0,.12) !important;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
    border: 2px solid #1A2791;
  }

  .ph-transaction-container {
    padding: 0;
  }

  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 100px;
  }

  .ph-primary-button.ph-stepper-button {
    height: 40px !important;
    width: 115px;
  }

  /* @media only screen and (min-width: 600px) {
    .v-stepper.ph-wu-receive-stepper .v-stepper__step {
      padding-left: 0;
    }
  } */

  .ph-wu-receive-summary-minified {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F4F4FA;
  }

  .ph-wu-receive-summary-content {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
  }

  .ph-wu-receive-summary-minified-info {
    margin-right: auto;
  }

  .ph-wu-receive-summary-minified-info p {
    margin-bottom: 0;
  }

  .ph-wu-receive-summary-minified-action {
    margin-left: auto;
  }

  .ph-wu-receive-form {
    padding: 12px 0;
  }

  @media only screen and (min-width: 960px) {
    .ph-stepper-action-buttons {
      display: flex;
      margin-bottom: 0;
    }
  }
</style>
