<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
      :items="items"
      divider=">"
      ></v-breadcrumbs>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12" :sm="4" :md="3">
          <v-autocomplete
            v-model="searchLocation"
            :items="collections.locations"
            @click:clear="getAllDevices"
            item-text="location_name"
            item-value="location_id"
            class="ph-textbox"
            label="Location"
            outlined
            clearable
          />
        </v-col>
        <v-col :cols="12" :sm="4" :md="3">
          <v-select
            v-model="searchCategory"
            :items="statusList"
            item-text="text"
            item-value="value"
            class="ph-textbox ml-md-3"
            label="Status"
            outlined
          />
        </v-col>
        <v-col :cols="12" :sm="4" :md="3">
          <v-btn
            x-large
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn ph-trusted-device-search-btn"
            @click="getBranchDeviceList"
          >
            Search
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-data-table
              :headers="headers"
              :items="deviceList"
              class="custom-table"
              :calculate-widths="true"
            >
              <template v-slot:[`item.os`]="{ item }">
                <div class="browser-item-container">
                  <div class="item-container">
                    <img class="item-icon" :src="require(`../../assets/images/browsers/${getBrowserName(item.browser)}`)" />
                    ({{ item.browser }})
                  </div>
                  <div class="item-container">
                    <img class="item-icon" :src="require(`../../assets/images/platform/${getPlatformName(item.os)}`)" />
                    ({{ item.os }})
                  </div>
                </div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDateTime(item.created_at) }}
              </template>
              <template v-slot:[`item.expiry_date`]="{ item }">
                {{ formatDateTime(item.expiry_date) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.status === 1 || item.status === '1'">
                  <v-icon color="orange" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.status === 2 || item.status === '2'">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Approved
                </span>
                <span class="ph-nonex-status-container red--text" v-if="item.status === 3 || item.status === '3'">
                  <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                  Disapproved
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip right v-if="item.status === 1" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="deviceAction(item, 'approve')" color="primary" icon>
                      <v-icon>mdi-check-bold</v-icon>
                    </v-btn>
                  </template>
                  <span>Approve</span>
                </v-tooltip>
                <v-tooltip right v-if="item.status === 1" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="item.status === 1" @click="deviceAction(item, 'disapprove')" color="primary" icon>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Disapprove</span>
                </v-tooltip>
                <v-tooltip right v-if="item.status === 2" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="item.status === 2" @click="deviceAction(item, 'edit')" color="primary" icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Device</span>
                </v-tooltip>
                <v-tooltip right v-if="item.status === 2 || item.status === 3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="item.status === 2 || item.status === 3" @click="deviceAction(item, 'delete')" color="primary" icon>
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Device</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="confirmDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            {{ confirmDialogTitle }}
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            {{ confirmDialogText }}
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="closeConfirmDialog"
              class="ph-action-button"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="doConfirmAction(confirmPayload, confirmActionType)"
              class="ph-action-button"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="50%">
        <v-card class="rounded-lg">
          <v-form ref="updateLocation">
            <v-card-title class="py-md-6">
              <h3 class="custom-heading primary--text">
                Edit Location
              </h3>
              <v-spacer />
              <v-btn @click="closeEditDialog" small icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                v-model="currentLocation"
                :items="collections.locations"
                item-text="location_name"
                item-value="location_id"
                :rules="requiredRules"
                class="ph-textbox"
                label="Branch"
                return-object
                outlined
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="danger"
                text
                @click="closeEditDialog"
                class="ph-action-button"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                elevation="0"
                @click="confirmEdit"
                class="ph-action-button"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { renderToast } from '@/utils'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
  name: 'UserTrustedDevices',
  data () {
    return {
      searchLocation: '',
      searchCategory: '',
      statusList: [
        { text: 'Pending', value: 1 },
        { text: 'Approved', value: 2 },
        { text: 'Disapproved', value: 3 }
      ],
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Admin',
          disabled: false,
          href: '#'
        },
        {
          text: 'Manage Trusted Devices',
          disabled: true,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Location',
          sortable: false,
          value: 'location_name'
        },
        {
          text: 'Device Info',
          sortable: false,
          value: 'os'
        },
        {
          text: 'Date Enrolled',
          sortable: false,
          value: 'created_at'
        },
        {
          text: 'Expiry Date',
          sortable: false,
          value: 'expiry_date'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      confirmActionType: '',
      confirmPayload: '',
      editDialog: false,
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      currentLocation: ''
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      deviceList: 'userTrustedDevices/deviceList',
      collections: 'collections'
    })
  },
  async beforeMount () {
    await this.$store.dispatch('getAllLocations', 0)
    await this.$store.dispatch('userTrustedDevices/getEnrolledList')
  },
  methods: {
    renderToast,
    formatDateTime (date) {
      return format(parseISO(date), 'yyyy-MM-dd')
    },
    getBrowserName (browser) {
      const browserName = browser.toLowerCase()
      let browserValue = ''

      switch (browserName) {
        case 'brave':
          browser = 'brave'
          break
        case 'chrome':
          browserValue = 'chrome'
          break
        case 'edge':
          browserValue = 'edge'
          break
        case 'firefox':
          browserValue = 'firefox'
          break
        case 'firefox_nightly':
          browserValue = 'firefox_nightly'
          break
        case 'firefox_old':
          browserValue = 'firefox_old'
          break
        case 'ie9':
          browserValue = 'ie9'
          break
        case 'opera':
          browserValue = 'opera'
          break
        case 'opera_gx':
          browserValue = 'opera_gx'
          break
        case 'safari':
          browserValue = 'safari'
          break
        default:
          browserValue = ''
      }

      return browserValue + '.svg'
    },
    getPlatformName (platform) {
      const platformName = platform.toLowerCase()
      let platformValue = ''

      switch (platformName) {
        case 'windows':
          platformValue = 'devices'
          break
        case 'android':
          platformValue = 'android'
          break
        case 'macos':
        case 'ios':
          platformValue = 'ios'
          break
        default:
          platformValue = 'devices'
      }

      return platformValue + '.svg'
    },
    deviceAction (item, type) {
      this.confirmPayload = item
      this.confirmActionType = type

      switch (type) {
        case 'approve':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Approve Request'
          this.confirmDialogText = 'Are you sure you want to approve this device?'
          break
        case 'disapprove':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Disapprove Request'
          this.confirmDialogText = 'Are you sure you want to reject this device?'
          break
        case 'edit':
          this.editDialog = true
          this.currentLocation = item.location_id
          this.confirmDialogTitle = 'Edit Location'
          this.confirmDialogText = 'Are you sure you want to edit the current location of this device?'
          break
        case 'delete':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Delete Device'
          this.confirmDialogText = 'Are you sure you want to delete this device?'
          break
      }
    },
    async doConfirmAction (item, type) {
      const payload = {
        id: item.id,
        params: {
          status: (type === 'approve') ? 2 : 3,
          remarks: (type === 'approve') ? 'Device is approved.' : 'Device is rejected.'
        }
      }

      let confirmAction = ''
      let confirmTypeText = ''

      switch (type) {
        case 'approve':
          confirmTypeText = 'The request has been successfully approved'
          confirmAction = 'userTrustedDevices/updateStatus'
          break
        case 'disapprove':
          confirmTypeText = 'The request has been successfully rejected'
          confirmAction = 'userTrustedDevices/updateStatus'
          break
        case 'edit':
          confirmTypeText = 'The request has been successfully updated'
          confirmAction = 'userTrustedDevices/updateData'
          payload.params = {
            location_id: this.currentLocation.location_id
          }
          break
        case 'delete':
          confirmTypeText = 'The device has been deleted'
          confirmAction = 'userTrustedDevices/deleteDevice'
          break
      }

      await this.$store.dispatch(confirmAction, payload).then(res => {
        if (res.code === 200) {
          this.renderToast('success', 'Action Successful', confirmTypeText)
          this.confirmDialog = false
          this.editDialog = false
          this.confirmDialogTitle = ''
          this.confirmDialogText = ''
          this.currentLocation = ''
          if (this.searchLocation !== '' && this.searchCategory !== '') {
            this.getBranchDeviceList()
          } else {
            this.$store.dispatch('userTrustedDevices/getEnrolledList')
          }
        }
      }).catch(err => {
        this.renderToast('error', 'Error', err)
        this.confirmDialog = false
        this.editDialog = false
        this.confirmDialogTitle = ''
        this.confirmDialogText = ''
        this.currentLocation = ''
      })
    },
    closeConfirmDialog () {
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''
    },
    closeEditDialog () {
      this.editDialog = false
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''
    },
    confirmEdit () {
      if (this.$refs.updateLocation.validate()) {
        this.confirmDialog = true
      } else {
        this.renderToast('error', 'Validation Error', 'You must select a location')
      }
    },
    async getAllDevices () {
      await this.$store.dispatch('userTrustedDevices/getEnrolledList')
      this.searchLocation = ''
      this.searchCategory = ''
    },
    async getBranchDeviceList () {
      const payload = {
        location_id: this.searchLocation,
        status: this.searchCategory
      }
      await this.$store.dispatch('userTrustedDevices/searchEnrolledDevice', payload)
    }
  }
}
</script>
<style>
.browser-item-container {
  display: flex;
  flex-direction: row;
}

.item-container {
  margin-right: 10px;
}

.item-icon {
  height: 18px;
  width: auto;
  position: relative;
  top: 4px;
}

.ph-primary-button.ph-trusted-device-search-btn {
  margin: 0 10px;
  height: 49px !important;
}
</style>
