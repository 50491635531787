import { endpoints } from '@/api/endpoints'

const wuPayments = endpoints.use('wuPayments')

export default {
  retrieveOrders: ({ commit }, payload) => new Promise((resolve, reject) => {
    wuPayments.retrieveOrders(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getMerchants: ({ commit }, payload) => new Promise((resolve, reject) => {
    wuPayments.merchantList(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  orderLookup: ({ commit }, payload) => new Promise((resolve, reject) => {
    wuPayments.orderLookup(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  orderPaymentHold: ({ commit }, payload) => new Promise((resolve, reject) => {
    wuPayments.orderPaymentHold(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  orderPaymentRelease: ({ commit }, payload) => new Promise((resolve, reject) => {
    wuPayments.orderPaymentRelease(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  orderRefundHold: ({ commit }, payload) => new Promise((resolve, reject) => {
    wuPayments.orderRefundHold(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  orderRefundRelease: ({ commit }, payload) => new Promise((resolve, reject) => {
    wuPayments.orderRefundRelease(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
