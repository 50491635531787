<template>
  <div class="ph-container">
      <v-container fluid>
        <v-dialog
          persistent
          v-model="dialogSignature"
          width="500"
          >
          <v-card>
            <v-card-title class="ph-dialog-title">
              No User Signature Uploaded
            </v-card-title>
            <br>
            <v-card-text class="ph-dialog-header">
              <p>The system has detected that you have not uploaded your signature yet. Please upload your signature.</p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="handleClickSignature"
              >
                Got it
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'noSignatureDialog',
  data () {
    return {
      dialogSignature: false
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  mounted () {
    console.log(this.currUser)
    if (!this.currUser.userSignature && this.currUser.has_wacom === true) {
      this.dialogSignature = true
    }
  },
  methods: {
    handleClickSignature () {
      this.dialogSignature = false
      this.$router.push('/account')
    }
  }
}
</script>
