<template>
  <div>
    <strong>Delivery Service</strong>
    <v-row class="mt-md-3 mb-md-5" no-gutters>
      <v-col :cols="12">
        <v-select
          v-model="transactionData.deliveryService"
          :items="deliveryServiceTypes"
          @change="selectedType(transactionData.deliveryService)"
          class="ph-textbox mb-md-6"
          label="Type*"
          item-text="text"
          item-value="value"
          outlined
          :return-object="true"
        />
      </v-col>
      <v-col class="pr-md-1" :cols="12" :md="6" v-if="transactionData.destinationCountryID && transactionData.destinationCountryID !== countryID && transactionData.deliveryService.value !== 'D2B'">
        <v-text-field
          v-model="transactionData.testQuestion"
          class="ph-textbox mb-md-6"
          label="Test Question"
          outlined
        />
      </v-col>
      <v-col class="pl-md-1" :cols="12" :md="6" v-if="transactionData.destinationCountryID && transactionData.destinationCountryID !== countryID && transactionData.deliveryService.value !== 'D2B'">
        <v-text-field
          v-model="transactionData.answer"
          class="ph-textbox mb-md-6"
          label="Answer"
          outlined
        />
      </v-col>
    </v-row>
    <!-- Direct to Bank Fields -->
    <strong v-if="transactionData.deliveryService.value === 'D2B'">Direct to Bank Fields</strong>
    <!-- New Zealand Only -->
    <v-row v-if="transactionData.destinationCountryID === 187 && transactionData.deliveryService.value === 'D2B'" class="mt-md-3 mb-md-5" no-gutters>
      <v-col class="pr-md-1" :cols="12" :md="12" v-for="(fieldData, index) in fieldsList" :key="index">
        <v-text-field
          v-if="fieldData[0] === 'Bank Name'"
          v-model="transactionData[fieldData[0].replace(/[^A-Z0-9]+/ig, '')]"
          class="ph-textbox mb-md-6"
          :label="fieldData[0]"
          outlined
          :required="fieldData[3].includes('required')"
          :maxlength="fieldData[1]"
        />
      </v-col>
      <v-row class="pr-md-1" :cols="12" :md="12">
        <v-col cols="12">
          <strong>Account Number</strong>
        </v-col>
        <v-col :cols="fieldData[1] === '7' ? 5 : (fieldData[1] === '4' ? 3 : 2)" v-for="(fieldData, index) in fieldsList.slice(2)" :key="index">
          <v-text-field
            v-if="fieldData[1] !== '0'"
            :v-model="transactionData['AccountNumber'+(parseInt(index) + 1)]"
            class="ph-textbox mb-md-6"
            :placeholder="fieldData[1] === '7' ? 'XXXXXXX' : (fieldData[1] === '4' ? 'XXXX' : 'XX')"
            outlined
            :required="fieldData[3].includes('required')"
            :maxlength="fieldData[1]"
            @change="setAccountNumberValue((parseInt(index) + 1), $event)"
          />
        </v-col>
      </v-row>
    </v-row>
    <!-- Non NZ -->
    <v-row v-if="transactionData.destinationCountryID !== 187 && transactionData.deliveryService.value === 'D2B'" class="mt-md-3 mb-md-5" no-gutters>
      <v-col class="pr-md-1" :cols="12" :md="6" v-for="(fieldData, index) in fieldsList" :key="index">
        <!-- Alphanumeric Fields -->
        <v-text-field
          v-if="fieldData[5] === 'ALPHANUM'"
          v-model="transactionData[fieldData[0].replace(/[^A-Z0-9]+/ig, '')]"
          class="ph-textbox mb-md-6"
          :label="fieldData[0]"
          outlined
          :required="fieldData[3].includes('required')"
          :maxlength="fieldData[1]"
        />
        <!-- Uppercase Fields -->
        <v-text-field
          v-if="fieldData[5] === 'UPRSTRING'"
          @input="convertToUpperCase($event, fieldData[0].replace(/[^A-Z0-9]+/ig, ''))"
          v-model="transactionData[fieldData[0].replace(/[^A-Z0-9]+/ig, '')]"
          class="ph-textbox mb-md-6"
          :label="fieldData[0]"
          outlined
          :required="fieldData[3].includes('required')"
          :maxlength="fieldData[1]"
        />
        <!-- Number Fields -->
        <v-text-field
          v-if="fieldData[5] === 'DIGIT'"
          v-model="transactionData[fieldData[0].replace(/[^A-Z0-9]+/ig, '')]"
          class="ph-textbox mb-md-6"
          :label="fieldData[0]"
          outlined
          :required="fieldData[3].includes('required')"
          type="number"
          :hide-spin-buttons="true"
          :maxlength="fieldData[1]"
        />
        <!-- Selectbox Fields -->
        <v-autocomplete
          v-if="fieldData[5] === 'COMBO'"
          v-model="transactionData[fieldData[0].replace(/[^A-Z0-9]+/ig, '')]"
          :items="getComboItems(fieldData[2], 'combo')"
          class="ph-textbox mb-md-6"
          :label="fieldData[0]"
          item-text="text"
          item-value="value"
          outlined
          :required="fieldData[3].includes('required')"
          :maxlength="fieldData[1]"
          :return-object="true"
        />
        <!-- Selectbox Fields -->
        <v-autocomplete
          v-if="fieldData[5] === 'COMBO2'"
          v-model="transactionData[fieldData[0].replace(/[^A-Z0-9]+/ig, '')]"
          @change="getChildComboItems(fieldData, fieldData[0], fieldData[10].split(',')[1], $event)"
          :items="getComboItems(fieldData[2], 'combo2')"
          class="ph-textbox mb-md-6"
          :label="fieldData[0]"
          item-text="text"
          item-value="value"
          outlined
          :return-object="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      fieldsListArray: [],
      combosListArray: [],
      terminalId: '',
      operatorId: '',
      locationId: ''
    }
  },
  computed: {
    ...mapGetters({
      collections: 'collections',
      transactionData: 'send/transactionData',
      currUser: 'auth/currUser',
      payoutCountryList: 'send/payoutCountryList',
      deliveryServiceTypes: 'send/deliveryServiceTypes',
      fieldsList: 'send/fieldsList',
      combosList: 'send/combosList',
      remoteData: 'obo/remoteData',
      countryID: 'send/phID',
      feeInquiryData: 'send/feeInquiryData',
      remcoList: 'send/remcoList'
    })
  },
  mounted () {
    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData?.remote_location?.location_id
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    }
  },
  methods: {
    getComboItems (id, type) {
      const comboData = this.combosList.filter(item => item.id === id)
      const comboValue = []

      if (comboData[0]?.items) {
        const comboItems = comboData[0].items

        if (type === 'combo') {
          comboItems.forEach(item => {
            comboValue.push({
              text: item[3],
              value: item[2]
            })
          })
        }

        if (type === 'combo2') {
          comboItems.forEach(item => {
            comboValue.push({
              text: item.LIST_TEXT,
              value: item.LIST_VALUE
            })
          })
        }

        return comboValue
      }
    },
    async getChildComboItems (data, id, level, event) {
      const currentFormInput = id.replace(/[^A-Z0-9]+/ig, '')
      this[currentFormInput] = event.text
      if (data) {
        const getExistingList = this.combosList.filter(item => item.parent === id)
        if (getExistingList.length > 0) {
          const elevateLevel = parseInt(level) + 1
          console.log(event)
          let response = []
          if (elevateLevel === 2) {
            response = await this.getCascadeChildList(data, elevateLevel.toString(), event.text)
          } else if (elevateLevel === 3) {
            const parentName = this.combosList[0].parent.replace(/[^A-Z0-9]+/ig, '')

            const cascadeParams = {
              query3: this[parentName],
              query4: event.text
            }
            response = await this.getCascadeChildList(data, elevateLevel.toString(), cascadeParams)
          }

          if (Array.isArray(response)) {
            getExistingList[0].items = response
          } else {
            getExistingList[0].items = [response]
          }
        }
      }
    },
    async selectedType (data) {
      await this.feeInquiry()
      if (data.value === 'D2B') {
        const currency = this.transactionData.destinationCountryCurrency
        const countryCode = this.transactionData.destinationCountryCode

        const payload = {
          location_id: this.currUser.location_id,
          operator_id: this.operatorId,
          module: 'wudas',
          request: 'GetDeliveryOptionTemplate',
          terminal_id: this.currUser.fs_id,
          DestinationCountry: `${countryCode} ${currency}`,
          Template: data.tmplt
        }

        await this.$store.dispatch('getWudasData', payload).then(res => {
          const apiResponse = res.result
          const templateData = apiResponse.slice(2)
          let currentComboId = ''
          let parentComboId = ''
          this.fieldsListArray = []
          this.combosListArray = []

          templateData.forEach((data, index) => {
            const description = data.DESCRIPTION
            const getParameters = description.split(';')
            const formattedParameters = []

            getParameters.forEach((item, index) => {
              if (index < getParameters.length) {
                formattedParameters.push(item.replace(/^\s+|\s+$/g, ''))
              }
            })

            if (formattedParameters[0] !== '1' && !isNaN(formattedParameters[2])) {
              console.log(formattedParameters[0].replace(/[^A-Z0-9]+/ig, ''))
              if (formattedParameters[5] === 'COMBO') {
                currentComboId = formattedParameters[2]
                this.combosListArray.push({
                  id: currentComboId,
                  items: []
                })

                this.$store.commit('send/SET_COMBOS_LIST', this.combosListArray)
              }

              if (formattedParameters[5] === 'COMBO2') {
                currentComboId = formattedParameters[2]

                if (formattedParameters[10].split(',')[1] === '1') {
                  parentComboId = formattedParameters[0]
                  this.getCascadeList(formattedParameters, currentComboId)
                } else if (formattedParameters[10].split(',')[1] === '3') {
                  const getPreviousTemplateData = templateData[index - 1]
                  const getPreviousDescription = getPreviousTemplateData.DESCRIPTION
                  const getPreviousParameters = getPreviousDescription.split(';')
                  const formattedPreviousParameters = []

                  getPreviousParameters.forEach((item, index) => {
                    if (index < getPreviousParameters.length) {
                      formattedPreviousParameters.push(item.replace(/^\s+|\s+$/g, ''))
                    }
                  })

                  parentComboId = formattedPreviousParameters[0]
                  this.generateChildCasdadeList(parentComboId, currentComboId)
                } else {
                  this.generateChildCasdadeList(parentComboId, currentComboId)
                }
              }

              this.fieldsListArray.push(formattedParameters)
              this.$store.commit('send/SET_FIELDS_LIST', this.fieldsListArray)
            } else {
              this.combosListArray.forEach(data => {
                if (data.id === currentComboId) {
                  data.items.push(formattedParameters)
                }
              })
            }
          })
        })
      }
    },
    async getCascadeList (data, comboId) {
      const cascadeValue = data[10].split(',')

      const payload = {
        location_id: this.currUser.location_id,
        operator_id: this.operatorId,
        module: 'wudas',
        request: 'GetCascadeList',
        terminal_id: this.currUser.fs_id,
        DestinationCode: cascadeValue[0].replace('CMB', '')
      }

      await this.$store.dispatch('getWudasData', payload).then(res => {
        const listData = res.result
        console.log(comboId, listData)
        this.combosListArray.push({
          id: comboId,
          items: listData
        })

        this.$store.commit('send/SET_COMBOS_LIST', this.combosListArray)
      })
    },
    async generateChildCasdadeList (parentComboId, currentComboId) {
      this.combosListArray.push({
        id: currentComboId,
        parent: parentComboId,
        items: []
      })

      this.$store.commit('send/SET_COMBOS_LIST', this.combosListArray)
    },
    async getCascadeChildList (data, level, value) {
      const cascadeValue = data[10].split(',')

      const payload = {
        location_id: this.currUser.location_id,
        operator_id: this.operatorId,
        module: 'wudas',
        request: 'GetCascadeList',
        terminal_id: this.currUser.fs_id,
        DestinationCode: cascadeValue[0].replace('CMB', '')
      }

      if (level === '2') {
        payload.result.param.QueryFilter3 = value
      }

      if (level === '3') {
        payload.result.param.QueryFilter3 = value.query3
        payload.result.param.QueryFilter4 = value.query4
      }

      let listData = []

      await this.$store.dispatch('getWudasData', payload).then(res => {
        listData = res.result
      })

      return listData
    },
    convertToUpperCase (value, field) {
      if (value) {
        this.transactionData[field] = value.toUpperCase()
      }
    },
    setAccountNumberValue (type, data) {
      this.transactionData['AccountNumber' + type] = data

      console.log(this.transactionData)
    },
    async feeInquiry (flag) {
      const {
        destinationCountryID,
        destinationCountryCode,
        destinationCountryCurrency,
        deliveryService,
        remcoID,
        sendAmount,
        receiveAmount,
        promoCode,
        message,
        refNo
      } = this.transactionData

      if (flag && sendAmount && receiveAmount) return false

      if (flag) {
        this.$store.commit('send/SET_TRANSACTION_DATA', {
          prop: 'fixedAmountFlag',
          value: flag
        })
      }

      this.transactionData.serviceFee = ''
      this.transactionData.totalAmount = ''

      const { fixedAmountFlag } = this.transactionData

      if (destinationCountryID && fixedAmountFlag && (sendAmount || receiveAmount) && (Number(sendAmount) > 0 || Number(receiveAmount) > 0)) {
        let principalAmount

        if (fixedAmountFlag === 'N') principalAmount = sendAmount
        if (fixedAmountFlag === 'F') principalAmount = receiveAmount

        if (principalAmount) {
          const payload = {
            principal_amount: principalAmount,
            agent_code: this.currUser.location_code,
            // agent_code: '01030063',
            branch_code: this.currUser.location_code,
            foreign_reference_no: refNo,
            fixed_amount_flag: fixedAmountFlag,
            destination_country_code: destinationCountryCode,
            destination_currency_code: destinationCountryCurrency,
            transaction_type: deliveryService.value || 'MIM',
            promo_code: promoCode,
            message: message,
            message_line_count: message.length,
            terminal_id: this.terminalId,
            operator_id: this.operatorId,
            location_id: this.locationId
          }

          const req = await this.$store.dispatch('send/feeInquiry', payload)

          if (req.code === 200) {
            const remcos = Object.values(req.result)

            remcos.forEach(remco => {
              this.$store.commit('send/SET_REMCO_FEE', {
                id: remco.id,
                serviceFee: remco.service_fee,
                body: remco.body
              })

              if (remco.id === 0) {
                this.$store.commit('send/SET_FEE_INQUIRY_DATA', remco.body)
                this.$store.commit('send/SET_TRANSACTION_DATA', {
                  prop: 'exchangeRate',
                  value: this.feeInquiryData.exchange_rate
                })

                if (destinationCountryID !== this.countryID) {
                  this.$store.commit('send/SET_TRANSACTION_DATA', {
                    prop: 'sendAmount',
                    value: Number(this.feeInquiryData.originators_principal_amount) / 100
                  })

                  this.$store.commit('send/SET_TRANSACTION_DATA', {
                    prop: 'receiveAmount',
                    value: Number(this.feeInquiryData.pay_amount) / 100
                  })
                }
              }
            })

            if (destinationCountryID === this.countryID) {
              this.$store.commit('send/SET_TRANSACTION_DATA', {
                prop: 'exchangeRate',
                value: '1 PHP = 1.0000000 PHP'
              })
              this.$store.commit('send/SET_TRANSACTION_DATA', {
                prop: 'receiveAmount',
                value: principalAmount
              })
            }

            if (remcoID !== '') {
              const selectedRemco = this.remcoList.filter(remco => remco.id === remcoID)
              let totalAmount = (Number(sendAmount) + Number(selectedRemco[0].fee))

              if (remcoID === 0 && fixedAmountFlag === 'F') {
                totalAmount = selectedRemco[0]?.body?.gross_total_amount / 100
              }

              this.$store.commit('send/SET_TRANSACTION_DATA', {
                prop: 'totalAmount',
                value: totalAmount
              })

              this.$store.commit('send/SET_TRANSACTION_DATA', {
                prop: 'serviceFee',
                value: selectedRemco[0].fee
              })
            }
          } else {
            // Error handler
          }
        }
      }
    }
  }
}
</script>
