import { endpoints } from '@/api/endpoints'

const nonex = endpoints.use('nonex')

export default {
  getTransactions: ({ commit }, { trxDate, locationID, trxType, wuTrxCategory }) => new Promise((resolve) => {
    nonex.transactions(trxDate, locationID, trxType, wuTrxCategory || '')
      .then(({ data }) => {
        if (trxType === 1) {
          commit('SET_TRANSACTIONS', data.result.filter(item => item.form_number))
        } else {
          commit('SET_TRANSACTIONS', data.result)
        }
        resolve(data)
      })
  }),
  getInquireResult: ({ commit }, payload) => new Promise((resolve) => {
    nonex.consolidator(payload)
      .then(({ data }) => {
        commit('SET_INQUIRE_RESULT', data)
        resolve(data)
      })
  }),
  gcashInquireTrx: ({ commit }, payload) => new Promise((resolve) => {
    nonex.gcashInquireTrx(payload)
      .then(({ data }) => {
        let inquireData = {
          data: [],
          errors: []
        }

        let resultInfo = {}
        let currentData = data

        if (data?.result) {
          resultInfo = {
            remco_id: 26,
            remco_name: 'GCash Payout',
            payout_route: '/v1/remit/nonex/gcash-payout/payout'
          }

          resultInfo = Object.assign({}, resultInfo, currentData)
          inquireData = Object.assign({}, inquireData, { data: [resultInfo] })
        } else {
          resultInfo = {
            remco_id: 26,
            remco_name: 'GCash Payout'
          }

          currentData = Object.assign({}, currentData, { error: data.error === 'ORDER_STATUS_INVALID' ? 'Already Claimed' : data.error })
          resultInfo = Object.assign({}, resultInfo, currentData)
          inquireData = Object.assign({}, inquireData, { errors: [resultInfo] })
        }

        commit('SET_INQUIRE_RESULT', inquireData)
        resolve(inquireData)
      })
  }),
  getPayoutStatus: ({ commit }, payload) => new Promise((resolve) => {
    nonex.payoutStatus(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  getRemcos: ({ commit }) => new Promise((resolve) => {
    nonex.remcos()
      .then(({ data }) => {
        commit('SET_REMCO_LIST_RESULT', data)
        resolve(data)
      })
  }),
  updateCebuanaIntBeneficiary: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonex.cebuanaIntUpdateBeneficiary(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  executePayout: ({ commit }, payload) => new Promise((resolve, reject) => {
    delete payload.formData.endpoint

    nonex.executePayout(payload.endpoint, payload.formData)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  retryPayout: ({ commit }, payload) => new Promise((resolve, reject) => {
    nonex.retryPayout(payload.endpoint, payload.formData)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getSendOutServiceFee: ({ commit }, { amount, agentCode, branchCode }) => new Promise((resolve) => {
    nonex.sendOutServiceFee(amount, agentCode, branchCode)
      .then(({ data }) => {
        commit('SET_SENDOUT_SERVICE_FEES', data.result)
        resolve(data)
      })
  }),
  getRemcoServiceFee: ({ commit }, payload) => new Promise((resolve) => {
    nonex.remcoServiceFee(payload.payout_amount, payload.remco_id)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  executeSendout: ({ commit }, { endpoint, payload }) => new Promise((resolve) => {
    nonex.sendout(endpoint, payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  getRemcoStateCities: ({ commit }, { state, city, remcoId }) => new Promise((resolve) => {
    nonex.getRemcoStateCity(state, city, remcoId)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  uploadACR: ({ commit }, payload) => new Promise((resolve) => {
    nonex.uploadACR(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  cebuanaFindClient: ({ commit }, customer) => new Promise((resolve) => {
    nonex.cebuanaFindClient(customer)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  cebuanaAddClient: ({ commit }, { customer, currUser }) => new Promise((resolve) => {
    let sourceOfFund = null

    switch (customer.source_found) {
      case 'Salary/Income':
        sourceOfFund = 1
        break
      case 'Savings':
      case 'Inheritance':
        sourceOfFund = 4
        break
      default:
        sourceOfFund = 4
        break
    }

    const payload = {
      first_name: customer.first_name,
      last_name: customer.last_name,
      birth_date: customer.birth_date,
      cellphone_country_id: '63',
      contact_number: customer.mobile_no || '0',
      telephone_country_id: '0',
      telephone_area_code: '',
      telephone_number: '',
      country_address_id: '166',
      province_address: customer.province,
      current_address: `${customer.address} ${customer.municipality}`,
      user_id: currUser.user_id,
      source_of_fund_id: sourceOfFund,
      tin: '',
      agent_code: currUser.location_code
      // agent_code: '01030063'
    }

    nonex.cebuanaAddClient(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  cebuanaViewBeneficiary: ({ commit }, clientID) => new Promise((resolve) => {
    nonex.cebuanaViewBeneficiary(clientID)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  cebuanaAddBeneficiary: ({ commit }, { beneficiary, currUser, clientID }) => new Promise((resolve) => {
    const payload = {
      first_name: beneficiary.first_name,
      middle_name: beneficiary.middle_name,
      last_name: beneficiary.last_name,
      sender_client_id: clientID,
      birth_date: beneficiary.birth_date,
      cellphone_country_id: '63',
      contact_number: '',
      telephone_country_id: '0',
      telephone_area_code: '02',
      telephone_number: '',
      country_address_id: '166',
      birth_country_id: '166',
      province_address: '',
      address: '',
      user_id: currUser.user_id,
      zip_code: '',
      occupation: '',
      state_id_address: '0',
      tin: ''
    }

    nonex.cebuanaAddBeneficiary(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  cebuanaSendExecute: ({ commit }, customer) => new Promise((resolve) => {
    nonex.cebuanaSendExecute(customer)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  getUnitellerOccupation: ({ commit }, customer) => new Promise((resolve) => {
    nonex.getUnitellerOccupation(customer)
      .then(({ data }) => {
        resolve(data)
      })
  }),
  cebuanaGetGender: ({ commit }) => new Promise((resolve) => {
    // Call the cebuanaGetGender method in nonex api module and change the state using SET_CEBUANA_GENDER_LIST mutation
    nonex.cebuanaGetGender()
      .then(({ data }) => {
        commit('SET_CEBUANA_GENDER_LIST', data.result.Gender)
        resolve(data)
      })
  }),
  cebuanaGetPurpose: ({ commit }) => new Promise((resolve) => {
    // Call the cebuanaGetPurpose method in nonex api module and change the state using SET_CEBUANA_PURPOSE_LIST mutation
    nonex.cebuanaGetPurpose()
      .then(({ data }) => {
        commit('SET_CEBUANA_PURPOSE_LIST', data.result.PurposeOfTransaction)
        resolve(data)
      })
  }),
  cebuanaGetRelation: ({ commit }) => new Promise((resolve) => {
    // Call the cebuanaGetRelation method in nonex api module and change the state using SET_CEBUANA_RELATION_LIST mutation
    nonex.cebuanaGetRelation()
      .then(({ data }) => {
        commit('SET_CEBUANA_RELATION_LIST', data.result.RelationshipToSender)
        resolve(data)
      })
  }),
  cebuanaGetOccupation: ({ commit }) => new Promise((resolve) => {
    // Call the cebuanaGetOccupation method in nonex api module and change the state using SET_CEBUANA_OCCUPATION_LIST mutation
    nonex.cebuanaGetOccupation()
      .then(({ data }) => {
        commit('SET_CEBUANA_OCCUPATION_LIST', data.result.Occupation)
        resolve(data)
      })
  })
}
