<template>
  <v-row>
    <v-col :cols="12">
      <v-chip class="red--text px-5 py-5" color="rgba(255, 3, 3, 0.2)" style="font-weight: 600 !important;border-radius: 25px !important;">
        Customer is on PEP
      </v-chip>
    </v-col>
    <v-col :cols="12">
      <v-row>
        <v-col :cols="6">
          Name<br><strong>{{ pnaAlertDialog.details.last_name }}, {{ pnaAlertDialog.details.first_name }}</strong>
        </v-col>
        <v-col :cols="6">
          Birth Date<br><strong>{{ pnaAlertDialog.details.birth_date || '-' }}</strong>
        </v-col>
        <v-col :cols="6">
          Position<br><strong>{{ pnaAlertDialog.details.position_name || '-' }}</strong>
        </v-col>
        <v-col :cols="6">
          Area Covered<br><strong>{{ pnaAlertDialog.details.area_covered || '-' }}</strong>
        </v-col>
        <v-col :cols="6">
          Link to PEP<br><strong>{{ pnaAlertDialog.details.link_pep_name || '-' }}</strong>
        </v-col>
        <v-col :cols="6"></v-col>
        <v-col :cols="12">
          Transaction cannot proceed. Please contact Compliance for advice.
          <br><br>
          <span class="red--text">Reason:</span> Customer is a Politically Exposed Person (PEP)
          <br>
          <v-checkbox
            v-model="pnaAlertDialog.isFalsePositive"
            align="start"
            :ripple="false"
            class="customFont"
            label="I have verified the customer in front and the fraudster in the image are not the same person."
            hide-details
          />
          <br>
          Disclaimer: Please bear in mind that by vouching his client as false positive, you fully understood the the Company may hold you accountable in case the customer and the fraudster above is the same person.
          <br><br>
          For compliance process and procedure, click <a class="font-weight-bold" href="#">HERE</a>
          <br><br>
          For immediate concern, please send an email to <a class="font-weight-bold" href="#">Compliance Team</a> or call 892-0971 or 812-9208 loc. 232.
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { aesDecrypt } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      pnaAlertDialog: 'compliance/pnaAlertDialog'
    }),
    currentID () {
      let img = this.pnaAlertDialog.customer.img || '{}'
      img = JSON.parse(img)
      const ids = Object.values(img)

      if (ids.length >= 1) {
        return this.getStoragePath(ids[0])
      }

      return ''
    }
  },
  methods: {
    getStoragePath (value) {
      if (value.usp2 === 'true') {
        return `${aesDecrypt(process.env.VUE_APP_STORAGE_BASE_URL)}/${this.pnaAlertDialog.customer.customer_id}/${value.img}`
      } else {
        return `${aesDecrypt(process.env.VUE_APP_STORAGE_USP1_URL)}/${this.pnaAlertDialog.customer.customer_id}/${value.img}`
      }
    }
  }
}
</script>

<style>
.v-input.customFont .v-label {
  font-size: 15px !important;
}
</style>
