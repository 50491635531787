<template>
  <div class="ph-container">
    <!-- <Header :title="header.title" :img="header.img" /> -->
    <v-container fluid class="ph-nonex-container">
      <v-row dense>
        <v-col v-for="service in services" :key="service.id" cols="12" sm="6" md="6" lg="3">
          <v-card v-ripple class="ph-nonex-button mt-5" elevation="0" router :to="service.to" >
            <div class="ph-nonex-button-icon-container">
              <v-avatar
                :color="service.color"
                size="58"
                class="ph-nonex-button-icon"
              >
                <img class="ph-nonex-button-icon-img" :src="require('../../assets/images/' + service.image + '.svg')" />
              </v-avatar>
            </div>
            <div class="ph-nonex-button-text-container">
              <h1 class="ph-nonex-button-title">{{service.title}}</h1>
              <p class="ph-nonex-button-description">{{service.description}}</p>
            </div>
          </v-card>
      </v-col>
      </v-row>
      <no-signature-dialog />
    </v-container>
  </div>
</template>
<script>
// import Header from '@/components/partials/Header.vue'
import noSignatureDialog from '@/components/wacom/noSignatureDialog.vue'
import wacomMixin from '@/mixins/wacom.js'

export default {
  name: 'PeraRemitLanding',
  mixins: wacomMixin,
  components: {
    // Header
    noSignatureDialog
  },
  data () {
    return {
      header: {
        title: 'Pera Remit',
        img: 'nonex-illustration'
      },
      services: [
        { id: 1, color: 'rgba(253, 209, 0, 0.1)', title: 'Send Money', description: 'Send money to recipient', image: 'nonex-send-icon', to: '/pera-remit/send' },
        { id: 2, color: 'rgba(139, 81, 255, 0.1)', title: 'Receive Money', description: 'Claim remittance transactions here', image: 'nonex-receive-icon', to: '/pera-remit/receive' },
        { id: 3, color: 'rgba(247, 111, 52, 0.1)', title: 'Pay Status', description: 'Check and Verify the current status of your transaction', image: 'nonex-status-icon', to: '/pera-remit/pay-status' },
        { id: 4, color: 'rgba(209, 44, 127, 0.1)', title: 'Quick Pay', description: 'Bills Payment for International Transactions', image: 'nonex-quickpay-icon', to: '/pera-remit/quickpay' },
        { id: 5, color: 'rgba(70, 183, 70, 0.1)', title: 'Adjustments', description: 'Make changes to your previous transaction', image: 'nonex-adjustment-icon', to: '/pera-remit/nonex-adjustment' },
        { id: 6, color: 'rgba(5, 172, 229, 0.1)', title: 'F2 Zoom', description: 'View the transaction information of each country', image: 'nonex-f2zoom-icon', to: '/pera-remit/f2-zoom' },
        { id: 7, color: 'rgba(102, 51, 204, 0.1)', title: 'Manual Entry', description: 'Manually insert a processed transaction here', image: 'manual-encoding', to: '/pera-remit/manual-entry' }
      ]
    }
  },
  beforeMount () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
  },
  mounted () {
    this.$store.commit('wacom/SET_WACOM_IDLE_DIALOG', true)
  }
}
</script>
<style scoped>
  .ph-container {
    margin: 0 15px;
  }

  .v-card.ph-nonex-button {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
    /* border: 1px solid #D0D0D0; */
  }

  .ph-nonex-button-text-container {
    padding: 30px;
    width: 100%;
  }

  .ph-nonex-button-title {
    font-family: "Baloo Extra Bold";
    color: #1A2791;
    font-size: 24px;
  }

  .ph-nonex-button-icon-container {
    padding: 30px;
    width: 100%;
    display: flex;
    padding-bottom: 0;
    /* margin-right: 20px !important; */
  }

  .ph-nonex-button-description {
    font-size: 16px;
  }

  /* .ph-nonex-button-icon {
    margin: auto;
  } */

  .ph-nonex-button-icon-img {
    margin: auto;
    opacity: 1 !important;
    transform: scale(0.35);
    border-radius: 0;
  }

  @media only screen and (min-width: 500px) {
    .v-card.ph-nonex-button {
      display: flex;
      cursor: pointer;
      box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
      border-radius: 12px;
      height: 250px;
    }

    .ph-nonex-button:nth-of-type(odd) {
      margin-left: 5px;
    }

    .ph-nonex-button:nth-of-type(even) {
      margin-right: 5px;
    }

    .ph-nonex-button-text-container {
      width: 80%;
    }

    .ph-nonex-button-icon-container {
      width: 20%;
      /* margin-right: unset !important; */
    }

    .ph-nonex-button-icon-img {
      margin: auto;
      opacity: 1 !important;
      transform: scale(0.4);
    }
  }

  @media only screen and (min-width: 376px) {
    .ph-nonex-container {
      margin: 0;
    }
  }
</style>
