const requireModule = require.context('./modules/', false, /\.js$/)

const modules = {}

requireModule.keys().forEach(fileName => {
  const moduleName = fileName.replace(/(\.\/|\.js)/g, '')
  modules[moduleName] = requireModule(fileName).default
})

export const endpoints = {
  use: name => modules[name]
}
