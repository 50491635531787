<template>
  <v-container fluid>
    <EntryDetails v-if="currentPage === 0" />
    <AssignCustomer v-if="currentPage === 1" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import EntryDetails from '@/components/manual/EntryDetails.vue'
import AssignCustomer from '@/components/manual/AssignCustomer.vue'

export default {
  computed: {
    ...mapGetters({
      currentPage: 'manual/currentPage',
      selectedEntry: 'manual/selectedEntry'
    })
  },
  components: {
    EntryDetails,
    AssignCustomer
  },
  mounted () {
    if (!this.selectedEntry) {
      this.$router.push({
        path: '/pera-remit/manual-entry'
      })
    }
  }
}
</script>
