<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
      :items="items"
      divider=">"
      ></v-breadcrumbs>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-data-table
              :headers="headers"
              :items="adjRequestList"
              class="custom-table"
              :calculate-widths="true"
            >
              <template v-slot:[`item.remco_id`]="{ item }">
                {{ getRemcoName(item.remco_id) }}
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDate(item.created_at) }}
              </template>
              <template v-slot:[`item.currency_id`]="{ item }">
                {{ item.currency_id === 1 ? 'PHP' : 'USD' }}
              </template>
              <template v-slot:[`item.adj_category`]="{ item }">
                {{ item.adj_category.toUpperCase() }}
              </template>
              <!-- <template v-slot:[`item.trx_date`]="{ item }">
                {{ formatDate(item.trx_date) }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ !isNaN(item.amount) ? formatAmount(item.amount) : 0 }}
              </template>
              <template v-slot:[`item.is_cash`]="{ item }">
                {{ item.is_cash === '1' ? 'Deliver/Pick-up by Cash' : 'Deposit to Account' }}
              </template> -->
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.status === 1 || item.status === '1'">
                  <v-icon color="orange" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.status === 2 || item.status === '2'">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Approved
                </span>
                <span class="ph-nonex-status-container red--text" v-if="item.status === 3 || item.status === '3'">
                  <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                  Disapproved
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <!-- <v-tooltip right v-if="item.status === 1 || item.status === '1'" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="displayAdjData(item)" color="primary" icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Info</span>
                </v-tooltip> -->
                <v-tooltip right v-if="item.status === 1 || item.status === '1'" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="displayAdjData(item, 'approve')" color="primary" icon>
                      <v-icon>mdi-thumb-up</v-icon>
                    </v-btn>
                  </template>
                  <span>Approve</span>
                </v-tooltip>
                <v-tooltip right v-if="item.status === 1 || item.status === '1'" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="item.status === 1 || item.status === '1'" @click="displayAdjData(item, 'disapprove')" color="primary" icon>
                      <v-icon>mdi-thumb-down</v-icon>
                    </v-btn>
                  </template>
                  <span>Disapprove</span>
                </v-tooltip>
                <!-- <v-btn v-if="item.status !== 1 && item.status !== '1'" disabled color="primary" icon>
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn> -->
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-fab-transition>
        <v-btn
          color="primary"
          fab
          large
          fixed
          dark
          bottom
          right
          @click="newAdjustmentEntry"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { renderToast } from '@/utils'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
  name: 'NonexAdjustmentIndex',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Nonex Adjustment',
          disabled: true,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Remittance Company',
          sortable: true,
          value: 'remco_id'
        },
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'created_at'
        },
        {
          text: 'Control Date',
          sortable: true,
          value: 'control_number'
        },
        {
          text: 'Sender\'s Name',
          sortable: true,
          value: 'sender_name'
        },
        {
          text: 'Receiver\'s Name',
          sortable: true,
          value: 'receiver_name'
        },
        {
          text: 'Currency',
          sortable: true,
          value: 'currency_id'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'principal_amount'
        },
        {
          text: 'Service Charge',
          sortable: true,
          value: 'service_charge'
        },
        {
          text: 'DST',
          sortable: true,
          value: 'dst_amount'
        },
        {
          text: 'Type',
          sortable: true,
          value: 'adj_category'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      adjRequestList: 'nonexAdjustment/adjRequestList',
      collections: 'collections'
    })
  },
  async beforeMount () {
    await this.$store.dispatch('nonexAdjustment/getAdjRequestList', {
      trx_date: this.currUser.trx_date,
      location_id: this.currUser.location_id
    })
  },
  methods: {
    renderToast,
    getRemcoName (id) {
      let remcoName = ''

      switch (id) {
        case 1:
          remcoName = 'iRemit'
          break
        case 2:
          remcoName = 'BPI'
          break
        case 7:
          remcoName = 'Transfast'
          break
        case 8:
          remcoName = 'Metrobank'
          break
        case 9:
          remcoName = 'Cebuana Lhuillier'
          break
        case 10:
          remcoName = 'USSC'
          break
        case 11:
          remcoName = 'Express Money'
          break
        case 12:
          remcoName = 'Ria'
          break
        case 13:
          remcoName = 'Landbank'
          break
        case 14:
          remcoName = 'USSC DOLE'
          break
        case 15:
          remcoName = 'USSC DOLE'
          break
        case 16:
          remcoName = 'InstantCash'
          break
        case 17:
          remcoName = 'JapanRemit'
          break
        case 19:
          remcoName = 'Cebuana International'
          break
        case 20:
          remcoName = 'Uniteller'
          break
        case 21:
          remcoName = 'Remitly'
          break
        case 22:
          remcoName = 'Ayannah'
          break
        case 24:
          remcoName = 'Intel Express'
          break
        case 26:
          remcoName = 'GCash Payout'
          break
        case 27:
          remcoName = 'Fastpay'
          break
        case 28:
          remcoName = 'Teleremit'
          break
        default:
          remcoName = 'Not Provided'
      }

      return remcoName
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    formatDate (date) {
      return format(parseISO(date), 'yyyy-MM-dd HH:mm:ss')
    },
    newAdjustmentEntry () {
      this.$router.push('/pera-remit/nonex-adjustment/form')
    }
  }
}
</script>
