<template>
  <v-container fluid>
    <CustomerSearch v-if="currentPage === 0" />
    <NewTransaction v-if="currentPage === 1" />
    <SuccessfulTransaction v-if="currentPage === 2" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerSearch from '@/components/eload/CustomerSearch.vue'
import NewTransaction from '@/components/eload/NewTransaction.vue'
import SuccessfulTransaction from '@/components/eload/SuccessfulTransaction.vue'

export default {
  computed: {
    ...mapGetters({
      currentPage: 'eload/currentPage'
    })
  },
  components: {
    CustomerSearch,
    NewTransaction,
    SuccessfulTransaction
  },
  async beforeMount () {
    try {
      const req = await this.$store.dispatch('eload/getProducts')

      if (req.code === 200) {
        this.$store.commit('eload/SET_PRODUCTS', req.result)
      } else {
        // Test
      }
    } catch (e) {
      // Test
    }
  }
}
</script>
