<template>
  <div class="ph-container">
    <!-- <Header :title="header.title" :img="header.img" /> -->
    <v-container fluid class="ph-nonex-container">
      <v-row dense>
        <v-col v-for="service in services" :key="service.id" cols="12" sm="6" md="6" lg="3">
          <v-card v-ripple class="ph-nonex-button mt-5" elevation="0" router :to="service.to" >
            <div class="ph-nonex-button-icon-container">
              <v-avatar
                :color="service.color"
                size="58"
                class="ph-nonex-button-icon"
              >
                <img class="ph-nonex-button-icon-img" :src="require('../../assets/images/' + service.image + '.svg')" />
              </v-avatar>
            </div>
            <div class="ph-nonex-button-text-container">
              <h1 class="ph-nonex-button-title" :style="'color:' + service.text_color">{{service.title}}</h1>
              <p class="ph-nonex-button-description">{{service.description}}</p>
            </div>
          </v-card>
      </v-col>
      </v-row>
      <no-signature-dialog />
    </v-container>
  </div>
</template>
<script>
// import Header from '@/components/partials/Header.vue'
import noSignatureDialog from '@/components/wacom/noSignatureDialog.vue'
import wacomMixin from '@/mixins/wacom.js'

export default {
  name: 'CardsLanding',
  mixins: wacomMixin,
  components: {
    // Header
    noSignatureDialog
  },
  data () {
    return {
      header: {
        title: 'Pera Remit',
        img: 'nonex-illustration'
      },
      services: [
        // { id: 1, color: 'rgba(253, 209, 0, 0.1)', text_color: '#C9A600', title: 'Pera Redeem', description: 'Sample description here', image: 'cards/pera-redeem-icon', to: '/cards/#' },
        // { id: 2, color: 'rgba(139, 81, 255, 0.1)', text_color: '#6633CC', title: 'Pera EON', description: 'Sample description here', image: 'cards/pera-eon-icon', to: '/cards/#' },
        // { id: 3, color: 'rgba(247, 111, 52, 0.1)', text_color: '#F76F34', title: 'ATM Withdrawal', description: 'Sample description here', image: 'cards/atm-withdrawal-icon', to: '/cards/#' },
        // { id: 4, color: 'rgba(209, 44, 127, 0.1)', text_color: '#D12C7F', title: 'Pera Bank', description: 'Sample description here', image: 'cards/pera-bank-icon', to: '/cards/pera-bank' },
        { id: 1, color: 'rgba(5, 172, 229, 0.1)', text_color: '#05ACE5', title: 'Pera Debit Card', description: 'Purchase and Replace Cards', image: 'cards/pera-debit-card-icon', to: '/cards/pera-debit-card' },
        { id: 2, color: 'rgba(70, 183, 70, 0.1)', text_color: '#46B746', title: 'Card Inventory', description: 'Cards Acknowledgement and Allocation', image: 'cards/card-inventory-icon', to: '/cards/inventory' },
        { id: 3, color: 'rgba(209, 44, 127, 0.1)', text_color: '#D12C7F', title: 'Pera Bank', description: 'Sample description here', image: 'cards/pera-bank-icon', to: '/cards/pera-bank' }
      ]
    }
  },
  beforeMount () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
  },
  mounted () {
    this.$store.commit('wacom/SET_WACOM_IDLE_DIALOG', true)
  }
}
</script>
<style scoped>
  .ph-container {
    margin: 0 15px;
  }

  .v-card.ph-nonex-button {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
    /* border: 1px solid #D0D0D0; */
  }

  .ph-nonex-button-text-container {
    padding: 30px;
    width: 100%;
  }

  .ph-nonex-button-title {
    font-family: "Baloo Extra Bold";
    font-size: 24px;
  }

  .ph-nonex-button-icon-container {
    padding: 30px;
    width: 100%;
    display: flex;
    padding-bottom: 0;
    /* margin-right: 20px !important; */
  }

  .ph-nonex-button-description {
    font-size: 16px;
  }

  /* .ph-nonex-button-icon {
    margin: auto;
  } */

  .ph-nonex-button-icon-img {
    margin: auto;
    opacity: 1 !important;
    transform: scale(0.35);
    border-radius: 0;
  }

  @media only screen and (min-width: 500px) {
    .v-card.ph-nonex-button {
      display: flex;
      cursor: pointer;
      box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
      border-radius: 12px;
      height: 250px;
    }

    .ph-nonex-button:nth-of-type(odd) {
      margin-left: 5px;
    }

    .ph-nonex-button:nth-of-type(even) {
      margin-right: 5px;
    }

    .ph-nonex-button-text-container {
      width: 80%;
    }

    .ph-nonex-button-icon-container {
      width: 20%;
      /* margin-right: unset !important; */
    }

    .ph-nonex-button-icon-img {
      margin: auto;
      opacity: 1 !important;
      transform: scale(0.4);
    }
  }

  @media only screen and (min-width: 376px) {
    .ph-nonex-container {
      margin: 0;
    }
  }
</style>
