const showWacom = state => state.showWacom
const showWacomConsent = state => state.showWacomConsent
const showWacomIdle = state => state.showWacomIdle
const showWacomDataConsentFirst = state => state.showWacomDataConsentFirst
const showWacomDataConsentSecond = state => state.showWacomDataConsentSecond
const showWacomDataConsentThird = state => state.showWacomDataConsentThird
const showWacomDataConsentFourth = state => state.showWacomDataConsentFourth
const showWacomDataConsentFifth = state => state.showWacomDataConsentFifth
const showWacomDataConsentSixth = state => state.showWacomDataConsentSixth
const showWacomDataConsentSeventh = state => state.showWacomDataConsentSeventh
const consentValues = state => state.consentValues

export default {
  showWacom,
  showWacomIdle,
  showWacomConsent,
  showWacomDataConsentFirst,
  showWacomDataConsentSecond,
  showWacomDataConsentThird,
  showWacomDataConsentFourth,
  showWacomDataConsentFifth,
  showWacomDataConsentSixth,
  showWacomDataConsentSeventh,
  consentValues
}
