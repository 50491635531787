export default {
  SET_CURRENT_PAGE (state, payload) {
    state.currentPage = payload
  },
  SET_RATES (state, payload) {
    state.rates = payload
  },
  SET_SUCCESS_DATA (state, payload) {
    state.successData = payload
  },
  SET_TRANSACTION_DATA (state, { prop, value }) {
    state.transactionData[prop] = value
  },
  SET_ALERT (state, data) {
    state.alert = { ...data }
  }
}
