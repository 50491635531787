export default {
  SET_CURRENT_TRANSACTION (state, payload) {
    state.currentTrx = payload
  },
  SET_PAYOUT_CURRENT_CURRENCY (state, payload) {
    state.payoutCurrencyType = payload
  },
  SET_PAYOUT_FULFILL_CURRENT_CURRENCY (state, payload) {
    state.payoutFulfillCurrencyType = payload
  },
  SET_CURRENT_SENDOUT_FULFILL_TRANSACTION (state, payload) {
    state.currentSendFulfillTrx = payload
  },
  SET_CURRENT_PAYOUT_FULFILL_TRANSACTION (state, payload) {
    state.currentReceiveFulfillTrx = payload
  },
  SET_FOREIGN_REF_NO (state, payload) {
    state.payoutForeignRefNo = payload
  },
  SET_SEND_FULFILL_FOREIGN_REF_NO (state, payload) {
    state.sendoutReleaseForeignRefNo = payload
  },
  SET_RECEIVE_FULFILL_FOREIGN_REF_NO (state, payload) {
    state.payoutReleaseForeignRefNo = payload
  },
  SET_SHOW_PAYOUT_SEARCH_FORM (state, payload) {
    state.showPayoutSearchForm = payload
  },
  SET_SHOW_PAYOUT_RESULT_FORM (state, payload) {
    state.showPayoutResultForm = payload
  },
  SET_SHOW_PAYOUT_SUCCESS_SCREEN (state, payload) {
    state.showSuccessPayoutScreen = payload
  },
  SET_SHOW_PAYOUT_SUCCESS_DATA (state, payload) {
    state.payoutSuccessData = payload
  },
  SET_SHOW_SENDOUT_FULFILL_SEARCH_FORM (state, payload) {
    state.showSendoutFulfillSearchForm = payload
  },
  SET_SHOW_SENDOUT_FULFILL_RESULT_FORM (state, payload) {
    state.showSendoutFulfillResultForm = payload
  },
  SET_SHOW_SENDOUT_FULFILL_SUCCESS_SCREEN (state, payload) {
    state.showSuccessSendoutFulfillScreen = payload
  },
  SET_SHOW_SENDOUT_FULFILL_SUCCESS_DATA (state, payload) {
    state.sendoutFulfillSuccessData = payload
  },
  SET_SHOW_PAYOUT_FULFILL_SEARCH_FORM (state, payload) {
    state.showPayoutFulfillSearchForm = payload
  },
  SET_SHOW_PAYOUT_FULFILL_RESULT_FORM (state, payload) {
    state.showPayoutFulfillResultForm = payload
  },
  SET_SHOW_PAYOUT_FULFILL_SUCCESS_SCREEN (state, payload) {
    state.showSuccessPayoutFulfillScreen = payload
  },
  SET_SHOW_PAYOUT_FULFILL_SUCCESS_DATA (state, payload) {
    state.payoutFulfillSuccessData = payload
  },
  SET_WU_KYC_MULTIPLE_SEARCH_DATA (state, payload) {
    state.wuKYCMultipleSearchData = payload
  },
  SET_WU_KYC_SINGLE_SEARCH_DATA (state, payload) {
    state.wuKYCSingleSearchData = payload
  },
  SET_QP_CURRENT_PAGE (state, payload) {
    state.quickPayCurrentPage = payload
  },
  SET_QP_FOREIGN_REF_NO (state, payload) {
    state.qpForeignRefNo = payload
  },
  SET_QP_VALIDATE_DATA (state, payload) {
    state.qpValidateData = payload
  },
  SET_QP_SUCCESS_DATA (state, payload) {
    state.qpSuccessData = payload
  },
  SET_MC_RATE_DATA (state, payload) {
    state.mcRateData = payload
  }
}
