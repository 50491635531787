export default {
  tpaId: '',
  peraLoanTransactions: [],
  peraLoanCurrentTrx: null,
  peraLoanCurrentPage: 0,
  collectionProviders: [],
  peraLoanResponseData: null,
  voucherDetails: {},
  loanAccountNo: ''
}
