import { endpoints } from '@/api/endpoints'

const oar = endpoints.use('oar')

export default {
  getOarNumber: ({ commit }, { locationID, type }) => new Promise((resolve) => {
    oar.getOarNumber(locationID, type)
      .then(({ data }) => {
        // commit('SET_OAR_NUMBER', data.result)
        commit('SET_OAR', data.result, { root: true })
        resolve(data)
      })
  })
}
