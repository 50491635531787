<template>
  <div>
    <div v-for="(user_id, index) in Object.keys(reportData.bills)" :key="index" style="width: 750px; font-size:12px; overflow: hidden; margin:15px auto 15px auto;">
      <div style="width: 750px; margin: 4.6px auto 0 auto;">
        <div style="height: 50px;">
          <div style="float: left;"><img src="@/assets/images/ph-logo-horizontal.svg" width="200"></div>
          <div style="float: left; width: 375px; margin:0 0 0 20px;"><h4>CASH ON HAND<br/>As Accounted</h4></div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div style="width: 550px; margin: 0 auto; overflow:hidden;">
        <div style="float: left; text-align: right; font-weight: bold;">
          <div>DATE:</div>
          <div>TELLER\'S NAME:</div>
          <div>BRANCH:</div>
        </div>
        <div style="float: left; text-align: left; margin: 0 0 0 10px">
          <div>{{ params.trx_date }}</div>
          <div>{{ getUserName(user_id) }}</div>
          <div>{{ params.location_name }}</div>
        </div>
      </div>
      <br>
      <div class="clearfix"></div>
      <table class='cashcount-table' style='width: 750px;'>
        <thead>
          <tr>
            <th>DENOMINATION</th>
            <th>NO. OF PCS.</th>
            <th>DOLLAR VALUE</th>
            <th>PESO VALUE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan='4' style='font-weight: bold;'>in DOLLAR</td>
          </tr>
          <!-- Looped -->

          <tr v-for="billDetails, index in getUserBills(user_id, 'usd')" :key="index">
            <td class='text-right' style='padding: 3px 20px 3px 3px;'>{{billDetails.bill}}</td>
            <td class='text-center'>{{billDetails.bills_count}}</td>
            <td class='text-right' style='padding: 3px 20px 3px 3px;'>{{formatAmount(billDetails.usd_amount)}}</td>
            <td></td>
          </tr>
          <tr>
            <td colspan='4' style='font-weight: bold;'>in PESO</td>
          </tr>
          <!-- Looped -->
          <tr v-for="billDetails, index in getUserBills(user_id, 'php')" :key="index">
            <td class='text-right' style='padding: 3px 20px 3px 3px;'>{{billDetails.bill}}</td>
            <td class='text-center'>{{billDetails.bills_count}}</td>
            <td></td>
            <td class='text-right' style='padding: 3px 20px 3px 3px;'>{{formatAmount(billDetails.peso_amount)}}</td>
          </tr>
          <!-- Looped -->
          <tr v-for="billDetails, index in getUserBills(user_id, 'total')" :key="index">
            <td style='font-weight: bold;'>TOTAL</td>
            <td></td>
            <td class='text-right' style='padding: 3px 20px 3px 3px; font-weight:bold;'>{{formatAmount(billDetails.usd_amount)}}</td>
            <td class='text-right' style='padding: 3px 20px 3px 3px; font-weight:bold;'>{{formatAmount(billDetails.peso_amount)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="clearfix"></div>
    <div style="width: 750px; overflow: hidden; margin:15px auto 0 auto;">
      <div style="border: 2px solid #000; padding: 3px; overflow: hidden;">
          <div style="width: 750px;">
              <div style="float: left; width: 476px; text-align: left">
                  &nbsp;
              </div>
              <div style="border-bottom:1px #000 solid; float: left; width: 125px; text-align: left; margin: 0 3px">
                  <div style="text-align: center"><b>DOLLAR</b></div>
              </div>
              <div style="border-bottom:1px #000 solid; float: left; width: 125px; text-align: left; margin: 0 3px">
                  <div style='text-align: center'><b>PESO</b></div>
              </div>
          </div>
          <div v-for="(summaryItem, name) in reportData.cash_summary" :key="name">
            <div style="float: left; width: 476px; text-align: left">
              <div><b>{{ summaryItem.teller }}</b></div>
            </div>
            <div style="float: left; width: 125px; text-align: left; margin: 0 3px">
              <div style="text-align: right">{{formatAmount(summaryItem.usd_ending)}}</div>
            </div>
            <div style="float: left; width: 125px; text-align: left; margin: 0 3px">
              <div style="text-align: right">{{formatAmount(summaryItem.peso_ending)}}</div>
            </div>
          </div>
          <div style="width: 750px;">
            <div style="float: left; width: 476px; text-align: left">
              &nbsp;
            </div>
            <div style="float: left; width: 125px; text-align: left; margin: 0 3px">
              <div style="text-align: right; border-top:3px #000 ridge">&nbsp;</div>
            </div>
            <div style="float: left; width: 125px; text-align: left; margin: 0 3px">
              <div style="text-align: right; border-top:3px #000 ridge">&nbsp;</div>
            </div>
          </div>
      </div>
      <div style='float: left; width: 375px; padding: 5px 0 0 0; margin-bottom: 50px;'>
          <div><b>Prepared By:</b></div>
          <div style="border-bottom: 2px solid #000; width: 280px">&nbsp;</div>
      </div>
      <div style='float: right; width: 375px; padding: 5px 0 0 0; margin-bottom: 50px;'>
          <div><b>Checked By:</b></div>
          <div style="border-bottom: 2px solid #000; width: 280px;">&nbsp;</div>
      </div>
    </div>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'

export default {
  props: [
    'isLoading',
    'reportData',
    'columns',
    'footer',
    'params'
  ],
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  methods: {
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    formatData (data, type) {
      if (type === 'yearFromNow') return this.getAge(data)
      if (type === 'float') return numeral(data).format('0,0.00')
      if (type === 'accounting') return this.getAccountingFormat(data)
      if (type === 'transactionCount') return ((data === 0) ? 0 : 1)
      if (type === 'insuranceProvider') return this.getProviderName(data)
      if (type === 'accountNumber') return this.formatAccountNumber(data)

      return data
    },
    formatAccountNumber (data) {
      const str = String(data)

      return str.slice(-4).padStart(str.length, 'X')
    },
    getAccountingFormat (amount) {
      const number = Number(amount)

      if (number < 0) {
        return `(${numeral(Math.abs(amount)).format('0,0.00')})`
      }

      return numeral(amount).format('0,0.00')
    },
    printData () {
      var divToPrint = document.getElementById('csh_turnover')
      var newWin = window.open('')
      newWin.document.write(divToPrint.outerHTML)
      newWin.print()
      newWin.close()
    },
    getAge (dateString) {
      var today = new Date()
      var birthDate = new Date(dateString)
      var age = today.getFullYear() - birthDate.getFullYear()
      var m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    getUserBills (userId, currency) {
      const billData = this.reportData.bills[userId].filter(item => {
        switch (currency) {
          case 'usd':
            return item.currency_name === 'US Dollar'
          case 'php':
            return item.currency_name === 'Peso'
          case 'total':
            return item.currency_name === 'TOTAL'
          default:
            return item.currency_name === 'TOTAL'
        }
      })

      return billData
    },
    getUserName (currentId) {
      const cashSummary = this.reportData.cash_summary
      const userData = Object.keys(cashSummary).filter((user) => {
        if (cashSummary[user].user_id === parseInt(currentId)) {
          return cashSummary[user]
        }
      })

      console.log(userData)

      if (userData.length > 0) {
        return userData[0]
      } else {
        return currentId
      }
    }
  }
}
</script>
<style>
table.cashcount-table, table.cashcount-table th, table.cashcount-table td {
  border: 1px solid;
  border-collapse: collapse;
}

table.cashcount-table td {
  padding: 1px 5px;
}
</style>
