import { endpoints } from '@/api/endpoints'

const userTrustedDevices = endpoints.use('userTrustedDevices')

export default {
  getEnrolledList: ({ commit }, payload) => new Promise((resolve, reject) => {
    userTrustedDevices.getEnrolledDevices()
      .then(({ data }) => {
        commit('SET_DEVICE_LIST_ITEMS', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  searchEnrolledDevice: ({ commit }, payload) => new Promise((resolve, reject) => {
    userTrustedDevices.searchEnrolledDevice(payload)
      .then(({ data }) => {
        commit('SET_DEVICE_LIST_ITEMS', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  updateData: ({ commit }, payload) => new Promise((resolve, reject) => {
    userTrustedDevices.updateData(payload.id, payload.params)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  updateStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    userTrustedDevices.updateStatus(payload.id, payload.params)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  deleteDevice: ({ commit }, payload) => new Promise((resolve, reject) => {
    userTrustedDevices.deleteDevice(payload.id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
