import { endpoints } from '@/api/endpoints'

const bankDeposit = endpoints.use('bankDeposit')

export default {
  getBankDepositList: ({ commit }, payload) => new Promise((resolve, reject) => {
    bankDeposit.getBankDepositList(payload)
      .then(({ data }) => {
        commit('SET_PERA_BANK_TRANSACTIONS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getBankPartners: ({ commit }) => new Promise((resolve, reject) => {
    bankDeposit.getBankPartners()
      .then(({ data }) => {
        commit('SET_PERA_BANK_PARTNERS', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getBankDetails: ({ commit }) => new Promise((resolve, reject) => {
    bankDeposit.getBankDetails()
      .then(({ data }) => {
        commit('SET_PERA_BANK_PARTNER_DETAILS', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getUBPToken: ({ commit }) => new Promise((resolve, reject) => {
    bankDeposit.getUBPToken()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  executeUBPCashIn: ({ commit }, payload) => new Promise((resolve, reject) => {
    bankDeposit.executeUBPCashIn(payload.data, payload.token)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  retryUBPCashIn: ({ commit }, payload) => new Promise((resolve, reject) => {
    bankDeposit.retryUBPCashIn(payload.data, payload.token)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  inquireUBPCashIn: ({ commit }, payload) => new Promise((resolve, reject) => {
    bankDeposit.inquireUBPCashIn(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  executeMBCashIn: ({ commit }, payload) => new Promise((resolve, reject) => {
    bankDeposit.executeMBCashIn(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  retryMBCashIn: ({ commit }, payload) => new Promise((resolve, reject) => {
    bankDeposit.retryMBCashIn(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  inquireMBCashIn: ({ commit }, payload) => new Promise((resolve, reject) => {
    bankDeposit.inquireMBCashIn(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  executeBPICashIn: ({ commit }, payload) => new Promise((resolve, reject) => {
    bankDeposit.executeBPICashIn(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  retryBPICashIn: ({ commit }, payload) => new Promise((resolve, reject) => {
    bankDeposit.retryBPICashIn(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  inquireBPICashIn: ({ commit }, payload) => new Promise((resolve, reject) => {
    bankDeposit.inquireBPICashIn(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
