import { endpoints } from '@/api/endpoints'

const phvc = endpoints.use('phvc')

export default {
  getServiceFeeList: ({ commit }, payload) => new Promise((resolve, reject) => {
    phvc.getServiceFeeList(payload)
      .then(({ data }) => {
        commit('SET_SERVICE_FEE_ITEMS', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  addServiceFee: ({ commit }, payload) => new Promise((resolve, reject) => {
    phvc.addServiceFee(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  updateServiceFee: ({ commit }, payload) => new Promise((resolve, reject) => {
    phvc.updateServiceFee(payload.id, payload.phvc_code, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  deleteServiceFee: ({ commit }, payload) => new Promise((resolve, reject) => {
    phvc.deleteServiceFee({ ...payload })
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getServiceFee: ({ commit }, payload) => new Promise((resolve, reject) => {
    phvc.getServiceFee(payload)
      .then(({ data }) => {
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
