<template>
  <v-row class="ph-nonex-adjustment-form-container">
    <v-row class="ma-3">
      <v-col :cols="12" :md="3">
        <v-form ref="adjSearchForm">
          <v-row class="ph-ibt-form">
            <v-col cols="12">
              <v-autocomplete
                v-model="adjRequestForm.type"
                :items="adjTypes"
                item-text="name"
                item-value="value"
                class="ph-textbox"
                label="Adjustment Type"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="adjRequestForm.control_number"
                label="Control Number"
                color="gray darken-1"
                class="ph-textbox"
                outlined
                :hide-spin-buttons="true"
                :rules="requiredRules"
              />
            </v-col>
            <v-col cols="12">
              <p>Note: Do not include spaces, dashes and special
                characters when inputing a Control Number.</p>
            </v-col>
            <v-col cols="12">
              <v-row class="ph-ibt-action-buttons">
                <v-col cols="6" class="ph-ibt-action-button">
                  <v-btn
                    color="primary"
                    x-large
                    class="ph-action-button"
                    outlined
                    @click="backToListPage"
                  >
                    Back
                  </v-btn>
                </v-col>
                <v-col cols="6" class="ph-ibt-action-button">
                  <v-btn
                    color="primary"
                    x-large
                    class="ph-action-button ph-action-button-proceed-confirm"
                    elevation="0"
                    @click="searchTransaction"
                  >
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog
      v-model="confirmDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          {{ confirmDialogTitle }}
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          {{ confirmDialogText }}
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            class="ph-action-button"
          >
            No
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            class="ph-action-button"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { renderToast } from '@/utils'

export default {
  name: 'NonexAdjustmentSearch',
  props: {
    adjRequestForm: Object
  },
  data () {
    return {
      adjTypes: [
        { name: 'Amend (Send Transaction Only)', value: 'amend' },
        { name: 'Cancel (Send Transaction Only)', value: 'cancel' },
        { name: 'Refund (Send Transaction Only)', value: 'refund' },
        { name: 'Unpay (Receive Transaction Only)', value: 'unpay' }
      ],
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      requiredRules: [
        v => !!v || 'This field is required'
      ]
    }
  },
  beforeMount () {
  },
  methods: {
    renderToast,
    backToListPage () {
      this.$emit('backButton', '')
    },
    searchTransaction () {
      if (this.$refs.adjSearchForm.validate()) {
        this.$emit('searchAdjTransaction')
      } else {
        this.renderToast('error', 'Lookup Error', 'Please complete the form to continue.')
      }
    }
  }
}
</script>
<style scoped>
.ph-ibt-action-button:nth-of-type(2) {
  display: flex;
}

.ph-action-button-proceed-confirm {
  margin-left: auto;
}
</style>
