import api from '@/api'

export default {
  getTPARequest (payload) {
    return api.post('/v1/maintenance/api/maintenance/bills/tpa', payload)
  },
  getCollectionTrx (payload) {
    return api.get(`/v1/transactions/api/trx/pera-dali?trx_date=${payload.trx_date}&location_id=${payload.location_id}&user_id=${payload.user_id}&trx_type=${payload.trx_type}`)
  },
  getLoanProvider (payload) {
    return api.get(`/v1/maintenance/api/collections/loan-provider?location_id=${payload.location_id}`)
  },
  getOtherProvider (payload) {
    return api.get(`/v1/maintenance/api/collections/pera-dali-provider?location_id=${payload.location_id}`)
  },
  executeCollectionTrx (payload) {
    return api.post('/v1/transactions/api/trx/pera-dali', payload)
  },
  loanAccountLookup (payload) {
    return api.post('/v1/loanpaycenter/api/validate/account-number', payload)
  },
  generatePeraLoanVoucher (payload) {
    return api.get(`/v1/maintenance/api/receipt/print-voucher?location_id=${payload.location_id}&trx_date=${payload.trx_date}&ref_no=${payload.ref_no}&trx_type=${payload.trx_type}`)
  },
  getPeraLoanTrx (payload) {
    return api.post('/v1/transactions/api/trx/pera-loan/grid', payload)
  },
  saveCustomerInfo (payload) {
    return api.post('/v1/transactions/api/trx/pera-loan/save-customer-info', payload)
  },
  saveCustomerPensionInfo (payload) {
    return api.post('/v1/transactions/api/trx/pera-loan-pension/save-customer-info', payload)
  },
  saveLoanInfo (payload) {
    return api.put('/v1/transactions/api/trx/pera-loan/save-loan-info', payload)
  },
  saveDisbursement (payload) {
    return api.post('/v1/transactions/api/trx/pera-loan/save-disburse', payload)
  },
  getBanks () {
    return api.get('/v1/transactions/api/trx/pera-loan/get-banks')
  }
}
