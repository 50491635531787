<template>
  <v-container fluid>
    <v-row class="mt-16 mb-4 pt-10 mx-2 mt-sm-4 pt-sm-0 mx-sm-2 mx-md-4" no-gutters>
      <v-col :cols="12" :md="4" class="pt-sm-2">
        <v-text-field
          v-model="searchText"
          class="ph-textbox"
          placeholder="Search User"
          append-icon="mdi-magnify"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="3">
        <v-btn @click="search" class="mt-md-2 ml-md-2 rounded-lg d-block" color="primary" width="150px" x-large>
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-md-12 mb-md-10" no-gutters>
      <v-col :cols="12">
        <v-data-table
          :headers="headers"
          :items="searchResult"
          class="custom-table"
          :calculate-widths="true"
        >
          <template v-slot:[`item.name`]="{ item }">
            <v-avatar class="mr-1" size="25px" :style="avatarColor(`${item.last_name}, ${item.first_name}`)">
              <span class="white--text text-body-2">{{ avatarInitial(`${item.last_name}, ${item.first_name}`) }}</span>
            </v-avatar>
            {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
          </template>
          <template v-slot:[`item.user_type`]="{ item }">
            {{ getUserRole(item.user_type) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn @click="updateUser(item)" color="primary" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="deleteUser(item)" color="primary" icon>
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="addUserDialog" width="40%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 primary--text font-weight-black">
            Add User
          </h3>
          <v-spacer />
          <v-btn @click="addUserDialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="12">
              <v-text-field
                class="ph-textbox"
                label="First Name"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                class="ph-textbox"
                label="Last Name"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                class="ph-textbox"
                label="Job Title"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                class="ph-textbox"
                label="Email Address"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-select
                class="ph-textbox"
                label="User Role"
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 pr-md-6">
          <v-spacer />
          <v-btn color="primary" width="100px">
            Invite
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateUserDialog" width="50%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 primary--text font-weight-black">
            Update User
          </h3>
          <v-spacer />
          <v-btn @click="closeUpdate" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedUser.first_name"
                class="ph-textbox"
                label="First Name"
                readonly
                outlined
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedUser.last_name"
                class="ph-textbox"
                label="Last Name"
                readonly
                outlined
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedUser.email_address"
                class="ph-textbox"
                label="Email Address"
                readonly
                outlined
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-select
                v-model="selectedUser.navigation_ids"
                :items="collections.modules"
                item-text="module_name"
                item-value="id"
                class="ph-textbox"
                label="Access"
                multiple
                outlined
              >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.module_name }} &nbsp;</span>
                <span
                  v-if="index === 1"
                  class="grey--text caption"
                >(+{{ selectedUser.navigation_ids.length - 1 }} more)</span>
              </template>
              </v-select>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-autocomplete
                v-model="selectedUser.user_type"
                :items="collections.userTypes"
                item-text="name"
                item-value="id"
                class="ph-textbox"
                label="User Type"
                outlined
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-autocomplete
                v-model="selectedUser.assign_location_id"
                :items="collections.locations"
                item-text="location_name"
                item-value="location_id"
                class="ph-textbox"
                label="Location"
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 pr-md-6">
          <v-spacer />
          <v-btn
            @click="doUpdate"
            :disabled="!isCompleteUpdateForm"
            color="primary"
            width="100px"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteUserDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete User
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this user?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDelete"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="doDelete"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      searchResult: 'users/searchResult',
      collections: 'collections',
      currUser: 'auth/currUser'
    }),
    isCompleteUpdateForm () {
      return this.selectedUser.user_id &&
        this.selectedUser.user_type &&
        this.selectedUser.navigation_ids &&
        this.selectedUser.assign_location_id
    }
  },
  data () {
    return {
      searchText: '',
      addUserDialog: false,
      updateUserDialog: false,
      deleteUserDialog: false,
      selectedUser: {
        first_name: '',
        last_name: '',
        email_address: '',
        user_type: '',
        navigation_ids: '',
        assign_location_id: ''
      },
      headers: [
        {
          text: 'Name',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Email Address',
          sortable: false,
          value: 'email_address'
        },
        {
          text: 'User Role',
          sortable: true,
          value: 'user_type'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ]
    }
  },
  methods: {
    async search () {
      if (this.searchText) {
        const req = await this.$store.dispatch('users/searchUser', {
          search: this.searchText,
          user_id: this.currUser.user_id,
          module_id: 28,
          access_permission: 'view_access'
        })

        if (req.code === 403) {
          renderToast('error', req.message, req.error.message)
        }
      }
    },
    closeUpdate () {
      this.selectedUserReset()
      this.updateUserDialog = false
    },
    closeDelete () {
      this.selectedUserReset()
      this.deleteUserDialog = false
    },
    getUserRole (id) {
      if (id) {
        const role = this.collections.userTypes.filter(item => item.id === Number(id))
        if (role.length === 1) return role[0].name
        return ''
      }
      return ''
    },
    selectUser (data) {
      this.selectedUser = {
        ...this.selectedUser,
        ...data
      }

      if (this.selectedUser.user_type) {
        this.selectedUser.user_type = Number(this.selectedUser.user_type)
      }

      if (this.selectedUser.navigation_ids) {
        const navigationIDs = this.selectedUser.navigation_ids.split(',')
        this.selectedUser.navigation_ids = navigationIDs.map(item => {
          return Number(item)
        })
      }
    },
    updateUser (data) {
      this.selectUser(data)
      this.updateUserDialog = true
    },
    deleteUser (data) {
      this.selectUser(data)
      this.deleteUserDialog = true
    },
    async doDelete () {
      if (this.selectedUser.user_id) {
        const req = await this.$store.dispatch('users/deleteUser', this.selectedUser.user_id)

        if (req.code === 200) {
          this.search()
          this.selectedUserReset()
          this.deleteUserDialog = false
          this.renderToast('success', 'Success', 'User has been deleted')
        } else if (req.code === 403) {
          renderToast('error', req.message, req.error.message)
        } else {
          this.renderToast('error', 'Error', 'Failed to delete user')
        }
      }
    },
    async doUpdate () {
      if (this.isCompleteUpdateForm) {
        const req = await this.$store.dispatch('users/allocateLocation', {
          location_id: this.selectedUser.assign_location_id,
          user_id: this.selectedUser.user_id,
          user_type: this.selectedUser.user_type,
          assign_location_by: this.currUser.user_id,
          navigation_ids: this.selectedUser.navigation_ids.join(','),
          module_id: 28,
          access_permission: 'create_access'
        })

        if (req.code === 200) {
          this.search()
          this.selectedUserReset()
          this.updateUserDialog = false
          this.renderToast('success', 'Success', 'User details have been updated')
        } else if (req.code === 403) {
          renderToast('error', req.message, req.error.message)
        } else {
          this.renderToast('error', 'Error', 'Failed to update user details')
        }
      }
    },
    selectedUserReset () {
      this.selectedUser = {
        first_name: '',
        last_name: '',
        email_address: '',
        user_type: '',
        navigation_ids: '',
        assign_location_id: ''
      }
    },
    renderToast,
    toProperCase,
    avatarColor,
    avatarInitial
  },
  async beforeMount () {
    await this.$store.dispatch('getAllLocations', 0)
    const req = await this.$store.dispatch('getAllModules', {
      module_code: 0,
      user_id: this.currUser.user_id,
      module_id: 28,
      access_permission: 'view_access'
    })

    if (req.code === 403) {
      renderToast('error', req.message, req.error.message)
    }
  }
}
</script>
