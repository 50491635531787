export default {
  billers: state => state.billers,
  tpaId: state => state.tpaId,
  peraLoanTransactions: state => state.peraLoanTransactions,
  peraLoanCurrentPage: state => state.peraLoanCurrentPage,
  collectionProviders: state => state.collectionProviders,
  peraLoanResponseData: state => state.peraLoanResponseData,
  peraLoanCurrentTrx: state => state.peraLoanCurrentTrx,
  voucherDetails: state => state.voucherDetails,
  loanAccountNo: state => state.loanAccountNo
}
