import { render, staticRenderFns } from "./RequestsIndex.vue?vue&type=template&id=ede824b0&scoped=true"
import script from "./RequestsIndex.vue?vue&type=script&lang=js"
export * from "./RequestsIndex.vue?vue&type=script&lang=js"
import style0 from "./RequestsIndex.vue?vue&type=style&index=0&id=ede824b0&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ede824b0",
  null
  
)

export default component.exports