import api from '@/api'

export default {
  getEreceiptID (payload) {
    return api.post('/v1/maintenance/api/ereceipt/lookup', payload)
  },
  getEreceiptByID (payload) {
    return api.get('v1/ereceipt/receipt-detail/' + payload)
  },
  downloadEreceipt (payload) {
    return api.get('/v1/ereceipt/receipt-detail-receipt/' + payload.application_id + '/' + payload.ereceipt_api_id, {
      responseType: 'arraybuffer'
    })
  },
  getBranchDetail (payload) {
    return api.get('/v1/ereceipt/application-erpcode/' + payload.location_id)
  },
  cancelEReceipt (payload) {
    return api.get(`v1/ereceipt/receipt-cancel/${payload.id}`, payload.data)
  }
}
