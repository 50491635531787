<template>
  <v-row>
    <v-col :cols="12" v-if="customerRiskLog.length > 0">
      <v-data-table
        :headers="headers"
        :items="customerRiskLog"
        class="custom-table"
        calculate-widths
      />
    </v-col>
    <v-col :cols="12" class="text-center" v-else>
      <span class="caption">
        Nothing to display
      </span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      headers: [
        {
          text: 'Risk Score',
          sortable: true,
          value: 'risk_score',
          width: '180px'
        },
        {
          text: 'Risk Criteria',
          sortable: true,
          value: 'risk_criteria',
          width: '180px'
        },
        {
          text: 'Date',
          sortable: true,
          value: 'date_updated',
          width: '180px'
        }
      ]
    }
  },
  props: {
    customerInfo: {
      type: Object,
      default: null
    },
    customerTransactions: {
      type: Array,
      default: null
    },
    customerRiskLog: {
      type: Array,
      default: null
    }
  }
}
</script>
