import { endpoints } from '@/api/endpoints'

const mcRate = endpoints.use('mcRate')

export default {
  retrieveData: ({ commit }, { trxDate }) => new Promise((resolve, reject) => {
    mcRate.retrieveData(trxDate)
      .then(({ data }) => {
        commit('setItems', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveData: ({ commit }, payload) => new Promise((resolve, reject) => {
    mcRate.saveData(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    mcRate.updateStatus(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
