<template>
  <div v-if="printData" class="mx-auto" style="width: 730px;overflow-x: hidden;">
    <div style="width: 710px;height: 50px;overflow: hidden; padding: 5px;">
      <div style="text-align: center;width:550px;" class="noprint">
        <h4 style="font-weight: bold;padding: 0px;margin: 0px;">MONEY CHANGING FACILITY</h4>
        <p style="font-weight: bold;font-size: 12px; ">This form is requirement of Bangko Sentral ng Pilipinas</p>
      </div>
    </div>
    <div style="width: 710px;overflow-x: hidden;">
      <table style="width: 100%;">
        <tr>
          <td style="width: 254px;border:2px solid #000;" colspan="2">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">Name: </label>
            <small class="print"></small>
          </td>
          <td style="width: 199px !important;border-top:2px solid #000;border-right:2px solid #000;text-align: center;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;">For PETNET use only</label>
          </td>
          <td style="width: 199px;border-top:2px solid #000;border-right: 2px solid #000;" rowspan="2">
            <label class="noprint" style="display: block;font-size: 12px;font-weight: bold;text-align: center;">
              This serves as your Acknowledgement Receipt
            </label>
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000" colspan="2">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">
              Amt & currency to SELL/BUY:
            </label>
            <small></small>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Date:</label>
            <small class="print">{{ currentDate }}</small>
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000;" colspan="2" rowspan="2">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">In words:</label>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Teller:
            </label><small id="teller"
              name="teller" class="print">{{ currentUser }}</small>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;">
          </td>
        </tr>
        <tr>
          <td style="width: 199px;border: none;border-right: 2px solid #000;">
            <label class="noprint"
              style="font-size: 12px;font-weight: bold;padding-right: 2px;">Amount:</label><small id="amount"
              name="amount" class="print">{{ amount }}</small>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Date:
            </label><small class="print">{{ currentDate }}</small><span style="font-size: 12px;font-weight:bold;float: right;"
              class="noprint">MC {{ printData.type }}</span>
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000;" colspan="2" rowspan="3">
            <label class="noprint" style="font-size: 11px;font-weight: bold;padding-right: 10px;">Source of foreign
              Currency:</label><span style="font-size: 11px;padding-right: 10px;" class="noprint">1 OFW</span><span
              style="font-size: 11px;padding-right: 10px;" class="noprint">1 Tourist</span><span
              style="font-size: 11px;padding-right: 10px;" class="noprint">1 Expatriate</span><br />
            <span style="font-size: 11px;padding-right: 10px;" class="noprint">1 Excess Travel Funds</span><span
              style="font-size: 11px;padding-right: 10px;" class="noprint">1 Foreign Currency Dep.Acct.
              holder</span><br />
            <span style="font-size: 11px;padding-right: 10px;" class="noprint">1 Others</span>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;" rowspan="3">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Rate ($1=Php):
            </label><small id="rate" name="rate" class="print">{{ printData.forex_rate }}</small>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Client:
            </label><small id="client" name="client" class="print">{{ printData.customer_name }}</small>
          </td>
        </tr>
        <tr>
          <td style="width: 199px;border: none;border-right: 2px solid #000;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Amount:
            </label><small id="amt" name="amt" class="print">{{ amount }}</small>
          </td>
        </tr>
        <tr>
          <td style="width: 199px;border: none;border-right: 2px solid #000;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Rate ($1=Php):
            </label><small id="rates" name="rates" class="print">{{ printData.forex_rate }}</small>
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000;" colspan="2">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 10px;">Purpose of
              purchase: </label>
          </td>
          <td style="width: 199px;border:2px solid #000;border: none;border-right: 2px solid #000;">
          </td>
          <td style="width: 199px;border:2px solid #000;border: none;border-right: 2px solid #000;">
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000;" colspan="2">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">Client's
              Signature: </label>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;" rowspan="1">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Amount Released to
              Client: </label><br /><small id="amt_release_client" name="amt_release_client" class="print">{{ releasedAmount }}</small>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;" rowspan="1">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Amount Released to
              Client: </label><br /><small id="amt_released_client" name="amt_released_client" class="print">{{ releasedAmount }}</small>
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000;text-align: center;" colspan="2">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">For 1st time
              clients, please fill-out space below: </label>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;" rowspan="2">
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;" rowspan="2">
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000;" colspan="2">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">Address: </label>
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">Home #: </label>
          </td>
          <td style="width: 254px;border:2px solid #000;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">Mobile #: </label>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;" rowspan="1">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Received By:
            </label><br /><small id="receive_by" name="receive_by" class="print">{{ printData.customer_name }}</small>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;" rowspan="1">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 2px;">Processed By:
            </label><small id="processed_by"
              name="processed_by" class="print">{{ currentUser }}</small>
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">Date of Birth:
            </label>
          </td>
          <td style="width:254px;border:2px solid #000;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">Place of Birth:
            </label>
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;" rowspan="2">
          </td>
          <td style="width: 199px;border: none;border-right: 2px solid #000;" rowspan="2">
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000;" colspan="2">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">Nationality:
            </label>
          </td>
        </tr>
        <tr>
          <td style="width: 254px;border:2px solid #000;padding: 0px;" colspan="2">
            <label class="noprint" style="font-size: 10px;font-weight: bold;padding-right: 15px;"> * Please write
              U$D denomination & serial number at the back, then sign. </label>
          </td>
          <td style="width: 199px;border:2px solid #000;padding: 0px;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">Client's Signature
            </label>
          </td>
          <td style="width: 199px;border:2px solid #000;padding: 0px;">
            <label class="noprint" style="font-size: 12px;font-weight: bold;padding-right: 15px;">Teller's Signature
            </label><span style="font-size: 12px;font-weight:bold;float: right;padding-right: 10px;"
              class="noprint">Date</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      currentDate: '',
      currentUser: '',
      amount: '',
      releasedAmount: ''
    }
  },
  props: {
    printData: {
      type: Object,
      default: null
    }
  },
  beforeMount () {
    this.currentDate = format(new Date(), 'MM/dd/yyyy')
    this.currentUser = this.printData.user
    if (this.printData.type === 'BUY') {
      this.amount = `USD ${this.printData.forex_amount}`
      this.releasedAmount = `PHP ${this.printData.peso_amount}`
    } else {
      this.amount = `PHP ${this.printData.peso_amount}`
      this.releasedAmount = `USD ${this.printData.forex_amount}`
    }
  }
}
</script>

<style scoped>
* {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 12px !important;
}

table tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

table td {
  line-height: 1.0 !important;
  display: table-cell;
  vertical-align: middle;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}

h4 {
  font-size: 18px !important;
  margin: 0px !important;
}

p {
  margin: 0 !important;
}

@page {
  margin-left: 0 ;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 15px;
}

@media print {
  table {
    border: none !important;
  }

  th, td {
    border: none !important;
  }

  *:not(.print) {
    visibility: hidden;
  }

  *.print {
    font-size: 15px !important;
    visibility: visible !important;
  }
}
</style>
