<template>
  <v-container fluid>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="12" :sm="4" :md="4">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search Promo"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-8" :cols="12">
        <v-data-table
          :headers="headers"
          :items="promos"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:[`item.promo_name`]="{ item }">
            <span v-if="item.status === 1">
              {{ (item.approved_promo_name) ? item.approved_promo_name : '—' }}
            </span>
            <span v-else>
              {{ (item.requested_promo_name) ? item.requested_promo_name : '—' }}
            </span>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span v-if="item.created_at">
              {{ formatDate(item.created_at) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.start_date`]="{ item }">
            <span v-if="item.status === 1">
              {{ (item.approved_start_date) ? formatDate(item.approved_start_date) : '—' }}
            </span>
            <span v-else>
              {{ (item.requested_start_date) ? formatDate(item.requested_start_date) : '—' }}
            </span>
          </template>
          <template v-slot:[`item.end_date`]="{ item }">
            <span v-if="item.status === 1">
              {{ (item.approved_end_date) ? formatDate(item.approved_end_date) : '—' }}
            </span>
            <span v-else>
              {{ (item.requested_end_date) ? formatDate(item.requested_end_date) : '—' }}
            </span>
          </template>
          <template v-slot:[`item.user_fullname`]="{ item }">
            <span class="d-flex align-center" v-if="item.user_fullname">
              <v-avatar class="mr-1" size="23px" :style="avatarColor(item.user_fullname)">
                <span class="white--text text-body-2">{{ avatarInitial(item.user_fullname) }}</span>
              </v-avatar>
              <span>
                {{ toProperCase(item.user_fullname) }}
              </span>
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span
              :class="[ 'd-flex', 'align-center', (item.status === 1) ? 'green--text' : 'red--text' ]"
              v-if="item.status === 0 || item.status === 1"
            >
              <v-icon
                :class="['mr-1', (item.status === 1) ? 'green--text' : 'red--text' ]"
                x-small
              >
                mdi-circle
              </v-icon>
              <span>
                {{ (item.status === 1) ? 'Active' : 'Inactive' }}
              </span>
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              @click="viewPromo(item)"
              color="primary"
              icon
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              @click="confirmDeletion(item)"
              :disabled="item.status === 1"
              color="primary"
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="newTransaction"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="deleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Promo
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this promo?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDeleteDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="deletePromo"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import {
  avatarInitial,
  avatarColor,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      policies: 'insurance/policies',
      selectedPromo: 'promos/selectedPromo',
      isApprover: 'promos/isApprover'
    })
  },
  data () {
    return {
      search: '',
      deleteDialog: false,
      promos: [],
      headers: [
        {
          text: 'Promo Name',
          sortable: true,
          value: 'promo_name',
          width: '150px'
        },
        {
          text: 'Date Created',
          sortable: true,
          value: 'created_at',
          width: '125px'
        },
        {
          text: 'Start Date',
          sortable: true,
          value: 'start_date',
          width: '125px'
        },
        {
          text: 'End Date',
          sortable: true,
          value: 'end_date',
          width: '125px'
        },
        {
          text: 'Created By',
          sortable: true,
          value: 'user_fullname',
          width: '150px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '100px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '80px'
        }
      ]
    }
  },
  methods: {
    newTransaction () {
      this.$store.dispatch('promos/resetForm')
      this.$router.push({ path: '/promos/new' })
    },
    viewPromo (item) {
      const prefix = (item.status === 1 ? 'approved_' : 'requested_')
      this.$store.commit('promos/SET_SELECTED_PROMO', item)
      this.$store.commit('promos/SET_FORM_DATA', {
        promoType: item[`${prefix}promo_transaction_type`],
        promoName: item[`${prefix}promo_name`],
        startDate: item[`${prefix}start_date`],
        endDate: item[`${prefix}end_date`],
        frequency: item[`${prefix}frequency`],
        amountToCredit: item[`${prefix}incentive_amount`],
        minTransactionAmount: item[`${prefix}minimum_amount`],
        status: item.status
      })
      this.$router.push(`/promos/${item.promo_id}`)
    },
    confirmDeletion (item) {
      this.$store.commit('promos/SET_SELECTED_PROMO', item)
      this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.$store.commit('promos/SET_SELECTED_PROMO', null)
      this.deleteDialog = false
    },
    async deletePromo () {
      if (this.selectedPromo) {
        const id = this.selectedPromo.promo_id
        const req = await this.$store.dispatch('promos/deletePromo', id)

        this.closeDeleteDialog()

        if (req.code === 200) {
          this.getGrid()
          renderToast('success', 'Successful', 'Promo has been deleted')
        } else {
          renderToast('error', 'Failed', 'Failed to delete promo')
        }
      }
    },
    formatDate (date) {
      if (date) return format(new Date(date), 'MMMM d, yyyy')
      return ''
    },
    async getGrid () {
      const req = await this.$store.dispatch('promos/getPromos')

      if (req.code === 200) {
        this.promos = req.result
      }
    },
    avatarInitial,
    avatarColor,
    toProperCase
  },
  async beforeMount () {
    this.getGrid()

    const res = await this.$store.dispatch('promos/checkApprover', { user_id: this.currUser.user_id })

    if (res.code === 200 && res.result) {
      this.$store.commit('promos/SET_IS_APPROVER', true)
    } else {
      this.$store.commit('promos/SET_IS_APPROVER', false)
    }
  }
}
</script>
