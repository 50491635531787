<template>
  <div>
    <v-row class="mt-md-5" no-gutters>
      <v-col :cols="12" :md="4">
        <h3 class="pb-md-6 custom-heading primary--text">
          KYC Lookup
        </h3>
        <p class="text-body-2">
          Search for Last name, First name<br>e.g. Doe, John
        </p>
      </v-col>
      <v-col class="pl-md-6" :cols="12" :md="8">
        <h4 class="mb-5 grey--text text--darken-2">
          Customer's name
        </h4>
        <v-text-field
          v-model="firstName"
          class="ph-textbox mb-md-6 ph-textbox-quickpay"
          label="First Name"
          @input="convertToProperCase('firstName')"
          @keyup.enter="doSearch"
          outlined
        />
        <v-text-field
          v-model="lastName"
          class="ph-textbox mb-md-6 ph-textbox-quickpay"
          label="Last Name"
          @input="convertToProperCase('lastName')"
          @keyup.enter="doSearch"
          outlined
        />
        <div class="text-right">
          <v-btn @click="doSearch" :disabled="!firstName || !lastName" class="rounded-lg ph-search-customer" color="primary" large>
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="resultDialog" persistent :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '60%'">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            PERA Hub Customer Results
          </h3>
          <v-spacer />
          <v-btn @click="resultDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select Customer</strong>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                class="ph-textbox rounded-lg"
                placeholder="Search"
                outlined
              />
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectCustomer"
            :headers="headers"
            :items="searchData.results"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No customers found"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ toProperCase(item.gender) || '-' }}
            </template>
            <template v-slot:[`item.mobile_no`]="{ item }">
              {{ item.mobile_no || '-' }}
            </template>
          </v-data-table>
          <div class="d-flex align-center">
            <span>Can't find customer here?</span>
            <v-btn
              @click="addNewCustomer"
              color="primary"
              x-small
              text
            >
              Add New Customer
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  customerRiskAssessment,
  getForeignRefNo,
  avatarColor,
  avatarInitial,
  toProperCase,
  trim,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      searchData: 'customers/searchData',
      oarNumber: 'oar'
    })
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      resultDialog: false,
      searchText: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      showRemoteNotice: false,
      headers: [
        {
          text: 'Customer No.',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'mobile_no'
        }
      ]
    }
  },
  methods: {
    renderToast,
    addNewCustomer () {
      this.$router.push({
        path: '/customers/new',
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    convertToProperCase (field) {
      this[field] = toProperCase(trim(this[field]))
    },
    async selectCustomer (customerDetails) {
      // Checking of available offer for the customer
      const { code, result } = await this.$store.dispatch('prompt/checkAvailableOffer', {
        customer_number: customerDetails.customer_number,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id
      })

      if (code === 200 && result) {
        this.$store.commit('prompt/SET_AVAILABLE_OFFER', result)
        this.$store.commit('prompt/SET_SHOW_OFFER_DIALOG', true)
      }

      const foreignRefNo = getForeignRefNo()

      this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)

      if (this.currUser.cisrat === 1) {
        this.resultDialog = false
        this.searchData.results = []

        const { code, data } = await customerRiskAssessment(customerDetails)

        if (code === 'FOUND_HIGH_RISK_GO' || code === 'FOUND_HIGH_RISK_STOP') {
          this.$store.commit('compliance/UPDATE_HIGH_RISK_DIALOG', {
            isShow: true,
            type: code,
            details: data,
            customer: customerDetails
          })
          return true
        }

        if (code === 'ERR_INTERNAL_CATCH') {
          renderToast('error', 'USP Error', 'An error encountered while checking the customer')
          return true
        }

        if (code === 'ERROR_CODE') {
          renderToast('error', `[${data.code}] Error`, 'An error encountered while checking the customer')
          return true
        }

        if (code === 'ERR_NO_API_RESPONSE') {
          renderToast('error', 'USP Error', 'Invalid API response')
          return true
        }

        if (code === 'FOUND_NEGATIVE_LIST') {
          if (customerDetails.is_negative_list !== 1) {
            this.$store.commit('compliance/UPDATE_PNA_ALERT_DIALOG', {
              isShow: true,
              type: code,
              details: data,
              customer: customerDetails
            })
            return true
          }
        }

        if (code === 'FOUND_ADVERSE') {
          if (customerDetails.is_fraud !== 1) {
            this.$store.commit('compliance/UPDATE_PNA_ALERT_DIALOG', {
              isShow: true,
              type: code,
              details: data,
              customer: customerDetails
            })
            return true
          }
        }

        if (code === 'FOUND_PEP') {
          if (customerDetails.is_pep !== 1) {
            this.$store.commit('compliance/UPDATE_PNA_ALERT_DIALOG', {
              isShow: true,
              type: code,
              details: data,
              customer: customerDetails
            })
            return true
          }
        }
      }

      const updateState = [
        {
          prop: 'senderFirstName',
          value: customerDetails.first_name
        },
        {
          prop: 'senderMiddleName',
          value: customerDetails.middle_name
        },
        {
          prop: 'senderLastName',
          value: customerDetails.last_name
        },
        {
          prop: 'senderBirthDate',
          value: customerDetails.birth_date
        },
        {
          prop: 'refNo',
          value: foreignRefNo
        }
      ]
      updateState.map((payload) => this.$store.commit('send/SET_TRANSACTION_DATA', payload))

      this.proceedToTransaction()
    },
    proceedToTransaction () {
      switch (this.$route.name) {
        case 'PeraCashIn':
          console.log('PUMASOK')
          this.$store.commit('cico/SET_CASHIN_CURRENT_PAGE', 1)
          break
        case 'PeraCashOut':
          this.$store.commit('cico/SET_CASHOUT_CURRENT_PAGE', 1)
          break
      }
    },
    async doSearch () {
      if (this.firstName && this.lastName) {
        const firstName = this.firstName.replace(/(^\s+|\s+$)/g, '')
        const lastName = this.lastName.replace(/(^\s+|\s+$)/g, '')
        const keyword = `${lastName},${firstName}`

        this.searchData.results = []
        this.$store.dispatch('customers/doSearch', keyword)
        this.$store.commit('send/SET_CURRENT_STEP', 1)
        this.resultDialog = true
      } else {
        this.searchData.results = []
        this.renderToast('error', 'Search Error', 'The required fields must be completed.')
      }
    },
    toProperCase,
    avatarColor,
    avatarInitial
  },
  async mounted () {
    this.$store.commit('cico/SET_CASHIN_CURRENT_TRX', null)
    this.$store.commit('cico/SET_CASHOUT_CURRENT_TRX', null)
    this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)

    switch (this.$route.name) {
      case 'PeraCashIn':
        this.$store.commit('SET_PAGE_TITLE', 'Pera Cash-In')
        break
      case 'PeraCashOut':
        this.$store.commit('SET_PAGE_TITLE', 'Pera Cash-Out')
        break
    }

    await this.$store.dispatch('getIDList', 'wu')
  }
}
</script>
<style scoped>
  .ph-textbox-quickpay {
    margin-bottom: 16px !important;
  }
</style>
