import { endpoints } from '@/api/endpoints'

const cashCount = endpoints.use('cashCount')

export default {
  getCashCountList: ({ commit }, payload) => new Promise((resolve, reject) => {
    cashCount.getCashCountList(payload)
      .then(({ data }) => {
        commit('SET_CASH_COUNT_LIST', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  insertCashCountEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    cashCount.insertCashCountEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  updateCashCountEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    cashCount.updateCashCountEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
