<template>
  <div class="px-md-4 mt-3">
    <PayoutDetails />
    <RemcoSelection />
    <DeliveryService v-if="transactionData.remcoID === 0" />
    <div class="d-flex justify-space-between">
      <v-btn
        @click="cancel"
        class="rounded-lg font-weight-bold"
        color="primary"
        outlined
        large
      >
        Cancel
      </v-btn>
      <v-btn
        @click="next"
        class="rounded-lg font-weight-bold"
        color="primary"
        :disabled="!isCompleteForm"
        large
      >
        Next
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RemcoSelection from '@/components/send/RemcoSelection.vue'
import DeliveryService from '@/components/send/DeliveryService.vue'
import PayoutDetails from '@/components/send/PayoutDetails.vue'

export default {
  computed: {
    ...mapGetters({
      transactionData: 'send/transactionData'
    }),
    isCompleteForm () {
      const {
        destinationCountryID,
        serviceFee,
        sendAmount,
        remcoID
      } = this.transactionData

      return (String(destinationCountryID) && String(serviceFee) && (sendAmount > 0) && String(remcoID))
    }
  },
  components: {
    RemcoSelection,
    DeliveryService,
    PayoutDetails
  },
  methods: {
    next () {
      this.$store.commit('send/SET_CURRENT_STEP', 2)
    },
    cancel () {
      this.$router.push({ path: '/pera-remit/send' })
    }
  },
  mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Transfer Information')
  }
}
</script>
