import { endpoints } from '@/api/endpoints'

const customers = endpoints.use('customers')
const users = endpoints.use('users')

export default {
  doSearch: ({ commit }, payload) => new Promise((resolve) => {
    customers.search(payload)
      .then(({ data }) => {
        commit('SET_SEARCH_RESULT', data.result)
        resolve(data)
      })
  }),

  doSave: (context, payload) => new Promise((resolve) => {
    customers.save(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),

  doUpdate: (context, payload) => new Promise((resolve) => {
    customers.update(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),

  doDelete: ({ state }, { customerID, userID }) => new Promise((resolve) => {
    const searchResults = state.searchData.results
    const recentlyAdded = state.recentlyAdded
    customers.delete(customerID, userID)
      .then(({ data }) => {
        const searchResultIndex = searchResults.findIndex((item) => item.customer_id === customerID)
        const recentlyAddedIndex = recentlyAdded.findIndex((item) => item.customer_id === customerID)
        if (data.code === 200) {
          if (searchResultIndex !== -1) searchResults.splice(searchResultIndex, 1)
          if (recentlyAddedIndex !== -1) recentlyAdded.splice(recentlyAddedIndex, 1)
        }
        resolve(data)
      })
  }),

  getTransactionLogs: (context, customerID) => new Promise((resolve) => {
    customers.transactionLogs(customerID)
      .then(({ data }) => {
        resolve(data.result)
      })
  }),

  getTransactionCount: (context, customerID) => new Promise((resolve) => {
    customers.transactionCount(customerID)
      .then(({ data }) => {
        resolve(data.result)
      })
  }),

  getRecentlyAdded: ({ commit }, payload) => new Promise((resolve) => {
    customers.recentlyAdded(payload)
      .then(({ data }) => {
        commit('SET_RECENTLY_ADDED', data.result)
        resolve(data)
      })
  }),

  getDynamicFields: (context, payload) => new Promise((resolve) => {
    customers.dynamicFields(payload)
      .then(({ data }) => {
        resolve(data)
      })
  }),

  generateACR: ({ commit }, payload) => new Promise((resolve, reject) => {
    customers.generateACR(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  generateACRMoneygram: ({ commit }, payload) => new Promise((resolve, reject) => {
    customers.generateACRMoneygram(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  retrieveSignatures: ({ commit }, payload) => new Promise((resolve, reject) => {
    customers.retrieveSignatures(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  uploadUserSignature: ({ commit }, payload) => new Promise((resolve, reject) => {
    users.uploadUserSignature(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getNewRequests: ({ commit }, branchCode) => new Promise((resolve, reject) => {
    customers.newRequests(branchCode)
      .then(({ data }) => {
        commit('SET_REQUESTS', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  deleteNewRequest: ({ commit }, id) => new Promise((resolve, reject) => {
    customers.deleteNewRequest(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getClientPolicyList: ({ state }) => new Promise((resolve, reject) => {
    const { selectedCustomer } = state
    customers.clientPolicyList(selectedCustomer.first_name, selectedCustomer.last_name, selectedCustomer.birth_date)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  resetForm ({ state, commit }) {
    Object.keys(state.formData).forEach(prop => {
      let value = ''

      if (prop === 'img_file' || prop === 'customer_signatures' || prop === 'display_previous_signatures') value = []
      if (prop === 'country1' || prop === 'country_birth' || prop === 'country') value = 'Philippines'
      if (prop === 'tag_no_middlename' || prop === 'tag_verified_mobile') value = '0'
      if (prop === 'nationality') value = 'Filipino'
      if (prop === 'tags') value = '[]'

      commit('SET_FORM_DATA_VALUE', { [prop]: value })
    })
  },

  checkExistingCust: ({ commit }, mobileNo) => new Promise((resolve, reject) => {
    customers.checkIfDuplicate(mobileNo)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })

}
