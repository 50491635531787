<template>
  <v-dialog
    v-model="showFlaggedPromptDialog"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title class="ph-dialog-title">
        Flagged Transaction
      </v-card-title>
      <br>
      <v-card-text class="ph-dialog-header">
        <p class="text-center">
          Are you related to this customer?
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="saveFlaggedTransaction(1)"
          color="primary"
          text
          class="ph-action-button"
        >
          Yes
        </v-btn>
        <v-btn
          @click="saveFlaggedTransaction(0)"
          elevation="0"
          text
          class="ph-action-button"
        >
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      showFlaggedPromptDialog: 'transactionLimit/showFlaggedPromptDialog',
      flaggedTransactionPayload: 'transactionLimit/flaggedTransactionPayload'
    })
  },
  methods: {
    async saveFlaggedTransaction (answer) {
      if (this.flaggedTransactionPayload) {
        this.flaggedTransactionPayload.status = answer
        this.flaggedTransactionPayload.remarks = (answer === 1) ? 'YES' : 'NO'

        const { code } = await this.$store.dispatch('transactionLimit/saveFlaggedTransaction', this.flaggedTransactionPayload)

        if (code === 200) {
          this.$emit('callback')
          this.$store.commit('transactionLimit/SET_SHOW_FLAGGED_PROMPT_DIALOG', false)
        } else {
          renderToast('error', 'Logging Failed', 'Failed to save flagged transaction, please try again')
        }
      }
    }
  }
}
</script>
