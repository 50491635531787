<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <h2 class="title ph-title primary--text mx-6 my-2" style="font-size: 24px !important;">
      Fraudster Details
    </h2>
    <v-row class="mb-3 mx-2">
      <v-col :cols="3">
        <v-form ref="form">
          <v-row class="mt-1">
            <v-col :cols="12">
              <v-text-field
                v-model="formData.firstName"
                class="ph-textbox"
                label="First Name *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.lastName"
                class="ph-textbox"
                label="Last Name *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.source"
                class="ph-textbox"
                label="Source *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-menu
                v-model="datePublishedPicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.datePublished"
                    :rules="dateRules"
                    class="ph-textbox"
                    label="Date Published *"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    clearable
                  />
                </template>
                <v-date-picker
                  v-model="formData.datePublished"
                  @input="syncDatePublished()"
                />
              </v-menu>
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.address"
                class="ph-textbox"
                label="Address *"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.remarks"
                class="ph-textbox"
                label="Remarks"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.alias"
                class="ph-textbox"
                label="Alias"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <FileUploader accept="image/*" guideText="image (.png, .jpg, .webp)" @onFileChange="handleFile" />
              <p class="d-flex align-center mt-3" v-if="formData.uploadedFileName">
                <v-icon class="mr-1">mdi-file-outline</v-icon>
                <strong>{{ formData.uploadedFileName }}</strong>
              </p>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="save" class="font-weight-bold" color="primary" block x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import FileUploader from '@/components/partials/FileUploader.vue'
import { renderToast } from '@/utils'
import { parseISO, format } from 'date-fns'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      datePublishedPicker: false,
      formData: {
        firstName: '',
        lastName: '',
        source: '',
        datePublished: '',
        address: '',
        remarks: '',
        alias: '',
        uploadedFile: null,
        uploadedFileName: ''
      },
      requiredRules: [v => !!v || 'This field is required'],
      dateRules: [
        v => !!v || 'This field is required',
        v => (!v || (!!v && /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v))) || 'Use YYYY-MM-DD format'
      ],
      breadcrumbsItems: [
        {
          text: 'Compliance Module',
          disabled: false,
          exact: true,
          to: '/compliance'
        },
        {
          text: 'Adverse',
          disabled: false,
          exact: true,
          to: '/compliance/adverse'
        },
        {
          text: 'Update Entry',
          disabled: true
        }
      ]
    }
  },
  components: {
    FileUploader
  },
  methods: {
    syncDatePublished () {
      this.datePublishedPicker = false
    },
    handleFile ([file]) {
      const currentDate = new Date()
      const formattedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, '')
      const randNum = Math.floor(Math.random() * (999 - 100 + 1)) + 100
      const firstName = this.formData.firstName
      const lastName = this.formData.lastName

      this.formData.uploadedFile = file
      this.formData.uploadedFileName = `${lastName}_${firstName}_${formattedDate}${randNum}.png`
    },
    async save () {
      if (this.$refs.form.validate()) {
        const payload = new FormData()
        const firstName = this.formData.firstName
        const lastName = this.formData.lastName
        let fileName = this.formData.uploadedFileName
        let filePhoto = ''

        if (this.formData.uploadedFile) {
          fileName = this.formData.uploadedFileName
          filePhoto = this.formData.uploadedFile
        }

        payload.append('id', this.$route.params.id)
        payload.append('first_name', firstName)
        payload.append('last_name', lastName)
        payload.append('tag', this.formData.remarks)
        payload.append('other_info', this.formData.alias)
        payload.append('updated_by', this.currUser.user_id)
        payload.append('type', 'FRAUD')
        payload.append('pep_categ', this.formData.address)
        payload.append('source', this.formData.source)
        payload.append('date_published', this.formData.datePublished)
        payload.append('img', fileName)
        payload.append('photo', filePhoto)

        const { code } = await this.$store.dispatch('compliance/updateAdverseEntry', payload)

        if (code === 200) {
          renderToast('success', 'Successful', 'Fraudster details has been updated')
          this.$router.push('/compliance/adverse')
        } else {
          renderToast('error', 'Error', 'Failed to update fraudster details')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill out all required fields')
      }
    },
    cancel () {
      this.$router.push('/compliance/adverse')
    },
    formatDate (date) {
      if (date) return format(parseISO(date), 'yyyy-MM-dd')
      return ''
    }
  },
  async mounted () {
    const id = this.$route.params.id

    if (id) {
      const { code, result } = await this.$store.dispatch('compliance/retrieveAdverseItem', id)

      if (code === 200) {
        if (result.length === 1) {
          this.formData = {
            ...this.formData,
            firstName: result[0].first_name,
            lastName: result[0].last_name,
            uploadedFileName: result[0].img,
            remarks: result[0].tag,
            alias: result[0].other_info,
            address: result[0].pep_categ,
            source: result[0].source,
            datePublished: this.formatDate(result[0].date_published)
          }
        } else {
          renderToast('error', 'Error', 'Failed to retrieve fraudster details')
          this.$router.push('/compliance/adverse')
        }
      } else {
        renderToast('error', 'Error', 'Failed to retrieve fraudster details')
        this.$router.push('/compliance/adverse')
      }
    } else {
      this.$router.push('/compliance/adverse')
    }
  }
}
</script>
