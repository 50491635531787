<template>
  <v-row class="ph-wu-receive-transfer-info">
    <v-col cols="12">
      <div class="ph-buyback-details-container payout-details-container">
        <div class="ph-buyback-detail-title">
          <b>Buy Back</b>
        </div>
        <v-col cols="12">
          <v-row fluid class="ph-buyback-detail-content">
            <v-row cols="12">
              <v-col cols="12" class="ph-buy-back-rate">
                <v-select
                  @change="selectedRate($event)"
                  v-model="rateValue"
                  label="Rate"
                  :items="buyBackRateList"
                  item-value="mc_rate_id"
                  item-text="category"
                  return-object
                  color="gray darken-1"
                  class="ph-textbox"
                  outlined
                >
                  <template slot="selection" slot-scope="{ item }">
                    {{ item.category === '' ? 'Select a rate type' : getRateText(item) }}
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    {{ item.category === '' ? 'Select a rate type' : getRateText(item) }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <p><b>PeraHub Dollar Rate:</b> {{transactionData.mc_rate ? 'PHP ' + dollarRate : '-'}} <i v-if="transactionData.mc_rate">(as of {{dateOfRate}})</i></p>
              </v-col>
            </v-row>
            <v-row cols="12">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="transactionData.buy_back"
                  @keyup="computeEquivalent($event.target.value, dollarRate)"
                  class="ph-textbox"
                  outlined
                  label="Amount to Buyback"
                  type="number"
                  :hide-spin-buttons="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="ph-buy-back-currency">
                <p><b>Currency:</b> USD</p>
              </v-col>
            </v-row>
            <v-row cols="12">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="totalAmount"
                  class="ph-textbox"
                  outlined
                  label="Equivalent"
                  readonly
                  type="number"
                  :hide-spin-buttons="true"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="ph-buy-back-currency">
                <p><b>Currency:</b> PHP</p>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { convertToRealAmount, toMoney } from '@/utils'

export default {
  props: {
    transactionData: Object,
    currentTrx: Object,
    buyBackRateList: Array
  },
  data () {
    return {
      netDollarPay: '',
      dateOfRate: '',
      totalAmount: '',
      rateValue: '',
      dollarRate: numeral(0).format('0, 0.00')
    }
  },
  computed: {
    ...mapGetters({
      mcRateData: 'wu/mcRateData',
      currentTrx: 'wu/currentTrx',
      currUser: 'auth/currUser'
    })
  },
  watch: {
    mcRateData: {
      handler (value) {
        // Autoselects if only 1 item
        if (this.buyBackRateList.length < 2) {
          this.transactionData.mc_rate = this.buyBackRateList[0]
          this.selectedRate(this.buyBackRateList[0])
        }
      },
      deep: true
    },
    totalAmount: {
      handler (value) {
        this.transactionData.total_amount = value
      },
      deep: true
    }
  },
  mounted () {
    console.log(this.transactionData.mc_rate)
  },
  methods: {
    convertToRealAmount,
    toMoney,
    getRate (data) {
      if (String(this.currUser.location_type) === '1') return data.buy_am_rate
      return data.bp_mc_rate
    },
    getRateText (item) {
      return `${item.category} - ${toMoney(this.getRate(item))}`
    },
    computeEquivalent (amountToConvert, rate) {
      let amount = amountToConvert

      if (amount === '') {
        amount = 0
      }

      this.totalAmount = parseFloat(amount) * rate
    },
    selectedRate (data) {
      if (data.category !== '') {
        this.dollarRate = this.getRate(data)
        this.dateOfRate = data.date_updated
        this.transactionData.mc_rate_id = data.mc_rate_id
        this.transactionData.mc_rate = this.getRate(data)
        this.transactionData.adj_type = 'BUY'
        this.transactionData.rate_category = data.category

        const paymentTrx = this.currentTrx.result.payment_transaction
        this.transactionData.buy_back = this.convertToRealAmount(paymentTrx.financials.pay_amount)

        this.computeEquivalent(this.transactionData.buy_back, this.getRate(data))
      } else {
        this.dollarRate = ''
        this.dateOfRate = ''
        this.totalAmount = ''
        this.transactionData.mc_rate_id = ''
        this.transactionData.mc_rate = ''
        this.transactionData.adj_type = 'SELL'
        this.transactionData.rate_category = ''
        this.transactionData.buy_back = '0'
      }
    }
  }
}
</script>
<style scoped>
  .custom-card .caption {
    font-family: 'Proxima Nova' !important;
  }
  .ph-id-detail-title {
    margin-bottom: 15px;
  }

  .ph-buy-back-rate {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .ph-buyback-detail-content {
    display: flex;
    flex-direction: column;
  }

  .ph-buy-back-currency {
    margin-top: 10px;
  }
</style>
