<template>
  <v-container fluid>
    <CustomerSearch v-if="currentPage === 0" />
    <NewTransaction v-if="currentPage === 1" />
    <SuccessfulTransaction v-if="currentPage === 2" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerSearch from '@/components/insurance/CustomerSearch.vue'
import NewTransaction from '@/components/insurance/NewTransaction.vue'
import SuccessfulTransaction from '@/components/insurance/SuccessfulTransaction.vue'

export default {
  computed: {
    ...mapGetters({
      currentPage: 'insurance/currentPage'
    })
  },
  components: {
    CustomerSearch,
    NewTransaction,
    SuccessfulTransaction
  }
}
</script>
