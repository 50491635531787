<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-card-title class="ph-nonex-datatable-title">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="ph-textbox ph-textbox-grid-search"
                outlined
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="billsCollectionTransactions"
              :search="search"
              :calculate-widths="true"
              class="ph-nonex-datatable"
            >
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDateTime(item.created_at) }}
              </template>
              <template v-slot:[`item.reference_no`]="{item}">
                <v-chip
                  class="ma-2 ph-nonex-table-names-text text-amount text-inquire-button"
                  color="primary"
                  @click="generateVoucher(item)"
                >
                  <v-icon left>
                    mdi-file-eye-outline
                  </v-icon>
                  {{item.reference_no}}
                </v-chip>
              </template>
              <template v-slot:[`item.customer_name`]="{item}">
                <div class="ph-nonex-receiver-container">
                  <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.customer_name)">
                    <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
                  </v-avatar>
                  <p class="ph-nonex-table-names-text">{{item.customer_name}}</p>
                </div>
              </template>
              <template v-slot:[`item.principal_amount`]="{item}">
                <p class="ph-nonex-table-names-text">PHP {{formatAmount(item.principal_amount)}}</p>
              </template>
              <template v-slot:[`item.service_fee`]="{item}">
                <p class="ph-nonex-table-names-text">PHP {{formatAmount(item.service_fee)}}</p>
              </template>
              <template v-slot:[`item.total_amount`]="{item}">
                <p class="ph-nonex-table-names-text">PHP {{formatAmount(item.total_amount)}}</p>
              </template>
              <template v-slot:[`item.form_number`]="{item}">
                <v-chip
                  class="ma-2 ph-nonex-table-names-text text-amount text-inquire-button"
                  color="primary"
                  @click="generateOAR(item.form_number)"
                  v-if="currUser.location_type === '1'"
                >
                  <v-icon left>
                    mdi-eye
                  </v-icon>
                  {{item.form_number}}
                </v-chip>
                <p
                  v-if="currUser.location_type !== '1'"
                  class="ph-nonex-table-text"
                >
                  {{item.form_number}}
                </p>
              </template>
              <template v-slot:[`item.action`]="{item}">
                <v-menu
                  bottom
                  left
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.status === 1"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="reprintReceipt(item)">
                      <v-list-item-title>Print Receipt</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.status === 0"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="retryTransaction(item)"
                    >
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>Retry</span>
                </v-tooltip>
                <div class="ph-nonex-action-container" v-if="false">
                  <v-btn
                    icon
                    color="primary"
                    @click="retryTransaction(item)"
                    v-if="item.status === 0"
                    >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-speed-dial
        v-model="fab"
        dark
        fixed
        bottom
        right
        class="mb-5"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="#1A2791"
            dark
            fab
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#FFD600"
              v-bind="attrs"
              v-on="on"
              small
              fab
              to="/pera-bills/collections/new?provider_type=others"
            >
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-btn>
          </template>
          <span>Other Providers</span>
        </v-tooltip>
        <v-tooltip left content-class="ph-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="#1A2791"
              v-bind="attrs"
              v-on="on"
              to="/pera-bills/collections/new?provider_type=loans"
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </template>
          <span>Loans</span>
        </v-tooltip>
      </v-speed-dial>
    </v-container>
    <v-dialog
      v-model="confirmOARAssign"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Assign {{oarAssignType === 'Multiple' ? 'OAR to all transactions?' : `an OAR to ${selectedTrx.biller_reference}?`}}
        </v-card-title>

        <v-card-text>
          <br>
          <p><b>Are you sure you want to {{oarAssignType === 'Multiple' ? 'assign all items into single OAR?' : 'assign an OAR to your selected transaction?' }}</b></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            class="ph-action-button ph-stepper-button"
            elevation="0"
            @click="confirmOARAssign = false; oarAssignType = ''"
          >
            No
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="executeAssignOAR(oarAssignType)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Successful
        </v-card-title>

        <v-card-text>
          <br>
          <p><b>The print receipt window will open in 3 seconds.</b></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="printReceipt"
          >
            Print Receipt
            &nbsp;
            <v-progress-circular
              :rotate="360"
              :value="progressValue"
              color="white"
            >
              {{ countdown }}
            </v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'CollectionIndex',
  data () {
    return {
      base64String: '',
      billsPaymentList: [],
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Bills',
          disabled: false,
          href: '/pera-bills'
        },
        {
          text: 'Collections',
          disabled: true,
          href: '#'
        }
      ],
      filteredACRs: [],
      search: '',
      headers: [
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date'
        },
        {
          text: 'Provider',
          sortable: true,
          value: 'provider'
        },
        {
          text: 'Reference Number',
          sortable: true,
          value: 'reference_no'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customer_name'
        },
        {
          text: 'Principal Amount',
          sortable: true,
          value: 'principal_amount'
        },
        {
          text: 'Service Fee',
          sortable: true,
          value: 'service_fee'
        },
        {
          text: 'Total Amount',
          sortable: true,
          value: 'total_amount'
        },
        {
          text: 'OAR Number',
          sortable: true,
          value: 'form_number'
        }
      ],
      successDialog: false,
      interval: {},
      progressValue: 0,
      countdown: 3,
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      transition: 'slide-y-reverse-transition',
      enableSingleOAR: false,
      enableMultipleOAR: true,
      confirmOARAssign: false,
      oarAssignType: '',
      isSingleOARAssigned: false,
      selectedTrx: {}
    }
  },
  computed: {
    ...mapGetters({
      billsCollectionTransactions: 'bills/billsCollectionTransactions',
      collections: 'collections',
      currUser: 'auth/currUser',
      remcos: 'nonex/remcos',
      oarNumber: 'oar/oarNumber'
    })
  },
  watch: {
    countdown: {
      handler (count) {
        if (count === 0) {
          const that = this
          setTimeout(function () {
            that.progressValue = 0
            that.successDialog = false
          }, 1000)

          clearInterval(this.interval)
        }
      }
    },
    billsPaymentTransactions: {
      handler (newVal) {
        const transactions = newVal
        const filterOARAssigned = transactions.filter(item => { return item.assign_oar === true })

        if (filterOARAssigned.length > 0) {
          this.isSingleOARAssigned = true
          this.enableSingleOAR = true
        } else {
          this.isSingleOARAssigned = false
          this.enableSingleOAR = false
        }
      },
      deep: true
    }
  },
  async beforeMount () {
    this.getTransactions()
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    getTransactions () {
      this.$store.commit('bills/SET_BILLS_PAYMENT_RESPONSE_DATA', null)
      this.$store.commit('bills/SET_BILLS_TPA_ID', '')
      this.$store.commit('bills/SET_BILLS_PAYMENT_RECEIPT_DATA', {})
      this.$store.commit('bills/SET_BILLS_LOAN_ACCOUNT_NO', '')

      this.$store.dispatch('bills/getCollectionTrx', {
        trx_date: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id
      })
    },
    formatDateTime (date) {
      return format(parseISO(date), 'yyyy-MM-dd HH:mm:SS')
    },
    formatDateTimeACR (date) {
      return format(date, 'yyyy-MM-dd HH:mm:SS')
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    printReceipt (ACRPayload) {
      const that = this
      this.$store.dispatch('customers/generateACR', ACRPayload).then((res) => {
        that.base64String = res.result
        that.pdfConvert()
        that.getTransactions()
      }).catch((error) => {
        console.log(error)
      })
    },
    async retryTransaction (item) {
      const currentUser = this.currUser
      const apiRequestData = JSON.parse(item.api_request)

      const tpaPayload = {
        bill_id: 2,
        location_id: this.currUser.location_id
      }

      let tpaId = ''

      await this.$store.dispatch('bills/getTPARequest', tpaPayload)
        .then(res => {
          tpaId = res.tpa_id
        }).catch(e => {
          console.log(e)
        })

      const retryPayload = {
        coy: item.coy,
        type: apiRequestData.type,
        amount: item.amount,
        tpa_id: tpaId,
        bill_id: item.bill_id,
        user_id: currentUser.user_id.toString(),
        trx_date: currentUser.trx_date,
        form_type: item.form_type,
        otherInfo: apiRequestData.otherInfo ? apiRequestData.otherInfo : '',
        biller_tag: item.biller_tag,
        identifier: item.identifier,
        biller_name: apiRequestData.biller_name,
        callbackUrl: null,
        currency_id: item.currency_id,
        customer_id: item.customer_id,
        form_number: item.form_number,
        location_id: item.location_id,
        total_amount: item.total_amount,
        location_name: item.location_name,
        account_number: item.account_number,
        partner_charge: item.partner_charge,
        payment_method: apiRequestData.payment_method,
        service_charge: item.service_charge,
        reference_number: item.reference_number,
        validation_number: item.validation_number,
        client_reference_number: item.client_reference_number,
        receipt_validation_number: apiRequestData.receipt_validation_number,
        id: item.id
      }

      await this.$store.dispatch('bills/retryTrx', retryPayload).then(res => {
        if (res?.result) {
          this.successDialog = true
          this.countdown = 3
          this.interval = setInterval(() => {
            if (this.progressValue === 99.99 && this.countdown === 0) {
              return (this.progressValue = 0)
            }
            this.progressValue += 33.3333333333
            this.countdown -= 1
          }, 1000)
          const that = this

          setTimeout(function () {
            that.printBillsReceipt(tpaId, retryPayload)
          }, 3000)
        } else {
          this.renderToast('error', 'Transaction Error', res.error.moreInfo.details.message)
        }
      }).catch(() => {
        this.renderToast('error', 'Transaction Error', 'Something went wrong in retrying the transaction. Please Try Again.')
      })
    },
    selectedTransaction (item) {
      if (item.assign_oar) {
        this.selectedTrx = item
      } else {
        this.selectedTrx = {}
      }
    },
    async reprintReceipt (item) {
      const tpaPayload = {
        bill_id: 2,
        location_id: this.currUser.location_id
      }

      let tpaId = ''

      await this.$store.dispatch('bills/getTPARequest', tpaPayload)
        .then(res => {
          tpaId = res.tpa_id
          this.printBillsReceipt(tpaId, item)
        }).catch(e => {
          console.log(e)
        })
    },
    async printBillsReceipt (tpaId, item) {
      const transactionRefNo = `TR#${item.transaction_id} ${this.currUser.user_name}/${tpaId}`
      const transactionTimestamp = `${format(new Date(item.created_at), 'yyyy-MM-dd HH:mm:SS')}`
      const getAPIRequest = JSON.parse(item.api_request)

      const data = {
        billerName: getAPIRequest.biller_name,
        transactionRefNo: transactionRefNo,
        transactionTimestamp: transactionTimestamp,
        referenceNo: item.reference_number,
        paymentMethod: item.payment_type === 0 ? 'CASH' : '',
        amount: item.amount,
        trxId: item.transaction_id,
        tpaId: tpaId
      }

      this.$store.commit('bills/SET_BILLS_PAYMENT_RECEIPT_DATA', data)

      setTimeout(function () {
        window.open('/pera-bills/receipt', '_blank')
      }, 2500)
    },
    generateOAR (formNumber) {
      window.open(`/receipt?ref=${formNumber}&trx_type=peradali&type=OAR`, '_blank')
    },
    generateVoucher (data) {
      console.log(data)
      const payload = {
        location_id: data.location_id,
        trx_date: data.trx_date,
        ref_no: data.reference_no,
        trx_type: 'peradali'
      }

      const that = this

      this.$store.dispatch('bills/generateBillsCollectionVoucher', payload).then(res => {
        const voucherData = res.result[0]

        const voucherItem = {
          trx_data: data,
          voucher_data: voucherData
        }

        that.$store.commit('bills/SET_BILLS_COLLECTION_VOUCHER_DATA', voucherItem)

        setTimeout(function () {
          window.open('/pera-bills/print-voucher', '_blank')
        }, 2500)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style scoped>
/* .ph-nonex-datatable-title {
  padding-left: 0;
} */

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.35);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

.ph-primary-button {
  background: #FFF;
  color: #1A2791 !important;
  /* border: 2px solid #1A2791; */
  border-radius: 10px;
  font-family: 'Proxima Nova';
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 23px;
  transition: .2s;
  margin: 10px 0;
  margin-right: 10px;
}

.ph-primary-button:hover {
  background: #1A2791 !important;
  color: #fff !important;
}

.ph-primary-button.ph-primary-execute-btn {
  background: #1A2791;
  color: #fff !important;
  font-size: 14px;
}

.ph-primary-button.ph-primary-execute-btn:hover {
  background: #fff !important;
  color: #1A2791 !important;
  border: 2px solid #1A2791;
}

.ph-datatable-checked {
  margin-left: 2px;
}
</style>
