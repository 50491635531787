export default {
  SET_PERA_BANK_TRANSACTIONS (state, payload) {
    state.bankDepositTransactions = payload
  },
  SET_PERA_BANK_CURRENT_TRX (state, payload) {
    state.bankDepositCurrentTrx = payload
  },
  SET_PERA_BANK_CURRENT_PAGE (state, payload) {
    state.bankDepositCurrentPage = payload
  },
  SET_PERA_BANK_PARTNERS (state, payload) {
    state.bankDepositPartners = payload
  },
  SET_PERA_BANK_PARTNER_DETAILS (state, payload) {
    state.bankDepositPartnerDetails = payload
  }
}
