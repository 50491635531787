<template>
  <v-dialog
    v-model="dialog"
    :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '50%'"
    persistent
  >
    <v-card>
      <v-card-title class="ph-dialog-title">
        Do you want to Cash Out?
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <p class="ph-dialog-subtitle-radio"><b>Select Cash-out option:</b></p>
            <v-col cols="12">
              <v-radio-group
                v-model="disburseLoanData.disburse_type"
                mandatory
                row
                @change="getBanks($event)"
              >
                <v-radio
                  label="Cash Out"
                  value="cashout"
                ></v-radio>
                <v-radio
                  label="Bank Deposit"
                  value="bankdeposit"
                ></v-radio>
                <v-radio
                  label="Cash Out and Bank Deposit"
                  value="cashoutbd"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <p class="ph-dialog-subtitle-text"><b>Please fill-out the required fields to continue</b></p>
            <v-col class="py-5" cols="12">
              <v-text-field
                v-model="disburseLoanData.customer"
                label="Customer"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                readonly
              />
            </v-col>
            <v-col class="py-5" cols="12" md="6">
              <v-text-field
                v-model="disburseLoanData.cashout_amount"
                label="Cash Out Amount"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                type="number"
                :hide-spin-buttons="true"
                readonly
              />
            </v-col>
            <v-col class="py-5" cols="12" md="6">
              <v-text-field
                v-model="disburseLoanData.net_proceeds"
                label="Net Proceeds"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                type="number"
                readonly
                :hide-spin-buttons="true"
              />
            </v-col>
            <v-col class="py-5" cols="12" v-if="disburseLoanData.disburse_type !== 'bankdeposit'">
              <v-text-field
                v-model="disburseLoanData.actual_cash_out"
                label="Actual Cash Out"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                type="number"
                :hide-spin-buttons="true"
              />
            </v-col>
            <v-col class="py-5" cols="12" v-if="disburseLoanData.disburse_type !== 'cashout'">
              <v-text-field
                v-model="disburseLoanData.for_deposit"
                label="For Deposit"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                type="number"
                :hide-spin-buttons="true"
              />
            </v-col>
            <v-col class="py-5" cols="12" v-if="disburseLoanData.disburse_type !== 'cashout'">
              <v-autocomplete
                v-model="disburseLoanData.select_bank"
                :items="banks"
                item-text="bank_name"
                item-value="bank_code"
                :return-object="true"
                label="Bank"
                :rules="requiredField"
                color="gray darken-1"
                class="ph-textbox"
                autofocus
                outlined
              />
            </v-col>
            <v-col class="py-5" cols="12" md="6" v-if="disburseLoanData.disburse_type !== 'cashout'">
              <v-text-field
                v-model="disburseLoanData.account_name"
                label="Account Name"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                readonly
                :hide-spin-buttons="true"
              />
            </v-col>
            <v-col class="py-5" cols="12" md="6" v-if="disburseLoanData.disburse_type !== 'cashout'">
              <v-text-field
                v-model="disburseLoanData.account_number"
                label="Account Number"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                :hide-spin-buttons="true"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ph-action-button"
          color="danger"
          text
          @click="closeDialog"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          class="ph-action-button ph-action-button-receive-confirm"
          elevation="0"
          @click="disburseLoan"
        >
          Disburse
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'
import { format, parseISO } from 'date-fns'

export default {
  name: 'PeraLoanCashOutDialog',
  props: {
    cashOutDialog: Boolean,
    selectedTrx: Object
  },
  data () {
    return {
      dialog: false,
      disburseLoanData: {},
      selectedData: {},
      banks: [],
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  computed: {
    ...mapGetters({
      peraLoanTransactions: 'loans/peraLoanTransactions',
      currUser: 'auth/currUser'
    })
  },
  watch: {
    selectedTrx: function (data) {
      this.selectedData = data
      this.populateData(data)
    },
    cashOutDialog: function (data) {
      this.dialog = data
    },
    peraLoanCashOutData: {
      handler (val) {
      },
      deep: true
    },
    disburseLoanData: {
      handler (val) {
      },
      deep: true
    }
  },
  mounted () {

  },
  methods: {
    renderToast,
    populateData (data) {
      const currentData = {
        customer: data.customer_name,
        cashout_amount: data.gcashout,
        net_proceeds: data.gnetpro,
        account_name: data.customer_name
      }

      console.log(currentData)

      this.disburseLoanData = Object.assign({}, this.disburseLoanData, currentData)
    },
    closeDialog () {
      this.dialog = false
      this.$emit('update:cashOutDialog', false)
    },
    async getBanks (data) {
      if (data !== 'cashout') {
        await this.$store.dispatch('loans/getBanks').then(res => {
          this.banks = res

          const currentData = {
            account_name: this.selectedTrx.customer_name
          }

          this.disburseLoanData = Object.assign({}, this.disburseLoanData, currentData)
        })
      }
    },
    disburseLoan () {
      if (this.$refs.form.validate()) {
        const disburseData = this.disburseLoanData

        let payload = {}

        if (this.disburseLoanData.disburse_type === 'cashout' && (this.disburseLoanData.actual_cash_out > this.disburseLoanData.net_proceeds)) {
          this.renderToast('error', 'Validation Error', 'Actual Cash Out Amount must not be higher than net proceeds.')
        } else if (this.disburseLoanData.disburse_type === 'bankdeposit' && (this.disburseLoanData.for_deposit > this.disburseLoanData.net_proceeds)) {
          this.renderToast('error', 'Validation Error', 'For Deposit Amount must not be higher than net proceeds.')
        } else if (this.disburseLoanData.disburse_type === 'cashoutbd' &&
          ((this.disburseLoanData.actual_cash_out > this.disburseLoanData.net_proceeds) ||
          (this.disburseLoanData.for_deposit > this.disburseLoanData.net_proceeds))) {
          this.renderToast('error', 'Validation Error', 'For Deposit and/or Actual Cash Out Amount must not be higher than net proceeds.')
        } else {
          switch (this.disburseLoanData.disburse_type) {
            case 'cashout':
              payload = {
                id_loan: this.selectedTrx.id_loan,
                customer_name: disburseData.customer,
                actualcashout: disburseData.actual_cash_out,
                custid: this.selectedTrx.customer_id
              }
              break
            case 'bankdeposit':
              payload = {
                id_loan: this.selectedTrx.id_loan,
                customer_name: disburseData.customer,
                bank_id: disburseData.select_bank.banks_id,
                bank_name: disburseData.select_bank.bank_name,
                bank_acct: disburseData.account_number,
                dep_amount: disburseData.for_deposit,
                custid: this.selectedTrx.customer_id
              }
              break
            case 'cashoutbd':
              payload = {
                id_loan: this.selectedTrx.id_loan,
                customer_name: disburseData.customer,
                actualcashout: disburseData.actual_cash_out,
                bank_id: disburseData.select_bank.banks_id,
                bank_name: disburseData.select_bank.bank_name,
                bank_acct: disburseData.account_number,
                dep_amount: disburseData.for_deposit,
                custid: this.selectedTrx.customer_id
              }
              break
          }

          this.$store.dispatch('loans/saveDisbursement', payload).then(res => {
            if (res.message === 'Good') {
              this.closeDialog()
              this.renderToast('success', 'Loan Saving Success', 'The transaction was saved successfullly.')

              this.$store.dispatch('loans/getPeraLoanTrx', {
                date_from: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
                date_to: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
                location_id: this.currUser.location_id
              })
            } else {
              this.renderToast('error', 'Transaction Failed', 'Something went wrong when processing the transaction, please try again.')
            }
          }).catch(err => {
            console.log(err)
          })
          console.log(payload)
        }

        console.log(payload)
      } else {
        this.renderToast('error', 'Validation Error', 'You must complete the following fields')
      }
    }
  }
}
</script>
<style scoped>
.ph-dialog-subtitle {
  margin-top: 20px;
}

.ph-dialog-subtitle-text {
  margin-bottom: 0 !important;
  margin: 0 15px;
}

.ph-dialog-subtitle-radio {
  margin-bottom: 0 !important;
  margin: 30px 15px;
}
</style>
