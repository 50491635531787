import api from '@/api'

export default {
  getRemoteLocations (locationGroupID) {
    return api.get(`/v1/maintenance/api/remote/locations?location_group_id=${locationGroupID}`)
  },
  getRemoteUsers (locationID) {
    return api.get(`/v1/maintenance/api/remote/users?location_id=${locationID}`)
  },
  validateRemoteUser (payload) {
    return api.post('/v1/maintenance/api/remote/validate', payload)
  }
}
