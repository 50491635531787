<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="12" :sm="4" :md="4">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-8" :cols="12">
        <v-data-table
          :headers="headers"
          :items="transactions"
          :search="search"
          class="custom-table"
          :calculate-widths="true"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDateTime(item.created_at) }}
          </template>
          <template v-slot:[`item.sender_name`]="{ item }">
            <v-avatar class="mr-1" size="26px" :style="avatarColor(item.sender_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.sender_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.sender_name) }}
          </template>
          <template v-slot:[`item.receiver_name`]="{ item }">
            <v-avatar class="mr-1" size="26px" :style="avatarColor(item.receiver_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.receiver_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.receiver_name) }}
          </template>
          <template v-slot:[`item.remco_id`]="{ item }">
            <img
              style="width: 50px"
              :src="getRemcoImage(item.remco_id)"
              alt=""
            >
          </template>
          <template v-slot:[`item.total_amount`]="{ item }">
            {{ toMoney(item.total_amount) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span :class="{ 'red--text': (item.status === 0 || item.status === '0'), 'green--text': (item.status === 1 || item.status === '1')}">
              <v-icon
                :class="{ 'red--text': (item.status === 0 || item.status === '0'), 'green--text': (item.status === 1 || item.status === '1')}"
                x-small
              >
                mdi-circle
              </v-icon>
              {{ getStatusText(item.status) }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.status === 1 || item.status === '1'"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <!-- <v-list-item>
                  <v-list-item-title>Print OAR & ACR</v-list-item-title>
                </v-list-item> -->
                <v-list-item @click="printOAR(item)">
                  <v-list-item-title>Print OAR</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.status === 1" @click="reprintACRNoWacom(item)">
                  <v-list-item-title>Print ACR</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.status === 1 && !checkIfUploadedPre(item) && item.remco_id === 0" @click="retrieveUploaded(item, 'pre')">
                  <v-list-item-title>Print Pre Print</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.status === 1 && !checkIfUploaded(item)" @click="retrieveUploaded(item, 'acr')">
                  <v-list-item-title>Print Uploaded ACR</v-list-item-title>
                </v-list-item>
                <ACRUpload v-show="checkIfUploaded(item)" :item="item"/>
              </v-list>
            </v-menu>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="retrySend(item)"
                  v-if="item.status === 0 || item.status === '0'"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </template>
              <span>Retry</span>
            </v-tooltip>
            <div style="display: flex; flex-direction: row;" v-if="false">
              <v-btn
                text
                color="primary"
                v-if="item.status === 1 && !checkIfUploaded(item)"
                @click="retrieveUploaded(item)"
                >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <v-btn
                text
                color="primary"
                v-if="item.status === 1 && checkIfUploaded(item)"
                @click="reprintACRNoWacom(item)"
                >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <ACRUpload v-show="checkIfUploaded(item)" :item="item"/>
              <!-- <v-btn class="d-none d-sm-inline" color="primary" icon title="Print Receipt">
                <v-icon>mdi-printer</v-icon>
              </v-btn> -->
              <!-- <v-btn class="d-inline d-sm-none" color="primary" text>
                <v-icon class="mr-1">mdi-printer</v-icon>
                Print Receipt
              </v-btn> -->
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <wacom
      v-if="currUser.has_wacom"
      @submit-sig="submitSig" type="transactional"
      :referenceSignatures="customerSignaturesReference"
      :trxData="{sender_name: selectedItem.senderName, receiver_name: selectedItem.receiverName, total_amount: selectedItem.amount}"
    />
    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="newSendMoneyFulfillOBO"
            color="#1A2791"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <img src="@/assets/images/send-money-obo-icon.svg" width="18" />
          </v-btn>
        </template>
        <span>Fulfill Send Money for Different Location</span>
      </v-tooltip>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/pera-remit/send/new-fulfill-trx"
            color="#1A2791"
            v-bind="attrs"
            v-on="on"
            small
            fab
            dark
          >
            <v-img class="ph-fulfill-logo" src="@/assets/images/wu-fulfill-receive-icon.svg"></v-img>
          </v-btn>
        </template>
        <span>Fulfill Send Money</span>
      </v-tooltip>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="newSendMoneyOBO"
            color="#05ACE5"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <img src="@/assets/images/send-money-obo-icon.svg" width="18" />
          </v-btn>
        </template>
        <span>Send Money for Different Location</span>
      </v-tooltip>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="newSendMoney"
            color="yellow"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <img src="@/assets/images/send-money-icon.svg" width="18" />
          </v-btn>
        </template>
        <span>Send Money</span>
      </v-tooltip>
    </v-speed-dial>
    <v-dialog
      v-model="retryDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Retry Transaction
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to retry this transaction?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeRetryDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="showWacom"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import ACRUpload from '@/components/customers/ACRUpload'
import wacomMixin from '@/mixins/wacom.js'
import wacom from '@/components/wacom/wacomTransaction'
import { format } from 'date-fns'
import {
  getStatusText,
  avatarColor,
  avatarInitial,
  toProperCase,
  toMoney,
  renderToast
} from '@/utils'

export default {
  mixins: [wacomMixin],
  computed: {
    ...mapGetters({
      transactions: 'nonex/transactions',
      currUser: 'auth/currUser',
      remcoList: 'send/remcoList',
      remcos: 'nonex/remcos'
    })
  },
  watch: {
    transactions: {
      handler (newVal) {
        if (newVal) {
          console.log(newVal)
        }
      },
      immediate: true
    }
  },
  components: {
    ACRUpload,
    wacom
  },
  data () {
    return {
      customerSignature: '',
      filteredACRs: [],
      search: '',
      customerSignaturesReference: [],
      fab: false,
      selectedTransaction: null,
      retryDialog: false,
      selectedItem: {
        amount: '',
        senderName: '',
        receiverName: ''
      },
      breadcrumbsItems: [
        {
          text: 'PERA Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Send Money',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'created_at',
          width: '140px'
        },
        {
          text: 'MTCN / Ref No.',
          sortable: true,
          value: 'control_number',
          width: '150px'
        },
        {
          text: 'Sender',
          sortable: true,
          value: 'sender_name',
          width: '180px'
        },
        {
          text: 'Receiver',
          sortable: true,
          value: 'receiver_name',
          width: '180px'
        },
        {
          text: 'Send via',
          sortable: true,
          value: 'remco_id',
          width: '100px'
        },
        {
          text: 'Total Amount',
          sortable: true,
          value: 'total_amount',
          width: '150px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '130px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '90px'
        }
      ]
    }
  },
  methods: {
    formatAmountACR (amount) {
      return numeral(amount).format('0,0.00')
    },
    submitSig (emitted) {
      this.customerSignature = emitted
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
      this.doRetrySend()
    },
    async showWacom () {
      if (this.currUser.has_wacom) {
        this.selectedItem.senderName = this.selectedTransaction.sender_name
        this.selectedItem.receiverName = this.selectedTransaction.receiver_name
        this.selectedItem.amount = this.selectedTransaction.total_amount
        const signatures = await this.$store.dispatch('customers/retrieveSignatures', { customer_id: this.selectedTransaction.customer_id })
        this.customerSignaturesReference = signatures.result
        this.$store.commit('wacom/SET_WACOM_DIALOG', true)
      } else {
        this.doRetrySend()
      }
    },

    async retryWU (item) {
      // WU Retry code here...
      const payload = {
        mtcn: item.control_number,
        terminal_id: item.wu_terminal_id,
        operator_id: item.wu_opid,
        trx_wu_pending_id: item.id,
        location_id: item.location_id
      }

      const { code } = await this.$store.dispatch('wu/retrySendMoney', payload)

      if (code === 200) {
        this.closeRetryDialog()
        renderToast('success', 'Successful', 'Retry Succeeded')
        this.getTransactions()
      } else {
        renderToast('error', 'Failed', 'Retry Failed')
      }
    },
    async retryNonex (item) {
      const remcoData = this.remcos.data.filter(remco => remco.id === item.remco_id)

      if (remcoData.length > 0) {
        const baseRoute = remcoData[0].payout_route.split('/')
        const retryRoute = `${baseRoute[0]}/retry`
        const payload = {
          id: item.id,
          control_number: item.control_number,
          client_reference_no: item.client_reference_no,
          location_id: this.currUser.location_id,
          location_name: this.currUser.location_name,
          branch: this.currUser.location_name,
          branch_code: this.currUser.location_code,
          reference_number: item.control_number,
          remco_id: item.remco_id,
          retry_data: item,
          user_id: this.currUser.user_id
        }

        this.closeRetryDialog()

        try {
          const req = await this.$store.dispatch('nonex/executeSendout', {
            endpoint: retryRoute,
            payload: payload
          })

          if (req.code === 200 && !Object.prototype.hasOwnProperty.call(req, 'errors')) {
            const ACRPayload = {}
            const currentAddress = `${item.address}, ${item.barangay}, ${item.city}, ${item.province}, ${item.country}, ${item.zip_code}`
            const agentName = `${this.currUser.last_name}, ${this.currUser.first_name}`
            ACRPayload.remco_name = item.non_ex_name
            ACRPayload.sender_name = item.sender_name
            ACRPayload.remco_id = item.remco_id
            ACRPayload.trx_type = item.trx_type
            ACRPayload.receiver_name = item.receiver_name
            ACRPayload.current_address = currentAddress
            ACRPayload.id_type = item.id_type
            ACRPayload.id_number = item.id_number
            ACRPayload.contact_number = item.contact_number
            ACRPayload.purpose_transaction = item.purpose_transaction
            ACRPayload.control_number = item.control_number
            ACRPayload.trx_timestamp = this.formatDateTime(new Date())
            ACRPayload.vlocation_name = this.currUser.location_name
            ACRPayload.currency_name = 'PHP'
            ACRPayload.principal_amount = item.principal_amount
            ACRPayload.service_charge = item.service_charge
            ACRPayload.total_amount = item.total_amount
            ACRPayload.buy_back_amount = item.buy_back_amount
            ACRPayload.currency_id = item.currency_id
            ACRPayload.agent_name = agentName
            ACRPayload.location_id = this.currUser.location_id
            ACRPayload.has_wacom = this.currUser.has_wacom
            ACRPayload.crn = item.client_reference_no
            ACRPayload.uploaded_by = this.currUser.user_id
            ACRPayload.customer_id = item.customer_id.toString()
            if (this.currUser.has_wacom === true) {
              ACRPayload.img = this.customerSignature
              ACRPayload.img_fla = this.currUser.userSignature
            }
            await this.$store.dispatch('customers/generateACR', ACRPayload).then((res) => {
              var decoded = Buffer.from(res.result, 'base64')
              var blob = new Blob([decoded], { type: 'application/pdf' })
              var fileURL = URL.createObjectURL(blob)
              window.open(fileURL, '_blank')
            }).catch((error) => {
              console.log(error)
            })
            this.getTransactions()
            const acrs = await this.$store.dispatch('acr/retrieveFilteredACR',
              {
                location_id: this.currUser.location_id,
                trx_date: this.currUser.trx_date.replace(' 00:00:00', ''),
                user_id: this.currUser.user_id
              })
            this.filteredACRs = acrs.data
            this.renderToast('success', 'Retry Successful', 'Transaction has been retry successfully')
            window.open(`/receipt?ref=${item.form_number}&trx_type=nonex&type=OAR`, '_blank')
          } else {
            this.renderToast('error', 'Retry failed', 'An error occured while retrying the transaction')
          }
        } catch (e) {
          this.renderToast('error', 'Retry failed', 'An error occured while sending money')
        }
      }
    },
    doRetrySend () {
      const item = this.selectedTransaction

      if (item) {
        if (item.remco_id === 0) this.retryWU(item)
        else this.retryNonex(item)
      } else {
        this.closeRetryDialog()
        this.renderToast('error', 'Retry failed', 'There is no transaction selected')
      }
    },
    retrySend (item) {
      this.selectedTransaction = item
      this.retryDialog = true
    },
    closeRetryDialog () {
      this.selectedTransaction = null
      this.retryDialog = false
    },
    getRemcoImage (remcoID) {
      console.log(remcoID)
      if (remcoID !== null || remcoID !== undefined) {
        const remcoDetails = this.remcoList.filter(item => item.id === remcoID)

        if (remcoDetails.length === 1) {
          return require(`@/assets/images/partner/${remcoDetails[0].logo}`)
        }
        return ''
      }
    },
    newSendMoneyOBO () {
      this.$store.dispatch('send/resetForm')
      this.$router.push({
        path: '/pera-remit/remote-location?trx_type=SO'
      })
    },
    newSendMoneyFulfillOBO () {
      this.$router.push({
        path: '/pera-remit/remote-location?trx_type=SOF'
      })
    },
    newSendMoney () {
      this.$store.dispatch('send/resetForm')
      this.$router.push({
        path: '/pera-remit/send/new'
      })
    },
    customAvatarColor (name) {
      if (name === 'Western Union') {
        return 'background-color: #FFDD00'
      } else {
        return this.avatarColor(name)
      }
    },
    customAvatarInitial (name) {
      if (name === 'Western Union') {
        return 'WU'
      } else {
        return this.avatarInitial(name)
      }
    },
    async retrieveUploaded (item, type) {
      let filtered = {}
      if (type === 'acr') {
        filtered = this.filteredACRs.filter(function (e) {
          return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id && e.type === 'acr'
        })
      } else {
        filtered = this.filteredACRs.filter(function (e) {
          return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id && e.type === 'pre'
        })
      }

      const payload = {
        file: btoa(filtered[0].bucket_path)
      }
      const res = await this.$store.dispatch('acr/retrieveACRFile', payload)
      var extension = res.result.extension
      var file = Buffer.from(res.result.file, 'base64')
      var blob = ''
      if (extension === 'pdf') {
        blob = new Blob([file], { type: 'application/pdf' })
      } else if (extension === 'jpg') {
        blob = new Blob([file], { type: 'image/jpg' })
      } else if (extension === 'png') {
        blob = new Blob([file], { type: 'image/png' })
      } else if (extension === 'jpeg') {
        blob = new Blob([file], { type: 'image/jpeg' })
      }
      var fileURL = URL.createObjectURL(blob)
      window.open(fileURL, '_blank')
    },
    formatDateTime (date) {
      if (date) return format(new Date(date), 'yyyy-MM-dd h:mmaa')
      return ''
    },
    pdfConvert () {
      var decoded = Buffer.from(this.base64String, 'base64')
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      window.open(fileURL, '_blank')
      // FileSaver.saveAs(blob, 'filename')
    },
    checkIfUploaded (item) {
      const self = this
      var filtered = self.filteredACRs.filter(function (e) {
        return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id
      })
      if (filtered.length !== 0) {
        return false
      } else {
        return true
      }
    },
    checkIfUploadedPre (item) {
      const self = this
      var filtered = self.filteredACRs.filter(function (e) {
        return e.ref_number === item.control_number && parseInt(e.customer_id) === item.customer_id && e.type === 'pre'
      })
      if (filtered.length !== 0) {
        return false
      } else {
        return true
      }
    },
    checkUploadedFiles () {
      const self = this
      var transactionsWithACR = []
      self.transactions.forEach(function (e) {
        self.filteredACRs.forEach(function (f) {
          if (e.control_number === f.ref_number) {
            transactionsWithACR.push(e)
          }
        })
      })
      return transactionsWithACR
    },
    printBoth (data) {
      this.printACR(data)
      this.printOAR(data)
    },
    async printOAR (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: '2',
          or_series_no: '',
          oar_series_no: data.form_number,
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    },
    printACR (data) {},
    reprintACR (payload) {
      var filtered = this.filteredACRs.filter(function (e) {
        return e.ref_number === payload
      })
      window.open(filtered[0].bucket_path, '_blank')
      this.printOAR(payload.form_number)
    },
    async reprintACRNoWacom (item) {
      let customerSignature = ''

      await this.$store.dispatch('customers/retrieveSignatures', { customer_id: item.customer_id.toString() }).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          if (res.result.length > 0) {
            customerSignature = res.result[0].bucket_path
          } else {
            customerSignature = ''
          }
        } else {
          customerSignature = ''
        }
      })

      if (item.remco_id === 0) {
        const that = this
        const printReceiptPayload = {}

        const payoutAmount = item.exchange_rate * item.principal_amount
        const payoutRounded = Math.round(payoutAmount * 100) / 100

        printReceiptPayload.print_data = {
          sender: item.sender_name,
          address: item.address + ' ' + item.barangay + ' ' + item.city + ' ' + item.province + ' ' + item.zip_code + ' ' + item.country,
          id_type: '',
          id_number: item.id_number,
          test_question: '',
          answer: '',
          sender_mobile_number: item.contact_number,
          receiver: item.receiver_name,
          r_city: '',
          r_country: '',
          service: '',
          message: item.delivery_message,
          receiver_mobile_number: '',
          mtcn: item.control_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
          date_time: this.formatDateTime(new Date()),
          agent_name: this.currUser.location_name + ' BRANCH',
          amount: item.principal_amount.toFixed(2),
          transfer_fee: parseInt(item.service_charge).toFixed(2),
          msg_charge: '',
          delivery_charge: '0.00',
          promo_code: '',
          discount: '',
          total: 'Philippine Peso ' + this.formatAmountACR(item.total_amount.toFixed(2)),
          exchange_rate: item.exchange_rate,
          payout_amount: item.country_currency_paid + ' ' + payoutRounded,
          promo_text_message: '',
          pin_text_message_set_2: '',
          want_to_register: '',
          mywu_number: '',
          mywu_points: '',
          total_mywu_points: '',
          adj_type: '',
          mc_rate: '',
          buyback_amount: '',
          total_amount: '',
          purpose_of_trxn: item.purpose_transaction,
          promo_flag: 'N',
          expiration_date: '',
          vuser_group_id: this.currUser.user_group_id
        }
        printReceiptPayload.data = {
          has_wacom: this.currUser?.has_wacom ? this.currUser.has_wacom : false,
          trx_type: 1,
          remco_name: 'Western Union',
          control_number: '',
          customer_id: item.customer_id.toString(),
          location_id: this.currUser.location_id,
          location_name_uploaded: this.currUser.location_name,
          crn: '-',
          uploaded_by: this.currUser.user_id,
          first_name: '-',
          last_name: '-',
          product: 'WU Send Money',
          receipt_type: 'acr'
        }

        if (this.currUser.has_wacom) {
          printReceiptPayload.print_data.fla_signature = this.currUser.userSignature
          printReceiptPayload.print_data.customer_signature = customerSignature
        }

        setTimeout(function () {
          const thatFunc = that
          that.$store.dispatch('wu/generateACRWU', printReceiptPayload).then((res) => {
            thatFunc.base64String = res.result
            thatFunc.pdfConvert()
          }).catch((error) => {
            console.log(error)
          })
        }, 3000)
      } else {
        const that = this
        const currentAddress = `${item.address}, ${item.barangay}, ${item.city}, ${item.province}, ${item.country}`
        const agentName = `${this.currUser.last_name}, ${this.currUser.first_name}`
        const ACRPayload = {
          remco_name: item.non_ex_name,
          sender_name: item.sender_name,
          remco_id: item.remco_id,
          trx_type: item.trx_type,
          receiver_name: item.receiver_name,
          current_address: currentAddress,
          id_type: item.id_type,
          id_number: item.id_number,
          contact_number: item.contact_number,
          purpose_transaction: item.purpose_transaction,
          control_number: item.control_number,
          trx_timestamp: this.formatDateTime(new Date()),
          vlocation_name: this.currUser.location_name,
          currency_name: 'PHP',
          principal_amount: item.principal_amount,
          service_charge: item.service_charge,
          total_amount: item.total_amount,
          buy_back_amount: item.buy_back_amount,
          currency_id: item.currency_id,
          agent_name: agentName,
          location_id: item.location_id,
          customer_id: item.customer_id.toString(),
          crn: item.client_reference_no,
          has_wacom: this.currUser?.has_wacom ? this.currUser.has_wacom : false,
          uploaded_by: this.currUser.user_id
        }

        if (this.currUser.has_wacom) {
          ACRPayload.img_fla = this.currUser.userSignature
          ACRPayload.img = customerSignature
        }

        setTimeout(function () {
          const thatFunc = that
          that.$store.dispatch('customers/generateACR', ACRPayload).then((res) => {
            thatFunc.base64String = res.result
            thatFunc.pdfConvert()
          }).catch((error) => {
            console.log(error)
          })
        }, 3000)
      }
    },
    getTransactions () {
      this.$store.dispatch('nonex/getTransactions', {
        trxDate: this.currUser.trx_date,
        locationID: this.currUser.location_id,
        trxType: 1
      })
    },
    renderToast,
    toMoney,
    getStatusText,
    avatarColor,
    avatarInitial,
    toProperCase
  },
  beforeMount () {
    this.getTransactions()
    this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
    this.$store.dispatch('nonex/getRemcos')
  },
  async mounted () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)

    const acrs = await this.$store.dispatch('acr/retrieveFilteredACR',
      {
        location_id: this.currUser.location_id,
        trx_date: this.currUser.trx_date.replace(' 00:00:00', ''),
        user_id: this.currUser.user_id
      })
    this.filteredACRs = acrs.data
    // this.checkUploadedFiles()
    await this.$store.dispatch('nonex/getRemcos')
  }
}
</script>
<style scoped>
  .ph-fulfill-logo {
    transform: scale(0.35);
    border-radius: 0;
  }
</style>
