export default {
  module: {
    hasAccess: false,
    subModules: []
  },
  uploadedData: [],
  uploadedFileName: '',
  pnaAlertDialog: {
    isShow: false,
    type: null,
    details: null,
    customer: null,
    isFalsePositive: false
  },
  highRiskDialog: {
    isShow: false,
    type: null,
    details: null,
    customer: null
  },
  riskData: {},
  selectedMonitoringItem: null,
  selectedWhitelistingItem: null,
  currentPage: 0,
  auditTrailData: {
    type: '',
    payload: {}
  }
}
