export default {
  SET_BILLS_PAYMENT_TRANSACTIONS (state, payload) {
    state.billsPaymentTransactions = payload
  },
  SET_BILLS_PAYMENT_CURRENT_TRX (state, payload) {
    state.billsPaymentCurrentTrx = payload
  },
  SET_BILLS_PAYMENT_CURRENT_PAGE (state, payload) {
    state.billsPaymentCurrentPage = payload
  },
  SET_BILLS_BILLERS (state, payload) {
    state.billers = payload
  },
  SET_BILLS_MISC (state, payload) {
    state.billsPaymentGetMisc = payload
  },
  SET_BILLS_PAYMENT_RECEIPT_DATA (state, payload) {
    state.bayadReceiptData = payload
  },
  SET_BILLS_TPA_ID (state, payload) {
    state.tpaId = payload
  },
  SET_BILLS_PAYMENT_RESPONSE_DATA (state, payload) {
    state.billsPaymentResponseData = payload
  },
  SET_BILLS_COLLECTION_CURRENT_PAGE (state, payload) {
    state.billsCollectionCurrentPage = payload
  },
  SET_BILLS_COLLECTION_TRANSACTIONS (state, payload) {
    state.billsCollectionTransactions = payload
  },
  SET_BILLS_COLLECTION_RESPONSE_DATA (state, payload) {
    state.billsCollectionResponseData = payload
  },
  SET_BILLS_COLLECTION_CURRENT_TRX (state, payload) {
    state.billsCollectionCurrentTrx = payload
  },
  SET_BILLS_COLLECTION_PROVIDERS (state, payload) {
    state.collectionProviders = payload
  },
  SET_BILLS_COLLECTION_VOUCHER_DATA (state, payload) {
    state.billsCollectionVoucherData = payload
  },
  SET_BILLS_LOAN_ACCOUNT_NO (state, payload) {
    state.billsLoanAccountNo = payload
  }
}
