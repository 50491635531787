<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        class="ph-breadcrumbs"
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ph-transaction-container">
        <v-col cols="12">
          <div>
            <v-row class="mt-md-5" no-gutters>
              <v-col :cols="12" :md="4">
                <h3 class="pb-md-6 custom-heading primary--text">
                  Inquire Transaction
                </h3>
                <p class="text-body-2">
                  Check the current status of your transaction
                </p>
              </v-col>
              <v-col class="pl-md-6" :cols="12" :md="8">
                <h4 class="mb-5 grey--text text--darken-2">
                  Transaction info
                </h4>
                <v-autocomplete
                  v-model="provider"
                  :items="providers"
                  item-text="code"
                  item-value="name"
                  :return-object="false"
                  label="Payment Provider"
                  color="gray darken-1"
                  class="ph-textbox mb-md-6 ph-textbox-quickpay"
                  outlined
                />
                <v-autocomplete
                  v-model="biller"
                  label="Biller"
                  :items="billers"
                  item-text="name"
                  item-value="code"
                  color="gray darken-1"
                  class="ph-textbox mb-md-6 ph-textbox-quickpay"
                  outlined
                  placeholder="Select Biller"
                  :return-object="true"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name }} - {{ item.category }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name }} - {{ item.category }}
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-model="refNumber"
                  class="ph-textbox mb-md-6 ph-textbox-quickpay"
                  label="Reference Number"
                  @keyup.enter="doInquire"
                  outlined
                />
                <div class="text-right">
                  <v-btn @click="doInquire" :disabled="!provider || !biller || !refNumber" class="rounded-lg ph-search-customer" color="primary" large>
                    Inquire Payment
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" v-if="inquireInfo.length > 0">
          <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  <b>Biller Name</b>
                </th>
                <th class="text-left">
                  <b>Category</b>
                </th>
                <th class="text-left">
                  <b>Transaction ID</b>
                </th>
                <th class="text-left">
                  <b>Account Number</b>
                </th>
                <th class="text-left">
                  <b>Client Ref No.</b>
                </th>
                <th class="text-left">
                  <b>Biller Ref No.</b>
                </th>
                <th class="text-left">
                  <b>Status</b>
                </th>
                <th class="text-left">
                  <b>Date Created</b>
                </th>
                <th class="text-left">
                  <b>Date Posted</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in inquireInfo"
                :key="index"
              >
                <td>{{biller.name}}</td>
                <td>{{biller.category}}</td>
                <td>{{item.transactionId}}</td>
                <td>{{item.referenceNumber}}</td>
                <td>{{item.clientReference}}</td>
                <td>{{item.billerReference}}</td>
                <td>{{item.status}}</td>
                <td>{{item.createdAt}}</td>
                <td>{{item.postedAt}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      billers: 'bills/billers',
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      refNumber: '',
      biller: '',
      provider: '',
      showRemoteNotice: false,
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Bills',
          disabled: false,
          href: '/pera-bills'
        },
        {
          text: 'Inquire',
          disabled: false,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Biller Name',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Category',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Transaction ID',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Account Number',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Client Ref No.',
          sortable: true,
          value: 'mobile_no'
        },
        {
          text: 'Biller Ref No.',
          sortable: true,
          value: 'mobile_no'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'mobile_no'
        },
        {
          text: 'Date Created',
          sortable: true,
          value: 'mobile_no'
        },
        {
          text: 'Date Posted',
          sortable: true,
          value: 'mobile_no'
        }
      ],
      providers: [
        { code: 'Bayad Center', name: 'Bayad Center' }
      ],
      inquireInfo: []
    }
  },
  methods: {
    renderToast,
    async doInquire () {
      if (this.provider && this.biller && this.refNumber) {
        const payload = {
          code: this.biller.code,
          clientReference: this.refNumber
        }

        this.inquireInfo = []
        this.$store.dispatch('bills/inquireTrx', payload).then(res => {
          if (res !== undefined) {
            this.inquireInfo.push(res)
          } else {
            this.renderToast('error', 'Search Error', 'No Transaction Found.')
          }
        })
      } else {
        this.renderToast('error', 'Search Error', 'The required fields must be completed.')
      }
    }
  },
  async mounted () {
    const tpaPayload = {
      bill_id: 2,
      location_id: this.currUser.location_id
    }

    let tpaId = ''

    await this.$store.dispatch('bills/getTPARequest', tpaPayload)
      .then(res => {
        tpaId = res.tpa_id
      }).catch(e => {
        console.log(e)
      })

    const payload = {
      grant_type: 'client_credentials',
      tpa_id: tpaId,
      scope: 'mecom-auth/all'
    }

    await this.$store.dispatch('bills/getMisc', payload)
    await this.$store.dispatch('bills/getBillers')
    this.provider = 'Bayad Center'
  }
}
</script>
<style scoped>
  .ph-textbox-quickpay {
    margin-bottom: 16px !important;
  }

  .ph-bills-new-customer-btn {
    margin-left: 15px;
  }

  .ph-transaction-container .col {
    padding: 15px;
  }

  .ph-breadcrumbs {
    padding: 5px 5px;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }

    .ph-transaction-container .col {
      padding: 12px;
    }

    .ph-breadcrumbs {
      padding: 18px 12px;
      padding-left: 24px;
    }
  }
</style>
