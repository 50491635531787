import api from '@/api'

export default {
  uploadUserSignature (payload) {
    return api.post('/v1/maintenance/api/user-signature-create', payload)
  },

  retrieveUserSignature (payload) {
    return api.post('/v1/maintenance/api/user-signature-search', payload)
  },

  searchUser (payload) {
    return api.post('/v1/maintenance/api/user-management/search-user', payload)
  },
  getUsers () {
    return api.get('/v1/maintenance/api/user-management/show-users')
  },
  addUser (payload) {
    return api.post('/v1/maintenance/api/user-management/add-user', payload)
  },
  updateUser (userID, payload) {
    return api.put(`/v1/maintenance/api/user-management/update-user/${userID}`, payload)
  },
  deleteUser (userID) {
    return api.put(`/v1/maintenance/api/user-management/delete-user/${userID}`)
  },
  allocateLocation (payload) {
    return api.post('/v1/maintenance/api/user-management/add-user-loc', payload)
  },
  getUserRoles (payload) {
    return api.post('/v1/maintenance/api/user-management/show-user-management-role', payload)
  },
  searchUserRole (payload) {
    return api.post('/v1/maintenance/api/user-management/search-user-management-role', payload)
  },
  addUserRole (payload) {
    return api.post('/v1/maintenance/api/user-management/add-user-management-role', payload)
  },
  updateUserRole (payload) {
    return api.post('/v1/maintenance/api/user-management/update-user-management-role', payload)
  },
  deleteUserRole (payload) {
    return api.post('/v1/maintenance/api/user-management/delete-user-management-role', payload)
  },
  showUserRoles (payload) {
    return api.post('/v1/maintenance/api/user-management/show-user-roles', payload)
  },
  showAllModules (payload) {
    return api.post('/v1/maintenance/api/user-management/all-modules', payload)
  }
}
