import { endpoints } from '@/api/endpoints'

const acr = endpoints.use('acr')

export default {
  retrieveFilteredACR: ({ commit }, payload) => new Promise((resolve, reject) => {
    acr.retrieveFilteredACR(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  retrieveACRFile: ({ commit }, payload) => new Promise((resolve, reject) => {
    acr.retrieveACRFile(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
