<template>
  <v-container fluid>
    <v-row class="mx-2 mt-1">
      <v-col :cols="7">
        <v-breadcrumbs class="px-0 mx-0 pb-10" :items="breadcrumbsItems">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-form ref="form">
          <h4 class="mb-3">Merchant</h4>
          <v-select
            v-model="formData.merchant"
            :items="merchants"
            class="ph-textbox"
            placeholder="Select Merchant"
            item-text="MERCHANT_DESC"
            item-value="MERCHANT_CODE"
            :rules="requiredRules"
            return-object
            outlined
          />
          <h4 class="mt-10 mb-3">Look-Up Type</h4>
          <v-row no-gutters>
            <v-col
              v-for="(type, index) in lookUpTypes"
              :key="index"
              class="py-1 py-md-0 pr-md-2 pb-md-2 mr-5"
              :cols="12"
              :sm="6"
              :md="4"
            >
              <v-card
                @click="selectType(type.value)"
                :class="['rounded-lg py-4 px-3 selection', { 'selected': formData.type === type.value }]"
                outlined
                flat
              >
                <v-card-text>
                  <v-row align="center" no-gutters>
                    <v-col class="d-flex flex-column align-center" :cols="4">
                      <img
                        v-if="type.icon"
                        style="width: 100%;width: 30px;height: 30px;"
                        :src="require(`@/assets/images/wuPayments/${type.icon}.svg`)"
                        alt=""
                      >
                    </v-col>
                    <v-col class="pl-md-2" :cols="8">
                      <span>{{ type.text }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <h4 class="mt-10 mb-3">{{ (formData.type === 'PAY_CODE') ? 'Pay' : 'QR' }} Code</h4>
          <v-text-field
            v-model="formData.code"
            @keyup="maskCode"
            class="ph-textbox"
            placeholder="Enter Pay Code"
            maxlength="11"
            :rules="requiredRules"
            v-if="formData.type === 'PAY_CODE'"
            outlined
          />
          <v-text-field
            v-model="formData.code"
            type="password"
            class="ph-textbox"
            placeholder="Scan the QR Code"
            :rules="requiredRules"
            v-if="formData.type === 'QR_CODE'"
            outlined
          />
        </v-form>
        <div class="mt-12 d-flex justify-space-between">
          <v-btn
            @click="cancel"
            class="rounded-lg font-weight-bold"
            color="primary"
            outlined
            large
          >
            Cancel
          </v-btn>
        </div>
      </v-col>
      <v-col class="pl-12 pr-8" :cols="5">
        <v-card id="summaryOverview" class="d-none d-md-block px-8 py-6 grey--text text--darken-2" flat>
          <h2 class="text-center mb-md-8">Summary</h2>
          <h4 class="mb-4">Order Details</h4>
          <v-row no-gutters>
            <v-col :cols="12">
              <div class="d-flex justify-space-between">
                <div>Merchant Name</div>
                <div>
                  <strong>{{ ((formData.merchant) ? formData.merchant.MERCHANT_DESC : '—') }}</strong>
                </div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="d-flex justify-space-between">
                <div>{{ (formData.type === 'PAY_CODE') ? 'Pay' : 'QR' }} Code</div>
                <div>
                  <strong v-if="formData.type === 'PAY_CODE'">{{ formData.code || '—' }}</strong>
                  <strong v-else>{{ (formData.code ? 'Provided' : '—') }}</strong>
                </div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="d-flex justify-space-between">
                <div>Total Payment</div>
                <div>
                  <strong>{{ formData.totalPayment !== '' ? formData.totalPayment : '—' }}</strong>
                </div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
          </v-row>
          <h4 class="mt-10 mb-4">Customer Details</h4>
          <v-row no-gutters>
            <v-col :cols="12">
              <div class="d-flex justify-space-between">
                <div>First Name</div>
                <div>
                  <strong>{{ formData.firstName || '—' }}</strong>
                </div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
            <v-col :cols="12">
              <div class="d-flex justify-space-between">
                <div>Last Name</div>
                <div>
                  <strong>{{ formData.lastName || '—' }}</strong>
                </div>
              </div>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
          </v-row>
          <v-checkbox
            v-model="formData.isVerified"
            align="start"
            :ripple="false"
            class="customFont mt-6"
            label="Check this if ID presented is verified"
            hide-details
          />
          <br>
          <v-btn
            @click="validateTrx"
            class="rounded-lg font-weight-bold"
            color="primary"
            outlined
            block
            x-large
          >
            Validate
          </v-btn>
          <v-btn
            @click="openVerifyOTP"
            class="rounded-lg mt-5 font-weight-black"
            color="primary"
            block
            x-large
            :disabled="!isTrxVerified"
          >
            Confirm
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="otpDialog"
      persistent
      :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '30%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="closeOTP" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <br>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withoutEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updateDetails(selectedCustomer)"
            color="primary"
            text
          >
            Update Customer Record
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="holdTrx(lookupResponse)"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Receipt will send to the client/s email address. Would you like to proceed this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="printReceiptInstead"
            color="primary"
            text
          >
            I want a printed Receipt
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="holdTrx(lookupResponse)"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resultDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="60%">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            PERA Hub Customer Results
          </h3>
          <v-spacer />
          <v-btn @click="resultDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select Customer</strong>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                class="ph-textbox rounded-lg"
                placeholder="Search"
                outlined
              />
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectCustomer"
            :headers="headers"
            :items="searchData.results"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No customers found"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ toProperCase(item.gender) || '-' }}
            </template>
            <template v-slot:[`item.mobile_no`]="{ item }">
              {{ item.mobile_no || '-' }}
            </template>
          </v-data-table>
          <div class="d-flex align-center">
            <span>Can't find customer here?</span>
            <v-btn
              @click="addNewCustomer"
              color="primary"
              x-small
              text
            >
              Add New Customer
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showQRDialog"
      persistent
      max-width="390"
      justify="center"
    >
      <v-card>
        <v-toolbar
          color="#FFFFFF"
          elevation="0"
          light
        >
          <v-spacer></v-spacer>
          <v-btn icon @click="closeQRDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h5 ph-search-dialog-title">
          Scan the QR Code to Continue
        </v-card-title>
        <v-card-text class="ph-search-dialog-text">
          <img v-if="isSearching" src="@/assets/images/ph-scan-qr-code.gif" />
        </v-card-text>
        <v-card-actions class="ph-search-dialog-actions">
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            outlined
            text
            class="ph-primary-button ph-action-button ph-search-dialog-cancel-button"
            @click="closeQRDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  renderToast,
  getForeignRefNo,
  avatarColor,
  avatarInitial,
  toProperCase
} from '@/utils'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer',
      searchData: 'customers/searchData'
    })
  },
  data () {
    return {
      otpDialog: false,
      isOTPLoading: false,
      otpMessage: '',
      otp: '',
      summaryData: [],
      foreignRefNo: '',
      formData: {
        merchant: null,
        type: 'PAY_CODE',
        code: '',
        totalPayment: 0,
        firstName: '',
        lastName: '',
        isVerified: false
      },
      requiredRules: [v => !!v || 'This field is required'],
      merchants: [],
      lookUpTypes: [
        { text: 'By Pay Code', value: 'PAY_CODE', icon: 'pay-code' }
        // { text: 'By QR Code', value: 'QR_CODE', icon: 'qr-code' }
      ],
      breadcrumbsItems: [
        {
          text: 'Pay @ WU',
          disabled: false,
          exact: true,
          to: '/wu-payments'
        },
        {
          text: 'Payment Order',
          disabled: false,
          exact: true,
          to: '/wu-payments/pay'
        },
        {
          text: 'New Transaction',
          disabled: true
        }
      ],
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      lookupResponse: {},
      holdPayload: {},
      holdResponse: {},
      resultDialog: false,
      searchText: '',
      headers: [
        {
          text: 'Customer No.',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'mobile_no'
        }
      ],
      receipt: {},
      showQRDialog: false,
      isSearching: false,
      qrData: '',
      isTrxVerified: false
    }
  },
  mounted () {
    this.$store.commit('wuPayments/SET_CURRENT_ORDER_TRX', {})
    this.foreignRefNo = this.getForeignRefNo()
    const payload = {
      DASName: 'MerchantList',
      operator_id: this.currUser.user_id,
      terminal_id: this.currUser.fs_id,
      foreign_reference_no: this.foreignRefNo,
      location_id: this.currUser.location_id
    }

    this.$store.dispatch('wuPayments/getMerchants', payload).then(res => {
      if (res.code === 200) {
        if (typeof res.result === 'object') {
          this.merchants = [res.result]
        } else {
          this.merchants = res.result
        }
      }
    })
  },
  methods: {
    getForeignRefNo,
    toProperCase,
    avatarColor,
    avatarInitial,
    addNewCustomer () {
      this.$router.push({
        path: '/customers/new',
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    async doVerify (pin) {
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        const payload = {
          user_name: this.currUser.user_name,
          otp_code: this.otp,
          pin: this.currUser.otp_pin,
          module: 'paywu'
        }

        const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)
        const isOTPVerified = OTPVerification.result?.value || false

        if (isOTPVerified) {
          this.otpInvalid = false
          this.isOTPLoading = false
          this.otpDialog = false
          this.releaseTrx(this.holdPayload, this.holdResponse)
        } else {
          this.otp = ''
          this.otpInvalid = true
          this.isOTPLoading = false
          this.otpMessage = 'Incorrect OTP PIN, please try again.'
        }
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    },
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    openVerifyOTP () {
      this.otp = ''
      this.otpDialog = true
    },
    maskCode () {
      let code = this.formData.code.split('-').join('')

      if (code.length > 0) {
        code = code.match(new RegExp('.{1,3}', 'g')).join('-')
      }
      this.formData.code = code
    },
    cancel () {
      this.$router.push('/wu-payments/pay')
    },
    async validateTrx () {
      this.lookupResponse = {}
      this.holdPayload = {}
      this.holdResponse = {}

      if (this.formData.isVerified) {
        if (this.$refs.form.validate()) {
          const payload = {
            confirmation_number: this.formData.code,
            merchant_id: this.formData.merchant.MERCHANT_CODE,
            operator_id: this.currUser.user_id,
            terminal_id: this.currUser.fs_id,
            foreign_reference_no: this.foreignRefNo,
            lookup_key: this.formData.type === 'PAY_CODE' ? 'CONFIRMATION_NUMBER' : 'QR_CODE',
            search_type: 'PAYMENT',
            location_id: this.currUser.location_id
          }

          await this.$store.dispatch('wuPayments/orderLookup', payload).then(res => {
            if (res.code >= 200 && res.code <= 299) {
              this.customerLookup(res.result)
              this.isTrxVerified = true
            } else {
              renderToast('error', 'Lookup Error', res.message)
            }
          }).catch(err => {
            renderToast('error', 'Lookup Error', err)
          })
        } else {
          renderToast('warning', 'Validation Error', 'Please fill out all required fields')
        }
      } else {
        renderToast('error', 'ID Not Verified', 'Please make sure that the customer\'s ID is checked and verified.')
      }
    },
    async customerLookup (data) {
      const customerDetails = data.customer_details
      const customerNameInfo = customerDetails.name
      this.lookupResponse = data

      if (customerNameInfo.first_name && customerNameInfo.last_name) {
        const firstName = customerNameInfo.first_name.replace(/(^\s+|\s+$)/g, '')
        const lastName = customerNameInfo.last_name.replace(/(^\s+|\s+$)/g, '')
        const keyword = `${lastName},${firstName}`

        this.searchData.results = []
        this.$store.dispatch('customers/doSearch', keyword)
        this.resultDialog = true
      } else {
        this.searchData.results = []
      }
    },
    async selectCustomer (customerDetails) {
      // Checking of available offer for the customer
      const { code, result } = await this.$store.dispatch('prompt/checkAvailableOffer', {
        customer_number: customerDetails.customer_number,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id
      })

      if (code === 200 && result) {
        this.$store.commit('prompt/SET_AVAILABLE_OFFER', result)
        this.$store.commit('prompt/SET_SHOW_OFFER_DIALOG', true)
      }

      this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)
      this.resultDialog = false
      this.checkEmail()
    },
    async holdTrx (data) {
      this.withoutEmailPrompt = false
      this.withEmailPrompt = false

      if (this.currUser.location_type !== '1') {
        this.executeHold(data)
      } else {
        const getSeries = await this.$store.dispatch('getReceipt', {
          location_id: this.currUser.location_id,
          type: '2',
          application_id: this.currUser.application_id
        })

        if (getSeries.code === 200) {
          this.receipt = getSeries.result
          this.executeHold(data)
        }
      }
    },
    async executeHold (data) {
      const customerDetails = data.customer_details
      const complianceDetails = customerDetails.compliance_details
      const customerLocation = customerDetails.address
      const customerNameInfo = customerDetails.name
      const customerMobileDetails = customerLocation.phone_details

      this.formData.totalPayment = data.payment_details.amount
      this.formData.firstName = customerNameInfo.first_name
      this.formData.lastName = customerNameInfo.last_name

      const payload = {
        order_details: data.order_details,
        payment_details: data.payment_details,
        order_digest: data.order_digest,
        country_of_birth: complianceDetails.country_of_birth,
        customer_type: customerDetails.customer_type,
        complete_name: customerNameInfo.complete_name,
        first_name: customerNameInfo.first_name,
        last_name: customerNameInfo.last_name,
        country_code: customerLocation.country_code,
        date_of_birth: complianceDetails.date_of_birth,
        customer_id: customerDetails.customer_id,
        addr_line1: customerLocation.addr_line1,
        city: customerLocation.city,
        state: customerLocation.state,
        postal_code: customerLocation.postal_code,
        mobile_country_code: customerMobileDetails.country_code,
        phone_number: customerLocation.phone_details.phone_number,
        mtcn: '',
        new_mtcn: '',
        compliance_data_buffer: data?.compliance_data_buffer ? data.compliance_data_buffer : '0106UMP_010708120619653311PHILIPPINES2126GOODS AND SERVICES PAYMENT2011PHILIPPINES310417028612METRO MANILA3014PARANAQUE CITY2929237 QUIRINO AVENUE BRGY TAMBOC4026332109284424807',
        phone_type: customerLocation.phone_details.phone_type,
        operator_id: this.currUser.user_id,
        terminal_id: this.currUser.fs_id,
        foreign_reference_no: this.foreignRefNo,
        location_id: this.currUser.location_id,
        trx_date: this.currUser.trx_date,
        customers_id: this.selectedCustomer.customer_id,
        form_type: (this.currUser.location_type === '1' ? 'OAR' : ''),
        form_number: (this.currUser.location_type === '1' ? this.receipt.oar_series_no : this.foreignRefNo),
        tran_type: 'PAYMENT'
      }

      this.holdPayload = payload

      this.$store.dispatch('wuPayments/orderPaymentHold', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.holdResponse = res.result
          this.openVerifyOTP()
        } else {
          renderToast('error', 'Lookup Error', res.message)
        }
      }).catch(err => {
        renderToast('error', 'Lookup Error', err)
      })
    },
    async releaseTrx (holdPayload, holdResponse) {
      console.log(holdPayload)
      const payload = {
        order_details: holdResponse.new_order_details,
        payment_details: holdPayload.payment_details,
        order_digest: holdResponse.order_digest,
        country_of_birth: holdPayload.country_of_birth,
        customer_type: holdPayload.customer_type,
        complete_name: holdPayload.complete_name,
        first_name: holdPayload.first_name,
        last_name: holdPayload.last_name,
        country_code: holdPayload.country_code,
        date_of_birth: holdPayload.date_of_birth,
        customer_id: holdPayload.customer_id,
        addr_line1: holdPayload.addr_line1,
        city: holdPayload.city,
        state: holdPayload.state,
        postal_code: holdPayload.postal_code,
        mobile_country_code: holdPayload.mobile_country_code,
        phone_number: holdPayload.phone_number,
        mtcn: holdResponse.transaction_details.mtcn,
        new_mtcn: holdResponse.transaction_details.new_mtcn,
        compliance_data_buffer: holdPayload.compliance_data_buffer,
        phone_type: holdPayload.phone_type,
        operator_id: holdPayload.operator_id,
        terminal_id: holdPayload.terminal_id,
        foreign_reference_no: holdPayload.foreign_reference_no,
        location_id: holdPayload.location_id,
        trx_date: holdPayload.trx_date,
        customers_id: holdPayload.customers_id,
        form_type: holdPayload.form_type,
        form_number: holdPayload.form_number,
        tran_type: holdPayload.tran_type
      }

      payload.payment_details.exchange_rate = 0.00

      this.$store.commit('wuPayments/SET_CURRENT_ORDER_TRX', payload)
      await this.$store.dispatch('wuPayments/orderPaymentRelease', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          if (this.currUser.location_type === '1') {
            this.renderReceipt(res)
          }

          this.$router.push('/wu-payments/success')
          renderToast('success', 'Order Payment Successful', res.message)
        } else {
          renderToast('error', 'Order Release Error', res.message)
        }
      }).catch(err => {
        console.log(err)
        renderToast('error', 'Order Release Error', err)
      })
    },
    selectType (type) {
      this.formData.code = ''
      this.formData.type = type

      if (type === 'QR_CODE') {
        this.scanQRCode()
      } else {
        this.qrData = ''
        this.formData.code = ''
      }
    },
    async renderReceipt (data) {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipalty
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const saveReceipt = await this.$store.dispatch('saveReceipt', {
        ereceipt_id: this.receipt.ereceipt_id,
        application_id: this.currUser.application_id,
        application_header_name: this.currUser.branchDetail.header_name,
        application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
        receipt_type: this.receipt.type,
        customer_id: this.selectedCustomer.customer_id,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_tin_no: '',
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
        cashier_id: this.currUser.user_id,
        cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
        oar_receipt_no: this.receipt.oar_receipt_no,
        oar_number: this.receipt.oar_series_no,
        oar_service: `Pay @ WU (${this.formData.code})`,
        oar_currency: 'PHP',
        oar_principal_amount: Number(this.formData.totalPayment),
        oar_discount: 0,
        oar_total: Number(this.formData.totalPayment),
        grand_total: Number(this.formData.totalPayment),
        print_email_hold: 0,
        is_forex_logo: 0
      })

      if (saveReceipt.code === 200) {
        if (saveReceipt.result.receipt_status === 1) {
          renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
        } else if (saveReceipt.result.receipt_status === 2) {
          const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
            application_id: this.currUser.application_id,
            ereceipt_api_id: saveReceipt.result.ereceipt_api_id
          })
          var newBlob = new Blob([res], { type: 'application/pdf' })
          var newFileURL = URL.createObjectURL(newBlob)
          window.open(newFileURL, '_blank')
        } else {
          renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
        }
      } else {
        renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
      }
    },
    checkEmail () {
      if (this.currUser.location_type === '1') {
        this.sendViaEmail = true
        if (this.selectedCustomer.email_add) {
          this.withoutEmailPrompt = false
          this.withEmailPrompt = true
        } else {
          this.withoutEmailPrompt = true
          this.withEmailPrompt = false
          this.sendViaEmail = false
        }
      } else {
        this.holdTrx(this.lookupResponse)
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.holdTrx(this.lookupResponse)
    },
    updateDetails (customerDetails) {
      this.noIDFlag = false

      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    closeOTP () {
      this.otpDialog = false
      this.formData.totalPayment = ''
      this.formData.firstName = ''
      this.formData.lastName = ''
      this.isTrxVerified = false
    },
    scanQRCode () {
      this.showQRDialog = true
      this.isSearching = true
      this.qrData = ''
      window.addEventListener('keydown', this.checkScannedData, false)
    },
    async checkScannedData (e) {
      if (e.key === 'Enter') {
        const pattern = /^\(?([0-9]{3})\)?[-]([0-9]{3})[-]([0-9]{3})$/
        console.log(this.qrData)
        if (pattern.test(this.qrData)) {
          this.formData.code = this.qrData
          this.showQRDialog = false
          renderToast('success', 'Success', 'QR Code successfully scanned.')
        } else {
          this.qrData = ''
          renderToast('error', 'QR Code Invalid', 'The Scanned Code is invalid. Please make sure that the scanned QR code is valid.')
        }
      } else {
        if (e.key !== 'Shift') {
          this.qrData += e.key
        }
      }
    },
    closeQRDialog () {
      this.showQRDialog = false
      this.qrData = ''
      this.formData.code = ''
    }
  }
}
</script>

<style scoped>
#summaryOverview {
  position: sticky !important;
  top: 80px !important;
  background: #f4f4fa !important;
  border-radius: 5px !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:100%;
}
.v-stepper.ph-wu-receive-stepper .v-stepper__header {
  box-shadow: none !important;
}

.ph-wu-receive-summary .v-card {
  padding: 20px;
}

.ph-transfer-summary-header {
  font-size: 18px;
  text-align: center;
}

.ph-transfer-summary-content-title {
  font-size: 14px;
}

.transfer-info-title,
.transfer-info-value {
  border-bottom: thin dashed rgba(0,0,0,.12) !important;
}

.transfer-info-title {
  font-weight: bolder;
  padding-left: 0 !important;
}

.transfer-info-value {
  text-align: right;
  padding-right: 0 !important;
}

.ph-transfer-summary-table {
  background-color: transparent !important;
}

.ph-primary-button {
  background: #FFF;
  color: #1A2791 !important;
  /* border: 2px solid #1A2791; */
  border-radius: 10px;
  font-family: 'Proxima Nova';
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 23px;
  transition: .2s;
  height: 60px !important;
  width: 100%;
  margin: 10px 0;
}

.ph-primary-button:hover {
  background: #1A2791 !important;
  color: #fff !important;
  /* border: 2px solid #1A2791; */
}

.ph-primary-button.ph-primary-execute-btn {
  background: #1A2791;
  color: #fff !important;
  /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
  font-size: 14px;
}

.ph-primary-button.ph-primary-execute-btn:hover {
  background: #fff !important;
  color: #1A2791 !important;
  border: 2px solid #1A2791;
}

.ph-transaction-container {
  padding: 0;
}

.ph-stepper-action-buttons {
  display: flex;
  margin-bottom: 100px;
}

.ph-primary-button.ph-stepper-button {
  height: 40px !important;
  width: 115px;
}

/* @media only screen and (min-width: 600px) {
  .v-stepper.ph-wu-receive-stepper .v-stepper__step {
    padding-left: 0;
  }
} */

.ph-wu-receive-summary-minified {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #F4F4FA;
}

.ph-wu-receive-summary-content {
  display: flex;
  flex-direction: row;
  padding: 15px 30px;
}

.ph-wu-receive-summary-minified-info {
  margin-right: auto;
}

.ph-wu-receive-summary-minified-info p {
  margin-bottom: 0;
}

.ph-wu-receive-summary-minified-action {
  margin-left: auto;
}

.ph-wu-receive-form {
  padding: 12px 0;
}

.ph-search-dialog-title {
  text-align: center;
  display: block;
  font-family: 'Baloo Extra Bold' !important;
  color: #1A2791;
  padding: 20px !important;
}

.ph-search-dialog-text {
  display: flex;
  flex-direction: column;
}

.ph-search-dialog-text img {
  margin: 0 auto;
}

.ph-primary-button.ph-search-dialog-cancel-button {
  margin-right: 0;
}

@media only screen and (min-width: 960px) {
  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 0;
  }
}
</style>
