<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mt-16 mb-4 pt-10 mx-2 mt-sm-4 pt-sm-0 mx-sm-2 mx-md-4" no-gutters>
      <v-col :cols="12" :md="4" class="pt-sm-2">
        <v-text-field
          v-model="searchText"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          outlined
        />
      </v-col>
    </v-row>
    <v-row class="mt-md-12 mb-md-10" no-gutters>
      <v-col :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :search="searchText"
          class="custom-table"
          :calculate-widths="true"
        >
          <template v-slot:[`item.bill_name`]="{ item }">
            <span v-if="item.bill_name">
              {{ item.bill_name }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.module`]="{ item }">
            <span v-if="item.module">
              {{ getModuleName(item.module) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.process`]="{ item }">
            <span v-if="item.process">
              {{ getProcessName(item.process) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.remarks`]="{ item }">
            <span v-if="item.remarks !== null">
              {{ item.remarks }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn @click="openPreviewDialog(item)" color="primary">
              <v-icon class="mr-2">mdi-eye</v-icon>
              View
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="approveDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Approve
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to approve this request?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeApproveDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="approveRequest"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="disapproveDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Disapprove
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to disapprove this request?
          <v-form ref="disapproveForm">
            <v-row class="mt-2 mb-2">
              <v-col :cols="12">
                <v-text-field
                  v-model="formData.remarks"
                  class="ph-textbox"
                  label="Remarks"
                  :rules="requiredRules"
                  outlined
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDisapproveDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="disapproveRequest"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedItem && billerDialog" v-model="billerDialog" width="60%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            {{ getProcessName(selectedItem.process) }} {{ getModuleName(selectedItem.module) }} Request
          </h3>
          <v-spacer />
          <v-btn @click="closePreviewDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <h3>
            Biller
          </h3>
          <v-row class="mt-2 mb-2">
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedItem.payload.biller_code"
                class="ph-textbox"
                label="Biller Code"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedItem.payload.biller_name"
                class="ph-textbox"
                label="Biller Name"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedItem.payload.common_biller_tag"
                class="ph-textbox"
                label="Common Biller Tag"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-select
                v-model="selectedItem.payload.category_id"
                :items="categories"
                item-text="category_name"
                item-value="id"
                class="ph-textbox"
                label="Category"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-select
                v-model="selectedItem.payload.partner_id"
                :items="partners"
                item-text="bill_name"
                item-value="id"
                class="ph-textbox"
                label="Partner"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedItem.payload.spiels"
                class="ph-textbox"
                label="Spiel"
                outlined
                readonly
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6">
          <v-spacer />
          <v-btn
            @click="openApproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Approve
          </v-btn>
          <v-btn
            @click="openDisapproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
            text
          >
            Disapprove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedItem && partnerDialog" v-model="partnerDialog" width="60%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            {{ getProcessName(selectedItem.process) }} {{ getModuleName(selectedItem.module) }} Request
          </h3>
          <v-spacer />
          <v-btn @click="closePreviewDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <h3>
            Partner
          </h3>
          <v-row class="mt-2 mb-4 mb-md-4">
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedItem.payload.bill_code"
                class="ph-textbox"
                label="Partner Code"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedItem.payload.bill_name"
                class="ph-textbox"
                label="Partner Name"
                outlined
                readonly
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6">
          <v-spacer />
          <v-btn
            @click="openApproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Approve
          </v-btn>
          <v-btn
            @click="openDisapproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
            text
          >
            Disapprove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedItem && hierarchyDialog" v-model="hierarchyDialog" width="60%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            {{ getProcessName(selectedItem.process) }} {{ getModuleName(selectedItem.module) }} Request
          </h3>
          <v-spacer />
          <v-btn @click="closePreviewDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <h3>
            Biller
          </h3>
          <v-row class="mt-2 mb-4 mb-md-8">
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedItem.payload.biller_tag"
                class="ph-textbox"
                label="Biller Code"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedItem.payload.biller_name"
                class="ph-textbox"
                label="Biller Name"
                outlined
                readonly
              />
            </v-col>
          </v-row>
          <h3>
            Prioritization
          </h3>
          <v-row class="mt-2 mb-4 mb-md-8">
            <v-col :cols="12" :md="4">
              <v-select
                v-model="getHierarchy(selectedItem.payload.bill_partner_id)[0]"
                :items="partners"
                item-value="id"
                item-text="bill_name"
                class="ph-textbox"
                label="First Priority"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12" :md="4">
              <v-select
                v-model="getHierarchy(selectedItem.payload.bill_partner_id)[1]"
                :items="partners"
                item-value="id"
                item-text="bill_name"
                class="ph-textbox"
                label="Second Priority"
                outlined
                readonly
              />
            </v-col>
            <v-col :cols="12" :md="4">
              <v-select
                v-model="getHierarchy(selectedItem.payload.bill_partner_id)[2]"
                :items="partners"
                item-value="id"
                item-text="bill_name"
                class="ph-textbox"
                label="Third Priority"
                outlined
                readonly
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6">
          <v-spacer />
          <v-btn
            @click="openApproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Approve
          </v-btn>
          <v-btn
            @click="openDisapproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
            text
          >
            Disapprove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedItem && switchBillerDialog" v-model="switchBillerDialog" width="30%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            {{ getProcessName(selectedItem.process) }} {{ getModuleName(selectedItem.module) }} Request
          </h3>
          <v-spacer />
          <v-btn @click="closePreviewDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2 mb-2">
            <v-col :cols="12" :md="6">
              <v-switch
                v-model="selectedItem.payload.status"
                :true-value="1"
                :false-value="0"
                class="d-inline-block"
                color="primary"
                :label="selectedItem.payload.status ? 'Active' : 'Inactive'"
                :ripple="false"
                readonly
                inset
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6">
          <v-spacer />
          <v-btn
            @click="openApproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Approve
          </v-btn>
          <v-btn
            @click="openDisapproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
            text
          >
            Disapprove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedItem && switchPartnerDialog" v-model="switchPartnerDialog" width="30%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            {{ getProcessName(selectedItem.process) }} {{ getModuleName(selectedItem.module) }} Request
          </h3>
          <v-spacer />
          <v-btn @click="closePreviewDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2 mb-2">
            <v-col :cols="12" :md="6">
              <v-switch
                v-model="selectedItem.payload.status"
                :true-value="1"
                :false-value="0"
                class="d-inline-block"
                color="primary"
                :label="selectedItem.payload.status ? 'Active' : 'Inactive'"
                :ripple="false"
                readonly
                inset
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6">
          <v-spacer />
          <v-btn
            @click="openApproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Approve
          </v-btn>
          <v-btn
            @click="openDisapproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
            text
          >
            Disapprove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedItem && billerDeleteDialog" v-model="billerDeleteDialog" width="30%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            {{ getProcessName(selectedItem.process) }} {{ getModuleName(selectedItem.module) }} Request
          </h3>
          <v-spacer />
          <v-btn @click="closePreviewDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2 mb-2">
            <v-col :cols="12">
              <v-text-field
                v-model="selectedItem.payload.biller_name"
                class="ph-textbox"
                label="Biller Name"
                outlined
                readonly
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6">
          <v-spacer />
          <v-btn
            @click="openApproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Approve
          </v-btn>
          <v-btn
            @click="openDisapproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
            text
          >
            Disapprove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedItem && partnerDeleteDialog" v-model="partnerDeleteDialog" width="30%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            {{ getProcessName(selectedItem.process) }} {{ getModuleName(selectedItem.module) }} Request
          </h3>
          <v-spacer />
          <v-btn @click="closePreviewDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2 mb-2">
            <v-col :cols="12" :md="6">
              <v-text-field
                v-model="selectedItem.payload.bill_name"
                class="ph-textbox"
                label="Partner Name"
                outlined
                readonly
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6">
          <v-spacer />
          <v-btn
            @click="openApproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Approve
          </v-btn>
          <v-btn
            @click="openDisapproveDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
            text
          >
            Disapprove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      items: [],
      searchText: '',
      category: null,
      partner: null,
      selectedItem: null,
      billerDialog: false,
      billerDeleteDialog: false,
      partnerDialog: false,
      partnerDeleteDialog: false,
      hierarchyDialog: false,
      switchBillerDialog: false,
      switchPartnerDialog: false,
      approveDialog: false,
      disapproveDialog: false,
      formData: {
        remarks: ''
      },
      breadcrumbsItems: [
        { text: 'Dashboard', disabled: false, href: '/' },
        { text: 'Biller Approval', disabled: true }
      ],
      headers: [
        { text: 'Record Name', sortable: true, value: 'record_name' },
        { text: 'Module', sortable: true, value: 'module' },
        { text: 'Type', sortable: true, value: 'process' },
        { text: 'Remarks', sortable: true, value: 'remarks' },
        { text: '', sortable: false, width: '150px', value: 'action' }
      ],
      requiredRules: [
        v => !!v || 'This field is required'
      ]
    }
  },
  methods: {
    getHierarchy (ids) {
      const idString = String(ids)
      return idString.split(',').map(item => Number(item))
    },
    getModuleName (text) {
      if (text === 'biller') {
        return 'Biller'
      } else if (text === 'partner') {
        return 'Partner Maintenance'
      } else if (text === 'hierarchy') {
        return 'Common Biller'
      } else if (text === 'on-off-biller') {
        return 'Biller Status'
      } else if (text === 'on-off-partner') {
        return 'Partner Status'
      } else {
        return ''
      }
    },
    getProcessName (text) {
      if (text === 'to_add') {
        return 'Create'
      } else if (text === 'to_update') {
        return 'Update'
      } else if (text === 'to_delete') {
        return 'Delete'
      } else {
        return ''
      }
    },
    openPreviewDialog (data) {
      this.selectedItem = data

      if (data.module === 'biller') {
        if (data.process === 'to_delete') {
          this.billerDeleteDialog = true
        } else {
          this.billerDialog = true
        }
      }

      if (data.module === 'partner') {
        if (data.process === 'to_delete') {
          this.partnerDeleteDialog = true
        } else {
          this.partnerDialog = true
        }
      }

      if (data.module === 'hierarchy') {
        if (data.process === 'to_delete') {
          this.billerDeleteDialog = true
        } else {
          this.hierarchyDialog = true
        }
      }

      if (data.module === 'on-off-biller') {
        this.switchBillerDialog = true
      }

      if (data.module === 'on-off-partner') {
        this.switchPartnerDialog = true
      }
    },
    closePreviewDialog () {
      this.selectedItem = null
      this.billerDialog = false
      this.billerDeleteDialog = false
      this.partnerDialog = false
      this.partnerDeleteDialog = false
      this.hierarchyDialog = false
      this.switchBillerDialog = false
      this.switchPartnerDialog = false
    },
    openApproveDialog () {
      if (this.selectedItem) {
        this.approveDialog = true
      }
    },
    closeApproveDialog () {
      this.approveDialog = false
    },
    openDisapproveDialog () {
      if (this.selectedItem) {
        this.disapproveDialog = true
      }
    },
    closeDisapproveDialog () {
      this.disapproveDialog = false
      this.formData = {
        remarks: ''
      }
      this.$refs.disapproveForm.resetValidation()
    },
    async approveRequest () {
      const payload = {
        id: this.selectedItem.id,
        biller_id: this.selectedItem.payload.biller_id,
        approver: this.currUser.user_id
      }

      this.closeApproveDialog()

      const { code, message, result } = await this.$store.dispatch('billers/approveRequest', payload)

      if (code === 200) {
        renderToast('success', 'Successful', 'Request has been approved')
        this.getGrid()
        this.closePreviewDialog()
      } else {
        if (message && result) {
          let type = 'error'

          if (code === 422) type = 'warning'

          renderToast(type, 'Saving Failed', result)
        } else {
          renderToast('error', 'Saving Failed', 'Something went wrong while disapproving this request, please try again')
        }
      }
    },
    async disapproveRequest () {
      if (this.$refs.disapproveForm.validate()) {
        const { code, message, result } = await this.$store.dispatch('billers/disapproveRequest', {
          id: this.selectedItem.id,
          payload: {
            remarks: this.formData.remarks
          }
        })

        this.closeDisapproveDialog()

        if (code === 200) {
          renderToast('success', 'Successful', 'Request has been disapproved')
          this.getGrid()
          this.closePreviewDialog()
        } else {
          if (message && result) {
            let type = 'error'

            if (code === 422) type = 'warning'

            renderToast(type, 'Saving Failed', result)
          } else {
            renderToast('error', 'Saving Failed', 'Something went wrong while disapproving this request, please try again')
          }
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please add a remarks')
      }
    },
    async getGrid () {
      const req = await this.$store.dispatch('billers/getApprovalRequests')

      if (req.code === 200) {
        this.items = req.result.filter(item => item.status === 'pending')
      }
    },
    async getCategory () {
      const req = await this.$store.dispatch('billers/getCategories')

      if (req.code === 200) {
        this.categories = req.result
      }
    },
    async getPartners () {
      const req = await this.$store.dispatch('billers/getPartners')

      if (req.code === 200) {
        this.partners = req.result
      }
    }
  },
  beforeMount () {
    this.getGrid()
    this.getPartners()
    this.getCategory()
  }
}
</script>
