<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col class="flex" :cols="12" :sm="4" :md="4">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search Transaction"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-8" :cols="12">
        <v-data-table
          :headers="headers"
          :items="transactions"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:[`item.customer_name`]="{ item }">
            <span v-if="item.customer_name" class="d-flex align-center">
              <v-avatar class="mr-1" size="26px" :style="avatarColor(item.customer_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.customer_name) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.provider`]="{ item }">
            <span v-if="item.provider">
              {{ item.provider }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.reloc_number`]="{ item }">
            <span v-if="item.reloc_number">
              {{ getRelocNumber(item) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.form_number`]="{ item }">
            <span v-if="item.form_number">
              {{ item.form_number }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.ticket_status`]="{ item }">
            <span v-if="item.ticket_status" :class="[getStatusColor(item.ticket_status)]">
              {{ item.ticket_status }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.trx_date`]="{ item }">
            <span v-if="item.trx_date">
              {{ formatDate(item.trx_date) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.total_amount`]="{ item }">
            <span v-if="item.total_amount">
              {{ toMoney(item.total_amount) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="viewDetails(item)">
                  <v-list-item-title>
                    View Details
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="printOAR(item)" v-if="item.form_number !== '' && currUser.location_type === '1'">
                  <v-list-item-title>
                    Print OAR
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="newTransaction"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="viewDialog"
      v-if="selectedTransaction"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Details
        </v-card-title>
        <br>
        <v-card-text>
          <v-row>
            <v-col class="text-right" :cols="4">
              Transaction Date:
            </v-col>
            <v-col :cols="8">
              <strong>{{ formatDate(selectedTransaction.trx_date) || '—' }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              OAR Number:
            </v-col>
            <v-col :cols="8">
              <strong>{{ selectedTransaction.form_number || '—' }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Customer Name:
            </v-col>
            <v-col :cols="8">
              <strong>{{ toProperCase(selectedTransaction.customer_name) || '—' }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Provider:
            </v-col>
            <v-col :cols="8">
              <strong>{{ selectedTransaction.provider || '—' }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Reloc Number:
            </v-col>
            <v-col :cols="8">
              <strong>{{ getRelocNumber(selectedTransaction) || '—' }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Total Amount:
            </v-col>
            <v-col :cols="8">
              <strong>{{ toMoney(selectedTransaction.total_amount) || '—' }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Ticket Status:
            </v-col>
            <v-col :cols="8">
              <strong :class="[getStatusColor(selectedTransaction.ticket_status)]">
                {{ selectedTransaction.ticket_status || '—' }}
              </strong>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeViewDialog"
            class="ph-action-button"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import {
  avatarInitial,
  // renderToast,
  avatarColor,
  toProperCase,
  toMoney
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      fab: false,
      viewDialog: false,
      selectedTransaction: null,
      search: '',
      transactions: [],
      breadcrumbsItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Travel',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'customer_name',
          width: '130px'
        },
        {
          text: 'Provider',
          sortable: true,
          value: 'provider',
          width: '110px'
        },
        {
          text: 'Reloc Number',
          sortable: true,
          value: 'reloc_number',
          width: '110px'
        },
        {
          text: 'Ticket Status',
          sortable: true,
          value: 'ticket_status',
          width: '110px'
        },
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date',
          width: '110px'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'total_amount',
          width: '110px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '70px'
        }
      ]
    }
  },
  methods: {
    async printOAR (data) {
      console.log(data)
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: data.provider === 'Air Asia' ? '1' : '3',
          or_series_no: '',
          oar_series_no: data.form_number,
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    },
    getRelocNumber (data) {
      if (data.provider === 'Air Asia') {
        const parsedData = JSON.parse(data.reloc_number)
        return parsedData[0].RELOCNumber
      } else {
        return data.reloc_number
      }
    },
    viewDetails (data) {
      this.selectedTransaction = data
      this.viewDialog = true
    },
    closeViewDialog () {
      this.selectedTransaction = null
      this.viewDialog = false
    },
    // printOAR (data) {
    //   let type = ''

    //   if (data.provider === 'Cebu Pacific') type = 'air'
    //   if (data.provider === 'Air Asia') type = 'peradali'

    //   window.open(`/receipt?ref=${data.form_number}&trx_type=${type}&type=OAR`, '_blank')
    // },
    newTransaction () {
      this.$store.dispatch('airlines/resetForm')
      this.$router.push({ path: '/pera-travel/new' })
    },
    async getGrid () {
      const req = await this.$store.dispatch('airlines/getGrid', {
        trxDate: this.currUser.trx_date,
        locationID: this.currUser.location_id
      })

      if (req.code === 200) {
        this.transactions = req.result
      }
    },
    formatDate (date) {
      return format(new Date(date), 'yyyy-MM-dd')
    },
    getStatusColor (text) {
      if (text === 'CONFIRMED') {
        return 'green--text'
      } else if (text === 'REBOOKED') {
        return 'primary--text'
      } else if (text === 'CANCELLED') {
        return 'red--text'
      } else {
        return ''
      }
    },
    avatarInitial,
    avatarColor,
    toProperCase,
    toMoney
  },
  beforeMount () {
    this.getGrid()
  }
}
</script>
