import api from '@/api'

export default {
  grid (trxDate, locationID) {
    return api.get(`/v1/transactions/api/trx/eload?trx_date=${trxDate}&location_id=${locationID}`)
  },
  products () {
    return api.get('/v1/transactions/api/trx/eload-list')
  },
  save (payload) {
    return api.post('/v1/eload/api/sell', payload)
  },
  inquire (payload) {
    return api.post('/v1/eload/api/inquire', payload)
  },
  assignAllOAR (payload) {
    return api.post('v1/maintenance/api/eload/assign-alloar', payload)
  }
}
