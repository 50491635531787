<template>
  <v-row class="pt-8 pb-4 px-2 px-sm-4 px-md-6 pb-sm-12" no-gutters>
    <v-col :cols="12">
      <h3 class="custom-heading pb-4 pt-2 pb-md-6 primary--text font-weight-black">
        Pending KYC from self-service portal
      </h3>
    </v-col>
    <v-col
      class="d-flex px-0 px-sm-1 px-md-2 pb-2 pb-md-3"
      :cols="12"
      :sm="4"
      :md="2"
      v-for="customer in requests"
      :key="customer.customer_id"
    >
      <v-card @click="doUpdate(customer)" class="custom-card px-auto px-sm-2">
        <v-card-title class="px-0 mx-0 py-2">
          <v-spacer />
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="px-0 py-1">
              <v-list-item  @click="doUpdate(customer)">
                <v-icon class="mr-1" small>mdi-pencil</v-icon>
                Update
              </v-list-item>
              <v-list-item class="red--text" @click="confirmDelete(customer.customer_id)">
                <v-icon class="mr-1" color="red" small>mdi-delete</v-icon>
                Archive
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text class="px-1">
          <v-row no-gutters>
            <v-col class="d-flex flex-row justify-center" :cols="12">
              <v-avatar size="70px" :style="avatarColor(customer.full_name)">
                <span class="white--text text-h4">{{ avatarInitial(customer.full_name) }}</span>
              </v-avatar>
            </v-col>
            <v-col class="pt-3 pb-3" :cols="12">
              <h4 class="customer-name">
                {{ toProperCase(customer.first_name) }} {{ toProperCase(customer.last_name) }}
              </h4>
            </v-col>
            <v-col class="py-2 text-center" :cols="12">
              <span class="caption" :title="formatDate(customer.created_at)">
                added {{ getDistanceToNow(customer.created_at) }}
              </span>
            </v-col>
          </v-row>
          <v-dialog
            v-model="deleteDialog"
            width="500"
            persistent
          >
            <v-card>
              <v-card-title class="ph-dialog-title">
                Delete Pending KYC entry
              </v-card-title>
              <br>
              <v-card-text class="ph-dialog-header">
                Are you sure you want to delete this pending KYC entry?
              </v-card-text>
              <br>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="danger"
                  text
                  @click="closeDeleteDialog"
                  class="ph-action-button"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  elevation="0"
                  @click="doDelete"
                  class="ph-action-button"
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col :cols="12" v-if="requests.length === 0">
      <span class="caption">
        No available request
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { format, formatDistanceToNow } from 'date-fns'
import {
  avatarInitial,
  toProperCase,
  avatarColor,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      requests: 'customers/requests',
      currUser: 'auth/currUser',
      formData: 'customers/formData'
    })
  },
  data () {
    return {
      deleteDialog: false,
      newRequestID: null
    }
  },
  methods: {
    doUpdate (data) {
      const customerData = {
        ...this.formData,
        newRequestID: data.customer_id,
        first_name: data.first_name,
        middle_name: data.middle_name,
        last_name: data.last_name,
        gender: data.gender,
        birth_date: data.birth_date,
        birth_place: data.birth_place,
        civil_status: data.civil_status,
        nationality: data.nationality,
        mobile_no: data.mobile_no,
        email_add: data.email_address,
        address: data.address,
        barangay: data.barangay,
        municipality: data.municipality,
        province: data.province,
        country: data.country,
        zip_code: data.zip_code,
        street1: data.permanent_address,
        barangay1: data.permanent_barangay,
        city1: data.permanent_city,
        province1: data.permanent_province,
        country1: data.permanent_country,
        zip_code1: data.permanent_zip_code,
        employment: data.employment,
        source_fund: data.source_of_fund,
        employer_name: data.employer_name,
        nature_work: data.nature_of_work,
        occupation: data.occupation
      }
      this.$store.commit('customers/SET_FORM_DATA', customerData)
      this.$router.push({
        path: '/customers/new',
        query: {
          standalone: true
        }
      })
    },
    async doDelete () {
      const req = await this.$store.dispatch('customers/deleteNewRequest', this.newRequestID)

      if (req.code === 200) {
        this.newRequestID = null
        this.deleteDialog = false
        this.getNewRequests()
        this.renderToast('success', 'Success', 'Pending KYC entry has been deleted')
      } else {
        this.renderToast('error', 'Error', 'Failed to delete pending KYC entry')
      }
    },
    confirmDelete (id) {
      this.newRequestID = id
      this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.newRequestID = null
      this.deleteDialog = false
    },
    formatDate (createdAt) {
      return format(new Date(createdAt), 'MMM d, yyyy \'at\' h:mm aa (EEEE)')
    },
    getDistanceToNow (createdAt) {
      return formatDistanceToNow(new Date(createdAt), { addSuffix: true })
    },
    async getNewRequests () {
      await this.$store.dispatch('customers/getNewRequests', this.currUser.location_code)
    },
    renderToast,
    avatarInitial,
    toProperCase,
    avatarColor
  },
  beforeMount () {
    this.getNewRequests()
  }
}
</script>
