<template>
  <v-form ref="form">
    <v-row class="px-8 py-8" no-gutters>
      <v-col class="pb-4 pb-sm-6" :cols="12">
        <span class="text-caption">
          Please complete the necessary fields*.
        </span>
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.email_add"
          class="ph-textbox"
          color="gray darken-1"
          label="Email Address"
          autofocus
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.tel_no"
          class="ph-textbox"
          color="gray darken-1"
          label="Landline"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :md="4">
        <v-text-field
          v-model="formData.mobile_no"
          class="ph-textbox"
          color="gray darken-1"
          label="Mobile Number *"
          :rules="mobileNumberRules"
          :disabled="isNotAllowed"
          :counter="11"
          outlined
          @change="checkNumberIfExists"
        />
        <br>
        <br>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  isValidEmail,
  isValidLandline,
  isValidMobileNumber
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      formData: 'customers/formData',
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer',
      oldMobileNumber: 'customers/oldMobileNumber'
    }),
    isCurrentMobileNumber () {
      console.log(this.currentMobileNo, this.formData.mobile_no)
      return (this.currentMobileNo === this.formData.mobile_no)
    }
  },
  watch: {
    oldMobileNumber: {
      handler (val) {
        this.currentMobileNo = val
      },
      deep: true
    },
    selectedCustomer: {
      handler (val) {
        console.log(val.ub_card_no)
        this.handleMobileNumberField()
      },
      deep: true
    }
  },
  data () {
    return {
      isNotAllowed: false,
      isNumberExist: false,
      isUpdating: false,
      currentMobileNo: '',
      emailRules: [
        v => (v !== '' && isValidEmail(v)) || 'Invalid email address'
      ],
      landlineRules: [
        v => (v !== '' && isValidLandline(v)) || 'Invalid landline number'
      ],
      mobileNumberRules: [
        v => !!v || 'This field is required',
        v => isValidMobileNumber(v) || 'Invalid landline number'
      ]
    }
  },
  async mounted () {
    if (Object.prototype.hasOwnProperty.call(this.$route.params, 'crn')) {
      this.isUpdating = true
    } else {
      this.isUpdating = false
    }

    this.handleMobileNumberField()

    if (this.isUpdating) {
      if (await this.selectedCustomer?.mobile_no) {
        this.currentMobileNo = this.selectedCustomer.mobile_no
        this.$store.commit('customers/SET_OLD_MOBILE_NUMBER', this.selectedCustomer.mobile_no)
      } else {
        this.currentMobileNo = this.oldMobileNumber
      }
    }
  },
  methods: {
    async checkNumberIfExists () {
      console.log(this.isCurrentMobileNumber, this.isUpdating)
      if (!(this.isCurrentMobileNumber && this.isUpdating === true)) {
        this.$emit('showDuplicateModal', false, [])
        await this.$store.dispatch('customers/checkExistingCust', this.formData.mobile_no).then(res => {
          if (res.result.length > 0) {
            this.$emit('showDuplicateModal', true, res.result)
          }
        })
      }
    },
    handleMobileNumberField () {
      if (this.selectedCustomer.ub_card_no || this.formData?.ub_card_no) {
        if (this.currUser.user_group_id === 72 || this.currUser.user_group_id === 14 || this.currUser.user_group_id === 77) {
          this.isNotAllowed = false
        } else {
          this.isNotAllowed = true
        }
      } else {
        this.isNotAllowed = false
      }
    }
  }
}
</script>
