import api from '@/api'
import newkycgatewayv2 from '@/api/newkycgatewayv2'

export default {
  feeInquiry (query) {
    return api.get(`/v1/maintenance/api/nonex-sf-all?${query.join('&')}`)
  },
  kycLookup (payload) {
    return api.post('/v1/remit/wu/wu/trx/prereq/kyc-lookup', payload)
  },
  sendValidate (payload) {
    return api.post('/v1/remit/wu/wu/trx/send-money/validate', payload)
  },
  sendStore (payload) {
    return api.post('/v1/remit/wu/wu/trx/send-money/store', payload)
  },
  feeInquiryV2 (payload) {
    return newkycgatewayv2.post('/v1/remit/wu/ob/wuso-feeinquiry', payload)
  },
  sendValidateV2 (payload) {
    return newkycgatewayv2.post('/v1/remit/wu/ob/wuso-validate', payload)
  },
  sendStoreV2 (payload) {
    return newkycgatewayv2.post('/v1/remit/wu/ob/wuso-store', payload)
  }
}
