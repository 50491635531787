<template>
  <v-dialog
    v-model="crossSellingData.renderSpielModal"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="ph-dialog-title">
        Transaction Complete!
      </v-card-title>
      <v-card-text>
        <br>
        <b>Congratulations, you have completed a transaction.</b>
        <p>You can offer these services to your customer!</p>
        <p><b>Offer One:</b></p>
        <p>{{crossSellingData.offer_one ? crossSellingData.offer_one : '-'}}</p>
        <p><b>Offer Two:</b></p>
        <p>{{crossSellingData.offer_two ? crossSellingData.offer_two : '-'}}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="resetState"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Snackbar',
  data () {
    return {
      crossSellingData: {
        renderSpielModal: false,
        offer_one: '',
        offer_two: '',
        product_line: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      crossSellingSpiel: 'app/getAVPSpielState'
    })
  },
  watch: {
    crossSellingSpiel: function (data) {
      this.crossSellingData = data
    }
  },
  methods: {
    resetState () {
      this.crossSellingData = {
        renderSpielModal: false,
        offer_one: '',
        offer_two: '',
        product_line: ''
      }

      this.$store.commit('app/SET_AVP_SPIEL_STATE', this.crossSellingData)
    }
  }
}
</script>
<style scoped>

</style>
