<template>
  <v-row>
    <v-col v-for="userBalData in userBalanceDatas" :key="userBalData.currency_id" cols="12" sm="6" md="6" lg="6">
      <v-card
        elevation="0"
        :class="'mx-auto ph-dashboard-card ' + (userBalData.currency_code === 'PHP' ? 'php' : 'usd')"
        max-width="100%">
          <v-avatar
            size="58"
            class="ph-nonex-button-icon">
            <div class="ph-nonex-button-icon-container">
              <v-icon x-large class="ph-dashboard-currency php" v-if="userBalData.currency_code === 'PHP'">mdi-currency-php</v-icon>
              <v-icon x-large class="ph-dashboard-currency usd" v-if="userBalData.currency_code === 'USD'">mdi-currency-usd</v-icon>
            </div>
          </v-avatar>
          <div class="ph-dashboard-card-amount">
            <v-card-title class="ph-dashboard-header">{{ userBalData.currency_code }} Balance</v-card-title>
            <v-card-text>
                <p>
                  <span class="ph-dashboard-amount">{{ formatAmount(userBalData.ending_balance) }}</span>
                </p>
            </v-card-text>
          </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
export default {
  name: 'UserBalanceDataComponent',
  computed: {
    ...mapGetters({
      userBalanceDatas: 'report/userBalanceDatas',
      currUser: 'auth/currUser'
    })
  },
  methods: {
    formatAmount (amount) {
      return numeral(amount).format('0,00.00')
    }
  }
}
</script>
<style scoped>
.ph-dashboard-amount {
  font-family: 'Proxima Nova Bold';
  font-size: 50px;
  font-weight: bolder;
  color: #FFF;
  margin: 10px 0;
  position: relative;
}

.ph-dashboard-header {
  font-family: 'Baloo Extra Bold';
  font-weight: bolder;
  color: #FFF;
}

.ph-dashboard-currency {
  color: #FFF;
}

.ph-dashboard-currency.php {
  color: #8DC26F;
}

.ph-dashboard-currency.usd {
  color: #1A2791;
}

.v-card.ph-dashboard-card {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background: linear-gradient(273.97deg, #6633CC -36.6%, #1A2791 87.36%);
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
  /* border: 1px solid #D0D0D0; */
}

.ph-dashboard-card-amount {
  margin-left: auto;
  text-align: right;
}

.ph-dashboard-card.php {
  background: linear-gradient(to right, #8DC26F, #76b852); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.ph-dashboard-card.usd {
  background: linear-gradient(273.97deg, #6633CC -36.6%, #1A2791 87.36%);
}

.ph-nonex-button-icon-container {
  padding: 15px;
  width: 100%;
  display: flex;
}

.ph-nonex-button-icon {
  margin-left: 20px;
  margin-top: 35px;
  background: #FFF;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

@media only screen and (min-width: 500px) {
  .v-card.ph-nonex-button {
    display: flex;
    cursor: pointer;
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
    border-radius: 12px;
    height: 250px;
  }
}
</style>
