const TOGGLE_LOADING = (state, loading) => {
  state.loading = loading
}

const TOGGLE_OPAQUE_LOADING = (state, opaqueLoading) => {
  state.opaqueLoading = opaqueLoading
}

const TOGGLE_DRAWER = (state, drawer) => {
  state.drawer = drawer
}

const TOGGLE_MINI_SIDEBAR = (state, mini) => {
  state.mini = mini
}

const SET_SNACKBAR_STATE = (state, snackbar) => {
  state.snackbar = snackbar
}

const SET_AVP_SPIEL_STATE = (state, crossSellingSpiel) => {
  state.crossSellingSpiel = crossSellingSpiel
}

export default {
  TOGGLE_LOADING,
  TOGGLE_OPAQUE_LOADING,
  TOGGLE_MINI_SIDEBAR,
  TOGGLE_DRAWER,
  SET_SNACKBAR_STATE,
  SET_AVP_SPIEL_STATE
}
