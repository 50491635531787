<template>
  <v-container v-if="selectedMonitoringItem" fluid>
    <v-breadcrumbs class="mt-sm-4 mx-sm-2 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card class="custom-card mt-6 mx-4">
      <v-card-text>
        <v-row class="px-4 py-3">
          <v-col class="d-flex align-center" :cols="12" :md="6">
            <v-avatar class="mr-2" size="80px" :style="avatarColor(`${selectedMonitoringItem.first_name} ${selectedMonitoringItem.last_name}`)">
              <span class="white--text text-h3">{{ avatarInitial(`${selectedMonitoringItem.first_name} ${selectedMonitoringItem.last_name}`) }}</span>
            </v-avatar>
            <h1 class="ml-3">{{ `${selectedMonitoringItem.first_name} ${selectedMonitoringItem.last_name}` }}</h1>
          </v-col>
          <v-col class="d-flex align-center" :cols="12" :md="6">
            <v-spacer />
            <v-btn
              @click="doShowApproveDialog"
              class="font-weight-bold mr-sm-2 mr-md-4 rounded-lg"
              color="primary"
              width="150px"
              x-large
            >
              Approve
            </v-btn>
            <v-btn
              @click="doShowDisapproveDialog"
              class="font-weight-bold mr-sm-2 mr-md-4 rounded-lg"
              color="primary"
              width="150px"
              outlined
              x-large
            >
              Decline
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="mt-2 mt-sm-4 mt-md-6 mb-8" no-gutters>
      <v-col class="px-2 px-md-4" :cols="12">
        <v-card class="custom-card px-0">
          <v-tabs v-model="currentTab" class="px-6" height="60px">
            <v-tabs-slider />
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              class="font-weight-bold"
              style="text-transform: initial !important;"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>
          <div class="px-6 py-8" style="min-height: 200px;font-size: 15px !important;color: #6E6E6E !important">
            <component
              :is="tabs[currentTab].component"
              :customerInfo="customerInfo"
              :customerTransactions="customerTransactions"
              :customerRiskLog="customerRiskLog"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showApproveDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Approve Request
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header text-center">
          Are you sure you want to approve this request?
          <br><br>
          <v-text-field
            v-model="remarks"
            class="ph-textbox"
            label="Remarks"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeApproveDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="approve"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDisapproveDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Disapprove Request
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header text-center">
          Are you sure you want to disapprove this request?
          <br><br>
          <v-text-field
            v-model="remarks"
            class="ph-textbox"
            label="Remarks"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDisapproveDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="disapprove"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CustomerInformation from '@/views/Compliance/Monitoring/CustomerInformation.vue'
import Transaction from '@/views/Compliance/Monitoring/Transaction.vue'
import Identifications from '@/views/Compliance/Monitoring/Identifications.vue'
import Documents from '@/views/Compliance/Monitoring/Documents.vue'
import RiskProfileHistory from '@/views/Compliance/Monitoring/RiskProfileHistory.vue'
import { mapGetters } from 'vuex'
import {
  avatarInitial,
  renderToast,
  avatarColor
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      riskData: 'compliance/riskData',
      selectedMonitoringItem: 'compliance/selectedMonitoringItem'
    })
  },
  data () {
    return {
      customerInfo: {},
      customerTransactions: [],
      customerRiskLog: [],
      currentTab: 0,
      remarks: '',
      showApproveDialog: false,
      showDisapproveDialog: false,
      tabs: [
        {
          name: 'Customer Information',
          component: CustomerInformation
        },
        {
          name: 'Transaction',
          component: Transaction
        },
        {
          name: 'IDs and Other Images',
          component: Identifications
        },
        {
          name: 'EDD and Other Documents',
          component: Documents
        },
        {
          name: 'Risk Profile History',
          component: RiskProfileHistory
        }
      ],
      breadcrumbsItems: [
        {
          text: 'Compliance Module',
          disabled: false,
          exact: true,
          to: '/compliance'
        },
        {
          text: 'Compliance Monitoring',
          disabled: false,
          exact: true,
          to: '/compliance/monitoring'
        },
        {
          text: '',
          disabled: true
        }
      ]
    }
  },
  methods: {
    doShowApproveDialog () {
      this.showApproveDialog = true
    },
    doShowDisapproveDialog () {
      this.showDisapproveDialog = true
    },
    closeApproveDialog () {
      this.remarks = ''
      this.showApproveDialog = false
    },
    closeDisapproveDialog () {
      this.remarks = ''
      this.showDisapproveDialog = false
    },
    async approve () {
      const payload = {
        customer_id: this.selectedMonitoringItem.customer_id,
        monitoring_type: String(this.selectedMonitoringItem.monitoring_type),
        edd_status: '3',
        compliance_remarks: this.remarks,
        update_status_by: this.currUser.user_id
      }

      const { code } = await this.$store.dispatch('compliance/updateEddStatus', payload)

      if (code === 200) {
        this.closeApproveDialog()
        renderToast('success', 'Successful', 'This request has been approved')
        this.$router.push('/compliance/monitoring')
      } else {
        renderToast('error', 'Failed', 'An error occurred while trying to approve this request')
      }
    },
    async disapprove () {
      const payload = {
        customer_id: this.selectedMonitoringItem.customer_id,
        monitoring_type: String(this.selectedMonitoringItem.monitoring_type),
        edd_status: '4',
        compliance_remarks: this.remarks,
        update_status_by: this.currUser.user_id
      }

      const { code } = await this.$store.dispatch('compliance/updateEddStatus', payload)

      if (code === 200) {
        this.closeDisapproveDialog()
        renderToast('success', 'Successful', 'This request has been disapproved')
        this.$router.push('/compliance/monitoring')
      } else {
        renderToast('error', 'Failed', 'An error occurred while trying to disapprove this request')
      }
    },
    avatarInitial,
    avatarColor
  },
  async mounted () {
    if (this.selectedMonitoringItem) {
      this.customerInfo = await this.$store.dispatch('compliance/getCustomerInfo', this.selectedMonitoringItem.customer_id)
      this.customerTransactions = await this.$store.dispatch('compliance/getCustomerTransactions', {
        customer_id: this.selectedMonitoringItem.customer_id,
        currrow: 1,
        groupCount: 0
      })
      this.customerRiskLog = await this.$store.dispatch('compliance/getCustomerRiskLog', this.selectedMonitoringItem.customer_id)
      this.breadcrumbsItems[2].text = `${this.selectedMonitoringItem.first_name} ${this.selectedMonitoringItem.last_name}`
    } else {
      this.$router.push('/compliance/monitoring')
    }
  }
}
</script>
