import api from '@/api'

export default {
  getReceipt (payload) {
    return api.post('/v1/maintenance/api/ereceipt/seriesno', payload)
  },
  saveReceipt (payload) {
    return api.post('/v1/maintenance/api/ereceipt/detailed', payload)
  },
  formNumber (locationID, type) {
    return api.get(`/v1/maintenance/api/receipt/get-available?location_id=${locationID}&type=${type}`)
  },
  cancelFormNumber (payload) {
    return api.post('/v1/maintenance/api/receipt/cancel-form', payload)
  },
  print (locationID, trxDate, refNo, trxType, type) {
    return api.get(`/v1/maintenance/api/receipt/print?location_id=${locationID}&trx_date=${trxDate}&ref_no=${refNo}&trx_type=${trxType}&type=${type}`)
  }
}
