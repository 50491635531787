<template>
  <div class="px-2 px-md-2 mt-md-4 mb-16 pb-8 pb-md-0 mb-md-0">
    <v-btn @click="backToSearch" class="text-capitalize" text small>
      <v-icon class="mr-1" small>
        mdi-arrow-left
      </v-icon>
      Back to search
    </v-btn>
    <v-row no-gutters>
      <v-col class="pr-md-8" :cols="12" :md="8">
        <v-stepper class="elevation-0 mt-md-4" v-model="currentStep">
          <v-stepper-header class="elevation-0">
            <v-stepper-step
              class="pl-4"
              :complete="currentStep > 1"
              step="1"
            >
              Select Insurance
            </v-stepper-step>

            <v-divider class="mx-md-1" />

            <v-stepper-step
              :complete="currentStep > 2"
              step="2"
            >
              Insured/Beneficiary
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
        <SelectInsurance v-if="currentStep === 1" />
        <InsuranceDetails v-if="currentStep === 2" />
      </v-col>
      <TransactionSummary />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TransactionSummary from '@/components/insurance/TransactionSummary.vue'
import SelectInsurance from '@/components/insurance/SelectInsurance.vue'
import InsuranceDetails from '@/components/insurance/InsuranceDetails.vue'

export default {
  computed: {
    ...mapGetters({
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser'
    }),
    currentStep: {
      get () {
        return this.$store.state.insurance.currentStep
      },
      set (val) {
        this.$store.commit('insurance/SET_CURRENT_STEP', val)
      }
    }
  },
  components: {
    TransactionSummary,
    SelectInsurance,
    InsuranceDetails
  },
  methods: {
    backToSearch () {
      this.$store.dispatch('insurance/resetForm')
      this.$store.commit('insurance/SET_CURRENT_PAGE', 0)
    }
  },
  async beforeMount () {
    await this.$store.dispatch('getOccupation')
    await this.$store.dispatch('getRelationship')
    await this.$store.dispatch('getRelationshipDppv2')
  },
  mounted () {
    const newTransactionData = [
      {
        prop: 'insuredCustomerID',
        value: this.selectedCustomer.customer_id
      },
      {
        prop: 'insuredFirstName',
        value: this.selectedCustomer.first_name
      },
      {
        prop: 'insuredMiddleName',
        value: this.selectedCustomer.middle_name
      },
      {
        prop: 'insuredNoMiddleNameTag',
        value: (Number(this.selectedCustomer.tag_no_middlename) === 1)
      },
      {
        prop: 'insuredLastName',
        value: this.selectedCustomer.last_name
      },
      {
        prop: 'insuredBirthDate',
        value: this.selectedCustomer.birth_date
      },
      {
        prop: 'insuredMobileNumber',
        value: this.selectedCustomer.mobile_no
      },
      {
        prop: 'insuredEmailAddress',
        value: this.selectedCustomer.email_add
      },
      {
        prop: 'insuredOccupation',
        value: this.selectedCustomer.occupation
      },
      {
        prop: 'address',
        value: this.selectedCustomer.address
      },
      {
        prop: 'province',
        value: this.selectedCustomer.province
      },
      {
        prop: 'birthPlace',
        value: this.selectedCustomer.birth_place
      }
    ]

    newTransactionData.map(data => this.$store.commit('insurance/SET_TRANSACTION_DATA', data))
  }
}
</script>
