<template>
  <div class="px-md-4 mt-3">
    <strong v-if="transactionData.insurance.category === 'COC7'">
      Customer Information
    </strong>
    <strong v-else>
      Insured Information
    </strong>
    <v-form ref="insured" v-model="transactionData.isFormValid">
      <v-row class="mt-6 mb-md-5" no-gutters>
        <v-col class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredFirstName"
            class="ph-textbox"
            label="First Name"
            readonly
            outlined
          />
        </v-col>
        <v-col class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredMiddleName"
            class="ph-textbox"
            label="Middle Name"
            readonly
            outlined
          />
          <v-spacer />
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="transactionData.insuredNoMiddleNameTag"
              class="my-0 py-0 d-inline-block"
              :ripple="false"
              label="No Middle Name"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
              readonly
            />
          </div>
        </v-col>
        <v-col class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredLastName"
            class="ph-textbox"
            label="Last Name"
            readonly
            outlined
          />
        </v-col>
        <v-col class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredBirthDate"
            :rules="computedBirthDateRules"
            class="ph-textbox"
            label="Birth Date"
            readonly
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC4' && transactionData.insurance.category !== 'COC7'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.insuredOccupation"
            :items="collections.occupation"
            item-text="occupation"
            item-value="occupation_value"
            :rules="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10' ? requiredRules : []"
            :return-object="false"
            class="ph-textbox"
            :label="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10' ? 'Occupation *' : 'Occupation'"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC4' || transactionData.insurance.category === 'COC7' || transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredMobileNumber"
            class="ph-textbox"
            :label="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10' ? 'Mobile Number *' : 'Mobile Number'"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7' || transactionData.insurance.category === 'COC'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insuredEmailAddress"
            class="ph-textbox"
            label="Email Address"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-autocomplete
            v-model="transactionData.gender"
            :items="ruralNetGender"
            class="ph-textbox"
            label="Gender *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.address"
            class="ph-textbox"
            label="Address *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-autocomplete
            v-model="transactionData.province"
            :items="transactionData.provincesData"
            @change="populateCity"
            class="ph-textbox"
            label="Province *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-autocomplete
            v-model="transactionData.city"
            :disabled="!transactionData.province"
            :items="transactionData.citiesData"
            class="ph-textbox"
            label="City *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-autocomplete
            v-model="transactionData.civilStatus"
            :items="ruralNetCivilStatus"
            class="ph-textbox"
            label="Civil Status *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.insCardNo"
            :rules="insuranceCardNoRules"
            class="ph-textbox"
            label="Insurance Card"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.referrer"
            class="ph-textbox"
            label="Referrer *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col
          v-if="transactionData.insurance.category === 'COC' || transactionData.insurance.category === 'COC6'"
          class="pr-md-2 pb-3 pb-md-8"
          :cols="12"
          :md="6"
        >
          <v-text-field
            v-model="transactionData.insuredPensionCode"
            class="ph-textbox"
            label="Pension Association Code"
            outlined
          />
        </v-col>
      </v-row>
    </v-form>
    <strong v-if="transactionData.insurance.category === 'COC7'">
      Vehicle Information
    </strong>
    <strong v-else>
      Beneficiary Information
    </strong>
    <v-form ref="insured">
      <v-row class="mt-6 mb-md-5" no-gutters>
        <v-col v-if="transactionData.insurance.category !== 'COC7'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiaryFirstName"
            @input="convertToProperCase('beneficiaryFirstName')"
            class="ph-textbox"
            label="First Name"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiaryMiddleName"
            @input="convertToProperCase('beneficiaryMiddleName')"
            :disabled="transactionData.beneficiaryNoMiddleNameTag"
            class="ph-textbox"
            label="Middle Name"
            outlined
          />
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="transactionData.beneficiaryNoMiddleNameTag"
              @change="toggleNoMiddleName"
              class="my-0 py-0 d-inline-block"
              :ripple="false"
              label="No Middle Name"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
            />
          </div>
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7'" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiaryLastName"
            @input="convertToProperCase('beneficiaryLastName')"
            class="ph-textbox"
            label="Last Name"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && transactionData.insurance.category !== 'COC9' && transactionData.insurance.category !== 'COC10'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.beneficiaryRelationship"
            :items="computedRelationships"
            item-text="relationship"
            item-value="relationship"
            :return-object="false"
            class="ph-textbox"
            label="Relationship to Insured"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.beneficiaryRelationship"
            :items="computedRelationships"
            :return-object="false"
            class="ph-textbox"
            label="Relationship to Insured"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC9' || transactionData.insurance.category === 'COC10' && this.transactionData.beneficiary2 === false" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-btn
            @click="addBeneficiary2"
            class="text-bold mr-sm-2 mr-md-4 rounded-lg"
            color="primary"
            width="150px"
          >
            Add Beneficiary
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="this.transactionData.beneficiary2 === true">
          <strong>
            2nd Beneficiary Information
          </strong>
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary2 === true" class="pr-md-2 pb-3 pb-md-8 pt-7" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiary2FirstName"
            @input="convertToProperCase('beneficiary2FirstName')"
            class="ph-textbox"
            label="First Name"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary2 === true" class="pl-md-2 pb-3 pb-md-8 pt-7" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiary2MiddleName"
            @input="convertToProperCase('beneficiary2MiddleName')"
            :disabled="transactionData.beneficiary2NoMiddleNameTag"
            class="ph-textbox"
            label="Middle Name"
            outlined
          />
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="transactionData.beneficiary2NoMiddleNameTag"
              @change="toggleNoMiddleName2"
              class="my-0 py-0 d-inline-block"
              :ripple="false"
              label="No Middle Name"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
            />
          </div>
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary2 === true" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiary2LastName"
            @input="convertToProperCase('beneficiary2LastName')"
            class="ph-textbox"
            label="Last Name"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary2 === true" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.beneficiary2Relationship"
            :items="computedRelationships"
            :return-object="false"
            class="ph-textbox"
            label="Relationship to Insured"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary2 === true && this.transactionData.beneficiary3 === false" class="pl-md-2 pb-3 pb-md-8 d-flex flex-row" :cols="12" :md="6">
          <v-btn
            @click="addBeneficiary3"
            class="text-bold mr-sm-2 mr-md-4 rounded-lg"
            color="primary"
            width="150px"
          >
            Add Beneficiary
          </v-btn>
          <v-btn
            @click="removeBeneficiary2"
            class="rounded-lg"
            color="primary"
            outlined
          >
            Remove Beneficiary
          </v-btn>
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleFileNumber"
            @input="toUpperCase('vehicleFileNumber')"
            class="ph-textbox"
            label="MV File Number *"
            :rules="mvFileNumberRules"
            type="number"
            hide-spin-buttons
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleChassisNumber"
            @input="toUpperCase('vehicleChassisNumber')"
            class="ph-textbox"
            label="Chassis Number *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleEngineNumber"
            @input="toUpperCase('vehicleEngineNumber')"
            class="ph-textbox"
            label="Engine Number *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleYear"
            class="ph-textbox"
            label="Year *"
            :rules="requiredRules"
            type="number"
            hide-spin-buttons
            outlined
          />
        </v-col>
        <v-col cols="12" v-if="this.transactionData.beneficiary3 === true">
          <strong>
            3rd Beneficiary Information
          </strong>
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary3 === true" class="pr-md-2 pb-3 pb-md-8 pt-7" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiary3FirstName"
            @input="convertToProperCase('beneficiary3FirstName')"
            class="ph-textbox"
            label="First Name"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary3 === true" class="pl-md-2 pb-3 pb-md-8 pt-7" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiary3MiddleName"
            @input="convertToProperCase('beneficiary3MiddleName')"
            :disabled="transactionData.beneficiary3NoMiddleNameTag"
            class="ph-textbox"
            label="Middle Name"
            outlined
          />
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="transactionData.beneficiary3NoMiddleNameTag"
              @change="toggleNoMiddleName3"
              class="my-0 py-0 d-inline-block"
              :ripple="false"
              label="No Middle Name"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
            />
          </div>
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary3 === true" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiary3LastName"
            @input="convertToProperCase('beneficiary3LastName')"
            class="ph-textbox"
            label="Last Name"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary3 === true" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.beneficiary3Relationship"
            :items="computedRelationships"
            :return-object="false"
            class="ph-textbox"
            label="Relationship to Insured"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary3 === true && this.transactionData.beneficiary4 === false" class="pl-md-2 pb-3 pb-md-8 d-flex flex-row" :cols="12" :md="6">
          <v-btn
            @click="addBeneficiary4"
            class="text-bold mr-sm-2 mr-md-4 rounded-lg"
            color="primary"
            width="150px"
          >
            Add Beneficiary
          </v-btn>
          <v-btn
            @click="removeBeneficiary3"
            class="rounded-lg"
            color="primary"
            outlined
          >
            Remove Beneficiary
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="this.transactionData.beneficiary4 === true">
          <strong>
            4th Beneficiary Information
          </strong>
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary4 === true" class="pr-md-2 pb-3 pb-md-8 pt-7" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiary4FirstName"
            @input="convertToProperCase('beneficiary4FirstName')"
            class="ph-textbox"
            label="First Name"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary4 === true" class="pl-md-2 pb-3 pb-md-8 pt-7" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiary4MiddleName"
            @input="convertToProperCase('beneficiary4MiddleName')"
            :disabled="transactionData.beneficiary4NoMiddleNameTag"
            class="ph-textbox"
            label="Middle Name"
            outlined
          />
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="transactionData.beneficiary4NoMiddleNameTag"
              @change="toggleNoMiddleName4"
              class="my-0 py-0 d-inline-block"
              :ripple="false"
              label="No Middle Name"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
            />
          </div>
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary4 === true" class="pr-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.beneficiary4LastName"
            @input="convertToProperCase('beneficiary4LastName')"
            class="ph-textbox"
            label="Last Name"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary4 === true" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-select
            v-model="transactionData.beneficiary4Relationship"
            :items="computedRelationships"
            :return-object="false"
            class="ph-textbox"
            label="Relationship to Insured"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category !== 'COC7' && this.transactionData.beneficiary4 === true" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-btn
            @click="removeBeneficiary4"
            class="rounded-lg"
            color="primary"
            outlined
          >
            Remove Beneficiary
          </v-btn>
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleMake"
            @input="convertToProperCase('vehicleMake')"
            class="ph-textbox"
            label="Make and Model/Series *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleColor"
            @input="convertToProperCase('vehicleColor')"
            class="ph-textbox"
            label="Color *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleBody"
            @input="convertToProperCase('vehicleBody')"
            class="ph-textbox"
            label="Type of Body *"
            :rules="requiredRules"
            outlined
          />
        </v-col>
        <v-col v-if="transactionData.insurance.category === 'COC7'" class="pl-md-2 pb-3 pb-md-8" :cols="12" :md="6">
          <v-text-field
            v-model="transactionData.vehicleCompanyName"
            @input="convertToProperCase('vehicleCompanyName')"
            class="ph-textbox"
            label="Company Name *"
            :rules="companyNameRules"
            :disabled="!transactionData.vehicleIsCorporate"
            outlined
          />
          <div class="d-flex justify-end">
            <v-checkbox
              v-model="transactionData.vehicleIsCorporate"
              @change="onIsCoporateChange"
              class="my-0 py-0 d-inline-block"
              :ripple="false"
              label="Registered under Company"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
    <div class="mt-6 d-flex justify-space-between">
      <v-btn
        @click="back"
        class="rounded-lg font-weight-bold"
        color="primary"
        outlined
        large
      >
        Back
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toProperCase, trim } from '@/utils'
import { parseISO, differenceInYears } from 'date-fns'

export default {
  computed: {
    ...mapGetters({
      collections: 'collections',
      transactionData: 'insurance/transactionData',
      provinceData: 'insurance/provinceData',
      ruralNetGender: 'insurance/ruralNetGender',
      ruralNetCivilStatus: 'insurance/ruralNetCivilStatus'
    }),
    computedRelationships () {
      return this.transactionData.insurance.category === 'COC8' ? this.collections.relationshipdppv2 : this.transactionData.insurance.category === 'COC9' || this.transactionData.insurance.category === 'COC10' ? this.transactionData.relationshipRuralNet : this.collections.relationship
    },
    computedBirthDateRules () {
      return [
        v => {
          if (this.transactionData.insurance.category === 'COC9' || this.transactionData.insurance.category === 'COC10') {
            const birthDate = parseISO(this.transactionData.insuredBirthDate)
            const age = differenceInYears(new Date(), birthDate)
            if (age < 18) return 'Insured must be at least 18 years old.'
            if (age > 65) return 'Insured must be 65 years old or younger.'
          } else {
            return true
          }
        }
      ]
    }
  },
  data () {
    return {
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      mvFileNumberRules: [
        v => !!v || 'This field is required',
        v => (v.length === 15) || 'Must be 15 characters'
      ],
      companyNameRules: [
        v => ((this.transactionData.vehicleIsCorporate && !!v) || (!this.transactionData.vehicleIsCorporate && !v)) || 'This field is required'
      ],
      insuranceCardNoRules: [
        v => (v.length < 15) || 'Insurance Card Number should only be 15 characters max'
      ]
    }
  },
  methods: {
    toUpperCase (prop) {
      this.transactionData[prop] = this.transactionData[prop].toUpperCase()
    },
    convertToProperCase (field) {
      this.transactionData[field] = toProperCase(trim(this.transactionData[field]))
    },
    onIsCoporateChange () {
      this.transactionData.vehicleCompanyName = ''
      this.$refs.insured.resetValidation()
    },
    back () {
      this.$store.commit('insurance/SET_CURRENT_STEP', 1)
    },
    toggleNoMiddleName (val) {
      if (val) this.transactionData.beneficiaryMiddleName = ''
    },
    toggleNoMiddleName2 (val) {
      if (val) this.transactionData.beneficiary2MiddleName = ''
    },
    toggleNoMiddleName3 (val) {
      if (val) this.transactionData.beneficiary3MiddleName = ''
    },
    toggleNoMiddleName4 (val) {
      if (val) this.transactionData.beneficiary4MiddleName = ''
    },
    addBeneficiary2 () {
      this.transactionData.beneficiary2 = true
      this.transactionData.beneficiary2NoMiddleNameTag = false
    },
    addBeneficiary3 () {
      this.transactionData.beneficiary3 = true
      this.transactionData.beneficiary3NoMiddleNameTag = false
    },
    addBeneficiary4 () {
      this.transactionData.beneficiary4 = true
      this.transactionData.beneficiary4NoMiddleNameTag = false
    },
    removeBeneficiary2 () {
      this.transactionData.beneficiary2 = false
      this.transactionData.beneficiary2MiddleName = ''
      this.transactionData.beneficiary2FirstName = ''
      this.transactionData.beneficiary2LastName = ''
      this.transactionData.beneficiary2Relationship = ''
    },
    removeBeneficiary3 () {
      this.transactionData.beneficiary3 = false
      this.transactionData.beneficiary3MiddleName = ''
      this.transactionData.beneficiary3FirstName = ''
      this.transactionData.beneficiary3LastName = ''
      this.transactionData.beneficiary3Relationship = ''
    },
    removeBeneficiary4 () {
      this.transactionData.beneficiary4 = false
      this.transactionData.beneficiary4MiddleName = ''
      this.transactionData.beneficiary4FirstName = ''
      this.transactionData.beneficiary4LastName = ''
      this.transactionData.beneficiary4Relationship = ''
    },
    populateCity () {
      const locations = this.transactionData.locationRuralNetData.result
      const province = locations.find(loc => loc.provinceCode === this.transactionData.province)
      this.transactionData.citiesData = []
      province.cities.forEach(city => {
        this.transactionData.citiesData.push({
          text: city.cityState,
          value: city.cityCode
        })
      })
      console.log(this.transactionData.citiesData)
    }
  }
}
</script>
