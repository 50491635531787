export default {
  phID: state => state.phID,
  hasAgent: state => state.hasAgent,
  currentPage: state => state.currentPage,
  currentStep: state => state.currentStep,
  receiverList: state => state.receiverList,
  feeInquiryData: state => state.feeInquiryData,
  kycLookupData: state => state.kycLookupData,
  validateData: state => state.validateData,
  storeData: state => state.storeData,
  remoteData: state => state.remoteData,
  remoteDetails: state => state.remoteDetails,
  remcoList: state => state.remcoList,
  transactionData: state => state.transactionData,
  payoutCountryList: state => state.payoutCountryList,
  deliveryServiceTypes: state => state.deliveryServiceTypes,
  fieldsList: state => state.fieldsList,
  combosList: state => state.combosList
}
