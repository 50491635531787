<template>
  <div>
  </div>
</template>
<script>
import WacomSDK from '../../assets/js/wacom/wgssStuSdk.js'
import Q from '../../assets/js/wacom/q.js'
import { mapGetters } from 'vuex'
import WacomEncryption from '../../assets/js/wacom/demoButtons_encryption'
import { renderToast } from '@/utils'

export default {
  name: 'wacom',
  props: ['type', 'trxData', 'referenceSignatures'],
  data () {
    return {
      m_btns: '',
      m_clickBtn: -1,
      intf: '',
      formDiv: '',
      protocol: '',
      m_usbDevices: '',
      tablet: '',
      m_capability: '',
      m_inkThreshold: '',
      m_imgData: '',
      m_encodingMode: '',
      ctx: '',
      canvas: '',
      modalBackground: '',
      container: '',
      m_penData: '',
      lastPoint: '',
      isDown: '',
      retry: 0,
      signatureImage: '',
      signatureImageDiv1: '',
      signatureImageDiv2: '',
      signatureImageDiv3: '',
      signatureImageDiv4: '',
      signatureImage1: '',
      signatureImage2: '',
      signatureImage3: '',
      signatureImage4: '',
      finalSignature: '',
      canvasEmpty: true
    }
  },
  created: function () {
    this.addPrototype()
  },
  watch: {
    showWacomDataConsentFirst: {
      handler (getResult) {
        console.log(getResult)
        if (getResult === true) {
          this.tabletDemo()
        }
      }
    },
    deep: true
  },
  mounted: function () {
    setTimeout(this.checkForSigCaptX, 1000)
    if (this.type === 'kyc' || this.type === 'transactional') {
      this.checkForWacom()
    }
  },
  computed: {
    ...mapGetters({
      formData: 'customers/formData',
      currUser: 'auth/currUser',
      showWacomDataConsentFirst: 'wacom/showWacomDataConsentFirst'
    })
  },
  methods: {
    renderToast,
    checkForSigCaptX () {
      // Establishing a connection to SigCaptX Web Service can take a few seconds,
      // particularly if the browser itself is still loading/initialising
      // or on a slower machine.
      this.retry = this.retry + 1
      if (WacomSDK.STU.isServiceReady()) {
        this.retry = 0
        console.log('SigCaptX Web Service: ready')
      } else {
        console.log('SigCaptX Web Service: not connected')
        if (this.retry < 20) {
          setTimeout(this.checkForSigCaptX, 1000)
        } else {
          console.log('Unable to establish connection to SigCaptX')
        }
      }
    },

    onDCAtimeout () {
      // Device Control App has timed-out and shut down
      // For this sample, we just closedown tabletDemo (assumking it's running)
      console.log('DCA disconnected')
      setTimeout(this.close, 0)
    },

    Rectangle (x, y, width, height) {
      this.x = x
      this.y = y
      this.width = width
      this.height = height
      this.Contains = function (pt) {
        if (((pt.x >= this.x) && (pt.x <= (this.x + this.width))) &&
            ((pt.y >= this.y) && (pt.y <= (this.y + this.height)))) {
          return true
        } else {
          return false
        }
      }
    },

    Button () {
      this.Bounds // eslint-disable-line
      this.Text // eslint-disable-line
      this.Click // eslint-disable-line
    },

    Point (x, y) {
      this.x = x
      this.y = y
    },

    noScroll () {
      window.scrollTo(0, 0)
    },

    createModalWindow (width, height) {
      window.addEventListener('scroll', this.noScroll)
      window.scrollTo(0, 0)
      this.modalBackground = document.createElement('div')
      this.modalBackground.id = 'modal-background'
      this.modalBackground.className = 'active'
      this.modalBackground.style.width = window.innerWidth
      this.modalBackground.style.height = window.innerHeight
      document.getElementsByTagName('body')[0].appendChild(this.modalBackground)

      this.container = document.createElement('div')
      this.container.id = 'signatureWindowContainer'
      this.container.classList.add('vertical-center')
      this.container.style.display = 'flex'
      document.getElementsByTagName('body')[0].appendChild(this.container)

      this.formDiv = document.createElement('div')
      this.formDiv.id = 'signatureWindow'
      this.formDiv.classList.add('active', 'signatureWindow')
      //   formDiv.style.top = (window.innerHeight / 2) - (height / 2) + "px";
      //   formDiv.style.left = (window.innerWidth) - (width) + "px";
      this.formDiv.style.width = width + 'px'
      this.formDiv.style.height = height + 'px'
      this.container.appendChild(this.formDiv)

      this.canvas = document.createElement('canvas')
      this.canvas.id = 'myCanvas'
      this.canvas.height = this.formDiv.offsetHeight
      this.canvas.width = this.formDiv.offsetWidth
      this.formDiv.appendChild(this.canvas)
      this.ctx = this.canvas.getContext('2d')

      if (this.canvas.addEventListener) {
        this.canvas.addEventListener('click', this.onCanvasClick, false)
      } else if (this.canvas.attachEvent) {
        this.canvas.attachEvent('onClick', this.onCanvasClick)
      } else {
        this.canvas.onClick = this.onCanvasClick
      }
    },

    disconnect () {
      var deferred = Q.defer()
      const self = this
      if (!(self.tablet === undefined || self.tablet === null)) {
        var p = new WacomSDK.STU.Protocol()
        self.tablet.setInkingMode(p.InkingMode.InkingMode_Off)
          .then(function (message) {
            console.log('received: ' + JSON.stringify(message))
            return self.tablet.endCapture()
          })
          .then(function (message) {
            console.log('received: ' + JSON.stringify(message))
            if (self.m_imgData !== null) {
              return self.m_imgData.remove()
            } else {
              return message
            }
          })
          .then(function (message) {
            console.log('received: ' + JSON.stringify(message))
            self.m_imgData = null
            return self.tablet.setClearScreen()
          })
          .then(function (message) {
            console.log('received: ' + JSON.stringify(message))
            return self.tablet.disconnect()
          })
          .then(function (message) {
            console.log('received: ' + JSON.stringify(message))
            self.tablet = null
            // clear canvas
            self.clearCanvas(self.canvas, self.ctx)
          })
          .then(function (message) {
            console.log(message)
            deferred.resolve()
          })
          .fail(function (message) {
            console.log('disconnect error: ' + message)
            deferred.resolve()
          })
      } else {
        deferred.resolve()
      }
      return deferred.promise
    },

    // Error-derived object for Device Control App not ready exception
    DCANotReady () { },

    checkForWacom () {
      const self = this
      WacomSDK.STU.isDCAReady()
        .then(function (message) {
          console.log(message)
          if (!message) {
            throw new self.DCANotReady()
          }
          // Set handler for Device Control App timeout
          WacomSDK.STU.onDCAtimeout = self.onDCAtimeout
          return WacomSDK.STU.getUsbDevices()
        })
        .then(function (message) {
          if (self.type === 'transactional') {
            if (message === null || message.length === 0) {
              self.$emit('has-wacom', false)
            } else {
              self.$emit('has-wacom', true)
            }
          } else {
            if (message === null || message.length === 0) {
              self.formData.hasWacom = false
            } else {
              self.formData.hasWacom = true
            }
          }
        })
    },

    tabletDemo () {
      this.$store.commit('wacom/SET_WACOM_IDLE_DIALOG', false)
      const self = this
      var p = new WacomSDK.STU.Protocol()
      var intf
      var usingEncryption = false
      var encH
      var encH2
      var encH2Impl

      WacomSDK.STU.isDCAReady()
        .then(function (message) {
          console.log(message)
          if (!message) {
            throw new self.DCANotReady()
          }
          // Set handler for Device Control App timeout
          WacomSDK.STU.onDCAtimeout = self.onDCAtimeout
          return WacomSDK.STU.getUsbDevices()
        })
        .then(function (message) {
          if (message === null || message.length === 0) {
            // $('#btn_e_signature').attr('disabled', true);
            throw new Error('No STU devices found')
          }
          console.log('received: ' + JSON.stringify(message))
          self.m_usbDevices = message
          return WacomSDK.STU.isSupportedUsbDevice(self.m_usbDevices[0].idVendor, self.m_usbDevices[0].idProduct)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          intf = new WacomSDK.STU.UsbInterface()
          return intf.Constructor()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return intf.connect(self.m_usbDevices[0], true)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          console.log(message.value === 0 ? 'connected!' : 'not connected')
          if (message.value === 0) {
            encH = new WacomSDK.STU.EncryptionHandler(new WacomEncryption.EncryptionHandler())
            return encH.Constructor()
          }
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          encH2Impl = new WacomEncryption.EncryptionHandler2()
          encH2 = new WacomSDK.STU.EncryptionHandler2(encH2Impl)
          return encH2.Constructor()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          self.tablet = new WacomSDK.STU.Tablet()
          return self.tablet.Constructor(intf, encH, encH2)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          intf = null
          return self.tablet.getInkThreshold()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          self.m_inkThreshold = message
          // console.log(self.tablet)
          return self.tablet.getCapability()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          self.m_capability = message
          self.createModalWindow(self.m_capability.screenWidth, self.m_capability.screenHeight)
          return self.tablet.getInformation()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.getInkThreshold()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.getProductId()
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return WacomSDK.STU.ProtocolHelper.simulateEncodingFlag(message, self.m_capability.encodingFlag)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          var encodingFlag = message
          if ((encodingFlag & p.EncodingFlag.EncodingFlag_24bit) !== 0) {
            return self.tablet.supportsWrite()
              .then(function (message) {
                self.m_encodingMode = message ? p.EncodingMode.EncodingMode_24bit_Bulk : p.EncodingMode.EncodingMode_24bit
              })
          } else if ((encodingFlag & p.EncodingFlag.EncodingFlag_16bit) !== 0) {
            return this.tablet.supportsWrite()
              .then(function (message) {
                self.m_encodingMode = message ? p.EncodingMode.EncodingMode_16bit_Bulk : p.EncodingMode.EncodingMode_16bit
              })
          } else { // assumes 1bit is available
            self.m_encodingMode = p.EncodingMode.EncodingMode_1bit
          }
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.isSupported(p.ReportId.ReportId_EncryptionStatus) // v2 encryption
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          usingEncryption = message
          // if the encryption script is missing turn off encryption regardless
          if (typeof window.sjcl === 'undefined') {
            console.log('sjcl not found - encryption disabled')
            usingEncryption = true
          }
          return self.tablet.getDHprime()
        })
        .then(function (dhPrime) {
          console.log('received: ' + JSON.stringify(dhPrime))
          return WacomSDK.STU.ProtocolHelper.supportsEncryption_DHprime(dhPrime) // v1 encryption
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          usingEncryption = (message ? true : usingEncryption)
          return self.tablet.setClearScreen()
        })
        .then(function (message) {
          if (usingEncryption) {
            // return tablet.startCapture(0xc0ffee);
          } else {
            return message
          }
        })
        .then(function (message) {
          if (typeof encH2Impl.error !== 'undefined') {
            throw new Error('Encryption failed, restarting demo')
          }
          return message
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.isSupported(p.ReportId.ReportId_PenDataOptionMode)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          if (message) {
            return self.tablet.getProductId()
              .then(function (message) {
                // console.log(message);
                var penDataOptionMode = p.PenDataOptionMode.PenDataOptionMode_None
                switch (message) {
                  case WacomSDK.STU.ProductId.ProductId_520A:
                    penDataOptionMode = p.PenDataOptionMode.PenDataOptionMode_TimeCount
                    break
                  case WacomSDK.STU.ProductId.ProductId_430:
                  case WacomSDK.STU.ProductId.ProductId_530:
                    penDataOptionMode = p.PenDataOptionMode.PenDataOptionMode_TimeCountSequence
                    break
                  default:
                    console.log('Unknown tablet supporting PenDataOptionMode, setting to None.')
                }
                return self.tablet.setPenDataOptionMode(penDataOptionMode)
              })
          } else {
            this.m_encodingMode = p.EncodingMode.EncodingMode_1bit
            return this.m_encodingMode
          }
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          self.addButtons()
          var canvasImage = self.canvas.toDataURL('image/png', 1.0)
          // console.log(self.m_encodingMode, self.m_capability)
          return WacomSDK.STU.ProtocolHelper.resizeAndFlatten(canvasImage, 0, 0, 0, 0, self.m_capability.screenWidth, self.m_capability.screenHeight, self.m_encodingMode, 1, false, 0, true)
        })
        .then(function (message) {
          self.m_imgData = message
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.writeImage(self.m_encodingMode, message)
        })
        .then(function (message) {
          // console.log(message)
          if (encH2Impl.error) {
            throw new Error('Encryption failed, restarting demo')
          }
          return message
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          return self.tablet.setInkingMode(p.InkingMode.InkingMode_On)
        })
        .then(function (message) {
          console.log('received: ' + JSON.stringify(message))
          var reportHandler = new WacomSDK.STU.ProtocolHelper.ReportHandler()
          self.lastPoint = { 'x': 0, 'y': 0 } // eslint-disable-line
          self.isDown = false
          self.ctx.lineWidth = 1

          var penData = function (report) {
            // console.log("report: " + JSON.stringify(report.x), 'report');
            // console.log(self.m_btns[0].Bounds.x)
            // var m_btn_x = self.m_btns[0].Bounds.x;
            // var m_btn_y = self.m_btns[0].Bounds.y;
            // console.log(report.x)

            var nextPoint = {}
            nextPoint.x = Math.round(self.canvas.width * report.x / self.m_capability.tabletMaxX)
            nextPoint.y = Math.round(self.canvas.height * report.y / self.m_capability.tabletMaxY)

            var shouldPush = true

            for (var i = 0; i < self.m_btns.length; ++i) {
              if (self.m_btns[i].Bounds.Contains(nextPoint)) {
                shouldPush = false
                break
              }
            }
            if (shouldPush) {
              self.m_penData.push(report)
            }

            self.processButtons(report, self.canvas)
            // console.log('pressure',report);
            self.processPoint(report, self.canvas, self.ctx)
          }
          var penDataEncryptedOption = function (report) {
            console.log('reportOp: ' + JSON.stringify(report))
            self.m_penData.push(report.penData[0], report.penData[1])
            self.processButtons(report.penData[0], self.canvas)
            self.processPoint(report.penData[0], self.canvas, self.ctx)
            self.processButtons(report.penData[1], self.canvas)
            self.processPoint(report.penData[1], self.canvas, self.ctx)
          }

          var log = function () {
            // console.log("report: " + JSON.stringify(report));
          }

          var decrypted = function () {
            // console.log("decrypted: " + JSON.stringify(report));
          }
          self.m_penData = []
          reportHandler.onReportPenData = penData
          reportHandler.onReportPenDataOption = penData
          reportHandler.onReportPenDataTimeCountSequence = penData
          reportHandler.onReportPenDataEncrypted = penDataEncryptedOption
          reportHandler.onReportPenDataEncryptedOption = penDataEncryptedOption
          reportHandler.onReportPenDataTimeCountSequenceEncrypted = penData
          reportHandler.onReportDevicePublicKey = log
          reportHandler.onReportEncryptionStatus = log
          reportHandler.decrypt = decrypted
          return reportHandler.startReporting(self.tablet, true)
        })
        .fail(function (ex) {
          console.log(ex)
          // console.log('not hello');
          if (ex instanceof this.DCANotReady) {
            // console.log('hello');
            // Device Control App not detected
            // Reinitialize and re-try
            WacomSDK.STU.Reinitialize()
            setTimeout(this.tabletDemo, 1000)
          } else {
            // Some other error - Inform the user and closedown
            alert('tabletDemo failed:\n' + ex)
            setTimeout(this.close, 0)
          }
        })
    },

    addButtons () {
      this.m_btns = []

      if (this.m_usbDevices[0].idProduct !== WacomSDK.STU.ProductId.ProductId_300) {
        // Place the buttons across the bottom of the screen.
        var w2 = this.m_capability.screenWidth / 2
        var w1 = this.m_capability.screenWidth - w2
        var y = this.m_capability.screenHeight * 6 / 7
        var h = this.m_capability.screenHeight - y

        this.m_btns[0] = {
          Bounds: {
            x: 0,
            y: y,
            width: w1,
            height: h,
            Contains: function (pt) {
              if (((pt.x >= this.x) && (pt.x <= (this.x + this.width))) &&
                  ((pt.y >= this.y) && (pt.y <= (this.y + this.height)))) {
                return true
              } else {
                return false
              }
            }
          },
          Text: 'I AGREE',
          Click: this.btnAgree_Click
        }
        this.m_btns[1] = {
          Bounds: {
            x: w1,
            y: y,
            width: w2,
            height: h,
            Contains: function (pt) {
              if (((pt.x >= this.x) && (pt.x <= (this.x + this.width))) &&
                  ((pt.y >= this.y) && (pt.y <= (this.y + this.height)))) {
                return true
              } else {
                return false
              }
            }
          },
          Text: 'I DO NOT AGREE',
          Click: this.btnDisagree_Click
        }
      }
      this.clearCanvas(this.canvas, this.ctx)
      this.drawButtons()
    },

    drawButtons () {
      // This application uses the same bitmap for both the screen and client (window).
      const para = 'I hereby grant my written consent to PETNET, INC. (“PETNET”) to collect process and/or store my Personal Information and Sensitive Personal Information (“Personal Data”), whether such information is existing or accumulating, and to transfer and share my Personal Data with each of its directors, officers, employees, consultants, agents and representatives for the limited purpose of (a) establishing, confirming and updating my identity and customer record; (b) assessing and determining my eligibility and qualification to access PETNET’s products and services; (c) performing internal credit, risk management, audit, compliance and administrative operations; and (d) any other legitimate business interest or purpose that PETNET may deem necessary.'
      this.ctx.save()
      this.ctx.setTransform(1, 0, 0, 1, 0, 0)
      this.ctx.beginPath()
      this.ctx.textAlign = 'center'
      var left = 10
      var center = this.canvas.width / 2
      var width = this.canvas.width - left * 2
      var y = 30
      this.ctx.lineWidth = 1
      this.ctx.strokeStyle = 'black'
      this.ctx.fillStyle = 'black'
      this.ctx.font = 'bold 30px Proxima Nova'
      this.ctx.fillText('CONSENT ON DATA SHARING (1/7)', center, y)
      y += 30
      this.ctx.font = '25px Proxima Nova'
      this.ctx.textAlign = 'left'
      var setting = {
        maxSpaceSize: 6,
        minSpaceSize: 0.5,
        lineSpacing: 1.2,
        compact: true
      }
      this.ctx.fillParaText(para, left, y, width, setting)
      // this.ctx.fillText('I hereby grant my written consent to PETNET, INC. (“PETNET”) to collect process and/or', 2, 70)
      // this.ctx.fillText('store my Personal Information and Sensitive Personal Information (“Personal Data”),', 2, 91)
      // this.ctx.fillText('whether such information is existing or accumulating, and to transfer and share my', 2, 112)
      // this.ctx.fillText('Personal Data with each of its directors, officers, employees, consultants, agents and', 2, 133)
      // this.ctx.fillText('representatives for the limited purpose of', 2, 154)
      // this.ctx.fillText('(a) establishing, confirming and updating my identity and customer record;', 2, 175)
      // this.ctx.fillText('(b) assessing and determining my eligibility and qualification to access PETNET’s', 2, 196)
      // this.ctx.fillText('products and services;', 2, 217)
      // this.ctx.fillText('(c) performing internal credit, risk management, audit, compliance and administrative', 2, 238)
      // this.ctx.fillText('operations; and', 2, 259)
      // this.ctx.fillText('(d) any other legitimate business interest or purpose that PETNET may deem necessary.', 2, 280)

      // Draw the buttons
      for (var i = 0; i < this.m_btns.length; ++i) {
        this.ctx.fillStyle = '#1A2791'
        this.ctx.font = '30px Proxima Nova'
        this.ctx.fillRect(this.m_btns[i].Bounds.x, this.m_btns[i].Bounds.y, this.m_btns[i].Bounds.width, this.m_btns[i].Bounds.height)
        this.ctx.fillStyle = 'white'
        this.ctx.rect(this.m_btns[i].Bounds.x, this.m_btns[i].Bounds.y, this.m_btns[i].Bounds.width, this.m_btns[i].Bounds.height)
        var xPos = this.m_btns[i].Bounds.x + ((this.m_btns[i].Bounds.width / 2) - (this.ctx.measureText(this.m_btns[i].Text).width / 2))
        var yOffset
        if (this.m_usbDevices[0].idProduct === WacomSDK.STU.ProductId.ProductId_300) {
          yOffset = 28
        } else if (this.m_usbDevices[0].idProduct === WacomSDK.STU.ProductId.ProductId_430) {
          yOffset = 26
        } else {
          yOffset = 40
          this.ctx.fillText(this.m_btns[i].Text, xPos, this.m_btns[i].Bounds.y + yOffset)
        }
        this.ctx.stroke()
        this.ctx.closePath()
        this.ctx.restore()
      }
    },

    clearScreen () {
      console.log('cleared screen')
      this.clearCanvas(this.canvas, this.ctx)
      this.drawButtons()
      this.m_penData = []
      this.tablet.writeImage(this.m_encodingMode, this.m_imgData)
    },

    btnAgree_Click () {
      // You probably want to add additional processing here.
      const payload = {
        value: true,
        consent: 'first_clause'
      }
      window.removeEventListener('scroll', this.noScroll)
      this.$store.commit('wacom/SET_CLAUSE_VALUE', payload)
      this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', false)
      this.close()
      this.$store.commit('app/TOGGLE_LOADING', true)
      setTimeout(() => {
        this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_SECOND_DIALOG', true)
        this.$store.commit('app/TOGGLE_LOADING', false)
      }, 3000)
    },

    btnDisagree_Click () {
      // You probably want to add additional processing here.
      window.removeEventListener('scroll', this.noScroll)
      this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', false)
      this.close()
      this.$store.commit('app/TOGGLE_LOADING', true)
      setTimeout(() => {
        this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_SECOND_DIALOG', true)
        this.$store.commit('app/TOGGLE_LOADING', false)
      }, 3000)
    },

    distance (a, b) {
      return Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)
    },

    clearCanvas (inCanvas, inCtx) {
      inCtx.save()
      inCtx.setTransform(1, 0, 0, 1, 0, 0)
      inCtx.fillStyle = 'white'
      inCtx.fillRect(0, 0, inCanvas.width, inCanvas.height)
      inCtx.restore()
    },

    processButtons (point, inCanvas) {
      // console.log('processButtons',in_canvas)
      // console.log('processButtons', point)
      // console.log(this.m_capability.tabletMaxX, 'tabletmaxx')
      var nextPoint = {}
      nextPoint.x = Math.round(inCanvas.width * point.x / this.m_capability.tabletMaxX)
      nextPoint.y = Math.round(inCanvas.height * point.y / this.m_capability.tabletMaxY)
      var isDown2 = (this.isDown ? !(point.pressure <= this.m_inkThreshold.offPressureMark) : (point.pressure > this.m_inkThreshold.onPressureMark))
      var btn = -1

      for (var i = 0; i < this.m_btns.length; ++i) {
        if (this.m_btns[i].Bounds.Contains(nextPoint)) {
          btn = i
          break
        }
      }
      // console.log(this.isDown, 'isDown');
      if (this.isDown && !isDown2) {
        if (btn !== -1 && this.m_clickBtn === btn) {
          this.m_btns[btn].Click()
        }
        this.m_clickBtn = -1
      } else if (btn !== -1 && !this.isDown && isDown2) {
        this.m_clickBtn = btn
      }
      return (btn === -1)
    },

    processPoint (point, inCanvas, inCtx) {
      // console.log('processPoint-point', point)
      // console.log('processPoint', inCanvas)
      // console.log('processPoint', inCtx)
      var nextPoint = {}
      nextPoint.x = Math.round(inCanvas.width * point.x / this.m_capability.tabletMaxX)
      nextPoint.y = Math.round(inCanvas.height * point.y / this.m_capability.tabletMaxY)
      // console.log(nextPoint.x, 'nextPoint.x');
      // console.log(nextPoint.y, 'nextPoint.y');
      var isDown2 = (this.isDown ? !(point.pressure <= this.m_inkThreshold.offPressureMark) : (point.pressure > this.m_inkThreshold.onPressureMark))
      // console.log(point.pressure);
      if (!this.isDown && isDown2) {
        this.lastPoint = nextPoint
      }

      if ((isDown2 && 10 < this.distance(this.lastPoint, nextPoint)) || (this.isDown && !isDown2)) { // eslint-disable-line
        // this.canvasEmpty = false;
        inCtx.beginPath()
        inCtx.imageSmoothingQuality = 'high'
        inCtx.moveTo(this.lastPoint.x, this.lastPoint.y)
        inCtx.lineTo(nextPoint.x, nextPoint.y)
        inCtx.stroke()
        inCtx.closePath()
        this.lastPoint = nextPoint
      }
      // console.log(isDown2, 'isdown2')
      this.isDown = isDown2
    },

    addPrototype () {
      this.DCANotReady.prototype = new Error()
    },

    close () {
      // Clear handler for Device Control App timeout
      WacomSDK.STU.onDCAtimeout = null

      this.disconnect()
      document.getElementsByTagName('body')[0].removeChild(this.modalBackground)
      document.getElementById('signatureWindowContainer').remove()
      // this.$store.commit('wacom/SET_WACOM_IDLE_DIALOG', true)
      //   document.getElementById('signatureWindowContainer').removeChild(formDiv);
      //   document.getElementById('signatureWindowContainer').removeChild(signatureImage);
    },

    onCanvasClick (event) {
      // Enable the mouse to click on the simulated buttons that we have displayed.

      // Note that this can add some tricky logic into processing pen data
      // if the pen was down at the time of this click, especially if the pen was logically
      // also 'pressing' a button! This demo however ignores any that.

      var posX = event.pageX - this.formDiv.offsetLeft
      var posY = event.pageY - this.formDiv.offsetTop

      for (var i = 0; i < this.m_btns.length; i++) {
        if (this.m_btns[i].Bounds.Contains({ x: posX, y: posY })) {
          this.m_btns[i].Click()
          break
        }
      }
    }
  }
}
</script>

<style>
.button-wacom {
  position:absolute;
}

#signatureBox {
  display: none;
}
#modal-background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: .50;
    -webkit-opacity: .5;
    -moz-opacity: .5;
    filter: alpha(opacity=50);
    z-index: 1000;
}

#signatureWindow {
    box-shadow: 0 0 20px 0 #222;
    -webkit-box-shadow: 0 0 20px 0 #222;
    -moz-box-shadow: 0 0 20px 0 #222;
    display: none;
    z-index: 1000;
}

#modal-background.active, #signatureWindow.active {
    display: block;
}

.colorDiv {
  background-color: white;
}

#myCanvas {
  width:100%;
  height:100%;
}
/*
#modal-background.active, #myCanvas.active {
    display: block;
}*/
/*
#myCanvas canvas {
  width:100%;
  height:100%;
}*/

#signatureDiv {
  float:left;
  margin-right:15px;
}

#signatureImage {
  width:300px;
  height:200px;
  border:1px solid black;
  display:block;
}

#wgssSTU {
  width:0;
  height:0;
}

.thirdPartyLicenses {
  display:none;
  font-size:8pt;
}.button {
  position:absolute;
}

#signatureBox {
  display: none;
}
#modal-background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: .50;
    -webkit-opacity: .5;
    -moz-opacity: .5;
    filter: alpha(opacity=50);
}

.signatureWindow {
    box-shadow: 0 0 20px 0 #222;
    -webkit-box-shadow: 0 0 20px 0 #222;
    -moz-box-shadow: 0 0 20px 0 #222;
    display: none;
    z-index: 1500000 !important;
}

.vertical-center{
  margin: auto;
  padding-top: 40px;
  position: fixed;
  justify-content: center;
  top: 0; left: 0; bottom: 0; right: 0;
  z-index: 50000000000 !important;
}

#modal-background.active, #signatureWindow.active {
    display: block;
}

#myCanvas {
  width:100%;
  height:100%;
}
/*
#modal-background.active, #myCanvas.active {
    display: block;
}*/
/*
#myCanvas canvas {
  width:100%;
  height:100%;
}*/

#signatureDiv {
  float:left;
  margin-right:15px;
}

#signatureImage {
  width:300px;
  height:200px;
  border:1px solid black;
  display:block;
}

#wgssSTU {
  width:0;
  height:0;
}

.thirdPartyLicenses {
  display:none;
  font-size:8pt;
}
</style>
