<template>
  <div class="ph-container">
    <v-container fluid class="ph-pay-status-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
    </v-container>
    <v-row class="ph-transaction-container">
      <v-col cols="12">
        <v-row cols="12" class="ph-pay-status-select-container">
          <v-col cols="12" md="2" class="ph-pay-status-select-title">
            <b>Select Payout Partner:</b>
          </v-col>
          <v-col cols="12" md="10" class="ph-pay-status-selector">
            <v-radio-group
              v-model="payoutType"
              mandatory
              row
            >
              <v-radio
                label="Western Union"
                value="wu"
              ></v-radio>
              <v-radio
                label="Non-Exclusive Partners"
                value="nonex"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row cols="12" class="ph-pay-status-select-container">
          <v-col cols="10">
            <v-text-field
              v-model="mtcn"
              class="ph-textbox"
              single-line
              outlined
              :placeholder="payoutType === 'wu' ? 'MTCN' : 'Control Number'"
              persistent-hint
              @paste="formatMTCN"
            >
            </v-text-field>
            <v-checkbox
              v-if="payoutType !== 'wu'"
              v-model="isGCashTransaction"
            >
              <template v-slot:label>
                Check this if the transaction is&nbsp;<b>GCash Payout</b>
              </template>
            </v-checkbox>
            <v-text-field
              v-if="payoutType !== 'wu'"
              v-model="amount"
              class="ph-textbox"
              single-line
              outlined
              placeholder="Insert Amount"
              persistent-hint
              type="number"
              :hide-spin-buttons="true"
              :disabled="!isGCashTransaction"
              hint="<p class='control-number-instruction' style='font-size: 12px;'><b class='ph-textbox-hint-bold'>Please make sure that the control number and amount values provided are correct before inquiring the transaction.</b></p><i>Frequent searching of invalid transaction details may cause your branch to get suspended on GCash Payout API</i>"
            >
              <template v-slot:message="{ message }">
                <span class="ph-textbox-hint" v-html="message"></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              elevation="0"
              class="ph-primary-button ph-primary-execute-btn"
              @click="getPayoutStatus"
            >
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="showResult">
        <br>
        <br>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  <b>Sender</b>
                </th>
                <th class="text-left">
                  <b>Receiver</b>
                </th>
                <th class="text-left">
                  <b>Send Amount</b>
                </th>
                <th class="text-left">
                  <b>Partner</b>
                </th>
                <th class="text-left">
                  <b>Pay Status</b>
                </th>
                <th class="text-left" v-if="payoutType === 'nonex'">
                  <b>Actions</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in transactions"
                :key="item.sender"
              >
                <td>
                  <div class="ph-pay-status-sender-container">
                    <v-avatar v-if="item.sender !== '-'" class="mr-1 ph-pay-status-status-circle" size="25px" :style="avatarColor(item.sender)">
                      <span class="white--text text-body-2">{{ avatarInitial(item.sender) }}</span>
                    </v-avatar>
                    <p class="ph-pay-status-table-names-text">{{item.sender === '-' ? 'Sender Name not provided' : item.sender}}</p>
                  </div>
                </td>
                <td>
                  <div class="ph-pay-status-receiver-container">
                    <v-avatar class="mr-1 ph-pay-status-status-circle" size="25px" :style="avatarColor(item.receiver)">
                      <span class="white--text text-body-2">{{ avatarInitial(item.receiver) }}</span>
                    </v-avatar>
                    <p class="ph-pay-status-table-names-text">{{item.receiver}}</p>
                  </div>
                </td>
                <td>{{ item.sendout_amount }}</td>
                <td>{{ item.partner }}</td>
                <td>{{ item.pay_status }}</td>
                <td v-if="payoutType === 'nonex'">
                  <v-btn v-if="item.pay_status !== 'Paid'" color="primary" elevation="0" @click="selectTransaction">
                    Select
                  </v-btn>
                  {{ item.pay_status === 'Paid' ? 'No further action needed' : '' }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { convertToRealAmount, getForeignRefNo, avatarColor, avatarInitial, renderToast, getNonexRefNumber } from '@/utils'

export default {
  name: 'PayStatus',
  data () {
    return {
      payoutType: '',
      mtcn: '',
      amount: '',
      showResult: false,
      availableTransactions: {},
      paidTransactions: {},
      isGCashTransaction: false,
      nonexRefNumber: '',
      transactions: [
        { sender: '', receiver: '', sendout_amount: '', partner: '', pay_status: '' }
      ],
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Pay Status',
          disabled: false,
          href: '#'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      remcos: 'nonex/remcos'
    })
  },
  watch: {
    payoutType: {
      handler () {
        this.showResult = false
        this.mtcn = ''
        this.transactions = [
          { sender: '', receiver: '', sendout_amount: '', partner: '', pay_status: '' }
        ]
      },
      deep: true
    }
  },
  mounted () {
    this.nonexRefNumber = this.getNonexRefNumber()
  },
  methods: {
    getNonexRefNumber,
    getForeignRefNo,
    convertToRealAmount,
    avatarColor,
    avatarInitial,
    renderToast,
    formatMTCN (event) {
      event.preventDefault()
      console.log(event.clipboardData.getData('text/plain'))

      let currentValue = ''
      let formattedValue = ''
      this.mtcn = ''

      currentValue = event.clipboardData.getData('text/plain')
      formattedValue = currentValue.replace(/-/g, '')

      console.log(this.mtcn)

      this.mtcn = formattedValue
    },
    selectTransaction () {
      this.$router.push(`/pera-remit/receive/new?control_number=${this.mtcn}`)
    },
    resetTransactionTable () {
      this.showResult = false
      this.transactions = [
        { sender: '', receiver: '', sendout_amount: '', partner: '', pay_status: '' }
      ]
    },
    async getPayoutStatus () {
      if (this.mtcn.length === '') {
        this.renderToast('error', 'Error', 'You must insert a control number or MTCN to continue.')
      } else if (this.payoutType === 'wu' && (this.mtcn.length < 10 || this.mtcn.length > 10)) {
        this.renderToast('error', 'Error', 'The MTCN must be 10-digits')
      } else {
        await this.resetTransactionTable()
        let payload = {}
        let generateDeviceId = ''
        let branchCode = ''

        switch (this.payoutType) {
          case 'wu':
            payload = {
              mtcn: this.mtcn,
              terminal_id: this.currUser.fs_id,
              operator_id: this.currUser.user_id,
              location_id: this.currUser.location_id
            }

            await this.$store.dispatch('wu/getPayoutStatus', payload).then(res => {
              if (!(res.code >= 200 && res.code <= 299)) {
                this.showResult = false
                this.renderToast('error', res.code + ' Error', res.message)
              } else {
                const paymentTransactions = res.result.payment_transactions.payment_transaction
                const orignatingCountryCurency = paymentTransactions.payment_details.originating_country_currency
                this.showResult = true

                this.transactions = [{
                  sender: `${paymentTransactions.sender.name.first_name} ${paymentTransactions.sender.name.last_name}`,
                  receiver: `${paymentTransactions.receiver.name.first_name} ${paymentTransactions.receiver.name.last_name}`,
                  sendout_amount: orignatingCountryCurency.iso_code.currency_code + ' ' + numeral(this.convertToRealAmount(paymentTransactions.financials.originators_principal_amount)).format('0, 0.00'),
                  partner: 'Western Union',
                  pay_status: paymentTransactions.pay_status_description
                }]
              }
            }).catch(err => {
              this.showResult = false
              console.log(err)
              this.renderToast('error', err.data.code + ' Error', err.data.message)
            })
            break
          case 'nonex':
            generateDeviceId = `${this.currUser.user_id}${this.currUser.location_id}`
            branchCode = String('00000' + this.currUser.location_id).slice(-5)

            payload = {
              location_name: this.currUser.location_name,
              reference_number: this.nonexRefNumber,
              control_number: this.mtcn,
              location_id: this.currUser.location_id,
              user_id: this.currUser.user_id,
              branch: this.currUser.location_name,
              international_partner_code: 'PL0005',
              agent_code: this.currUser.location_code,
              agent_id: '84424911',
              branch_code: branchCode,
              location_code: `PERAHUB-${this.currUser.location_code}`,
              currency: 'PHP',
              device_id: Buffer.from(generateDeviceId).toString('base64')
            }

            // payload = {
            //   location_name: 'Aguirre',
            //   reference_number: this.nonexRefNumber,
            //   control_number: this.mtcn,
            //   location_id: this.currUser.location_id + '2',
            //   user_id: this.currUser.user_id,
            //   branch: 'Aguirre',
            //   international_partner_code: 'PL0005',
            //   agent_code: '01010701',
            //   agent_id: '84424911',
            //   branch_code: branchCode,
            //   location_code: `PERAHUB1-${this.currUser.location_code}`,
            //   currency: 'PHP',
            //   device_id: Buffer.from(generateDeviceId).toString('base64')
            // }

            await this.$store.dispatch('nonex/getRemcos')

            await this.$store.dispatch('nonex/getPayoutStatus', this.mtcn).then(res => {
              this.paidTransactions = res
            }).catch(err => {
              console.log(err)
            })

            if (this.isGCashTransaction) {
              payload.payout_amount = this.amount

              if (this.amount < 1) {
                this.renderToast('error', 'Search Error', 'Amount must be greater than 0.')
              } else {
                await this.$store.dispatch('nonex/gcashInquireTrx', payload).then(res => {
                  this.availableTransactions = res

                  console.log(res)
                }).catch(err => {
                  console.log(err)
                })
              }
            } else {
              await this.$store.dispatch('nonex/getInquireResult', payload).then(res => {
                this.availableTransactions = res
              }).catch(err => {
                console.log(err)
              })
            }

            console.log(this.paidTransactions)
            if (this.availableTransactions?.data) {
              if (this.availableTransactions.data.length > 0) {
                this.transactions = []

                this.availableTransactions.data.forEach(item => {
                  this.transactions.push({
                    sender: item.result.sender_name ? item.result.sender_name : '-',
                    receiver: item.result.receiver_name,
                    sendout_amount: `${item.result.currency} ${item.result.principal_amount}`,
                    partner: item.remco_name,
                    pay_status: 'Available for Pick-up'
                  })
                })

                this.showResult = true
              } else if (this.paidTransactions.result.length > 0) {
                this.transactions = []

                this.paidTransactions.result.forEach(item => {
                  const currentRemco = this.remcos.result.filter(remcoValue => {
                    return remcoValue.non_ex_code === item.remco_id.toString()
                  })

                  console.log(currentRemco[0])

                  this.transactions.push({
                    sender: item.sender_name,
                    receiver: item.receiver_name,
                    sendout_amount: `${item.currency_id === 1 ? 'PHP' : 'USD'} ${numeral(item.principal_amount).format('0, 0.00')}`,
                    partner: currentRemco[0].non_ex_name,
                    pay_status: 'Paid'
                  })
                })

                this.showResult = true
              }
            } else {
              this.renderToast('error', 'Pay Status Search Error', 'An error has occured, please try again.')
            }
            break
        }
      }
    }
  }
}
</script>
<style scoped>
  .ph-pay-status-sender-container,
  .ph-pay-status-receiver-container {
    display: flex;
  }

  .ph-pay-status-table-avatar {
    margin: auto 0;
  }

  .ph-pay-status-table-names-text {
    margin: auto;
    margin-left: 10px;
  }

  .ph-pay-status-status-circle {
    height: 10px;
    width: 10px;
    background: #f5f5f5;
    border-radius: 50%;
    margin: auto 0;
  }

  .ph-pay-status-select-title {
    font-family: 'Proxima Nova Bold';
    padding-top: 18px;
  }

  .ph-pay-status-selector .v-input {
    margin-top: 0
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 50px !important;
    width: 100%;
    letter-spacing: 1.25px;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    font-size: 14px;
    margin: 0;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 35px;
    }

    .ph-primary-button {
      width: 160px;
      margin-left: auto;
    }
  }
</style>
