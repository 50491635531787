import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import VueExcelXlsx from 'vue-excel-xlsx'

const baseDomain = 'perahub.com.ph'

Vue.config.productionTip = false
Vue.prototype.$uspBaseDomain = `.${baseDomain}`
Vue.prototype.$uspAuthAppURL = `https://usp20-auth.${baseDomain}`

Vue.use(VueCookies, {
  expires: (60 * 20),
  domain: `.${baseDomain}`,
  secure: (process.env.NODE_ENV === 'production')
})
Vue.use(VueExcelXlsx)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
