export default {
  currentPage: 0,
  currentStep: 1,
  policies: [],
  successData: null,
  selectedPremium: null,
  transactionData: {
    formNumber: '',
    insurance: '',
    policy: '',
    inceptionDate: '',
    expiryDate: '',
    agentCode: '',
    agentPrefix: '',
    rsoCode: '',
    rsoPrefix: '',
    amount: '',
    insuredCustomerID: '',
    insuredFirstName: '',
    insuredMiddleName: '',
    insuredNoMiddleNameTag: false,
    insuredLastName: '',
    insuredBirthDate: '',
    insuredOccupation: '',
    insuredMobileNumber: '',
    insuredEmailAddress: '',
    insuredPensionCode: '',
    beneficiaryFirstName: '',
    beneficiaryMiddleName: '',
    beneficiaryNoMiddleNameTag: false,
    beneficiaryLastName: '',
    beneficiaryRelationship: '',
    beneficiary2FirstName: '',
    beneficiary2MiddleName: '',
    beneficiary2NoMiddleNameTag: false,
    beneficiary2LastName: '',
    beneficiary2Relationship: '',
    beneficiary3FirstName: '',
    beneficiary3MiddleName: '',
    beneficiary3NoMiddleNameTag: false,
    beneficiary3LastName: '',
    beneficiary3Relationship: '',
    beneficiary4FirstName: '',
    beneficiary4MiddleName: '',
    beneficiary4NoMiddleNameTag: false,
    beneficiary4LastName: '',
    beneficiary4Relationship: '',
    mvType: '',
    premiumType: '',
    plateNumber: '',
    noPlateNumber: false,
    ORCRMonth: '',
    vehicleYear: '',
    vehicleMake: '',
    vehicleFileNumber: '',
    vehicleChassisNumber: '',
    vehicleEngineNumber: '',
    vehicleColor: '',
    vehicleUnladenWeight: '',
    vehicleBody: '',
    vehicleAuthorizedCapacity: '',
    vehicleBasicPremium: '',
    vehicleAuthenticationFee: '',
    vehicleDocStamps: '',
    vehicleCancellationFee: '',
    vehicleVAT: '',
    vehicleLGT: '',
    vehicleTotalCommission: '',
    vehiclePetnetCommission: '',
    vehicleBPCommission: '',
    vehicleDiscountedPrice: '',
    vehiclePromoCode: '',
    vehicleIsCorporate: false,
    vehicleCompanyName: '',
    policyType: '',
    beneficiary2: false,
    beneficiary3: false,
    beneficiary4: false,
    province: '',
    city: '',
    gender: '',
    civilStatus: '',
    birthPlace: '',
    insCardNo: '',
    locationRuralNetData: [],
    provincesData: [],
    citiesData: [],
    address: '',
    referrer: '',
    relationshipRuralNet: [],
    isFormValid: false
  },
  insuranceList: [
    {
      category: 'COC',
      logo: 'accident.svg',
      name: 'Accident Protect'
    },
    {
      category: 'COC3',
      logo: 'dengue.svg',
      name: 'Dengue Protect Plus'
    },
    // {
    //   category: 'COC4',
    //   logo: 'payapa.svg',
    //   name: 'Pera Payapa'
    // },
    // {
    //   category: 'COC6',
    //   logo: 'covid.svg',
    //   name: 'COVID Protect Plus'
    // },
    {
      category: 'COC7',
      logo: 'ctpl.svg',
      name: 'CTPL'
    },
    {
      category: 'COC8',
      logo: 'dengue2.svg',
      name: 'Dengue Protect v2'
    },
    {
      category: 'COC9',
      logo: 'payapa2.svg',
      name: 'Pera Payapa v2'
    },
    {
      category: 'COC10',
      logo: 'microsafe.svg',
      name: 'Microsafe'
    }
  ],
  agentPrefixList: [
    'RSO',
    'SPA',
    'PA'
  ],
  rsoPrefixList: [
    'ROH',
    'RSO'
  ],
  ruralNetGender: [
    {
      text: 'Male',
      value: 'M'
    },
    {
      text: 'Female',
      value: 'F'
    }
  ],
  ruralNetCivilStatus: [
    {
      text: 'Single',
      value: 'S'
    },
    {
      text: 'Married',
      value: 'M'
    },
    {
      text: 'Widowed',
      value: 'W'
    },
    {
      text: 'Anulled',
      value: 'A'
    },
    {
      text: 'Divorced',
      value: 'D'
    }
  ],
  ctplList: []
}
