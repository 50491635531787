export default {
  SET_BRANCHES (state, payload) {
    state.branches = payload
  },
  SET_PRP (state, payload) {
    state.prpList = payload
  },
  SET_DEPOSIT_CREATION_MODAL (state, payload) {
    state.showDepositCreationModal = payload
  },
  SET_DEPOSIT_UPDATE_MODAL (state, payload) {
    state.showDepositUpdateModal = payload
  },
  SET_DEPOSIT (state, payload) {
    state.deposits = payload
  },
  SET_EDITED_ITEM (state, payload) {
    state.editedItem = payload
  }
}
