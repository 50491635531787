export default {
  billsPaymentTransactions: [],
  billsPaymentCurrentTrx: null,
  billsPaymentResponseData: null,
  billsPaymentCurrentPage: 0,
  billsPaymentGetMisc: {},
  bayadReceiptData: {},
  billers: [],
  tpaId: '',
  billsCollectionTransactions: [],
  billsCollectionCurrentTrx: null,
  billsCollectionCurrentPage: 0,
  collectionProviders: [],
  billsCollectionResponseData: null,
  billsCollectionVoucherData: {},
  billsLoanAccountNo: ''
}
