import api from '@/api'

export default {
  saveOffer (payload) {
    return api.post('/v1/maintenance/api/prompt/offers', payload)
  },
  retrieveOffers () {
    return api.get('/v1/maintenance/api/prompt/offers')
  },
  retrieveOfferById (id) {
    return api.get(`/v1/maintenance/api/prompt/offers/${id}`)
  },
  updateOffer (id, payload) {
    return api.put(`/v1/maintenance/api/prompt/offers/${id}`, payload)
  },
  deleteOffer (id) {
    return api.delete(`/v1/maintenance/api/prompt/offers/${id}`)
  },
  retrieveQualifiedCustomers () {
    return api.get('/v1/maintenance/api/prompt/qualified-customers')
  },
  updateQualifiedCustomer (id, payload) {
    return api.put(`/v1/maintenance/api/prompt/qualified-customers/${id}`, payload)
  },
  updateQualifiedCustomerStatus (id, payload) {
    return api.put(`/v1/maintenance/api/prompt/qualified-customers/${id}/update-status`, payload)
  },
  checkAvailableOffer (query) {
    return api.get(`/v1/maintenance/api/prompt/offers/check?${query}`)
  }
}
