<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-data-table
      :items="grid"
      :headers="headers"
    >
      <template v-slot:[`item.trx_date`]="{ item }">
        {{ formatDateTime(item.trx_date) }}
      </template>
      <template v-slot:[`item.wu_sender_name`]="{ item }">
        <v-avatar class="mr-1" size="26px" :style="avatarColor(item.wu_sender_name)">
          <span class="white--text text-body-2">{{ avatarInitial(item.wu_sender_name) }}</span>
        </v-avatar>
        {{ toProperCase(item.wu_sender_name) }}
      </template>
      <template v-slot:[`item.wu_payee_name`]="{ item }">
        <v-avatar class="mr-1" size="26px" :style="avatarColor(item.wu_payee_name)">
          <span class="white--text text-body-2">{{ avatarInitial(item.wu_payee_name) }}</span>
        </v-avatar>
        {{ toProperCase(item.wu_payee_name) }}
      </template>
      <template v-slot:[`item.net_amount`]="{ item }">
        {{ toMoney(item.net_amount) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span :class="{ 'red--text': (item.status === 0 || item.status === '0'), 'green--text': (item.status === 1 || item.status === '1')}">
          <v-icon
            :class="{ 'red--text': (item.status === 0 || item.status === '0'), 'green--text': (item.status === 1 || item.status === '1')}"
            x-small
          >
            mdi-circle
          </v-icon>
          {{ getStatusText(item.status) }}
        </span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn @click="updateEntry(item)" v-if="item.status === 0 || item.status === '0'" color="primary" icon>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn @click="confirmDelete(item)" v-if="item.status === 0 || item.status === '0'" color="primary" icon>
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="#8B51FF"
              v-bind="attrs"
              v-on="on"
              to="/pera-remit/manual-entry/receive"
            >
              <img src="@/assets/images/nonex-receive-icon-white.svg" width="18" />
            </v-btn>
          </template>
          <span>Manual Receive Entry</span>
        </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/pera-remit/manual-entry/send"
            color="yellow"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <img src="@/assets/images/send-money-icon.svg" width="18" />
          </v-btn>
        </template>
        <span>Manual Send Entry</span>
      </v-tooltip>
    </v-speed-dial>
    <v-dialog
      v-model="deleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Entry
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this entry?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeDeleteDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="deleteEntry"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import {
  getStatusText,
  avatarInitial,
  toProperCase,
  avatarColor,
  toMoney
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      selectedEntry: 'manual/selectedEntry'
    })
  },
  data () {
    return {
      grid: [],
      fab: false,
      deleteDialog: false,
      breadcrumbsItems: [
        {
          text: 'PERA Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Manual Entry',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date',
          width: '140px'
        },
        {
          text: 'MTCN / Ref No.',
          sortable: true,
          value: 'wu_mtcn',
          width: '160px'
        },
        {
          text: 'Sender',
          sortable: true,
          value: 'wu_sender_name',
          width: '180px'
        },
        {
          text: 'Payee',
          sortable: true,
          value: 'wu_payee_name',
          width: '180px'
        },
        {
          text: 'Total Amount',
          sortable: true,
          value: 'net_amount',
          width: '150px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '130px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '120px'
        }
      ]
    }
  },
  methods: {
    confirmDelete (data) {
      this.$store.commit('manual/SET_SELECTED_ENTRY', data)
      this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.$store.commit('manual/SET_SELECTED_ENTRY', null)
      this.deleteDialog = false
    },
    updateEntry (item) {
      this.$store.dispatch('manual/resetState')
      this.$store.commit('manual/SET_SELECTED_ENTRY', item)
      this.$router.push({
        path: '/pera-remit/manual-entry/update'
      })
    },
    async deleteEntry () {
      if (this.selectedEntry) {
        const req = await this.$store.dispatch('manual/delete', this.selectedEntry.id)

        if (req.code === 200) {
          this.getGrid()
          this.deleteDialog = false
        } else {
          // Handle error
        }
      }
    },
    formatDateTime (date) {
      if (date) return format(new Date(date), 'yyyy-MM-dd')
      return ''
    },
    async getGrid () {
      const req = await this.$store.dispatch('manual/getGrid', {
        locationID: this.currUser.location_id,
        trxDate: this.currUser.trx_date
      })

      if (req.code === 200) this.grid = req.result
    },
    getStatusText,
    avatarInitial,
    toProperCase,
    avatarColor,
    toMoney
  },
  async mounted () {
    this.getGrid()
    this.$store.commit('manual/SET_SELECTED_ENTRY', null)
  }
}
</script>
