import { render, staticRenderFns } from "./ReceiveMoneyStatus.vue?vue&type=template&id=ca3d2250&scoped=true"
import script from "./ReceiveMoneyStatus.vue?vue&type=script&lang=js"
export * from "./ReceiveMoneyStatus.vue?vue&type=script&lang=js"
import style0 from "./ReceiveMoneyStatus.vue?vue&type=style&index=0&id=ca3d2250&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca3d2250",
  null
  
)

export default component.exports