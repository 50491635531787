import { render, staticRenderFns } from "./CreateCustomerModal.vue?vue&type=template&id=7680449d&scoped=true"
import script from "./CreateCustomerModal.vue?vue&type=script&lang=js"
export * from "./CreateCustomerModal.vue?vue&type=script&lang=js"
import style0 from "./CreateCustomerModal.vue?vue&type=style&index=0&id=7680449d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7680449d",
  null
  
)

export default component.exports