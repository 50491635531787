<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mt-1 px-md-5">
      <v-col :cols="12" :md="5">
        <FileUploader
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          guideText="XLS, XLSX, CSV"
          @onFileChange="handleFile"
          :hasImportButton="true"
        />
      </v-col>
    </v-row>
    <v-row class="px-2 mt-md-6 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="12" :sm="4" :md="3">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-7" :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:[`item.customer_name`]="{ item }">
            <v-avatar class="mr-1" size="26px" :style="avatarColor(item.customer_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.customer_name) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ getStatus(item.status) }}
          </template>
          <template v-slot:[`item.date_published`]="{ item }">
            {{ formatDate(item.date_published) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              @click="editItem(item)"
              color="primary"
              icon
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              @click="showDeleteDialog(item)"
              color="primary"
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="newEntry"
            color="#1A2791"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <img src="@/assets/images/compliance/new-entry.svg" width="22" />
          </v-btn>
        </template>
        <span>New Entry</span>
      </v-tooltip>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="downloadSample"
            color="#D12C7F"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <img src="@/assets/images/compliance/sample-csv.svg" width="22" />
          </v-btn>
        </template>
        <span>Get Sample Format to CSV</span>
      </v-tooltip>
    </v-speed-dial>
    <v-dialog
      v-model="isDeleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Item
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this item?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDeleteDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="doDelete"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as xlsx from 'xlsx'
import FileUploader from '@/components/partials/FileUploader.vue'
import { format } from 'date-fns'
import {
  getFileExtension,
  avatarInitial,
  toProperCase,
  avatarColor,
  renderToast
} from '@/utils'

export default {
  data () {
    return {
      search: '',
      fab: false,
      selectedItem: null,
      isDeleteDialog: false,
      breadcrumbsItems: [
        {
          text: 'Compliance Module',
          disabled: false,
          exact: true,
          to: '/compliance'
        },
        {
          text: 'Adverse',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customer_name',
          width: '180px'
        },
        {
          text: 'Category',
          sortable: true,
          value: 'type',
          width: '120px'
        },
        {
          text: 'Source',
          sortable: true,
          value: 'source',
          width: '180px'
        },
        {
          text: 'Date Published',
          sortable: true,
          value: 'date_published',
          width: '120px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '120px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '80px'
        }
      ],
      items: []
    }
  },
  components: {
    FileUploader
  },
  methods: {
    downloadSample () {
      window.open('/comp/Fraudsters.csv', '_blank')
    },
    newEntry () {
      this.$router.push('/compliance/adverse/new')
    },
    getStatus (status) {
      return (String(status) === '1') ? 'Active' : 'Inactive'
    },
    handleFile (files) {
      const newFile = files[0]
      const that = this

      if (newFile) {
        const ext = getFileExtension(newFile.name)

        if (ext === 'csv' || ext === 'xls' || ext === 'xlsx') {
          const reader = new FileReader()

          reader.onload = async function (e) {
            const data = new Uint8Array(e.target.result)
            const workbook = xlsx.read(data, { type: 'array' })
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            let records = xlsx.utils.sheet_to_json(worksheet, { raw: false })
            records = records.filter(item => {
              const headers = Object.keys(item)
              return headers.includes('First Name') && headers.includes('Last Name') && headers.includes('Category')
            })

            if (records.length > 0) {
              const payload = records.map(item => {
                const {
                  'First Name': firstName,
                  'Last Name': lastName,
                  Category: type,
                  Address: pepCateg,
                  Source: source,
                  'Date Published': datePublished,
                  Remarks: tag,
                  Alias: otherInfo
                } = item

                return {
                  first_name: firstName,
                  last_name: lastName,
                  pep_categ: pepCateg || '',
                  date_published: (datePublished) ? format(new Date(datePublished), 'yyyy-MM-dd') : '',
                  other_info: otherInfo || '',
                  img: '',
                  type,
                  source,
                  tag
                }
              })

              that.$store.commit('compliance/SET_UPLOADED_DATA', payload)
              that.$store.commit('compliance/SET_UPLOADED_FILE_NAME', newFile.name)
              that.$router.push('/compliance/adverse/upload')
            } else {
              renderToast('error', 'Upload Failed', 'The file you have selected is not valid')
            }
          }

          reader.readAsArrayBuffer(newFile)
        } else {
          renderToast('error', 'Upload Failed', 'The file you have selected is not valid')
        }
      }
    },
    editItem (item) {
      this.$router.push(`/compliance/adverse/${item.id}`)
    },
    showDeleteDialog (item) {
      this.selectedItem = item
      this.isDeleteDialog = true
    },
    closeDeleteDialog () {
      this.selectedItem = null
      this.isDeleteDialog = false
    },
    async doDelete () {
      const that = this
      const { code } = await this.$store.dispatch('compliance/deleteAdverseEntry', that.selectedItem.id)

      if (code === 200) {
        renderToast('success', 'Successful', 'The fraudster has been removed')
      } else {
        renderToast('error', 'Error', 'Failed to delete fraudster from the list')
      }

      this.retrieveItems()
      this.closeDeleteDialog()
    },
    async retrieveItems () {
      try {
        const { code, result } = await this.$store.dispatch('compliance/retrieveAdverse')

        if (code === 200) {
          this.items = result.map(item => {
            return {
              ...item,
              customer_name: `${item.first_name} ${item.last_name}`
            }
          })
        } else {
          renderToast('error', `[${code}] Error`, 'Failed to retrieve grid')
        }
      } catch (e) {
        renderToast('error', 'USP Error', 'Failed to retrieve grid')
      }
    },
    formatDate (date) {
      if (date) return date.substr(0, 10)
      return date
    },
    avatarInitial,
    toProperCase,
    avatarColor
  },
  mounted () {
    this.retrieveItems()
  }
}
</script>
