<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        class="ph-breadcrumbs"
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ph-transaction-container">
        <v-col cols="12" v-if="currentPage === 0">
          <CustomerSearch />
        </v-col>
        <v-col cols="12" v-if="currentPage === 1">
          <QuickPayForm />
        </v-col>
        <v-col cols="12" v-if="currentPage === 2">
          <QuickPaySuccess />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerSearch from '@/components/wu/QuickPayCustomerSearch.vue'
import QuickPayForm from '@/components/wu/QuickPayForm.vue'
import QuickPaySuccess from '@/components/wu/QuickPaySuccess.vue'

export default {
  name: 'SendMoney',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Quick Pay',
          disabled: false,
          href: '/pera-remit/quickpay'
        },
        {
          text: 'New',
          disabled: true,
          href: '#'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'wu/quickPayCurrentPage'
    })
  },
  components: {
    CustomerSearch,
    QuickPayForm,
    QuickPaySuccess
  },
  mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Sender Lookup')
  }
}
</script>
<style scoped>
  .ph-transaction-container .col {
    padding: 15px;
  }

  .ph-breadcrumbs {
    padding: 5px 5px;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }

    .ph-transaction-container .col {
      padding: 12px;
    }

    .ph-breadcrumbs {
      padding: 18px 12px;
      padding-left: 24px;
    }
  }
</style>
