const AUTH_SUCCESS = (state, payload) => {
  state.isAuthenticated = payload
}

const SET_CURRENT_USER = (state, payload) => {
  state.currUser = payload
}

const SET_RUN_SESSION = (state, payload) => {
  state.runSession = payload
}

const SET_AUTH_TIMESTAMP = (state, payload) => {
  state.authTimestamp = payload
}

const SET_SHOW_REFRESH = (state, payload) => {
  state.showRefreshPrompt = payload
}

const SET_TOKEN = (state, payload) => {
  state.token = payload
}

const SET_HAS_WACOM = (state, payload) => {
  state.currUser = { ...state.currUser, has_wacom: payload }
}

const SET_HAS_WACOM_DEFAULT = (state) => {
  state.currUser.has_wacom = false
}

const SET_HAS_WACOM_SDK = (state, payload) => {
  state.currUser.has_wacom_sdk = payload
}

const SET_HAS_WACOM_SDK_DEFAULT = (state) => {
  state.currUser.has_wacom_sdk = false
}

const SET_IS_OTP_PASSED = (state, payload) => {
  state.isOTPpassed = payload
}

const SET_BRANCH_RECEIPT_DETAIL = (state, payload) => {
  state.currUser.branchReceiptDetails = payload
}

export default {
  AUTH_SUCCESS,
  SET_TOKEN,
  SET_CURRENT_USER,
  SET_RUN_SESSION,
  SET_AUTH_TIMESTAMP,
  SET_SHOW_REFRESH,
  SET_HAS_WACOM,
  SET_HAS_WACOM_DEFAULT,
  SET_HAS_WACOM_SDK_DEFAULT,
  SET_HAS_WACOM_SDK,
  SET_IS_OTP_PASSED,
  SET_BRANCH_RECEIPT_DETAIL
}
