export default {
  searchData: {
    isTyping: false,
    keyword: '',
    results: null
  },
  currentView: 0,
  requests: [],
  recentlyAdded: [],
  selectedCustomer: null,
  selectedMobileUser: null,
  oldMobileNumber: '',
  policyList: [],
  formType: 'save',
  formData: {
    first_name: '',
    middle_name: '',
    last_name: '',
    birth_date: '',
    address: '',
    municipality: '',
    province: '',
    zip_code: '',
    tel_no: '',
    mobile_no: '',
    email_add: '',
    updated_by: '',
    updated_loc: '',
    ip_address: '',
    gender: '',
    img: '',
    img_file: [],
    occupation: '',
    civil_status: '',
    nationality: 'Filipino',
    source_fund: '',
    employer_name: '',
    nature_work: '',
    employment: '',
    street1: '',
    city1: '',
    province1: '',
    country1: 'Philippines',
    zip_code1: '',
    country_birth: 'Philippines',
    mobile_country_code: '',
    tags: '[]',
    risk_level: '',
    dpa_consent: '',
    is_corporate: '',
    edd_path: '',
    barangay: '',
    barangay1: '',
    country: 'Philippines',
    birth_place: '',
    position_level: '',
    purpose_transaction: '',
    relation_to: '',
    compliance_edd_docs: '',
    customer_signatures: [],
    display_previous_signatures: [],
    branchcode: '',
    tag_no_middlename: '0',
    tag_verified_mobile: '0',
    hasWacom: '',
    customerDataConsent: []
  },
  showCustomerCreationModal: false
}
