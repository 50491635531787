<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-card-title class="ph-nonex-datatable-title">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="ph-textbox ph-textbox-grid-search"
                outlined
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="transactions"
              :search="search"
              :calculate-widths="true"
              class="ph-nonex-datatable"
            >
              <!-- <template v-slot:[`item.provider`]="{item}">
                <p class="ph-nonex-table-names-text text-amount">
                  {{(item.provider === 'PeraHubCard' || item.provider === 'Regular') ? (item.provider === 'Regular' ? 'Regular EON' : 'PHV Card') : item.provider}}
                </p>
              </template> -->
              <template v-slot:[`item.account_name`]="{item}">
                <div class="ph-nonex-sender-container">
                  <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.account_name)">
                    <span class="white--text text-body-2">{{ avatarInitial(item.account_name) }}</span>
                  </v-avatar>
                  <p class="ph-nonex-table-names-text">{{item.account_name}}</p>
                </div>
              </template>
              <template v-slot:[`item.reference_number`]="{item}">
                <v-chip
                  class="ma-2 ph-nonex-table-names-text text-amount text-inquire-button"
                  color="primary"
                  @click="inquirePeraBankTrx(item)"
                >
                  <v-icon left>
                    mdi-magnify
                  </v-icon>
                  {{item.reference_number}}
                </v-chip>
                <p v-if="item.provider !== 'Regular' && item.provider !== 'PeraHubCard' && item.status === 1" class="ph-nonex-table-names-text text-amount">{{item.petnet_trackingno}}</p>
              </template>
              <template v-slot:[`item.principal_amount`]="{item}">
                <p class="ph-nonex-table-names-text text-amount">{{item.currency_id == 1 ? 'PHP' : 'USD'}} {{formatAmount(item.principal_amount)}}</p>
              </template>
              <template v-slot:[`item.service_charge`]="{item}">
                <p class="ph-nonex-table-names-text text-amount">{{item.currency_id == 1 ? 'PHP' : 'USD'}} {{formatAmount(item.service_charge)}}</p>
              </template>
              <template v-slot:[`item.total_amount`]="{item}">
                <p class="ph-nonex-table-names-text text-amount">{{item.currency_id == 1 ? 'PHP' : 'USD'}} {{formatAmount(item.total_amount)}}</p>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.status === 0">
                  <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.status === 1">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Successful
                </span>
              </template>
              <template v-slot:[`item.action`]="{item}">
                <v-menu
                  bottom
                  left
                  v-if="currUser.location_type === '1'"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.status === 1"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="printOAR(item)">
                      <v-list-item-title>Print OAR</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-tooltip left v-if="item.status === 0 && currUser.location_type === '1'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="retryPeraBankTrx(item)"
                    >
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>Retry</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-fab-transition>
        <v-btn
          color="primary"
          fab
          large
          fixed
          dark
          bottom
          right
          to="/cards/pera-bank/new"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'PeraBankIndex',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Cards',
          disabled: false,
          href: '/cards'
        },
        {
          text: 'Pera Banks',
          disabled: false,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Bank Name',
          sortable: true,
          value: 'biller_name'
        },
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date'
        },
        {
          text: 'Reference Number',
          sortable: true,
          value: 'reference_number'
        },
        {
          text: 'Account Name',
          sortable: true,
          value: 'account_name'
        },
        {
          text: 'Account Number',
          sortable: true,
          value: 'account_number'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'principal_amount'
        },
        {
          text: 'Service Charge',
          sortable: true,
          value: 'service_charge'
        },
        {
          text: 'Total Amount',
          sortable: true,
          value: 'total_amount'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      filteredACRs: [],
      search: '',
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      transition: 'slide-y-reverse-transition',
      diskartechOTPDialog: false,
      isDiskartechOTPLoading: false,
      diskartechOTPInvalid: false,
      diskartechOTP: '',
      diskartechOTPMessage: '',
      successTransactionData: {}
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'bankDeposit/bankDepositTransactions',
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    const acrs = await this.$store.dispatch('acr/retrieveFilteredACR',
      {
        location_id: this.currUser.location_id,
        trx_date: this.currUser.trx_date.replace(' 00:00:00', ''),
        user_id: this.currUser.user_id
      })
    this.filteredACRs = acrs.data
    await this.getBankDepositList()
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    async getBankDepositList () {
      const payload = {
        trxDate: this.currUser.trx_date,
        locationId: this.currUser.location_id
      }

      this.$store.dispatch('bankDeposit/getBankDepositList', payload)
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    async inquirePeraBankTrx (data) {
      let executeEndpoint = ''
      const payload = {
        id: data.id,
        reference_number: data.reference_number
      }

      if (data.bank_id === 1) {
        executeEndpoint = 'bankDeposit/inquireUBPCashIn'
      } else if (data.bank_id === 30) {
        executeEndpoint = 'bankDeposit/inquireMBCashIn'
        payload.location_id = data.location_id
      } else {
        executeEndpoint = 'bankDeposit/inquireBPICashIn'
        payload.location_id = data.location_id
      }

      console.log(executeEndpoint, payload)

      await this.$store.dispatch(executeEndpoint, payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.renderToast('info', 'Transaction Status:', res.result.state)
        } else {
          this.renderToast('info', 'Transaction Status:', res.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async retryPeraBankTrx (data) {
      let executeEndpoint = ''
      const payload = {
        reference_number: data.reference_number,
        id: data.id
      }

      if (data.bank_id === 1) {
        executeEndpoint = 'bankDeposit/retryUBPCashIn'
      } else if (data.bank_id === 30) {
        executeEndpoint = 'bankDeposit/retryMBCashIn'
        payload.location_id = data.location_id
        payload.principal_amount = parseFloat(data.principal_amount)
        payload.form_number = data.form_number
      } else {
        executeEndpoint = 'bankDeposit/retryBPICashIn'
        payload.location_id = data.location_id
        payload.principal_amount = parseFloat(data.principal_amount)
        payload.form_number = data.form_number
      }

      await this.$store.dispatch(executeEndpoint, payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.getBankDepositList()
          this.renderToast('success', 'Bank Deposit Retry Successful', res.message)
        } else {
          this.renderToast('error', 'Error', res.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async printOAR (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: '2',
          or_series_no: '',
          oar_series_no: data.form_number,
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    }
  }
}
</script>
<style scoped>
/* .ph-nonex-datatable-title {
  padding-left: 0;
} */

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.7);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

.ph-nonex-table-names-text.text-amount {
  margin-left: 0 !important;
}

.text-inquire-button {
  cursor: pointer;
}

/* .ph-textbox.ph-textbox-grid-search {
  width: 256px;
} */
</style>
