export default {
  currentPage: 0,
  products: [],
  successData: null,
  transactionData: {
    formNumber: '',
    product: null,
    mobileNumber: '',
    promoCode: '',
    freeLoad: false,
    issueOAR: false
  },
  alert: {
    isVisible: false,
    type: '',
    message: ''
  }
}
