import { endpoints } from '@/api/endpoints'

const adjustmentAndExpenses = endpoints.use('adjustmentAndExpenses')

export default {
  retrieveData: ({ commit }, { trxDate, locationId, userId }) => new Promise((resolve, reject) => {
    adjustmentAndExpenses.retrieveData(trxDate, locationId, userId)
      .then(({ data }) => {
        commit('setItems', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveData: ({ commit }, payload) => new Promise((resolve, reject) => {
    adjustmentAndExpenses.saveData(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deleteRecord: ({ commit }, id) => new Promise((resolve, reject) => {
    adjustmentAndExpenses.deleteRecord(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getCount: ({ commit }, { trxDate, locationId }) => new Promise((resolve, reject) => {
    adjustmentAndExpenses.getCount(trxDate, locationId)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getTypes: ({ commit }) => new Promise((resolve, reject) => {
    adjustmentAndExpenses.getTypes()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
