export default {
  SET_INTER_BRANCH_LIST (state, payload) {
    state.ibtList = payload
  },
  SET_BORROWER_BANKS (state, payload) {
    state.borrowerBanks = payload
  },
  SET_USERS_BY_BORROWER_LOCATION (state, payload) {
    state.usersByBorrowerLocation = payload
  },
  SET_REASON_LIST (state, payload) {
    state.reasonList = payload
  },
  SET_INTER_BRANCH_APPROVAL_LIST (state, payload) {
    state.ibtApprovalList = payload
  }
}
