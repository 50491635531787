<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="12" :sm="4" :md="3">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col :cols="12" :sm="8" :md="9">
        <v-btn
          @click="retrieveData"
          class="text-capitalize font-weight-bold"
          color="primary"
          rounded
          large
          text
        >
          <v-icon class="mr-1">mdi-refresh</v-icon>
          Refresh
        </v-btn>
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-7" :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="custom-table"
          calculate-widths
        >
        <template #item.actions="{ item }">
            <v-btn @click="updateEntry(item)" color="primary" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="openDeleteDialog(item)" color="red" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="deleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Offer
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this item?
          <br><br>
          Code: <span v-if="selectedItem">{{ selectedItem.code }}</span><br>
          Name: <span v-if="selectedItem">{{ selectedItem.name }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDeleteDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="doDelete"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-fab-transition>
      <v-btn
        @click="newEntry"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      search: '',
      selectedItem: null,
      deleteDialog: false,
      items: [],
      breadcrumbsItems: [
        {
          text: 'Prompt Management',
          exact: true,
          to: '/prompt'
        },
        {
          text: 'Offers',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Code',
          sortable: true,
          value: 'code',
          width: '120px'
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          width: '120px'
        },
        {
          text: 'Spiel',
          sortable: true,
          value: 'spiel'
        },
        {
          text: 'Frequency',
          sortable: true,
          value: 'frequency',
          width: '120px'
        },
        {
          text: 'Encoded By',
          sortable: true,
          value: 'encoded_by',
          width: '120px'
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          width: '120px'
        }
      ]
    }
  },
  methods: {
    async doDelete () {
      try {
        const { code } = await this.$store.dispatch('prompt/deleteOffer', this.selectedItem.id)

        if (code === 200) {
          this.retrieveData()
          this.closeDeleteDialog()
          renderToast('success', 'Successful', 'Offer has been deleted')
        } else {
          renderToast('error', 'Failed', 'Failed to delete offer')
        }
      } catch (e) {
        renderToast('error', 'Failed', 'Failed to delete offer')
      }
    },
    openDeleteDialog (data) {
      this.selectedItem = data
      this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.selectedItem = null
      this.deleteDialog = false
    },
    updateEntry ({ id }) {
      this.$router.push(`/prompt/offers/${id}`)
    },
    newEntry () {
      this.$router.push('/prompt/offers/new')
    },
    async retrieveData () {
      try {
        const { code, result } = await this.$store.dispatch('prompt/retrieveOffers')

        if (code === 200) {
          this.items = result
        } else {
          renderToast('error', 'Request Error', 'Failed to retrieve grid')
        }
      } catch (e) {
        renderToast('error', 'Failed', 'Failed to retrieve grid')
      }
    }
  },
  mounted () {
    this.retrieveData()
  }
}
</script>
