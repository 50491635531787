<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mt-16 mb-4 pt-10 mx-2 mt-sm-4 pt-sm-0 mx-sm-2 mx-md-4" no-gutters>
      <v-col :cols="12" :md="4" class="pt-sm-2">
        <v-text-field
          v-model="searchText"
          class="ph-textbox"
          placeholder="Search User"
          append-icon="mdi-magnify"
          outlined
        />
      </v-col>
    </v-row>
    <v-row class="mt-md-12 mb-md-10" no-gutters>
      <v-col :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :search="searchText"
          class="custom-table"
          :calculate-widths="true"
        >
          <template v-slot:[`item.bill_name`]="{ item }">
            <span v-if="item.bill_name">
              {{ item.bill_name }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span v-if="item.status !== null">
              {{ getStatusDisplay(item.status) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn @click="openUpdateDialog(item)" color="primary" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="openDeleteDialog(item)" color="red" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="openAddDialog"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog v-model="addDialog" width="60%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            Add User Role
          </h3>
          <v-spacer />
          <v-btn @click="closeAddDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="addForm">
            <div v-if="selectedUser">
              <v-row class="mt-2 mb-4 mb-md-4">
                <v-col :cols="12" :md="6">
                  <v-select
                    v-model="selectedModule"
                    :items="formData.access"
                    item-text="module_name"
                    @change="setSelectedModuleIndex"
                    class="ph-textbox"
                    label="Module"
                    return-object
                    outlined
                  />
                </v-col>
              </v-row>
              <v-card>
                <v-card-text>
                  <v-row v-if="selectedModuleIndex !== -1">
                    <v-col :cols="12" :md="8">
                      <h2 class="my-2 my-md-4" v-if="selectedModule">
                        {{ selectedModule.module_name }}
                      </h2>
                    </v-col>
                    <v-col :cols="12" :md="4">
                      <v-select
                        v-model="formData.access[selectedModuleIndex].user_type"
                        :items="roles"
                        item-value="id"
                        item-text="user_role"
                        class="ph-textbox"
                        label="Role"
                        outlined
                      />
                    </v-col>
                    <v-col :cols="12" :md="3">
                      <v-checkbox
                        class="d-inline-block"
                        v-model="formData.access[selectedModuleIndex].create_access"
                        :true-value="1"
                        :false-value="0"
                        label="Create"
                      />
                    </v-col>
                    <v-col :cols="12" :md="3">
                      <v-checkbox
                        class="d-inline-block"
                        v-model="formData.access[selectedModuleIndex].view_access"
                        :true-value="1"
                        :false-value="0"
                        label="View"
                      />
                    </v-col>
                    <v-col :cols="12" :md="3">
                      <v-checkbox
                        class="d-inline-block"
                        v-model="formData.access[selectedModuleIndex].update_access"
                        :true-value="1"
                        :false-value="0"
                        label="Update"
                      />
                    </v-col>
                    <v-col :cols="12" :md="3">
                      <v-checkbox
                        class="d-inline-block"
                        v-model="formData.access[selectedModuleIndex].delete_access"
                        :true-value="1"
                        :false-value="0"
                        label="Delete"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <div v-else>
              <v-row class="d-flex align-center mt-2 mb-4 mb-md-4">
                <v-col :cols="12" :md="9">
                  <v-text-field
                    v-model="searchUserText"
                    class="ph-textbox"
                    label="Search User"
                    autofocus
                    outlined
                  />
                </v-col>
                <v-col :cols="12" :md="3">
                  <v-btn @click="searchUser" class="rounded-lg" color="primary" large block>
                    Search
                  </v-btn>
                </v-col>
                <v-col :cols="12">
                  <v-data-table class="ph-clickable-table" @click:row="selectUser" :items="users" :headers="usersHeaders" />
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6" v-if="selectedUser">
          <v-btn
            @click="closeAddDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            @click="addItem"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateDialog" width="60%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            Update User Role
          </h3>
          <v-spacer />
          <v-btn @click="closeUpdateDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="updateForm">
            <div v-if="selectedItem">
              <v-row class="mt-2 mb-4 mb-md-4">
                <v-col :cols="12" :md="6">
                  <v-select
                    v-model="selectedModule"
                    :items="formData.access"
                    item-text="module_name"
                    @change="setSelectedModuleIndex"
                    class="ph-textbox"
                    label="Module"
                    return-object
                    outlined
                  />
                </v-col>
              </v-row>
              <v-card>
                <v-card-text>
                  <v-row v-if="selectedModuleIndex !== -1">
                    <v-col :cols="12" :md="8">
                      <h2 class="my-2 my-md-4" v-if="selectedModule">
                        {{ selectedModule.module_name }}
                      </h2>
                    </v-col>
                    <v-col :cols="12" :md="4">
                      <v-select
                        v-model="formData.access[selectedModuleIndex].user_type"
                        :items="roles"
                        item-value="id"
                        item-text="user_role"
                        class="ph-textbox"
                        label="Role"
                        outlined
                      />
                    </v-col>
                    <v-col :cols="12" :md="3">
                      <v-checkbox
                        class="d-inline-block"
                        v-model="formData.access[selectedModuleIndex].create_access"
                        :true-value="1"
                        :false-value="0"
                        label="Create"
                      />
                    </v-col>
                    <v-col :cols="12" :md="3">
                      <v-checkbox
                        class="d-inline-block"
                        v-model="formData.access[selectedModuleIndex].view_access"
                        :true-value="1"
                        :false-value="0"
                        label="View"
                      />
                    </v-col>
                    <v-col :cols="12" :md="3">
                      <v-checkbox
                        class="d-inline-block"
                        v-model="formData.access[selectedModuleIndex].update_access"
                        :true-value="1"
                        :false-value="0"
                        label="Update"
                      />
                    </v-col>
                    <v-col :cols="12" :md="3">
                      <v-checkbox
                        class="d-inline-block"
                        v-model="formData.access[selectedModuleIndex].delete_access"
                        :true-value="1"
                        :false-value="0"
                        label="Delete"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6">
          <v-btn
            @click="closeUpdateDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            @click="updateItem"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete User Role
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this user role?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDeleteDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="deleteItem"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      items: [],
      users: [],
      roles: [],
      searchText: '',
      searchUserText: '',
      selectedItem: null,
      selectedUser: null,
      selectedModule: null,
      selectedModuleIndex: -1,
      addDialog: false,
      updateDialog: false,
      deleteDialog: false,
      formData: {
        user_id: '',
        access: []
      },
      breadcrumbsItems: [
        { text: 'Administrator', disabled: false, href: '/' },
        { text: 'User Role Management', disabled: true }
      ],
      headers: [
        { text: 'Username', sortable: true, value: 'user_name' },
        { text: 'Fullname', sortable: true, value: 'full_name' },
        { text: 'Email', sortable: true, value: 'email_address' },
        { text: 'Branch', sortable: true, value: 'location_name' },
        { text: 'Action', sortable: false, width: '150px', value: 'action' }
      ],
      usersHeaders: [
        { text: 'Username', sortable: true, value: 'user_name' },
        { text: 'Firstname', sortable: true, value: 'first_name' },
        { text: 'Lastname', sortable: true, value: 'last_name' },
        { text: 'Birthdate', sortable: true, value: 'birth_date' }
      ],
      requiredRules: [
        v => !!v || 'This field is required'
      ]
    }
  },
  methods: {
    setSelectedModuleIndex (data) {
      this.selectedModuleIndex = this.formData.access.indexOf(data)
    },
    selectUser (data) {
      this.selectedUser = data
      this.formData.access = this.formData.access.map(item => {
        return {
          ...item,
          user_id: data.user_id
        }
      })
    },
    async searchUser () {
      if (this.searchUserText) {
        const req = await this.$store.dispatch('users/searchUserRole', {
          search: this.searchUserText,
          user_id: this.currUser.user_id,
          module_id: 20,
          access_permission: 'view_access'
        })

        if (req.code === 200) {
          this.users = req.result
        } else if (req.code === 403) {
          renderToast('error', req.message, req.error.message)
        }
      }
    },
    resetForm () {
      this.users = []
      this.selectedUser = null
      this.selectedModule = null
      this.selectedModuleIndex = -1
      this.searchUserText = ''

      this.formData = {
        user_id: '',
        access: []
      }
    },
    async openAddDialog () {
      const modulesReq = await this.$store.dispatch('users/showAllModules', {
        user_id: this.currUser.user_id,
        module_id: 20,
        access_permission: 'view_access'
      })
      const rolesReq = await this.$store.dispatch('users/showUserRoles', {
        user_id: this.currUser.user_id,
        module_id: 20,
        access_permission: 'view_access'
      })

      if (modulesReq.code === 200) {
        this.formData.access = modulesReq.result.map(item => {
          return {
            user_id: '',
            user_type: null,
            user_status: 'active',
            module_access: item.id,
            module_name: item.module_name,
            create_access: 0,
            view_access: 0,
            update_access: 0,
            delete_access: 0
          }
        })

        if (this.formData.access.length > 0) {
          this.selectedModule = this.formData.access[0]
          this.selectedModuleIndex = 0
        }
      } else if (modulesReq.code === 403) {
        renderToast('error', modulesReq.message, modulesReq.error.message)
      }
      if (rolesReq.code === 200) {
        this.roles = rolesReq.result
      } else if (rolesReq.code === 403) {
        renderToast('error', rolesReq.message, rolesReq.error.message)
      }

      this.addDialog = true
    },
    closeAddDialog () {
      this.addDialog = false
      this.resetForm()
      this.$refs.addForm.resetValidation()
    },
    async openUpdateDialog  (data) {
      const rolesReq = await this.$store.dispatch('users/showUserRoles', {
        user_id: this.currUser.user_id,
        module_id: 20,
        access_permission: 'view_access'
      })

      if (rolesReq.code === 200) {
        this.roles = rolesReq.result
      } else if (rolesReq.code === 403) {
        renderToast('error', rolesReq.message, rolesReq.error.message)
      }

      if (data.access.length > 0) {
        this.selectedModule = data.access[0]
        this.selectedModuleIndex = 0
      }

      this.formData = data
      this.selectedItem = data
      this.updateDialog = true
    },
    closeUpdateDialog  () {
      this.selectedItem = null
      this.updateDialog = false
      this.resetForm()
      this.$refs.updateForm.resetValidation()
    },
    openDeleteDialog (data) {
      this.selectedItem = data
      this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.selectedItem = null
      this.deleteDialog = false
    },
    async addItem () {
      if (this.$refs.addForm) {
        const filteredAccess = this.formData.access.filter(item => item.user_type !== null)
        const payload = filteredAccess.map(item => {
          return {
            ...item,
            module_id: 20,
            access_permission: 'create_access'
          }
        })

        const req = await this.$store.dispatch('users/addUserRole', payload)

        if (req.code === 200) {
          renderToast('success', 'Successful', 'User Role has been added')
          this.getGrid()
          this.closeAddDialog()
        } else if (req.code === 403) {
          renderToast('error', req.message, req.error.message)
        } else {
          renderToast('error', 'Saving Failed', 'Something went wrong while saving this user role, please try again')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please complete the form to save this user role')
      }
    },
    async updateItem () {
      if (this.$refs.updateForm) {
        const payload = this.formData.access.map(item => {
          return {
            ...item,
            module_id: 20,
            access_permission: 'update_access'
          }
        })

        const req = await this.$store.dispatch('users/updateUserRole', payload)

        if (req.code === 200) {
          renderToast('success', 'Successful', 'User Role has been updated')
          this.getGrid()
          this.closeUpdateDialog()
        } else if (req.code === 403) {
          renderToast('error', req.message, req.error.message)
        } else {
          renderToast('error', 'Update Failed', 'Something went wrong while updating this user role, please try again.')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please complete the form to update this user role')
      }
    },
    async deleteItem () {
      const payload = this.selectedItem.access.map(item => {
        return {
          id: item.id,
          user_id: this.currUser.user_id,
          module_id: 20,
          access_permission: 'delete_access'
        }
      })

      const req = await this.$store.dispatch('users/deleteUserRole', payload)

      if (req.code === 200) {
        renderToast('success', 'Successful', 'User Role has been deleted')
        this.getGrid()
        this.closeDeleteDialog()
      } else if (req.code === 403) {
        renderToast('error', req.message, req.error.message)
      } else {
        renderToast('error', 'Delete Failed', 'Something went wrong while deleting this user role, please try again.')
      }
    },
    async getGrid () {
      const req = await this.$store.dispatch('users/getUserRoles', {
        user_id: this.currUser.user_id,
        module_id: 20,
        access_permission: 'view_access'
      })

      if (req.code === 200) {
        this.items = req.result
      } else if (req.code === 403) {
        renderToast('error', req.message, req.error.message)
      }
    },
    getStatusDisplay (status) {
      if (status === 1) return 'Active'
      return 'Inactive'
    }
  },
  beforeMount () {
    this.getGrid()
  }
}
</script>
