export default {
  billers: state => state.billers,
  tpaId: state => state.tpaId,
  peraDaliTransactions: state => state.peraDaliTransactions,
  peraDaliCurrentPage: state => state.peraDaliCurrentPage,
  collectionProviders: state => state.collectionProviders,
  peraDaliResponseData: state => state.peraDaliResponseData,
  peraDaliCurrentTrx: state => state.peraDaliCurrentTrx,
  loanAccountNo: state => state.loanAccountNo
}
