import { endpoints } from '@/api/endpoints'

const replenishBranch = endpoints.use('replenishBranch')

export default {
  retrieveData: ({ commit }, { trxDate, locationId, type }) => new Promise((resolve, reject) => {
    replenishBranch.retrieveData(trxDate, locationId, type)
      .then(({ data }) => {
        commit('setItems', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveData: ({ commit }, payload) => new Promise((resolve, reject) => {
    replenishBranch.saveData(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateData: ({ commit }, payload) => new Promise((resolve, reject) => {
    replenishBranch.updateData(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deleteRecord: ({ commit }, id) => new Promise((resolve, reject) => {
    replenishBranch.deleteRecord(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getBankList: ({ commit }, { locationId }) => new Promise((resolve, reject) => {
    replenishBranch.getBankList(locationId)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
