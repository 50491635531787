<template>
  <div>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <div class="mx-2 mx-md-5">
      <h4 class="mt-3 mt-md-6 mb-5 primary--text">Generate</h4>
      <v-row no-gutters>
        <v-col
          v-for="(data, index) in types"
          :key="index"
          class="py-1 py-md-0 pr-md-2 pb-md-2 mr-5"
          :cols="12"
          :sm="6"
          :md="2"
        >
          <v-card
            @click="selectType(data.value)"
            :class="['rounded-lg py-4 px-3 selection', { 'selected': selectedType === data.value }]"
            outlined
            flat
          >
            <v-card-text>
              <v-row align="center" no-gutters>
                <v-col class="d-flex flex-column align-center" :cols="4">
                  <v-icon class="primary--text" x-large>{{ data.icon }}</v-icon>
                </v-col>
                <v-col class="pl-md-2" :cols="8">
                  <span>{{ data.text }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div v-if="selectedType === 'CUSTOMER'">
        <h4 class="mt-6 mb-5 primary--text">Customer Look-Up</h4>
        <v-row>
          <v-col :cols="12" :md="4">
            <v-form ref="customerSearch">
              <v-text-field
                v-model="formData.firstName"
                class="ph-textbox mb-5 mb-md-6"
                label="First Name"
                @input="convertToProperCase('firstName')"
                @keyup.enter="doSearchCustomer"
                :rules="requiredRules"
                outlined
              />
              <v-text-field
                v-model="formData.lastName"
                class="ph-textbox mb-5 mb-md-6"
                label="Last Name"
                @input="convertToProperCase('lastName')"
                @keyup.enter="doSearchCustomer"
                :rules="requiredRules"
                outlined
              />
            </v-form>
            <v-btn
              @click="doSearchCustomer"
              class="rounded-lg"
              color="primary"
              large
            >
              Search
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-if="selectedType === 'TRANSACTIONS'">
        <h4 class="mt-6 mb-5 primary--text">Transactions Look-Up</h4>
        <v-form ref="transactionSearch">
          <v-row>
            <v-col :cols="12" :md="4">
              <v-select
                v-model="formData.filterBy"
                :items="filters"
                class="ph-textbox mb-5 mb-md-6"
                label="Filter By"
                :rules="requiredRules"
                outlined
              />
              <v-text-field
                v-model="formData.clientName"
                @input="convertToProperCase('clientName')"
                class="ph-textbox mb-5 mb-md-6"
                label="Customer Name"
                hint="Follow this format: Lastname, Firstname"
                :rules="requiredRules"
                outlined
                v-if="formData.filterBy === 'customer'"
              />
              <v-select
                v-model="formData.transactionType"
                :items="transactionTypes"
                class="ph-textbox mb-5 mb-md-6"
                label="Transaction Type"
                :rules="requiredRules"
                outlined
                v-if="formData.filterBy === 'type'"
              />
              <v-menu
                v-if="formData.filterBy === 'type' || formData.filterBy === 'date'"
                v-model="showDateFromPicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.dateFrom"
                    class="ph-textbox mb-5 mb-md-6"
                    label="Date From"
                    :rules="requiredRules"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    readonly
                  />
                </template>
                <v-date-picker
                  v-model="formData.dateFrom"
                  @input="showDateFromPicker = false"
                />
              </v-menu>
              <v-menu
                v-if="formData.filterBy === 'type' || formData.filterBy === 'date'"
                v-model="showDateToPicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.dateTo"
                    class="ph-textbox mb-5 mb-md-6"
                    label="Date To"
                    :rules="requiredRules"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    readonly
                  />
                </template>
                <v-date-picker
                  v-model="formData.dateTo"
                  @input="showDateToPicker = false"
                />
              </v-menu>
              <v-text-field
                v-model="formData.referenceNumber"
                class="ph-textbox mb-5 mb-md-6"
                label="Reference Number"
                :rules="requiredRules"
                outlined
                v-if="formData.filterBy === 'reference'"
              />
              <v-text-field
                v-model="formData.user"
                class="ph-textbox mb-5 mb-md-6"
                label="Employee Number"
                :rules="requiredRules"
                outlined
                v-if="formData.filterBy === 'user'"
              />
              <v-text-field
                v-model="formData.approver"
                class="ph-textbox mb-5 mb-md-6"
                label="Employee Number"
                :rules="requiredRules"
                outlined
                v-if="formData.filterBy === 'approver'"
              />
              <v-select
                v-model="formData.screening"
                :items="sanctionScreenings"
                class="ph-textbox mb-5 mb-md-6"
                label="Sanction Screening"
                :rules="requiredRules"
                outlined
                v-if="formData.filterBy === 'screening'"
              />
              <v-btn
                @click="doSearchTransactions"
                class="rounded-lg"
                color="primary"
                large
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <v-dialog v-model="isSearchResultShown" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="60%">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            Customer Search Results
          </h3>
          <v-spacer />
          <v-btn @click="isSearchResultShown = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select Customer</strong>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                class="ph-textbox rounded-lg"
                placeholder="Search"
                outlined
              />
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectCustomer"
            :headers="headers"
            :items="searchData.results"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No customers found"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ toProperCase(item.gender) || '-' }}
            </template>
            <template v-slot:[`item.mobile_no`]="{ item }">
              {{ item.mobile_no || '-' }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isUserSearchResultShown" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="60%">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            User Search Results
          </h3>
          <v-spacer />
          <v-btn @click="isUserSearchResultShown = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select User</strong>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                class="ph-textbox rounded-lg"
                placeholder="Search"
                outlined
              />
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectUser"
            :headers="userHeaders"
            :items="userSearchResult"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No users found"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(`${item.first_name} ${item.last_name}`)">
                <span class="white--text text-body-2">{{ avatarInitial(`${item.first_name} ${item.last_name}`) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarInitial,
  avatarColor,
  toProperCase,
  renderToast,
  trim
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      searchData: 'customers/searchData',
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      isUserSearchResultShown: false,
      userSearchResult: [],
      searchTransactionPayload: {
        query: { order: 'desc', offset: 0, limit: 10 }
      },
      showDateFromPicker: false,
      showDateToPicker: false,
      searchText: '',
      isSearchResultShown: false,
      selectedType: 'CUSTOMER',
      formData: {},
      transactionTypes: [
        { text: 'Sendout', value: 'SO' },
        { text: 'Payout', value: 'PO' },
        { text: 'MC - Buy', value: 'BUY' },
        { text: 'MC - Sell', value: 'SELL' }
      ],
      sanctionScreenings: [
        { text: 'No Hit', value: 'No Hit' },
        { text: 'Hit - False Positive', value: 'Hit - False Positive' },
        { text: 'Hit - PEP', value: 'Hit - PEP' },
        { text: 'Hit - Fraudster', value: 'Hit - Fraudster' },
        { text: 'Hit - Negative List', value: 'Hit - Negative List' }
      ],
      filters: [
        { text: 'By Date', value: 'date' },
        { text: 'By Transaction Type', value: 'type' },
        { text: 'By Reference Number', value: 'reference' },
        { text: 'By Customer', value: 'customer' },
        { text: 'By User', value: 'user' },
        { text: 'By Approver', value: 'approver' }
      ],
      headers: [
        {
          text: 'Customer No.',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'mobile_no'
        }
      ],
      userHeaders: [
        {
          text: 'Name',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Email Address',
          sortable: false,
          value: 'email_address'
        },
        {
          text: 'User Role',
          sortable: true,
          value: 'user_type'
        }
      ],
      breadcrumbsItems: [
        {
          text: 'Compliance Module',
          disabled: false,
          exact: true,
          to: '/compliance'
        },
        {
          text: 'Audit Trail',
          disabled: true
        }
      ],
      types: [
        { text: 'Customer', value: 'CUSTOMER', icon: 'mdi-account' },
        { text: 'Transactions', value: 'TRANSACTIONS', icon: 'mdi-receipt' }
      ],
      requiredRules: [v => !!v || 'This field is required']
    }
  },
  methods: {
    selectUser (data) {
      if (this.formData.filterBy === 'user') {
        this.searchTransactionPayload = {
          filterBy: this.formData.filterBy,
          userName: `${data.first_name} ${data.last_name}`,
          query: { ...this.searchTransactionPayload.query, processed_by: data.user_id }
        }
      }

      if (this.formData.filterBy === 'approver') {
        this.searchTransactionPayload = {
          filterBy: this.formData.filterBy,
          userName: `${data.first_name} ${data.last_name}`,
          query: { ...this.searchTransactionPayload.query, approved_by: data.user_id }
        }
      }

      this.$store.commit('compliance/SET_AUDIT_TRAIL_DATA', { type: 'trx', payload: this.searchTransactionPayload })
      this.$store.commit('compliance/SET_CURRENT_PAGE', 1)
    },
    async doSearchTransactions () {
      if (this.$refs.transactionSearch.validate()) {
        const { filterBy } = this.formData

        if (filterBy === 'customer') {
          const clientName = this.formData.clientName.replace(/(^\s+|\s+$)/g, '')

          this.searchData.results = []
          this.$store.dispatch('customers/doSearch', clientName)
          this.isSearchResultShown = true
        } else if (filterBy === 'user' || filterBy === 'approver') {
          const { code, result } = await this.$store.dispatch('users/searchUser', {
            search: this.formData.user || this.formData.approver,
            user_id: this.currUser.user_id,
            module_id: 28,
            access_permission: 'view_access'
          })

          if (code === 200) {
            this.userSearchResult = result
            this.isUserSearchResultShown = true
          }
        } else {
          this.searchTransactionPayload = {
            filterBy,
            query: { order: 'desc', offset: 0, limit: 10 }
          }

          if (filterBy === 'type') {
            const [{ text: trxType }] = this.transactionTypes.filter(item => item.value === this.formData.transactionType)

            this.searchTransactionPayload.transactionType = trxType
            this.searchTransactionPayload.query = {
              ...this.searchTransactionPayload.query,
              transaction_type: this.formData.transactionType,
              date_from: this.formData.dateFrom,
              date_to: this.formData.dateTo
            }
          }

          if (filterBy === 'date') {
            this.searchTransactionPayload.query = {
              ...this.searchTransactionPayload.query,
              date_from: this.formData.dateFrom,
              date_to: this.formData.dateTo
            }
          }

          if (filterBy === 'reference') {
            this.searchTransactionPayload.query = {
              ...this.searchTransactionPayload.query,
              reference_no: this.formData.referenceNumber
            }
          }

          if (filterBy === 'screening') {
            this.searchTransactionPayload.query = {
              ...this.searchTransactionPayload.query,
              sanction_screening: this.formData.screening
            }
          }

          this.$store.commit('compliance/SET_AUDIT_TRAIL_DATA', { type: 'trx', payload: this.searchTransactionPayload })
          this.$store.commit('compliance/SET_CURRENT_PAGE', 1)
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill up the required fields')
      }
    },
    selectCustomer (data) {
      this.isSearchResultShown = false
      if (this.selectedType === 'CUSTOMER') {
        this.$store.commit('compliance/SET_AUDIT_TRAIL_DATA', {
          type: 'cust',
          payload: {
            customerId: data.customer_id,
            customerName: `${data.first_name} ${data.last_name}`,
            query: {
              order: 'desc',
              offset: 0,
              limit: 10
            }
          }
        })
        this.$store.commit('compliance/SET_CURRENT_PAGE', 1)
      } else if (this.selectedType === 'TRANSACTIONS') {
        this.searchTransactionPayload = {
          filterBy: 'customer',
          customerName: `${data.first_name} ${data.last_name}`,
          query: { ...this.searchTransactionPayload.query, customer_id: data.customer_id }
        }

        this.$store.commit('compliance/SET_AUDIT_TRAIL_DATA', { type: 'trx', payload: this.searchTransactionPayload })
        this.$store.commit('compliance/SET_CURRENT_PAGE', 1)
      }
    },
    doSearchCustomer () {
      if (this.$refs.customerSearch.validate()) {
        const firstName = this.formData.firstName.replace(/(^\s+|\s+$)/g, '')
        const lastName = this.formData.lastName.replace(/(^\s+|\s+$)/g, '')
        const keyword = `${lastName},${firstName}`

        this.searchData.results = []
        this.$store.dispatch('customers/doSearch', keyword)
        this.isSearchResultShown = true
      } else {
        this.searchData.results = []
        renderToast('warning', 'Validation Error', 'Please fill up the required fields')
      }
    },
    selectType (type) {
      this.selectedType = type
    },
    convertToProperCase (field) {
      this.formData[field] = toProperCase(trim(this.formData[field]))
    },
    avatarInitial,
    toProperCase,
    avatarColor
  },
  mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Audit Trail')
  }
}
</script>
