<template>
  <div>
    <v-row class="mt-md-5" no-gutters>
      <v-col :cols="12" :md="4">
        <h3 class="pb-md-6 custom-heading primary--text">
          Account Lookup
        </h3>
        <p class="text-body-2">
          Please enter your Loan Account Number to continue
        </p>
      </v-col>
      <v-col class="pl-md-6" :cols="12" :md="8">
        <h4 class="mb-5 grey--text text--darken-2">
          Loan Number
        </h4>
        <v-text-field
          v-model="accountNumber"
          class="ph-textbox mb-md-6 ph-textbox-loan-collections"
          placeholder="Account Number"
          @keyup.enter="doSearch"
          outlined
        />
        <div class="text-right">
          <v-btn @click="doSearch" class="rounded-lg ph-search-customer" color="primary" large>
            Search
          </v-btn>
        </div>
        <div class="ph-wu-fulfill-search-multiple" v-if="accountList.length > 1">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <b>Name</b>
                  </th>
                  <th class="text-left">
                    <b>Source</b>
                  </th>
                  <th class="text-left">
                    <b>Action</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in accountList"
                  :key="item.name"
                >
                  <td>
                    <div class="ph-pay-status-sender-container">
                      <v-avatar class="mr-1 ph-pay-status-status-circle" size="25px" :style="avatarColor(item.first)">
                        <span class="white--text text-body-2">{{ avatarInitial(item.first) }}</span>
                      </v-avatar>
                      <p class="ph-pay-status-table-names-text">{{item.last}}, {{item.first}} {{item.middle ? item.middle : ''}}</p>
                    </div>
                  </td>
                  <td>{{ item.source }}</td>
                  <td>
                    <v-btn
                      @click="selectAccount(item)"
                      color="primary"
                      rounded
                      elevation="0"
                      class="ph-action-button"
                    >Select Account</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="resultDialog" persistent :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '60%'">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            PERA Hub Customer Results
          </h3>
          <v-spacer />
          <v-btn @click="resultDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p><b>Search results for: {{firstName}} {{lastName}}</b></p>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <p>Select a Customer</p>
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectCustomer"
            :headers="headers"
            :items="searchData.results"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No customers found"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ toProperCase(item.gender) || '-' }}
            </template>
            <template v-slot:[`item.mobile_no`]="{ item }">
              {{ item.mobile_no || '-' }}
            </template>
          </v-data-table>
          <div class="d-flex align-center customer-modal-actions">
            <v-spacer v-if="(!$vuetify.breakpoint.xs || !$vuetify.breakpoint.sm)" />
            <span class="customer-modal-hint">Can't find customer here?</span>
            <v-btn
              @click="addNewCustomer"
              color="primary"
              class="ph-loanc-new-customer-btn"
            >
              Register New Customer
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Select {{currentAccountData.first}} {{currentAccountData.last}} ({{currentAccountData.source}}) ?
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to select this account?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeConfirmDialog"
          >
            <b>No</b>
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="confirmSelectAccount"
          >
            <b>Yes</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getForeignRefNo,
  avatarColor,
  avatarInitial,
  toProperCase,
  trim,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      searchData: 'customers/searchData',
      selectedCustomer: 'customers/selectedCustomer'
    })
  },
  data () {
    return {
      accountNumber: '',
      firstName: '',
      lastName: '',
      resultDialog: false,
      confirmDialog: false,
      searchText: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      showRemoteNotice: false,
      headers: [
        {
          text: 'Customer No.',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'mobile_no'
        }
      ],
      accountList: [],
      currentAccountData: {}
    }
  },
  watch: {
    selectedCustomer: {
      handler (data) {
        if (data) {
          this.renderToast('info', 'Customer Selected!', `${data.full_name} has been successfully selected.`)
          this.$store.commit('loanCollections/SET_LOAN_COLLECTION_CURRENT_PAGE', 1)
        } else {
          this.$store.commit('loanCollections/SET_LOAN_COLLECTION_CURRENT_PAGE', 0)
        }
      },
      deep: true
    }
  },
  methods: {
    renderToast,
    addNewCustomer () {
      this.resultDialog = false
      this.$store.commit('customers/SET_CUSTOMER_CREATION_MODAL', true)
    },
    convertToProperCase (field) {
      this[field] = toProperCase(trim(this[field]))
    },
    async selectCustomer (customerDetails) {
      // Checking of available offer for the customer
      const { code, result } = await this.$store.dispatch('prompt/checkAvailableOffer', {
        customer_number: customerDetails.customer_number,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id
      })

      if (code === 200 && result) {
        this.$store.commit('prompt/SET_AVAILABLE_OFFER', result)
        this.$store.commit('prompt/SET_SHOW_OFFER_DIALOG', true)
      }

      const foreignRefNo = getForeignRefNo()

      this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)

      const updateState = [
        {
          prop: 'senderFirstName',
          value: customerDetails.first_name
        },
        {
          prop: 'senderMiddleName',
          value: customerDetails.middle_name
        },
        {
          prop: 'senderLastName',
          value: customerDetails.last_name
        },
        {
          prop: 'senderBirthDate',
          value: customerDetails.birth_date
        },
        {
          prop: 'refNo',
          value: foreignRefNo
        }
      ]
      updateState.map((payload) => this.$store.commit('send/SET_TRANSACTION_DATA', payload))
    },
    async doSearch () {
      const accountSearchPayload = {
        account_number: this.accountNumber
      }

      this.$store.dispatch('loanCollections/loanAccountLookup', accountSearchPayload).then(res => {
        if (res.result.message === 'Account Number does not exist!' || res.result.name.length < 1) {
          this.renderToast('error', 'Search Error', res.result.message)
        } else {
          this.accountList = res.result.name

          if (this.accountList.length < 2) {
            const accountName = res.result.name[0]

            this.firstName = accountName.first
            this.lastName = accountName.last

            if (this.firstName && this.lastName) {
              this.customerSearch(this.lastName, this.firstName)
            } else {
              this.searchData.results = []
              this.renderToast('error', 'Search Error', 'The required fields must be completed.')
            }
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    customerSearch (lastName, firstName) {
      this.firstName = firstName.replace(/(^\s+|\s+$)/g, '')
      this.lastName = lastName.replace(/(^\s+|\s+$)/g, '')
      const keyword = `${this.lastName}, ${this.firstName}`

      this.searchData.results = []
      this.$store.dispatch('customers/doSearch', keyword)
      this.$store.commit('send/SET_CURRENT_STEP', 1)
      this.resultDialog = true
    },
    closeConfirmDialog () {
      this.currentAccountData = {}
      this.confirmDialog = false
    },
    selectAccount (item) {
      this.currentAccountData = item
      this.confirmDialog = true
    },
    confirmSelectAccount () {
      this.confirmDialog = false
      const currentAccount = this.currentAccountData
      this.$store.commit('loanCollections/SET_LOAN_ACCOUNT_DATA', currentAccount)
      this.customerSearch(currentAccount.last, currentAccount.first)
    },
    toProperCase,
    avatarColor,
    avatarInitial
  },
  async mounted () {
    this.$store.commit('loanCollections/SET_LOAN_ACCOUNT_DATA', {})

    const tpaPayload = {
      bill_id: 2,
      location_id: this.currUser.location_id
    }

    let tpaId = ''

    await this.$store.dispatch('loanCollections/getTPARequest', tpaPayload)
      .then(res => {
        tpaId = res.tpa_id
        this.$store.commit('loanCollections/SET_LOAN_COLLECTION_TPA_ID', tpaId)
      }).catch(e => {
        console.log(e)
      })

    this.$store.commit('loanCollections/SET_LOAN_COLLECTION_CURRENT_PAGE', 0)
    this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)

    await this.$store.dispatch('getIDList', 'wu')
  }
}
</script>
<style scoped>
  .ph-pay-status-sender-container,
  .ph-pay-status-receiver-container {
    display: flex;
  }

  .ph-pay-status-table-avatar {
    margin: auto 0;
  }

  .ph-pay-status-table-names-text {
    margin: auto;
    margin-left: 10px;
  }

  .ph-pay-status-status-circle {
    height: 10px;
    width: 10px;
    background: #f5f5f5;
    border-radius: 50%;
    margin: auto 0;
  }

  /* .v-btn.ph-action-button {
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    background: #1A2791 !important;
    color: #fff;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 700;
  } */

  .ph-textbox-loan-collections {
    margin-bottom: 16px !important;
  }

  .customer-modal-actions {
    flex-direction: column;
  }

  @media only screen and (min-width: 600px) {
    .ph-loanc-new-customer-btn {
      margin-left: 15px;
    }
  }

  @media only screen and (min-width: 960px) {
    .customer-modal-actions {
      flex-direction: row;
    }
  }
</style>
