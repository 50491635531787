<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container" v-if="Object.keys(reportFilterParams).length === 0">
      <v-row dense v-if="reportCategories.length > 0">
        <v-col v-for="reportCategory in reportCategories" :key="reportCategory.id" cols="12" sm="6" md="6" lg="3">
          <v-card class="ph-nonex-button mt-5" elevation="0">
            <div class="ph-nonex-button-text-container">
              <h1 class="ph-nonex-button-title">{{reportCategory.title}}</h1>
              <!-- <p class="ph-nonex-button-description">{{reportCategory.description}}</p> -->
              <div class="ph-nonex-button-reptypes">
                <v-btn :to="`/reports/${item.rpt_value}`" rounded dark class="ph-report-type" text v-for="item in reportCategory.description" :key="item.rpt_value">
                  {{item.rpt_name}}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense v-if="reportCategories.length < 1">
        <v-col  cols="12" sm="6" md="6" lg="3" v-for="i in 5" :key="i">
          <v-card v-ripple class="ph-nonex-button mt-5 pa-6" elevation="0">
            <v-skeleton-loader
              type="heading"
            ></v-skeleton-loader>
            <br>
            <v-skeleton-loader
              type="text@3"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
      <no-signature-dialog />
    </v-container>
  </div>
</template>
<script>
import { renderToast } from '@/utils'
import noSignatureDialog from '@/components/wacom/noSignatureDialog.vue'

export default {
  name: 'Reports',
  components: {
    noSignatureDialog
  },
  data () {
    return {
      reportType: '',
      provider: '',
      regionalHead: '',
      status: '',
      productType: '',
      providerList: [],
      regionalHeadList: [],
      statusList: [
        { name: 'ALL', value: 'ALL' },
        { name: 'APPROVED', value: 'APPROVED' },
        { name: 'CANCELLED', value: 'CANCELLED' },
        { name: 'POSTED', value: 'POSTED' }
      ],
      reportCategories: [],
      productTypeList: [],
      dateFrom: '',
      dateTo: '',
      dateFromPicker: false,
      dateToPicker: false,
      activeDateFromPicker: null,
      activeDateToPicker: null,
      showProgressDialog: false,
      setPercentage: 0,
      setDescription: '',
      reportFilterParams: {},
      setColor: '#1A2791',
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  created () {
    this.showProgressDialog = true
    this.updateProgress(0, 'Loading Categories. Please wait...', '#FFCA28')
  },
  async mounted () {
    const that = this

    await this.$store.dispatch('report/getRptTypes').then(res => {
      console.log(res)
      if (!(res.code >= 200 && res.code <= 299)) {
        this.renderToast('error', 'Error', res.message)
      } else {
        Object.keys(res.result).forEach(function (key, index) {
          // let currentDescription = ''
          // res.result[key].forEach(repType => {
          //   currentDescription += repType.rpt_name + '\n\n\n'
          // })

          that.reportCategories.push({
            id: index + 1,
            title: key,
            color: 'rgba(253, 209, 0, 0.1)',
            description: res.result[key],
            image: 'nonex-send-icon',
            to: '#'
          })
        })

        that.showProgressDialog = false
      }
    })
  },
  methods: {
    renderToast,
    updateProgress (percentage, description, color) {
      this.setPercentage = percentage
      this.setDescription = description
      this.setColor = color
    },
    syncDateFrom (date) {
      this.$refs.dateFromPicker.save(date)
    },
    syncDateTo (date) {
      this.$refs.dateToPicker.save(date)
    },
    renderFilters (reportTypes) {
      console.log(reportTypes)
      const availRpt = [
        'digital_services_promo_rpt',
        'cdcr',
        'comp_aging_rpt',
        'dtrs'
      ]

      if (availRpt.includes(reportTypes.rpt_value)) {
        this.reportFilterParams = reportTypes
      } else {
        this.renderToast('error', 'Report not yet available on USP 2.0', 'This module will become available soon. Please use USP 1.0 for the time being.')
      }
    }
  }
}
</script>
<style scoped>
  .ph-container {
    margin: 0 15px;
  }

  .v-card.ph-nonex-button {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
    /* border: 1px solid #D0D0D0; */
  }

  .ph-nonex-button-text-container {
    width: 100%;
  }

  .ph-nonex-button-title {
    font-family: "Proxima Nova Bold";
    color: #1A2791;
    font-size: 24px;
    padding: 30px;
    padding-bottom: 0;
  }

  .ph-nonex-button-icon-container {
    padding: 30px;
    width: 100%;
    display: flex;
    padding-bottom: 0;
    /* margin-right: 20px !important; */
  }

  .ph-nonex-button-description {
    font-size: 16px;
  }

  /* .ph-nonex-button-icon {
    margin: auto;
  } */

  .ph-nonex-button-icon-img {
    margin: auto;
    opacity: 1 !important;
    transform: scale(0.35);
    border-radius: 0;
  }

  .ph-report-type::before {
    color: transparent
  }

  .ph-report-type {
    text-transform: initial;
    letter-spacing: normal;
    color: #000 !important;
  }

  .ph-report-type:hover {
    background: #FCF2C9;
  }

  .ph-nonex-button-reptypes {
    padding-top: 0;
    padding: 13px;
    display: table-caption;
  }

  @media only screen and (min-width: 500px) {
    .v-card.ph-nonex-button {
      display: flex;
      box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
      border-radius: 12px;
      height: 100%;
    }

    .ph-nonex-button:nth-of-type(odd) {
      margin-left: 5px;
    }

    .ph-nonex-button:nth-of-type(even) {
      margin-right: 5px;
    }

    .ph-nonex-button-text-container {
      width: 80%;
    }

    .ph-nonex-button-icon-container {
      width: 20%;
      /* margin-right: unset !important; */
    }

    .ph-nonex-button-icon-img {
      margin: auto;
      opacity: 1 !important;
      transform: scale(0.4);
    }
  }

  @media only screen and (min-width: 376px) {
    .ph-nonex-container {
      margin: 0;
    }
  }
</style>
