import { endpoints } from '@/api/endpoints'

const loans = endpoints.use('loans')

export default {
  getTPARequest: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.getTPARequest(payload)
      .then(({ data }) => {
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getPeraLoanTrx: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.getPeraLoanTrx(payload)
      .then(({ data }) => {
        commit('SET_PERA_LOAN_TRANSACTIONS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  executePeraLoanApplication: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.saveCustomerInfo(payload)
      .then(({ data }) => {
        commit('SET_PERA_LOAN_RESPONSE_DATA', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  executeLoanPensionApplication: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.saveCustomerPensionInfo(payload)
      .then(({ data }) => {
        commit('SET_PERA_LOAN_RESPONSE_DATA', data)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  saveLoanInfo: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.saveLoanInfo(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  saveDisbursement: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.saveDisbursement(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  generatePeraLoanVoucher: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.generatePeraLoanVoucher(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getBanks: ({ commit }, payload) => new Promise((resolve, reject) => {
    loans.getBanks(payload)
      .then(({ data }) => {
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
