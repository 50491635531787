export default {
  SET_DEBIT_CARD_INVENTORY (state, payload) {
    state.cardInventory = payload
  },
  SET_DEBIT_CARD_TRANSACTIONS (state, payload) {
    state.debitCardTransactions = payload
  },
  SET_DEBIT_CARD_CURRENT_TRX (state, payload) {
    state.debitCardCurrentTrx = payload
  },
  SET_DEBIT_CARD_CURRENT_PAGE (state, payload) {
    state.debitCardCurrentPage = payload
  },
  SET_DEBIT_CARD_PARTNERS (state, payload) {
    state.debitCardPartners = payload
  },
  SET_EON_SOURCE_OF_FUND (state, payload) {
    state.eonSourceOfFund = payload
  },
  SET_EON_EMPLOYMENT (state, payload) {
    state.eonEmployment = payload
  },
  SET_EON_NATURE_OF_WORK (state, payload) {
    state.eonNatureOfWork = payload
  },
  SET_EON_ID_LIST (state, payload) {
    state.eonIDList = payload
  },
  SET_IS_CUSTOMER_HIT (state, payload) {
    state.isCustomerHit = payload
  },
  SET_SHOW_SPIEL (state, payload) {
    state.showSpiel = payload
  }
}
