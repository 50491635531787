(function () {
  if (typeof CanvasRenderingContext2D.prototype.fillJustifyText !== 'function') {
    throw new ReferenceError('Justified Paragraph extension missing required CanvasRenderingContext2D justified text extension')
  }
  var maxSpaceSize = 3
  var minSpaceSize = 0.5
  var compact = true
  var lineSpacing = 1.5
  const noJustifySetting = {
    minSpaceSize: 1,
    maxSpaceSize: 1
  }

  var justifiedTextSettings = function (settings) {
    var min, max
    var vetNumber = (num, defaultNum) => {
      num = num !== null && num !== null && !isNaN(num) ? num : defaultNum
      return num < 0 ? defaultNum : num
    }
    if (settings === undefined || settings === null) { return }
    compact = settings.compact === true ? true : settings.compact === false ? false : compact
    max = vetNumber(settings.maxSpaceSize, maxSpaceSize)
    min = vetNumber(settings.minSpaceSize, minSpaceSize)
    lineSpacing = vetNumber(settings.lineSpacing, lineSpacing)
    if (min > max) { return }
    minSpaceSize = min
    maxSpaceSize = max
  }
  var getFontSize = function (font) {
    var numFind = /[0-9]+/
    var number = numFind.exec(font)[0]
    if (isNaN(number)) {
      throw new ReferenceError('justifiedPar Cant find font size')
    }
    return Number(number)
  }
  function justifiedPar (ctx, text, x, y, width, settings, stroke) {
    var spaceWidth, minS, maxS, words, count, lines, lineWidth, lastLineWidth, lastSize, i, renderer, fontSize, adjSpace, spaces, word, lineWords, lineFound // eslint-disable-line
    spaceWidth = ctx.measureText(' ').width
    minS = spaceWidth * minSpaceSize
    maxS = spaceWidth * maxSpaceSize
    words = text.split(' ').map(word => {
      var w = ctx.measureText(word).width
      return {
        width: w,
        word: word
      }
    })
    count = 0
    lines = []
    while (words.length > 0) {
      lastLineWidth = 0
      lastSize = -1
      lineFound = false
      word = words.shift()
      lineWidth = word.width
      lineWords = [word.word]
      count = 0
      while (lineWidth < width && words.length > 0) {
        word = words.shift()
        lineWidth += word.width
        lineWords.push(word.word)
        count += 1
        spaces = count - 1
        adjSpace = (width - lineWidth) / spaces
        if (minS > adjSpace) {
          lineFound = true
          words.unshift(word)
          lineWords.pop()
        } else {
          if (!compact) {
            if (adjSpace < spaceWidth) {
              if (lastSize === -1) {
                lastSize = adjSpace
              }
              if (Math.abs(spaceWidth - adjSpace) < Math.abs(spaceWidth - lastSize)) {
                lineFound = true
              } else {
                words.unshift(word)
                lineWords.pop()
                lineFound = true
              }
            }
          }
        }
        lastSize = adjSpace
      }
      lines.push(lineWords.join(' '))
    }
    fontSize = getFontSize(ctx.font)
    renderer = stroke === true ? ctx.strokeJustifyText.bind(ctx) : ctx.fillJustifyText.bind(ctx)
    for (i = 0; i < lines.length - 1; i++) {
      renderer(lines[i], x, y, width, settings)
      y += lineSpacing * fontSize
    }
    if (lines.length > 0) {
      if (ctx.textAlign === 'left' || ctx.textAlign === 'start') {
        renderer(lines[lines.length - 1], x, y, width, noJustifySetting)
        ctx.measureJustifiedText('', width, settings)
      } else {
        renderer(lines[lines.length - 1], x, y, width)
      }
    }
    y += lineSpacing * fontSize
    return {
      nextLine: y,
      fontSize: fontSize,
      lineHeight: lineSpacing * fontSize
    }
  }
  var fillParagraphText = function (text, x, y, width, settings) {
    justifiedTextSettings(settings)
    settings = {
      minSpaceSize: minSpaceSize,
      maxSpaceSize: maxSpaceSize
    }
    return justifiedPar(this, text, x, y, width, settings)
  }
  var strokeParagraphText = function (text, x, y, width, settings) {
    justifiedTextSettings(settings)
    settings = {
      minSpaceSize: minSpaceSize,
      maxSpaceSize: maxSpaceSize
    }
    return justifiedPar(this, text, x, y, width, settings, true)
  }
  CanvasRenderingContext2D.prototype.fillParaText = fillParagraphText
  CanvasRenderingContext2D.prototype.strokeParaText = strokeParagraphText
})()
