<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col class="mr-2" :cols="12" :md="2">
        <v-text-field
          v-model="firstName"
          class="ph-textbox"
          placeholder="Firstname"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="mr-2" :cols="12" :md="2">
        <v-text-field
          v-model="lastName"
          class="ph-textbox"
          placeholder="Lastname"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="d-flex align-center mr-2" :cols="12" :md="4">
        <v-btn
          @click="searchCustomer"
          color="primary"
          elevation="0"
          class="rounded-lg mr-2"
          x-large
        >
          Search
        </v-btn>
        <v-btn
          @click="clearSearchResults"
          color="primary"
          elevation="0"
          class="rounded-lg mr-2"
          :disabled="searchResults.length === 0"
          x-large
          outlined
        >
          Clear
        </v-btn>
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-8" :cols="12">
        <v-data-table
          :headers="headers"
          :items="itemsToDisplay"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:[`item.customer_name`]="{ item }">
            <v-avatar class="mr-1" size="26px" :style="avatarColor(item.customer_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.customer_name) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              @click="viewItem(item)"
              color="primary"
              icon
            >
              <v-icon color="primary" style="transform: rotate(-25deg);">mdi-send</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarInitial,
  toProperCase,
  avatarColor,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    }),
    itemsToDisplay () {
      if (this.searchResults.length > 0) {
        return this.searchResults
      }

      return this.items
    }
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      searchResults: [],
      breadcrumbsItems: [
        {
          text: 'Compliance Module',
          disabled: false,
          exact: true,
          to: '/compliance'
        },
        {
          text: 'Compliance Monitoring',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customer_name',
          width: '180px'
        },
        {
          text: 'Monitoring Type',
          sortable: true,
          value: 'monitoring_type',
          width: '120px'
        },
        {
          text: 'Risk Score',
          sortable: true,
          value: 'risk_score',
          width: '120px'
        },
        {
          text: 'Risk Type',
          sortable: true,
          value: 'risk_score_name',
          width: '120px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '120px'
        },
        {
          text: 'Remarks',
          sortable: false,
          value: 'remarks',
          width: '120px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '80px'
        }
      ],
      items: []
    }
  },
  methods: {
    async searchCustomer () {
      if (this.firstName && this.lastName) {
        const payload = {
          search_by: 'name',
          first_name: this.firstName,
          last_name: this.lastName
        }
        const { code, result } = await this.$store.dispatch('compliance/searchByCustomer', payload)

        if (code === 200) {
          this.searchResults = result.map(item => {
            return {
              ...item,
              customer_name: `${item.first_name} ${item.last_name}`,
              remarks: item.fp_remarks
            }
          })
        } else {
          renderToast('error', 'Failed', 'An error occured while trying to search the customer')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill out all required fields')
      }
    },
    clearSearchResults () {
      this.firstName = ''
      this.lastName = ''
      this.searchResults = []
    },
    viewItem (item) {
      this.$store.commit('compliance/UPDATE_SELECTED_MONITORING_ITEM', item)
      this.$router.push(`/compliance/monitoring/${item.customer_id}`)
    },
    async retrieveItems () {
      const that = this
      try {
        const { code, result } = await this.$store.dispatch('compliance/retrieveComplianceMonitoring', that.currUser.user_name)

        if (code === 200) {
          this.items = result.map(item => {
            return {
              ...item,
              customer_name: `${item.first_name} ${item.last_name}`,
              remarks: item.fp_remarks
            }
          })
        } else {
          renderToast('error', `[${code}] Error`, 'Failed to retrieve grid')
        }
      } catch (e) {
        renderToast('error', 'USP Error', 'Failed to retrieve grid')
      }
    },
    avatarInitial,
    toProperCase,
    avatarColor
  },
  mounted () {
    this.retrieveItems()
  }
}
</script>
