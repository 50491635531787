import { endpoints } from '@/api/endpoints'

const send = endpoints.use('send')

export default {
  feeInquiry: ({ commit }, payload) => new Promise((resolve, reject) => {
    const queries = Object.entries(payload).map(item => {
      if (typeof item[1] === 'object') {
        if (item[1].length > 0) {
          const messages = []

          item[1].forEach((data) => {
            messages.push(`message[]=${data}`)
          })
          return messages.join('&')
        } else {
          return 'message[]='
        }
      } else {
        return item.join('=')
      }
    })
    send.feeInquiry(queries)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  kycLookup: ({ commit }, payload) => new Promise((resolve, reject) => {
    send.kycLookup(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  sendValidate: ({ commit }, payload) => new Promise((resolve, reject) => {
    send.sendValidate(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  sendStore: ({ commit }, payload) => new Promise((resolve, reject) => {
    send.sendStore(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  feeInquiryV2: ({ commit }, payload) => new Promise((resolve, reject) => {
    const queries = Object.entries(payload).map(item => {
      if (typeof item[1] === 'object') {
        if (item[1].length > 0) {
          const messages = []

          item[1].forEach((data) => {
            messages.push(`message[]=${data}`)
          })
          return messages.join('&')
        } else {
          return 'message[]='
        }
      } else {
        return item.join('=')
      }
    })
    send.feeInquiryV2(queries)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  sendValidateV2: ({ commit }, payload) => new Promise((resolve, reject) => {
    send.sendValidateV2(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  sendStoreV2: ({ commit }, payload) => new Promise((resolve, reject) => {
    send.sendStoreV2(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  resetForm ({ state, commit }) {
    commit('SET_CURRENT_PAGE', 0)
    commit('SET_CURRENT_STEP', 1)
    commit('SET_RECEIVER_LIST', [])
    commit('SET_FEE_INQUIRY_DATA', null)
    commit('SET_KYC_LOOKUP_DATA', null)
    commit('SET_VALIDATE_DATA', null)
    commit('SET_STORE_DATA', null)

    state.remcoList.forEach(item => {
      commit('SET_REMCO_FEE', {
        id: item.id,
        serviceFee: '',
        body: null
      })
    })

    Object.keys(state.transactionData).forEach(prop => {
      let value = ''

      if (prop === 'destinationCountryID') value = ''
      if (prop === 'destinationCountryCode') value = 'PH'
      if (prop === 'destinationCountryCurrency') value = 'PHP'
      if (prop === 'destinationCountryName') value = 'Philippines'
      if (prop === 'message') value = []
      if (prop === 'deliveryService') value = 'MIM'
      if (prop === 'isOnBehalf') value = 'N'
      if (prop === 'searchBy') value = 'by_id'
      if (prop === 'agentVerifiedData') value = false

      commit('SET_TRANSACTION_DATA', {
        prop: prop,
        value: value
      })
    })
  }
}
