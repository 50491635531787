<template>
  <v-dialog v-model="showDepositCreationModal" persistent width="60%">
    <v-card class="rounded-lg">
      <v-card-title class="py-md-6">
        <h3 class="custom-heading primary--text">
          New PRP Deposit
        </h3>
        <v-spacer />
        <v-btn @click="closeDialog" small icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row cols="12" no-gutters>
            <v-col class="py-4 px-2" :cols="12" :md="6">
              <v-text-field
                v-model="formData.amount"
                class="ph-textbox"
                label="Amount *"
                type="number"
                :rules="requiredField"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="6">
              <v-autocomplete
                v-model="formData.currency"
                :items="currencies"
                :return-object="false"
                label="Currency *"
                class="ph-textbox"
                :rules="requiredField"
                outlined
              />
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="6">
              <v-text-field
                v-model="formData.trx_date"
                @click:append="openDatePicker"
                @input="syncDate"
                class="ph-textbox"
                label="Transaction Date *"
                append-icon="mdi-calendar"
                :rules="trxDateRules"
                hint="Type or pick a date by clicking the calendar icon"
                outlined
              />
              <v-dialog v-model="trxDatePicker" width="300">
                <v-date-picker
                  v-model="tempDate"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @input="trxDatePicker = false"
                />
              </v-dialog>
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="6"></v-col>
            <v-col class="py-4 px-2" :cols="12" :md="6">
              <p>Deposit Slip:</p>
              <v-file-input
                truncate-length="15"
                class="ph-textbox ph-debit-card-upload"
                outlined
                placeholder="Upload Picture"
                @change="uploadedFile($event)"
                :rules="requiredField"
                accept="image/png, image/jpeg, image/jpg"
              ></v-file-input>
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="6">
              <br><br>
              <v-btn text primary @click="togglePhoto()" v-if="depositFile.file">
                <v-icon left> mdi-{{showFile ? 'eye-off-outline' : 'eye-outline'}} </v-icon>
                {{showFile ? 'Hide Image' : 'View Image'}}
              </v-btn>
              <br v-if="depositFile.file">
              <br v-if="depositFile.file">
              <v-img
                v-if="showFile"
                lazy-src="@/assets/images/grey.jpg"
                :src="(depositFile.file !== '') ? 'data:image/png;base64, ' + depositFile.file : 'https://picsum.photos/id/11/10/6'"
                class="ph-image-upload-thumbnail"
              ></v-img>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex align-center">
        <v-btn
          color="danger"
          outlined
          text
          class="ph-primary-button ph-action-button"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          @click="addNewDeposit"
          color="primary"
          elevation="0"
          class="ph-primary-button ph-primary-execute-btn"
        >
          Add New Deposits
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { serialize } from 'object-to-formdata'

import {
  renderToast, aesDecrypt
} from '@/utils'

export default {
  name: 'CreateDeposit',
  data () {
    return {
      trxDatePicker: false,
      tempDate: null,
      formData: {
        loc_idno: ''
      },
      requiredField: [
        v => !!v || 'This field is required'
      ],
      trxDateRules: [
        v => !!v || 'This field is required',
        v => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v) || 'Please use YYYY-MM-DD format'
      ],
      depositFile: {},
      showFile: false
    }
  },
  computed: {
    ...mapGetters({
      showDepositCreationModal: 'prpDeposit/showDepositCreationModal',
      branches: 'prpDeposit/branches',
      currencies: 'prpDeposit/currencies',
      prpList: 'prpDeposit/prpList',
      currUser: 'auth/currUser'
    })
  },
  watch: {
    tempDate (val) {
      this.formData.trx_date = val
    }
  },
  mounted () {
    this.depositFile = {}
  },
  methods: {
    renderToast,
    syncDate (val) {
      if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(val)) {
        this.tempDate = val
      }
    },
    openDatePicker () {
      this.trxDatePicker = true
    },
    closeDialog () {
      this.$store.commit('prpDeposit/SET_DEPOSIT_CREATION_MODAL', false)
    },
    async addNewDeposit () {
      const userData = JSON.parse(aesDecrypt(this.$cookies.get('USP_DATA')))
      this.formData.loc_idno = userData.location_code
      if (this.$refs.form.validate()) {
        this.formData.edit_by = this.currUser.first_name + ' ' + this.currUser.last_name
        const formData = serialize(this.formData)
        const res = await this.$store.dispatch('prpDeposit/addDeposit', formData)

        console.log(res)

        if (res.code === 200) {
          this.renderToast('success', 'Saving Successful', 'New deposit has been added successfully')
          this.formData = {}
          this.$store.dispatch('prpDeposit/getDeposit', userData.location_code)
          this.$store.commit('prpDeposit/SET_DEPOSIT_CREATION_MODAL', false)
        } else {
          this.renderToast('error', 'Saving Failed', 'Failed to add new deposit')
        }
      } else {
        this.renderToast('error', 'Saving Failed', 'The fields are incomplete, kindly try again.')
      }
    },
    async uploadedFile (item) {
      let deposit = {}
      this.formData.image = item
      if (item !== null) {
        const getBase64 = await this.uploadedFileToConvert(item)
        deposit = {
          file: getBase64
        }

        this.depositFile = Object.assign({}, this.depositFile, deposit)
      } else {
        deposit = {
          file: ''
        }

        this.depositFile = Object.assign({}, this.depositFile, deposit)
      }
    },
    uploadedFileToConvert (item) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.readAsBinaryString(item)
        reader.onload = function () {
          resolve(btoa(reader.result))
        }
      })
    },
    async urlToBase64 (url) {
      const data = await fetch(url)
      const blob = await data.blob()

      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    },
    togglePhoto () {
      this.showFile = !this.showFile
    }
  }
}
</script>
<style scoped>
  .ph-transaction-container .col {
    padding: 15px;
  }

  .ph-breadcrumbs {
    padding: 5px 5px;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }

    .ph-transaction-container .col {
      padding: 12px;
    }

    .ph-breadcrumbs {
      padding: 18px 12px;
      padding-left: 24px;
    }
  }
</style>
