<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 px-md-5 mb-md-12" no-gutters>
      <v-col class="mt-2 mt-sm-4 mt-md-7" :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:[`item.sender_name`]="{ item }">
            <v-avatar class="mr-1" size="26px" :style="avatarColor(item.sender_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.sender_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.sender_name) }}
          </template>
          <template v-slot:[`item.receiver_name`]="{ item }">
            <v-avatar class="mr-1" size="26px" :style="avatarColor(item.receiver_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.receiver_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.receiver_name) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="newEntry"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarInitial,
  toProperCase,
  avatarColor,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      items: 'cashTransfer/items'
    })
  },
  data () {
    return {
      fab: false,
      selectedItem: null,
      isWhitelistDialog: false,
      breadcrumbsItems: [
        {
          text: 'BOS Module',
          disabled: true,
          exact: true,
          to: '/'
        },
        {
          text: 'Cash Transfer',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date',
          width: '150px'
        },
        {
          text: 'Sender',
          sortable: true,
          value: 'sender_name',
          width: '150px'
        },
        {
          text: 'Receiver',
          sortable: true,
          value: 'receiver_name',
          width: '150px'
        },
        {
          text: 'Currency',
          sortable: true,
          value: 'currency_name',
          width: '150px'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'total_amount',
          width: '150px'
        }
      ]
    }
  },
  methods: {
    newEntry () {
      this.$router.push('/cash-transfer/new')
    },
    async retrieveData () {
      try {
        const { code } = await this.$store.dispatch('cashTransfer/retrieveData', {
          trxDate: this.currUser.trx_date,
          locationId: this.currUser.location_id,
          userId: this.currUser.user_id
        })

        if (code !== 200) {
          renderToast('error', 'Request Error', 'Failed to retrieve grid')
        }
      } catch (e) {
        renderToast('error', 'USP Error', 'Failed to retrieve grid')
      }
    },
    avatarInitial,
    toProperCase,
    avatarColor
  },
  mounted () {
    this.retrieveData()
  }
}
</script>
