export default {
  SET_CURRENT_PAGE (state, payload) {
    state.currentPage = payload
  },
  SET_CURRENT_STEP (state, payload) {
    state.currentStep = payload
  },
  SET_POLICIES (state, payload) {
    state.policies = payload
  },
  SET_SUCCESS_DATA (state, payload) {
    state.successData = payload
  },
  SET_SELECTED_PREMIUM (state, payload) {
    state.selectedPremium = payload
  },
  SET_TRANSACTION_DATA (state, { prop, value }) {
    state.transactionData[prop] = value
  },
  SET_CTPL_LIST (state, data) {
    state.ctplList = data
  }
}
