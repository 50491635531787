<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mt-16 mb-4 pt-10 mx-2 mt-sm-4 pt-sm-0 mx-sm-2 mx-md-4" no-gutters>
      <v-col :cols="12" :md="4" class="pt-sm-2">
        <v-text-field
          v-model="searchText"
          class="ph-textbox"
          placeholder="Search Partner"
          append-icon="mdi-magnify"
          outlined
        />
      </v-col>
    </v-row>
    <v-row class="mt-md-12 mb-md-10" no-gutters>
      <v-col :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :search="searchText"
          class="custom-table"
          :calculate-widths="true"
        >
          <template v-slot:[`item.bill_name`]="{ item }">
            <span v-if="item.bill_name">
              {{ item.bill_name }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span v-if="item.status !== null">
              {{ getStatusDisplay(item.status) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn @click="openUpdateDialog(item)" color="primary" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <!-- <v-btn @click="openDeleteDialog(item)" color="red" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="openAddDialog"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog v-model="addDialog" width="60%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            Partner Information
          </h3>
          <v-spacer />
          <v-btn @click="closeAddDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="addForm">
            <h3>
              Partner
            </h3>
            <v-row class="mt-2 mb-4 mb-md-4">
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.partnerCode"
                  class="ph-textbox"
                  label="Partner Code"
                  :rules="requiredRules"
                  outlined
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.partnerName"
                  class="ph-textbox"
                  label="Partner Name"
                  :rules="requiredRules"
                  outlined
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6">
          <v-btn
            @click="closeAddDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            @click="addItem"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateDialog" width="60%" persistent>
      <v-card>
        <v-card-title>
          <h3 class="custom-heading pt-2 pb-2 pb-md-4 primary--text font-weight-black">
            Partner Information
          </h3>
          <v-spacer />
          <v-btn @click="closeUpdateDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="updateForm">
            <h3>
              Partner
            </h3>
            <v-row class="mt-2 mb-4 mb-md-4">
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.partnerCode"
                  class="ph-textbox"
                  label="Partner Code"
                  :rules="requiredRules"
                  outlined
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="formData.partnerName"
                  class="ph-textbox"
                  label="Partner Name"
                  :rules="requiredRules"
                  outlined
                />
              </v-col>
            </v-row>
            <v-switch
              v-model="formData.status"
              @change="switchStatus"
              :true-value="1"
              :false-value="0"
              class="d-inline-block"
              color="primary"
              :label="formData.status ? 'Active' : 'Inactive'"
              :ripple="false"
              inset
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-md-8 pt-md-4 px-md-6">
          <v-btn
            @click="closeUpdateDialog"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            outlined
            large
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            @click="updateItem"
            class="rounded-lg font-weight-black"
            color="primary"
            min-width="200px"
            large
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Partner
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this partner?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDeleteDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="deleteItem"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      items: [],
      searchText: '',
      selectedItem: null,
      addDialog: false,
      updateDialog: false,
      deleteDialog: false,
      formData: {
        partnerName: '',
        partnerCode: '',
        status: true
      },
      breadcrumbsItems: [
        { text: 'Dashboard', disabled: false, href: '/' },
        { text: 'Partner Maintenance', disabled: true }
      ],
      headers: [
        { text: 'Partner Name', sortable: true, value: 'bill_name' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Action', sortable: false, width: '150px', value: 'action' }
      ],
      requiredRules: [
        v => !!v || 'This field is required'
      ]
    }
  },
  methods: {
    resetForm () {
      this.formData = {
        partnerName: '',
        partnerCode: '',
        status: true
      }
    },
    openAddDialog () {
      this.addDialog = true
    },
    closeAddDialog () {
      this.addDialog = false
      this.resetForm()
      this.$refs.addForm.resetValidation()
    },
    openUpdateDialog  (data) {
      this.selectedItem = data
      this.formData = {
        partnerName: data.bill_name,
        partnerCode: data.bill_code,
        status: data.status
      }
      this.updateDialog = true
    },
    closeUpdateDialog  () {
      this.selectedItem = null
      this.updateDialog = false
      this.resetForm()
      this.$refs.updateForm.resetValidation()
    },
    openDeleteDialog (data) {
      this.selectedItem = data
      this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.selectedItem = null
      this.deleteDialog = false
    },
    async addItem () {
      if (this.$refs.addForm) {
        const payload = {
          bill_name: this.formData.partnerName,
          bill_code: this.formData.partnerCode,
          status: 1,
          edit_by: this.currUser.user_id,
          approver: this.currUser.user_id
        }

        const { code, message, result } = await this.$store.dispatch('billers/addPartner', payload)

        if (code === 200) {
          renderToast('success', 'Successful', 'Create Partner has been requested and waiting for approval')
          this.getGrid()
          this.closeAddDialog()
        } else {
          if (message && result) {
            let type = 'error'

            if (code === 422) type = 'warning'

            renderToast(type, 'Saving Failed', result)
          } else {
            renderToast('error', 'Saving Failed', 'Something went wrong while disapproving this request, please try again')
          }
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please complete the form to save this partner')
      }
    },
    async updateItem () {
      if (this.$refs.updateForm) {
        const payload = {
          partner_id: this.selectedItem.id,
          bill_code: this.formData.partnerCode,
          bill_name: this.formData.partnerName,
          edit_by: this.currUser.user_id,
          approver: this.currUser.user_id
        }

        const { code, message, result } = await this.$store.dispatch('billers/updatePartner', payload)

        if (code === 200) {
          renderToast('success', 'Successful', 'Update Partner has been requested and waiting for approval')
          this.getGrid()
          this.closeUpdateDialog()
        } else {
          if (message && result) {
            let type = 'error'

            if (code === 422) type = 'warning'

            renderToast(type, 'Saving Failed', result)
          } else {
            renderToast('error', 'Saving Failed', 'Something went wrong while disapproving this request, please try again')
          }
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please complete the form to update this partner')
      }
    },
    async deleteItem () {
      const { code, message, result } = await this.$store.dispatch('billers/deletePartner', this.selectedItem.id)

      if (code === 200) {
        renderToast('success', 'Successful', 'Partner has been deleted')
        this.getGrid()
        this.closeDeleteDialog()
      } else {
        if (message && result) {
          let type = 'error'

          if (code === 422) type = 'warning'

          renderToast(type, 'Saving Failed', result)
        } else {
          renderToast('error', 'Saving Failed', 'Something went wrong while disapproving this request, please try again')
        }
      }
    },
    async getGrid () {
      const req = await this.$store.dispatch('billers/getPartners')

      if (req.code === 200) {
        this.items = req.result
      }
    },
    async switchStatus (status) {
      const payload = {
        partner_id: this.selectedItem.id,
        remarks: `Requesting to change the status to ${status}`,
        status,
        edit_by: this.currUser.user_id,
        approver: this.currUser.user_id
      }

      const req = await this.$store.dispatch('billers/switchPartnerStatus', payload)

      if (req.code === 200) {
        renderToast('success', 'Successful', 'Update Partner Status has been requested and waiting for approval')
      } else {
        renderToast('error', 'Failed', 'Something went wrong while switching status, please try again.')
      }
    },
    getStatusDisplay (status) {
      if (status === 1) return 'Active'
      return 'Inactive'
    }
  },
  beforeMount () {
    this.getGrid()
  }
}
</script>
