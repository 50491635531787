export default {
  uploadedData: state => state.uploadedData,
  module: state => state.module,
  uploadedFileName: state => state.uploadedFileName,
  pnaAlertDialog: state => state.pnaAlertDialog,
  highRiskDialog: state => state.highRiskDialog,
  riskData: state => state.riskData,
  selectedMonitoringItem: state => state.selectedMonitoringItem,
  selectedWhitelistingItem: state => state.selectedWhitelistingItem,
  currentPage: state => state.currentPage,
  auditTrailData: state => state.auditTrailData
}
