import { endpoints } from '@/api/endpoints'

const airlines = endpoints.use('airlines')

export default {
  getGrid: ({ commit }, { trxDate, locationID }) => new Promise((resolve, reject) => {
    airlines.grid(trxDate, locationID)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  saveAirAsia: ({ commit }, payload) => new Promise((resolve, reject) => {
    airlines.saveAirAsia(payload)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  saveCebuPacific: ({ commit }, payload) => new Promise((resolve, reject) => {
    airlines.saveCebuPacific(payload)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  avpServiceFee: ({ commit }, locationID) => new Promise((resolve, reject) => {
    airlines.avpServiceFee(locationID)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  }),

  resetForm ({ state, commit }) {
    commit('SET_CURRENT_PAGE', 0)
    commit('SET_SUCCESS_DATA', null)

    Object.keys(state.transactionData).forEach(prop => {
      let value = ''

      if (prop === 'product') value = null

      commit('SET_TRANSACTION_DATA', { [prop]: value })
    })
  }
}
