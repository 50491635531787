import { endpoints } from '@/api/endpoints'
import { renderToast } from '@/utils'
import qs from 'qs'

const transactionLimit = endpoints.use('transactionLimit')

export default {
  getLocationLimit: ({ commit }) => new Promise((resolve, reject) => {
    transactionLimit.getLocationLimit()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveLocationLimit: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionLimit.saveLocationLimit(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  bulkSaveLocationLimit: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionLimit.bulkSaveLocationLimit(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  bulkValidateLocationLimit: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionLimit.bulkValidateLocationLimit(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateLocationLimit: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    transactionLimit.updateLocationLimit(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deleteLocationLimit: ({ commit }, { id, userID }) => new Promise((resolve, reject) => {
    transactionLimit.deleteLocationLimit(id, userID)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getCustomerLimit: ({ commit }) => new Promise((resolve, reject) => {
    transactionLimit.getCustomerLimit()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveCustomerLimit: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionLimit.saveCustomerLimit(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateCustomerLimit: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    transactionLimit.updateCustomerLimit(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deleteCustomerLimit: ({ commit }, { id, userID }) => new Promise((resolve, reject) => {
    transactionLimit.deleteCustomerLimit(id, userID)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getApprovers: ({ commit }) => new Promise((resolve, reject) => {
    transactionLimit.getApprovers()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getApprover: ({ commit }, id) => new Promise((resolve, reject) => {
    transactionLimit.getApprover(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveApprover: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionLimit.saveApprover(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateApprover: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    transactionLimit.updateApprover(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deleteApprover: ({ commit }, id) => new Promise((resolve, reject) => {
    transactionLimit.deleteApprover(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getIncreaseLocationRequests: ({ commit }) => new Promise((resolve, reject) => {
    transactionLimit.getIncreaseLocationRequests()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getIncreaseCustomerRequests: ({ commit }) => new Promise((resolve, reject) => {
    transactionLimit.getIncreaseCustomerRequests()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveIncreaseLocationLimit: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionLimit.saveIncreaseLocationLimit(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveIncreaseCustomerLimit: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionLimit.saveIncreaseCustomerLimit(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateIncreaseLocationLimit: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    transactionLimit.updateIncreaseLocationLimit(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateIncreaseCustomerLimit: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    transactionLimit.updateIncreaseCustomerLimit(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deleteIncreaseLocationLimit: ({ commit }, { id, userID }) => new Promise((resolve, reject) => {
    transactionLimit.deleteIncreaseLocationLimit(id, userID)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deleteIncreaseCustomerLimit: ({ commit }, { id, userID }) => new Promise((resolve, reject) => {
    transactionLimit.deleteIncreaseCustomerLimit(id, userID)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  approveIncreaseLocationRequest: ({ commit }, { type, id, payload }) => new Promise((resolve, reject) => {
    const action = {
      review: 'locationIncreaseReview',
      approve: 'locationIncreaseApprove',
      disapprove: 'locationIncreaseDisapprove'
    }

    transactionLimit[action[type]](id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  approveIncreaseCustomerRequest: ({ commit }, { type, id, payload }) => new Promise((resolve, reject) => {
    const action = {
      review: 'customerIncreaseReview',
      approve: 'customerIncreaseApprove',
      disapprove: 'customerIncreaseDisapprove'
    }

    transactionLimit[action[type]](id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getApprovalLocationRequests: ({ commit }) => new Promise((resolve, reject) => {
    transactionLimit.getLocationLimit()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getApprovalCustomerRequests: ({ commit }) => new Promise((resolve, reject) => {
    transactionLimit.getCustomerLimit()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  approveApprovalLocationRequest: ({ commit }, { type, id, payload }) => new Promise((resolve, reject) => {
    const action = {
      review: 'locationApprovalReview',
      approve: 'locationApprovalApprove',
      disapprove: 'locationApprovalDisapprove'
    }

    transactionLimit[action[type]](id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  approveApprovalCustomerRequest: ({ commit }, { type, id, payload }) => new Promise((resolve, reject) => {
    const action = {
      review: 'customerApprovalReview',
      approve: 'customerApprovalApprove',
      disapprove: 'customerApprovalDisapprove'
    }

    transactionLimit[action[type]](id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  checkLimit: ({ state, commit, rootState }, { transactionName, payload }) => new Promise((resolve, reject) => {
    const data = {
      customer_id: payload.customer_id,
      provider_code: payload.provider_code,
      service_name: payload.service_name,
      service_type: payload.service_type,
      channel: (rootState.auth.currUser.location_type === '1') ? 'COW' : 'PRP',
      location_id: rootState.auth.currUser.location_id,
      trx_date: rootState.auth.currUser.trx_date
    }

    transactionLimit.checkLimit(data)
      .then(({ data: { code, result } }) => {
        if (code === 200) {
          if (result) {
            commit('SET_PROMPT_DATA', {
              locationThreshold: result.location_threshold,
              customerThreshold: result.customer_threshold
            })

            if (payload.amount > result.location_threshold) {
              commit('SET_SHOW_PROMPT_DIALOG', true)
              commit('SET_PROMPT_DATA', { type: 'location', transactionName })
              resolve(false)
            } else if (payload.amount > result.customer_threshold) {
              commit('SET_SHOW_PROMPT_DIALOG', true)
              commit('SET_PROMPT_DATA', { type: 'customer', transactionName })
              resolve(false)
            } else {
              commit('SET_SHOW_PROMPT_DIALOG', false)
              commit('SET_PROMPT_DATA', {
                type: '',
                transactionName: '',
                locationThreshold: 0,
                customerThreshold: 0
              })
              resolve(true)
            }
          } else {
            commit('SET_SHOW_PROMPT_DIALOG', false)
            commit('SET_PROMPT_DATA', {
              type: '',
              transactionName: '',
              locationThreshold: 0,
              customerThreshold: 0
            })
            resolve(true)
          }
        } else {
          renderToast('error', 'Checking Failed', 'Failed to check current limit, please try again')
          resolve(false)
        }
      })
      .catch((err) => {
        reject(err)
      })
  }),
  getProviders: ({ commit }) => new Promise((resolve, reject) => {
    transactionLimit.getProviders()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getProvider: ({ commit }, id) => new Promise((resolve, reject) => {
    transactionLimit.getProvider(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveProvider: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionLimit.saveProvider(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateProvider: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    transactionLimit.updateProvider(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  deleteProvider: ({ commit }, id) => new Promise((resolve, reject) => {
    transactionLimit.deleteProvider(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getLocationDashboardData: ({ commit }, payload) => new Promise((resolve, reject) => {
    const query = qs.stringify(payload)
    transactionLimit.getLocationDashboardData(query)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getCustomerDashboardData: ({ commit }, payload) => new Promise((resolve, reject) => {
    const query = qs.stringify(payload)
    transactionLimit.getCustomerDashboardData(query)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getLocationLimitsReportData: ({ commit }, payload) => new Promise((resolve, reject) => {
    const query = qs.stringify(payload)
    transactionLimit.getLocationLimitsReportData(query)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getCustomerLimitsReportData: ({ commit }, payload) => new Promise((resolve, reject) => {
    const query = qs.stringify(payload)
    transactionLimit.getCustomerLimitsReportData(query)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getRequestLogsReportData: ({ commit }, payload) => new Promise((resolve, reject) => {
    const query = qs.stringify(payload)
    transactionLimit.getRequestLogsReportData(query)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getFlaggedTransactionReportData: ({ commit }) => new Promise((resolve, reject) => {
    transactionLimit.getFlaggedTransactionReportData()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  saveFlaggedTransaction: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionLimit.saveFlaggedTransaction(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  resetApprovalLocationForm ({ state, commit }) {
    Object.keys(state.approvalLocationFormData).forEach(prop => {
      let value = ''

      if (prop === 'provider') {
        value = {
          provider_name: '',
          provider_code: '',
          service_name: '',
          service_type: ''
        }
      }

      commit('SET_APPROVAL_LOCATION_FORM_DATA', { [prop]: value })
    })
  },
  resetApprovalCustomerForm ({ state, commit }) {
    Object.keys(state.approvalCustomerFormData).forEach(prop => {
      let value = ''

      if (prop === 'provider') {
        value = {
          provider_name: '',
          provider_code: '',
          service_name: '',
          service_type: ''
        }
      }

      commit('SET_APPROVAL_CUSTOMER_FORM_DATA', { [prop]: value })
    })
  },
  resetIncreaseLocationForm ({ state, commit }) {
    Object.keys(state.increaseLocationFormData).forEach(prop => {
      let value = ''

      if (prop === 'provider') {
        value = {
          provider_name: '',
          provider_code: '',
          service_name: '',
          service_type: ''
        }
      }

      commit('SET_INCREASE_LOCATION_FORM_DATA', { [prop]: value })
    })
  },
  resetIncreaseCustomerForm ({ state, commit }) {
    Object.keys(state.increaseCustomerFormData).forEach(prop => {
      let value = ''

      if (prop === 'provider') {
        value = {
          provider_name: '',
          provider_code: '',
          service_name: '',
          service_type: ''
        }
      }

      commit('SET_INCREASE_CUSTOMER_FORM_DATA', { [prop]: value })
    })
  },
  resetLocationForm ({ state, commit }) {
    Object.keys(state.locationFormData).forEach(prop => {
      let value = ''

      if (prop === 'provider') {
        value = {
          provider_name: '',
          provider_code: '',
          service_name: '',
          service_type: ''
        }
      }

      commit('SET_LOCATION_FORM_DATA', { [prop]: value })
    })
  },
  resetCustomerForm ({ state, commit }) {
    Object.keys(state.customerFormData).forEach(prop => {
      let value = ''

      if (prop === 'provider') {
        value = {
          provider_name: '',
          provider_code: '',
          service_name: '',
          service_type: ''
        }
      }

      commit('SET_CUSTOMER_FORM_DATA', { [prop]: value })
    })
  },
  resetApproverForm ({ state, commit }) {
    Object.keys(state.approverFormData).forEach(prop => {
      let value = ''

      if (prop === 'provider') {
        value = {
          provider_name: '',
          provider_code: '',
          service_name: '',
          service_type: ''
        }
      }

      commit('SET_APPROVER_FORM_DATA', { [prop]: value })
    })
  },
  resetProviderForm ({ state, commit }) {
    Object.keys(state.providerFormData).forEach(prop => {
      commit('SET_PROVIDER_FORM_DATA', { [prop]: '' })
    })
  }
}
