<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-form ref="form">
      <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
        <v-col :cols="3">
          <v-row>
            <v-col class="mb-md-2" :cols="12">
              <h6 class="text-h6 custom-heading primary--text">
                Provider Details
              </h6>
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.providerName"
                @input="convertToProperCase('providerName')"
                class="ph-textbox"
                label="Provider Name"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.providerCode"
                class="ph-textbox"
                label="Provider Code"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-select
                v-model="formData.serviceName"
                :items="serviceNames"
                class="ph-textbox"
                label="Service Name"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-select
                v-model="formData.serviceType"
                :items="serviceTypes"
                class="ph-textbox"
                label="Service Type"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.minLocationLimit"
                class="ph-textbox"
                label="Min Location Limit"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.maxLocationLimit"
                class="ph-textbox"
                label="Max Location Limit"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.minCustomerLimit"
                class="ph-textbox"
                label="Min Customer Limit"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.maxCustomerLimit"
                class="ph-textbox"
                label="Max Customer Limit"
                type="number"
                :rules="requiredRules"
                hide-spin-buttons
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-autocomplete
                v-model="formData.channel"
                :items="collections.channels"
                item-text="channel_name"
                item-value="channel_code"
                class="ph-textbox"
                label="Channel"
                :rules="requiredRules"
                outlined
              />
            </v-col>
            <v-col :cols="12">
              <v-text-field
                v-model="formData.thresholdRecipient"
                class="ph-textbox"
                label="Threshold Recipient"
                :rules="requiredEmailRules"
                outlined
              />
            </v-col>
            <v-col :cols="6">
              <v-btn @click="cancel" class="font-weight-bold" color="primary" outlined x-large>
                Cancel
              </v-btn>
            </v-col>
            <v-col :cols="6">
              <v-btn @click="save" class="font-weight-bold" color="primary" block x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  isValidEmail,
  toProperCase,
  renderToast,
  trim
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections',
      searchResult: 'users/searchResult',
      formData: 'transactionLimit/providerFormData',
      selectedProvider: 'transactionLimit/selectedProvider'
    })
  },
  data () {
    return {
      searchText: '',
      employeeNumber: '',
      resultDialog: false,
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      requiredEmailRules: [
        v => (v !== '' && isValidEmail(v)) || 'Invalid email address'
      ],
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Providers',
          disabled: false,
          exact: true,
          to: '/transaction-limit/providers'
        },
        {
          text: 'Update Provider',
          disabled: true
        }
      ],
      serviceNames: [
        { text: 'REMITTANCE', value: 'REMITTANCE' },
        { text: 'CICO', value: 'CICO' },
        { text: 'RTA', value: 'RTA' }
      ],
      serviceTypes: [
        { text: 'INBOUND', value: 'INBOUND' },
        { text: 'OUTBOUND', value: 'OUTBOUND' }
      ]
    }
  },
  methods: {
    async save () {
      if (this.$refs.form.validate()) {
        const payload = {
          provider_name: this.formData.providerName,
          provider_code: this.formData.providerCode,
          service_name: this.formData.serviceName,
          service_type: this.formData.serviceType,
          min_customer_limit: this.formData.minCustomerLimit,
          max_customer_limit: this.formData.maxCustomerLimit,
          min_location_limit: this.formData.minLocationLimit,
          max_location_limit: this.formData.maxLocationLimit,
          channel: this.formData.channel,
          threshold_recipient: this.formData.thresholdRecipient,
          updated_by: this.currUser.user_id
        }

        const { code } = await this.$store.dispatch('transactionLimit/updateProvider', {
          id: this.$route.params.id,
          payload
        })

        if (code === 200) {
          this.$store.dispatch('transactionLimit/resetProviderForm')
          renderToast('success', 'Successful', 'Provider details has been updated')
          this.$router.push('/transaction-limit/providers')
        } else {
          renderToast('error', 'Failed', 'Failed to update the provider details')
        }
      }
    },
    cancel () {
      this.$router.push('/transaction-limit/providers')
    },
    convertToProperCase (field) {
      this.formData[field] = toProperCase(trim(this.formData[field]))
    },
    toProperCase
  },
  async beforeMount () {
    this.$store.dispatch('transactionLimit/resetProviderForm')
    this.$store.commit('transactionLimit/SET_PROVIDER_FORM_DATA', {
      providerName: this.selectedProvider.provider_name,
      providerCode: this.selectedProvider.provider_code,
      serviceName: this.selectedProvider.service_name,
      serviceType: this.selectedProvider.service_type,
      minCustomerLimit: this.selectedProvider.min_customer_limit,
      maxCustomerLimit: this.selectedProvider.max_customer_limit,
      minLocationLimit: this.selectedProvider.min_location_limit,
      maxLocationLimit: this.selectedProvider.max_location_limit,
      channel: this.selectedProvider.channel,
      thresholdRecipient: this.selectedProvider.threshold_recipient
    })
  }
}
</script>

<style scoped>
.custom-heading {
  font-family: 'Baloo Extra Bold' !important;
}
</style>
