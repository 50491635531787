<template>
  <v-row class="ph-wu-receive-forms-container">
    <v-col cols="12" v-if="showRemoteNotice">
      <v-alert
        color="info"
        dark
        icon="mdi-remote"
        border="left"
        prominent
      >
        This transaction will be processed on behalf of <b>{{remoteData.remote_location.location_name}}</b> branch.
      </v-alert>
    </v-col>
    <v-col cols="12" md="8" class="ph-wu-receive-form">
      <v-form ref="sendFulfillForm">
        <v-btn text class="text-none" @click="backToSearch">
          <v-icon>mdi-arrow-left</v-icon> Back to Search
        </v-btn>
        <v-stepper v-model="payoutForm" elevation="0" class="ph-wu-receive-stepper">
          <v-stepper-header>
            <v-stepper-step
              :complete="payoutForm > 1"
              step="1"
            >
              Transfer Information
            </v-stepper-step>
            <v-divider class="ph-wu-receive-stepper-divider"></v-divider>
            <v-stepper-step
              :complete="payoutForm > 2"
              step="2"
            >
              Sender Details
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <SendMoneyFulfillTransferInfo v-bind:selectedTrxData="selectedTrxData" />
              <v-row>
                <v-col cols="12" class="ph-stepper-action-buttons">
                  <v-btn
                    color="danger"
                    outlined
                    text
                    light
                    class="ph-primary-button ph-stepper-button ph-action-button"
                  >
                    Back
                  </v-btn>
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
                    @click="toggleForm(2)"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
              <SendMoneyFulfillSenderInfo v-bind:transactionData="transactionData"/>
              <v-row>
                <v-col cols="12" class="ph-stepper-action-buttons">
                  <v-btn
                    color="danger"
                    outlined
                    text
                    light
                    class="ph-primary-button ph-stepper-button ph-action-button"
                    @click="toggleForm(1)"
                  >
                    Back
                  </v-btn>
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
                    @click="summaryDialog = true"
                    v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="4" class="ph-wu-receive-summary">
      <v-card
        elevation="0"
        color="rgba(26, 39, 145, 0.05)"
      >
        <div class="ph-transfer-summary-header">
          <b>Summary Overview</b>
        </div>
        <br>
        <div class="ph-transfer-summary-content">
          <p class="ph-transfer-summary-content-title">Please confirm if the sendout details are correct</p>
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in transferSummaryOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <wacom-transaction
          v-if="currUser.has_wacom"
          @submit-sig="submitSig" type="transactional"
          :referenceSignatures="customerSignaturesReference"
          :trxData="{sender_name: selectedCustomer.full_name, receiver_name: `${currentSendFulfillTrx.receiver.name.last_name}, ${currentSendFulfillTrx.receiver.name.first_name}`, total_amount: (currentSendFulfillTrx.financials.gross_total_amount / 100).toFixed(2)}"
        />
        <wacom-consent />
        <wacom-data-consent-first />
        <wacom-data-consent-second />
        <wacom-data-consent-third />
        <wacom-data-consent-fourth />
        <wacom-data-consent-fifth />
        <wacom-data-consent-sixth />
        <wacom-data-consent-seventh
          :remco="'Western Union'"
        />
        <div class="ph-transfer-summary-verify">
          <v-checkbox
            v-model="ackFlag"
            label="I have Verified Customer Data"
          ></v-checkbox>
        </div>
        <div class="ph-transfer-summary-action">
          <v-btn
            color="danger"
            outlined
            text
            light
            class="ph-primary-button ph-action-button"
            :disabled="ackFlag === false"
            @click="prePrintReceipt"
          >
            PRE-PRINT
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn"
            :disabled="isPrePrintClicked === false"
            @click="openVerifyOTP"
          >
            Confirm
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-wu-receive-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-wu-receive-summary-minified">
          <div class="ph-wu-receive-summary-content">
            <div class="ph-wu-receive-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>{{sendoutCurrency}} {{sendoutAmount}}</b></p>
            </div>
            <div class="ph-wu-receive-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <div class="ph-transfer-summary-content">
                <p class="ph-transfer-summary-content-title">Please confirm if the sendout details are correct</p>
                <v-simple-table class="ph-transfer-summary-table">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in transferSummaryOverview"
                        :key="item.name"
                      >
                        <td class="transfer-info-title">{{ item.name }}</td>
                        <td class="transfer-info-value">{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="ph-transfer-summary-verify">
                <v-checkbox
                  v-model="ackFlag"
                  label="I have Verified Customer Data"
                ></v-checkbox>
              </div>
              <div class="ph-transfer-summary-action">
                <v-btn
                  color="danger"
                  outlined
                  text
                  light
                  class="ph-primary-button ph-action-button"
                  :disabled="ackFlag === false"
                  @click="prePrintReceipt"
                >
                  PRE-PRINT
                </v-btn>
                <v-btn
                  color="primary"
                  elevation="0"
                  class="ph-primary-button ph-primary-execute-btn"
                  :disabled="isPrePrintClicked === false"
                  @click="openVerifyOTP"
                >
                  Confirm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noIDFlag"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          No identification card found
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="noIDFlag = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="updateDetails(selectedCustomer)"
          >
            Update Customer Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- OTP -->
    <v-dialog
      v-model="sendFulfillOTPDialog"
      persistent
      width="30%"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="sendFulfillOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import SendMoneyFulfillTransferInfo from '@/components/wu/SendMoneyFulfillTransferInfo'
import SendMoneyFulfillSenderInfo from '@/components/wu/SendMoneyFulfillSenderInfo'
import wacomMixin from '@/mixins/wacom.js'
import wacomTransaction from '@/components/wacom/wacomTransaction.vue'
import wacomConsent from '@/components/wacom/wacomConsent.vue'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { convertToRealAmount, formatMTCN, renderToast, formatWUName, formatWULastName, formatWUFirstName, formatWUMiddleName } from '@/utils'
import { format } from 'date-fns'

export default {
  name: 'SendMoneyFulfillForm',
  mixins: wacomMixin,
  components: {
    SendMoneyFulfillTransferInfo,
    SendMoneyFulfillSenderInfo,
    wacomTransaction,
    wacomConsent,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  data () {
    return {
      payoutForm: 1,
      transactionData: {},
      selectedTrxData: {},
      ackFlag: false,
      isPrePrintClicked: false,
      sendFulfillOTPDialog: false,
      otp: '',
      otpInvalid: false,
      isOTPLoading: false,
      terminalId: '',
      operatorId: '',
      locationId: '',
      otpMessage: '',
      noIDFlag: false,
      summaryDialog: false,
      sendoutCurrency: '',
      sendoutAmount: '',
      currentId: '',
      customerSignaturesReference: [],
      customerSignature: '',
      destinationCountry: '',
      showRemoteNotice: false,
      transferSummaryOverview: [
        { name: 'OAR', value: '-' },
        { name: 'Sender Name', value: '-' },
        { name: 'Sender DOB', value: '-' },
        { name: 'Receiver Name', value: '-' },
        { name: 'Destination', value: '-' },
        { name: 'Exchange Rate', value: '-' },
        { name: 'Send Amount', value: '-' },
        { name: 'Pay Amount', value: '-' },
        { name: 'Transfer Fee', value: '-' },
        { name: 'Total Amount', value: '-' }
      ],
      requiredField: [
        v => !!v || 'This field is required'
      ],
      hasCustomerDataConsent: false
    }
  },
  computed: {
    ...mapGetters({
      currentSendFulfillTrx: 'wu/currentSendFulfillTrx',
      payoutFormData: 'wu/payoutFormData',
      selectedCustomer: 'customers/selectedCustomer',
      collections: 'collections',
      oarNumber: 'oar/oarNumber',
      currUser: 'auth/currUser',
      sendoutReleaseForeignRefNo: 'wu/sendoutReleaseForeignRefNo',
      remoteData: 'obo/remoteData',
      oar: 'oar',
      consentValues: 'wacom/consentValues'
    })
  },
  watch: {
    currentSendFulfillTrx: {
      handler (returnVal) {
        console.log(returnVal)

        if (returnVal) {
          this.selectedTrxData = returnVal
          this.populateOverview(this.selectedTrxData)
        }
      },
      deep: true
    },
    selectedCustomer: {
      handler (val) {
        console.log(val)
      },
      deep: true
    },
    ackFlag: {
      handler (val) {
        if (val === false) {
          this.isPrePrintClicked = false
        }
      },
      deep: true
    }
  },
  beforeMount () {
    // this.transactionData = this.payoutFormData
  },
  async mounted () {
    if (this.currentSendFulfillTrx) {
      this.selectedTrxData = this.currentSendFulfillTrx
      this.populateOverview(this.selectedTrxData)
    } else {
      this.selectedTrxData = {}
    }

    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData.remote_location.location_id
      this.showRemoteNotice = true
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
      this.showRemoteNotice = false
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
      this.showRemoteNotice = false
    }
    const that = this
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    const signatures = await that.$store.dispatch('customers/retrieveSignatures', { customer_id: that.selectedCustomer.customer_id })
    that.customerSignaturesReference = signatures.result
    this.checkConsent()
    this.$store.commit('SET_PAGE_TITLE', 'Transfer Information')
  },
  methods: {
    submitSig (emitted) {
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
      this.customerSignature = emitted
      this.generatePrePrint()
    },
    async generatePrePrint () {
      const currentCustomer = this.selectedCustomer
      const idList = currentCustomer.img ? JSON.parse(currentCustomer.img) : []
      const trxData = this.transactionData

      const getId = this.collections.wuIDList.filter(item => {
        return item.id_value === trxData.id_type
      })

      let currentId = {}

      Object.keys(idList).forEach(function (key, index) {
        if (idList[key].id_type === getId[0].id_type) {
          currentId = idList[key]
        }
      })

      currentId = currentId || {}
      this.currentId = currentId
      console.log(this.currentId)
      const currentTrx = this.currentSendFulfillTrx
      console.log(this.collections.countries)
      console.log(currentTrx.payment_details.destination_country_currency.iso_code.currency_code, 'current currency code')
      const filteredCountry = this.collections.countries.filter(function (e) {
        return e.currency === currentTrx.payment_details.destination_country_currency.iso_code.currency_code
      })
      this.destinationCountry = filteredCountry[0].country
      console.log(currentTrx.payment_details.destination_country_currency)
      const receiverMiddleName = this.formatWUMiddleName(currentTrx.receiver.name)
      const senderMiddleName = this.formatWUMiddleName(currentTrx.sender.name)
      const prePrintPayload = {}
      prePrintPayload.print_data = {
        receiver_name: this.formatWUFirstName(currentTrx.receiver.name) + ' ' + receiverMiddleName + this.formatWULastName(currentTrx.receiver.name),
        sender_name: this.formatWUFirstName(currentTrx.sender.name) + ' ' + senderMiddleName + this.formatWULastName(currentTrx.sender.name),
        mywu_number: (currentCustomer.wu_card_no !== '0' || currentCustomer.wu_card_no !== null) ? currentCustomer.wu_card_no : '',
        points_earned: '0',
        total_wu_points: '',
        id_type: this.currentId.id_type,
        id_number: this.currentId.id_number,
        id_expiration: this.currentId.expiry,
        occupation: currentCustomer.occupation,
        name_of_employer: currentCustomer.employer_name,
        date_of_birth: currentCustomer.birth_date,
        country_of_birth: currentCustomer.country_birth,
        nationality: currentCustomer.nationality,
        purpose_of_transaction: currentCustomer.purpose_transaction,
        source_of_funds: currentCustomer.source_fund,
        relationship: currentCustomer.relation_to,
        present_address_line1: currentCustomer.address,
        present_address_line2: currentCustomer.barangay,
        present_city: currentCustomer.municipality,
        present_state: currentCustomer.province,
        present_postcode: currentCustomer.zip_code,
        permanent_address_line1: currentCustomer.street1,
        permanent_address_line2: currentCustomer.barangay1,
        permanent_city: currentCustomer.city1,
        permanent_state: currentCustomer.province1,
        permanent_postcode: currentCustomer.zip_code1,
        landline: '',
        mobile_no: currentCustomer.mobile_no,
        mtcn: '',
        date_and_time: this.formatDateTime(new Date()),
        agent_details: this.currUser.location_name + ' BRANCH',
        originating_country: 'Philippines',
        city: '',
        amount_receive: '',
        total: (currentTrx.financials.gross_total_amount / 100).toFixed(2),
        pay_amount: (currentTrx.financials.destination_principal_amount / 100).toFixed(2),
        exchange_rate: '1',
        dst_pay_amount: '',
        cash: '',
        checque: '',
        checque_number: '',
        credit_to_bank_account: '',
        account_number: '',
        amount_sent: (currentTrx.financials.originators_principal_amount / 100).toFixed(2),
        transfer_fee: (currentTrx.financials.charges / 100).toFixed(2),
        delivery_charge: '0.00',
        discount: '',
        destination_country: filteredCountry[0].country,
        originating_curr: '',
        destination_curr: '',
        message_charge: currentTrx.financials.message_charge.toFixed(2),
        message: ''
      }
      console.log(prePrintPayload, 'preprint payload')
      if (this.currUser.has_wacom) {
        prePrintPayload.print_data.fla_signature = this.currUser.userSignature
        prePrintPayload.print_data.customer_signature = this.customerSignature
      }
      prePrintPayload.data = {
        has_wacom: this.currUser.has_wacom,
        trx_type: 1,
        remco_name: 'Western Union',
        control_number: currentTrx.mtcn,
        customer_id: this.selectedCustomer.customer_id.toString(),
        location_id: this.currUser.location_id,
        location_name_uploaded: this.currUser.location_name,
        crn: this.selectedCustomer.customer_number,
        uploaded_by: this.currUser.user_id,
        first_name: currentTrx.sender.name.first_name,
        last_name: currentTrx.sender.name.last_name,
        product: 'WU Send Money Fulfill',
        receipt_type: 'pre'
      }
      console.log(prePrintPayload)
      const reqPrePrint = await this.$store.dispatch('wu/generateACRWU', prePrintPayload)
      var decoded = Buffer.from(reqPrePrint.result, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
    },
    formatDateTime (date) {
      return format(date, 'yyyy-MM-dd HH:mm:SS')
    },
    convertToRealAmount,
    formatMTCN,
    renderToast,
    formatWUName,
    formatWULastName,
    formatWUFirstName,
    formatWUMiddleName,
    populateOverview (payload) {
      const orignatingCountryCurrency = payload.payment_details.originating_country_currency
      const destinationCountryCurrency = payload.payment_details.destination_country_currency

      // let originatingCountry = ''
      let destinationCountry = ''

      this.collections.countries.filter(data => {
        // if (data.two_iso_code === orignatingCountryCurrency.iso_code.country_code) {
        //   originatingCountry = data.country
        // }

        if (data.two_iso_code === destinationCountryCurrency.iso_code.country_code) {
          destinationCountry = data.country
        }
      })

      this.sendoutCurrency = destinationCountryCurrency.iso_code.currency_code
      this.sendoutAmount = numeral(this.convertToRealAmount(payload.financials.originators_principal_amount)).format('0, 0.00')

      this.transferSummaryOverview = [
        { name: 'OAR', value: this.oarNumber.series_no },
        { name: 'Sender Name', value: this.formatWUName(payload.sender.name) },
        { name: 'Sender DOB', value: `${payload.sender.date_of_birth.substring(4, 8)}-${payload.sender.date_of_birth.substring(2, 4)}-${payload.sender.date_of_birth.substring(0, 2)}` },
        { name: 'Receiver Name', value: `${payload.receiver.name.first_name} ${payload.receiver.name.last_name}` },
        { name: 'Destination', value: destinationCountry },
        { name: 'Exchange Rate', value: `${orignatingCountryCurrency.iso_code.currency_code} 1.00 = ${payload.payment_details.exchange_rate}` },
        { name: 'Send Amount', value: `${orignatingCountryCurrency.iso_code.currency_code} ${numeral(this.convertToRealAmount(payload.financials.originators_principal_amount)).format('0, 0.00')}` },
        { name: 'Pay Amount', value: `${destinationCountryCurrency.iso_code.currency_code} ${numeral(this.convertToRealAmount(payload.financials.destination_principal_amount)).format('0, 0.00')}` },
        { name: 'Transfer Fee', value: `${orignatingCountryCurrency.iso_code.currency_code} ${numeral(this.convertToRealAmount(payload.financials.charges)).format('0, 0.00')}` },
        { name: 'Total Amount', value: `${orignatingCountryCurrency.iso_code.currency_code} ${numeral(this.convertToRealAmount(payload.financials.gross_total_amount)).format('0, 0.00')}` }
      ]

      this.populateIdItem(this.selectedCustomer)
    },
    prePrintReceipt () {
      if (this.$refs.sendFulfillForm.validate()) {
        console.log(this.currentSendFulfillTrx)
        if (this.ackFlag === true) {
          this.isPrePrintClicked = true
          if (this.currUser.has_wacom) {
            if (this.hasCustomerDataConsent) {
              this.$store.commit('wacom/SET_WACOM_CONSENT_DIALOG', true)
            } else {
              this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
            }
          } else {
            this.generatePrePrint()
          }
          this.renderToast('success', 'Pre-print receipt generated', 'The receipt was successfully generated. You may now process the transaction')
        } else {
          this.renderToast('error', 'Customer not verified!', 'You must verify the customer data first.')
        }
      } else {
        this.renderToast('error', 'Incomplete Data!', 'You must complete the form to process this transaction.')
      }
    },
    openVerifyOTP () {
      this.otp = ''
      if (this.isPrePrintClicked === true) {
        this.sendFulfillOTPDialog = true
      } else {
        this.renderToast('error', 'Pre-print receipt not yet generated!', 'You must generate a Pre-Print receipt first in order to process this transaction.')
      }
    },
    syncOTP (val) {
      this.otp = val
    },
    doCancel () {
      this.otp = ''
      this.sendFulfillOTPDialog = false
    },
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    async doVerify (pin) {
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        const payload = {
          user_name: this.currUser.user_name,
          otp_code: this.otp,
          pin: this.currUser.otp_pin,
          module: 'send'
        }

        const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)
        const isOTPVerified = OTPVerification.result?.value || false

        if (isOTPVerified) {
          this.otpInvalid = false
          this.isOTPLoading = false
          this.sendFulfillOTPDialog = false
          this.fulfillTransaction()
        } else {
          this.otp = ''
          this.otpInvalid = true
          this.isOTPLoading = false
          this.otpMessage = 'Incorrect OTP PIN, please try again.'
        }
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    },
    async fulfillTransaction () {
      const currentCustomer = this.selectedCustomer
      const currentTrx = this.currentSendFulfillTrx
      const trxData = this.transactionData
      // const orignatingCountryCurrency = currentTrx.payment_details.originating_country_currency
      const destinationCountryCurrency = currentTrx.payment_details.destination_country_currency
      // const origDestCountryCurency = currentTrx.payment_details.original_destination_country_currency

      const singleKYCLookupPayload = {
        foreign_reference_no: this.sendoutReleaseForeignRefNo,
        search_by: 'by_id',
        id_type: trxData.id_type,
        id_number: trxData.id_number,
        contact_phone: currentCustomer.mobile_no.substring(1),
        mywu_number: (currentCustomer.wu_card_no !== '0' || currentCustomer.wu_card_no !== null) ? currentCustomer.wu_card_no : '',
        first_name: this.formatWUFirstName(currentTrx.receiver.name),
        last_name: this.formatWULastName(currentTrx.receiver.name),
        is_multi: 'S',
        terminal_id: this.terminalId,
        operator_id: this.operatorId,
        location_id: this.locationId
      }

      let kycLookupCustomer = {}
      let customerComplianceBuffer = ''
      let galacticId = ''
      let wuNationality = ''

      await this.$store.dispatch('wu/kycLookup', singleKYCLookupPayload).then(res => {
        console.log(res)
        if (res.result) {
          kycLookupCustomer = res.result.customer
          customerComplianceBuffer = kycLookupCustomer.compliance_details.compliance_data_buffer
          galacticId = customerComplianceBuffer.substring(customerComplianceBuffer.indexOf('J6') + 4)
        }
      }).catch(err => {
        console.log(err)
      })

      const selectedNationality = this.collections.countries.filter(item => item.nationality === currentCustomer.nationality)

      if (selectedNationality.length === 1) {
        wuNationality = selectedNationality[0]?.country
      }

      const payload = {
        foreign_reference_no: this.sendoutReleaseForeignRefNo,
        sender_name_type: currentTrx.sender.name.name_type,
        sender_first_name: this.formatWUFirstName(currentTrx.sender.name),
        sender_middle_name: this.formatWUMiddleName(currentTrx.sender.name),
        sender_last_name: this.formatWULastName(currentTrx.sender.name),
        sender_addr_line1: currentCustomer.address,
        sender_addr_line2: currentCustomer.barangay,
        sender_city: currentCustomer.city1,
        sender_state: currentCustomer.province,
        sender_postal_code: currentCustomer.zip_code,
        sender_addr_country_code: currentTrx.sender.address.country_code.iso_code.country_code,
        sender_addr_currency_code: currentTrx.sender.address.country_code.iso_code.currency_code,
        sender_contact_phone: currentCustomer.tel_no,
        sender_mobile_country_code: currentCustomer.mobile_country_code.substring(1),
        sender_mobile_no: currentCustomer.mobile_no.substring(1),
        mywu_number: (currentCustomer.wu_card_no !== '0' || currentCustomer.wu_card_no !== null) ? currentCustomer.wu_card_no : '',
        id_type: trxData.id_type,
        id_country_of_issue: trxData.id_country_of_issue,
        id_number: trxData.id_number,
        id_issue_date: trxData.id_issue_date,
        id_expiration_date: trxData.id_expiration_date,
        date_of_birth: currentCustomer.birth_date,
        occupation: currentCustomer.occupation,
        Country_of_Birth: currentCustomer.country_birth,
        nationality: wuNationality,
        gender: currentCustomer.gender === 'female' ? 'F' : 'M',
        Source_of_Funds: currentCustomer.source_fund,
        Sender_Employeer: '',
        Relationship_to_Receiver: currentCustomer.relation_to,
        GEN_III_Indicator: '',
        ack_flag: this.ackFlag === true ? 'X' : '',
        reason_for_send: currentTrx.sender.compliance_details.transaction_reason,
        email: currentCustomer.email_add,
        receiver_name_type: currentTrx.receiver.name.name_type,
        receiver_first_name: this.formatWUFirstName(currentTrx.receiver.name),
        receiver_middle_name: this.formatWUMiddleName(currentTrx.receiver.name),
        receiver_last_name: this.formatWULastName(currentTrx.receiver.name),
        receiver_addr_line1: '',
        receiver_addr_line2: '',
        receiver_city: '',
        receiver_state: '',
        receiver_postal_code: '',
        receiver_addr_country_code: '',
        receiver_addr_currency_code: '',
        receiver_contact_phone: '',
        receiver_mobile_country_code: '',
        receiver_mobile_no: '',
        destination_country_code: destinationCountryCurrency.iso_code.country_code,
        destination_currency_code: destinationCountryCurrency.iso_code.currency_code,
        destination_state_code: '',
        destination_city_name: '',
        transaction_type: 'SO',
        principal_amount: currentTrx.financials.originators_principal_amount,
        fixed_amount_flag: 'F',
        promo_code: '',
        message: '[]',
        bank_name: '',
        account_number: '',
        bank_code: '',
        bank_location: '',
        account_suffix: '',
        terminal_id: this.terminalId,
        operator_id: this.operatorId,
        remote_terminal_id: this.currUser.fs_id,
        remote_operator_id: this.currUser.user_id,
        addl_service_charges: '010300002010030100103MSG02010030101103FEE120540000130540000962101045000020210030350099200101C02116PK0000135299170413wufxspd557487',
        originating_city: currentTrx.payment_details.originating_city,
        originating_state: currentTrx.payment_details.originating_state,
        mtcn: currentTrx.mtcn,
        new_mtcn: currentTrx.new_mtcn,
        mywu_enroll_tag: 'conv',
        compliance_data_buffer: '0108UNI_01_S0201C0311J04160045810411Philippines0611Philippines0708040319830813Self-Employed0908280520191008040320242401N2516Purchaser/Seller8811Philippines8911Philippines321097779455869901X3311Philippines3411Philippines3501M4407Savings2124Goods & Services payment6704none7016Purchaser/Seller7401YC1109777945586C402635735block 7 Armor village San Jose Gusu5914Zamboanga City6017ZAMBOANGA DEL SUR610470006211PhilippinesF901YG201MH604trueJ6193000000000207014747M118rhonielo@gmail.comO606PH_1.1', // `${customerComplianceBuffer}O606PH_1.1`
        staging_buffer: currentTrx.payment_details.stage_pay_fields.staging_buffer,
        test_question: '',
        answer: '',
        otp: this.otp,
        user_code: this.currUser.user_id,
        financials: currentTrx.financials,
        exchange_rate: '1',
        promotions: currentTrx.promotions,
        delivery_service: 'MONEY IN MINUTES',
        employment_position_level: '',
        Purpose_of_Transaction: currentCustomer.purpose_transaction,
        sender_addr_country_name: currentCustomer.country,
        is_on_behalf: 'N',
        galactic_id: galacticId || '',
        customer_code: currentCustomer.customer_number,
        client_reference_no: `SOR${this.sendoutReleaseForeignRefNo}`,
        wu_trx_category: 'SOR',
        location_id: this.locationId,
        remote_location_id: this.currUser.location_id,
        trx_date: this.currUser.trx_date,
        currency_id: '1',
        client_ip: '',
        customer_id: currentCustomer.customer_id,
        customer_name: `${this.formatWUFirstName(currentTrx.receiver.name)} ${this.formatWUMiddleName(currentTrx.receiver.name)}${this.formatWULastName(currentTrx.receiver.name)}`,
        dom_int: 'DMT',
        risk_score: currentCustomer.risk_score,
        risk_criteria: currentCustomer.risk_criteria,
        form_type: 'OAR',
        form_number: this.oarNumber.series_no
      }

      await this.$store.dispatch('wu/doFulfillSendTransaction', payload).then(res => {
        if (res.result) {
          let i = 0
          let j = 0
          let pinMessage = ''
          let promoTextMessage = ''

          for (i; i < res.result.confirmed_details.pin_message.length; i++) {
            if (res.result.confirmed_details.pin_message[i] !== '') {
              pinMessage += res.result.confirmed_details.pin_message[i] + ' '
            }
          }
          for (j; j < res.result.confirmed_details.promo_text_message.length; j++) {
            if (res.result.confirmed_details.promo_text_message[j] !== '') {
              promoTextMessage += res.result.confirmed_details.promo_text_message[j] + ' '
            }
          }
          window.open(`/receipt?ref=${this.oarNumber.series_no}&trx_type=wu&type=OAR`, '_blank')
          // window.open(`/receipt?location_id=${this.currUser.location_id}&trx_date=${this.currUser.trx_date}&ref_no=${this.oarNumber.series_no}&trx_type=wu&type=OAR`, '_blank')
          this.otp = ''
          this.$store.commit('obo/SET_REMOTE_DATA', {})
          this.renderToast('success', 'Send fulfill Successful', 'Transaction successful!')
          const printReceiptPayload = {}
          const receiverMiddleName = this.formatWUMiddleName(currentTrx.receiver.name)
          const senderMiddleName = this.formatWUMiddleName(currentTrx.sender.name)

          printReceiptPayload.print_data = {
            sender: currentTrx.sender.name.first_name + ' ' + senderMiddleName + ' ' + currentTrx.sender.name.last_name,
            address: this.selectedCustomer.address + ' ' + this.selectedCustomer.barangay + ' ' + this.selectedCustomer.municipality + ' ' + this.selectedCustomer.province + ' ' + this.selectedCustomer.zip_code,
            id_type: this.currentId.id_type,
            id_number: this.currentId.id_number,
            test_question: '',
            answer: '',
            sender_mobile_number: this.selectedCustomer.mobile_no,
            receiver: this.formatWUFirstName(currentTrx.receiver.name) + ' ' + receiverMiddleName + ' ' + this.formatWULastName(currentTrx.receiver.name),
            r_city: '',
            r_country: this.destinationCountry,
            service: '',
            message: '',
            receiver_mobile_number: '+' + currentTrx.receiver.mobile_phone.country_code + currentTrx.receiver.mobile_phone.national_number,
            mtcn: res.result.confirmed_details.mtcn.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
            date_time: this.formatDateTime(new Date()),
            agent_name: this.currUser.location_name + ' BRANCH',
            amount: (currentTrx.financials.originators_principal_amount / 100).toFixed(2),
            transfer_fee: (currentTrx.financials.charges / 100).toFixed(2),
            msg_charge: (currentTrx.financials.message_charge / 100).toFixed(2),
            delivery_charge: '0.00',
            promo_code: this.transactionData.promoCode,
            discount: '',
            total: 'Philippine Peso ' + (currentTrx.financials.gross_total_amount / 100).toFixed(2),
            exchange_rate: '1',
            payout_amount: destinationCountryCurrency.iso_code.currency_code + ' ' + (currentTrx.financials.destination_principal_amount / 100).toFixed(2),
            promo_text_message: promoTextMessage,
            pin_text_message_set_2: pinMessage,
            want_to_register: '',
            mywu_number: kycLookupCustomer.mywu_details.mywu_number,
            mywu_points: res.result.confirmed_details.new_points_earned,
            total_mywu_points: parseInt(kycLookupCustomer.mywu_details.current_yr_pts) + parseInt(res.result.confirmed_details.new_points_earned),
            adj_type: '',
            mc_rate: '',
            buyback_amount: '',
            total_amount: '',
            purpose_of_trxn: this.selectedCustomer.purpose_transaction,
            promo_flag: 'N',
            expiration_date: '',
            vuser_group_id: this.currUser.user_group_id
          }
          if (this.currUser.has_wacom) {
            printReceiptPayload.print_data.fla_signature = this.currUser.userSignature
            printReceiptPayload.print_data.customer_signature = this.customerSignature
          }
          printReceiptPayload.data = {
            has_wacom: this.currUser.has_wacom,
            trx_type: 1,
            remco_name: 'Western Union',
            control_number: res.result.confirmed_details.mtcn,
            customer_id: this.selectedCustomer.customer_id.toString(),
            location_id: this.currUser.location_id,
            location_name_uploaded: this.currUser.location_name,
            crn: this.selectedCustomer.customer_number,
            uploaded_by: this.currUser.user_id,
            first_name: currentTrx.sender.name.first_name,
            last_name: currentTrx.sender.name.last_name,
            product: 'WU Send Money Fulfill',
            receipt_type: 'acr'
          }
          this.generateACR(printReceiptPayload)
        } else {
          this.otp = ''
          this.renderToast('error', 'Error', res.message)
        }
      }).catch(err => {
        console.log(err)
      })

      console.log(payload)
    },
    async generateACR (payload) {
      const reqPrePrint = await this.$store.dispatch('wu/generateACRWU', payload)
      var decoded = Buffer.from(reqPrePrint.result, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
    },
    backToSearch () {
      this.$store.commit('wu/SET_SHOW_SENDOUT_FULFILL_SEARCH_FORM', true)
      this.$store.commit('wu/SET_SHOW_SENDOUT_FULFILL_RESULT_FORM', false)
      this.$store.commit('wu/SET_CURRENT_SENDOUT_FULFILL_TRANSACTION', {})
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
      this.$store.commit('SET_PAGE_TITLE', 'Fulfillment')
    },
    populateIdItem (currentCustomer) {
      let getIdType = []

      const idList = (currentCustomer.img !== '{}' && currentCustomer.img !== '') ? JSON.parse(currentCustomer.img) : {}

      let currentId = {}
      if (currentCustomer.img !== '{}' && currentCustomer.img !== '') {
        currentId = idList['1'] ? idList['1'] : {}
        this.currentId = currentId
        getIdType = this.collections.wuIDList.filter(item => {
          return item.id_type === currentId.id_type
        })
      } else {
        this.noIDFlag = true
      }

      const updatedPayload = {
        id_country_of_issue: currentId?.country ? currentId.country : '',
        id_type: getIdType[0]?.id_value ? getIdType[0].id_value : '',
        id_number: currentId?.id_number ? currentId.id_number : '',
        id_issue_date: currentId?.issue ? currentId.issue : '',
        id_expiration_date: currentId.expiry ? currentId.expiry : '',
        Does_the_ID_have_an_expiration_date: getIdType[0]?.hasExpiration ? getIdType[0].hasExpiration : 'N'
      }

      this.transactionData = Object.assign({}, this.transactionData, updatedPayload)
      this.transactionData.requiredField = this.requiredField
    },
    toggleForm (step) {
      this.payoutForm = step

      switch (step) {
        case 1:
          this.$store.commit('SET_PAGE_TITLE', 'Transfer Information')
          break
        case 2:
          this.$store.commit('SET_PAGE_TITLE', 'Send Fulfill Details')
          break
      }
    },
    updateDetails (customerDetails) {
      this.noIDFlag = false

      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    async generateConsent () {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: 'WU Send Money Fulfill',
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    }
  }
}
</script>
<style scoped>
  .v-stepper.ph-wu-receive-stepper .v-stepper__header {
    box-shadow: none !important;
  }

  .ph-wu-receive-summary .v-card {
    padding: 20px;
  }

  .ph-transfer-summary-header {
    font-size: 18px;
    text-align: center;
  }

  .ph-transfer-summary-content-title {
    font-size: 14px;
  }

  .transfer-info-title,
  .transfer-info-value {
    border-bottom: thin dashed rgba(0,0,0,.12) !important;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
    border: 2px solid #1A2791;
  }

  .ph-transaction-container {
    padding: 0;
  }

  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 100px;
  }

  .ph-primary-button.ph-stepper-button {
    height: 40px !important;
    width: 115px;
  }

  /* @media only screen and (min-width: 600px) {
    .v-stepper.ph-wu-receive-stepper .v-stepper__step {
      padding-left: 0;
    }
  } */

  .ph-wu-receive-summary-minified {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F4F4FA;
  }

  .ph-wu-receive-summary-content {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
  }

  .ph-wu-receive-summary-minified-info {
    margin-right: auto;
  }

  .ph-wu-receive-summary-minified-info p {
    margin-bottom: 0;
  }

  .ph-wu-receive-summary-minified-action {
    margin-left: auto;
  }

  .ph-wu-receive-form {
    padding: 12px 0;
  }

  @media only screen and (min-width: 960px) {
    .ph-stepper-action-buttons {
      display: flex;
      margin-bottom: 0;
    }
  }
</style>
