<template>
  <v-row>
    <v-col :cols="12" :md="3">
      <v-text-field
        v-model="search"
        class="ph-textbox"
        placeholder="Search Location Limit"
        append-icon="mdi-magnify"
        hide-details
        outlined
      />
    </v-col>
    <v-col class="d-flex align-center" :cols="12" :md="9">
      <v-btn
        @click="upload"
        class="text-capitalize font-weight-bold"
        color="primary"
        rounded
        large
        text
      >
        <v-icon class="mr-1">mdi-upload</v-icon>
        Upload File
      </v-btn>
      <v-btn
        @click="getLocationLimits"
        class="text-capitalize font-weight-bold"
        color="primary"
        rounded
        large
        text
      >
        <v-icon class="mr-1">mdi-refresh</v-icon>
        Refresh
      </v-btn>
      <input
        @change="handleFile"
        ref="fileInput"
        type="file"
        class="d-none"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      >
    </v-col>
    <v-col :cols="12">
      <v-card flat>
        <v-data-table
          :headers="locationLimitHeaders"
          :items="locationLimitData"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:item.max_amount="{ item }">
            {{ toMoney(item.max_amount) }}
          </template>
          <template v-slot:item.status="{ item }">
            <span :class="[getTransactionLimitStatus(item.status).class]">
              <v-icon
                :class="['mr-1', getTransactionLimitStatus(item.status).class]"
                x-small
              >
                mdi-circle
              </v-icon>
              {{ getTransactionLimitStatus(item.status).text }}
            </span>
          </template>
          <template v-slot:item.action="{ item }" v-if="approverLevel === 'ROH'">
            <v-btn @click="edit(item)" color="primary" :disabled="item.status !== 1 && item.status !== 4" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="openDeleteDialog(item)" color="primary" :disabled="item.status !== 1 && item.status !== 4" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog
      v-model="showDeleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Location Limit
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this location limit?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDeleteDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="doDelete"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import * as xlsx from 'xlsx'
import { getTransactionLimitStatus, toMoney, renderToast, getFileExtension } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      selectedLocationLimit: 'transactionLimit/selectedLocationLimit',
      currUser: 'auth/currUser',
      approverLevel: 'transactionLimit/approverLevel'
    })
  },
  data () {
    return {
      showDeleteDialog: false,
      search: '',
      locationLimitData: [],
      locationLimitHeaders: [
        {
          text: 'Branch Name',
          value: 'location_name',
          width: '150px'
        },
        {
          text: 'Provider',
          value: 'provider_name',
          width: '150px'
        },
        {
          text: 'Service Name',
          value: 'service_name',
          width: '150px'
        },
        {
          text: 'Service Type',
          value: 'service_type',
          width: '150px'
        },
        {
          text: 'Current Limit',
          value: 'max_amount',
          width: '150px'
        },
        {
          text: 'Channel',
          value: 'channel',
          width: '150px'
        },
        {
          text: 'Status',
          value: 'status',
          width: '150px'
        },
        {
          text: 'Action',
          value: 'action',
          width: '100px',
          sortable: false
        }
      ]
    }
  },
  methods: {
    handleFile (e) {
      const that = this
      const newFile = e.target.files[0]

      if (newFile) {
        const ext = getFileExtension(newFile.name)

        if (ext === 'csv' || ext === 'xls' || ext === 'xlsx') {
          const reader = new FileReader()

          reader.onload = async function (e) {
            const data = new Uint8Array(e.target.result)
            const workbook = xlsx.read(data, { type: 'array' })
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            let records = xlsx.utils.sheet_to_json(worksheet)
            records = records.filter(item => {
              const {
                'Location ID': locationID,
                'Provider Name': providerName,
                'Provider Code': providerCode,
                'Service Name': serviceName,
                'Service Type': serviceType,
                Amount: amount,
                Channel: channel,
                Remarks: remarks
              } = item
              return (locationID && providerName && providerCode && serviceName && serviceType && amount && channel && remarks)
            })

            if (records.length > 0) {
              const payload = records.map(item => {
                const {
                  'Location ID': locationID,
                  'Provider Name': providerName,
                  'Provider Code': providerCode,
                  'Service Name': serviceName,
                  'Service Type': serviceType,
                  Amount: amount,
                  Channel: channel,
                  Remarks: remarks
                } = item

                return {
                  location_id: locationID,
                  is_dsa: false,
                  dsa_code: null,
                  provider_name: providerName,
                  provider_code: providerCode,
                  service_name: serviceName,
                  service_type: serviceType,
                  max_req_amount: amount,
                  max_amount: amount,
                  channel: channel,
                  requestor: that.currUser.user_id,
                  remarks: remarks
                }
              })

              const { code, result } = await that.$store.dispatch('transactionLimit/bulkValidateLocationLimit', payload)

              if (code === 200) {
                that.$store.commit('transactionLimit/SET_FILE_NAME', newFile.name)
                that.$store.commit('transactionLimit/SET_UPLOADED_DATA', result)
                that.$router.push('/transaction-limit/defaults/upload')
              } else {
                renderToast('error', 'Upload Failed', 'Failed to validate the uploaded file')
              }
            } else {
              renderToast('error', 'Upload Failed', 'The file you have selected is not valid')
            }
          }

          reader.readAsArrayBuffer(newFile)
        } else {
          renderToast('error', 'Upload Failed', 'The file you have selected is not valid')
        }
      }
    },
    upload () {
      this.$refs.fileInput.click()
    },
    edit (payload) {
      this.$store.commit('transactionLimit/SET_SELECTED_LOCATION_LIMIT', payload)
      this.$router.push(`/transaction-limit/defaults/location/${payload.id}`)
    },
    async doDelete () {
      if (this.selectedLocationLimit) {
        const { code } = await this.$store.dispatch('transactionLimit/deleteLocationLimit', {
          id: this.selectedLocationLimit.id,
          userID: this.currUser.user_id
        })

        if (code === 200) {
          renderToast('success', 'Successful', 'Location Limit has been deleted')
          this.closeDeleteDialog()
          this.getLocationLimits()
        } else {
          // Add error code
        }
      }
    },
    openDeleteDialog (payload) {
      this.$store.commit('transactionLimit/SET_SELECTED_LOCATION_LIMIT', payload)
      this.showDeleteDialog = true
    },
    closeDeleteDialog () {
      this.$store.commit('transactionLimit/SET_SELECTED_LOCATION_LIMIT', null)
      this.showDeleteDialog = false
    },
    async getLocationLimits () {
      const { code, result } = await this.$store.dispatch('transactionLimit/getLocationLimit')

      if (code === 200) {
        this.locationLimitData = result.sort((x, y) => {
          return (new Date(y.created_at).getTime() - new Date(x.created_at).getTime())
        })
      }
    },
    getTransactionLimitStatus,
    toMoney
  },
  mounted () {
    this.getLocationLimits()
  }
}
</script>
