<template>
  <v-row class="ph-wu-receive-forms-container">
    <v-col cols="12" v-if="showRemoteNotice">
      <v-alert
        color="info"
        dark
        icon="mdi-remote"
        border="left"
        prominent
      >
        This transaction will be processed on behalf of <b>{{remoteData.remote_location.location_name}}</b> branch.
      </v-alert>
    </v-col>
    <v-col cols="12" md="8" class="ph-wu-receive-form">
      <v-form ref="receiveForm">
        <v-btn text class="text-none" @click="backToSearch">
          <v-icon>mdi-arrow-left</v-icon> Back to Search
        </v-btn>
        <v-stepper v-model="payoutForm" elevation="0" class="ph-wu-receive-stepper">
          <v-stepper-header>
            <v-stepper-step
              :complete="payoutForm > 1"
              step="1"
            >
              Transfer Information
            </v-stepper-step>
            <v-divider class="ph-wu-receive-stepper-divider"></v-divider>
            <v-stepper-step
              :complete="payoutForm > 2"
              step="2"
            >
              Receiver Details
            </v-stepper-step>
            <v-divider v-if="payoutCurrency !== 'PHP'" class="ph-wu-receive-stepper-divider"></v-divider>
            <v-stepper-step v-if="payoutCurrency !== 'PHP'"
              :complete="payoutForm > 3"
              step="3"
            >
              Buy Back
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <ReceiveMoneyTransferInfo v-bind:selectedTrxData="selectedTrxData" />
              <v-row>
                <v-col cols="12" class="ph-stepper-action-buttons">
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
                    @click="toggleForm(2)"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
              <ReceiveMoneyIDInfo v-bind:transactionData="transactionData"/>
              <v-row>
                <v-col cols="12" class="ph-stepper-action-buttons">
                  <v-btn
                    color="danger"
                    outlined
                    text
                    light
                    class="ph-primary-button ph-stepper-button ph-action-button"
                    @click="toggleForm(1)"
                  >
                    Back
                  </v-btn>
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
                    @click="toggleForm(3)"
                    v-if="payoutCurrency !== 'PHP'"
                  >
                    Next
                  </v-btn>
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
                    @click="summaryDialog = true"
                    v-if="payoutCurrency === 'PHP' && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="3" v-if="payoutCurrency !== 'PHP'">
              <ReceiveMoneyWUBuyBack v-bind:transactionData="transactionData" v-bind:currentTrx="currentTrx" v-bind:buyBackRateList="buyBackRateList"/>
              <v-row>
                <v-col cols="12" class="ph-stepper-action-buttons">
                  <v-btn
                    color="danger"
                    outlined
                    text
                    light
                    class="ph-primary-button ph-stepper-button ph-action-button"
                    @click="toggleForm(2)"
                  >
                    Back
                  </v-btn>
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
                    @click="summaryDialog = true"
                    v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="4" class="ph-wu-receive-summary">
      <v-card
        elevation="0"
        color="rgba(26, 39, 145, 0.05)"
      >
        <div class="ph-transfer-summary-header">
          <b>Summary Overview</b>
        </div>
        <br>
        <div class="ph-transfer-summary-content">
          <p class="ph-transfer-summary-content-title">Please confirm the payout details are correct</p>
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in transferSummaryOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="ph-transfer-summary-verify">
          <v-checkbox
            v-model="ackFlag"
            label="I have Verified Customer Data"
          ></v-checkbox>
        </div>
        <wacom-consent />
        <wacom-data-consent-first />
        <wacom-data-consent-second />
        <wacom-data-consent-third />
        <wacom-data-consent-fourth />
        <wacom-data-consent-fifth />
        <wacom-data-consent-sixth />
        <wacom-data-consent-seventh
          :remco="'Western Union'"
        />
        <wacom-transaction
          v-if="currUser.has_wacom"
          @submit-sig="submitSig" type="transactional"
          :referenceSignatures="customerSignaturesReference"
          :trxData="{sender_name: `${formatWULastName(currentTrx.result.payment_transaction.sender.name) + (formatWULastName(currentTrx.result.payment_transaction.sender.name) !== '' ? ',' : '')} ${formatWUFirstName(currentTrx.result.payment_transaction.sender.name)}`, receiver_name: `${formatWULastName(currentTrx.result.payment_transaction.receiver.name)}, ${formatWUFirstName(currentTrx.result.payment_transaction.receiver.name)}`, total_amount: transactionData.buy_back ? transactionData.total_amount : currentTrx.result.net_payout.toFixed(2)}"
        />
        <div class="ph-transfer-summary-action">
          <v-btn
            color="danger"
            outlined
            text
            light
            class="ph-primary-button ph-action-button"
            :disabled="ackFlag === false"
            @click="doRiskProfiling"
          >
            PRE-PRINT
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            :disabled="isPrePrintClicked === false"
            class="ph-primary-button ph-primary-execute-btn"
            @click="payoutTransaction"
          >
            PAYOUT NOW
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-wu-receive-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-wu-receive-summary-minified">
          <div class="ph-wu-receive-summary-content">
            <div class="ph-wu-receive-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>{{payoutCurrency}} {{payoutAmount}}</b></p>
            </div>
            <div class="ph-wu-receive-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <div class="ph-transfer-summary-content">
                <p class="ph-transfer-summary-content-title">Please confirm the payout details are correct</p>
                <v-simple-table class="ph-transfer-summary-table">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in transferSummaryOverview"
                        :key="item.name"
                      >
                        <td class="transfer-info-title">{{ item.name }}</td>
                        <td class="transfer-info-value">{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="ph-transfer-summary-verify">
                <v-checkbox
                  v-model="ackFlag"
                  label="I have Verified Customer Data"
                ></v-checkbox>
              </div>
              <div class="ph-transfer-summary-action">
                <v-btn
                  color="danger"
                  outlined
                  text
                  light
                  class="ph-primary-button ph-action-button"
                  :disabled="ackFlag === false"
                  @click="doRiskProfiling"
                >
                  PRE-PRINT
                </v-btn>
                <v-btn
                  color="primary"
                  elevation="0"
                  :disabled="isPrePrintClicked === false"
                  class="ph-primary-button ph-primary-execute-btn"
                  @click="payoutTransaction"
                >
                  PAYOUT NOW
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noIDFlag"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          No identification card found
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="noIDFlag = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="updateDetails(selectedCustomer)"
          >
            Update Customer Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="validationDialog" persistent>
      <v-card>
        <v-card-title class="ph-dialog-title">
          Form Validation
        </v-card-title>
        <v-card-text class="ph-dialog-header">
          <br>
          There are errors in the form submitted. Please refer to the message below:
          <br>
          <span v-html="validationMessage"></span>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="validationDialog = false"
          >
            Ok, got it.
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import ReceiveMoneyTransferInfo from '@/components/wu/ReceiveMoneyTransferInfo'
import ReceiveMoneyIDInfo from '@/components/wu/ReceiveMoneyIDInfo'
import ReceiveMoneyWUBuyBack from '@/components/wu/ReceiveMoneyWUBuyBack'
import wacomTransaction from '@/components/wacom/wacomTransaction.vue'
import wacomConsent from '@/components/wacom/wacomConsent.vue'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { convertToRealAmount, formatMTCN, renderToast, formatWUName, formatWULastName, formatWUFirstName, formatWUMiddleName } from '@/utils'
import { format } from 'date-fns'
import wacomMixin from '@/mixins/wacom.js'
import { validateWuReceive } from '@/utils/validations/wu'

export default {
  name: 'ReceiveMoneyWUForm',
  mixins: wacomMixin,
  components: {
    ReceiveMoneyTransferInfo,
    ReceiveMoneyIDInfo,
    ReceiveMoneyWUBuyBack,
    wacomConsent,
    wacomTransaction,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  data () {
    return {
      payoutForm: 1,
      transactionData: {},
      selectedTrxData: {},
      ackFlag: false,
      isPrePrintClicked: false,
      currentId: '',
      originCurrencyName: '',
      destCurrencyName: '',
      originatingCountry: '',
      destinationCountry: '',
      customerSignature: '',
      customerSignaturesReference: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      noIDFlag: false,
      summaryDialog: false,
      payoutCurrency: '',
      payoutAmount: '',
      showRemoteNotice: false,
      transferSummaryOverview: [
        { name: 'MTCN', value: '-' },
        { name: 'Sender Name', value: '-' },
        { name: 'Receiver Name', value: '-' },
        { name: 'Originating', value: '-' },
        { name: 'Destination', value: '-' },
        { name: 'Exchange Rate', value: '-' },
        { name: 'Transfer Fee', value: '-' },
        { name: 'Send Amount', value: '-' },
        { name: 'Payout Amount', value: '-' }
      ],
      buyBackRateList: [{
        buy_am_rate: '',
        bp_mc_rate: '',
        category: '',
        date_updated: '',
        mc_rate_id: '',
        remarks: '',
        sell_am_rate: '',
        trx_date: ''
      }],
      requiredField: [
        v => !!v || 'This field is required'
      ],
      hasCustomerDataConsent: false,
      validationDialog: false,
      validationMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      currentTrx: 'wu/currentTrx',
      payoutFormData: 'wu/payoutFormData',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser',
      payoutForeignRefNo: 'wu/payoutForeignRefNo',
      collections: 'collections',
      payoutCurrencyType: 'wu/payoutCurrencyType',
      remoteData: 'obo/remoteData',
      consentValues: 'wacom/consentValues',
      riskData: 'compliance/riskData'
    })
  },
  watch: {
    currentTrx: {
      handler (returnVal) {
        console.log(returnVal)

        if (returnVal) {
          this.selectedTrxData = returnVal.result.payment_transaction
          this.populateOverview(this.selectedTrxData)
        }
      },
      deep: true
    },
    selectedCustomer: {
      handler (val) {
        console.log(val)
      },
      deep: true
    },
    ackFlag: {
      handler (val) {
        if (val === false) {
          this.isPrePrintClicked = false
        }
      },
      deep: true
    }
  },
  beforeMount () {
    this.transactionData = this.payoutFormData
  },
  async mounted () {
    if (this.currentTrx) {
      this.selectedTrxData = this.currentTrx.result.payment_transaction
      this.populateOverview(this.selectedTrxData)
    } else {
      this.selectedTrxData = {}
    }
    console.log(this.currentTrx, 'current trx')
    console.log(this.selectedCustomer, 'selected customer')
    this.transactionData = this.payoutFormData
    this.populateData(this.selectedCustomer)
    const that = this
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    const signatures = await that.$store.dispatch('customers/retrieveSignatures', { customer_id: that.selectedCustomer.customer_id })
    that.customerSignaturesReference = signatures.result
    this.checkConsent()
    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData.remote_location.location_id
      this.showRemoteNotice = true
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
      this.showRemoteNotice = false
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
      this.showRemoteNotice = false
    }

    this.$store.commit('SET_PAGE_TITLE', 'Transfer Information')
  },
  methods: {
    validateWuReceive,
    async doRiskProfiling () {
      if (
        this.currUser.cisrat === 1 &&
        String(this.selectedCustomer.edd_upload_status) !== '3'
      ) {
        const [sourceOfFund] = this.collections.sourceOfFund.filter(item => item.source_of_funds === this.selectedCustomer.source_fund)
        const [purposeOfTransaction] = this.collections.purposeOfTransaction.filter(item => item.purpose === this.selectedCustomer.purpose_transaction)
        const [relationship] = this.collections.relationship.filter(item => item.relationship === this.selectedCustomer.relation_to)
        const [occupation] = this.collections.occupation.filter(item => item.occupation_value === this.selectedCustomer.occupation)

        this.$store.commit('compliance/UPDATE_RISK_DATA', {
          spro: {
            source_score: sourceOfFund?.risk_score || '',
            purpose_score: purposeOfTransaction?.risk_score || '',
            relationship_score: relationship?.risk_score || '',
            occupation_score: occupation?.risk_score || ''
          }
        })

        const payload = {
          trans_details: {
            dom_int: (this.originatingCountry === 'Philippines') ? 'DMT' : 'IMT',
            source_fund: sourceOfFund ? String(sourceOfFund.id) : '',
            purpose_transaction: purposeOfTransaction ? String(purposeOfTransaction.id) : '',
            relation_to: relationship ? String(relationship.id) : '',
            occupation: occupation ? String(occupation.id) : '',
            wu_compliance: `{"edd_upload_status": "${this.selectedCustomer.edd_upload_status}"}`,
            principal_amount: this.payoutAmount,
            wu_country_code_paid: 'PH'
          }
        }

        if (this.riskData.spro) payload.spro = JSON.stringify(this.riskData.spro)
        if (this.riskData.getSum) payload.tf = JSON.stringify(this.riskData.getSum)
        if (this.riskData.searchPNA) payload.pna = JSON.stringify(this.riskData.searchPNA)

        const { code, result } = await this.$store.dispatch('compliance/riskProfiling', payload)

        if (code === 200) {
          let eddStatus
          const reasons = []

          this.$store.commit('compliance/UPDATE_RISK_DATA', { riskProfiling: result })

          if (result.risk_criteria) {
            const riskCriteria = result.risk_criteria

            riskCriteria.forEach(item => {
              if (item.criteria !== 'Fraudsters' && item.criteria !== 'Negative List' && item.criteria !== 'PEP') {
                reasons.push(item.criteria)
              }
            })
          }

          if (String(this.selectedCustomer.edd_upload_status) === '1') eddStatus = 'Need to Upload Documents'
          if (String(this.selectedCustomer.edd_upload_status) === '2') eddStatus = 'Documents under review for compliance'
          if (String(this.selectedCustomer.edd_upload_status) === '4') eddStatus = 'Not Approve/Pending Documents'

          if (String(result.risk_score) === '3' || String(result.risk_score) === '4') {
            await this.$store.dispatch('compliance/saveRisk', {
              trans_details: {
                dom_int: (this.originatingCountry === 'Philippines') ? 'DMT' : 'IMT',
                trx_type: 'WU Receive',
                trx_date: this.currUser.trx_date,
                customer_name: this.selectedCustomer.full_name,
                risk_score: result.risk_score,
                curr_risk_score: this.selectedCustomer.risk_score,
                risk_criteria: (result.risk_criteria) ? JSON.stringify(result.risk_criteria) : '[]',
                customer_id: this.selectedCustomer.customer_id,
                monitoring_type: '1',
                principal_amount: this.payoutAmount
              },
              location_name: this.currUser.location_name,
              location_id: this.currUser.location_id,
              user_id: this.currUser.user_id,
              service_id: '1',
              update_status_by: this.currUser.user_id,
              first_name: this.selectedCustomer.first_name,
              last_name: this.selectedCustomer.last_name,
              compliance_remarks: this.selectedCustomer.compliance_edd_remarks,
              fp_remarks: '',
              false_positive: '',
              edd_alert: 'true',
              _is_not_fraud: '0',
              criteria: (result.risk_criteria) ? JSON.stringify(result.risk_criteria) : '[]',
              reference_no: '',
              actual_date_trx: this.currUser.trx_date
            })
          }

          if (String(result.risk_score) === '4') {
            this.$store.commit('compliance/UPDATE_HIGH_RISK_DIALOG', {
              isShow: true,
              type: 'FOUND_HIGH_RISK_STOP',
              details: {
                status: eddStatus,
                remarks: this.selectedCustomer.compliance_edd_remarks,
                reasons: reasons.join(', ')
              },
              customer: this.selectedCustomer
            })
            this.$router.push('/pera-remit/send')
            return true
          }
          if (String(result.risk_score) === '3') {
            this.$store.commit('compliance/UPDATE_HIGH_RISK_DIALOG', {
              isShow: true,
              type: 'FOUND_HIGH_RISK_GO',
              details: {
                status: eddStatus,
                remarks: this.selectedCustomer.compliance_edd_remarks,
                reasons: reasons.join(', ')
              },
              customer: this.selectedCustomer
            })
          }
          this.prePrintReceipt()
        } else {
          renderToast('error', 'API Error', 'Failed to risk profiling')
        }
      } else {
        this.prePrintReceipt()
      }
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    submitSig (emitted) {
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
      this.customerSignature = emitted
      if (this.hasCustomerDataConsent === false) {
        this.generateConsent()
      }
      this.generatePrePrint()
    },
    async generatePrePrint () {
      const currentTrx = this.currentTrx.result.payment_transaction
      const trxData = this.transactionData
      const prePrintPayload = {}
      const receiverMiddleName = this.formatWUMiddleName(currentTrx.receiver.name)
      const senderMiddleName = this.formatWUMiddleName(currentTrx.sender.name)
      const filteredOrigin = this.collections.countries.filter(function (e) {
        return e.currency === currentTrx.payment_details.originating_country_currency.iso_code.currency_code
      })
      const filteredDest = this.collections.countries.filter(function (e) {
        return e.currency === currentTrx.payment_details.destination_country_currency.iso_code.currency_code
      })
      this.originCurrencyName = filteredOrigin[0].currency_name
      this.destCurrencyName = filteredDest[0].currency_name
      console.log(filteredOrigin, 'filtered origin')

      const getIdType = this.collections.wuIDList.filter(item => {
        return item.id_value === trxData.id_type
      })

      // Updates current Id value
      this.currentId = {
        id_type: getIdType[0]?.id_type,
        id_number: trxData.id_number,
        id_expiration: trxData.id_expiration_date
      }

      const wuNationality = trxData.nationality.country

      const getRelationship = await this.collections.relationship.filter(item => {
        return item.relationship_value === trxData.Relationship_to_Receiver
      })

      prePrintPayload.print_data = {
        receiver_name: this.formatWUFirstName(currentTrx.receiver.name) + ' ' + receiverMiddleName + this.formatWULastName(currentTrx.receiver.name),
        sender_name: this.formatWUFirstName(currentTrx.sender.name) + ' ' + senderMiddleName + this.formatWULastName(currentTrx.sender.name),
        mywu_number: (this.selectedCustomer.wu_card_no !== '0' || this.selectedCustomer.wu_card_no !== null) ? this.selectedCustomer.wu_card_no : '',
        points_earned: '0',
        total_wu_points: '',
        id_type: this.currentId.id_type,
        id_number: this.currentId.id_number,
        id_expiration: this.currentId.id_expiration,
        tin_number: '',
        sss_gsis_number: '',
        occupation: trxData.occupation,
        name_of_employer: '',
        date_of_birth: this.selectedCustomer.birth_date,
        country_of_birth: trxData.Country_of_Birth,
        nationality: wuNationality,
        purpose_of_transaction: trxData.Purpose_of_Transaction,
        source_of_funds: trxData.Source_of_Funds,
        relationship: getRelationship[0].relationship,
        present_address_line1: this.selectedCustomer.address,
        present_address_line2: this.selectedCustomer.barangay,
        present_city: this.selectedCustomer.municipality,
        present_state: this.selectedCustomer.province,
        present_postcode: this.selectedCustomer.zip_code,
        permanent_address_line1: this.selectedCustomer.street1,
        permanent_address_line2: this.selectedCustomer.barangay1,
        permanent_city: this.selectedCustomer.city1,
        permanent_state: this.selectedCustomer.province1,
        permanent_postcode: this.selectedCustomer.zip_code1,
        landline: '',
        mobile_no: this.selectedCustomer.mobile_no,
        mtcn: '',
        date_and_time: this.formatDateTime(new Date()),
        agent_details: this.currUser.location_name + ' BRANCH',
        originating_country: this.originatingCountry,
        city: currentTrx.payment_details.expected_payout_location.city + ' ' + currentTrx.payment_details.expected_payout_location.state_code,
        amount_receive: this.currentTrx.result.gross_payout.toFixed(2),
        total: this.formatAmount(this.currentTrx.result.net_payout.toFixed(2)),
        pay_amount: (currentTrx.financials.principal_amount / 100).toFixed(2),
        exchange_rate: '',
        dst_pay_amount: this.currentTrx.result.dst,
        cash: this.formatAmount(this.currentTrx.result.net_payout.toFixed(2)),
        checque: '',
        checque_number: '',
        credit_to_bank_account: '',
        account_number: '',
        amount_sent: '',
        transfer_fee: '',
        delivery_charge: '',
        discount: '',
        destination_country: '',
        originating_curr: this.originCurrencyName,
        destination_curr: this.destCurrencyName,
        message_charge: '',
        mc_rate: '',
        buyback_amount: '',
        total_amount: '',
        message: ''
      }
      if (this.currUser.has_wacom) {
        prePrintPayload.print_data.fla_signature = this.currUser.userSignature
        prePrintPayload.print_data.customer_signature = this.customerSignature
      }
      prePrintPayload.data = {
        has_wacom: this.currUser.has_wacom,
        trx_type: 2,
        remco_name: 'Western Union',
        control_number: currentTrx.mtcn,
        customer_id: this.selectedCustomer.customer_id.toString(),
        location_id: this.currUser.location_id,
        location_name_uploaded: this.currUser.location_name,
        crn: this.selectedCustomer.customer_number,
        uploaded_by: this.currUser.user_id,
        first_name: this.formatWUFirstName(this.currentTrx.result.payment_transaction.receiver.name),
        last_name: this.formatWULastName(this.currentTrx.result.payment_transaction.receiver.name),
        product: 'WU Receive Money',
        receipt_type: 'pre'
      }

      if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
        prePrintPayload.print_data.agent_details = this.remoteData.remote_location.location_name + ' BRANCH'
        prePrintPayload.data.location_id = this.remoteData?.remote_location?.location_id
        prePrintPayload.data.uploaded_by = this.remoteData.remote_user
        prePrintPayload.data.location_name_uploaded = this.remoteData.remote_location.location_name
      } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
        prePrintPayload.print_data.agent_details = this.currUser.location_name + ' BRANCH'
        prePrintPayload.data.location_id = this.currUser.location_id
        prePrintPayload.data.uploaded_by = this.currUser.user_id
        prePrintPayload.data.location_name_uploaded = this.currUser.location_name
      }

      if (this.payoutCurrencyType !== 'PHP') {
        prePrintPayload.print_data.adj_type = trxData.adj_type ? trxData.adj_type : ''
        prePrintPayload.print_data.mc_rate = trxData.mc_rate ? trxData.mc_rate : ''
        prePrintPayload.print_data.buyback_amount = trxData.buy_back ? trxData.buy_back : ''
        prePrintPayload.print_data.rate_category = trxData.rate_category ? trxData.rate_category : ''
        prePrintPayload.print_data.mc_rate_id = trxData.mc_rate_id ? trxData.mc_rate_id : ''
        prePrintPayload.print_data.total_amount = trxData.total_amount ? trxData.total_amount : ''
      }

      console.log(prePrintPayload)
      const reqPrePrint = await this.$store.dispatch('wu/generateACRWU', prePrintPayload)
      var decoded = Buffer.from(reqPrePrint.result, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
      this.isPrePrintClicked = true
      this.renderToast('success', 'Pre-print receipt generated', 'The receipt was successfully generated. You may now process the transaction')
    },
    formatDateTime (date) {
      return format(date, 'yyyy-MM-dd HH:mm:SS')
    },
    convertToRealAmount,
    formatMTCN,
    renderToast,
    formatWUName,
    formatWULastName,
    formatWUFirstName,
    formatWUMiddleName,
    updateDetails (customerDetails) {
      this.noIDFlag = false

      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    async populateOverview (payload) {
      console.log(payload)
      const orignatingCountryCurrency = payload.payment_details.originating_country_currency
      const destinationCountryCurrency = payload.payment_details.destination_country_currency

      let originatingCountry = ''
      let destinationCountry = ''

      this.collections.countries.filter(data => {
        if (data.two_iso_code === orignatingCountryCurrency.iso_code.country_code) {
          originatingCountry = data.country
        }

        if (data.two_iso_code === destinationCountryCurrency.iso_code.country_code) {
          destinationCountry = data.country
        }
      })

      this.originatingCountry = originatingCountry
      this.destinationCountry = destinationCountry
      this.payoutCurrency = destinationCountryCurrency.iso_code.currency_code
      this.payoutAmount = numeral(this.convertToRealAmount(payload.financials.pay_amount)).format('0, 0.00')

      if (this.payoutCurrency === 'USD') {
        const mcPayload = {
          trxDate: this.currUser.trx_date,
          locationId: this.currUser.location_id,
          currencyId: this.payoutCurrencyType === 'PHP' ? '1' : '2'
        }

        await this.$store.dispatch('wu/getMCRate', mcPayload).then(res => {
          const updatedItem = res.result
          const currentItem = this.buyBackRateList
          const mergedItem = currentItem.concat(updatedItem)

          this.buyBackRateList = mergedItem
        }).catch(e => {
          console.log(e)
        })
      }

      this.transferSummaryOverview = [
        { name: 'MTCN', value: this.formatMTCN(payload.mtcn) },
        { name: 'Sender Name', value: this.formatWUName(payload.sender.name) },
        { name: 'Receiver Name', value: this.formatWUName(payload.receiver.name) },
        { name: 'Originating', value: originatingCountry },
        { name: 'Destination', value: destinationCountry },
        { name: 'Exchange Rate', value: numeral(payload.payment_details.exchange_rate).format('0, 0.00') },
        { name: 'Transfer Fee', value: destinationCountryCurrency.iso_code.currency_code + ' ' + numeral(this.convertToRealAmount(payload.financials.charges)).format('0, 0.00') },
        { name: 'Send Amount', value: orignatingCountryCurrency.iso_code.currency_code + ' ' + numeral(this.convertToRealAmount(payload.financials.principal_amount)).format('0, 0.00') },
        { name: 'Payout Amount', value: destinationCountryCurrency.iso_code.currency_code + ' ' + numeral(this.convertToRealAmount(payload.financials.pay_amount)).format('0, 0.00') }
      ]
    },
    prePrintReceipt () {
      if (this.$refs.receiveForm.validate()) {
        if (this.ackFlag === true) {
          if (this.currUser.has_wacom) {
            if (this.hasCustomerDataConsent) {
              this.$store.commit('wacom/SET_WACOM_CONSENT_DIALOG', true)
            } else {
              this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
            }
          } else {
            if (this.payoutCurrencyType !== 'PHP' && this.transactionData.buy_back === '0') {
              this.generatePrePrint()
            } else if (this.payoutCurrencyType !== 'PHP' && this.transactionData.buy_back !== '' && (this.transactionData.buy_back > 0 && this.transactionData.buy_back < 2)) {
              this.renderToast('error', 'Invalid Buyback Amount', 'Minimum buyback amount must be USD $2 or more.')
            } else {
              this.generatePrePrint()
            }
          }
        } else {
          this.renderToast('error', 'Customer not verified!', 'You must verify the customer data first.')
        }
      } else {
        this.renderToast('error', 'Incomplete Data!', 'You must complete the form to process this transaction.')
      }
    },
    async payoutTransaction () {
      if (this.isPrePrintClicked === true) {
        const currentCustomer = this.selectedCustomer
        const trxData = this.transactionData
        const currentTrx = this.currentTrx.result.payment_transaction
        const orignatingCountryCurrency = currentTrx.payment_details.originating_country_currency
        const destinationCountryCurrency = currentTrx.payment_details.destination_country_currency
        const origDestCountryCurency = currentTrx.payment_details.original_destination_country_currency
        const receiverMiddleName = this.formatWUMiddleName(currentTrx.receiver.name)
        const senderMiddleName = this.formatWUMiddleName(currentTrx.sender.name)
        const wuNationality = trxData.nationality.country

        const idDetails = this.collections.wuIDList.filter(item => item.id_value === trxData.id_type)
        let hasIssueDate = ''
        let hasExpirationDate = ''

        if (idDetails.length > 0) {
          hasIssueDate = idDetails[0].hasIssueDate
          hasExpirationDate = idDetails[0].hasExpiration
        }

        const toValidate = {
          Purpose_of_Transaction: trxData.Purpose_of_Transaction,
          id_type: trxData.id_type,
          id_issue_date: trxData.id_issue_date ? trxData.id_issue_date : '',
          id_expiration_date: trxData.id_expiration_date ? trxData.id_expiration_date : '',
          occupation: trxData.occupation,
          nationality: wuNationality,
          date_of_birth: currentCustomer.birth_date,
          has_issue_date: hasIssueDate,
          has_expiration_date: hasExpirationDate,
          rec_mob_country_code: currentCustomer.mobile_no.substring(1) ? (currentCustomer.mobile_country_code.substring(1) ? currentCustomer.mobile_country_code.substring(1) : '63') : '',
          contact_phone: currentCustomer.mobile_no.substring(1),
          employment_position_level: currentCustomer.position_level,
          receiver_street: currentCustomer.address,
          receiver_city: currentCustomer.city1,
          receiver_first_name: this.formatWUFirstName(currentTrx.receiver.name),
          receiver_last_name: this.formatWULastName(currentTrx.receiver.name)
        }

        const checkOccupation = this.collections.occupation.filter(res => {
          if (res.occupation_value === trxData.occupation) {
            return true
          } else {
            return false
          }
        })

        const checkPurpose = this.collections.purposeOfTransaction.filter(res => {
          if (res.purpose === trxData.Purpose_of_Transaction) {
            return true
          } else {
            return false
          }
        })

        const selectedCity = this.collections.cities.filter(item => item.city === currentCustomer.city1)
        // let currentProvince = ''

        // if (selectedCity.length === 1) currentProvince = selectedCity[0]?.province

        // const checkProvince = currentProvince === currentCustomer.province
        const checkCity = selectedCity.length > 0

        const getNationality = this.collections.countries.filter(res => res.country === wuNationality)
        const checkNationality = getNationality.length > 0

        const checkReceiverCountry = this.collections.countries.filter(res => {
          if (res.country === currentCustomer.country) {
            return true
          } else {
            return false
          }
        })

        // const toValidate = {
        //   Purpose_of_Transaction: '',
        //   id_type: '',
        //   id_issue_date: '',
        //   id_expiration_date: '',
        //   occupation: '',
        //   nationality: '',
        //   date_of_birth: '',
        //   has_issue_date: hasIssueDate,
        //   has_expiration_date: hasExpirationDate,
        //   rec_mob_country_code: '',
        //   contact_phone: ''
        // }

        this.validationMessage = ''

        const validateCheck = await this.validateWuReceive(toValidate)

        if (checkOccupation.length < 1) {
          this.validationDialog = true
          this.validationMessage += 1 + '. Please Enter Valid Occupation<br>'
        } else if (checkPurpose.length < 1) {
          this.validationDialog = true
          this.validationMessage += 1 + '. Please Enter Valid Purpose<br>'
        } else if (checkReceiverCountry.length < 1) {
          this.validationDialog = true
          this.validationMessage += 1 + '. Please Enter Valid Receiver Country<br>'
        } else if (!checkCity) {
          this.validationDialog = true
          this.validationMessage += 1 + '. Please Enter Valid City<br>'
        // } else if (!checkProvince) {
        //   this.validationDialog = true
        //   this.validationMessage += 1 + '. Please Enter Valid Province<br>'
        } else if (!checkNationality) {
          this.validationDialog = true
          this.validationMessage += 1 + '. Please Enter Valid Nationality<br>'
        } else if (validateCheck?.error) {
          this.validationDialog = true
          validateCheck.error.details.forEach((item, index) => {
            this.validationMessage += (index + 1) + '. ' + item.message + '<br>'
          })
        } else {
          const singleKYCLookupPayload = {
            foreign_reference_no: this.payoutForeignRefNo,
            search_by: 'by_id',
            id_type: trxData.id_type,
            id_number: trxData.id_number,
            contact_phone: currentCustomer.mobile_no.substring(1),
            mywu_number: (currentCustomer.wu_card_no !== '0' || currentCustomer.wu_card_no !== null) ? currentCustomer.wu_card_no : '',
            first_name: this.formatWUFirstName(currentTrx.receiver.name),
            last_name: this.formatWULastName(currentTrx.receiver.name),
            is_multi: 'S',
            terminal_id: this.terminalId,
            operator_id: this.operatorId,
            location_id: this.locationId
          }

          let kycLookupCustomer = {}
          let customerComplianceBuffer = ''
          let galacticId = ''
          let currentWUPoints = 0

          await this.$store.dispatch('wu/kycLookup', singleKYCLookupPayload).then(res => {
            console.log(res)
            if (res.result) {
              console.log(res.result, 'kyc result')
              kycLookupCustomer = res.result.customer
              customerComplianceBuffer = kycLookupCustomer.compliance_details.compliance_data_buffer
              galacticId = customerComplianceBuffer.substring(customerComplianceBuffer.indexOf('J6') + 4)
              currentWUPoints = res.result.customer.mywu_details.current_yr_pts ? res.result.customer.mywu_details.current_yr_pts : 0
            }
          }).catch(err => {
            console.log(err)
          })

          const payload = {
            foreign_reference_no: this.payoutForeignRefNo,
            receiver_name_type: currentTrx.receiver.name.name_type,
            receiver_first_name: this.formatWUFirstName(currentTrx.receiver.name),
            receiver_middle_name: this.formatWUMiddleName(currentTrx.receiver.name),
            receiver_last_name: this.formatWULastName(currentTrx.receiver.name),
            sender_first_name: this.formatWUFirstName(currentTrx.sender.name),
            sender_last_name: this.formatWULastName(currentTrx.sender.name),
            receiver_city: currentCustomer.city1,
            receiver_state: currentCustomer.province,
            receiver_country_code: currentTrx.receiver.address.country_code.iso_code.country_code ? currentTrx.receiver.address.country_code.iso_code.country_code : '',
            receiver_currency_code: currentTrx.receiver.address.country_code.iso_code.currency_code ? currentTrx.receiver.address.country_code.iso_code.currency_code : '',
            receiver_state_zip: currentCustomer.zip_code,
            receiver_street: currentCustomer.address,
            id_type: trxData.id_type,
            id_country_of_issue: trxData.id_country_of_issue,
            id_number: trxData.id_number,
            id_issue_date: trxData.id_issue_date ? trxData.id_issue_date : '',
            id_expiration_date: trxData.id_expiration_date ? trxData.id_expiration_date : '',
            date_of_birth: currentCustomer.birth_date,
            occupation: trxData.occupation,
            addr_line1: currentCustomer.address,
            addr_line2: currentCustomer.barangay,
            curr_city: currentCustomer.city1,
            curr_state_name: currentCustomer.province,
            postal_code: currentCustomer.zip_code,
            country: currentCustomer.country,
            contact_phone: currentCustomer.mobile_no.substring(1),
            Country_of_Birth: trxData.Country_of_Birth,
            nationality: wuNationality,
            Name_of_Employer_Business: currentCustomer.employer_name ? currentCustomer.employer_name : '',
            Relationship_to_Receiver_Sender: trxData.Relationship_to_Receiver,
            Source_of_Funds: trxData.Source_of_Funds,
            Does_the_ID_have_an_expiration_date: trxData.Does_the_ID_have_an_expiration_date,
            Does_receiver_have_a_phone_number: currentCustomer.tel_no ? 'Y' : 'N',
            ack_flag: this.ackFlag === true ? 'X' : '',
            GEN_III_Indicator: '',
            pd_state_code: currentTrx.payment_details.expected_payout_location.state_code,
            pd_city: currentTrx.payment_details.expected_payout_location.city,
            pd_dest_country_code: destinationCountryCurrency.iso_code.country_code,
            pd_dest_currency_code: destinationCountryCurrency.iso_code.currency_code,
            pd_orig_country_code: orignatingCountryCurrency.iso_code.country_code,
            pd_orig_currency_code: orignatingCountryCurrency.iso_code.currency_code,
            pd_originating_city: currentTrx.payment_details.originating_city,
            pd_transaction_type: currentTrx.payment_details.transaction_type,
            pd_exchange_rate: currentTrx.payment_details.exchange_rate,
            pd_orig_dest_country_currency_country_code: origDestCountryCurency.iso_code.country_code,
            pd_orig_dest_country_currency_currency_code: origDestCountryCurency.iso_code.currency_code,
            gross_total_amount: currentTrx.financials.gross_total_amount,
            pay_amount: currentTrx.financials.pay_amount,
            principal_amount: currentTrx.financials.principal_amount,
            charges: currentTrx.financials.charges,
            tolls: currentTrx.financials.tolls,
            money_transfer_key: currentTrx.money_transfer_key,
            new_mtcn: currentTrx.new_mtcn,
            mtcn: currentTrx.mtcn,
            pay_or_do_not_pay_indicator: 'P',
            rec_mob_country_code: currentCustomer.mobile_no.substring(1) ? (currentCustomer.mobile_country_code.substring(1) ? currentCustomer.mobile_country_code.substring(1) : '63') : '',
            national_number: currentCustomer.mobile_no.substring(1),
            telephone_city_code: currentCustomer.mobile_country_code.substring(1) ? currentCustomer.mobile_country_code.substring(1) : '63',
            filing_time: currentTrx.filing_time,
            filing_date: currentTrx.filing_date,
            message: [],
            client_reference_no: `PO${this.payoutForeignRefNo}`,
            real_principal_amount: this.currentTrx.result.gross_payout,
            dst_amount: this.currentTrx.result.dst,
            real_net_amount: this.currentTrx.result.net_payout,
            user_code: this.currUser.user_id,
            customer_code: currentCustomer.customer_number,
            terminal_id: this.terminalId,
            operator_id: this.operatorId,
            remote_terminal_id: this.currUser.fs_id,
            remote_operator_id: this.currUser.user_id,
            mywu_number: (currentCustomer.wu_card_no !== '0' || currentCustomer.wu_card_no !== null) ? currentCustomer.wu_card_no : '',
            employment_position_level: currentCustomer.position_level,
            Purpose_of_Transaction: trxData.Purpose_of_Transaction,
            // is_on_behalf: '',
            galactic_id: galacticId || '',
            location_id: this.locationId,
            remote_location_id: this.currUser.location_id,
            trx_date: this.currUser.trx_date,
            currency_id: this.payoutCurrencyType === 'PHP' ? '1' : '2',
            client_ip: '',
            customer_id: currentCustomer.customer_id,
            customer_name: `${this.formatWUFirstName(currentTrx.receiver.name)} ${this.formatWUMiddleName(currentTrx.receiver.name)}${this.formatWULastName(currentTrx.receiver.name)}`,
            dom_int: currentTrx.payment_details.originating_country_currency.iso_code.country_code === 'PH' ? 'DMT' : 'IMT',
            risk_score: currentCustomer.risk_score,
            risk_criteria: currentCustomer.risk_criteria,
            gender: trxData.Gender === 'female' ? 'F' : 'M',
            adj_type: this.payoutCurrencyType !== 'PHP' ? (trxData.adj_type ? trxData.adj_type : '') : '',
            mc_rate: this.payoutCurrencyType !== 'PHP' ? (trxData.mc_rate ? trxData.mc_rate : '') : '',
            buy_back: this.payoutCurrencyType !== 'PHP' ? (trxData.buy_back ? trxData.buy_back : '') : '',
            rate_category: this.payoutCurrencyType !== 'PHP' ? (trxData.rate_category ? trxData.rate_category : '') : '',
            mc_rate_id: this.payoutCurrencyType !== 'PHP' ? (trxData.mc_rate_id ? trxData.mc_rate_id : '') : ''
          }

          await this.$store.dispatch('wu/doPayoutTransaction', payload).then(async res => {
            if (res.result) {
              const printReceiptPayload = {}
              printReceiptPayload.print_data = {
                receiver: this.formatWUFirstName(currentTrx.receiver.name) + ' ' + receiverMiddleName + this.formatWULastName(currentTrx.receiver.name),
                address: this.selectedCustomer.address + ' ' + this.selectedCustomer.barangay + ' ' + this.selectedCustomer.municipality + ' ' + this.selectedCustomer.province + ' ' + this.selectedCustomer.zip_code,
                id_type: this.currentId.id_type,
                id_number: this.currentId.id_number,
                sender: this.formatWUFirstName(currentTrx.sender.name) + ' ' + senderMiddleName + formatWULastName(currentTrx.sender.name),
                test_question: '', // none
                answer: '', // none
                message: '',
                mtcn: this.formatMTCN(currentTrx.mtcn),
                date_time: this.formatDateTime(new Date()),
                host_message_set2: res.result.confirmed_details.host_message_set2 ? res.result.confirmed_details.host_message_set2 : '',
                agent_name: this.currUser.location_name + ' BRANCH',
                amount: this.formatAmount(this.currentTrx.result.gross_payout.toFixed(2)),
                originating_country: this.originatingCountry,
                total: currentTrx.payment_details.destination_country_currency.iso_code.currency_code + ' ' + this.formatAmount(this.currentTrx.result.net_payout.toFixed(2)),
                exchange_rate: currentTrx.payment_details.exchange_rate,
                dst: this.currentTrx.result.dst,
                payout_amount: this.originCurrencyName + ' ' + (currentTrx.financials.principal_amount / 100).toFixed(2),
                mywu_number: (this.selectedCustomer.wu_card_no !== '0' || this.selectedCustomer.wu_card_no !== null) ? this.selectedCustomer.wu_card_no : '',
                mywu_points: res.result.confirmed_details.new_points_earned ? res.result.confirmed_details.new_points_earned : '',
                total_mywu_points: parseInt(currentWUPoints) + parseInt(res.result.confirmed_details.new_points_earned ? res.result.confirmed_details.new_points_earned : 0),
                adj_type: this.payoutCurrencyType !== 'PHP' ? (trxData.adj_type === 'BUY' ? trxData.adj_type : 'SELL') : '',
                mc_rate: this.payoutCurrencyType !== 'PHP' ? (trxData.mc_rate ? trxData.mc_rate : '') : '',
                buyback_amount: this.payoutCurrencyType !== 'PHP' ? (trxData.buy_back ? trxData.buy_back : 0) : '',
                total_amount: this.payoutCurrencyType !== 'PHP' ? (trxData.total_amount ? trxData.total_amount : '') : '',
                ub_account_no: '',
                des_curr: currentTrx.payment_details.destination_country_currency.iso_code.currency_code,
                purpose_of_trxn: this.selectedCustomer.purpose_transaction,
                receiver_mobile_number: '', // none
                promo_flag: 'N',
                promo_code: '',
                expiration_date: '',
                vuser_group_id: this.currUser.user_group_id
              }
              if (this.currUser.has_wacom) {
                printReceiptPayload.print_data.customer_signature = this.customerSignature
                printReceiptPayload.print_data.fla_signature = this.currUser.userSignature
              }
              printReceiptPayload.data = {
                has_wacom: this.currUser.has_wacom,
                trx_type: 2,
                remco_name: 'Western Union',
                control_number: currentTrx.mtcn,
                customer_id: this.selectedCustomer.customer_id.toString(),
                location_id: this.currUser.location_id,
                location_name_uploaded: this.currUser.location_name,
                crn: this.selectedCustomer.customer_number,
                uploaded_by: this.currUser.user_id,
                first_name: this.formatWUFirstName(this.currentTrx.result.payment_transaction.receiver.name),
                last_name: this.formatWULastName(this.currentTrx.result.payment_transaction.receiver.name),
                product: 'WU Receive Money',
                receipt_type: 'acr'
              }

              if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
                printReceiptPayload.print_data.agent_name = this.remoteData.remote_location.location_name + ' BRANCH'
                printReceiptPayload.data.location_id = this.remoteData?.remote_location?.location_id
                printReceiptPayload.data.uploaded_by = this.remoteData.remote_user
                printReceiptPayload.data.location_name_uploaded = this.remoteData.remote_location.location_name
              } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
                printReceiptPayload.print_data.agent_name = this.currUser.location_name + ' BRANCH'
                printReceiptPayload.data.location_id = this.currUser.location_id
                printReceiptPayload.data.uploaded_by = this.currUser.user_id
                printReceiptPayload.data.location_name_uploaded = this.currUser.location_name
              }

              console.log(printReceiptPayload)
              this.generateACR(printReceiptPayload)
              this.$store.commit('obo/SET_REMOTE_DATA', {})
              this.renderToast('success', 'Payout Successful', 'Transaction successful!')
            } else {
              this.renderToast('error', 'Error', res.message)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      } else {
        this.renderToast('error', 'Pre-print receipt not yet generated!', 'You must generate a Pre-Print receipt first in order to process this transaction.')
      }
    },
    async generateACR (payload) {
      const reqPrePrint = await this.$store.dispatch('wu/generateACRWU', payload)
      var decoded = Buffer.from(reqPrePrint.result, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
      this.renderToast('success', 'Payout Successful', 'Transaction successful!')
    },
    backToSearch () {
      this.$store.commit('wu/SET_SHOW_PAYOUT_SEARCH_FORM', true)
      this.$store.commit('wu/SET_SHOW_PAYOUT_RESULT_FORM', false)
      this.$store.commit('wu/SET_CURRENT_TRANSACTION', {})
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
      this.$store.commit('wu/SET_PAYOUT_CURRENT_CURRENCY', '')
      this.$store.commit('SET_PAGE_TITLE', 'Payout Money')
    },
    populateData (currentCustomer) {
      let getIdType = []

      const idList = (currentCustomer.img !== '{}' && currentCustomer.img !== '') ? JSON.parse(currentCustomer.img) : {}

      let currentId = {}
      if (currentCustomer.img !== '{}' && currentCustomer.img !== '') {
        currentId = idList['1'] ? idList['1'] : {}
        this.currentId = currentId
        getIdType = this.collections.wuIDList.filter(item => {
          return item.id_type === currentId.id_type
        })
      } else {
        this.noIDFlag = true
      }

      const selectedNationality = this.collections.countries.filter(item => item.nationality === currentCustomer.nationality)

      const updatedPayload = {
        id_country_of_issue: currentId?.country ? currentId.country : '',
        id_type: getIdType[0]?.id_value ? getIdType[0].id_value : '',
        id_number: currentId?.id_number ? currentId.id_number : '',
        id_issue_date: currentId?.issue ? currentId.issue : '',
        Does_the_ID_have_an_expiration_date: getIdType[0]?.hasExpiration ? getIdType[0].hasExpiration : 'N',
        id_expiration_date: currentId.expiry ? currentId.expiry : '',
        Gender: currentCustomer.gender,
        occupation: currentCustomer.occupation,
        Purpose_of_Transaction: currentCustomer.purpose_transaction,
        Source_of_Funds: currentCustomer.source_fund,
        Relationship_to_Receiver: currentCustomer.relation_to,
        Country_of_Birth: currentCustomer.country_birth,
        nationality: selectedNationality[0],
        requiredField: this.requiredField
      }

      if (this.payoutCurrency === 'USD') {
        updatedPayload.adj_type = 'SELL'
        updatedPayload.buy_back = '0'
      }

      this.transactionData = Object.assign({}, this.transactionData, updatedPayload)
    },
    toggleForm (step) {
      this.payoutForm = step

      switch (step) {
        case 1:
          this.$store.commit('SET_PAGE_TITLE', 'Transfer Information')
          break
        case 2:
          this.$store.commit('SET_PAGE_TITLE', 'Receiver Details')
          break
      }
    },
    async generateConsent () {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: 'WU Receive Money',
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    }
  }
}
</script>
<style scoped>
  .v-stepper.ph-wu-receive-stepper .v-stepper__header {
    box-shadow: none !important;
  }

  .ph-wu-receive-summary .v-card {
    padding: 20px;
  }

  .ph-transfer-summary-header {
    font-size: 18px;
    text-align: center;
  }

  .ph-transfer-summary-content-title {
    font-size: 14px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
    border: 2px solid #1A2791;
  }

  .ph-transaction-container {
    padding: 0;
  }

  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 100px;
  }

  .ph-primary-button.ph-stepper-button {
    height: 40px !important;
    width: 115px;
  }

  /* @media only screen and (min-width: 600px) {
    .v-stepper.ph-wu-receive-stepper .v-stepper__step {
      padding-left: 0;
    }
  } */

  .ph-wu-receive-summary-minified {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F4F4FA;
  }

  .ph-wu-receive-summary-content {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
  }

  .ph-wu-receive-summary-minified-info {
    margin-right: auto;
  }

  .ph-wu-receive-summary-minified-info p {
    margin-bottom: 0;
  }

  .ph-wu-receive-summary-minified-action {
    margin-left: auto;
  }

  .ph-wu-receive-form {
    padding: 12px 0;
  }

  .transfer-info-title,
  .transfer-info-value {
    border-bottom: thin dashed rgba(0,0,0,.12) !important;
  }

  @media only screen and (min-width: 960px) {
    .ph-stepper-action-buttons {
      display: flex;
      margin-bottom: 0;
    }
  }
</style>
