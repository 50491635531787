<template>
  <v-row class="mt-md-3 mb-md-5" no-gutters>
    <v-col :cols="12">
      <v-card class="custom-card" v-if="selectedCustomer">
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" v-if="isCustomerHit">
              <v-alert
                text
                dense
                color="error"
                icon="mdi-alert"
                border="left"
                class="customer-hit-alert-card"
              >
                <v-row align="center">
                  <v-col class="grow">
                    <b>Under Investigation:</b> This customer is under investigation due to a hit.
                  </v-col>
                  <v-col class="shrink">
                    <v-btn color="error" text @click="openSpiel">View info</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col class="pr-md-1" :cols="12" :md="3">
              <div class="d-flex flex-column align-center">
                <v-avatar class="mr-2 mb-1" size="53px" :style="avatarColor(selectedCustomer.full_name)">
                  <span class="white--text text-h5">{{ avatarInitial(selectedCustomer.full_name) }}</span>
                </v-avatar>
                <h3 class="title text-h6">
                  {{ selectedCustomer.full_name }}
                </h3>
                <span class="caption">
                  {{ selectedCustomer.customer_number || '—' }}
                </span>
              </div>
            </v-col>
            <v-col class="caption px-md-1" :cols="12" :md="2">
              <v-row no-gutters>
                <v-col :cols="12">
                  <small>Mobile No.</small><br>
                  <strong>{{ selectedCustomer.mobile_no || '—' }}</strong>
                </v-col>
                <v-col :cols="12">
                  <small>Birthdate</small><br>
                  <strong>{{ formatProperDate(selectedCustomer.birth_date) || '—' }}</strong>
                </v-col>
                <v-col :cols="12">
                  <small>Age</small><br>
                  <strong>{{ getAge(selectedCustomer.birth_date) || '—' }} yrs old</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="caption px-md-1" :cols="12" :md="3">
              <v-row no-gutters>
                <v-col :cols="12">
                  <small>Insurance</small><br>
                  <div
                    @click="viewPolicyList"
                    :class="{ 'red--text': isPolicyExpiring }"
                    style="cursor: pointer"
                    v-if="policyList.length > 0"
                  >
                    <strong class="d-block mb-n2">{{ policyName }}</strong>
                    <small>
                      Expires on {{ formatProperDate(policyExpiry) }}
                    </small>
                  </div>
                  <div v-else>
                    —
                  </div>
                </v-col>
                <v-col :cols="12">
                  <small>Address</small><br>
                  <strong>{{ fullAddress || '—' }}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="py-2 pt-3 py-md-0 pl-md-4" :cols="12" :md="4">
              <v-btn @click="updateDetails(selectedCustomer)" class="rounded-lg" color="primary" block>
                Update
              </v-btn>
              <v-btn @click="viewTransactionHistory" class="rounded-lg mt-2" color="primary" small block text>
                Transaction History
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="transactionHistoryDialog"
        scrollable
        max-width="600px"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Transaction History
          </v-card-title>
          <v-divider />
          <v-card-text class="text-center">
            <v-data-table
              :items="transactionHistory"
              :headers="transactionHistoryHeader"
            >
              <template v-slot:[`item.amount`]="{ item }">
                {{ toMoney(item.amount) }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="danger"
              text
              class="ph-action-button"
              @click="transactionHistoryDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="policyListDialog"
        scrollable
        max-width="600px"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Customer's Policy List
          </v-card-title>
          <v-divider />
          <v-card-text class="text-center">
            <v-data-table
              :items="policyList"
              :headers="policyListHeader"
            >
              <template v-slot:[`item.category`]="{ item }">
                {{ getInsuranceName(item.category) }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="danger"
              text
              class="ph-action-button"
              @click="policyListDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { isAfter, format, parse, sub } from 'date-fns'
import {
  getInsuranceName,
  avatarInitial,
  toProperCase,
  avatarColor,
  toMoney,
  getAge,
  trim
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      selectedCustomer: 'customers/selectedCustomer',
      policyList: 'customers/policyList',
      isCustomerHit: 'debitCard/isCustomerHit'
    }),
    policyName () {
      if (this.policyList.length > 0) {
        const category = this.policyList[0].category

        return getInsuranceName(category)
      } else {
        return ''
      }
    },
    policyExpiry () {
      if (this.policyList.length > 0) {
        return this.policyList[0].expirydate
      } else {
        return ''
      }
    },
    isPolicyExpiring () {
      if (this.policyList.length > 0) {
        const parsedExpiryDate = parse(this.policyList[0].expirydate, 'yyyy-MM-dd', new Date())
        const dateNearExpiry = sub(parsedExpiryDate, { days: 15 })

        return isAfter(new Date(), dateNearExpiry)
      } else {
        return false
      }
    },
    fullAddress () {
      const addressDetails = [
        this.selectedCustomer.address,
        this.selectedCustomer.barangay,
        this.selectedCustomer.municipality,
        this.selectedCustomer.province,
        this.selectedCustomer.country
      ]

      return trim(addressDetails.join(' '))
    }
  },
  data () {
    return {
      transactionHistoryDialog: false,
      policyListDialog: false,
      transactionHistory: [],
      transactionHistoryHeader: [
        {
          text: 'Transaction',
          sortable: true,
          value: 'transaction_name',
          width: '130px'
        },
        {
          text: 'Date',
          sortable: true,
          value: 'actual_date',
          width: '150px'
        },
        {
          text: 'Reference #',
          sortable: true,
          value: 'reference_no',
          width: '150px'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount',
          width: '140px'
        },
        {
          text: 'Branch',
          sortable: true,
          value: 'location_name',
          width: '150px'
        },
        {
          text: 'FLA',
          sortable: true,
          value: 'updated_by',
          width: '150px'
        }
      ],
      policyListHeader: [
        {
          text: 'COC #',
          sortable: true,
          value: 'coc_number',
          width: '150px'
        },
        {
          text: 'Insurance',
          sortable: true,
          value: 'category',
          width: '150px'
        },
        {
          text: 'Inception Date',
          sortable: true,
          value: 'inceptiondate',
          width: '150px'
        },
        {
          text: 'Expiry Date',
          sortable: true,
          value: 'expirydate',
          width: '150px'
        },
        {
          text: 'Beneficiary',
          sortable: true,
          value: 'beneficiary',
          width: '150px'
        },
        {
          text: 'Insured',
          sortable: true,
          value: 'insured',
          width: '150px'
        }
      ]
    }
  },
  methods: {
    updateDetails (data) {
      this.$router.push({
        path: `/customers/${data.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    async viewTransactionHistory () {
      this.transactionHistory = await this.$store.dispatch('customers/getTransactionLogs', this.selectedCustomer.customer_id)
      this.transactionHistoryDialog = true
    },
    viewPolicyList () {
      this.policyListDialog = true
    },
    formatProperDate (date) {
      if (date) {
        const parsedDate = parse(date, 'yyyy-MM-dd', new Date())
        return format(parsedDate, 'MMM d, yyyy')
      } else {
        return ''
      }
    },
    openSpiel () {
      this.$store.commit('debitCard/SET_SHOW_SPIEL', true)
    },
    getInsuranceName,
    avatarInitial,
    toProperCase,
    avatarColor,
    toMoney,
    getAge
  },
  async beforeMount () {
    const req = await this.$store.dispatch('customers/getClientPolicyList')

    if (req.code === 200) {
      this.$store.commit('customers/SET_POLICY_LIST', req.result)
    }
  }
}
</script>
<style>
.v-alert.customer-hit-alert-card .v-alert__icon {
  margin: 5px 10px 5px 0 !important;
}
</style>
