<template>
  <v-dialog
    v-model="showOfferDialog"
    width="50%"
    persistent
  >
    <v-card>
      <v-card-title class="ph-dialog-title">
        Reminder
      </v-card-title>
      <br>
      <v-card-text class="ph-dialog-header">
        <p v-if="availableOffer">
          {{ availableOffer.spiel }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-col :cols="12" :md="6">
            <v-btn
              @click="doUpdateStatus(1)"
              color="primary"
              class="ph-action-button"
              text
              block
            >
              <v-icon class="mr-1">mdi-check</v-icon>
              Already offered to the client
            </v-btn>
          </v-col>
          <v-col :cols="12" :md="6">
            <v-btn
              @click="doUpdateStatus(0)"
              class="ph-action-button"
              text
              block
            >
              <v-icon class="mr-1">mdi-close</v-icon>
              Not yet offered to the client
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      showOfferDialog: 'prompt/showOfferDialog',
      availableOffer: 'prompt/availableOffer'
    })
  },
  methods: {
    closeDialog () {
      this.$store.commit('prompt/SET_SHOW_OFFER_DIALOG', false)
    },
    async doUpdateStatus (status) {
      try {
        const { code, result } = await this.$store.dispatch('prompt/updateQualifiedCustomerStatus', {
          id: this.availableOffer.id,
          payload: {
            offer_id: this.availableOffer.offer_id,
            spiel: this.availableOffer.spiel,
            location_id: this.currUser.location_id,
            user_id: this.currUser.user_id,
            customer_number: this.availableOffer.customer_number,
            status
          }
        })

        if (code !== 200 && !result) {
          renderToast('error', 'Failed', 'Failed to update status')
        }

        this.closeDialog()
      } catch (e) {
        renderToast('error', 'Failed', 'Failed to update status')
      }
    }
  }
}
</script>
