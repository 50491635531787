<template>
  <div>
    <v-row class="mt-md-5" no-gutters>
      <v-col :cols="12" :md="4">
        <h3 class="pb-md-6 custom-heading primary--text">
          {{$route.query.provider_type === 'loans' ? 'Account Lookup' : 'KYC Lookup'}}
        </h3>
        <p class="text-body-2" v-if="$route.query.provider_type === 'loans'">
          Please enter your Loan Account Number to continue
        </p>
        <p class="text-body-2" v-if="$route.query.provider_type !== 'loans'">
          Search for Last name, First name<br>e.g. Doe, John
        </p>
      </v-col>
      <v-col class="pl-md-6" :cols="12" :md="8">
        <h4 class="mb-5 grey--text text--darken-2" v-if="$route.query.provider_type === 'loans'">
          Loan Number
        </h4>
        <v-text-field
          v-if="$route.query.provider_type === 'loans'"
          v-model="accountNumber"
          class="ph-textbox mb-md-6 ph-textbox-bills-collections"
          label="Account Number"
          @keyup.enter="doSearch"
          outlined
        />
        <h4 class="mb-5 grey--text text--darken-2" v-if="$route.query.provider_type !== 'loans'">
          Customer's name
        </h4>
        <v-text-field
          v-model="firstName"
          class="ph-textbox mb-md-6 ph-textbox-bills-collections"
          label="First Name"
          @input="convertToProperCase('firstName')"
          @keyup.enter="doSearch"
          outlined
          v-if="$route.query.provider_type !== 'loans'"
        />
        <v-text-field
          v-model="lastName"
          class="ph-textbox mb-md-6 ph-textbox-bills-collections"
          label="Last Name"
          @input="convertToProperCase('lastName')"
          @keyup.enter="doSearch"
          outlined
          v-if="$route.query.provider_type !== 'loans'"
        />
        <div class="text-right">
          <!-- :disabled="($route.query.provider_type !== 'loans' && !firstName || !lastName) || ($route.query.provider_type === 'loans' && !accountNumber)" -->
          <v-btn @click="doSearch" class="rounded-lg ph-search-customer" color="primary" large>
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="resultDialog" persistent :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '60%'">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            PERA Hub Customer Results
          </h3>
          <v-spacer />
          <v-btn @click="resultDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p><b>Search results for: {{firstName}} {{lastName}}</b></p>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <p>Select a Customer</p>
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectCustomer"
            :headers="headers"
            :items="searchData.results"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No customers found"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ toProperCase(item.gender) || '-' }}
            </template>
            <template v-slot:[`item.mobile_no`]="{ item }">
              {{ item.mobile_no || '-' }}
            </template>
          </v-data-table>
          <div class="d-flex align-center customer-modal-actions">
            <v-spacer v-if="(!$vuetify.breakpoint.xs || !$vuetify.breakpoint.sm)" />
            <span class="customer-modal-hint">Can't find customer here?</span>
            <v-btn
              @click="addNewCustomer"
              color="primary"
              class="ph-bills-new-customer-btn"
            >
              Register New Customer
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getForeignRefNo,
  avatarColor,
  avatarInitial,
  toProperCase,
  trim,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      searchData: 'customers/searchData',
      selectedCustomer: 'customers/selectedCustomer'
    })
  },
  data () {
    return {
      accountNumber: '',
      firstName: '',
      lastName: '',
      resultDialog: false,
      searchText: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      showRemoteNotice: false,
      headers: [
        {
          text: 'Customer No.',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'mobile_no'
        }
      ]
    }
  },
  watch: {
    selectedCustomer: {
      handler (data) {
        if (data) {
          this.renderToast('info', 'Customer Selected!', `${data.full_name} has been successfully selected.`)
          this.$store.commit('bills/SET_BILLS_COLLECTION_CURRENT_PAGE', 1)
        } else {
          this.$store.commit('bills/SET_BILLS_COLLECTION_CURRENT_PAGE', 0)
        }
      },
      deep: true
    }
  },
  methods: {
    renderToast,
    addNewCustomer () {
      this.resultDialog = false
      this.$store.commit('customers/SET_CUSTOMER_CREATION_MODAL', true)
    },
    convertToProperCase (field) {
      this[field] = toProperCase(trim(this[field]))
    },
    async selectCustomer (customerDetails) {
      // Checking of available offer for the customer
      const { code, result } = await this.$store.dispatch('prompt/checkAvailableOffer', {
        customer_number: customerDetails.customer_number,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id
      })

      if (code === 200 && result) {
        this.$store.commit('prompt/SET_AVAILABLE_OFFER', result)
        this.$store.commit('prompt/SET_SHOW_OFFER_DIALOG', true)
      }

      const foreignRefNo = getForeignRefNo()

      this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)

      const updateState = [
        {
          prop: 'senderFirstName',
          value: customerDetails.first_name
        },
        {
          prop: 'senderMiddleName',
          value: customerDetails.middle_name
        },
        {
          prop: 'senderLastName',
          value: customerDetails.last_name
        },
        {
          prop: 'senderBirthDate',
          value: customerDetails.birth_date
        },
        {
          prop: 'refNo',
          value: foreignRefNo
        }
      ]
      updateState.map((payload) => this.$store.commit('send/SET_TRANSACTION_DATA', payload))
    },
    async doSearch () {
      if (this.$route.query.provider_type === 'loans') {
        const accountSearchPayload = {
          account_number: this.accountNumber
        }

        this.$store.dispatch('bills/loanAccountLookup', accountSearchPayload).then(res => {
          if (res.result.message === 'Account Number is invalid!') {
            this.renderToast('error', 'Error', res.result.message)
          } else {
            const accountName = res.result.name[0]

            this.firstName = accountName.first
            this.lastName = accountName.last

            if (this.firstName && this.lastName) {
              const firstName = this.firstName.replace(/(^\s+|\s+$)/g, '')
              const lastName = this.lastName.replace(/(^\s+|\s+$)/g, '')
              const keyword = `${lastName},${firstName}`

              this.searchData.results = []
              this.$store.dispatch('customers/doSearch', keyword)
              this.$store.commit('send/SET_CURRENT_STEP', 1)
              this.resultDialog = true
            } else {
              this.searchData.results = []
              this.renderToast('error', 'Search Error', 'The required fields must be completed.')
            }
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        if (this.firstName && this.lastName) {
          const firstName = this.firstName.replace(/(^\s+|\s+$)/g, '')
          const lastName = this.lastName.replace(/(^\s+|\s+$)/g, '')
          const keyword = `${lastName},${firstName}`

          this.searchData.results = []
          this.$store.dispatch('customers/doSearch', keyword)
          this.$store.commit('send/SET_CURRENT_STEP', 1)
          this.resultDialog = true
        } else {
          this.searchData.results = []
          this.renderToast('error', 'Search Error', 'The required fields must be completed.')
        }
      }
    },
    toProperCase,
    avatarColor,
    avatarInitial
  },
  async mounted () {
    const tpaPayload = {
      bill_id: 2,
      location_id: this.currUser.location_id
    }

    let tpaId = ''

    await this.$store.dispatch('bills/getTPARequest', tpaPayload)
      .then(res => {
        tpaId = res.tpa_id
        this.$store.commit('bills/SET_BILLS_TPA_ID', tpaId)
      }).catch(e => {
        console.log(e)
      })

    const payload = {
      grant_type: 'client_credentials',
      tpa_id: tpaId,
      scope: 'mecom-auth/all'
    }

    await this.$store.dispatch('bills/getMisc', payload)

    this.$store.commit('bills/SET_BILLS_PAYMENT_CURRENT_PAGE', 0)
    this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)

    switch (this.$route.name) {
      case 'PeraCashIn':
        this.$store.commit('SET_PAGE_TITLE', 'Pera Cash-In')
        break
      case 'PeraCashOut':
        this.$store.commit('SET_PAGE_TITLE', 'Pera Cash-Out')
        break
    }

    await this.$store.dispatch('getIDList', 'wu')
  }
}
</script>
<style scoped>
  .ph-textbox-bills-collections {
    margin-bottom: 16px !important;
  }

  .customer-modal-actions {
    flex-direction: column;
  }

  @media only screen and (min-width: 600px) {
    .ph-bills-new-customer-btn {
      margin-left: 15px;
    }
  }

  @media only screen and (min-width: 960px) {
    .customer-modal-actions {
      flex-direction: row;
    }
  }
</style>
