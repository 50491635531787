<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        class="ph-breadcrumbs"
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ph-transaction-container">
        <v-col cols="12" v-if="currentPage === 0">
          <CustomerSearch />
        </v-col>
        <v-col cols="12" v-if="currentPage === 1">
          <CashOutForm />
        </v-col>
        <v-col cols="12" v-if="currentPage === 2">
          <CICOSuccess />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerSearch from '@/components/cico/CICOSearch.vue'
import CashOutForm from '@/components/cico/CashOutForm.vue'
import CICOSuccess from '@/components/cico/CICOSuccess.vue'

export default {
  name: 'PeraCashOut',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera CICO',
          disabled: false,
          href: '/pera-cico'
        },
        {
          text: this.$route.name === 'PeraCashOut' ? 'Cash-Out' : 'Cash-In',
          disabled: false,
          href: '#'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'cico/cashOutCurrentPage'
    })
  },
  components: {
    CustomerSearch,
    CashOutForm,
    CICOSuccess
  }
}
</script>
<style scoped>
  .ph-transaction-container .col {
    padding: 15px;
  }

  .ph-breadcrumbs {
    padding: 5px 5px;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }

    .ph-transaction-container .col {
      padding: 12px;
    }

    .ph-breadcrumbs {
      padding: 18px 12px;
      padding-left: 24px;
    }
  }
</style>
