<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
      :items="items"
      divider=">"
      ></v-breadcrumbs>
    </v-container>
    <v-row class="mb-md-10 ma-5" no-gutters>
      <v-col :cols="12" :md="6">
        <v-btn
          x-large
          color="primary"
          elevation="0"
          class="ph-action-button ph-cashcount-action-btn"
          outlined
          v-if="!isEditMode"
          to="/reports/coh"
        >
          Print Report
        </v-btn>
        <v-btn
          x-large
          color="primary"
          elevation="0"
          class="ph-action-button ph-cashcount-action-btn"
          outlined
          v-if="!isEditMode"
          @click="isEditMode = true"
        >
          Edit Entries
        </v-btn>
        <v-btn
          x-large
          color="primary"
          elevation="0"
          class="ph-action-button ph-cashcount-action-btn"
          v-if="isEditMode"
          @click="cashCountAction('cancel')"
          outlined
        >
          Cancel
        </v-btn>
        <v-btn
          x-large
          color="primary"
          elevation="0"
          class="ph-action-button ph-primary-execute-btn ph-cashcount-action-btn"
          v-if="isEditMode"
          @click="cashCountAction('update')"
        >
          Save Entries
        </v-btn>
      </v-col>
      <v-col :cols="12" :md="6" class="ph-cashcount-total-container">
        <div class="ph-cashcount-totals">
          <p><b>Total US Dollar:</b> {{ totalUSDAmount }}</p>
          <p><b>Total Peso:</b> {{ totalPesoAmount }}</p>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
      <v-col :cols="12">
        <v-card elevation="1" class="ph-nonex-datatable-container">
          <v-data-table
            :headers="headers"
            :items="cashCountList"
            class="custom-table"
            :calculate-widths="true"
            :items-per-page="-1"
            :hide-default-footer="true"
          >
            <template v-slot:[`item.trx_date`]="{ item }">
              {{ formatDate(item.trx_date) }}
            </template>
            <template v-slot:[`item.bills_count`]="{ item }">
              <v-text-field
                v-if="isEditMode"
                v-model="item.bills_count"
                color="gray darken-1"
                outlined
                number
                type="number"
                hide-spin-buttons
                class="ph-textbox ph-textbox-cash-count"
                @click="item.bills_count = ''"
              />
              {{ !isEditMode ? item.bills_count : '' }}
            </template>
            <template v-slot:[`item.total_amount`]="{ item }">
              {{ !isNaN(item.total_amount) ? item.total_amount : 0 }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="confirmDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          {{ confirmDialogTitle }}
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          {{ confirmDialogText }}
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeConfirmDialog"
            class="ph-action-button"
          >
            No
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="updateCashCountEntry(confirmPayload, confirmActionType)"
            class="ph-action-button"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-fab-transition>
      <v-btn
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
        @click="addNewEntry"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>
<script>
import numeral from 'numeral'
import { renderToast } from '@/utils'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
  name: 'CashCountIndex',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Compliance',
          disabled: false,
          href: '#'
        },
        {
          text: 'Cash Count',
          disabled: true,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Date of Transaction',
          sortable: false,
          value: 'trx_date'
        },
        {
          text: 'Reference No.',
          sortable: false,
          value: 'reference_no'
        },
        {
          text: 'Currency',
          sortable: false,
          value: 'currency_code'
        },
        {
          text: 'Denomination',
          sortable: false,
          value: 'bill'
        },
        {
          text: 'Count',
          sortable: false,
          value: 'bills_count'
        },
        {
          text: 'Amount',
          sortable: false,
          value: 'total_amount'
        }
      ],
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      confirmActionType: '',
      confirmPayload: [],
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      currentLocation: '',
      isEditMode: false,
      totalPesoAmount: 0.00,
      totalUSDAmount: 0.00,
      isEntryValid: true
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections',
      cashCountList: 'cashCount/cashCountList'
    })
  },
  watch: {
    cashCountList: {
      handler (data) {
        let missingFieldCounter = 0
        data.forEach((item, index) => {
          if (!isNaN(item.bills_count)) {
            const getTotalAmountPerItem = parseFloat(item.bill) * parseFloat(item.bills_count)
            const getTotalPerItemRounded = Math.round(getTotalAmountPerItem * 100) / 100
            item.bills_count = parseFloat(item.bills_count)
            item.total_amount = getTotalPerItemRounded
          } else {
            missingFieldCounter += 1
          }
        })

        this.isEntryValid = missingFieldCounter < 1
        this.calculateTotalAmount(data)
      },
      deep: true
    }
  },
  async beforeMount () {
    this.getCashCountList()
  },
  methods: {
    renderToast,
    async getCashCountList () {
      const payload = {
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id,
        encoded_by: this.currUser.user_id
      }
      await this.$store.dispatch('cashCount/getCashCountList', payload)
      this.calculateTotalAmount(this.cashCountList)
    },
    calculateTotalAmount (items) {
      let getPHPTotal = 0
      let getUSDTotal = 0

      items.forEach(item => {
        if (item.currency_code === 'PHP') {
          getPHPTotal += item.total_amount
        } else {
          getUSDTotal += item.total_amount
        }
      })

      if (!isNaN(getPHPTotal) && !isNaN(getUSDTotal)) {
        this.totalPesoAmount = numeral(getPHPTotal).format('0,0.00')
        this.totalUSDAmount = numeral(getUSDTotal).format('0,0.00')
      }
    },
    formatDate (date) {
      if (date) return format(parseISO(date), 'yyyy-MM-dd')
      return date
    },
    addNewEntry () {
      this.$router.push({ path: '/cash-count/form' })
    },
    closeConfirmDialog () {
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''
    },
    cashCountAction (type) {
      this.confirmPayload = []
      this.confirmActionType = type

      this.cashCountList.forEach(item => {
        this.confirmPayload.push({
          trx_cash_count_id: item.trx_cash_count_id,
          bills_count: item.bills_count,
          total_amount: item.total_amount,
          updated_by: this.currUser.user_id
        })
      })

      switch (type) {
        case 'update':
          if (this.isEntryValid) {
            this.confirmDialog = true
            this.confirmDialogTitle = 'Save Update'
            this.confirmDialogText = 'Are you sure you want to save your entry?'
          } else {
            this.renderToast('error', 'Incomplete Field', 'Please complete the fields to continue.')
          }
          break
        case 'cancel':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Cancel Entry'
          this.confirmDialogText = 'Are you sure you want to cancel?'
          break
      }
    },
    async updateCashCountEntry (payload, type) {
      this.closeConfirmDialog()
      if (type === 'cancel') {
        this.isEditMode = false
        this.getCashCountList()
      } else {
        await this.$store.dispatch('cashCount/updateCashCountEntry', payload).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.renderToast('error', 'Error', res.message)
          } else {
            this.renderToast('success', 'Success', 'Update Cash Count Entry Successful')
          }
          this.getCashCountList()
        }).catch(err => {
          this.renderToast('error', 'Error', err)
        })

        this.isEditMode = false
      }
    }
  }
}
</script>
<style>
.ph-action-button.ph-cashcount-action-btn {
  margin: 0 10px;
}

.ph-cashcount-total-container {
  display: flex;
}

.ph-cashcount-totals {
  margin-left: auto;
  text-align: right;
  margin-top: 5px;
}

.ph-cashcount-totals p {
  margin-bottom: 5px;
}
</style>
