import { endpoints } from '@/api/endpoints'

const manual = endpoints.use('manual')

export default {
  getGrid: ({ commit }, { locationID, trxDate }) => new Promise((resolve, reject) => {
    manual.gridWU(locationID, trxDate)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  savePending: ({ commit }, payload) => new Promise((resolve, reject) => {
    manual.saveWUPending(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  save: ({ commit }, payload) => new Promise((resolve, reject) => {
    manual.saveWU(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  delete: ({ commit }, id) => new Promise((resolve, reject) => {
    manual.deleteWU(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  resetState: ({ commit }) => {
    commit('SET_SELECTED_ENTRY', null)
    commit('SET_OAR', '')
    commit('SET_CURRENT_PAGE', 0)
  }
}
