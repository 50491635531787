export default {
  SET_PAGE_TITLE: (state, payload) => {
    state.pageTitle = payload
  },
  SET_COLLECTIONS: (state, { prop, value }) => {
    state.collections[prop] = value
  },
  SET_OAR: (state, payload) => {
    state.oar = payload
  },
  SET_RECEIPT: (state, payload) => {
    state.receipt = payload
  }
}
