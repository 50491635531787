import { endpoints } from '@/api/endpoints'

const prpDeposit = endpoints.use('prpDeposit')

export default {
  getBranchList: ({ commit }, payload) => new Promise((resolve, reject) => {
    prpDeposit.getBranches(payload)
      .then(({ data }) => {
        commit('SET_BRANCHES', data.data[0].branches)
        resolve(data.data[0].branches)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getPRPList: ({ commit }, payload) => new Promise((resolve, reject) => {
    prpDeposit.getPRP(payload)
      .then(({ data }) => {
        commit('SET_PRP', data.data)
        resolve(data.data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  addDeposit: ({ commit }, payload) => new Promise((resolve, reject) => {
    prpDeposit.addDeposit(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  updateDeposit: ({ commit }, payload) => new Promise((resolve, reject) => {
    prpDeposit.updateDeposit(payload.id, payload.data)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getDeposit: ({ commit }, payload) => new Promise((resolve, reject) => {
    prpDeposit.getPRPDeposit(payload)
      .then(({ data }) => {
        commit('SET_DEPOSIT', data.data)
        resolve(data.data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  deleteDeposit: ({ commit }, payload) => new Promise((resolve, reject) => {
    prpDeposit.deleteDeposit(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
