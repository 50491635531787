<template>
  <div class="ph-container">
    <!-- <Header :title="header.title" :img="header.img" /> -->
    <v-container v-if="hasAccess" fluid class="ph-nonex-container">
      <v-row dense>
        <v-col v-for="service in services" :key="service.id" cols="12" sm="6" md="6" lg="3" :class="['d-none', { 'd-block': service.access.includes(approverLevel) }]">
          <v-card v-ripple class="ph-nonex-button mt-5" elevation="0" router :to="service.to" >
            <div class="ph-nonex-button-icon-container">
              <v-avatar
                :color="service.color"
                size="58"
                class="ph-nonex-button-icon"
              >
                <img class="ph-nonex-button-icon-img" :src="require('../../assets/images/' + service.image + '.svg')" />
              </v-avatar>
            </div>
            <div class="ph-nonex-button-text-container">
              <h1 class="ph-nonex-button-title" :style="'color:' + service.text_color">{{service.title}}</h1>
              <p class="ph-nonex-button-description">{{service.description}}</p>
            </div>
          </v-card>
      </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      approverLevel: 'transactionLimit/approverLevel'
    }),
    hasAccess () {
      if (this.currUser) {
        if (Object.prototype.hasOwnProperty.call(this.currUser, 'modules')) {
          const modules = this.currUser.modules.includes('&quot') ? JSON.parse(this.currUser.modules.replace(/&quot;/g, '"')) : this.currUser.modules
          return (modules.map(item => item.access_link).includes('/transaction-limit'))
        }
      }
      return false
    }
  },
  data () {
    return {
      services: [
        {
          id: 1,
          color: 'rgba(253, 209, 0, 0.1)',
          text_color: '#C9A600',
          title: 'Approvers',
          description: 'Manage the approvers',
          image: 'transactionLimit/approvers',
          to: '/transaction-limit/approvers',
          access: ['ADMIN']
        },
        {
          id: 2,
          color: 'rgba(139, 81, 255, 0.1)',
          text_color: '#6633CC',
          title: 'Default Limits',
          description: 'Manage the default limits',
          image: 'transactionLimit/default-limits',
          to: '/transaction-limit/defaults',
          access: ['ADMIN', 'ROH']
        },
        {
          id: 3,
          color: 'rgba(70, 183, 70, 0.1)',
          text_color: '#46B746',
          title: 'Increase Requests',
          description: 'Manage the Increase Requests',
          image: 'transactionLimit/increase-requests',
          to: '/transaction-limit/increase-requests',
          access: ['ADMIN', 'ROH', 'CS', '']
        },
        {
          id: 4,
          color: 'rgba(5, 172, 229, 0.1)',
          text_color: '#05ACE5',
          title: 'Limit Approval',
          description: 'Approve/Disapprove limit requests',
          image: 'transactionLimit/limit-approval',
          to: '/transaction-limit/limit-approval',
          access: ['ADMIN', 'FINANCE', 'NETOPS']
        },
        {
          id: 5,
          color: 'rgba(247, 111, 52, 0.1)',
          text_color: '#F76F34',
          title: 'Monitoring',
          description: 'Monitor limit requests',
          image: 'transactionLimit/monitoring',
          to: '/transaction-limit/monitoring',
          access: ['ADMIN', 'FINANCE', 'NETOPS']
        },
        {
          id: 6,
          color: 'rgba(209, 44, 127, 0.1)',
          text_color: '#D12C7F',
          title: 'Providers',
          description: 'Manage the providers',
          image: 'transactionLimit/providers',
          to: '/transaction-limit/providers',
          access: ['ADMIN']
        }
      ]
    }
  },
  async mounted () {
    const { code, result } = await this.$store.dispatch('transactionLimit/getApprover', this.currUser.user_id)

    if (code === 200) {
      if (result.length > 0) {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', result[0].approver_level)
      } else {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', '')
      }
    }

    if (!this.hasAccess) {
      renderToast('error', 'No access', 'You don\'t have access to this module')
    }
  }
}
</script>
<style scoped>
  .ph-container {
    margin: 0 15px;
  }

  .v-card.ph-nonex-button {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
    /* border: 1px solid #D0D0D0; */
  }

  .ph-nonex-button-text-container {
    padding: 30px;
    width: 100%;
  }

  .ph-nonex-button-title {
    font-family: "Baloo Extra Bold";
    font-size: 24px;
  }

  .ph-nonex-button-icon-container {
    padding: 30px;
    width: 100%;
    display: flex;
    padding-bottom: 0;
    /* margin-right: 20px !important; */
  }

  .ph-nonex-button-description {
    font-size: 16px;
  }

  /* .ph-nonex-button-icon {
    margin: auto;
  } */

  .ph-nonex-button-icon-img {
    margin: auto;
    opacity: 1 !important;
    transform: scale(0.35);
    border-radius: 0;
  }

  @media only screen and (min-width: 500px) {
    .v-card.ph-nonex-button {
      display: flex;
      cursor: pointer;
      box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
      border-radius: 12px;
      height: 250px;
    }

    .ph-nonex-button:nth-of-type(odd) {
      margin-left: 5px;
    }

    .ph-nonex-button:nth-of-type(even) {
      margin-right: 5px;
    }

    .ph-nonex-button-text-container {
      width: 90%;
    }

    .ph-nonex-button-icon-container {
      width: 20%;
      /* margin-right: unset !important; */
    }

    .ph-nonex-button-icon-img {
      margin: auto;
      opacity: 1 !important;
      transform: scale(0.4);
    }
  }

  @media only screen and (min-width: 376px) {
    .ph-nonex-container {
      margin: 0;
    }
  }
</style>
