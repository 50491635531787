export default {
  cardInventory: [],
  debitCardTransactions: [],
  debitCardCurrentTrx: [],
  debitCardCurrentPage: 0,
  debitCardPartners: [],
  eonSourceOfFund: [],
  eonEmployment: [],
  eonNatureOfWork: [],
  eonIDList: [],
  isCustomerHit: '',
  showSpiel: ''
}
