const getLoadingState = state => state.loading
const getOpaqueLoadingState = state => state.opaqueLoading
const getMiniState = state => state.mini
const getDrawerState = state => state.drawer
const getSnackbarState = state => state.snackbar
const getAVPSpielState = state => state.crossSellingSpiel

export default {
  getLoadingState,
  getOpaqueLoadingState,
  getMiniState,
  getDrawerState,
  getSnackbarState,
  getAVPSpielState
}
