import api from '@/api'

export default {
  getCashCountList (payload) {
    return api.get(`/v1/maintenance/api/cashcount?trx_date=${payload.trx_date}&location_id=${payload.location_id}&encoded_by=${payload.encoded_by}`)
  },
  insertCashCountEntry (payload) {
    return api.post('/v1/maintenance/api/cashcount', payload)
  },
  updateCashCountEntry (payload) {
    return api.post('/v1/maintenance/api/cashcount-update', payload)
  }
}
