<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="12" :sm="4" :md="4">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-8" :cols="12">
        <h2 class="title ph-title primary--text" style="font-size: 24px !important;">
          Success Transactions
        </h2>
        <br>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:[`item.pd_amount`]="{ item }">
            {{ formatAmount(item.pd_amount) }}
          </template>
          <template v-slot:[`item.pd_currency_id`]="{ item }">
            {{ item.pd_currency_id === 1 ? 'PHP' : 'USD' }}
          </template>
          <template v-slot:[`item.customer_name`]="{ item }">
            <v-avatar class="mr-1" size="26px" :style="avatarColor(item.customer_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.customer_name) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right v-if="currUser.location_type === '1'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="printOAR(item)" color="primary" icon>
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Print OAR</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="createNew"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import {
  avatarInitial,
  toProperCase,
  avatarColor,
  renderToast
} from '@/utils'
import { mapGetters } from 'vuex'
import numeral from 'numeral'

export default {
  data () {
    return {
      search: '',
      breadcrumbsItems: [
        {
          text: 'Pay @ WU',
          disabled: false,
          exact: true,
          to: '/wu-payments'
        },
        {
          text: 'Payment Order',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Pay Code',
          sortable: true,
          value: 'od_confirmation_number',
          width: '130px'
        },
        {
          text: 'Merchant Name',
          sortable: true,
          value: 'od_merchant_name',
          width: '130px'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'pd_amount',
          width: '130px'
        },
        {
          text: 'Currency',
          sortable: true,
          value: 'pd_currency_id',
          width: '130px'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customer_name',
          width: '180px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '100px'
        }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  methods: {
    createNew () {
      this.$router.push('/wu-payments/pay/new')
    },
    async retrieveItems () {
      try {
        const { code, result } = await this.$store.dispatch('wuPayments/retrieveOrders', {
          trx_date: this.currUser.trx_date,
          location_id: this.currUser.location_id,
          tran_type: 'PAYMENT'
        })

        if (code === 200) {
          this.items = result
        } else {
          renderToast('error', 'API Error', 'Failed to retrieve the data')
        }
      } catch (e) {
        renderToast('error', 'Internal Error', 'An error occurred while retrieving the data')
      }
    },
    avatarInitial,
    toProperCase,
    avatarColor,
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    async printOAR (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: '2',
          or_series_no: '',
          oar_series_no: data.form_number,
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    }
  },
  mounted () {
    this.retrieveItems()
    this.$store.commit('wuPayments/SET_CURRENT_ORDER_TRX', {})
  }
}
</script>
