import { endpoints } from '@/api/endpoints'

const customerProfile = endpoints.use('customerProfileV3')

export default {
  getHitList: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.getHitList(payload)
      .then(({ data }) => {
        commit('SET_HIT_LIST', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateCIStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    const data = payload
    customerProfile.updateCIStatus(data.id, data.status)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  searchByCRN: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.searchByCRN(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  checkIfDuplicate: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.checkIfDuplicate(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  }),
  inquireStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.inquireStatus(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  }),
  createCustomerProfile: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.createCustomerProfile(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  }),
  createVirtualAccount: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.createVirtualAccount(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  }),
  generateCVV: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.generateCVV(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  }),
  lockCard: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.lockCard(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  }),
  replaceCard: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.replaceCard(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  }),
  activateCard: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.activateCard(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  }),
  retryTransaction: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.retryTransaction(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  }),
  getCardInfo: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.getCardInfo(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  }),
  getCardDetails: ({ commit }, payload) => new Promise((resolve, reject) => {
    customerProfile.getCardDetails(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        resolve(err)
      })
  })
}
