<template>
  <v-form ref="form">
    <v-row class="px-8 py-8" no-gutters>
      <v-col class="py-4 px-2" :cols="12" :sm="6" :md="4">
        <v-autocomplete
          v-model="formData.occupation"
          :items="collections.occupation"
          label="Occupation *"
          item-text="occupation"
          item-value="occupation_value"
          :return-object="false"
          :rules="requiredField"
          color="gray darken-1"
          class="ph-textbox"
          autofocus
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :sm="6" :md="4">
        <v-autocomplete
          v-model="formData.position_level"
          label="Position Level *"
          :items="collections.positionLevel"
          item-text="position_level"
          item-value="position_level_value"
          :return-object="false"
          :rules="requiredField"
          color="gray darken-1"
          class="ph-textbox"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :sm="6" :md="4">
        <v-autocomplete
          v-model="formData.employment"
          label="Employment *"
          :items="collections.employment"
          item-text="employment"
          item-value="employment_value"
          :return-object="false"
          :rules="requiredField"
          color="gray darken-1"
          class="ph-textbox"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :sm="6" :md="4">
        <v-text-field
          v-model="formData.employer_name"
          class="ph-textbox"
          color="gray darken-1"
          label="Employer Name *"
          :rules="requiredField"
          @input="convertToProperCase('employer_name')"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :sm="6" :md="4">
        <v-autocomplete
          v-model="formData.nature_work"
          label="Nature of Work *"
          :items="collections.natureOfWork"
          item-text="nature_of_work"
          item-value="nature_of_work_value"
          :return-object="false"
          :rules="requiredField"
          color="gray darken-1"
          class="ph-textbox"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :sm="6" :md="4">
        <v-autocomplete
          v-model="formData.source_fund"
          label="Source of Funds *"
          :items="collections.sourceOfFund"
          item-text="source_of_funds"
          item-value="source_of_funds"
          :return-object="false"
          :rules="requiredField"
          color="gray darken-1"
          class="ph-textbox"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :sm="6" :md="4">
        <v-autocomplete
          v-model="formData.purpose_transaction"
          label="Purpose of Transactions *"
          :items="collections.purposeOfTransaction"
          item-text="purpose"
          item-value="purpose"
          :return-object="false"
          :rules="requiredField"
          color="gray darken-1"
          class="ph-textbox"
          outlined
        />
      </v-col>
      <v-col class="py-4 px-2" :cols="12" :sm="6" :md="4">
        <v-autocomplete
          v-model="formData.relation_to"
          label="Relationship to Sender/Receiver *"
          :items="collections.relationship"
          item-text="relationship"
          item-value="relationship"
          :return-object="false"
          :rules="requiredField"
          color="gray darken-1"
          class="ph-textbox"
          outlined
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { toProperCase } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      formData: 'customers/formData',
      collections: 'collections',
      selectedMobileUser: 'customers/selectedMobileUser'
    })
  },
  data () {
    return {
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  mounted () {
    if (this.collections?.occupation) {
      console.log(this.collections.occupation, this.selectedMobileUser)
    }
  },
  methods: {
    convertToProperCase (field) {
      this.formData[field] = toProperCase(this.formData[field])
    }
  }
}
</script>
