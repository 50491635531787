export default {
  SET_LOAN_ACCOUNT_DATA (state, payload) {
    state.loanAccountData = payload
  },
  SET_LOAN_COLLECTION_TPA_ID (state, payload) {
    state.tpaId = payload
  },
  SET_LOAN_COLLECTION_CURRENT_PAGE (state, payload) {
    state.loanCollectionCurrentPage = payload
  },
  SET_LOAN_COLLECTION_TRANSACTIONS (state, payload) {
    state.loanCollectionTransactions = payload
  },
  SET_LOAN_COLLECTION_RESPONSE_DATA (state, payload) {
    state.loanCollectionResponseData = payload
  },
  SET_LOAN_COLLECTION_CURRENT_TRX (state, payload) {
    state.loanCollectionCurrentTrx = payload
  },
  SET_LOAN_COLLECTION_PROVIDERS (state, payload) {
    state.collectionProviders = payload
  },
  SET_LOAN_ACCOUNT_NO (state, payload) {
    state.loanAccountNo = payload
  }
}
