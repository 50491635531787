<template>
  <v-row>
    <v-col :cols="12" :md="3">
      <v-text-field
       v-model="search"
        class="ph-textbox"
        placeholder="Search Customer Limit"
        append-icon="mdi-magnify"
        hide-details
        outlined
      />
    </v-col>
    <v-col class="d-flex align-center" :cols="12" :md="9">
      <v-btn
        @click="getCustomerLimits"
        class="text-capitalize font-weight-bold"
        color="primary"
        rounded
        large
        text
      >
        <v-icon class="mr-1">mdi-refresh</v-icon>
        Refresh
      </v-btn>
    </v-col>
    <v-col :cols="12">
      <v-card flat>
        <v-data-table
          :headers="customerLimitHeaders"
          :items="customerLimitData"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:item.customer_name="{ item }">
            <div class="d-flex align-center">
              <v-avatar class="mr-2" size="23px" :style="avatarColor(item.customer_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
              </v-avatar>
              {{ item.customer_name }}
            </div>
          </template>
          <template v-slot:item.max_amount="{ item }">
            {{ toMoney(item.max_amount) }}
          </template>
          <template v-slot:item.status="{ item }">
            <span :class="[getTransactionLimitStatus(item.status).class]">
              <v-icon
                :class="['mr-1', getTransactionLimitStatus(item.status).class]"
                x-small
              >
                mdi-circle
              </v-icon>
              {{ getTransactionLimitStatus(item.status).text }}
            </span>
          </template>
          <template v-slot:item.action="{ item }" v-if="approverLevel === 'ROH'">
            <v-btn @click="edit(item)" color="primary" :disabled="item.status !== 1 && item.status !== 4" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="openDeleteDialog(item)" color="primary" :disabled="item.status !== 1 && item.status !== 4" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog
      v-model="showDeleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Customer Limit
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to delete this customer limit?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDeleteDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="doDelete"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getTransactionLimitStatus,
  avatarInitial,
  avatarColor,
  renderToast,
  toMoney
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      selectedCustomerLimit: 'transactionLimit/selectedCustomerLimit',
      currUser: 'auth/currUser',
      approverLevel: 'transactionLimit/approverLevel'
    })
  },
  data () {
    return {
      showDeleteDialog: false,
      search: '',
      customerLimitData: [],
      customerLimitHeaders: [
        {
          text: 'Customer Name',
          value: 'customer_name',
          width: '150px'
        },
        {
          text: 'Provider',
          value: 'provider_name',
          width: '150px'
        },
        {
          text: 'Service Name',
          value: 'service_name',
          width: '150px'
        },
        {
          text: 'Service Type',
          value: 'service_type',
          width: '150px'
        },
        {
          text: 'Current Limit',
          value: 'max_amount',
          width: '150px'
        },
        {
          text: 'Channel',
          value: 'channel',
          width: '150px'
        },
        {
          text: 'Status',
          value: 'status',
          width: '150px'
        },
        {
          text: 'Action',
          value: 'action',
          width: '100px',
          sortable: false
        }
      ]
    }
  },
  methods: {
    edit (payload) {
      this.$store.commit('transactionLimit/SET_SELECTED_CUSTOMER_LIMIT', payload)
      this.$router.push(`/transaction-limit/defaults/customer/${payload.id}`)
    },
    async doDelete () {
      if (this.selectedCustomerLimit) {
        const { code } = await this.$store.dispatch('transactionLimit/deleteCustomerLimit', {
          id: this.selectedCustomerLimit.id,
          userID: this.currUser.user_id
        })

        if (code === 200) {
          renderToast('success', 'Successful', 'Customer Limit has been deleted')
          this.closeDeleteDialog()
          this.getCustomerLimits()
        } else {
          // Add error code
        }
      }
    },
    openDeleteDialog (payload) {
      this.$store.commit('transactionLimit/SET_SELECTED_CUSTOMER_LIMIT', payload)
      this.showDeleteDialog = true
    },
    closeDeleteDialog () {
      this.$store.commit('transactionLimit/SET_SELECTED_CUSTOMER_LIMIT', null)
      this.showDeleteDialog = false
    },
    async getCustomerLimits () {
      const { code, result } = await this.$store.dispatch('transactionLimit/getCustomerLimit')

      if (code === 200) {
        this.customerLimitData = result.sort((x, y) => {
          return (new Date(y.created_at).getTime() - new Date(x.created_at).getTime())
        })
      }
    },
    getTransactionLimitStatus,
    avatarInitial,
    avatarColor,
    toMoney
  },
  mounted () {
    this.getCustomerLimits()
  }
}
</script>
