<template>
  <div class="px-md-2">
    <h2 class="pt-md-6 custom-heading primary--text">
      Success!
    </h2>
    <p class="pt-md-4 pb-md-6">
      Thank you. Pera Travel transaction has been completed.
    </p>
    <h3 class="custom-heading">
      Transaction Summary
    </h3>
    <div class="mt-md-6" style="max-width: 350px">
      <v-row no-gutters>
        <v-col v-for="data in summaryData" :key="data.text" :cols="12">
          <div class="d-flex justify-space-between">
            <div>{{ data.text }}</div>
            <div>
              <strong>{{ data.value || '—' }}</strong>
            </div>
          </div>
          <v-divider class="my-md-2" style="border-style: dashed" />
        </v-col>
      </v-row>
    </div>
    <div class="mt-6">
      <v-btn
        @click="backToMain"
        class="rounded-lg"
        color="primary"
        large
      >
        Back to Pera Travel
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toMoney } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      transactionData: 'airlines/transactionData',
      successData: 'airlines/successData',
      collections: 'collections'
    }),
    summaryData () {
      const fullData = this.transactionData
      const summary = {
        formNumber: { text: 'OAR Number', value: null },
        provider: { text: 'Provider', value: null },
        relocNumber: { text: 'Reloc Number', value: null },
        routeType: { text: 'Route Type', value: null },
        flightType: { text: 'Flight Type', value: null },
        ticketStatus: { text: 'Ticket Status', value: null },
        principalAmount: { text: 'Principal Amount', value: null },
        serviceCharge: { text: 'Service Charge', value: null },
        totalAmount: { text: 'Total Amount', value: null }
      }

      const fields = Object.keys(summary)

      fields.forEach(field => {
        switch (field) {
          case 'formNumber':
            if (fullData.formNumber) {
              summary[field].value = fullData.formNumber
            }
            break
          case 'provider':
            if (fullData.provider) {
              summary[field].value = fullData.provider.name
            }
            break
          case 'routeType':
            if (fullData.routeType) {
              summary[field].value = this.getRouteType(fullData.routeType)
            }
            break
          case 'flightType':
            if (fullData.flightType) {
              summary[field].value = this.getFlightType(fullData.flightType)
            }
            break
          case 'principalAmount':
            if (fullData.principalAmount) {
              summary[field].value = toMoney(fullData.principalAmount)
            }
            break
          case 'serviceCharge':
            if (fullData.serviceCharge) {
              summary[field].value = toMoney(fullData.serviceCharge)
            }
            break
          case 'totalAmount':
            if (fullData.principalAmount) {
              summary[field].value = toMoney(Number(fullData.principalAmount) + Number(fullData.serviceCharge))
            }
            break
          default:
            if (fullData[field]) {
              summary[field].value = fullData[field]
            }
            break
        }
      })

      return Object.values(summary)
    }
  },
  methods: {
    getRouteType (type) {
      const filteredRouteType = this.collections.routeTypes.filter(item => item.value === type)

      if (filteredRouteType.length === 1) return filteredRouteType[0].text
      return ''
    },
    getFlightType (type) {
      const filteredFlightType = this.collections.flightTypes.filter(item => item.value === type)

      if (filteredFlightType.length === 1) return filteredFlightType[0].text
      return ''
    },
    backToMain () {
      this.$store.dispatch('airlines/resetForm')
      this.$router.push({ path: '/pera-travel' })
    }
  },
  mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Transaction Successful')
  }
}
</script>

<style scoped>
h3,
p {
  color: #4A4A4A !important;
}
.icon {
  width: 20%;
}
</style>
