<template>
  <v-row class="ph-bank-deposit-forms-container">
    <v-col cols="12" md="8" class="ph-bank-deposit-form">
      <TransactionProfileCard />
      <v-form ref="bankDepositFields">
        <v-row class="ph-bank-deposit-fields">
          <v-col cols="12">
            <div class="ph-bank-deposit-fields-title">
              <b>Transaction Details</b>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="transactionData.bank"
              item-text="bank_name"
              item-value="bank_code"
              label="Bank"
              :items="bankList"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Bank"
              return-object
              @change="selectBankPartner(transactionData.bank)"
              :rules="requiredField"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="transactionData.account_number"
              label="Account Number"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="number"
              :hide-spin-buttons="true"
              :rules="requiredField"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="transactionData.amount"
              label="Amount"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="number"
              :hide-spin-buttons="true"
              @change="setServiceCharge"
              :rules="requiredField"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="transactionData.service_charge"
              label="Service Charge"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="number"
              :hide-spin-buttons="true"
              readonly
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="transactionData.message"
              label="Message"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="text"
              :hide-spin-buttons="true"
              :rules="requiredField"
            />
          </v-col>
        </v-row>
        <!-- BPI and MB form -->
        <v-row class="ph-bank-deposit-fields bpi-mb-fields" v-if="showMBandBPIForm">
          <v-col cols="12">
            <div class="ph-bank-deposit-fields-title">
              <b>Beneficiary Information</b>
            </div>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="tagSameRemitterBeneficiary"
              @change="toggleSameRemitterBeneficiary"
              class="my-0 py-0"
              :ripple="false"
              label="Is remitter also the beneficiary of this transaction?"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
            />
          </v-col>
          <!-- FName, MName and LName -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="transactionData.beneficiary_first_name"
              label="First Name"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="text"
              :rules="requiredField"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="transactionData.beneficiary_middle_name"
              label="Middle Name"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="text"
              :rules="requiredField"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="transactionData.beneficiary_last_name"
              label="Last Name"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="text"
              :rules="requiredField"
            />
          </v-col>
          <!-- BDay, Gender and Civil -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="transactionData.beneficiary_birth_date"
              @click:append="openDatePicker"
              @input="syncDate"
              class="ph-textbox"
              label="Birth Date *"
              append-icon="mdi-calendar"
              :rules="birthDateRules"
              hint="Type or pick a date by clicking the calendar icon"
              outlined
            />
            <v-dialog v-model="birthDatePicker" width="300">
              <v-date-picker
                v-model="tempDate"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="1900-01-01"
                @input="birthDatePicker = false"
              />
            </v-dialog>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="transactionData.beneficiary_gender"
              :items="collections.gender"
              item-text="text"
              item-value="value"
              :return-object="false"
              label="Gender *"
              class="ph-textbox"
              :rules="requiredField"
              outlined
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="transactionData.beneficiary_civil_status"
              :items="collections.civilStatus"
              label="Civil Status *"
              color="gray darken-1"
              class="ph-textbox"
              :rules="requiredField"
              outlined
            />
          </v-col>
          <!-- TIN -->
          <v-col cols="12">
            <v-text-field
              v-model="transactionData.beneficiary_tin"
              label="TIN Number"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="number"
              :hide-spin-buttons="true"
              :readonly="hasTIN"
              :rules="requiredField"
            />
            <br>
            <v-checkbox
              v-model="hasTIN"
              @change="toggleNoTIN"
              class="my-0 py-0"
              :ripple="false"
              label="Check this if beneficiary has no TIN Number"
              on-icon="mdi-check-circle"
              off-icon="mdi-checkbox-blank-circle-outline"
              hide-details
            />
          </v-col>
          <!-- Address, Country, City and Province -->
          <v-col cols="12">
            <div class="ph-bank-deposit-fields-title">
              <b>Beneficiary Address</b>
            </div>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="transactionData.beneficiary_address"
              class="ph-textbox"
              color="gray darken-1"
              label="Address"
              :rules="requiredField"
              outlined
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="transactionData.beneficiary_city"
              :items="collections.cities"
              item-text="city"
              item-value="city"
              :return-object="false"
              label="City"
              :rules="requiredField"
              color="gray darken-1"
              @change="onCurrentCityChange"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="transactionData.beneficiary_province"
              class="ph-textbox"
              color="gray darken-1"
              label="Province"
              :rules="requiredField"
              disabled
              outlined
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="transactionData.beneficiary_country"
              :items="collections.countries"
              item-text="country"
              item-value="country"
              :return-object="false"
              label="Country"
              :rules="requiredField"
              color="gray darken-1"
              class="ph-textbox"
              autofocus
              outlined
            />
          </v-col>
        </v-row>
        <v-row class="ph-bank-deposit-back-button">
          <v-col cols="12" class="ph-stepper-action-buttons">
            <v-btn
              color="danger"
              outlined
              text
              light
              class="ph-primary-button ph-stepper-button ph-action-button"
              @click="cancelButton"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="4" class="ph-bank-deposit-summary">
      <v-card
        elevation="0"
        color="rgba(26, 39, 145, 0.05)"
      >
        <div class="ph-transfer-summary-header">
          <b>Summary Overview</b>
        </div>
        <br>
        <div class="ph-transfer-summary-content">
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in bankDepositSummaryOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <wacom
          v-if="currUser.has_wacom"
          @submit-sig="submitSig" type="transactional"
          :referenceSignatures="customerSignaturesReference"
          :trxData="{sender_name: `${quickPayTrxData.fullName}`, receiver_name: `${quickPayTrxData.company}`, total_amount: sendoutAmount}"
        />
        <div class="ph-transfer-summary-action">
          <v-btn
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn ph-validate-cash-in-desktop"
            @click="validateBankDeposit"
          >
            Confirm
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-bank-deposit-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-bank-deposit-summary-minified">
          <div class="ph-bank-deposit-summary-content">
            <div class="ph-bank-deposit-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>PHP {{amount > 0 ? amount : '-'}}</b></p>
            </div>
            <div class="ph-bank-deposit-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <div class="ph-transfer-summary-content">
                <p class="ph-transfer-summary-content-title">Please confirm if the sendout details are correct</p>
                <v-simple-table class="ph-transfer-summary-table">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in bankDepositSummaryOverview"
                        :key="item.name"
                      >
                        <td class="transfer-info-title">{{ item.name }}</td>
                        <td class="transfer-info-value">{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="ph-transfer-summary-action">
                <v-btn
                  color="primary"
                  elevation="0"
                  class="ph-primary-button ph-primary-execute-btn ph-validate-cash-in"
                  @click="validateBankDeposit"
                >
                  Confirm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noIDFlag"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          No identification card found
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="noIDFlag = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="updateDetails(selectedCustomer)"
          >
            Update Customer Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- OTP -->
    <v-dialog
      v-model="bankDepositOTPDialog"
      persistent
      :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '30%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="bankDepositOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <br>
          <p v-if="!showAmount"><b>Amount to Cash-In: </b>PHP {{formatAmount(transactionData.amount)}}</p>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withoutEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updateDetails(selectedCustomer)"
            color="primary"
            text
          >
            Update Customer Record
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Receipt will send to the client/s email address. Would you like to proceed this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="printReceiptInstead"
            color="primary"
            text
          >
            I want a printed Receipt
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <flaggingPrompt @callback="executeDeposit" />
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { avatarColor, avatarInitial, getForeignRefNo, renderToast, getCivilStatusCode, getGenderCode } from '@/utils'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import flaggingPrompt from '@/components/transactionLimit/flaggingPrompt.vue'
import { format } from 'date-fns'

export default {
  name: 'CashInForm',
  data () {
    return {
      noIDFlag: false,
      bankDepositOTPDialog: false,
      isOTPLoading: false,
      otpInvalid: false,
      otp: '',
      otpMessage: '',
      summaryDialog: false,
      isNotCustomerPage: true,
      showAmount: true,
      bankDepositSummaryOverview: [],
      providersList: [],
      mobileNumber: '',
      amount: '',
      currentId: '',
      foreignRefNo: '',
      petnetTrackingNo: '',
      currentProvider: '',
      currentProviderAmountPlaceholder: '',
      currentProviderTooltip: '',
      transactionData: {},
      executeData: {},
      minAmount: '',
      maxAmount: '',
      bankList: [],
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      tempDate: null,
      birthDatePicker: false,
      birthDateRules: [
        v => !!v || 'This field is required',
        v => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v) || 'Please use YYYY-MM-DD format'
      ],
      requiredField: [
        v => !!v || 'This field is required'
      ],
      showMBandBPIForm: false,
      tagSameRemitterBeneficiary: '',
      hasTIN: false
    }
  },
  computed: {
    ...mapGetters({
      bankDepositTransactions: 'bankDeposit/bankDepositTransactions',
      collections: 'collections',
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer',
      bankDepositCurrentTrx: 'bankDeposit/bankDepositCurrentTrx',
      oarNumber: 'oar/oarNumber',
      bankDepositPartners: 'bankDeposit/bankDepositPartners',
      bankDepositDetails: 'bankDeposit/bankDepositPartnerDetails',
      receipt: 'receipt'
    })
  },
  components: {
    TransactionProfileCard,
    flaggingPrompt
  },
  watch: {
    transactionData: {
      handler (val) {
        if (Object.prototype.hasOwnProperty.call(val, 'bank')) {
          this.handleChangeBank(val.bank)
        }
        this.updateOverview(val)
      },
      deep: true
    },
    tempDate (val) {
      this.transactionData.beneficiary_birth_date = val
    }
  },
  async mounted () {
    if (this.selectedCustomer) {
      this.foreignRefNo = this.getForeignRefNo()

      this.bankDepositSummaryOverview = [
        { name: 'Reference Number', value: '-' },
        { name: 'Currency', value: '-' },
        { name: 'Bank', value: '-' },
        { name: 'Account Name', value: '-' },
        { name: 'Account Number', value: '-' },
        { name: 'Amount', value: '-' },
        { name: 'Service Charge', value: '-' },
        { name: 'Message', value: '-' }
      ]

      await this.$store.dispatch('bankDeposit/getBankPartners')
      await this.$store.dispatch('bankDeposit/getBankDetails')

      this.bankList = this.bankDepositPartners
      const signatures = await this.$store.dispatch('customers/retrieveSignatures', { customer_id: this.selectedCustomer.customer_id })
      console.log(signatures)
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    getForeignRefNo,
    renderToast,
    getCivilStatusCode,
    getGenderCode,
    flagChecker () {
      if (this.currUser.first_name === this.selectedCustomer.first_name && this.currUser.last_name === this.selectedCustomer.last_name) {
        renderToast('error', 'Blocked Transaction', 'You are not allowed to process this transaction for this customer')
        return false
      }

      if (this.currUser.last_name === this.selectedCustomer.last_name) {
        this.$store.commit('transactionLimit/SET_FLAGGED_TRANSACTION_PAYLOAD', {
          location_id: this.currUser.location_id,
          user_id: this.currUser.user_id,
          customer_id: this.selectedCustomer.customer_id,
          service_name: 'RTA',
          service_type: 'OUTBOUND',
          provider_name: this.transactionData.bank.bank_name,
          provider_code: this.transactionData.bank.bank_code
        })
        this.$store.commit('transactionLimit/SET_SHOW_FLAGGED_PROMPT_DIALOG', true)
      } else {
        this.executeDeposit()
      }
    },
    handleChangeBank (value) {
      var filtered = this.bankDepositDetails.filter(function (e) {
        return e.bank_id === value.id
      })
      this.minAmount = filtered[0].range_from
      this.maxAmount = filtered[0].range_to
    },
    openOTPDialog () {
      this.withoutEmailPrompt = false
      this.withEmailPrompt = false
      this.openVerifyOTP()
    },
    proceedTransaction () {
      this.openOTPDialog()
    },
    checkEmail () {
      if (this.currUser.location_type === '1') {
        this.sendViaEmail = true
        if (this.selectedCustomer.email_add) {
          this.withoutEmailPrompt = false
          this.withEmailPrompt = true
        } else {
          this.withoutEmailPrompt = true
          this.withEmailPrompt = false
          this.sendViaEmail = false
        }
      } else {
        this.proceedTransaction()
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.openOTPDialog()
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    updateOverview (data) {
      console.log(data)
      //   let amount = ''

      //   if (data?.trx?.principal_amount) {
      //     amount = data.trx.principal_amount
      //   } else if (data.amount) {
      //     amount = data.amount
      //   } else if (data.amount < 1) {
      //     amount = '-'
      //   } else {
      //     amount = '-'
      //   }

      this.bankDepositSummaryOverview = [
        { name: 'Reference Number', value: this.foreignRefNo ? this.foreignRefNo : '-' },
        { name: 'Currency', value: 'PHP' },
        { name: 'Bank', value: this.transactionData.bank?.bank_name ? this.transactionData.bank.bank_name : '-' },
        { name: 'Account Name', value: `${this.selectedCustomer.last_name}, ${this.selectedCustomer.first_name}` },
        { name: 'Account Number', value: this.transactionData.account_number },
        { name: 'Amount', value: this.transactionData.amount },
        { name: 'Service Charge', value: this.transactionData.service_charge },
        { name: 'Message', value: this.transactionData.message }
      ]
    },
    async selectBankPartner (data) {
      this.transactionData.bpiBankCode = this.transactionData.bank.id === 8 ? 'CHBKPHMMXXX' : data.bpi_bank_code

      if (data.id !== 1) {
        this.showMBandBPIForm = true
      } else {
        this.showMBandBPIForm = false
      }
    },
    async validateBankDeposit () {
      if (this.$refs.bankDepositFields.validate()) {
        if (this.transactionData.bank) {
          if (this.showAmount === true && (!this.transactionData.amount || this.transactionData.amount < 1)) {
            this.renderToast('error', 'Transaction Error', 'Amount field is required and must not be 0')
          } else if (parseInt(this.transactionData.amount) < parseInt(this.minAmount)) {
            this.renderToast('error', 'Transaction Error', `${this.formatAmount(this.transactionData.amount)} is below ${this.transactionData.bank.bank_name}'s minimum transaction amount`)
          } else if (parseInt(this.transactionData.amount) > parseInt(this.maxAmount)) {
            this.renderToast('error', 'Transaction Error', `${this.formatAmount(this.transactionData.amount)} is above ${this.transactionData.bank.bank_name}'s maximum transaction amount`)
          } else {
            this.checkEmail()
          }
        }
      } else {
        this.renderToast('error', 'Transaction Error', 'Please fill-out the missing fields')
      }
    },
    async executeDeposit () {
      const totalAmount = parseFloat(this.transactionData.amount) + parseFloat(this.transactionData.service_charge)
      const getDate = format(new Date(), 'yyyy-MM-dd')
      const getTime = format(new Date(), 'HH:mm:ss.SSS')
      const getCurrentDate = `${getDate}T${getTime}`

      try {
        const isProcessable = await this.$store.dispatch('transactionLimit/checkLimit', {
          transactionName: this.transactionData.bank.bank_code,
          payload: {
            customer_id: this.selectedCustomer.customer_id,
            provider_code: this.transactionData.bank.bank_code,
            service_name: 'RTA',
            service_type: 'OUTBOUND',
            amount: this.transactionData.amount
          }
        })

        if (isProcessable) {
          switch (this.transactionData.bank.id) {
            case 1:
              await this.executeUBDeposit(totalAmount, getCurrentDate)
              break
            default:
              await this.executeOthersDeposit(totalAmount, getCurrentDate)
          }
        }
      } catch (error) {
        renderToast('error', 'Checking Failed', 'Failed to check current limit, please submit a ticket to IT Support')
      }
    },
    async executeUBDeposit (totalAmount, getCurrentDate) {
      let ubpToken = ''

      await this.$store.dispatch('bankDeposit/getUBPToken').then(res => {
        if (res.code >= 200 && res.code <= 299) {
          ubpToken = res.result.access_token
        } else {
          this.renderToast('error', 'Error', res.message)
        }
      }).catch(err => {
        console.log(err)
      })

      if (ubpToken === '') {
        this.renderToast('error', 'Error', 'Token is Missing')
      } else {
        const payload = {
          token: ubpToken,
          data: {
            bank_id: this.transactionData.bank.id,
            location_id: this.currUser.location_id,
            user_id: this.currUser.user_id,
            biller_name: this.transactionData.bank.bank_code,
            trx_time: format(new Date(), 'HH:mm:ss'),
            total_amount: totalAmount.toString(),
            currency_id: 1,
            remote_location_id: this.currUser.location_id,
            remote_user_id: this.currUser.user_id,
            customer_id: this.selectedCustomer.customer_id,
            form_type: (this.currUser.location_type === '1' ? 'OAR' : ''),
            form_number: (this.currUser.location_type === '1' ? this.receipt.oar_series_no : this.foreignRefNo),
            trx_type: 1,
            reference_number: this.foreignRefNo,
            trx_date: getCurrentDate,
            account_number: this.transactionData.account_number,
            currency: 'PHP',
            principal_amount: this.transactionData.amount,
            service_charge: this.transactionData.service_charge.toString(),
            remarks: 'Transfer remarks',
            particulars: 'Transfer particulars',
            merchant_name: 'Cash In via PERA HUB',
            notification: 'false',
            account_name: this.selectedCustomer.last_name + ', ' + this.selectedCustomer.first_name,
            info: [
              {
                index: 1,
                name: 'Recipient',
                value: this.selectedCustomer.last_name + ', ' + this.selectedCustomer.first_name
              },
              {
                index: 2,
                name: 'Message',
                value: this.transactionData.message
              }
            ]
          }
        }

        await this.$store.dispatch('bankDeposit/executeUBPCashIn', payload).then(async res => {
          if (res.code >= 200 && res.code <= 299) {
            this.$store.commit('bankDeposit/SET_PERA_BANK_CURRENT_TRX', payload)
            this.renderToast('success', 'Bank Deposit Successful', res.message)

            if (this.currUser.location_type === '1') {
              this.generateEReceipt()
            }
            this.$store.commit('bankDeposit/SET_PERA_BANK_CURRENT_PAGE', 2)
          } else {
            this.renderToast('error', 'Error', res.message)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    async executeOthersDeposit (totalAmount, getCurrentDate) {
      const payload = {
        reference_number: this.foreignRefNo,
        trx_date: getCurrentDate,
        account_number: this.transactionData.account_number,
        currency: '1',
        service_charge: this.transactionData.service_charge.toString(),
        remarks: this.transactionData.message,
        particulars: 'Transfer Particulars',
        merchant_name: 'Perahub',
        bank_id: this.transactionData.bank.id,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        currency_id: '1',
        customer_id: this.selectedCustomer.customer_id,
        form_type: 'OAR',
        form_number: this.receipt.oar_series_no,
        trx_type: '1',
        remote_location_id: this.currUser.location_id,
        remote_user_id: this.currUser.user_id,
        biller_name: this.transactionData.bank.bank_code,
        trx_time: format(new Date(), 'HH:mm:ss'),
        total_amount: totalAmount,
        account_name: 'Jerica Naparate', // test account
        // account_name: this.selectedCustomer.last_name + ' ' + this.selectedCustomer.first_name,
        beneficiary_address: this.transactionData.beneficiary_address,
        beneficiary_birthdate: this.transactionData.beneficiary_birth_date,
        beneficiary_city: this.transactionData.beneficiary_city.substr(0, 20),
        beneficiary_civil: this.getCivilStatusCode(this.transactionData.beneficiary_civil_status),
        beneficiary_country: this.transactionData.beneficiary_country,
        beneficiary_customertype: 'I',
        beneficiary_firstname: this.transactionData.beneficiary_first_name,
        beneficiary_lastname: this.transactionData.beneficiary_middle_name,
        beneficiary_middlename: this.transactionData.beneficiary_last_name,
        beneficiary_tin: this.transactionData.beneficiary_tin,
        beneficiary_sex: this.getGenderCode(this.transactionData.beneficiary_gender),
        beneficiary_state: this.transactionData.beneficiary_province,
        currency_code_principal_amount: 'PHP',
        principal_amount: this.transactionData.amount,
        record_type: '01',
        remitter_address: this.selectedCustomer.address,
        remitter_birthdate: this.selectedCustomer.birth_date,
        remitter_city: this.selectedCustomer.city1.substr(0, 20),
        remitter_civil: this.getCivilStatusCode(this.selectedCustomer.civil_status),
        remitter_country: this.selectedCustomer.country,
        remitter_customer_type: 'I',
        remitter_firstname: this.selectedCustomer.first_name,
        remitter_gender: this.getGenderCode(this.selectedCustomer.gender),
        remitter_id: this.selectedCustomer.customer_id,
        remitter_lastname: this.selectedCustomer.last_name,
        remitter_middlename: this.selectedCustomer.middle_name,
        remitter_state: this.selectedCustomer.province,
        settlement_mode: this.transactionData.bank.id === 30 ? '01' : '03',
        bene_zip_code: '1000'
      }

      let executeRoute = ''

      if (this.transactionData.bank.id === 30) {
        executeRoute = 'bankDeposit/executeMBCashIn'
      } else {
        payload.bpi_bank_code = this.transactionData.bank.id === 8 ? 'CHBKPHMMXXX' : this.transactionData.bpiBankCode
        executeRoute = 'bankDeposit/executeBPICashIn'
      }

      await this.$store.dispatch(executeRoute, payload).then(async res => {
        if (res.code >= 200 && res.code <= 299) {
          this.$store.commit('bankDeposit/SET_PERA_BANK_CURRENT_TRX', payload)

          if (this.currUser.location_type === '1') {
            this.generateEReceipt()
          }

          this.$store.commit('bankDeposit/SET_PERA_BANK_CURRENT_PAGE', 2)
          this.renderToast('success', 'Bank Deposit Successful', res.message)
        } else {
          if (res.code === '8') {
            this.$store.commit('bankDeposit/SET_PERA_BANK_CURRENT_TRX', payload)

            if (this.currUser.location_type === '1') {
              this.generateEReceipt()
            }

            this.$store.commit('bankDeposit/SET_PERA_BANK_CURRENT_PAGE', 2)
            this.renderToast('success', 'Bank Deposit Successful', res.message)
          } else if (res?.error) {
            this.renderToast('error', 'Error', res.error.message)
          } else {
            this.renderToast('error', 'Error', res.message)
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async generateEReceipt () {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipalty
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const saveReceipt = await this.$store.dispatch('saveReceipt', {
        ereceipt_id: this.receipt.ereceipt_id,
        application_id: this.currUser.application_id,
        application_header_name: this.currUser.branchDetail.header_name,
        application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
        receipt_type: this.receipt.type,
        customer_id: this.selectedCustomer.customer_id,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_tin_no: '',
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
        cashier_id: this.currUser.user_id,
        cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
        oar_receipt_no: this.receipt.oar_receipt_no,
        oar_number: this.receipt.oar_series_no,
        oar_service: 'Pera Bank',
        oar_currency: 'PHP',
        oar_principal_amount: Number(this.transactionData.amount),
        oar_discount: 0,
        oar_total: Number(this.transactionData.amount) + Number(this.transactionData.service_charge),
        grand_total: Number(this.transactionData.amount) + Number(this.transactionData.service_charge),
        print_email_hold: 0,
        is_forex_logo: 0
      })

      if (saveReceipt.code === 200) {
        if (saveReceipt.result.receipt_status === 1) {
          renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
        } else if (saveReceipt.result.receipt_status === 2) {
          const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
            application_id: this.currUser.application_id,
            ereceipt_api_id: saveReceipt.result.ereceipt_api_id
          })
          var newBlob = new Blob([res], { type: 'application/pdf' })
          var newFileURL = URL.createObjectURL(newBlob)
          window.open(newFileURL, '_blank')
        } else {
          renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
        }
      } else {
        renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
      }
    },
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    openVerifyOTP () {
      this.otp = ''
      this.bankDepositOTPDialog = true

      console.log(this.bankDepositOTPDialog)
    },
    updateDetails (customerDetails) {
      this.noIDFlag = false

      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    cancelButton () {
      this.$store.commit('bankDeposit/SET_PERA_BANK_CURRENT_PAGE', 0)
    },
    async doVerify (pin) {
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        const payload = {
          user_name: this.currUser.user_name,
          otp_code: this.otp,
          pin: this.currUser.otp_pin,
          module: 'send'
        }

        const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)
        const isOTPVerified = OTPVerification.result?.value || false

        if (isOTPVerified) {
          this.otpInvalid = false
          this.isOTPLoading = false
          this.bankDepositOTPDialog = false

          if (this.currUser.location_type !== '1') {
            this.flagChecker()
          } else {
            const getReceipt = await this.$store.dispatch('getReceipt', {
              location_id: this.currUser.location_id,
              type: '2',
              application_id: this.currUser.application_id
            })

            if (getReceipt.code === 200) {
              this.flagChecker()
            } else {
              renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
            }
          }
        } else {
          this.otp = ''
          this.otpInvalid = true
          this.isOTPLoading = false
          this.otpMessage = 'Incorrect OTP PIN, please try again.'
        }
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    },
    setServiceCharge (amount) {
      let serviceCharge = 0

      this.bankDepositDetails.forEach(item => {
        if (parseFloat(amount) >= parseFloat(item.range_from) && parseFloat(amount) <= parseFloat(item.range_to) && this.transactionData.bank.id === item.bank_id) {
          serviceCharge = parseFloat(item.service_charge)
          console.log(item)
        }
      })

      const addToPayload = {
        service_charge: serviceCharge
      }

      this.transactionData = Object.assign({}, this.transactionData, addToPayload)
    },
    syncDate (val) {
      if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(val)) {
        this.tempDate = val
      }

      console.log(this.tempDate)
    },
    openDatePicker () {
      this.birthDatePicker = true
    },
    async onCurrentCityChange (city) {
      console.log('selected city:' + city)
      const selectedCity = this.collections.cities.filter(item => item.city === city)

      if (selectedCity.length === 1) this.transactionData.beneficiary_province = selectedCity[0]?.province

      const barangayList = await this.$store.dispatch('getBarangayList', city)

      if (barangayList.code === 200) this.barangay = barangayList.result
    },
    toggleSameRemitterBeneficiary (isChecked) {
      if (isChecked) {
        this.transactionData.beneficiary_address = this.selectedCustomer.address
        this.transactionData.beneficiary_birth_date = this.selectedCustomer.birth_date
        this.transactionData.beneficiary_city = this.selectedCustomer.city1
        this.transactionData.beneficiary_civil_status = this.selectedCustomer.civil_status
        this.transactionData.beneficiary_country = this.selectedCustomer.country
        this.transactionData.beneficiary_first_name = this.selectedCustomer.first_name
        this.transactionData.beneficiary_middle_name = this.selectedCustomer.middle_name
        this.transactionData.beneficiary_last_name = this.selectedCustomer.last_name
        this.transactionData.beneficiary_gender = this.selectedCustomer.gender
        this.transactionData.beneficiary_province = this.selectedCustomer.province
      } else {
        this.transactionData.beneficiary_address = ''
        this.transactionData.beneficiary_birth_date = ''
        this.transactionData.beneficiary_city = ''
        this.transactionData.beneficiary_civil_status = ''
        this.transactionData.beneficiary_country = ''
        this.transactionData.beneficiary_first_name = ''
        this.transactionData.beneficiary_middle_name = ''
        this.transactionData.beneficiary_last_name = ''
        this.transactionData.beneficiary_tin = ''
        this.transactionData.beneficiary_gender = ''
        this.transactionData.beneficiary_province = ''
        this.hasTIN = false
      }
    },
    toggleNoTIN (isChecked) {
      this.hasTIN = isChecked

      if (isChecked) {
        this.transactionData.beneficiary_tin = '000000000000000'
      } else {
        this.transactionData.beneficiary_tin = ''
      }
    }
  }
}
</script>
<style scoped>
  .v-stepper.ph-wu-quickpay-stepper .v-stepper__header {
    box-shadow: none !important;
  }

  .ph-bank-deposit-summary .v-card {
    padding: 20px;
  }

  .ph-transfer-summary-header {
    font-size: 18px;
    text-align: center;
  }

  .ph-transfer-summary-content-title {
    font-size: 14px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
    border: 2px solid #1A2791;
  }

  .ph-transaction-container {
    padding: 0;
  }

  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 100px;
  }

  .ph-bank-deposit-summary-minified {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F4F4FA;
  }

  .ph-bank-deposit-summary-content {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
  }

  .ph-bank-deposit-summary-minified-info {
    margin-right: auto;
  }

  .ph-bank-deposit-summary-minified-info p {
    margin-bottom: 0;
  }

  .ph-bank-deposit-summary-minified-action {
    margin-left: auto;
  }

  .ph-primary-button.ph-stepper-button {
    height: 40px !important;
    width: 115px;
  }

  .ph-bank-deposit-form {
    padding: 12px 0;
  }

  /* @media only screen and (min-width: 600px) {
    .v-stepper.ph-wu-quickpay-stepper .v-stepper__step {
      padding-left: 0;
    }
  } */

  .transfer-info-title,
  .transfer-info-value {
    border-bottom: thin dashed rgba(0,0,0,.12) !important;
  }

  .ph-bank-deposit-providers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .ph-bank-deposit-partner-container {
    height: 100px;
    display: flex;
    z-index: 2;
    border: 1px solid transparent;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08) !important;
    border-radius: 12px !important;
    transition: all .2s;
    cursor: pointer;
  }

  .ph-bank-deposit-partner-container:hover {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  .ph-bank-deposit-partner-icon-img {
    margin: auto;
    object-fit: fill;
    z-index: 1;
    border-radius: 0 !important;
  }

  .ph-bank-deposit-partner-icon-img.partner-paymaya {
    height: 50px;
    width: auto;
    /* position: relative;
    bottom: 15px; */
  }

  .ph-bank-deposit-partner-icon-img.partner-diskartech {
    height: 45px;
    width: auto;
  }

  .ph-bank-deposit-partner-icon-img.partner-dragonpay {
    height: 65px;
    width: auto;
  }

  .ph-bank-deposit-partner-icon-img.partner-ph,
  .ph-bank-deposit-partner-icon-img.partner-eon {
    height: 30px;
    width: auto;
  }

  .ph-bank-deposit-partner-icon-img.partner-phv {
    height: 30px;
    width: auto;
    margin-right: 10px;
  }

  .phv-text {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    font-size: 25px;
    font-family: 'Proxima Nova Bold';
  }

  .ph-bank-deposit-fields,
  .ph-bank-deposit-back-button {
    margin: 0;
  }

  .ph-bank-deposit-partner-container.selected {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  @media only screen and (min-width: 960px) {
    .ph-stepper-action-buttons {
      display: flex;
      margin-bottom: 0;
    }

    .ph-bank-deposit-forms-container {
      margin: 0;
    }
  }
</style>
