<template>
  <div class="px-md-4 mt-3">
    <div class="mb-3 mb-md-0" v-if="hasReceivers">
      <strong>Exisiting Recipient/s</strong>
      <v-row class="mt-md-3 mb-md-5" no-gutters>
        <v-col :cols="12" :md="6">
          <v-data-table
            class="ph-clickable-table"
            @click:row="selectReceiver"
            :headers="headers"
            :items="receiverList"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:[`item.full_name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(`${item.last_name}, ${item.first_name}`)">
                <span class="white--text text-body-2">{{ avatarInitial(`${item.last_name}, ${item.first_name}`) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.country`]="{ item }">
              {{ item.country_code }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <strong>Receiver Name</strong>
    <v-btn
      @click="beneficiaryDialog = true"
      v-if="transactionData.remcoID === 9"
      class="ml-2 ml-md-4"
      color="primary"
      rounded
      small
    >
      Add Beneficiary
    </v-btn>
    <v-row class="mt-md-6 mb-md-5" no-gutters>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="transactionData.receiverFirstName"
          @input="convertToProperCase('receiverFirstName')"
          class="ph-textbox mt-2 mt-md-0 mb-3 mb-md-6 mr-md-1"
          label="First Name"
          :readonly="transactionData.remcoID === 9"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="transactionData.receiverMiddleName"
          @input="convertToProperCase('receiverMiddleName')"
          class="ph-textbox mb-3 mb-md-6 mx-md-1"
          label="Middle Name"
          :readonly="transactionData.remcoID === 9"
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="transactionData.receiverLastName"
          @input="convertToProperCase('receiverLastName')"
          class="ph-textbox mb-3 mb-md-6 ml-md-1"
          label="Last Name"
          :readonly="transactionData.remcoID === 9"
          outlined
        />
      </v-col>
      <v-col v-if="transactionData.remcoID === 10 || transactionData.remcoID === 22" :cols="12" :md="4">
        <v-text-field
          v-model="transactionData.receiverMobileNumber"
          @input="convertToProperCase('receiverMobileNumber')"
          class="ph-textbox mb-md-6 ml-md-1"
          label="Mobile Number"
          outlined
        />
      </v-col>
      <v-col v-if="transactionData.remcoID === 3" :cols="12" :md="4">
        <v-text-field
          v-model="transactionData.receiverAccountNumber"
          @input="convertToProperCase('receiverAccountNumber')"
          class="ph-textbox mb-md-6 ml-md-1"
          label="Account Number"
          outlined
        />
      </v-col>
      <v-col v-if="hasState && transactionData.deliveryService.value !== 'D2B'" :cols="12" :md="4">
        <v-autocomplete
          v-model="transactionData.receiverState"
          @change="getCities"
          :items="collections.stateAndCities"
          item-text="state_name"
          item-value="state_code"
          class="ph-textbox mb-md-6 ml-md-1"
          label="State"
          outlined
        />
      </v-col>
      <v-col v-if="hasCities" :cols="12" :md="4">
        <v-autocomplete
          v-model="transactionData.receiverCity"
          :items="cities"
          item-text="city"
          item-value="city"
          class="ph-textbox mb-md-6 ml-md-1"
          label="City"
          :disabled="cities.length === 0"
          outlined
        />
      </v-col>
    </v-row>

    <div class="mt-6 d-flex justify-space-between">
      <v-btn
        @click="prev"
        class="rounded-lg font-weight-bold"
        color="primary"
        outlined
        large
      >
        Back
      </v-btn>
    </div>
    <v-dialog
      v-model="beneficiaryDialog"
      width="450px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      persistent
    >
      <v-card>
        <v-card-title>
          <h3 class="custom-heading primary--text">
            Add Beneficiary
          </h3>
        </v-card-title>
        <v-card-text>
          <v-form ref="beneficiaryForm">
            <v-row>
              <v-col :cols="12">
                <v-text-field
                  v-model="beneficiaryData.first_name"
                  class="ph-textbox"
                  label="First Name *"
                  :rules="requiredField"
                  dense
                  outlined
                />
              </v-col>
              <v-col :cols="12">
                <v-text-field
                  v-model="beneficiaryData.middle_name"
                  class="ph-textbox"
                  label="Middle Name *"
                  :rules="requiredField"
                  dense
                  outlined
                />
              </v-col>
              <v-col :cols="12">
                <v-text-field
                  v-model="beneficiaryData.last_name"
                  class="ph-textbox"
                  label="Last Name *"
                  :rules="requiredField"
                  dense
                  outlined
                />
              </v-col>
              <v-col :cols="12">
                <v-text-field
                  v-model="beneficiaryData.birth_date"
                  @click:append="datePickerDialog = true"
                  @input="syncDate"
                  :rules="dateRules"
                  class="ph-textbox"
                  label="Birth Date"
                  append-icon="mdi-calendar"
                  outlined
                />
                <v-dialog v-model="datePickerDialog" width="300">
                  <v-date-picker
                    v-model="tempDate"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @input="datePickerDialog = false"
                  />
                </v-dialog>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="beneficiaryDialog = false" color="grey darken-1" text>
            Cancel
          </v-btn>
          <v-btn @click="addBeneficiary" color="primary">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  toProperCase,
  avatarColor,
  avatarInitial,
  trim
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      transactionData: 'send/transactionData',
      receiverList: 'send/receiverList',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser',
      collections: 'collections'
    }),
    hasReceivers () {
      return (this.transactionData.remcoID === 0 || this.transactionData.remcoID === 9)
    },
    hasState () {
      const { destinationCountryCode } = this.transactionData
      return (destinationCountryCode === 'US' || destinationCountryCode === 'MX')
    },
    hasCities () {
      const { destinationCountryCode } = this.transactionData
      return (destinationCountryCode === 'MX')
    }
  },
  data () {
    return {
      cities: [],
      tempDate: '',
      datePickerDialog: false,
      beneficiaryDialog: false,
      beneficiaryData: {
        first_name: '',
        middle_name: '',
        last_name: '',
        birth_date: ''
      },
      requiredField: [
        v => !!v || 'This field is required'
      ],
      dateRules: [
        v => (!v || (!!v && /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v))) || 'Please use YYYY-MM-DD format'
      ],
      headers: [
        {
          text: 'Full name',
          value: 'full_name'
        },
        {
          text: 'Country',
          value: 'country'
        }
      ],
      hasSignature: '',
      customerSignaturesReference: [],
      customerSignature: ''
    }
  },
  watch: {
    tempDate (val) {
      this.beneficiaryData.birth_date = val
    }
  },
  methods: {
    getCities (stateCode) {
      const cities = this.collections.stateAndCities.filter(item => item.state_code === stateCode)

      this.cities = cities
    },
    async addBeneficiary () {
      if (this.$refs.beneficiaryForm.validate() === false) return false

      const addBeneficiaryRequest = await this.$store.dispatch('nonex/cebuanaAddBeneficiary', {
        beneficiary: this.beneficiaryData,
        currUser: this.currUser,
        clientID: this.transactionData.clientID
      })

      if (addBeneficiaryRequest.message === 'Successful') {
        this.getBeneficiary()

        this.selectReceiver({
          first_name: this.beneficiaryData.first_name,
          middle_name: this.beneficiaryData.middle_name,
          last_name: this.beneficiaryData.last_name,
          birth_date: this.beneficiaryData.birth_date,
          beneficiary_id: addBeneficiaryRequest.result.BeneficiaryID
        })

        this.beneficiaryData = {
          first_name: '',
          middle_name: '',
          last_name: '',
          birth_date: ''
        }

        this.$refs.beneficiaryForm.reset()
        this.beneficiaryDialog = false
      } else {
        // Handle error here
      }
    },
    selectReceiver (data) {
      [
        {
          prop: 'receiverFirstName',
          value: toProperCase(data.first_name)
        },
        {
          prop: 'receiverLastName',
          value: toProperCase(data.last_name)
        }
      ].map(payload => this.$store.commit('send/SET_TRANSACTION_DATA', payload))

      if (this.transactionData.remcoID === 9) {
        this.$store.commit('send/SET_TRANSACTION_DATA', {
          prop: 'beneficiaryID',
          value: data.beneficiary_id
        })
      }
    },
    async getBeneficiary () {
      const viewBeneficiaryRequest = await this.$store.dispatch('nonex/cebuanaViewBeneficiary', this.transactionData.clientID)

      if (viewBeneficiaryRequest.message === 'Successful') {
        if (JSON.stringify(viewBeneficiaryRequest.result) === '{}') {
          this.$store.commit('send/SET_RECEIVER_LIST', [])
        } else {
          if (viewBeneficiaryRequest.result.Beneficiary.length === undefined) {
            const { FirstName, LastName, BeneficiaryID } = viewBeneficiaryRequest.result.Beneficiary
            this.$store.commit('send/SET_RECEIVER_LIST', [{
              country_code: 'PH',
              first_name: FirstName,
              last_name: LastName,
              beneficiary_id: BeneficiaryID
            }])
          } else {
            const mapList = viewBeneficiaryRequest.result.Beneficiary.map(item => {
              return {
                country_code: 'PH',
                first_name: item.FirstName,
                last_name: item.LastName,
                beneficiary_id: item.BeneficiaryID
              }
            })
            this.$store.commit('send/SET_RECEIVER_LIST', mapList)
          }
        }
      }
    },
    syncDate (val) {
      if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(val)) {
        this.tempDate = val
      }
    },
    prev () {
      this.$store.commit('send/SET_CURRENT_STEP', 2)
    },
    convertToProperCase (field) {
      this.transactionData[field] = toProperCase(trim(this.transactionData[field]))
    },
    avatarColor,
    avatarInitial,
    toProperCase
  },
  async mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Receiver Details')

    if (this.transactionData.remcoID === 9) this.getBeneficiary()
  }
}
</script>
