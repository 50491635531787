import api from '@/api'

export default {
  gridWU (locationID, trxDate) {
    return api.get(`/v1/transactions/api/trx/grid-wu?location_id=${locationID}&trx_date=${trxDate}`)
  },
  saveWUPending (payload) {
    return api.post('/v1/transactions/api/trx/save-wu-pending', payload)
  },
  saveWU (payload) {
    return api.post('/v1/transactions/api/trx/save-wu', payload)
  },
  deleteWU (id) {
    return api.delete(`/v1/transactions/api/trx/wupending/${id}`)
  }
}
