<template>
  <v-row class="ph-wu-success-page-container">
    <v-col cols="12">
      <h1 class="ph-wu-form-title">Success!</h1>
      <p class="ph-wu-form-subtitle">Thank you. {{this.$route.name === 'PeraCashIn' ? 'Cash-In' : 'Cash-Out'}} transaction has been completed.</p>
    </v-col>
    <v-col cols="12" md="8">
      <p class="ph-wu-success-receiver-name"><b>Summary Overview:</b></p>
      <v-row class="ph-wu-success-details">
        <v-col cols="12" sm="4">
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in cicoSummaryOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        elevation="0"
        class="ph-primary-button ph-primary-execute-btn"
        @click="backToCICOPage"
      >
        Back to CICO
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { formatMTCN, renderAVPSpiel, getAge } from '@/utils'
import numeral from 'numeral'

export default {
  name: 'CICOSuccess',
  data () {
    return {
      receiverName: '',
      loyaltyCardNumber: '',
      loyaltyPoints: '',
      mtcn: '',
      crossSellingData: {},
      cicoSummaryOverview: [
        { name: 'Customer Name', value: '-' },
        { name: 'Wallet', value: '-' },
        { name: `Amount to ${this.$route.name === 'PeraCashIn' ? 'Cash-In' : 'Cash-Out'}`, value: '-' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      cashInCurrentTrx: 'cico/cashInCurrentTrx',
      cashOutCurrentTrx: 'cico/cashOutCurrentTrx',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    if (this.selectedCustomer) {
      this.receiverName = `${this.selectedCustomer.first_name} ${this.selectedCustomer.last_name}`
      let currentTrx = {}

      if (this.$route.name === 'PeraCashIn') {
        currentTrx = this.cashInCurrentTrx.trx
      } else {
        currentTrx = this.cashOutCurrentTrx.trx
      }

      const payload = {
        product: 'cash-in',
        amount: currentTrx.principal_amount,
        gender: this.selectedCustomer.gender,
        age: this.getAge(this.selectedCustomer.birth_date),
        type: this.selectedCustomer.civil_status
      }

      await this.$store.dispatch('getCrossSellingSpiel', payload).then(res => {
        this.crossSellingData = res.result[0]
        this.renderAVPSpiel(this.crossSellingData)
      }).catch(err => {
        console.log(err)
      })

      this.cicoSummaryOverview = [
        { name: 'Customer Name', value: this.receiverName },
        { name: 'Wallet', value: currentTrx?.reference_number ? currentTrx.reference_number : '-' },
        { name: `Amount to ${this.$route.name === 'PeraCashIn' ? 'Cash-In' : 'Cash-Out'}`, value: currentTrx?.principal_amount ? `PHP ${this.formatAmount(currentTrx.principal_amount)}` : '-' }
      ]
    }
  },
  methods: {
    formatMTCN,
    renderAVPSpiel,
    getAge,
    backToCICOPage () {
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
      this.$store.commit('cico/SET_CASHIN_CURRENT_TRX', null)
      this.$store.commit('cico/SET_CASHOUT_CURRENT_TRX', null)
      window.location.href = '/pera-cico'
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    }
  }
}
</script>
<style scoped>
  .ph-wu-form-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-wu-success-detail {
    border: 1px solid #CCC !important;
    border-radius: 10px !important;
    display: flex;
    padding: 10px 18px;
  }

  .ph-wu-success-detail-text {
    margin-left: 10px;
    margin-top: 4px;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    font-size: 14px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  @media only screen and (min-width: 600px) {
    .ph-primary-button {
      width: 250px;
      margin-left: auto;
    }
  }
</style>
