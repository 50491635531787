export default {
  currentTrx: state => state.currentTrx,
  currentSendFulfillTrx: state => state.currentSendFulfillTrx,
  currentReceiveFulfillTrx: state => state.currentReceiveFulfillTrx,
  payoutCurrencyType: state => state.payoutCurrencyType,
  payoutFulfillCurrencyType: state => state.payoutFulfillCurrencyType,
  payoutFormData: state => state.payoutFormData,
  payoutForeignRefNo: state => state.payoutForeignRefNo,
  sendoutReleaseForeignRefNo: state => state.sendoutReleaseForeignRefNo,
  payoutReleaseForeignRefNo: state => state.payoutReleaseForeignRefNo,
  payoutSuccessData: state => state.payoutSuccessData,
  showPayoutSearchForm: state => state.showPayoutSearchForm,
  showPayoutResultForm: state => state.showPayoutResultForm,
  showSuccessPayoutScreen: state => state.showSuccessPayoutScreen,
  showPayoutFulfillSearchForm: state => state.showPayoutFulfillSearchForm,
  showPayoutFulfillResultForm: state => state.showPayoutFulfillResultForm,
  showSuccessPayoutFulfillScreen: state => state.showSuccessPayoutFulfillScreen,
  payoutFulfillSuccessData: state => state.payoutFulfillSuccessData,
  showSendoutFulfillSearchForm: state => state.showSendoutFulfillSearchForm,
  showSendoutFulfillResultForm: state => state.showSendoutFulfillResultForm,
  showSuccessSendoutFulfillScreen: state => state.showSuccessSendoutFulfillScreen,
  sendoutFulfillSuccessData: state => state.sendoutFulfillSuccessData,
  wuKYCMultipleSearchData: state => state.wuKYCMultipleSearchData,
  wuKYCSingleSearchData: state => state.wuKYCSingleSearchData,
  quickPayCurrentPage: state => state.quickPayCurrentPage,
  qpForeignRefNo: state => state.qpForeignRefNo,
  qpValidateData: state => state.qpValidateData,
  qpSuccessData: state => state.qpSuccessData,
  mcRateData: state => state.mcRateData
}
