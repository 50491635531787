<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12">
      <v-col :cols="12" :md="3">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="d-flex align-center" :cols="12" :md="9">
        <v-btn
          @click="cancel"
          class="text-capitalize font-weight-bold"
          color="grey darken-2"
          rounded
          large
          text
        >
          Cancel
        </v-btn>
        <v-btn
          @click="save"
          class="text-capitalize font-weight-bold"
          color="primary"
          rounded
          large
          text
        >
          <v-icon class="mr-1">mdi-check</v-icon>
          Save Changes
        </v-btn>
      </v-col>
      <v-col :cols="12">
        <v-card flat>
          <v-data-table
            :headers="headers"
            :items="uploadedData"
            :search="search"
            class="custom-table"
            calculate-widths
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { toMoney, renderToast } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      fileName: 'compliance/uploadedFileName',
      uploadedData: 'compliance/uploadedData'
    })
  },
  data () {
    return {
      search: '',
      headers: [
        {
          text: 'First Name',
          value: 'first_name',
          width: '150px'
        },
        {
          text: 'Last Name',
          value: 'last_name',
          width: '150px'
        },
        {
          text: 'Category',
          value: 'type',
          width: '150px'
        },
        {
          text: 'Address',
          value: 'pep_categ',
          width: '150px'
        },
        {
          text: 'Source',
          value: 'source',
          width: '150px'
        },
        {
          text: 'Date Published',
          value: 'date_published',
          width: '150px'
        },
        {
          text: 'Remarks',
          value: 'tag',
          width: '150px'
        },
        {
          text: 'Alias',
          value: 'other_info',
          width: '150px'
        }
      ],
      breadcrumbsItems: [
        {
          text: 'Compliance Module',
          disabled: false,
          exact: true,
          to: '/compliance'
        },
        {
          text: 'Adverse',
          disabled: false,
          exact: true,
          to: '/compliance/adverse'
        },
        {
          text: 'Upload Preview',
          disabled: true
        }
      ]
    }
  },
  methods: {
    async save () {
      const payload = this.uploadedData.map(item => {
        return {
          ...item,
          updated_by: this.currUser.user_id
        }
      })

      const { code } = await this.$store.dispatch('compliance/addAdverseBulk', payload)

      if (code === 200) {
        renderToast('success', 'Successful', 'Uploaded data has been added')
        this.cancel()
      } else {
        renderToast('error', 'Failed', 'Failed to save uploaded file')
      }
    },
    cancel () {
      this.$store.commit('compliance/SET_UPLOADED_FILE_NAME', '')
      this.$store.commit('compliance/SET_UPLOADED_DATA', [])
      this.$router.push('/compliance/adverse')
    },
    toMoney
  },
  beforeMount () {
    if (this.uploadedData.length === 0) {
      renderToast('error', 'No file uploaded', 'Please upload a file first')
      this.$router.push('/compliance/adverse')
    }
  }
}
</script>
