export default {
  transactions: [],
  inquireResult: [],
  remcos: [],
  currentTrx: {},
  cebuanaClientData: {
    client_id: null
  },
  sendoutConfirmDialog: false,
  sendoutOTPDialog: false,
  sendoutServiceFees: [],
  sendoutReceiverDetails: {
    first_name: '',
    middle_name: '',
    last_name: '',
    mobile_no: '',
    account_no: '',
    street: '',
    barangay: '',
    city: '',
    province: '',
    country: '',
    zip_code: ''
  },
  sendoutCompleted: {
    senderDetails: false,
    transactionDetails: false
  },
  sendoutFormData: {
    location_id: '',
    user_id: '',
    location_name: '',
    trx_date: '',
    customer_id: '',
    currency_id: '1',
    remco_id: '',
    trx_type: '1',
    is_domestic: '1',
    customer_name: '',
    service_charge: '',
    remote_location_id: '',
    remote_ip_address: '',
    ip_address: '',
    dst_amount: '0',
    total_amount: '',
    remote_user_id: '',
    originating_country: 'PH',
    destination_country: 'PH',
    purpose_transaction: '',
    source_fund: '',
    occupation: '',
    relation_to: '',
    birth_date: '',
    birth_place: '',
    birth_country: '',
    id_type: '',
    id_number: '',
    address: '',
    barangay: '',
    city: '',
    province: '',
    zip_code: '',
    country: '',
    contact_number: '',
    current_address: {
      address: '',
      barangay: '',
      city: '',
      province: '',
      zip_code: '',
      country: ''
    },
    permanent_address: {
      address: '',
      barangay: '',
      city: '',
      province: '',
      zip_code: '',
      country: ''
    },
    risk_score: '1',
    risk_criteria: '{}',
    form_type: 'OAR',
    form_number: '',
    payout_type: '1',
    sender_name: '',
    receiver_name: '',
    principal_amount: '',
    control_number: '',
    client_reference_no: '',
    reference_number: ''
  },
  payoutFormData: {
    location_id: '',
    user_id: '',
    trx_date: '',
    currency: '',
    currency_id: '',
    remco_id: '',
    trx_type: '',
    is_domestic: '',
    customer_id: '',
    control_number: '',
    client_reference_no: '',
    customer_name: '',
    sender_name: '',
    receiver_name: '',
    principal_amount: '',
    service_charge: '',
    dst_amount: '',
    total_amount: '',
    mc_rate: '',
    buy_back_amount: '',
    rate_category: '',
    mc_rate_id: '',
    originating_country: '',
    destination_country: 'PH',
    purpose_transaction: '',
    source_fund: '',
    occupation: '',
    relation_to: '',
    birth_place: '',
    birth_country: '',
    id_type: '',
    id_number: '',
    id_issued_by: '',
    id_date_of_issue: '',
    id_expiration_date: '',
    address: '',
    barangay: '',
    city: '',
    province: '',
    zip_code: '',
    country: '',
    contact_number: '',
    form_type: '',
    form_number: '',
    payout_type: 1,
    remote_location_id: '',
    remote_user_id: '',
    remote_ip_address: '',
    ip_address: '',
    reference_number: '',
    current_address: {
      address_1: '',
      address_2: '',
      barangay: '',
      city: '',
      province: '',
      zip_code: '',
      country: ''
    },
    permanent_address: {
      address_1: '',
      address_2: '',
      barangay: '',
      city: '',
      province: '',
      zip_code: '',
      country: ''
    }
  },
  payoutReferenceNo: '',
  isGCashTrx: false,
  cebuanaGenderList: [], // Declare the cebuanaGenderList as array
  cebuanaPurposeList: [], // Declare the cebuanaPurposeList as array
  cebuanaRelationList: [], // Declare the cebuanaRelationList as array
  cebuanaOccupationList: [] // Declare the cebuanaOccupationList as array
}
