<template>
  <v-container fluid class="ph-nonex-container">
    <div v-if="isCloseListRequired">
      <v-col :cols="12" class="pl-0 pr-0">
        <h3 class="custom-heading pb-4 pt-2 pb-md-6 primary--text font-weight-black">
          Below are the checklist you need to complete every End of Day Closing
        </h3>
      </v-col>
      <v-data-table no-data-text="No actions needed so far" :headers="headers" :items="closeList" class="ph-nonex-datatable">
        <template v-slot:[`item.full_name`]="{item}">
          <div class="ph-fla-name-container">
            <v-avatar class="mr-1 ph-fla-status-circle" size="25px" :style="avatarColor(item.full_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
            </v-avatar>
            <p class="ph-fla-name-text">{{item.full_name}}</p>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <!-- Variance Explanation -->
          <v-btn
            class="ph-button-checklist"
            text
            elevation="0"
            color="primary"
            @click="openVarianceDialog(item, item.HitLabel.split('REASON :')[1])"
            v-if="item.HitLabel.toLowerCase().includes('variance explanation')"
          >
            {{ item.HitLabel.toLowerCase().includes('reason') ? 'Click here to Edit Variance Explanation; Reason previously provided' : 'Click here to Add Variance Explanation' }}
          </v-btn>
          <!-- Cash Count -->
          <v-btn
            class="ph-button-checklist"
            text
            elevation="0"
            color="primary"
            v-if="item.HitLabel.toLowerCase().includes('cashcount')"
            @click="redirectToCashCount"
          >
            {{ item.HitLabel.toLowerCase().includes('add cashcount') ? 'Click here to Add Cash Count' : 'Click here to Verify Cash Count' }}
          </v-btn>
          <!-- Excess Fund -->
          <v-btn
            class="ph-button-checklist"
            text
            elevation="0"
            color="primary"
            v-if="item.HitLabel.toLowerCase().includes('excess fund')"
            @click="openFormDialog(item)"
          >
            Click here to request to use excess funds
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog
        v-model="excessDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Excess Funds Request
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            Are you going to DEPOSIT Excess Funds today?
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="openFormDialog(depositFundPayload)"
              class="ph-action-button"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="depositFunds"
              class="ph-action-button"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="requestFormDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="50%">
        <v-card class="rounded-lg">
          <v-card-title class="py-md-6">
            <h3 class="custom-heading primary--text">
              Request Form
            </h3>
            <v-spacer />
            <v-btn @click="closeFormDialog" small icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form ref="excessRequestForm">
              <v-row class="mb-md-8">
                <v-col :cols="12" :md="12">
                  <v-select
                    v-model="excessReasonData.currency"
                    :items="currencyList"
                    class="ph-textbox"
                    label="Currency *"
                    :rules="requiredRules"
                    outlined
                    item-text="text"
                    item-value="value"
                  />
                </v-col>
                <v-col :cols="12" :md="12">
                  <v-text-field
                    v-model="excessReasonData.amount"
                    class="ph-textbox"
                    label="Amount *"
                    :rules="requiredRules"
                    outlined
                  />
                </v-col>
                <v-col :cols="12" :md="12">
                  <v-select
                    v-model="excessReasonData.reason"
                    :items="excessFundReasonList"
                    class="ph-textbox"
                    label="Reason *"
                    :rules="requiredRules"
                    outlined
                    item-text="reasons"
                    item-value="reasons"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="closeFormDialog"
              class="ph-action-button"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="sendRequest"
              class="ph-action-button"
            >
              Send Request
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="varianceExplainDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="50%">
        <v-card class="rounded-lg">
          <v-card-title class="py-md-6">
            <h3 class="custom-heading primary--text">
              Add Variance Explanation
            </h3>
            <v-spacer />
            <v-btn @click="varianceExplainDialog = false" small icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form ref="varianceExplainForm">
              <v-row class="mb-md-8">
                <v-col :cols="12" :md="12">
                  <v-textarea
                    v-model="varianceExplanation"
                    label="Reason for amount variance *"
                    :rules="requiredRules"
                    rows="10"
                    filled
                    outlined
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="varianceExplainDialog = false"
              class="ph-action-button"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="sendExplanation"
              class="ph-action-button"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import { renderToast, avatarColor, avatarInitial } from '@/utils'
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import numeral from 'numeral'
export default {
  name: 'CloseBranchComponent',
  computed: {
    ...mapGetters({
      closeList: 'closeBranch/closeList',
      excessFundReasonList: 'closeBranch/excessFundReasonList',
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      headers: [
        {
          text: 'Item/things you need to check',
          sortable: true,
          value: 'TaskName'
        },
        {
          text: 'FLA/Teller',
          sortable: true,
          value: 'full_name'
        },
        {
          text: 'Remarks/Reasons why cannot close transaction date',
          sortable: true,
          value: 'HitRemarks'
        },
        {
          text: 'Actions you need to do',
          sortable: true,
          value: 'action'
        }
      ],
      sampleData: [],
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      currencyList: [
        { text: 'Philippine Peso - PHP', value: '1' },
        { text: 'US Dollar - USD', value: '2' }
      ],
      excessDialog: false,
      requestFormDialog: false,
      varianceExplainDialog: false,
      selectedActionItem: {},
      excessReasonData: {
        ref_no: '',
        currency: '',
        amount: '',
        reason: ''
      },
      varianceExplanation: '',
      depositFundPayload: {},
      isCloseListRequired: false
    }
  },
  beforeMount () {
    this.checkIfCloseListRequired(this.currUser.user_group_id)
  },
  async mounted () {
    await this.runChecklist()
  },
  methods: {
    renderToast,
    avatarColor,
    avatarInitial,
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    async depositFunds () {
      await this.$store.dispatch('closeBranch/depositExcessFunds', this.depositFundPayload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.renderToast('success', 'Success', 'Deposited successfully')
        } else {
          this.renderToast('error', 'Error', res.message)
        }
        this.excessDialog = false
      }).catch(err => {
        console.log(err)
      })
    },
    openVarianceDialog (item, explanation) {
      this.varianceExplainDialog = true
      this.selectedActionItem = item
      this.varianceExplanation = explanation
    },
    async sendExplanation () {
      const payload = {
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        currency_id: this.selectedActionItem.currency_id,
        variance_remarks: this.varianceExplanation
      }

      if (this.$refs.varianceExplainForm.validate()) {
        await this.$store.dispatch('closeBranch/waiveVariance', payload).then(res => {
          if (res.code >= 200 && res.code <= 299) {
            this.renderToast('success', 'Explanation Submitted', 'Your variance explanation has been successfully submitted')
            this.varianceExplainDialog = false
            this.runChecklist()
          }
        })
      } else {
        this.renderToast('error', 'Validation Error', 'You must add an explanation')
      }
    },
    async sendRequest () {
      const currentDate = format(new Date(), 'yyyyMMddHHmmss')
      const payload = {
        reference_number: `${this.currUser.location_id}${currentDate}`,
        amount: this.excessReasonData.amount,
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id,
        remarks: this.excessReasonData.reason,
        encoded_by: this.currUser.user_id,
        currency_id: this.excessReasonData.currency
      }

      console.log(payload)

      if (this.$refs.excessRequestForm.validate()) {
        await this.$store.dispatch('closeBranch/excessFundRequest', payload).then(res => {
          console.log(res)
          if (res.code >= 200 && res.code <= 299) {
            this.renderToast('success', 'Request Form Submitted', 'Your request has been successfully submitted')
            this.requestFormDialog = false
            this.runChecklist()
          }
        })
      } else {
        this.renderToast('error', 'Validation Error', 'You must complete all the fields')
      }
    },
    redirectToCashCount () {
      this.$router.push('/cash-count')
    },
    openFormDialog (item) {
      let splitRemarks = ''
      let excessAmount = ''

      if (item?.HitRemarks) {
        if (item.HitRemarks.toLowerCase().includes('pesos')) {
          splitRemarks = item.HitRemarks.toLowerCase().split('pesos')
          excessAmount = splitRemarks[0]
          this.excessReasonData.amount = excessAmount.replace(/\s+/g, '')
        } else if (item.HitRemarks.toLowerCase().includes('us dollars')) {
          splitRemarks = item.HitRemarks.toLowerCase().split('us dollars')
          excessAmount = splitRemarks[0]
          this.excessReasonData.amount = excessAmount.replace(/\s+/g, '')
        }
      }

      this.requestFormDialog = true
      this.excessReasonData.currency = item.currency_id.toString()
      this.excessDialog = false
    },
    closeFormDialog () {
      this.excessReasonData = {}
      this.requestFormDialog = false
      this.excessDialog = false
    },
    checkIfCloseListRequired (groupId) {
      console.log(groupId)
      if (groupId === 5 || groupId === 6 || groupId === 16 || groupId === 17 || groupId === 18 || groupId === 20 || groupId === 21 || groupId === 22 ||
      groupId === 44 || groupId === 45 || groupId === 46 || groupId === 47 || groupId === 48 || groupId === 49 || groupId === 50 || groupId === 42 ||
      groupId === 52 || groupId === 34 || groupId === 54 || groupId === 55 || groupId === 56 || groupId === 57 || groupId === 58 || groupId === 59 ||
      groupId === 60 || groupId === 61 || groupId === 62 || groupId === 63 || groupId === 64 || groupId === 79) {
        this.isCloseListRequired = true
      } else {
        this.isCloseListRequired = false
      }
    },
    async runChecklist () {
      if (this.isCloseListRequired) {
        await this.$store.dispatch('closeBranch/getCloseList', {
          location_id: this.currUser.location_id,
          trx_date: this.currUser.trx_date,
          user_id: this.currUser.user_id,
          user_group: this.currUser.user_group_id
        })

        await this.$store.dispatch('closeBranch/getExcessFundReasonList')

        await this.$store.dispatch('closeBranch/checkExcessFund', {
          location_id: this.currUser.location_id,
          trx_date: this.currUser.trx_date
        }).then(res => {
          if (res.code >= 200 && res.code <= 299) {
            const pesoHitCount = res.result.Peso.filter(data => data.HitCount > 0).length
            const dollarHitCount = res.result.Dollar.filter(data => data.HitCount > 0).length
            let payload = {}

            if (pesoHitCount > 0) {
              payload = {
                trx_date: this.currUser.trx_date,
                location_id: this.currUser.location_id,
                currency_id: 1
              }
              this.excessDialog = true
            } else if (dollarHitCount > 0) {
              payload = {
                trx_date: this.currUser.trx_date,
                location_id: this.currUser.location_id,
                currency_id: 2
              }
              this.excessDialog = true
            } else {
              this.excessDialog = false
            }

            this.depositFundPayload = payload
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.ph-nonex-container {
  padding-left: 0;
  padding-right: 0;
}

p {
  font-family: 'Baloo Extra Bold';
  font-size: 20px;
  font-weight: bolder;
  color: #1A2791;
}

.ph-nonex-button-icon-container {
  padding: 15px;
  width: 100%;
  display: flex;
}

.ph-nonex-button-icon {
  margin-left: 20px;
  margin-top: 20px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  border-radius: 4px;
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-fla-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-fla-name-container {
  display: flex;
}

.ph-fla-name-text {
  margin: auto;
  margin-left: 10px;
  font-family: 'Proxima Nova', sans-serif !important;
  color: #000000;
  font-size: 0.875rem;
  font-weight: normal;
}

.ph-button-checklist {
  text-transform: none;
  letter-spacing: normal;
}
</style>
