const environment = 'prod' // 'prod' or 'dev'

const configurations = {
  prod: {
    baseURL: process.env.VUE_APP_API_PROD_URL,
    token: process.env.VUE_APP_API_PROD_TOKEN,
    consolidatorURL: process.env.VUE_APP_CONSOLIDATOR_API_PROD_URL,
    consolidatorToken: process.env.VUE_APP_CONSOLIDATOR_PROD_TOKEN,
    gcpBaseURL: 'U2FsdGVkX186VFj/D5q9RKkgrVaQ+HDal5JHWcgKKhJCuRac/H0ZUISY7zpDrQIU7jawxDW4bZSM22r9HDY1sPiHI8Ay2Z9VO167XMzNNJg=',
    uspMaintenanceURL: 'U2FsdGVkX197OPN2eGsJB+qBv6PjKFpDMGGiwigYMTvmu5jrKFuhLzl3v2Owq9dCdlLJ+EdH/HfHfMzyJ4seKgAr9SVdvQdPBfiCsNVL82Y=',
    petnetAPIV2URL: process.env.VUE_APP_PETNETAPIV2_PROD_URL,
    petnetAPIV2Token: process.env.VUE_APP_PETNETAPIV2_PROD_TOKEN,
    newkycgatewayV2URL: process.env.VUE_APP_NEWKYCGATEWAYV2_PROD_URL,
    newkycgatewayV2Token: process.env.VUE_APP_NEWKYCGATEWAYV2_PROD_TOKEN,
    treasuryURL: 'https://treasuryapi.perahub.com.ph/api',
    treasuryToken: '81a38t0exyqit3wnocgy5vw7tosmy18b'
  },
  dev: {
    baseURL: process.env.VUE_APP_API_DEV_URL,
    token: process.env.VUE_APP_API_DEV_TOKEN,
    consolidatorURL: process.env.VUE_APP_CONSOLIDATOR_API_DEV_URL,
    consolidatorToken: process.env.VUE_APP_CONSOLIDATOR_DEV_TOKEN,
    gcpBaseURL: 'U2FsdGVkX186VFj/D5q9RKkgrVaQ+HDal5JHWcgKKhJCuRac/H0ZUISY7zpDrQIU7jawxDW4bZSM22r9HDY1sPiHI8Ay2Z9VO167XMzNNJg=',
    uspMaintenanceURL: 'U2FsdGVkX19Jq3ASkb7Mtk47PsHqD2VYvzSbDMluA1WjaTi9P2eGPNA5GAA4lU6euhEJWuI+cfqpvmSyH3xkVrAiw7ecihC3Elhlkl5A008=',
    petnetAPIV2URL: process.env.VUE_APP_PETNETAPIV2_DEV_URL,
    petnetAPIV2Token: process.env.VUE_APP_PETNETAPIV2_DEV_TOKEN,
    eReceiptURL: process.env.VUE_APP_ERECEIPT_DEV_URL,
    newkycgatewayV2URL: process.env.VUE_APP_NEWKYCGATEWAYV2_DEV_URL,
    newkycgatewayV2Token: process.env.VUE_APP_NEWKYCGATEWAYV2_DEV_TOKEN,
    treasuryURL: 'https://treasuryapi.dev.perahub.com.ph/api',
    treasuryToken: '81a38t0exyqit3wnocgy5vw7tosmy18b'
  }
}

export const env = {
  get: name => configurations[environment][name]
}
