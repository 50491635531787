<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mt-md-4 mx-md-5" no-gutters>
      <v-col class="pb-md-5" :cols="12">
        <strong>Sender/Receiver Details</strong>
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="selectedEntry.wu_sender_name"
          class="ph-textbox mr-md-2"
          label="Sender Name"
          readonly
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="selectedEntry.wu_payee_name"
          class="ph-textbox mx-md-2"
          label="Receiver Name"
          readonly
          outlined
        />
      </v-col>
      <v-col class="pt-md-8 pb-md-5" :cols="12">
        <strong>Transaction Details</strong>
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="selectedEntry.wu_mtcn"
          class="ph-textbox mr-md-2"
          label="MTCN"
          readonly
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="selectedEntry.wu_trx_date"
          class="ph-textbox mr-md-2"
          label="Date Transacted"
          readonly
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="selectedEntry.wu_originating_country"
          class="ph-textbox ml-md-2"
          label="Country"
          readonly
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="selectedEntry.wu_country_currency_paid"
          class="ph-textbox mr-md-2 mt-md-8"
          label="Currency"
          readonly
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="selectedEntry.wu_total_charge"
          class="ph-textbox mx-md-2 mt-md-8"
          label="Service Charge"
          readonly
          outlined
        />
      </v-col>
      <v-col :cols="12" :md="4">
        <v-text-field
          v-model="selectedEntry.principal_amount"
          class="ph-textbox ml-md-2 mt-md-8"
          label="Principal Amount"
          readonly
          outlined
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between px-md-5 py-md-10">
      <v-btn @click="cancel" class="rounded-lg" color="primary" outlined x-large>
        Cancel
      </v-btn>
      <v-btn @click="next" class="rounded-lg" color="primary" x-large>
        Find Customer
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { toProperCase, trim } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      selectedEntry: 'manual/selectedEntry'
    })
  },
  data () {
    return {
      breadcrumbsItems: [
        {
          text: 'PERA Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Manual Entry',
          disabled: false,
          href: '/pera-remit/manual-entry'
        },
        {
          text: 'Update Manual Send Entry',
          disabled: true
        }
      ]
    }
  },
  methods: {
    cancel () {
      this.$router.push({
        path: '/pera-remit/manual-remit'
      })
    },
    next () {
      this.$store.commit('manual/SET_CURRENT_PAGE', 1)
    },
    convertToProperCase (field) {
      this.formData[field] = toProperCase(trim(this.formData[field]))
    }
  },
  mounted () {
    this.breadcrumbsItems[2].text = this.selectedEntry.wu_mtcn

    if (this.selectedEntry.wu_tran_type === 'SO') {
      this.$store.commit('SET_PAGE_TITLE', 'Update Manual Send Entry')
    }

    if (this.selectedEntry.wu_tran_type === 'PO') {
      this.$store.commit('SET_PAGE_TITLE', 'Update Manual Receive Entry')
    }
  }
}
</script>
