export default {
  billsPaymentTransactions: state => state.billsPaymentTransactions,
  billsPaymentCurrentTrx: state => state.billsPaymentCurrentTrx,
  billsPaymentCurrentPage: state => state.billsPaymentCurrentPage,
  billers: state => state.billers,
  billsPaymentGetMisc: state => state.billsPaymentGetMisc,
  bayadReceiptData: state => state.bayadReceiptData,
  tpaId: state => state.tpaId,
  billsCollectionTransactions: state => state.billsCollectionTransactions,
  billsPaymentResponseData: state => state.billsPaymentResponseData,
  billsCollectionCurrentPage: state => state.billsCollectionCurrentPage,
  collectionProviders: state => state.collectionProviders,
  billsCollectionResponseData: state => state.billsCollectionResponseData,
  billsCollectionCurrentTrx: state => state.billsCollectionCurrentTrx,
  billsCollectionVoucherData: state => state.billsCollectionVoucherData,
  billsLoanAccountNo: state => state.billsLoanAccountNo
}
