<template>
  <v-form ref="form">
    <v-row class="px-8 py-8" no-gutters>
      <v-col class="py-4 px-2" :cols="12">
        <h3>Identification Cards</h3>
      </v-col>
      <v-col :cols="12">
        <v-btn @click="addFile" color="primary" class="rounded-lg">
          Upload
        </v-btn>
        <form ref="idFileForm">
          <input ref="idFile" @change="addID" type="file" class="d-none" accept="image/jpeg, image/png">
        </form>
      </v-col>
      <v-col class="pt-4 pt-sm-6 pt-md-12" :cols="12">
        <v-row no-gutters v-if="ids.length > 0">
          <v-col
            :cols="12"
            :sm="6"
            :md="4"
            class="px-sm-2"
            v-for="(value, key) in ids"
            :key="key"
          >
              <h3 class="mb-2">ID #{{ key + 1 }}</h3>
              <v-row no-gutters>
                <v-col style="height: 200px !important" class="pb-2 pb-sm-4 text-center" :cols="12">
                  <img :src="preview[key]" alt="" v-if="preview[key]">
                  <img :src="getStoragePath(value)" alt="" v-else>
                </v-col>
                <v-col class="pt-4" :cols="12">
                  <v-autocomplete
                    v-model="ids[key].id_type"
                    @change="syncInput"
                    label="ID Type *"
                    :items="collections.idList"
                    item-text="id_type"
                    item-value="id_value"
                    :return-object="false"
                    :rules="requiredField"
                    color="gray darken-1"
                    class="ph-textbox"
                    dense
                    outlined
                  />
                </v-col>
                <v-col class="pt-7" :cols="12">
                  <v-autocomplete
                    v-model="ids[key].country"
                    @change="syncInput"
                    label="Issuing Country *"
                    :items="collections.countries"
                    item-text="country"
                    item-value="country"
                    :return-object="false"
                    :rules="requiredField"
                    color="gray darken-1"
                    class="ph-textbox"
                    dense
                    outlined
                  />
                </v-col>
                <v-col class="pt-7" :cols="12">
                  <v-text-field
                    v-model="ids[key].id_number"
                    @change="syncInput"
                    @input="convertToUpperCase(key, ids[key].id_number)"
                    class="ph-textbox"
                    color="gray darken-1"
                    label="ID Number *"
                    :rules="idNumberValidator(ids[key].id_number, ids[key].id_type, ids[key].country)"
                    dense
                    outlined
                  />
                </v-col>
                <v-col class="pt-7" :cols="12">
                  <v-menu
                    v-model="expiryDatePicker[key]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="ids[key].expiry"
                        @input="syncInput"
                        class="ph-textbox"
                        color="gray darken-1"
                        label="ID Expiry Date *"
                        :rules="dateRules"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                      />
                    </template>
                    <v-date-picker
                      v-model="ids[key].expiry"
                      @input="syncExpiryDate(key)"
                    />
                  </v-menu>
                </v-col>
                <v-col class="pt-7" :cols="12">
                  <v-menu
                    v-model="issueDatePicker[key]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="ids[key].issue"
                        @input="syncInput"
                        class="ph-textbox"
                        color="gray darken-1"
                        label="ID Issued Date *"
                        :rules="dateRules"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                      />
                    </template>
                    <v-date-picker
                      v-model="ids[key].issue"
                      @input="syncIssueDate(key)"
                    />
                  </v-menu>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters v-else>
          <v-col :cols="12">
            <span class="caption">
              No available ID
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { getDataURL, aesDecrypt } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      formData: 'customers/formData',
      collections: 'collections'
    })
  },
  watch: {
    formData () {
      this.syncID()
    }
  },
  data () {
    return {
      ids: [],
      preview: [],
      issueDatePicker: [],
      expiryDatePicker: [],
      requiredField: [
        v => !!v || 'This field is required'
      ],
      dateRules: [
        v => (!v || (!!v && /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v))) || 'Use YYYY-MM-DD format'
      ]
    }
  },
  methods: {
    convertToUpperCase (key, value) {
      if (value) {
        this.ids[key].id_number = value.toUpperCase()
      }
    },
    idNumberValidator (text, type, country) {
      let rules = [v => !!v || 'This field is required']

      switch (type) {
        case 'Passport':
          if (country === 'Philippines') {
            rules = [
              ...rules,
              v => /^([a-zA-Z0-9]){9,9}$/.test(text) || 'Should be 9 characters and only letters/numbers are allowed'
            ]
          } else {
            rules = [
              ...rules,
              v => /^([a-zA-Z0-9]){6,9}$/.test(text) || 'Should be 6-9 characters and only letters/numbers are allowed'
            ]
          }
          break
        case 'Gov\'t Office/GOCC ID':
        case 'OWWA ID (Overseas Workers)':
        case 'Seaman\'s Book':
        case 'DSWD Certificate':
        case 'Senior Citizen Card':
        case 'Student\'s ID':
        case 'Police Clearance':
        case 'OFW ID':
        case 'Alien Cert. of Registration':
        case 'Employment ID':
        case 'Barangay Certification':
        case 'AFP ( Armed Forces of the Philippines ID)':
        case 'PRPWD (Philippine Registry for Persons with Disabilities)':
          rules = [
            ...rules,
            v => /^[a-zA-Z0-9]*$/.test(text) || 'Only letters/numbers are allowed'
          ]
          break
        case 'Driver\'s license':
          rules = [
            ...rules,
            v => /^([a-zA-Z0-9]){11,11}$/.test(text) || 'Should be 11 characters and only letters/numbers are allowed'
          ]
          break
        case 'Postal ID':
          rules = [
            ...rules,
            v => /^([a-zA-Z0-9]){12,12}$/.test(text) || 'Should be 12 characters and only letters/numbers are allowed'
          ]
          break
        case 'NBI Clearance':
          rules = [
            ...rules,
            v => /^([a-zA-Z0-9]){18,20}$/.test(text) || 'Should be 18-20 characters and only letters/numbers are allowed'
          ]
          break
        case 'Voter\'s ID':
          rules = [
            ...rules,
            v => /^([a-zA-Z0-9]){16,25}$/.test(text) || 'Should be 16-25 characters and only letters/numbers are allowed'
          ]
          break
        case 'GSIS e-Card':
          rules = [
            ...rules,
            v => /^([0-9]){11,11}$/.test(text) || 'Should be 11 characters and only numbers are allowed'
          ]
          break
        case 'Disabled Person Cert/ID NCWDP':
        case 'Unified MultiPurpose ID (UMID)':
        case 'PAG IBIG ID': // not on kycid
        case 'PhilHealth': // not on kycid
        case 'National Council for the Welfare': // not on kycid
          rules = [
            ...rules,
            v => /^([0-9]){12,12}$/.test(text) || 'Should be 12 characters and only numbers are allowed'
          ]
          break
        case 'PRC ID (Prof. Reg. Commission)':
          rules = [
            ...rules,
            v => /^([0-9]){7,7}$/.test(text) || 'Should be 7 characters and only numbers are allowed'
          ]
          break
        case 'Integrated Bar (IBP) ID':
          rules = [
            ...rules,
            v => /^([0-9]){5,5}$/.test(text) || 'Should be 5 characters and only numbers are allowed'
          ]
          break
        case 'SSS Card (Social Security)':
          rules = [
            ...rules,
            v => /^(01|02|03|04|05|06|07|08|09|10|33|34)([0-9]){8,8}$/.test(text) || 'Should begin with 01, 02, 03, 04, 05, 06, 07, 08, 09, 10, 33 or 34, must be 10 characters and only numbers are allowed'
          ]
          break
        case 'Taxpayer\'s ID (TIN ID)':
          rules = [
            ...rules,
            v => /^(([0-9]){12,12}|([0-9]){9,9})$/.test(text) || 'Should be 9 or 12 characters and only numbers are allowed'
          ]
          break
        case 'PhilSys (Philippine Identification System ID)':
          rules = [
            ...rules,
            v => /^([0-9]){16,16}$/.test(text) || 'Should be 16 characters and only numbers are allowed'
          ]
          break
      }

      return rules
    },
    addFile () {
      this.$refs.idFileForm.reset()
      this.$refs.idFile.click()
    },
    async addID (e) {
      const files = e.target.files

      if (files.length > 0) {
        const dateToday = format(new Date(), 'yyyyMMdd')
        const randomNumber = Math.floor(Math.random() * (999 - 100 + 1)) + 100
        this.formData.img_file.reverse()
        this.formData.img_file.push(files[0])
        this.formData.img_file.reverse()

        this.expiryDatePicker.reverse()
        this.expiryDatePicker.push(false)
        this.expiryDatePicker.reverse()

        this.issueDatePicker.reverse()
        this.issueDatePicker.push(false)
        this.issueDatePicker.reverse()

        const preview = await getDataURL(files[0])
        this.preview.push(preview)

        this.ids.reverse()
        this.ids.push({
          img: `${dateToday}${randomNumber}.png`,
          id_type: '',
          id_number: '',
          country: 'Philippines',
          expiry: '',
          issue: '',
          usp2: 'true'
        })
        this.ids.reverse()

        this.formData.img = this.convertToObject(this.ids)

        this.syncID()
      }
    },
    convertToObject (ids) {
      const img = {}

      ids.forEach((value, index) => {
        img[index + 1] = value
      })

      return JSON.stringify(img)
    },
    syncInput () {
      this.formData.img = this.convertToObject(this.ids)
      this.syncID()
    },
    syncIssueDate (key) {
      this.issueDatePicker[key] = false
      this.syncInput()
    },
    syncExpiryDate (key) {
      this.expiryDatePicker[key] = false
      this.syncInput()
    },
    async syncID () {
      // if (this.$route.query.isMobileUser) {
      //   let selectedId = {}
      //   const currentId = JSON.parse(this.formData.img)[1]
      //   const currentIdType = currentId.id_type
      //   await this.collections.idList.forEach(list => {
      //     if (list.id_value.toLowerCase() === currentIdType.toLowerCase()) {
      //       selectedId = list

      //       console.log('pumasok')

      //       const customerIdObject = {
      //         1: {
      //           img: currentId.img,
      //           id_type: selectedId.id_value,
      //           id_number: currentId.id_number,
      //           country: currentId.country,
      //           expiry: currentId.expiry,
      //           issue: currentId.issue,
      //           usp2: currentId.usp2
      //         }
      //       }

      //       this.formData.img = JSON.stringify(customerIdObject)
      //     }
      //   })
      // }

      const that = this
      let img = this.formData.img || '{}'
      img = JSON.parse(img)
      this.ids = Object.values(img)
      this.ids.forEach((e) => {
        const regex = new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)
        const regexYYYYMMDD = new RegExp(/(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])/)
        if (e.issue && !regex.test(e.issue)) {
          if (regexYYYYMMDD.test(e.issue)) {
            e.issue = e.issue.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
          } else {
            e.issue = that.formatDate(e.issue)
          }
        }
        if (e.expiry && !regex.test(e.expiry)) {
          if (regexYYYYMMDD.test(e.expiry)) {
            e.expiry = e.expiry.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
          } else {
            e.expiry = that.formatDate(e.expiry)
          }
        }
      })
      this.formData.img = this.convertToObject(this.ids)
      this.$refs.form.resetValidation()
    },
    formatDate (date) {
      var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }
      return [year, month, day].join('-')
    },
    async getMobileAppId () {
      const preview = await getDataURL(this.formData.img_file[0])
      this.preview.push(preview)
    },
    getStoragePath (value) {
      if (value.usp2 === 'true' && this.$route.query.isMobileUser) {
        this.getMobileAppId()
      } else if (value.usp2 === 'true') {
        return `${aesDecrypt(process.env.VUE_APP_STORAGE_BASE_URL)}/${this.formData.customer_id}/${value.img}`
      } else {
        return `${aesDecrypt(process.env.VUE_APP_STORAGE_USP1_URL)}/${this.formData.customer_id}/${value.img}`
      }
    },
    onFileSelected (event) {
      console.log(this.generateIDName(event))
      // console.log(event)
      // console.log(Object.keys(event).length)
    },
    generateIDName (event) {
      var imgDatas = []
      const countFile = Object.keys(event).length // get files count
      const dateToday = new Date().toISOString().slice(0, 10) // get date
      const max = 999
      const min = 111
      for (let index = 1; index <= countFile; index++) {
        const rand = Math.floor(Math.random() * (max - min + 1) + min) // generate random number
        const imgName = dateToday.replaceAll('-', '') + rand + '.png' // remove - from the 2021-12-16 and add .png
        imgDatas.push({
          imgName: imgName,
          id_type: null,
          id_number: null,
          country: 'Philippines',
          expiry: null,
          issue: null
        })
      }
      // console.log(typeof (imgDatas))
      return JSON.stringify(imgDatas)
      // return imgDatas
    }
  },
  mounted () {
    this.syncID()
  }
}
</script>

<style scoped>
img {
  max-width: 100% !important;
  height: 200px !important;
}
</style>
