import api from '@/api'

export default {
  retrieveFilteredACR (payload) {
    return api.post('/v1/maintenance/api/customer-acr-return-all-filtered', payload)
  },
  retrieveACRFile (payload) {
    return api.post('/v1/maintenance/api/customer-acr-get-file', payload)
  }
}
