<template>
  <div>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-md-1 mx-md-4 mt-md-5" no-gutters>
      <v-col cols="12" v-if="showRemoteNotice">
        <v-alert
          color="info"
          dark
          icon="mdi-remote"
          border="left"
          prominent
        >
          This transaction will be processed on behalf of <b>{{remoteData.remote_location.location_name}}</b> branch.
        </v-alert>
      </v-col>
      <v-col :cols="12" :md="4">
        <h3 class="pb-2 pb-md-6 custom-heading primary--text">
          Customer Search
        </h3>
        <p class="text-body-2 mb-6">
          Search for a PERA Hub customer or click New Customer for first-time transaction
        </p>
      </v-col>
      <v-col class="pl-md-6" :cols="12" :md="8">
        <h4 class="mb-4 mb-md-5 grey--text text--darken-2">
          Customer's name
        </h4>
        <v-text-field
          v-model="firstName"
          class="ph-textbox mb-4 mb-md-6"
          label="First Name"
          @input="convertToProperCase('firstName')"
          outlined
        />
        <v-text-field
          v-model="lastName"
          class="ph-textbox mb-4 mb-md-6"
          label="Last Name"
          @input="convertToProperCase('lastName')"
          outlined
        />
        <div class="text-right">
          <v-btn @click="doSearch" :disabled="!firstName || !lastName" class="rounded-lg" color="primary" large>
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="resultDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="60%">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            PERA Hub Customer Results
          </h3>
          <v-spacer />
          <v-btn @click="resultDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select Customer</strong>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                class="ph-textbox rounded-lg"
                placeholder="Search"
                outlined
              />
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectCustomer"
            :headers="headers"
            :items="searchData.results"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No customers found"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ toProperCase(item.gender) || '-' }}
            </template>
            <template v-slot:[`item.mobile_no`]="{ item }">
              {{ item.mobile_no || '-' }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  trim,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      searchData: 'customers/searchData',
      selectedCustomer: 'customers/selectedCustomer',
      selectedEntry: 'manual/selectedEntry',
      oar: 'manual/oar',
      remoteData: 'obo/remoteData'
    }),
    customerID () {
      let img = this.selectedCustomer.img || '{}'
      img = JSON.parse(img)
      return Object.values(img)
    }
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      resultDialog: false,
      searchText: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      showRemoteNotice: false,
      headers: [
        {
          text: 'Customer No.',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'mobile_no'
        }
      ],
      breadcrumbsItems: [
        {
          text: 'PERA Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Manual Entry',
          disabled: false,
          href: '/pera-remit/manual-entry'
        },
        {
          text: 'Customer Search',
          disabled: true
        }
      ]
    }
  },
  methods: {
    convertToProperCase (field) {
      this[field] = toProperCase(trim(this[field]))
    },
    async selectCustomer (customerDetails) {
      const formNumber = await this.$store.dispatch('getFormNumber', {
        locationID: this.locationId,
        type: 'OAR'
      })

      if (formNumber) {
        this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)
        this.$store.commit('manual/SET_OAR', formNumber)

        let idType = ''
        let idNumber = ''

        if (this.customerID.length > 0) {
          idType = this.customerID[0].id_type
          idNumber = this.customerID[0].id_number
        }

        const payload = {
          trx_wu_pending_id: this.selectedEntry.id,
          form_type: 'OAR',
          form_number: this.oar,
          customer_id: this.selectedCustomer.customer_id,
          customer_name: this.selectedEntry.wu_payee_name,
          adj_type: '',
          mc_rate: '0',
          buy_back: '0',
          rate_category: '',
          mc_rate_id: 0,
          purpose_transaction: this.selectedCustomer.purpose_transaction,
          source_fund: this.selectedCustomer.source_fund,
          occupation: this.selectedCustomer.occupation,
          relation_to: this.selectedCustomer.relation_to,
          curr_address: this.selectedCustomer.address,
          curr_barangay: this.selectedCustomer.barangay,
          curr_city: this.selectedCustomer.municipality,
          curr_province: this.selectedCustomer.province,
          curr_country: this.selectedCustomer.country,
          id_type: idType,
          id_number: idNumber,
          birth_date: this.selectedCustomer.birth_date,
          birth_place: this.selectedCustomer.birth_place,
          birth_country: this.selectedCustomer.country_birth,
          last_name: this.selectedCustomer.last_name,
          first_name: this.selectedCustomer.first_name,
          middle_name: this.selectedCustomer.middle_name,
          recipient_last_name: this.selectedEntry.wu_payee_name.split(' ')[1],
          recipient_first_name: this.selectedEntry.wu_payee_name.split(' ')[0],
          recipient_middle_name: '',
          contact_no: this.selectedCustomer.mobile_no,
          first_buy_back: 0,
          risk_score: this.selectedCustomer.risk_score,
          risk_criteria: this.selectedCustomer.risk_criteria
        }

        const req = await this.$store.dispatch('manual/save', payload)

        if (req.code === 200) {
          this.renderToast('success', 'Success', 'Manual entry has been updated')
          this.$router.push({
            path: '/pera-remit/manual-entry'
          })
        } else {
          this.renderToast('error', 'Error', 'Failed to update manual entry')
        }
      } else {
        this.renderToast('warning', 'No OAR available', 'Please allocate OAR first to proceed to the transaction')
      }
    },
    doSearch () {
      if (this.firstName && this.lastName) {
        const firstName = this.firstName.replace(/(^\s+|\s+$)/g, '')
        const lastName = this.lastName.replace(/(^\s+|\s+$)/g, '')
        const keyword = `${lastName},${firstName}`

        this.searchData.results = []
        this.$store.dispatch('customers/doSearch', keyword)
        this.$store.commit('send/SET_CURRENT_STEP', 1)
        this.resultDialog = true
      } else {
        this.searchData.results = []
      }
    },
    renderToast,
    toProperCase,
    avatarColor,
    avatarInitial
  },
  mounted () {
    this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
    this.$store.commit('SET_PAGE_TITLE', 'Sender Lookup')

    console.log(this.remoteData)

    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData?.remote_location?.location_id

      this.showRemoteNotice = true

      this.renderToast('info', 'Remote Transaction is Enabled', `This transaction will be processed on behalf of ${this.remoteData?.remote_location?.location_name} branch.`)
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id

      this.showRemoteNotice = false

      this.renderToast('warning', 'Remote Transaction data cannot be retrived', 'The remote transaction data cannot be found. Normal transaction settings will be applied for this transaction instead.')
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id

      this.showRemoteNotice = false
    }
  }
}
</script>
