<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col>
        <PromoDetails ref="promoForm" />
      </v-col>
      <v-col :cols="0" :md="4">
        <v-card class="sticky-overview d-none d-md-block px-6 py-6 grey--text text--darken-2" flat>
          <h3 class="text-center mb-md-8">Summary Overview</h3>
          <v-row no-gutters>
            <v-col v-for="data in summaryData" :key="data.text" :cols="12">
              <v-row>
                <v-col :cols="12" :md="7">
                  {{ data.text }}
                </v-col>
                <v-col class="text-right" :cols="12" :md="5">
                  <strong>{{ data.value || '—' }}</strong>
                </v-col>
              </v-row>
              <v-divider class="my-md-2" style="border-style: dashed" />
            </v-col>
          </v-row>
          <v-btn
            @click="savePromo"
            class="rounded-lg mt-md-4 font-weight-black"
            color="primary"
            block
            x-large
          >
            Create
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { toMoney } from '@/utils'
import PromoDetails from '@/components/promos/PromoDetails.vue'

export default {
  computed: {
    ...mapGetters({
      formData: 'promos/formData',
      promoTypes: 'promos/promoTypes',
      frequencyList: 'promos/frequencyList'
    }),
    summaryData () {
      const fullData = this.formData
      const summary = {
        promoType: { text: 'Promo Type', value: null },
        promoName: { text: 'Promo Name', value: null },
        startDate: { text: 'Start Date', value: null },
        endDate: { text: 'End Date', value: null },
        frequency: { text: 'Transaction Frequency', value: null },
        amountToCredit: { text: 'Peso Amount to Credit', value: null },
        minTransactionAmount: { text: 'Minimum Transaction Amount', value: null }
      }

      const fields = Object.keys(summary)

      fields.forEach(field => {
        switch (field) {
          case 'promoType':
            if (fullData[field] && fullData[field].length > 0) {
              const promoTypes = []
              for (let i = 0; i < fullData[field].length; i++) {
                const promoType = this.promoTypes.filter(item => item.value === fullData[field][i])

                if (promoType.length === 1) {
                  promoTypes.push(promoType[0].text)
                }
              }

              summary[field].value = promoTypes.join(', ')
            }
            break
          case 'frequency':
            if (fullData[field] && fullData[field].length > 0) {
              const frequencies = []
              for (let i = 0; i < fullData[field].length; i++) {
                const frequency = this.frequencyList.filter(item => item.value === fullData[field][i])

                if (frequency.length === 1) {
                  frequencies.push(frequency[0].text)
                }
              }

              summary[field].value = frequencies.join(', ')
            }
            break
          case 'startDate':
          case 'endDate':
            if (fullData[field]) {
              summary[field].value = format(new Date(fullData[field]), 'MMMM d, yyyy')
            }
            break
          case 'amountToCredit':
          case 'minTransactionAmount':
            if (fullData[field]) {
              summary[field].value = toMoney(fullData[field])
            }
            break
          default:
            if (fullData[field]) {
              summary[field].value = fullData[field]
            }
            break
        }
      })

      return Object.values(summary)
    }
  },
  components: {
    PromoDetails
  },
  data () {
    return {
      breadcrumbsItems: [
        {
          text: 'Promos',
          disabled: false,
          href: '/promos'
        },
        {
          text: 'Create Promo',
          disabled: true
        }
      ]
    }
  },
  methods: {
    savePromo () {
      if (this.$refs.promoForm.$refs.form.validate()) {
        this.$refs.promoForm.savePromo()
      } else {
        // Validation error
      }
    }
  },
  beforeMount () {
    this.$store.dispatch('promos/resetForm')
  }
}
</script>
