<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 px-md-5 mb-md-12" no-gutters>
      <v-col class="mt-2 mt-sm-4 mt-md-7" :cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          class="custom-table"
          calculate-widths
        >
          <template #item.type="{ item }">
            <span v-if="item.type === 'WADJ'">Withdrawals, Adjustment</span>
            <span v-if="item.type === 'WIBT'">Withdrawals, Inter-Branch Transfer</span>
            <span v-if="item.type === 'WREP'">Withdrawals, Daily Replenishment</span>
            <span v-if="item.type === 'WPCF'">Withdrawals, Petty Cash</span>
            <span v-if="item.type === 'DEP' || item.type === 'HODEP'">Bank Deposits</span>
            <span v-if="item.type === 'WA'">Withdrawals, Cash Advance</span>
          </template>
          <template #item.action="{ item }">
            <v-btn @click="showDeletePrompt(item)" color="red" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        @click="newEntry"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="isDeletePromptOpened"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header text-center">
          Are you sure you want to proceed?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="danger"
            text
            @click="closeDeletePrompt"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="deleteRecord"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      items: 'replenishBranch/items'
    })
  },
  data () {
    return {
      fab: false,
      isDeletePromptOpened: false,
      selectedItem: null,
      breadcrumbsItems: [
        {
          text: 'BOS Module',
          disabled: true,
          exact: true,
          to: '/'
        },
        {
          text: 'Replenish Branch',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Date of Transaction',
          sortable: true,
          value: 'trx_date',
          width: '150px'
        },
        {
          text: 'Bank Branch',
          sortable: true,
          value: 'bank_branch',
          width: '150px'
        },
        {
          text: 'Reference No.',
          sortable: true,
          value: 'reference_no',
          width: '150px'
        },
        {
          text: 'Transaction',
          sortable: true,
          value: 'type',
          width: '150px'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount',
          width: '150px'
        },
        {
          text: 'Action',
          sortable: true,
          value: 'action',
          width: '150px'
        }
      ]
    }
  },
  methods: {
    async deleteRecord () {
      if (this.selectedItem) {
        try {
          const { code } = await this.$store.dispatch('replenishBranch/deleteRecord', this.selectedItem.trx_bank_transaction_id)

          if (code === 200) {
            renderToast('success', 'Successful', 'Record has been deleted')
            this.closeDeletePrompt()
            this.retrieveData()
          } else {
            renderToast('error', 'Request Error', 'Failed to delete record')
          }
        } catch (e) {
          renderToast('error', 'USP Error', 'Failed to delete record')
        }
      }
    },
    showDeletePrompt (item) {
      this.selectedItem = item
      this.isDeletePromptOpened = true
    },
    closeDeletePrompt () {
      this.selectedItem = null
      this.isDeletePromptOpened = false
    },
    newEntry () {
      this.$router.push('/replenish-branch/new')
    },
    async retrieveData () {
      try {
        const { code } = await this.$store.dispatch('replenishBranch/retrieveData', {
          trxDate: this.currUser.trx_date,
          locationId: this.currUser.location_id,
          type: 'D'
        })

        if (code !== 200) {
          renderToast('error', 'Request Error', 'Failed to retrieve grid')
        }
      } catch (e) {
        renderToast('error', 'USP Error', 'Failed to retrieve grid')
      }
    }
  },
  mounted () {
    this.retrieveData()
  }
}
</script>
