<template>
  <div class="reports-container" v-if="currentReport !== null">
    <div class="report-header" id="reportActionHeader" style="display: flex; flex-direction: row;margin-bottom: 10px;">
      <div class="report-logo" v-if="$route.params.report !== 'coh'">
        <img style="width: 100%; margin: auto" src="@/assets/images/ph-logo-horizontal.svg"/>
      </div>
      <div class="report-details" v-if="$route.params.report !== 'coh'">
        <p class="report-details-name"><strong>{{ currentReport.rpt_name }}</strong></p>
        <p v-if="params.location_name">Branch: <b>{{ params.location_name }}</b></p>
        <p>For the period of <b>{{ params.date_from }}</b> to <b>{{ params.date_to }}</b></p>
      </div>
      <div class="report-action" id="reportActionButtons">
        <v-btn
          v-if="$route.params.report !== 'dtrs' && $route.params.report !== 'coh'"
          @click="exportReport"
          elevation="0"
          color="primary"
          class="report-export"
        >
          <v-icon
            left
            dark
          >
            mdi-file-excel
          </v-icon>
          Export to Excel
        </v-btn>
        <v-btn @click="printReport" elevation="0" class="report-print">
          <v-icon
            left
            dark
          >
            mdi-printer-outline
          </v-icon>
          Print
        </v-btn>
      </div>
    </div>
    <component
      style="font-size: 12px"
      id="reportTable"
      :is="selectedReport"
      :isLoading="isLoading"
      :reportData="reportData"
      :columns="columns"
      :footer="footer"
      :params="params"
    />
    <div id="dtrsDivApproval" v-if="!isLoading && $route.params.report !== 'coh'">
      <div class="dtrsDivApproval_inner">&nbsp;</div>
      <div class="dtrsDivApproval_inner">
          <div>
              <div>Checked And Approved by:</div>
          </div>
          <div>
              <div>{{ flaName }}</div>
              <div>Signature Over Printed Name</div>
          </div>
          <div>
              <div>{{ dateToday }}</div>
              <div>Date</div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonReportLayout from '@/layouts/reports/CommonReportLayout.vue'
import DTRSLayout from '@/layouts/reports/DTRSLayout.vue'
import CashCountLayout from '@/layouts/reports/CashCountLayout.vue'
import { format, parse } from 'date-fns'
import { mapGetters } from 'vuex'
import * as XLSX from 'xlsx'

export default {
  computed: {
    ...mapGetters({
      reportsStructure: 'report/reportsStructure',
      currUser: 'auth/currUser'
    }),
    flaName () {
      return `${this.currUser.last_name}, ${this.currUser.first_name}`
    },
    dateToday () {
      return (this.currUser.trx_date ? format(parse(this.currUser.trx_date, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy') : '')
    },
    availableReports () {
      if (this.reportsStructure) {
        const reportCategories = Object.values(this.reportsStructure)
        const allReports = []

        reportCategories.forEach(reports => {
          for (let i = 0; i < reports.length; i++) {
            allReports.push(reports[i])
          }
        })
        return allReports
      }
      return []
    },
    currentReport () {
      let report = null
      this.availableReports.forEach(item => {
        if (item.rpt_value === this.$route.params.report) report = item
      })

      return report
    },
    columns () {
      if (this.currentReport) {
        return JSON.parse(this.currentReport.rpt_columns)
      }

      return []
    },
    footer () {
      if (this.columns && this.columns.length > 0 && this.reportData) {
        const data = {}
        let breakSpan = false
        let span = 0

        for (let i = 0; i < this.reportData.length; i++) {
          for (let j = span; j < this.columns.length; j++) {
            if (this.columns[j].hasTotal) {
              if (!breakSpan) breakSpan = true

              if (this.reportData[i][this.columns[j].value]) {
                if (data[this.columns[j].value]) {
                  data[this.columns[j].value] += Number(this.reportData[i][this.columns[j].value])
                } else {
                  data[this.columns[j].value] = Number(this.reportData[i][this.columns[j].value])
                }
              }
            } else {
              if (!breakSpan) span += 1
              else data[this.columns[j].value] = ''
            }
          }
          if (!breakSpan) breakSpan = true
        }

        return { span, data }
      }

      return null
    }
  },
  data () {
    return {
      reportData: null,
      params: {},
      isLoading: true,
      selectedReport: null
    }
  },
  methods: {
    exportReport () {
      const data = document.getElementById('reportTable')
      const excelFile = XLSX.utils.table_to_book(data, { sheet: 'Sheet1', cellDates: true })
      XLSX.write(excelFile, { bookType: 'xlsx', bookSST: true, type: 'base64' })
      XLSX.writeFile(excelFile, `${this.currentReport.rpt_value}.xlsx`)
    },
    printReport () {
      window.print()
    }
  },
  async mounted () {
    if (this.reportsStructure.length === 0) await this.$store.dispatch('report/getRptTypes')

    console.log(this.currentReport.rpt_value)

    if (this.currentReport.rpt_value === 'dtrs') {
      this.selectedReport = DTRSLayout
    } else if (this.currentReport.rpt_value === 'coh') {
      this.selectedReport = CashCountLayout
    } else {
      this.selectedReport = CommonReportLayout
    }

    if (this.$route.query.q) {
      const endpoint = this.currentReport.rpt_endpoint
      const getBase64Data = Buffer.from(this.$route.query.q, 'base64').toString('ascii')
      this.params = JSON.parse(getBase64Data)
      const payload = { ...this.params }

      const excludeToPayload = [
        'location_name',
        'currency_name'
      ]

      excludeToPayload.map(item => {
        if (Object.prototype.hasOwnProperty.call(payload, item)) {
          delete payload[item]
        }
      })

      let method = 'GET'

      if (['cdcr', 'digital_services_promo_rpt'].includes(this.currentReport.rpt_value)) method = 'POST'

      const res = await this.$store.dispatch('report/generateReport', { endpoint, payload, method })

      this.isLoading = false

      if (this.currentReport.rpt_value === 'comp_aging_rpt') {
        this.reportData = res
      } else if (this.currentReport.rpt_value === 'cpr') {
        this.reportData = res.result.cpr
      } else {
        if (res.code === 200) {
          this.reportData = res.result
        }
      }
    } else {
      window.close()
    }
  }
}
</script>

<style scoped>

body {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFFFFF), color-stop(1, #FFFFFF));
  background-image: -o-linear-gradient(bottom, #FFFFFF 0%, #FFFFFF 100%);
  background-image: -moz-linear-gradient(bottom, #FFFFFF 0%, #FFFFFF 100%);
  background-image: -webkit-linear-gradient(bottom, #FFFFFF 0%, #FFFFFF 100%);
  background-image: -ms-linear-gradient(bottom, #FFFFFF 0%, #FFFFFF 100%);
  background-image: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 100%);
}

table
{
    border-bottom: 1px solid #ddd !important;
    background-color: #fff !important;
}

@media screen {
  #intVal {
      visibility: visible;
  }
}

@media print {
  thead {
      display: table-header-group;
  }
}

#tbllsgreport, #tblgwautocashin, #tblsales, #tblDTRS, #tblCPR, #tblCRS, #tblDTRS2, #tblDTRS5, #tblcprs, #tblavpmc, #compstrd, #tblavpdaily, #tblrecadj, #tblnfc, #tblnonex, #tblcebreport, #tblbegbalreport, #tblbranchvarreport, #tblbpdtrreport, #tblchangerateproductreport, #tblmonthlyregionreport, #tblnewavpdailyreport, #tblnewavpmonthlyreport, #tblibtreport, #tblreportbalancereset, #tblreportpwdreset, #slsbillpay, #tblbananapay, #tblreconvarreport, #tblreconadjreport, #tblloyaltypromoreport, #tblinstantcash, #tblctpl, #tblctplcancel, #tblctplammend, #tblbayadcenterreport, #tbljournal, #tblcicolimit, #tblcicolimitlog {
  width: 99.9%;
}

#tblbpwubb, #tblbpins, #tblbpload, #tblbpsumloadl, #tblbpsumloadd, #tblbpsuminsl, #tblbpsuminsd, #tblbpsumwubbd, #tblbpsumwubbl, #tblmbbtbp, #tbldetwubbfc, #tblsumwubbfc, #tblexpnses {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

#tbllsgreport thead tr th, #tblgwautocashin thead tr th, #tblavpdaily thead tr th, #tblcebreport thead tr th, #tblbegbalreport thead tr th, #tblbranchvarreport thead tr th, #tblbpdtrreport thead tr th, #tblchangerateproductreport thead tr th, #tblmonthlyregionreport thead tr th, #tblnewavpdailyreport thead tr th, #tblnewavpmonthlyreport thead tr th, #tblibtreport thead tr th, #tblreportbalancereset thead tr th, #tblreportpwdreset thead tr th, #slsbillpay thead tr th, #tblbananapay, thead tr th, #tblreconvarreport thead tr th, #tblrecadj thead tr th, #tblnfc thead tr th, #tblsales thead tr th, #tblnonex thead tr th, #tblcompctr thead tr th, #tblDTRS thead tr th, #tblCPR thead tr th, #tblTrxLogs thead tr th, #tblCRS thead tr th, #tblbpwubb thead tr th, #tblbpins thead tr th, #tblbpload thead tr th, #tblbpsumloadl thead tr th, #tblbpsumloadd thead tr th, #tblbpsuminsl thead tr th, #tblbpsuminsd thead tr th, #tblbpsumwubbd thead tr th, #tblbpsumwubbl thead tr th, #tblmbbtbp thead tr th, #tbldetwubbfc thead tr th, #tblsumwubbfc thead tr th, #tblexpnses thead tr th, #tblinstantcash thead tr th, #tblctpl thead tr th, #tblctplcancel thead tr th, #tblctplammend thead tr th, #tblbayadcenterreport thead tr th, #tbljournal thead tr th, #tblcicolimit thead tr th, #tblcicolimitlog thead tr th {
  padding: 3px;
}

#tblcprs thead tr th {
  padding: 3px;
}

#tbllsgreport tbody tr th, #tblgwautocashin tbody tr th, #tblsales tbody tr th, #tblnonex tbody tr th, #tblrecadj tbody tr th, #tblnfc tbody tr th, #tblavpdaily tbody tr th, #tblcebreport tbody tr th, #tblbegbalreport tbody tr th, #tblbranchvarreport tbody tr th, #tblbpdtrreport tbody tr th, #tblchangerateproductreport tbody tr th, #tblmonthlyregionreport tbody tr th, #tblnewavpdailyreport tbody tr th, #tblnewavpmonthlyreport tbody tr th, #tblibtreport tbody tr th, #tblreportbalancereset tbody tr th, #tblreportpwdreset tbody tr th, #slsbillpay tbody tr th, #tblbananapay tbody tr th, #tblreconvarreport tbody tr th, #tblreconadjreport tbody tr th, #tblloyaltypromoreport tbody tr th, #tblcompctr tbody tr th, #tblDTRS tbody tr th, #tblCPR tbody tr th, #tblCRS tbody tr th, #tblTrxLogs tbody tr th, #tblinstantcash tbody tr th, #tblctpl tbody tr th, #tblctplcancel tbody tr th, #tblctplammend tbody tr th, #tblbayadcenterreport tbody tr th, #tbljournal tbody tr th, #tblcicolimit tbody tr th, #tblcicolimitlog tbody tr th {
  text-align: center !important;
  font-size: 10px;
}

#tblbpwubb tbody tr th, #tblbpins tbody tr th, #tblbpload tbody tr th, #tblbpsumloadl tbody tr th, #tblbpsumloadd tbody tr th, #tblbpsuminsl tbody tr th, #tblbpsuminsd tbody tr th, #tblbpsumwubbd tbody tr th, #tblbpsumwubbl tbody tr th, #tblmbbtbp tbody tr th, #tbldetwubbfc tbody tr th, #tblsumwubbfc tbody tr th, #tblexpnses tbody tr th {
  text-align: center !important;
  font-size: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: #006dcc;
}

#tblcprs tbody tr th {
  text-align: center !important;
  font-size: 10px;
}

#tblbpwubb tbody tr:nth-child(even), #tblbpins tbody tr:nth-child(even), #tblbpload tbody tr:nth-child(even), #tblbpsumloadl tbody tr:nth-child(even), #tblbpsumloadd tbody tr:nth-child(even), #tblbpsuminsl tbody tr:nth-child(even), #tblbpsuminsd tbody tr:nth-child(even), #tblbpsumwubbd tbody tr:nth-child(even), #tblbpsumwubbl tbody tr:nth-child(even), #tblmbbtbp tbody tr:nth-child(even), #tblsumwubbfc tbody tr:nth-child(even), #tbldetwubbfc tbody tr:nth-child(even), #tblexpnses tbody tr:nth-child(even) {
  background-color: #f2f2f2
}

#tbllsgreport tbody tr td, #tblgwautocashin tbody tr td, #tblsales tbody tr td, #tblnonex tbody tr td, #tblnfc tbody tr td, #tblrecadj tbody tr td, #tblavpdaily tbody tr td, #tblcebreport tbody tr td, #tblbegbalreport tbody tr td, #tblbranchvarreport tbody tr td, #tblbpdtrreport tbody tr td, #tblchangerateproductreport tbody tr td, #tblmonthlyregionreport tbody tr td, #tblnewavpdailyreport tbody tr td, #tblnewavpmonthlyreport tbody tr td, #tblibtreport tbody tr td, #tblreportbalancereset tbody tr td, #tblreportpwdreset tbody tr td, #slsbillpay tbody tr td, #tblbananapay tbody tr td, #tblreconvarreport tbody tr td, #tblreconadjreport tbody tr td, #tblloyaltypromoreport tbody tr td, #tblcompctr tbody tr td, #tblDTRS tbody tr td, #tblCPR tbody tr td, #tblCRS tbody tr td, #tblTrxLogs tbody tr td, #tblinstantcash tbody tr td, #tblctpl tbody tr td, #tblctplcancel tbody tr td, #tblctplammend tbody tr td, #tblbayadcenterreport tbody tr td, #tbljournal tbody tr td, #tblcicolimit tbody tr td, #tblcicolimitlog tbody tr td {
  padding: 3px;
  font-size: 10px;
}

#tblbpwubb tbody tr td, #tblbpins tbody tr td, #tblbpload tbody tr td, #tblbpsumloadl tbody tr td, #tblbpsumloadd tbody tr td, #tblbpsuminsl tbody tr td, #tblbpsuminsd tbody tr td, #tblbpsumwubbd tbody tr td, #tblbpsumwubbl tbody tr td, #tblmbbtbp tbody tr td, #tblsumwubbfc tbody tr td, #tbldetwubbfc tbody tr td, #tblexpnses tbody tr td {
  padding: 7px;
  font-size: 11px;
  border-style: dotted;
  border-width: 1px;
  border-color: #428bca;
}

.digit {
  text-align: right;
}

.date {
  text-align: left;
}

.bordertd {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  /*double;*/
}

.borderdouble {
  padding: 2px 0 !important;
}

#printTable div.col-lg-12 {
}

#tblcprDiv {
  margin: 20px auto 0 auto;
  width: 80%;
}

#tblcprDiv table {
  width: 100%;
  font-size: 12px;
  border: 1px solid #000;
}

#tblcprDiv table tr:nth-child(1) th {
  background-color: #ddd;
  text-align: center;
}

#tblcprDiv table tr td {
  padding: 3px;
}

#dtrstoptbl {
  font-size: 10px;
}

div.dtrslabel {
  float: left;
  width: 274.75px;
  padding: 16px 0 0 0;
  font-weight: bold;
  text-align: right;
}

#dtrstoptbl table tr:first-child {
  border-bottom: 1px solid #000;
}

#dtrstoptbl table tr:last-child {
  border-bottom: 1px solid #000;
}

#dtrstoptbl table tr:first-child th {
  text-align: center;
}

#dtrstoptbl table tr:nth-child(1) td {
  border: none;
}

#dtrstoptbl table tr td {
  padding: 3px 5px;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

#tblDTRS2 {
  margin: 30px 0 0 0;
  font-size: 10px;
}

#tblDTRS2 tbody tr {
  border: 3px solid #000 !important;
}

#tblDTRS2 tbody tr:first-child {
  border: none !important;
}

#tblDTRS2 tbody tr:first-child th {
  border-right: none;
}

#tblDTRS2 tbody tr th, #tblDTRS2 tbody tr td {
  padding: 3px;
  text-align: right;
}

#tblDTRS2 tbody tr td:nth-child(2) {
  border-left: 2px solid #000;
}

#tblDTRS2 tbody tr td:nth-child(3) {
  border-right: 2px solid #000;
}

#tblDTRS2 tbody tr td:nth-child(1) {
  font-weight: bold;
  text-align: center;
}

#tblDTRS3, #tblDTRS4 {
  width: 100%;
}

#tblDTRS3 tbody tr td, #tblDTRS4 tbody tr td {
  padding: 3px;
}

#tblDTRS3 tbody tr td:nth-child(1), #tblDTRS4 tbody tr td:nth-child(1) {
  font-weight: bold;
}

#tblDTRS3 tbody tr:nth-child(1) th:nth-child(3), #tblDTRS4 tbody tr:nth-child(1) th:nth-child(3) {
  text-align: center;
}

#tblDTRS3 tbody tr:nth-child(2) td, #tblDTRS4 tbody tr:nth-child(2) td {
  text-align: center;
  font-weight: bold;
}

#dtrsDiv {
  float: left;
  width: 100px;
  border-right: 2px solid #000;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  padding: 3px;
}

#dtrsDiv div:nth-child(2) {
  width: 100%;
  background-color: #999;
}

#dtrsDiv2 {
  font-size: 10px;
  float: left;
  width: 396px;
}

div.dtrsDiv2child {
  width: 100%;
  border-bottom: 2px solid #000;
  overflow: hidden;
  padding: 3px;
}

div.dtrsDiv2child:last-child {
  border-bottom: none;
}

div.dtrsDiv2child2 {
  float: left;
  width: 50%;
}

div.dtrsDiv2child2:first-child {
  font-weight: bold;
}

#tblDTRS5 {
  border: 3px solid #000;
  font-size: 10px;
}

#tblDTRS5 tbody tr:first-child {
  border-bottom: 3px solid #000;
}

#tblDTRS5 tbody tr th, #tblDTRS5 tbody tr td {
  padding: 3px;
}

#tblDTRS5 tbody tr:first-child th {
  text-align: center;
}

#tblDTRS5 tbody tr:nth-child(2) td:first-child {
  border-right: none;
  border-bottom: 3px solid #000;
}

#tblDTRS5 tbody tr:nth-child(2) td:nth-child(2) {
  border-bottom: 3px solid #000;
}

#tblDTRS5 tbody tr:nth-child(2) td, #tblDTRS5 tbody tr:nth-child(3) td {
  border-right: 3px solid #000;
  text-align: center;
  font-weight: bold;
}

#tblDTRS5 tbody tr:nth-child(3) td {
  border-bottom: 3px solid #000;
}

#tblDTRS5 tbody tr:nth-child(3) td:first-child {
  border-bottom: none;
}

#tblDTRS5 tbody tr:nth-child(3) td:nth-child(2), #tblDTRS5 tbody tr:nth-child(3) td:nth-child(4), #tblDTRS5 tbody tr:nth-child(3) td:nth-child(6) {
  border-right: none;
}

#tblDTRS5 tbody tr:nth-child(4) td, #tblDTRS5 tbody tr:last-child td {
  border-right: 3px solid #000;
}

#tblDTRS5 tbody tr:nth-child(4) td:first-child, #tblDTRS5 tbody tr:last-child td:first-child {
  text-align: right;
  font-weight: bold;
}

#tblDTRS5 tbody tr:last-child td {
  border-bottom: 3px solid #000;
}

#dtrsDivApproval {
  width: 100%;
  margin: 50px 0 0 0;
  font-size: 11px;
}

div.dtrsDivApproval_inner {
  float: left;
  width: 50%;
}

div.dtrsDivApproval_inner div {
  float: left;
  width: 31%;
  margin: 0 0 0 5px;
}

div.dtrsDivApproval_inner div div {
  width: 100%;
  text-align: center;
}

div.dtrsDivApproval_inner div:nth-child(1) div {
  font-weight: bold;
}

div.dtrsDivApproval_inner div:nth-child(2) div:nth-child(2), div.dtrsDivApproval_inner div:nth-child(3) div:nth-child(2) {
  border-top: 2px solid #000;
}

.report-table-head th {
  font-weight: bolder;
  padding: 4px 5px !important;
}

tr {
  border-bottom: 1px solid black;
}

.report-table-data td,
.report-table-footer td {
  padding-right: 2px;
  padding-left: 2px;
}

@media print {
  #reportTable {
    display: block;
  }

  .reports-container {
    overflow-y: hidden !important;
  }
}

.reports-container {
  overflow-x: auto;
}
</style>
