<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ph-transaction-container">
        <v-col cols="12">
          <ReceiveMoneySearch />
        </v-col>
        <v-col cols="12">
          <ReceiveMoneyStatus />
        </v-col>
        <v-col cols="12">
          <ReceiveMoneyResult />
        </v-col>
        <v-col cols="12">
          <ReceiveMoneyCustomerSearch />
        </v-col>
        <v-col cols="12">
          <ReceiveMoneyForm />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ReceiveMoneySearch from '@/components/nonex/ReceiveMoneySearch'
import ReceiveMoneyStatus from '@/components/nonex/ReceiveMoneyStatus'
import ReceiveMoneyResult from '@/components/nonex/ReceiveMoneyResult'
import ReceiveMoneyCustomerSearch from '@/components/nonex/ReceiveMoneyCustomerSearch'
import ReceiveMoneyForm from '@/components/nonex/ReceiveMoneyForm'
import wacomMixin from '@/mixins/wacom.js'

export default {
  name: 'ReceiveMoneyNewTrx',
  mixins: [wacomMixin],
  components: {
    ReceiveMoneySearch,
    ReceiveMoneyStatus,
    ReceiveMoneyResult,
    ReceiveMoneyCustomerSearch,
    ReceiveMoneyForm
  },
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Remit',
          disabled: false,
          href: '/pera-remit'
        },
        {
          text: 'Receive Money',
          disabled: false,
          href: '/pera-remit/receive'
        },
        {
          text: 'Create',
          disabled: true,
          href: '#'
        }
      ]
    }
  },
  mounted () {
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)

    console.log('mountedd')

    // this.$store.commit('nonex/SET_INQUIRE_RESULT', [])
    // this.$store.commit('nonex/SET_CURRENT_TRANSACTION', {})
    // this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
    // this.$store.commit('customers/SET_SEARCH_RESULT', null)
  }
}
</script>
<style scoped>
  .ph-nonex-card-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 160px;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
  }

  .ph-nonex-receive-action-buttons {
    display: flex;
    padding: 20px;
  }

  .ph-transaction-container {
    padding: 0;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }
  }
</style>
