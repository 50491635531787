<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row v-if="approverLevel === 'ADMIN' || approverLevel === 'FINANCE' || approverLevel === 'NETOPS'" class="px-2 pt-md-2 px-md-5 mb-md-12">
      <v-col :cols="6">
        <v-tabs v-model="currentMonitoringView" class="noBottomLine">
          <v-tab class="font-weight-bold text-subtitle-1 text-capitalize tabText">Location</v-tab>
          <v-tab class="font-weight-bold text-subtitle-1 text-capitalize tabText">Customer</v-tab>
        </v-tabs>
      </v-col>
      <v-col class="d-flex align-center justify-end" :cols="12" :md="6">
        <v-btn
          @click="generateReport"
          class="rounded-lg px-9 py-4"
          color="primary"
          large
        >
          Generate Report
        </v-btn>
      </v-col>
      <v-col class="pt-4" :cols="12">
        <v-tabs-items v-model="currentMonitoringView" class="py-2">
          <v-tab-item>
            <LocationMonitoring />
          </v-tab-item>
          <v-tab-item>
            <CustomerMonitoring />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'
import LocationMonitoring from '@/views/TransactionLimit/Monitoring/LocationMonitoring.vue'
import CustomerMonitoring from '@/views/TransactionLimit/Monitoring/CustomerMonitoring.vue'

export default {
  computed: {
    ...mapGetters({
      approverLevel: 'transactionLimit/approverLevel',
      currUser: 'auth/currUser'
    }),
    currentMonitoringView: {
      get () {
        return this.$store.state.transactionLimit.currentMonitoringView
      },
      set (val) {
        this.$store.commit('transactionLimit/SET_CURRENT_MONITORING_VIEW', val)
      }
    }
  },
  components: {
    LocationMonitoring,
    CustomerMonitoring
  },
  data () {
    return {
      fab: false,
      breadcrumbsItems: [
        {
          text: 'Transaction Limit',
          disabled: false,
          exact: true,
          to: '/transaction-limit'
        },
        {
          text: 'Monitoring',
          disabled: true
        }
      ]
    }
  },
  methods: {
    generateReport () {
      this.$router.push('/transaction-limit/monitoring/generate-report')
    }
  },
  async mounted () {
    const { code, result } = await this.$store.dispatch('transactionLimit/getApprover', this.currUser.user_id)

    if (code === 200) {
      if (result.length > 0) {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', result[0].approver_level)
      } else {
        this.$store.commit('transactionLimit/SET_APPROVER_LEVEL', '')
      }
    }

    if (this.approverLevel !== 'ADMIN' && this.approverLevel !== 'FINANCE' && this.approverLevel !== 'NETOPS') {
      renderToast('error', 'No access', 'You don\'t have access to this module')
    }
  },
  async beforeMount () {
    await this.$store.dispatch('getProviders')
  }
}
</script>

<style lang="css" scoped>
.noBottomLine {
  border-bottom: none !important;
}

.tabText {
  font-family: 'Baloo Extra Bold' !important;
  /* min-width: 150px !important; */
  margin-right: 10px !important;
}
</style>
