<template>
  <v-expansion-panels accordion>
    <v-expansion-panel class="ph-nonex-card">
      <v-expansion-panel-header class="ph-expansion-panel-header">
        <div class="ph-nonex-card-title-container">
          <h1 color="primary" class="ph-nonex-card-title">Partners Status</h1>
          <div class="ph-nonex-card-chip-container">
            <v-chip
              class="ph-inquire-success-count"
              color="green"
              text-color="white"
            >
              <v-avatar
                left
                class="green darken-4"
              >
                {{onlineCount}}
              </v-avatar>
              Online
            </v-chip>
            <v-chip
              class="ph-inquire-error-count"
              color="red"
              text-color="white"
            >
              <v-avatar
                left
                class="red darken-4"
              >
                {{offlineCount}}
              </v-avatar>
              Offline
            </v-chip>
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="ph-inquire-result-content">
        <v-row fluid>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="3"
            v-for="item in remcoList"
            :key="item.length"
          >
            <v-sheet
              :color="item.status === 1 ? 'success' : 'error'"
              height="100"
              rounded
              class="ph-nonex-status-remco-sheet lighten-2"
            >
              <h1 class="ph-nonex-status-remco-name">{{item.non_ex_name}}</h1>
              <p class="ph-nonex-status-text">{{item.status === 1 ? 'Online' : 'Offline'}}</p>
            </v-sheet>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ReceiveMoneyStatus',
  data () {
    return {
      remcoList: [],
      onlineCount: 0,
      offlineCount: 0
    }
  },
  computed: {
    ...mapGetters({
      remcos: 'nonex/remcos'
    })
  },
  watch: {
    remcos: {
      handler (getRemco) {
        console.log(getRemco)
        this.remcoList = getRemco.data.filter(item => { return item.can_receive_money === true })
      },
      deep: true
    }
  },
  async beforeMount () {
    await this.$store.dispatch('nonex/getRemcos')
    const getOnlineList = this.remcoList.filter((item) => { return item.status === 1 })
    const getOfflineList = this.remcoList.filter((item) => { return item.status === 0 })

    console.log(this.remcoList)
    this.onlineCount = getOnlineList.length
    this.offlineCount = getOfflineList.length
  }
}
</script>
<style scoped>
  .ph-nonex-card-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-nonex-receive-search-title {
    display: flex;
  }

  .ph-textbox-title {
    margin: auto;
    margin-left: 0;
    font-family: 'Proxima Nova Bold';
    font-size: 16px;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 160px;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
  }

  .ph-inquire-result-content {
    line-height: 1.5;
  }

  .ph-inquire-result-content p {
    margin-bottom: 0;
    font-size: 16px;
  }

  .ph-nonex-receive-action-buttons {
    display: flex;
  }

  .ph-inquire-result-receiver-name,
  .ph-inquire-result-header {
    font-family: 'Proxima Nova Bold';
  }

  .ph-inquire-error-items {
    padding: 0;
  }

  .ph-inquire-success-count,
  .ph-inquire-error-count {
    flex: unset !important;
    margin-left: 0;
    margin-right: 10px;
  }

  .ph-nonex-status-remco-sheet {
    padding: 10px;
  }

  .ph-nonex-status-remco-name {
    font-family: "Proxima Nova Bold";
    font-size: 20px;
  }

  .ph-nonex-status-text {
    font-size: 14px;
  }

  .ph-expansion-panel-header {
    display: flex;
    flex-direction: row;
  }

  .ph-nonex-card-title-container {
    display: flex;
    flex-direction: column;
  }

  .ph-nonex-card-chip-container {
    margin-top: 10px;
  }

  @media only screen and (min-width: 600px) {
    .ph-nonex-card-title-container {
      flex-direction: row;
    }

    .ph-nonex-card-chip-container {
      margin-left: auto;
      margin-top: 0;
    }

    .ph-inquire-success-count,
    .ph-inquire-error-count {
      margin-left: 10px;
      margin-right: 0;
    }
  }
</style>
