<template>
  <div class="px-md-2">
    <h2 class="pt-md-6 custom-heading primary--text">
      Success!
    </h2>
    <p class="pt-md-4 pb-md-6">
      Thank you. Pera Protect purchased has been completed.
    </p>
    <h3 class="custom-heading">
      Transaction Summary
    </h3>
    <div v-if="transactionData.insurance.category === 'COC7'" class="mt-md-6" style="max-width: 350px">
      <v-row no-gutters>
        <v-col :cols="6">
          OAR
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionData.formNumber ? transactionData.formNumber : '-' }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Insurance
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionData.insurance.name }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          MV Type
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionData.mvType }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Premium Type
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionData.premiumType }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Amount to Collect
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionData.amount }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Customer Name
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ getInsuredFullName() }}
          <v-divider class="my-md-2" />
        </v-col>
      </v-row>
    </div>
    <div v-else class="mt-md-6" style="max-width: 600px">
      <v-row no-gutters>
        <v-col :cols="6">
          OAR
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionData.formNumber ? transactionData.formNumber : '-' }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Insurance
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionData.insurance.name }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Policy Name
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionData.policy.policy_name }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Amount to Collect
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionData.amount }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Insured
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ getInsuredFullName() }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Beneficiary
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ getBeneficiaryFullName() }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          Relationship to Insured
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ transactionData.beneficiaryRelationship }}
          <v-divider class="my-md-2" />
        </v-col>
        <v-col :cols="6">
          COC Number
          <v-divider class="my-md-2" />
        </v-col>
        <v-col class="text-right" :cols="6">
          {{ successData.coc_number }}
          <v-divider class="my-md-2" />
        </v-col>
      </v-row>
    </div>
    <div class="mt-6 mt-md-10">
      <v-btn
        @click="backToMain"
        class="rounded-lg"
        color="primary"
        large
      >
        Back to Pera Protect
      </v-btn>
      <!-- <v-btn
        class="mt-4 mt-md-0 ml-md-4 rounded-lg"
        color="primary"
        outlined
        large
      >
        Print COC
      </v-btn> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { trim } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      transactionData: 'insurance/transactionData',
      successData: 'insurance/successData'
    })
  },
  methods: {
    getInsuredFullName () {
      const fullName = [
        this.transactionData.insuredFirstName,
        this.transactionData.insuredMiddleName,
        this.transactionData.insuredLastName
      ]

      return trim(fullName.join(' '))
    },
    getBeneficiaryFullName () {
      const fullName = [
        this.transactionData.beneficiaryFirstName,
        this.transactionData.beneficiaryMiddleName,
        this.transactionData.beneficiaryLastName
      ]

      return trim(fullName.join(' '))
    },
    backToMain () {
      this.$store.dispatch('insurance/resetForm')
      this.$router.push({ path: '/pera-protect' })
    }
  },
  mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Transaction Successful')
  }
}
</script>

<style scoped>
h3,
p {
  color: #4A4A4A !important;
}
.icon {
  width: 20%;
}
</style>
