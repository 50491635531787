import api from '@/api'

export default {
  retrieveData (trxDate, locationId) {
    return api.get(`/v1/maintenance/api/maintenance/adjustment-and-expenses-grid?location_id=${locationId}&trx_date=${trxDate}`)
  },
  saveData (payload) {
    return api.post('/v1/maintenance/api/maintenance/adjustment-and-expenses-insert', payload)
  },
  deleteRecord (id) {
    return api.delete(`/v1/maintenance/api/maintenance/adjustment-and-expenses-delete/${id}`)
  },
  getCount (trxDate, locationId) {
    return api.get(`/v1/maintenance/api/maintenance/adjustment-and-expenses-get-count?location_id=${locationId}&trx_date=${trxDate}`)
  },
  getTypes () {
    return api.get('/v1/maintenance/api/maintenance/petty-cash-category-grid')
  }
}
