<template>
  <v-dialog
    v-model="dialog"
    :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '60%'"
    persistent
  >
    <v-card>
      <v-card-title class="ph-dialog-title">
        Loan Details - Teacher's Loan
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col class="ph-dialog-subtitle" cols="12">
              <p class="ph-dialog-subtitle-text"><b>Please fill-out the required fields to continue</b></p>
            </v-col>
            <v-col class="py-6" cols="12" md="6">
              <v-text-field
                v-model="updateLoanData.loan_account_number"
                label="Loan Account Number"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
              />
            </v-col>
            <v-col class="py-6" cols="12" md="6">
              <v-autocomplete
                v-model="updateLoanData.loan_type"
                :items="loanTypeList"
                item-text="text"
                item-value="name"
                :return-object="false"
                label="Loan Type"
                :rules="requiredField"
                color="gray darken-1"
                class="ph-textbox"
                autofocus
                outlined
              />
            </v-col>
            <v-col class="py-6" cols="12" md="6">
              <v-text-field
                v-model="updateLoanData.loan_amount"
                label="Loan Amount"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                type="number"
                :hide-spin-buttons="true"
              />
            </v-col>
            <v-col class="py-6" cols="12" md="6">
              <v-text-field
                v-model="updateLoanData.voucher_number"
                label="Voucher Number"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                readonly
              />
            </v-col>
            <v-col class="py-6" cols="12" md="6" v-if="updateLoanData.loan_type === 'hhlreloan'">
              <v-text-field
                v-model="updateLoanData.old_balance"
                label="Old Balance"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                type="number"
                :hide-spin-buttons="true"
              />
            </v-col>
            <v-col class="py-6" cols="12" md="6" v-if="updateLoanData.loan_type === 'hhlreloan'">
              <v-text-field
                v-model="updateLoanData.hhl_balance"
                label="HHL"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                type="number"
                :hide-spin-buttons="true"
              />
            </v-col>
            <v-col class="py-6" cols="12" v-if="updateLoanData.loan_type === 'reloan'">
              <v-text-field
                v-model="updateLoanData.outstanding_balance"
                label="Outstanding Balance"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                :persistent-hint="true"
                hint="Please refer to disclosure statement"
                type="number"
                :hide-spin-buttons="true"
              />
            </v-col>
            <v-col class="py-6" cols="12" md="6">
              <v-text-field
                v-model="updateLoanData.cashout_amount"
                label="Cash Out Amount"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                type="number"
                :hide-spin-buttons="true"
              />
            </v-col>
            <v-col class="py-6" cols="12" md="6">
              <v-text-field
                v-model="updateLoanData.net_proceeds"
                label="Net Proceeds"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
                type="number"
                readonly
                :hide-spin-buttons="true"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ph-action-button"
          color="danger"
          text
          @click="closeDialog"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          class="ph-action-button ph-action-button-receive-confirm"
          elevation="0"
          @click="updateLoanDetails"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { format, parseISO, getDayOfYear } from 'date-fns'
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  name: 'PeraLoanUpdateDialog',
  props: {
    updateLoanDetailsDialog: Boolean,
    selectedTrx: Object
  },
  data () {
    return {
      dialog: false,
      updateLoanData: {},
      loanTypeList: [
        { name: 'newloan', text: 'New Loan' },
        { name: 'reloan', text: 'Re-Loan' },
        { name: 'hhlreloan', text: 'Re-Loan HHL' },
        { name: 'recaptured', text: 'Re-captured' }
      ],
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  watch: {
    updateLoanDetailsDialog: function (data) {
      this.dialog = data
    },
    updateLoanData: {
      handler (val) {
        this.calculateAmount(val.loan_type, val)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      peraLoanTransactions: 'loans/peraLoanTransactions',
      currUser: 'auth/currUser'
    })
  },
  mounted () {
    this.generateVoucherNumber()
  },
  methods: {
    renderToast,
    calculateAmount (loanType, loanAmountsData) {
      const loanAmount = loanAmountsData.loan_amount || 0
      const outstandingAmount = loanAmountsData.outstanding_balance || 0
      const hhlAmount = loanAmountsData.hhl_balance || 0

      switch (loanType) {
        case 'newloan':
        case 'recaptured':
          this.updateLoanData.net_proceeds = loanAmount * 0.94
          break
        case 'reloan':
          this.updateLoanData.net_proceeds = (loanAmount * 0.94) - outstandingAmount
          break
        case 'hhlreloan':
          this.updateLoanData.net_proceeds = ((loanAmount * 0.94) - outstandingAmount) - hhlAmount
          break
      }
    },
    generateVoucherNumber () {
      const currentYear = format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy').substring(2)
      const totalCurrentTrx = this.getTransactionCount()
      const trxCount = totalCurrentTrx + 1
      const julianDate = this.generateRefNumber(3, '0', '', this.getJulianDate(this.currUser.trx_date))
      const refNumber = this.generateRefNumber(12, '0', 'PLT' + currentYear + julianDate, trxCount)

      this.updateLoanData.voucher_number = refNumber
    },
    getJulianDate (date) {
      const currentDayCount = getDayOfYear(parseISO(date))
      return currentDayCount
    },
    getTransactionCount () {
      console.log(this.peraLoanTransactions)
      const getReleasedTrx = this.peraLoanTransactions.filter(res => res.status === '2')

      return getReleasedTrx.length > 0 ? getReleasedTrx.length : 0
    },
    generateRefNumber (_length, _char, _prefix, _suffix) {
      var _return = ''
      var _remaining = _length - (_suffix.toString().length + _prefix.toString().length)
      for (var i = 0, max = _remaining; i < max; i++) {
        _return += _char
      }
      return _prefix + _return + _suffix
    },
    closeDialog () {
      this.dialog = false
      this.$emit('update:updateLoanDetailsDialog', false)
    },
    updateLoanDetails () {
      if (this.$refs.form.validate()) {
        if (this.updateLoanData.cashout_amount > this.updateLoanData.net_proceeds) {
          this.renderToast('error', 'Validation Error', 'The Cash-Out Amount cannot be higher than Net Proceeds')
        } else {
          const loanData = this.updateLoanData
          const payload = {
            id_loan: this.selectedTrx.id_loan,
            elaacctnum: loanData.loan_account_number,
            gloantype: 'Pera Loan',
            gloanamt: loanData.loan_amount,
            gnetpro: loanData.net_proceeds,
            gcashout: loanData.cashout_amount,
            gvouchnum: loanData.voucher_number
          }

          this.$store.dispatch('loans/saveLoanInfo', payload).then(res => {
            if (res.message === 'Good') {
              this.closeDialog()
              this.renderToast('success', 'Loan Saving Success', 'The transaction was saved successfullly.')

              this.$store.dispatch('loans/getPeraLoanTrx', {
                date_from: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
                date_to: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
                location_id: this.currUser.location_id
              })
            } else {
              this.renderToast('error', 'Transaction Failed', 'Something went wrong when processing the transaction, please try again.')
            }
          }).catch(err => {
            console.log(err)
          })
        }
      } else {
        this.renderToast('error', 'Validation Error', 'You must complete the following fields')
      }
    }
  }
}
</script>
<style scoped>
.ph-dialog-subtitle {
  margin-top: 20px;
}

.ph-dialog-subtitle-text {
  margin-bottom: 0 !important;
}
</style>
