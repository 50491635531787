import numeral from 'numeral'
import CryptoJS from 'crypto-js'
import { format, differenceInYears, parse } from 'date-fns'
import store from '@/store'
import sha256 from 'crypto-js/sha256'
import { ToWords } from 'to-words'

export const addTimestamp = (dateString) => {
  const date = new Date()

  if (dateString) {
    if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(dateString)) {
      const [year, month, day] = dateString.split('-').map(item => Number(item))
      date.setFullYear(year, (month - 1), day)
    } else {
      throw new Error('The `dateString` argument must be a valid YYYY-MM-DD format')
    }
  }

  return format(date, format(date, 'yyyy-MM-dd HH:mm:ss'))
}

export const customerRiskAssessment = (customerDetails) => new Promise((resolve, reject) => {
  if (
    (String(customerDetails.risk_score) === '3' && (String(customerDetails.edd_upload_status) === '1' || String(customerDetails.edd_upload_status) === '2' || String(customerDetails.edd_upload_status) === '4')) ||
    (String(customerDetails.risk_score) === '4' && (String(customerDetails.edd_upload_status) === '0' || String(customerDetails.edd_upload_status) === '1' || String(customerDetails.edd_upload_status) === '2' || String(customerDetails.edd_upload_status) === '4'))
  ) {
    let eddStatus
    const reasons = []

    if (customerDetails.risk_criteria) {
      const riskCriteria = JSON.parse(customerDetails.risk_criteria)

      riskCriteria.forEach(item => {
        if (item.criteria !== 'Fraudsters' && item.criteria !== 'Negative List' && item.criteria !== 'PEP') {
          reasons.push(item.criteria)
        }
      })
    }

    if (String(customerDetails.edd_upload_status) === '1') eddStatus = 'Need to Upload Documents'
    if (String(customerDetails.edd_upload_status) === '2') eddStatus = 'Documents under review for compliance'
    if (String(customerDetails.edd_upload_status) === '4') eddStatus = 'Not Approve/Pending Documents'

    resolve({
      code: 'FOUND_HIGH_RISK_STOP',
      data: {
        status: eddStatus,
        risk_score: 4,
        remarks: customerDetails.compliance_edd_remarks,
        reasons: reasons.join(', ')
      }
    })
  } else {
    store.dispatch('compliance/searchPNA', {
      customer_id: customerDetails.customer_id,
      first_name: customerDetails.first_name,
      last_name: customerDetails.last_name
    })
      .then(function ({ code, result }) {
        if (code === 200) {
          if (result) {
            const searchPNA = {}
            const getSum = []

            if (result.is_negative_list) searchPNA[1] = [result.is_negative_list]
            if (result.is_fraud) searchPNA[2] = [result.is_fraud]
            if (result.is_pep) searchPNA[3] = [result.is_pep]
            if (result.last_transaction) getSum.push(result.last_transaction)

            store.commit('compliance/UPDATE_RISK_DATA', {
              searchPNA,
              getSum
            })
            if (result.is_negative_list) resolve({ code: 'FOUND_NEGATIVE_LIST', data: result.is_negative_list })
            if (result.is_fraud) resolve({ code: 'FOUND_ADVERSE', data: result.is_fraud })
            if (result.is_pep) resolve({ code: 'FOUND_PEP', data: result.is_pep })
            resolve({ code: 'SUCCESS', data: result })
          } else {
            resolve({ code: 'ERR_NO_API_RESPONSE', data: { message: 'No API Response' } })
          }
        } else {
          resolve({ code: 'ERROR_CODE', data: { code } })
        }
      })
      .catch(function (error) {
        resolve({ code: 'ERR_INTERNAL_CATCH', data: error })
      })
  }
})

export const idNumberValidator = (text, type, country) => {
  let rules = [v => !!v || 'This field is required']

  switch (type) {
    case 'Passport':
      if (country === 'Philippines') {
        rules = [
          ...rules,
          v => /^([a-zA-Z0-9]){9,9}$/.test(text) || 'Should be 9 characters and only letters/numbers are allowed'
        ]
      } else {
        rules = [
          ...rules,
          v => /^([a-zA-Z0-9]){6,9}$/.test(text) || 'Should be 6-9 characters and only letters/numbers are allowed'
        ]
      }
      break
    case 'Gov\'t Office/GOCC ID':
    case 'OWWA ID (Overseas Workers)':
    case 'Seaman\'s Book':
    case 'DSWD Certificate':
    case 'Senior Citizen Card':
    case 'Student\'s ID':
    case 'Police Clearance':
    case 'OFW ID':
    case 'Alien Cert. of Registration':
    case 'Employment ID':
    case 'Barangay Certification':
    case 'AFP ( Armed Forces of the Philippines ID)':
    case 'PRPWD (Philippine Registry for Persons with Disabilities)':
      rules = [
        ...rules,
        v => /^[a-zA-Z0-9]*$/.test(text) || 'Only letters/numbers are allowed'
      ]
      break
    case 'Driver\'s license':
      rules = [
        ...rules,
        v => /^([a-zA-Z0-9]){11,11}$/.test(text) || 'Should be 11 characters and only letters/numbers are allowed'
      ]
      break
    case 'Postal ID':
      rules = [
        ...rules,
        v => /^([a-zA-Z0-9]){12,12}$/.test(text) || 'Should be 12 characters and only letters/numbers are allowed'
      ]
      break
    case 'NBI Clearance':
      rules = [
        ...rules,
        v => /^([a-zA-Z0-9]){18,20}$/.test(text) || 'Should be 18-20 characters and only letters/numbers are allowed'
      ]
      break
    case 'Voter\'s ID':
      rules = [
        ...rules,
        v => /^([a-zA-Z0-9]){16,25}$/.test(text) || 'Should be 16-25 characters and only letters/numbers are allowed'
      ]
      break
    case 'GSIS e-Card':
      rules = [
        ...rules,
        v => /^([0-9]){11,11}$/.test(text) || 'Should be 11 characters and only numbers are allowed'
      ]
      break
    case 'Disabled Person Cert/ID NCWDP':
    case 'Unified MultiPurpose ID (UMID)':
    case 'PAG IBIG ID': // not on kycid
    case 'PhilHealth': // not on kycid
    case 'National Council for the Welfare': // not on kycid
      rules = [
        ...rules,
        v => /^([0-9]){12,12}$/.test(text) || 'Should be 12 characters and only numbers are allowed'
      ]
      break
    case 'PRC ID (Prof. Reg. Commission)':
      rules = [
        ...rules,
        v => /^([0-9]){7,7}$/.test(text) || 'Should be 7 characters and only numbers are allowed'
      ]
      break
    case 'Integrated Bar (IBP) ID':
      rules = [
        ...rules,
        v => /^([0-9]){5,5}$/.test(text) || 'Should be 5 characters and only numbers are allowed'
      ]
      break
    case 'SSS Card (Social Security)':
      rules = [
        ...rules,
        v => /^(01|02|03|04|05|06|07|08|09|10|33|34)([0-9]){8,8}$/.test(text) || 'Should begin with 01, 02, 03, 04, 05, 06, 07, 08, 09, 10, 33 or 34, must be 10 characters and only numbers are allowed'
      ]
      break
    case 'Taxpayer\'s ID (TIN ID)':
      rules = [
        ...rules,
        v => /^(([0-9]){12,12}|([0-9]){9,9})$/.test(text) || 'Should be 9 or 12 characters and only numbers are allowed'
      ]
      break
    case 'PhilSys (Philippine Identification System ID)':
      rules = [
        ...rules,
        v => /^([0-9]){16,16}$/.test(text) || 'Should be 16 characters and only numbers are allowed'
      ]
      break
  }

  return rules
}

const toWordsLib = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true
  }
})

const SHARED_KEY = process.env.VUE_APP_PRE_SHARED_KEY || ''
const susi = 'wlK7ZRNOBimj74oV9bqowfVPSqq+QD6t1YRwVWQMsHA='

export const createSignature = (payload, timestamp) => {
  if (SHARED_KEY && timestamp) {
    if (payload) {
      if (typeof payload === 'object') {
        payload = JSON.stringify(payload)
      }

      return sha256(timestamp + SHARED_KEY + payload).toString()
    } else {
      return sha256(timestamp + SHARED_KEY).toString()
    }
  }

  return ''
}

export const aesEncrypt = (string) => {
  return CryptoJS.AES.encrypt(string, susi).toString()
}

export const aesDecrypt = (string) => {
  return CryptoJS.AES.decrypt(string, susi).toString(CryptoJS.enc.Utf8)
}

export const toMoney = (number, currency) => {
  if (currency) {
    return `${currency} ${numeral(number).format('0,0.00')}`
  }
  return `₱ ${numeral(number).format('0,0.00')}`
}

export const toProperCase = (string) => {
  if (typeof string === 'string') {
    string = string.toLowerCase()
    return string.replace(/(^|[\s\xA0])[^\s\xA0]/g, function (s) { return s.toUpperCase() })
  }

  return string
}

export const trim = (string) => {
  return string.replace(/(^\s+|\s+$)/g, '')
}

export const getStoragePath = (customerID, fileName) => {
  return `${CryptoJS.AES.encrypt(process.env.VUE_APP_STORAGE_BASE_URL, susi).toString()}/${customerID}/${fileName}`
}

export const avatarColor = (fullname, ligthness = 40, saturation = 75) => {
  let hash = 0

  if (fullname) {
    for (let i = 0; i < fullname.length; i++) {
      hash = fullname.charCodeAt(i) + ((hash << 5) - hash)
    }
    const hue = hash % 360
    return `background-color: hsl(${hue}, ${saturation}%, ${ligthness}%) !important`
  }
  return ''
}

export const avatarInitial = (fullname) => {
  let initial = ''

  if (fullname) {
    let name = ''

    fullname = fullname.toUpperCase()

    if (fullname.indexOf(',') !== -1) {
      fullname = fullname.split(',')
      name = fullname[1]?.replace(/(^\s+|\s+$)/g, '')
    } else {
      name = fullname
    }

    initial = name.substr(0, 1)
  }
  return initial
}

export const isValidEmail = (email) => {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regEx.test(String(email).toLowerCase())
}

export const isValidMobileNumber = (mobileNumber) => {
  const regEx = /^(09)\d{9}$/
  return regEx.test(String(mobileNumber))
}

export const isValidLandline = (landlineNumber) => {
  const regEx = /^\d{0,4}\d{7,8}$/
  return regEx.test(String(landlineNumber))
}

export const isValidDate = (dateString) => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/
  if (!dateString.match(regEx)) return false
  var d = new Date(dateString)
  return d.toISOString().slice(0, 10) === dateString
}

export const getStatusText = (code) => {
  if (code === 0 || code === '0') return 'Pending'
  if (code === 1 || code === '1') return 'Successful'
  return 'Unknown'
}

export const getDataURL = (file) => new Promise((resolve) => {
  const reader = new FileReader()

  reader.addEventListener('load', (e) => {
    resolve(e.target.result)
  })

  reader.readAsDataURL(file)
})

export const getForeignRefNo = () => {
  const currentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
  const convertToString = CryptoJS.MD5(currentDate).toString()
  const foreignRefNo = convertToString.substring(0, 20)

  return foreignRefNo
}

export const convertToRealAmount = (amount) => {
  const convertedAmount = amount / 100
  return convertedAmount
}

export const formatTime = (time) => {
  var timeParts = time.split(/(\d{1,2})(\d{1,2})/)
  var subtimeParts = timeParts[3].split(' ')
  var timeInd = subtimeParts[0] === 'A' ? 'AM ' : 'PM '
  return timeParts[1] + ':' + timeParts[2] + ' ' + timeInd + subtimeParts[1]
}

export const formatMTCN = (mtcn) => {
  var mtcnParts = mtcn.split(/(\d{1,3})(\d{1,3})(\d{4})/)
  return mtcnParts[1] + '-' + mtcnParts[2] + '-' + mtcnParts[3]
}

export const renderToast = (type, title, description) => {
  store.commit('app/SET_SNACKBAR_STATE', {
    renderSnackbar: true,
    snackbarColor: type,
    snackbarTitle: title,
    snackbarText: description
  })
}

export const getAge = (birthDate) => {
  const getBirthDate = parse(birthDate, 'yyyy-MM-dd', new Date())
  const getCurrentAge = differenceInYears(new Date(), getBirthDate)
  return getCurrentAge
}

export const renderAVPSpiel = (spielData) => {
  store.commit('app/SET_AVP_SPIEL_STATE', {
    renderSpielModal: true,
    offer_one: spielData.offer_one,
    offer_two: spielData.offer_two,
    product_line: spielData.product_line
  })
}

export const formatWUName = (wuName) => {
  let fullName = ''

  switch (wuName.name_type) {
    case 'D':
      fullName = `${wuName.first_name} ${(wuName?.middle_name) ? wuName.middle_name + '' : ''} ${wuName.last_name}`
      break
    case 'M':
      fullName = `${wuName.given_name} ${(wuName?.paternal_name) ? wuName.paternal_name + '' : ''} ${wuName.maternal_name}`
      break
    case 'C':
      fullName = `${wuName.business_name} ${wuName.attention}`
      break
    default:
      fullName = `${wuName.first_name} ${(wuName?.middle_name) ? wuName.middle_name + '' : ''} ${wuName.last_name}`
  }

  return fullName
}

export const formatWULastName = (wuName) => {
  let lastName = ''

  switch (wuName.name_type) {
    case 'D':
      lastName = wuName.last_name
      break
    case 'M':
      lastName = wuName.maternal_name
      break
    case 'C':
      lastName = wuName.attention ? wuName.attention : ''
      break
    default:
      lastName = wuName.last_name
  }

  return lastName
}

export const formatWUFirstName = (wuName) => {
  let firstName = ''

  switch (wuName.name_type) {
    case 'D':
      firstName = wuName.first_name
      break
    case 'M':
      firstName = wuName.given_name
      break
    case 'C':
      firstName = wuName.business_name
      break
    default:
      firstName = wuName.first_name
  }

  return firstName
}

export const formatWUMiddleName = (wuName) => {
  let middleName = ''

  switch (wuName.name_type) {
    case 'D':
      middleName = (wuName?.middle_name) ? wuName.middle_name + ' ' : ''
      break
    case 'M':
      middleName = (wuName?.paternal_name) ? wuName.paternal_name + ' ' : ''
      break
    case 'C':
      middleName = ''
      break
    default:
      middleName = (wuName?.middle_name) ? wuName.middle_name + ' ' : ''
  }

  return middleName
}

export const getInsuranceName = (category) => {
  const insurances = {
    COC: 'Accident Protect',
    COC3: 'Dengue Protect Plus',
    COC4: 'Pera Payapa',
    COC6: 'Covid Protect Plus',
    COC8: 'Dengue Protect v2'
  }

  return insurances[category]
}

export const checkProperty = (data, subItems) => {
  const splitSubItems = subItems.split('.')
  let isExists = false

  splitSubItems.forEach((res, index) => {
    if (Object.prototype.hasOwnProperty.call(data, res)) {
      isExists = true
    } else {
      isExists = false
    }
  })

  return isExists
}

export const toWords = (amount, currency = 'PHP') => {
  if (amount) {
    if (currency === 'PHP') {
      let words = toWordsLib.convert(amount)
      words = words.replace(/Dollars/g, 'Pesos')
      words = words.replace(/Cents/g, 'Centavos')

      return words
    }

    return toWordsLib.convert(amount)
  }

  return ''
}

export const getNonexRefNumber = () => {
  const currentDate = format(new Date(), 'yyyyMMdd')
  const randNum = Math.floor(10000000 + Math.random() * 90000000)
  const nonexRefNumber = `${currentDate}PHB0${randNum}`

  return nonexRefNumber
}

export const getFileExtension = (fileName) => {
  const parts = fileName.split('.')

  return parts[parts.length - 1].toLowerCase()
}

export const getTransactionLimitStatus = (code) => {
  if (code === 1) {
    return { class: 'red--text', text: 'Filed' }
  } else if (code === 2) {
    return { class: 'purple--text', text: 'Reviewed' }
  } else if (code === 3) {
    return { class: 'green--text', text: 'Approved' }
  } else if (code === 4) {
    return { class: 'red--text', text: 'Disapproved' }
  } else if (code === 5) {
    return { class: 'orange--text', text: 'For Re-Approval' }
  } else {
    return { class: '', text: '' }
  }
}

export const camelCaseToSnakeCase = (str) => {
  return str.replace(/[A-Z]/g, char => `_${char.toLowerCase()}`)
}

export const getCivilStatusCode = (civilStatus) => {
  let civilStatusValue = ''

  switch (civilStatus) {
    case 'Single':
      civilStatusValue = 'S'
      break
    case 'Married':
      civilStatusValue = 'M'
      break
    case 'Widow/Er':
      civilStatusValue = 'W'
      break
    case 'Separated':
      civilStatusValue = 'D'
      break
    case 'Divorced':
      civilStatusValue = 'D'
      break
  }

  return civilStatusValue
}

export const getGenderCode = (gender) => {
  let genderValue = ''

  switch (gender) {
    case 'male':
      genderValue = 'M'
      break
    case 'female':
      genderValue = 'F'
      break
    default:
      genderValue = ''
      break
  }

  return genderValue
}
