import { endpoints } from '@/api/endpoints'

const billers = endpoints.use('billers')

export default {
  getBillers: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.getBillers(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getCategories: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.getCategories(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  addBiller: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.addBiller(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  updateBiller: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.updateBiller(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  deleteBiller: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.deleteBiller(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  switchBillerStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.switchBillerStatus(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getPartners: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.getPartners(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  addPartner: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.addPartner(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  updatePartner: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.updatePartner(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  deletePartner: ({ commit }, id) => new Promise((resolve, reject) => {
    billers.deletePartner(id)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  switchPartnerStatus: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.switchPartnerStatus(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getCommonBillers: ({ commit }) => new Promise((resolve, reject) => {
    billers.getCommonBillers()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  addCommonBiller: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.addCommonBiller(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  updateCommonBiller: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.updateCommonBiller(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  deleteCommonBiller: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.deleteCommonBiller(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  getApprovalRequests: ({ commit }) => new Promise((resolve, reject) => {
    billers.getApprovalRequests()
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  approveRequest: ({ commit }, payload) => new Promise((resolve, reject) => {
    billers.approveRequest(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),

  disapproveRequest: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
    billers.disapproveRequest(id, payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
