<template>
  <v-container fluid>
    <CustomerSearch v-if="currentPage === 0" />
    <NewTransaction v-if="currentPage === 1" />
    <SuccessfulTransaction v-if="currentPage === 2" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerSearch from '@/components/moneyChange/CustomerSearch.vue'
import NewTransaction from '@/components/moneyChange/NewTransaction.vue'
import SuccessfulTransaction from '@/components/moneyChange/SuccessfulTransaction.vue'

export default {
  computed: {
    ...mapGetters({
      currentPage: 'moneyChange/currentPage'
    })
  },
  components: {
    CustomerSearch,
    NewTransaction,
    SuccessfulTransaction
  },
  beforeMount () {
    const initialValues = [
      { prop: 'type', value: 'SELL' },
      { prop: 'receiveCurrency', value: 'PHP' },
      { prop: 'releaseCurrency', value: 'USD' }
    ]

    initialValues.map(payload => this.$store.commit('moneyChange/SET_TRANSACTION_DATA', payload))
  }
}
</script>
