<template>
  <div class="ph-wu-search-page-container">
    <v-form ref="searchFulfill" @submit.prevent="searchWUTransaction">
      <v-row class="ph-wu-search-container">
        <v-col cols="12" v-if="showRemoteNotice">
          <v-alert
            color="info"
            dark
            icon="mdi-remote"
            border="left"
            prominent
          >
            This transaction will be processed on behalf of <b>{{remoteData.remote_location.location_name}}</b> branch.
          </v-alert>
        </v-col>
        <v-col cols="12" md="4" class="ph-wu-search-header">
          <h1 class="ph-wu-form-title">Receive Money Fulfill</h1>
          <router-link class="ph-wu-form-subtitle" to="/pera-remit/remote-location?trx_type=POF">Fulfil transaction from a Different Location</router-link>
        </v-col>
        <v-col cols="12" md="8" class="ph-wu-search-form">
          <div class="ph-wu-search-fields ph-wu-search-mobile-phone">
            <v-select
              v-model="mobileCountryCode"
              placeholder="+XXX"
              :items="collections.countries"
              :rules="requiredField"
              :item-text="item =>`${item.mobile_code} - ${item.country}`"
              item-value="mobile_code"
              color="gray darken-1"
              class="ph-textbox ph-textbox-mobile-country"
              outlined
            >
              <template v-slot:selection="{ item }">
                <p><div class="flag">{{item.two_iso_code === 'TP' ? countryCodeEmoji('TL') : countryCodeEmoji(item.two_iso_code)}}</div>&nbsp;{{ item.mobile_code }}
              </template>
              <template v-slot:item="{ item }">
                <p><div class="flag">{{item.two_iso_code === 'TP' ? countryCodeEmoji('TL') : countryCodeEmoji(item.two_iso_code)}}</div>&nbsp;{{ item.mobile_code }} - {{ item.country }}
              </template>
            </v-select>
            <v-text-field
              v-model="mobileNo"
              class="ph-textbox ph-textbox-mobile-phone-number"
              single-line
              outlined
              placeholder="Mobile Number"
              persistent-hint
              :rules="requiredField"
              maxlength="10"
            >
            </v-text-field>
          </div>
          <v-row class="ph-wu-search-fields">
            <v-col cols="12" md="2" class="ph-wu-currency-select-title">
              <b>Pay By:</b>
            </v-col>
            <v-col cols="12" md="10" class="ph-wu-currency-selector">
              <v-radio-group
                v-model="currency"
                mandatory
                row
              >
                <v-radio
                  label="PHP"
                  value="PHP"
                ></v-radio>
                <v-radio
                  label="USD"
                  value="USD"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="ph-wu-search-fields ph-wu-customer-search">
            <v-col cols="12">
              <b>Customer's name</b>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="first_name"
                class="ph-textbox"
                single-line
                outlined
                placeholder="First Name"
                persistent-hint
                :rules="requiredField"
                @keyup.enter="searchFulfillTransaction"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                  v-model="last_name"
                  class="ph-textbox"
                  single-line
                  outlined
                  placeholder="Last Name"
                  persistent-hint
                  :rules="requiredField"
                  @keyup.enter="searchFulfillTransaction"
                >
                </v-text-field>
            </v-col>
          </v-row>
          <v-row class="ph-wu-search-fields ph-wu-action-container">
            <v-col cols="12" class="ph-wu-action-item">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                elevation="0"
                class="ph-primary-button ph-primary-execute-btn"
                @click="searchFulfillTransaction"
                :disabled="!mobileNo || !first_name || !last_name"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="ph-wu-fulfill-search-multiple" v-if="fulfilList.length > 0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <b>Sender</b>
                  </th>
                  <th class="text-left">
                    <b>Receiver</b>
                  </th>
                  <th class="text-left">
                    <b>Amount</b>
                  </th>
                  <th class="text-left">
                    <b>Country Originated</b>
                  </th>
                  <th class="text-left">
                    <b>Action</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in fulfilList"
                  :key="item.name"
                >
                  <td>
                    <div class="ph-pay-status-sender-container">
                      <v-avatar class="mr-1 ph-pay-status-status-circle" size="25px" :style="avatarColor(item.sender_name)">
                        <span class="white--text text-body-2">{{ avatarInitial(item.sender_name) }}</span>
                      </v-avatar>
                      <p class="ph-pay-status-table-names-text">{{item.sender_name}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="ph-pay-status-receiver-container">
                      <v-avatar class="mr-1 ph-pay-status-status-circle" size="25px" :style="avatarColor(item.receiver_name)">
                        <span class="white--text text-body-2">{{ avatarInitial(item.receiver_name) }}</span>
                      </v-avatar>
                      <p class="ph-pay-status-table-names-text">{{item.receiver_name}}</p>
                    </div>
                  </td>
                  <td>{{ item.amount }}</td>
                  <td>{{ item.originating_country }}</td>
                  <td>
                    <v-btn
                      @click="selectTransaction(item.new_mtcn)"
                      primary
                      rounded
                      elevation="0"
                      class="ph-action-button"
                    >Fulfill</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getForeignRefNo, convertToRealAmount, avatarColor, avatarInitial, renderToast } from '@/utils'
import numeral from 'numeral'
import { countryCodeEmoji } from 'country-code-emoji'

export default {
  name: 'ReceiveMoneyFulfillSearch',
  data () {
    return {
      foreignRefNo: '',
      first_name: '',
      last_name: '',
      mobileNo: '',
      currency: '',
      fulfilList: [],
      terminalId: '',
      operatorId: '',
      locationId: '',
      showRemoteNotice: false,
      mobileCountryCode: '+63',
      requiredField: [
        v => !!v || 'This field is required'
      ]
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections',
      remoteData: 'obo/remoteData',
      selectedCustomer: 'customers/selectedCustomer',
      currentReceiveFulfillTrx: 'wu/currentReceiveFulfillTrx'
    })
  },
  watch: {
    selectedCustomer: {
      handler (data) {
        if (data) {
          this.renderToast('info', 'Customer Selected!', `${data.full_name} has been successfully selected.`)
          this.$store.commit('wu/SET_SHOW_PAYOUT_FULFILL_SEARCH_FORM', false)
          this.$store.commit('wu/SET_SHOW_PAYOUT_FULFILL_RESULT_FORM', true)
        } else {
          this.$store.commit('wu/SET_SHOW_PAYOUT_FULFILL_SEARCH_FORM', true)
          this.$store.commit('wu/SET_SHOW_PAYOUT_FULFILL_RESULT_FORM', false)
        }
      },
      deep: true
    }
  },
  mounted () {
    this.foreignRefNo = this.getForeignRefNo()
    this.$store.commit('wu/SET_RECEIVE_FULFILL_FOREIGN_REF_NO', this.foreignRefNo)

    if (this.selectedCustomer && this.currentReceiveFulfillTrx?.result) {
      this.renderToast('info', 'Customer Selected!', `${this.selectedCustomer.full_name} has been successfully selected.`)
      this.$store.commit('wu/SET_SHOW_PAYOUT_FULFILL_SEARCH_FORM', false)
      this.$store.commit('wu/SET_SHOW_PAYOUT_FULFILL_RESULT_FORM', true)
    }

    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData.remote_location.location_id

      this.showRemoteNotice = true

      this.renderToast('info', 'Remote Transaction is Enabled', `This transaction will be processed on behalf of ${this.remoteData.remote_location.location_name} branch.`)
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id

      this.showRemoteNotice = false

      this.renderToast('warning', 'Remote Transaction data cannot be retrived', 'The remote transaction data cannot be found. Normal transaction settings will be applied for this transaction instead.')
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
      this.showRemoteNotice = false
    }
  },
  methods: {
    getForeignRefNo,
    convertToRealAmount,
    avatarColor,
    avatarInitial,
    renderToast,
    countryCodeEmoji,
    async searchFulfillTransaction () {
      if (!this.mobileNo || !this.first_name || !this.last_name) {
        this.renderToast('error', 'Search Error', 'The required fields must be completed.')
      } else {
        this.$store.commit('wu/SET_PAYOUT_FULFILL_CURRENT_CURRENCY', this.currency)
        const payload = {
          contact_phone: this.mobileCountryCode.substring(1) + this.mobileNo,
          pd_dest_currency_code: this.currency,
          foreign_reference_no: this.foreignRefNo,
          terminal_id: this.terminalId,
          operator_id: this.operatorId,
          location_id: this.locationId,
          transaction_type: 'PO'
        }

        await this.$store.dispatch('getIDList', 'wu')
        await this.$store.dispatch('wu/getFulfillSearchResult', payload).then(res => {
          const fulfillDetails = res.result
          // Check if fulfill search result has multiple results
          if (res?.result && Object.prototype.hasOwnProperty.call(fulfillDetails, 'receivemoney_hold_transactions')) {
            const holdTransactions = fulfillDetails.receivemoney_hold_transactions.receivemoney_hold_transaction

            this.fulfilList = []
            let originatingCountry = ''

            if (typeof holdTransactions === 'object' && holdTransactions.payment_details?.originating_country_currency) {
              this.collections.countries.filter(data => {
                if (data.two_iso_code === holdTransactions.payment_details.originating_country_currency.iso_code.country_code) {
                  originatingCountry = data.country
                }
              })

              this.fulfilList.push({
                sender_name: `${holdTransactions.sender.name.first_name} ${holdTransactions.sender.name.last_name}`,
                receiver_name: `${holdTransactions.receiver.name.first_name} ${holdTransactions.receiver.name.last_name}`,
                amount: `${holdTransactions.payment_details.originating_country_currency.iso_code.currency_code} ${numeral(this.convertToRealAmount(holdTransactions.financials.originators_principal_amount)).format('0, 0.00')}`,
                originating_country: originatingCountry,
                new_mtcn: holdTransactions.new_mtcn
              })

              this.selectTransaction(holdTransactions.new_mtcn)
            } else {
              holdTransactions.forEach(item => {
                this.collections.countries.filter(data => {
                  if (data.two_iso_code === item.payment_details.originating_country_currency.iso_code.country_code) {
                    originatingCountry = data.country
                  }
                })

                this.fulfilList.push({
                  sender_name: `${item.sender.name.first_name} ${item.sender.name.last_name}`,
                  receiver_name: `${item.receiver.name.first_name} ${item.receiver.name.last_name}`,
                  amount: `${item.payment_details.originating_country_currency.iso_code.currency_code} ${numeral(this.convertToRealAmount(item.financials.originators_principal_amount)).format('0, 0.00')}`,
                  originating_country: originatingCountry,
                  new_mtcn: item.new_mtcn
                })
              })
            }
          } else if (fulfillDetails?.receivemoney_hold_transaction) {
            this.populateTransaction(fulfillDetails.receivemoney_hold_transaction)
          } else {
            this.renderToast('error', 'WU Error', res?.message ? res.message : 'No Transaction Found')
          }
        }).catch(err => {
          console.log(err)
          this.renderToast('error', 'WU Error', err.data.message)
        })
      }
    },
    async searchCustomer (customerName) {
      this.$store.dispatch('customers/doSearch', customerName).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    selectTransaction (newMTCN) {
      const payload = {
        foreign_reference_no: this.foreignRefNo,
        new_mtcn: newMTCN,
        terminal_id: this.terminalId,
        operator_id: this.operatorId,
        location_id: this.locationId,
        transaction_type: 'PO',
        pd_dest_currency_code: this.currency
      }

      this.$store.dispatch('wu/selectFulfillTransaction', payload).then(res => {
        console.log(res)
        this.populateTransaction(res.result.receivemoney_hold_transaction)
      }).catch(err => {
        console.log(err)
      })
    },
    async populateTransaction (item) {
      const customerName = `${this.last_name}, ${this.first_name}`
      await this.$store.dispatch('customers/doSearch', customerName)
      this.$store.commit('app/TOGGLE_LOADING', false)
      this.$store.commit('wu/SET_CURRENT_PAYOUT_FULFILL_TRANSACTION', item)
    }
  }
}
</script>
<style scoped>
  .ph-pay-status-sender-container,
  .ph-pay-status-receiver-container {
    display: flex;
  }

  .ph-pay-status-table-avatar {
    margin: auto 0;
  }

  .ph-pay-status-table-names-text {
    margin: auto;
    margin-left: 10px;
  }

  .ph-pay-status-status-circle {
    height: 10px;
    width: 10px;
    background: #f5f5f5;
    border-radius: 50%;
    margin: auto 0;
  }

  .ph-wu-form-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-wu-search-header {
    margin-top: 10px;
  }

  .ph-wu-search-fields {
    margin-top: 20px;
  }

  .ph-wu-currency-select-title {
    font-family: 'Proxima Nova Bold';
    padding-top: 18px;
  }

  .ph-wu-currency-selector .v-input {
    margin-top: 0
  }

  .ph-wu-customer-search {
    margin-top: 0;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    font-size: 14px;
  }

  .v-btn.ph-action-button {
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    background: #1A2791 !important;
    color: #fff;
    letter-spacing: 1px;
    font-size: 14px;
  }

  .ph-wu-action-item {
    display: flex;
  }

  .flag {
    font-family: 'Noto Emoji';
  }

  .test {
    font-variant: small-caps;
  }

  .ph-wu-search-mobile-phone {
    display: flex;
    flex-direction: row;
  }

  .ph-textbox-mobile-country {
    width: 55%;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .ph-textbox-mobile-phone-number {
    width: 70%;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: 1.5px solid;
    border-image: linear-gradient(transparent 10%, #CECED4 10% 90%, transparent 90%) 0 0 0 1 / 3px;
  }

  @media only screen and (min-width: 600px) {
    .ph-primary-button {
      width: 160px;
      margin-left: auto;
    }

    .ph-textbox-mobile-country {
      width: 30%;
    }

    .ph-textbox-mobile-phone-number {
      width: 80%;
    }
  }

  @media only screen and (min-width: 1200px) {
    .ph-textbox-mobile-country {
      width: 19%;
    }

    .ph-textbox-mobile-phone-number {
      width: 81%;
    }
  }
</style>
