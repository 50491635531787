import { endpoints } from '@/api/endpoints'

const interBranchTransfer = endpoints.use('interBranchTransfer')

export default {
  getIBTList: ({ commit }, payload) => new Promise((resolve, reject) => {
    interBranchTransfer.getIBTList(payload)
      .then(({ data }) => {
        commit('SET_INTER_BRANCH_LIST', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getIBTApprovalList: ({ commit }, payload) => new Promise((resolve, reject) => {
    console.log(payload)
    interBranchTransfer.getIBTApprovalList(payload)
      .then(({ data }) => {
        commit('SET_INTER_BRANCH_APPROVAL_LIST', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getBorrowerBank: ({ commit }, payload) => new Promise((resolve, reject) => {
    interBranchTransfer.getBorrowerBank(payload)
      .then(({ data }) => {
        commit('SET_BORROWER_BANKS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getUsersOnBorrowerLocations: ({ commit }, payload) => new Promise((resolve, reject) => {
    interBranchTransfer.getUsersOnBorrowerLocations(payload)
      .then(({ data }) => {
        commit('SET_USERS_BY_BORROWER_LOCATION', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getReasonList: ({ commit }, payload) => new Promise((resolve, reject) => {
    interBranchTransfer.getReasonList(payload)
      .then(({ data }) => {
        commit('SET_REASON_LIST', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  requestTransfer: ({ commit }, payload) => new Promise((resolve, reject) => {
    interBranchTransfer.requestTransfer(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  requestApprovalAction: ({ commit }, payload) => new Promise((resolve, reject) => {
    interBranchTransfer.requestApprovalAction(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  tagAsReleased: ({ commit }, payload) => new Promise((resolve, reject) => {
    interBranchTransfer.tagAsReleased(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  tagAsReceived: ({ commit }, payload) => new Promise((resolve, reject) => {
    interBranchTransfer.tagAsReceived(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  requestSettlement: ({ commit }, payload) => new Promise((resolve, reject) => {
    console.log(payload)
    interBranchTransfer.requestSettlement(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getReceiptData: ({ commit }, payload) => new Promise((resolve, reject) => {
    interBranchTransfer.getReceiptData(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getIBTCount: ({ commit }, payload) => new Promise((resolve, reject) => {
    interBranchTransfer.getIBTCount(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}
