<template>
  <v-row>
      <v-col cols="12" md="4" lg="4" v-for="rhData in rhDatas" :key="rhData.location_name">
        <v-card>
          <v-avatar
          size="58"
          class="ph-nonex-button-icon">
          <div class="ph-nonex-button-icon-container">
            <img :src="require('../../assets/images/nonex-status-icon.svg')" />
          </div>
        </v-avatar>
          <v-card-text>
            Location Name <p>{{ rhData.location_name }}</p>
            Total Count <p>{{ rhData.total_trx }}</p>
            Total Amount <p>{{ rhData.total_amount }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RHDataComponent',
  computed: {
    ...mapGetters({
      rhDatas: 'report/rhDatas',
      currUser: 'auth/currUser'
    })
  },
  mounted () {
    this.$store.dispatch('report/getRptTotalRH', {
      trxDate: this.currUser.trx_date,
      groupLocation: this.currUser.location_group_id
    })
  }
}
</script>
<style scoped>
p {
  font-family: 'Baloo Extra Bold';
  font-size: 20px;
  font-weight: bolder;
  color: #1A2791;
}

.ph-nonex-button-icon-container {
  padding: 15px;
  width: 100%;
  display: flex;
}

.ph-nonex-button-icon {
    margin-left: 20px;
    margin-top: 20px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  }
</style>
