<template>
  <v-app-bar class="top-bar px-md-4" color="white" height="70px" flat app>
    <v-app-bar-nav-icon @click="toggleSidebar"></v-app-bar-nav-icon>

    <v-toolbar-title class="ph-navbar-title text-h6 primary--text pl-2">
      {{ pageTitle }}
    </v-toolbar-title>
    <v-spacer />
    <div class="ph-stats-container">
      <v-list-item>
        <v-list-item-action class="ph-stats-icons">
          <v-tooltip bottom color="error"  v-if="!isCurrentDate">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="error"
                dark
                v-bind="attrs"
                v-on="on"
                @click="confirmCloseBranch"
              >
                <v-icon>mdi-alert-circle</v-icon>
              </v-btn>
            </template>
            <span>The transaction date is outdated. You need to close transaction date first.</span>
          </v-tooltip>
          <v-icon v-if="isCurrentDate">mdi-calendar-range</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Transaction Date</v-list-item-title>
          <v-list-item-subtitle>{{formatDateTime(currUser.trx_date)}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="$route.name !== 'Dashboard'">
        <v-list-item-action class="ph-stats-icons">
          <v-icon>mdi-currency-php</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>PHP Balance</v-list-item-title>
          <v-list-item-subtitle>{{phpBalance !== '' ? formatAmount(phpBalance) : 'Calculating...'}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="$route.name !== 'Dashboard'">
        <v-list-item-action class="ph-stats-icons">
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>USD Balance</v-list-item-title>
          <v-list-item-subtitle>{{usdBalance !== '' ? formatAmount(usdBalance) : 'Calculating...'}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-btn class="mr-2" icon>
      <v-icon size="20px">mdi-bell</v-icon>
    </v-btn>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :max-width="$vuetify.breakpoint.width >= 380 ? 380 : 300"
      :nudge-width="$vuetify.breakpoint.width >= 380 ? 380 : 300"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar size="36px" :style="avatarColor(userFullName)">
            <span class="white--text text-h6">{{ avatarInitial(userFullName) }}</span>
          </v-avatar>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar size="36px" :style="avatarColor(userFullName)">
                <span class="white--text text-h6">{{ avatarInitial(userFullName) }}</span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{userFullName}}</v-list-item-title>
              <v-list-item-subtitle>{{currUser.location_name}} Branch</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list v-if="!isCurrentDate">
          <v-list-item>
            <v-alert
              prominent
              icon="mdi-alert-circle"
              type="error"
            >
              <v-row align="center" class="ph-stats-popover-error">
                <v-col class="grow">
                  The transaction date is outdated. You need to close transaction date first.
                </v-col>
                <v-col class="shrink ph-stats-popover-error-action">
                  <v-btn @click="confirmCloseBranch">Close Transaction Date</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-list-item>
        </v-list>
        <v-list v-if="isCurrentDate">
          <v-list-item>
            <v-alert
              prominent
              icon="mdi-checkbox-marked-circle"
              type="success"
              class="ph-stats-popover-success-container"
            >
              <v-row align="center" class="ph-stats-popover-success">
                <v-col cols="12">
                  Transaction date is up-to-date.
                </v-col>
              </v-row>
            </v-alert>
          </v-list-item>
        </v-list>
        <v-list class="ph-stats-mobile">
          <v-list-item>
            <v-list-item-action class="ph-stats-icons">
              <v-icon>mdi-calendar-range</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Transaction Date</v-list-item-title>
              <v-list-item-subtitle>{{formatDateTime(currUser.trx_date)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="$route.name !== 'Dashboard' && phpBalance !== ''">
            <v-list-item-action class="ph-stats-icons">
              <v-icon>mdi-currency-php</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>PHP Balance</v-list-item-title>
              <v-list-item-subtitle>{{formatAmount(phpBalance)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="$route.name !== 'Dashboard' && usdBalance !== ''">
            <v-list-item-action class="ph-stats-icons">
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>USD Balance</v-list-item-title>
              <v-list-item-subtitle>{{formatAmount(usdBalance)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn
            text
            @click="menu = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            to="/account"
          >
            View Account
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Close Transaction Date
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to close transaction date?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="dialog = false"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="closeBranch"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import { avatarColor, avatarInitial, renderToast } from '@/utils'
import { format, parseISO } from 'date-fns'
import numeral from 'numeral'

export default {
  data () {
    return {
      miniSidebar: true,
      sidebar: false,
      phpBalance: '',
      usdBalance: '',
      isCurrentDate: false,
      menu: false,
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      pageTitle: 'pageTitle',
      getMiniState: 'app/getMiniState',
      getDrawerState: 'app/getDrawerState',
      currUser: 'auth/currUser',
      userBalanceDatas: 'report/userBalanceDatas',
      closeList: 'closeBranch/closeList',
      excessFundInfo: 'closeBranch/excessFundInfo'
    }),
    userFullName () {
      return `${this.currUser.first_name} ${this.currUser.last_name}`
    }
  },
  watch: {
    getMiniState: {
      handler (getState) {
        this.miniSidebar = getState
      },
      deep: true
    },
    getDrawerState: {
      handler (getState) {
        this.sidebar = getState
      },
      deep: true
    },
    userBalanceDatas: {
      handler (getState) {
        getState.forEach(item => {
          if (item.currency_code === 'PHP') {
            this.phpBalance = item.ending_balance
          }

          if (item.currency_code === 'USD') {
            this.usdBalance = item.ending_balance
          }
        })
      },
      deep: true
    }
  },
  mounted () {
    console.log(this.getMiniState)
    this.miniSidebar = this.getMiniState
    this.sidebar = this.getDrawerState

    const currentDate = format(new Date(), 'yyyy-MM-dd')

    if (currentDate === this.formatDateTime(this.currUser.trx_date)) {
      this.isCurrentDate = true
    } else {
      this.isCurrentDate = false
    }

    this.$store.dispatch('report/getRptUserBalanceTotal', {
      locationID: this.currUser.location_id,
      trxDate: this.currUser.trx_date,
      userID: this.currUser.user_id
    })
  },
  methods: {
    avatarColor,
    avatarInitial,
    renderToast,
    confirmCloseBranch () {
      let closeHitCount = 0
      if (this.excessFundInfo?.Peso && this.excessFundInfo?.Dollar) {
        const pesoHitCount = this.excessFundInfo.Peso.filter(data => data.HitCount > 0).length
        const dollarHitCount = this.excessFundInfo.Dollar.filter(data => data.HitCount > 0).length

        if (this.closeList) {
          if (this.closeList.length > 0) {
            this.closeList.forEach(res => {
              if (res.HitCount > 0) {
                closeHitCount += 1
              }
            })
          }

          this.validateCloseBranch(closeHitCount > 0 || pesoHitCount > 0 || dollarHitCount > 0)
        } else {
          this.validateCloseBranch(pesoHitCount > 0 || dollarHitCount > 0)
        }
      } else {
        this.validateCloseBranch(false)
      }
    },
    validateCloseBranch (condition) {
      if (condition) {
        this.renderToast('error', 'Close Branch Error', 'You must close all the checklist below before closing the branch')
      } else {
        this.dialog = true
      }
    },
    async closeBranch () {
      this.dialog = false

      console.log(this.currUser.trx_date)

      const payload = {
        locationID: this.currUser.location_id,
        trxDate: this.formatDateTime(this.currUser.trx_date),
        userID: this.currUser.user_id
      }

      const res = await this.$store.dispatch('auth/closeBranch', payload)

      if (res.code === 200) {
        this.logout()
      }
    },
    async logout () {
      const that = this
      this.dialog = false

      const payload = {
        username: this.currUser.user_id,
        ip_address: this.currUser.ip_address
      }

      this.$store.commit('auth/SET_IS_OTP_PASSED', false)
      this.$store.commit('app/TOGGLE_LOADING', true)

      await this.$store.dispatch('auth/doLogout', payload).then(res => {
        console.log(res)
        that.$store.commit('auth/SET_CURRENT_USER', {})
        that.$store.commit('auth/AUTH_SUCCESS', false)
        that.$store.commit('app/TOGGLE_LOADING', false)
        that.$store.commit('auth/SET_SHOW_REFRESH', false)
        location.href = `${this.$uspAuthAppURL}/login`
        localStorage.setItem('logout_message', 'Transaction Date has been closed, please relogin your account')
      }).catch(() => {
        this.renderToast('error', 'Error logging-out', 'An error has occured, please try again.')
      })
    },
    formatDateTime (date) {
      if (date) return format(parseISO(date), 'yyyy-MM-dd')
      return ''
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    toggleSidebar () {
      console.log()

      if (this.$vuetify.breakpoint.width > 1263) {
        console.log('old value: ' + this.miniSidebar)
        this.miniSidebar = !this.miniSidebar
        console.log('new value: ' + this.miniSidebar)
        this.$store.commit('app/TOGGLE_MINI_SIDEBAR', this.miniSidebar)
      } else {
        this.miniSidebar = false
        this.$store.commit('app/TOGGLE_MINI_SIDEBAR', this.miniSidebar)

        this.sidebar = !this.sidebar
        this.$store.commit('app/TOGGLE_DRAWER', this.sidebar)
      }
    }
  }
}
</script>

<style scoped>
.v-application .top-bar.white {
  border-bottom: 1px solid #E5E5E5 !important;
}

.v-application  .ph-navbar-title {
  font-family: 'Baloo Extra Bold' !important;
}

.ph-stats-container {
  display: none;
}

.ph-stats-mobile {
  display: flex;
  flex-direction: column;
}

.v-application--is-ltr .v-list-item__action:first-child {
  margin-right: 10px !important;
}

.ph-stats-popover-error {
  display: flex;
  flex-direction: column;
}

.ph-stats-popover-error-action {
  padding-top: 0;
}

.ph-stats-popover-success-container {
  width: 100% !important;
}

@media only screen and (min-width: 715px) {
  .ph-stats-mobile {
    display: none;
  }

  .ph-stats-container {
    display: flex;
    flex-direction: row;
  }

  .ph-stats-container .v-list-item {
    padding-right: 0 !important;
  }

  .ph-stats-container .v-list-item:nth-of-type(3) {
    padding-left: 0 !important;
  }

  .ph-stats-container .v-list-item__content {
    width: 130px !important;
  }
}
</style>
