<template>
  <div>
    <div class="noprint">
      <v-row>
        <v-col cols="12">
          <v-spacer></v-spacer>
          <v-btn
            class="print-receipt-btn"
            color="primary"
            @click="printDiv('voucher')"
          >
            Print Receipt
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="voucher">
      <div style="width: 710px;height:525px;border-style: solid;border-width: 1px;">
        <div style="width: 320px;height: 80px;border-style:none;border-right-style: solid;border-right-width: 1px;border-bottom-style: solid;border-bottom-width: 1px;float:left;padding:10px;">
          <img src="@/assets/images/ph-logo-horizontal.svg" style="width:200px;height:70px;margin:auto;display: block">
        </div>
        <div style="width: 388px;height: 30px;border-style:none;border-bottom-style: solid;border-bottom-width: 1px;float:right;">
          <h4 style="text-align: center;font-weight: bold;">INTER-BRANCH TRANSFER</h4>
        </div>
        <div style="width: 388px;height:25px;border-style:none;border-bottom-style: solid;border-bottom-width: 1px;float:right;padding-left: 10px;padding-top: 5px;">
          <label >Date:</label>
          <span>{{ formatDate(receiptData.trx_date) }}</span>
        </div>
        <div style="width: 388px;height:25px;border-style:none;border-bottom-style: solid;border-bottom-width: 1px;float:right;">

        </div>
        <div style="width: 708px;height: 350px;border-style:none;border-bottom-style: solid;border-bottom-width: 1px;padding-left: 15px;padding-top:80px;">
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-bottom: 0px;">
            <div style="width: 25%;float:left;">
              <label>Date Needed/ Day: </label>
            </div>
            <div style="width: 30%;float:left;border-bottom-style: solid;border-bottom-width: 1px;">
              <p>{{ formatDate(receiptData.trx_date) }}</p>
            </div>
          </div>
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-top:0px;padding-bottom: 0px;">
            <div style="width: 25%;float:left;">
              <label>Amount In: </label>&nbsp;
            </div>
            <div style="width: 30%;float:left;border-bottom-style: solid;border-bottom-width: 1px;">
              <span style="text-transform: uppercase;">{{ receiptData.currency_name }}&nbsp; {{ receiptData.amount }}</span>
            </div>
          </div>
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-top:0px;padding-bottom: 0px;">
            <div style="width: 25%;float:left;">
              <label >Total Amount In Words:  </label>&nbsp;
            </div>
            <div style="width: 71%;float:left;border-bottom-style: solid;border-bottom-width: 1px;padding-right:30px;">
              <span style="text-transform: uppercase;">{{ toWords(receiptData.amount) }}</span>
            </div>
          </div>
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-top:0px;padding-bottom: 0px;">
            <div style="width: 28%;">
              <label>Reason for Fund Transfer:  </label>&nbsp;
            </div>
          </div>
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-top:0px;padding-bottom: 0px;padding-right:30px;">
            <div style="width: 100%;float:left;border-bottom-style: solid;border-bottom-width: 1px;">
              {{ receiptData.reason }}
            </div>
          </div>
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-top:0px;padding-bottom: 0px;">
            <div style="width: 45%;float:left;padding-right: 10px;">
              <label>Lending Branch Name: </label>&nbsp;
              <div style="width: 100%;float:left;border-bottom-style: solid;border-bottom-width: 1px;">
                {{ receiptData.lending_branch }}
              </div>
            </div>
            <div style="width: 45%;float:left">
              <label>Borrowing Branch Name: </label>&nbsp;
              <div style="width: 100%;float:left;border-bottom-style: solid;border-bottom-width: 1px;">
                {{ receiptData.borrowing_branch }}
              </div>
            </div>
          </div>
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-top:0px;padding-bottom: 0px;margin-bottom: 0px;">
            <div style="float:left;">
              <label>Requested by: </label>&nbsp;
            </div>
            <div style="width: 31%;float:left;border-bottom-style: solid;border-bottom-width: 1px;padding-right: 10px;padding-bottom: 0px;">
              {{ receiptData.received_by }}
            </div>
            <div style="float:left;padding-left: 10px;">
              <label>Released by: </label>&nbsp;
            </div>
            <div style="width: 32%;float:left;border-bottom-style: solid;border-bottom-width: 1px;padding-right: 10px;">
              {{ receiptData.released_by }}
            </div>
          </div>
          <div>
            <small style="padding-right: 10px;padding-left: 90px;padding-top: 0px;margin-top: 0px;margin-bottom: 0px;font-size: 11px;">Signature over printed Name/Position</small>
            <small style="padding-right: 10px;padding-left: 110px;padding-top: 0px;margin-top: 0px;margin-bottom: 0px;font-size: 11px;">Signature over printed Name/Position</small>
          </div>
          <div>
            <small style="padding-right: 10px;padding-left: 130px;padding-top: 0px;margin-top: 0px;margin-bottom: 0px;font-size: 11px;">(Lending Branch)</small>
            <small style="padding-right: 10px;padding-left: 210px;padding-top: 0px;margin-top: 0px;margin-bottom: 0px;font-size: 11px;">(Borrowing Branch)</small>
          </div>
          <div class="row approved-signature-section" style="border-style: none;padding-left:15px;width: 708px;padding-top:0px;padding-bottom: 0px;margin-bottom: 0px;">
            <div style="float:left;">
              <label>Approved by: </label>&nbsp;
            </div>
            <div style="width: 31%;float:left;border-bottom-style: solid;border-bottom-width: 1px;padding-right: 10px;padding-bottom: 0px;">
              {{ receiptData.approved_by }}&nbsp;
            </div>
          </div>
          <small style="padding-right: 10px;padding-left: 115px;padding-top: 0px;margin-top: 0px;margin-bottom: 0px;font-size: 11px;">Signature over printed Name</small>
          <div class="row note" style="border-style: none;padding-left:15px;width: 708px;padding-top:0px;padding-bottom: 0px;margin-bottom: 0px;">
            <div style="float:left;">
              <i>Note: Please attached email reply of the approving committee </i>
            </div>
          </div>
        </div>
        <div class="fund-transfer" style="width: 708px;height:80px;border-style:none;border-bottom-style: solid;border-bottom-width: 1px;padding-left: 15px;padding-top: 0px;">
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-bottom: 0px;padding-top: 0px;">
            <div style="float:left;">
              <label>Fund Transfer Received By</label>
            </div>
            <div style="padding-left:75px;float:left;">
              <label>Date:</label>
            </div>
            <div style="width: 32%;float:left;border-bottom-style: solid;border-bottom-width: 1px;padding-right: 10px;">
              &nbsp;{{ formatDate(receiptData.trx_date) }}
            </div>
          </div>
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-bottom: 0px;padding-top: 0px;">
            <div style="width: 20px;height:15px;border-style: solid;border-width: 2px;float: left;">
            </div>
            <div style="padding-left:10px;float: left;">
              <p>Bank Transfer: Attached copy of Withdrawal Slip</p>
            </div>
          </div>
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-bottom: 0px;padding-top: 0px;">
            <div style="width: 20px;height:15px;border-style: solid;border-width: 2px;float: left;">
            </div>
            <div style="padding-left:10px;float: left;">
              <p>Physically Received By:</p>
            </div>
            <div style="width: 45%;float:left;border-bottom-style: solid;border-bottom-width: 1px;padding-right: 10px;">
              &nbsp;{{ receiptData.received_by }}
            </div>
          </div>
          <small class="borrower-signature-text" style="padding-right: 10px;padding-left: 170px;padding-top: 0px;margin-top: 0px;margin-bottom: 0px;font-size: 11px;">Signature over printed Name of Borrower/Position</small>
        </div>
        <div class="fund-transfer" style="width: 708px;height:85px;border-style:none;border-bottom-style: solid;border-bottom-width: 1px;padding-left: 15px;padding-top: 0px;">
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-bottom: 0px;padding-top: 0px;">
            <div style="float:left;">
              <label>Fund Transfer Returned By</label>
            </div>
            <div style="padding-left:75px;float:left;">
              <label>Date:</label>
            </div>
            <div style="width: 32%;float:left;border-bottom-style: solid;border-bottom-width: 1px;padding-right: 10px;">
              &nbsp;
            </div>
          </div>
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-bottom: 0px;padding-top: 0px;">
            <div style="width: 20px;height:15px;border-style: solid;border-width: 2px;float: left;">
            </div>
            <div style="padding-left:10px;float: left;">
              <p>Bank Transfer: Attached copy of Deposit Slip</p>
            </div>
          </div>
          <div class="row" style="border-style: none;padding-left:15px;width: 708px;padding-bottom: 0px;padding-top: 0px;">
          <div style="width: 20px;height:15px;border-style: solid;border-width: 2px;float: left;">
            </div>
            <div style="padding-left:10px;float: left;">
              <p>Physically Returned By:</p>
            </div>
            <div style="width: 45%;float:left;border-bottom-style: solid;border-bottom-width: 1px;padding-right: 10px;">
              &nbsp;
            </div>
          </div>
          <small class="borrower-signature-text" style="padding-right: 10px;padding-left: 170px;padding-top: 0px;margin-top: 0px;margin-bottom: 0px;font-size: 11px;">Signature over printed Name of Borrower/Position</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format, parseISO } from 'date-fns'
import { toWords } from '@/utils'

export default {
  data () {
    return {
      receiptData: {}
    }
  },
  beforeMount () {
    if (this.$route.query.qq) {
      const getData = Buffer.from(this.$route.query.qq, 'base64').toString('ascii')
      this.receiptData = JSON.parse(getData)
    } else {
      this.$router.push('/inter-branch-transfer')
    }
  },
  methods: {
    toWords,
    formatDate (date) {
      if (date) return format(parseISO(date), 'yyyy-MM-dd')
      return date
    },
    printDiv (printableArea) {
      var headstr = "<style> body{ font-size: 12px; line-height:1.1;margin:auto;display: block;padding-left:220px;font-family: 'Arial' !important } .container{ margin-left: auto; } #main { margin-top: 10px; }</style><body><div id='main' class='container'>"
      var footstr = '</body>'
      var printcontents = document.getElementById(printableArea).innerHTML
      var origcontents = document.body.innerHTML
      document.body.innerHTML = headstr + printcontents + footstr
      window.print()
      document.body.innerHTML = origcontents
    }
  }
}
</script>
<style scoped>
  #voucher {
    font-size: 12px;
    line-height: 1.5;
  }

  .print-receipt-btn {
    float: right;
  }

  @import url('../../css/ibt-receipt.css');

  @media not print {
    #voucher .fund-transfer {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      height: 90px !important;
    }

    #voucher .borrower-signature-text {
      position: relative;
      top: 11px;
    }

    #voucher .note {
      padding-top: 15px !important;
    }

    #voucher .approved-signature-section {
      padding-top: 20px !important;
    }

    .noprint {
      padding: 20px;
    }
  }
</style>
