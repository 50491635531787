<template>
  <v-dialog v-model="showCustomerCreationModal" persistent width="80%">
    <v-card class="rounded-lg">
      <v-card-title class="py-md-6">
        <h3 class="custom-heading primary--text">
          PERA Hub Register New Customer
        </h3>
        <v-spacer />
        <v-btn @click="closeDialog" small icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row cols="12" no-gutters>
            <v-col class="pb-4 px-2" :cols="12" :md="3">
              <v-text-field
                v-model="formData.first_name"
                class="ph-textbox"
                color="gray darken-1"
                label="First Name *"
                :rules="requiredField"
                @input="convertToProperCase('first_name')"
                autofocus
                outlined
              />
            </v-col>
            <v-col class="pb-4 px-2" :cols="12" :md="3">
              <v-text-field
                v-model="formData.middle_name"
                class="ph-textbox mb-6"
                color="gray darken-1"
                label="Middle Name *"
                :disabled="tag_no_middlename"
                @input="convertToProperCase('middle_name')"
                outlined
              />
              <div class="d-flex flex-row justify-end">
                <v-checkbox
                  v-model="tag_no_middlename"
                  @change="toggleNoMiddleName"
                  class="my-0 py-0"
                  :ripple="false"
                  label="No Middle Name"
                  on-icon="mdi-check-circle"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  hide-details
                />
              </div>
            </v-col>
            <v-col class="pb-4 px-2" :cols="12" :md="3">
              <v-text-field
                v-model="formData.last_name"
                class="ph-textbox"
                color="gray darken-1"
                label="Last Name *"
                :rules="requiredField"
                @input="convertToProperCase('last_name')"
                outlined
              />
            </v-col>
            <v-col class="py-0 px-2" :cols="12" :md="3">
              <v-text-field
                v-model="formData.birth_date"
                @click:append="openDatePicker"
                @input="syncDate"
                class="ph-textbox"
                label="Birth Date *"
                append-icon="mdi-calendar"
                :rules="birthDateRules"
                hint="Type or pick a date by clicking the calendar icon"
                outlined
              />
              <v-dialog v-model="birthDatePicker" width="300">
                <v-date-picker
                  v-model="tempDate"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @input="birthDatePicker = false"
                />
              </v-dialog>
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="3">
              <v-autocomplete
                v-model="formData.gender"
                :items="collections.gender"
                item-text="text"
                item-value="value"
                :return-object="false"
                label="Gender *"
                class="ph-textbox"
                :rules="requiredField"
                outlined
              />
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="3">
              <v-autocomplete
                v-model="formData.civil_status"
                :items="collections.civilStatus"
                label="Civil Status *"
                color="gray darken-1"
                class="ph-textbox"
                :rules="requiredField"
                outlined
              />
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="3">
              <v-autocomplete
                v-model="formData.country_birth"
                :items="collections.countries"
                item-text="country"
                item-value="country"
                :return-object="false"
                label="Country of Birth *"
                class="ph-textbox"
                @change="setNationality"
                :rules="requiredField"
                outlined
              />
              <v-checkbox class="ml-12 my-0 py-0 pt-2" hide-details style="visibility: hidden" />
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="3">
              <v-autocomplete
                v-model="formData.birth_place"
                :items="collections.cities"
                item-text="city"
                item-value="city"
                :return-object="false"
                label="Birth Place *"
                class="ph-textbox"
                :rules="requiredField"
                v-if="formData.country_birth === 'Philippines'"
                outlined
              >
                <template v-slot:selection="{ item }">
                  {{ item.city }}, {{ item.province }}
                </template>
                <template v-slot:item="{ item }">
                  {{ item.city }}, {{ item.province }}
                </template>
              </v-autocomplete>
              <v-text-field
                v-model="formData.birth_place"
                class="ph-textbox"
                color="gray darken-1"
                label="Birth Place *"
                :rules="requiredField"
                @input="convertToProperCase('birth_place')"
                outlined
                v-else
              />
            </v-col>
          </v-row>
          <v-row cols="23" no-gutters>
            <v-col cols="12">
              <p><b>Contact Details</b></p>
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="3">
              <v-text-field
                v-model="formData.email_add"
                class="ph-textbox"
                color="gray darken-1"
                label="Email Address"
                autofocus
                outlined
              />
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="3">
              <v-text-field
                v-model="formData.tel_no"
                class="ph-textbox"
                color="gray darken-1"
                label="Landline"
                outlined
              />
            </v-col>
            <v-col class="py-4 px-2" :cols="12" :md="3">
              <v-text-field
                v-model="formData.mobile_no"
                class="ph-textbox"
                color="gray darken-1"
                label="Mobile Number *"
                :rules="mobileNumberRules"
                :counter="11"
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex align-center">
        <v-btn
          color="danger"
          outlined
          text
          class="ph-primary-button ph-action-button"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          @click="addNewCustomer"
          color="primary"
          elevation="0"
          class="ph-primary-button ph-primary-execute-btn"
        >
          Add New Customer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { serialize } from 'object-to-formdata'

import {
  isValidEmail,
  isValidLandline,
  isValidMobileNumber,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  data () {
    return {
      birthDatePicker: false,
      tempDate: null,
      formData: {
        first_name: ''
      },
      requiredField: [
        v => !!v || 'This field is required'
      ],
      emailRules: [
        v => (v !== '' && isValidEmail(v)) || 'Invalid email address'
      ],
      landlineRules: [
        v => (v !== '' && isValidLandline(v)) || 'Invalid landline number'
      ],
      mobileNumberRules: [
        v => !!v || 'This field is required',
        v => isValidMobileNumber(v) || 'Invalid landline number'
      ],
      middleNameRules: [
        v => (!!v && !this.tag_no_middlename) || 'This field is required'
      ],
      birthDateRules: [
        v => !!v || 'This field is required',
        v => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v) || 'Please use YYYY-MM-DD format'
      ]
    }
  },
  computed: {
    ...mapGetters({
      showCustomerCreationModal: 'customers/showCustomerCreationModal',
      collections: 'collections',
      currUser: 'auth/currUser'
    }),
    tag_no_middlename: {
      get () {
        return (this.formData.tag_no_middlename === '1' || this.formData.tag_no_middlename === 1)
      },
      set (val) {
        const tag = (val) ? '1' : '0'
        this.formData.tag_no_middlename = tag
      }
    }
  },
  watch: {
    tempDate (val) {
      this.formData.birth_date = val
    }
  },
  methods: {
    renderToast,
    syncDate (val) {
      if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(val)) {
        this.tempDate = val
      }
    },
    openDatePicker () {
      this.birthDatePicker = true
    },
    convertToProperCase (field) {
      this.formData[field] = toProperCase(this.formData[field])
    },
    closeDialog () {
      this.$store.commit('customers/SET_CUSTOMER_CREATION_MODAL', false)
    },
    toggleNoMiddleName () {
      this.formData.middle_name = ''
      this.$refs.form.resetValidation()
    },
    setNationality (val) {
      this.formData.birth_place = ''
      const selectedCountry = this.collections.countries.filter(item => item.country === val)
      if (selectedCountry.length === 1) {
        this.formData.nationality = selectedCountry[0]?.nationality
      }
    },
    async addNewCustomer () {
      console.log(this.formData)

      if (this.$refs.form.validate()) {
        this.formData.created_by = this.currUser.user_id
        this.formData.updated_by = this.currUser.user_id
        const formData = serialize(this.formData)

        const res = await this.$store.dispatch('customers/doSave', formData)

        if (res.code === 200) {
          this.renderToast('success', 'Saving Successful', 'New customer has been added successfully')
          this.$store.commit('customers/SET_SELECTED_CUSTOMER', res.result)
          this.$store.commit('customers/SET_CUSTOMER_CREATION_MODAL', false)
          this.$store.commit('bills/SET_BILLS_PAYMENT_CURRENT_PAGE', 1)
        } else {
          this.renderToast('error', 'Saving Failed', 'Failed to add new customer')
        }
      } else {
        this.renderToast('error', 'Saving Failed', 'The fields are incomplete, kindly try again.')
      }
    }
  }
}
</script>

<style scoped>
  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
    width: initial;
    padding: 0 30px;
  }

  .ph-nonex-receive-action-buttons {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 20px;
  }

  @media only screen and (min-width: 600px) {
    .ph-nonex-receive-action-buttons {
      flex-direction: row;
    }

    .ph-primary-button {
      width: 160px;
    }
  }
</style>
