import api from '@/api'

export default {
  printMalayan (payload) {
    return api.post('/v1/maintenance/api/print-malayan', payload)
  },
  printMalayanNew (payload) {
    return api.post('/v1/maintenance/api/print-malayan-new', payload)
  },
  reprintMalayan (payload) {
    return api.post('/v1/maintenance/api/display-malayan', payload)
  },
  printPeraPayapa (payload) {
    return api.post('/v1/maintenance/api/print-pera-payapa', payload)
  },
  printDengueProtectPlus (payload) {
    return api.post('/v1/maintenance/api/print-dengue-protect-plus', payload)
  },
  printDengueProtectv2 (payload) {
    return api.post('/v1/maintenance/api/print-dengue-protect-v2', payload)
  },
  printCovidProtectPlus (payload) {
    return api.post('/v1/maintenance/api/print-covid-a', payload)
  },
  printCTPL (payload) {
    return api.post('/v1/maintenance/api/print-ctpl', payload)
  }
}
