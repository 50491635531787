export default {
  SET_CURRENT_PAGE (state, payload) {
    state.currentPage = payload
  },
  SET_SUCCESS_DATA (state, payload) {
    state.successData = Object.assign({}, state.successData, payload)
  },
  SET_SERVICE_FEES (state, payload) {
    state.serviceFees = payload
  },
  SET_TRANSACTION_DATA (state, payload) {
    state.transactionData = Object.assign({}, state.transactionData, payload)
  }
}
