export default {
  reportsStructure: [],
  remcoDatas: [],
  rhDatas: [],
  userBalanceDatas: [],
  // data needed for commit
  location_id: '',
  trx_date: '',
  group_location: '',
  user_id: ''
}
