<template>
  <div>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-md-1 mx-md-4 mt-md-5" no-gutters>
      <v-col :cols="12" :md="4">
        <h3 class="pb-md-6 custom-heading primary--text">
          Remote Agent
        </h3>
        <p class="text-body-2">
          Send Money from my location ({{ this.currUser.location_name }})
        </p>
      </v-col>
      <v-col class="pl-md-6" :cols="12" :md="8">
        <h4 class="mb-md-5 grey--text text--darken-2">
          Remote Agent Details
        </h4>
        <v-autocomplete
          class="ph-textbox mb-md-6"
          label="Remote Location"
          outlined
        />
        <v-autocomplete
          class="ph-textbox mb-md-6"
          label="Remote Username"
          outlined
        />
        <v-text-field
          class="ph-textbox mb-md-6"
          label="OTP"
          outlined
        />
        <div class="text-right">
          <v-btn class="rounded-lg" color="primary" large>
            Validate Remote User
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Remote Agent')
  }
}
</script>
