<template>
  <v-dialog
    class="progress-dialog"
    v-model="showProgressDialog"
    width="500"
    persistent>
    <v-card>
      <v-card-title class="ph-dialog-title">
        Processing Transaction
      </v-card-title>
      <br>
      <v-card-text class="ph-dialog-header">
        <v-progress-linear
          rounded
          :value="setPercentage"
          :color="setColor"
          height="25"
          dark
          :indeterminate="setColor === '#FFCA28'"
        >
          <template v-slot:default="{ value }" v-if="setColor !== '#FFCA28'">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
        <br>
        <p :class="(setColor === '#B71C1C') ? 'red--text darken-4' : ''">{{setDescription}}</p>
      </v-card-text>
      <v-card-actions v-if="setColor === '#B71C1C'">
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation="0" @click="closeDialog"><b>Close</b></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    showProgressDialog: Boolean,
    setPercentage: Number,
    setDescription: String,
    setColor: String
  },
  methods: {
    closeDialog () {
      this.$emit('update:showProgressDialog', false)
      this.$emit('update:setPercentage', 0)
      this.$emit('update:setDescription', '')
      this.$emit('update:setColor', '#1A2791')
    }
  }
}
</script>
<style>
.progress-dialog {
  z-index: 99999999999999999999999999
}
</style>
