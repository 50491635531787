export default {
  currentPage: 0,
  successData: null,
  serviceFees: [],
  transactionData: {
    formNumber: '',
    provider: '',
    relocNumber: '',
    referenceNumber: '',
    principalAmount: '',
    serviceCharge: '',
    remarks: '',
    ticketNumber: '',
    routeType: '',
    flightType: '',
    ticketStatus: '',
    paxNames: '',
    contactPerson: '',
    optionTime: '',
    totalPax: ''
  },
  providers: [
    { id: 0, name: 'Air Asia', logo: 'air-asia.png' },
    { id: 1, name: 'Cebu Pacific', logo: 'cebu-pacific.png' }
  ]
}
